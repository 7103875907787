import { Box, Paper } from "@mui/material";
import React from "react";

import { DescriptionPostStyles } from "~/presentation/views/Blog/Post/Sections/MostAccessedSection/components/DescriptionPost/DescriptionPost.styles";

export const DescriptionPost = ({ post, handleOnClick }) => {
  const { title, publishDate } = post;

  return (
    <Paper
      elevation={1}
      sx={{
        background: "#e1e1e1",
        borderRadius: 2,
        cursor: "pointer",
        padding: 0,
        "&:hover": {
          transform: "scale(1.03)",
          backgroundColor: "#ededed",
        },
      }}
    >
      <Box sx={{ borderRadius: 2 }}>
        {post.videoUrl && (
          <DescriptionPostStyles.VideoContainer>
            <div>
              <iframe src={post.videoUrl} frameBorder="0" width="100%" height="100%" />
            </div>
          </DescriptionPostStyles.VideoContainer>
        )}

        {!post.videoUrl && post.image && (
          <DescriptionPostStyles.ImageContainer src={post.image} alt="post-image" />
        )}

        <DescriptionPostStyles.Title
          onClick={() => {
            handleOnClick();
          }}
        >
          {title}
        </DescriptionPostStyles.Title>
        <DescriptionPostStyles.Date>
          Publicado: {`${new Date(post.publishDate).toLocaleDateString()}`}
        </DescriptionPostStyles.Date>
      </Box>
    </Paper>
  );
};
