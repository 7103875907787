import styled from "styled-components";
import FormikCustomErrorMessage from "~/presentation/components/ErrorMessage/index.jsx";

export const Container = styled.div`
  width: 100%;

  @media (min-width: 600px) {
    width: 70%;
  }
`;
export const CheckboxesWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  flex-flow: column;
  padding: 0 20px;

  .MuiTypography-body1 {
    font-weight: 300;
  }
`;

export const ErrorMessage = styled(FormikCustomErrorMessage)``;
