import React from "react";
import {SectionHeader} from "~/presentation/views/Client/ClientHome/components/SectionHeader";
import {Card} from "~/presentation/views/Client/ClientHome/components/Card";
import {CardsWrapper} from "~/presentation/views/Client/ClientHome/components/CardsWrapper";
import {Container} from "./styles";
import {useHistory} from "react-router";

const contentFeatures = [
  {
    id: 1,
    icon: "/assets/icons/notepad.png",
    title: "Artigos",
    text: "Leia artigos exclusivos e tire dúvidas que muita gente também tem.",
    link: "/cliente/conteudo-selecionado/artigo",
  },
  {
    id: 2,
    icon: "/assets/icons/file_stack.png",
    title: "Legislação",
    text:
      "Existem milhões de normas tributárias, mas nós separamos as que lhe interessam.",
    link: "/cliente/conteudo-selecionado/legislacao",
  },
  {
    id: 3,
    icon: "/assets/icons/laptop.png",
    title: "Multimídia",
    text:
      "Assista vídeos e entenda de forma fácil e didática o que parece complicado.",
    link: "/cliente/conteudo-selecionado/multimidia",
  },
];

export const SelectedContent = ({componentRef}) => {
  const history = useHistory();

  const handleClick = (link) => {
    history.push(link);
  };

  return (
    <Container ref={componentRef}>
      <SectionHeader
        text={`CONTEÚDO SELECIONADO`}
        subTitle="Leia artigos, assista vídeos e veja a legislação específica sobre a tributação dos seus contratos."
      />
      <CardsWrapper>
        {contentFeatures.map((card) => (
          <Card
            key={card.id}
            icon={card.icon}
            title={card.title}
            text={card.text}
            onClick={() => handleClick(card.link)}
            buttonVariant="outlined"
          />
        ))}
      </CardsWrapper>
    </Container>
  );
};
