import React, { useContext, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import removeMask from "~/presentation/functions/removeMask";
import { Container, InfoIcon } from "./styles";
import { Grid, FormControl, Button, TextField, Box, InputLabel } from "@mui/material";
import validationSchema from "./validationSchema";
import InputMask from "react-input-mask";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { DialogContext } from "~/presentation/providers/DialogProvider";

export const EditUserForm = ({ user, updateUser }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [userEdit, setUserEdit] = useState(user);
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false); // State to track password visibility

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const textinfopassword =
    "Escolha uma senha segura com pelo menos 8 caracteres, contendo pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial (por exemplo, !, @, #, $, %, etc.).";

  const verifyField = (value) => {
    return value ? value : "";
  };

  const handleClick = () => {
    window.location.reload();
  };

  const handleSubmit = async (values) => {
    event.preventDefault();
    const data = {
      companyId: user.companyId,
      name: values.name ?? user.name,
      email: values.email ?? user.email,
      password: values.password ?? user.password,
      confirmPassword: values.confirmPassword ?? user.confirmPassword,
      role: user.role,
      userProfileId: user.userProfileId,
      active: values.active,
      homePhone: values?.homePhone ?? user.homePhone,
      mobilePhone: values?.mobilePhone,
    };
    validationSchema
      .validate(data, { abortEarly: false })
      .then(() => {
        openDialogBox({
          message: `Deseja mesmo editar este usuário?`,
          callback: async () => {
            try {
              openBackdrop(true, "Editando usuário");
              await updateUser(data);
              setFeedbackSnackbar({
                isOpen: true,
                message: "Usuário editado com sucesso!",
                type: "success",
              });
            } catch (error) {
              setFeedbackSnackbar({
                isOpen: true,
                message: "Erro ao editar usuário: " + error?.response.data,
                type: "error",
              });
            } finally {
              openBackdrop(false);
              window.location.reload();
            }
          },
        });
      })
      .catch((errors) => {
        console.log(errors);
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
      });
  };

  const handlePhoneChange = (event) => {
    const { name, value } = event.target;
    setUserEdit({ ...userEdit, [name]: value });
  };

  return (
    <Container>
      {user && (
        <FormControl>
          {user && (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="name"
                  type="text"
                  label="Nome *"
                  value={userEdit?.name ?? user.name}
                  onChange={(e) => setUserEdit({ ...userEdit, name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="email"
                  type="email"
                  label="E-mail *"
                  value={userEdit?.email ?? user.email}
                  onChange={(e) => setUserEdit({ ...userEdit, email: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputMask
                  mask="(99) 9999-9999"
                  disabled={false}
                  onChange={handlePhoneChange}
                  value={userEdit?.homePhone ?? user.homePhone}
                  InputLabelProps={{ shrink: true }}
                  sx={styles.field}
                >
                  {() => <TextField sx={styles.field} name="homePhone" label="Telefone" />}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputMask
                  mask="(99) 99999-9999"
                  disabled={false}
                  onChange={handlePhoneChange}
                  maskChar=" "
                  type="text"
                  value={userEdit?.mobilePhone ?? user.mobilePhone}
                  InputLabelProps={{ shrink: true }}
                  sx={styles.field}
                >
                  {() => <TextField sx={styles.field} name="mobilePhone" label="Celular" />}
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="password"
                  type={showPassword ? "text" : "password"} // Toggle between text and password type
                  label="Senha *"
                  value={userEdit?.password ?? user.password}
                  onChange={(e) => setUserEdit({ ...userEdit, password: e.target.value })}
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={styles.field}
                  name="confirmPassword"
                  type={showPassword ? "text" : "password"} // Toggle between text and password type
                  label="Confirmar Senha *"
                  value={userEdit?.confirmPassword ?? user.confirmPassword}
                  onChange={(e) => setUserEdit({ ...userEdit, confirmPassword: e.target.value })}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button sx={styles.CancelButton} onClick={handleClick} variant="contained">
                  Cancelar
                </Button>
                <Button
                  sx={styles.Button}
                  variant="contained"
                  onClick={() => handleSubmit(userEdit)}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          )}
        </FormControl>
      )}
    </Container>
  );
};

const styles = {
  button: {
    backgroundColor: "#081445",
    color: "white",
    padding: "10px 30px",
    borderRadius: 1,
    fontSize: 16,
    fontWeight: 500,
    cursor: "pointer",
    transition: "background-color 0.12s ease-in-out",
    "&:hover": {
      backgroundColor: "#0092C7",
    },
    mr: 2,
  },
  buttonCancel: {
    backgroundColor: "#DDD",
    color: "#000",
    padding: "10px 30px",
    borderRadius: 1,
    fontSize: 16,
    fontWeight: 500,
    cursor: "pointer",
    transition: "background-color 0.12s ease-in-out",
    "&:hover": {
      backgroundColor: "#CCC",
    },
  },
  field: {
    backgroundColor: "#EFF2F4",
    width: "100%",
  },
  CancelButton: {
    backgroundColor: "#EFF2F4",
    color: "black",
  },
  Button: {
    background: "#081445",
    paddingInline: 4,
    paddingBlock: 1,
    marginLeft: 2,
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#0e3279",
    },
  },
};
