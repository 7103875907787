import React, { useEffect, useState } from "react";
import { EditUserForm } from "~/presentation/views/Client/MyPanel/Pages/UserData/components/EditUserForm/index";
import { Container, Box, Typography as Text, Grid } from "@mui/material";
import InputMask from "react-input-mask";

export const UserData = ({ companyUseCases, userUseCases }) => {
  const { companyId, id: userId } = JSON.parse(localStorage.getItem("GT_user"));
  const [company, setCompany] = useState(null);
  const [user, setUser] = useState();
  const [taxData, setTaxData] = useState();

  const getUser = async () => {
    const result = await userUseCases.getUserById(userId);
    setUser(result);
  };
  const getTax = async () => {
    const result = await userUseCases.getTaxData();
    setTaxData(result);
  };

  const updateUser = (data) => {
    return userUseCases.updateUser(userId, data);
  };

  const FormattedField = ({ mask, value, style }) => {
    return (
      <InputMask mask={mask} value={value} style={style}>
        {(inputProps) => <span {...inputProps} />} {/* Render as 'span' for display */}
      </InputMask>
    );
  };
  const getCompany = async () => {
    const result = await companyUseCases.getCompanyById(companyId);
    const mobilePhone = result?.mobilePhone;
    const homePhone = result?.mobilePhone;
    const cnpj = result?.cnpj;
    const cep = result?.zipCode;
    const formattedMobilePhone = mobilePhone ? (
      <InputMask
        style={{
          background: "transparent",
          border: "none",
          margin: 0,
          textAlign: "right",
          color: "#021148",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 400,
        }}
        mask="(99) 99999-9999"
        value={mobilePhone}
      />
    ) : null;

    const formattedHomePhone = homePhone ? (
      <InputMask
        style={{
          background: "transparent",
          border: "none",
          margin: 0,
          textAlign: "right",
          color: "#021148",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 400,
        }}
        mask="(99) 99999-9999"
        value={homePhone}
      />
    ) : null;

    const formattedCpnj = cnpj ? (
      <InputMask
        style={{
          background: "transparent",
          border: "none",
          margin: 0,
          textAlign: "right",
          color: "#021148",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 400,
        }}
        mask="99.999.999/9999-99"
        value={cnpj}
      />
    ) : null;

    const formattedCep = cnpj ? (
      <InputMask
        style={{
          background: "transparent",
          border: "none",
          margin: 0,
          textAlign: "right",
          color: "#021148",
          fontFamily: "Montserrat, sans-serif",
          fontWeight: 400,
        }}
        mask="99999-999"
        value={cep}
      />
    ) : null;

    setCompany([
      ["Categoria", result.category.name],
      ["Plano", result.plan.name],
      ["CNPJ", formattedCpnj],
      ["Nome", result.name],
      ["Endereço", result.address],
      ["Complemento", result.complement],
      ["CEP", formattedCep],
      ["Cidade", result.city.name],
      ["Estado", result.state.name],
      ["Telefone", formattedHomePhone],
      ["Celular", formattedMobilePhone],
      ["E-mail", result.email],
    ]);
  };

  useEffect(() => {
    getCompany();
    getUser();
    getTax();
  }, []);

  return (
    <Container sx={{paddingBottom: 6}}>
      <Text sx={styles.title}>Minha Empresa</Text>
      <Grid container spacing={2}>
        {company?.map((c, i) => (
          <Grid item xs={12} sm={6} key={i}>
            <Box sx={styles.box}>
              <Text sx={[styles.text, { fontWeight: 500 }]}>{c[0]}</Text>
              <Text sx={styles.text}>{c[1]}</Text>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Text sx={styles.title}>Minhas Informações</Text>
      <EditUserForm user={user} updateUser={updateUser} />
    </Container>
  );
};

const styles = {
  box: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  ifs: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#EFF2F4",
    padding: "20px 20px",
    borderRadius: 2,
  },
  border: {
    borderLeft: "2px solid #9EA5AA",
    height: 20,
  },
  text: {
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    paddingBlock: 3,
  },
};
