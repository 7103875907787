import { transparentize } from "polished";
import { lighten } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  background-image: url("/assets/escritorio.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
  padding: clamp(16px, 4vw, 6vw);

  @media screen and (min-width: 1080px) {
    grid-template-columns: 55% 45%;
  }

  @media screen and (min-width: 1240px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const FirstContent = styled.div`
  background: #1e88e5;
  position: relative;
  display: flex;
  align-items: center;

  .line {
    position: absolute;
    top: 10%;
    left: -100vw;
    bottom: 10%;
    right: 10%;
    border-top: solid 2px #fafafa;
    border-right: solid 2px #fafafa;
    border-bottom: solid 2px #fafafa;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .wrapper {
    padding: 10% 20% 10% 10%;

    @media screen and (max-width: 600px) {
      padding: 10% 5%;
    }

    h1 {
      font-weight: normal;
      font-size: max(32px, 4.2vw);
      line-height: max(32px, 4.2vw);
    }

    p {
      margin: max(20px, 2vw) 0;
      font-size: max(18px, 1.3vw);
      line-height: max(24px, 1.8vw);
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: max(5px, 0.5vw);
      font-size: max(14px, 1vw);
      flex-wrap: wrap;

      @media screen and (min-width: 425px) {
        font-size: max(18px, 1.3vw);
      }

      @media screen and (min-width: 600px) {
        justify-content: flex-end;
      }

      .MuiSvgIcon-root {
        margin-right: max(16px, 1.3vw);
      }
    }

    h1,
    p,
    div,
    b {
      font-family: "Montserrat", sans-serif;
      color: #fafafa;
      text-align: center;

      @media screen and (min-width: 600px) {
        text-align: right;
      }
    }
  }
`;

export const SecondContent = styled.div`
  background: #1e88e5;
  padding: 12% 10% 10%;
`;

export const AddressAndNewsLetterSubscribe = styled.div`
  background: #dedede;
  background: #fafafa;
  background-image: url("/assets/bg/dot-grid.png");
  padding: max(20px, 5vw) max(20px, 10vw);

  div.card {
    padding: max(20px, 4vw);
    background: #fafafa;
    display: flex;
    flex-wrap: wrap;
    gap: max(40px, 4vw);

    .address,
    .newsletter {
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    .address {
      align-items: center;
      p,
      h3 {
        text-align: center;
        font-family: "Montserrat", sans-serif;
      }
      h3 {
        font-size: max(20px, 2vw);
        font-weight: normal;
        margin-bottom: max(16px, 1.3vw);
      }
      p {
        max-width: 400px;
        font-size: max(16px, 1.3vw);
      }

      @media screen and (min-width: 1080px) {
        align-items: flex-start;
        p,
        h3 {
          text-align: left;
        }
      }
    }
  }
`;
