import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";

const columns = [
  { name: "code", label: "Código" },
  { name: "value", label: "Valor" },
  { name: "name", label: "Nome" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const ValueVariable = ({ valueVariableUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Search
  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  //Data
  const [valueVariables, setValueVariables] = useState();

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const apiValueVariables = await valueVariableUseCases.getAll();

    const userIsSuperAdmin = context.haveSuperAdminAccess();

    apiValueVariables.forEach((valueVariable) => {
      if (userIsSuperAdmin) {
        valueVariable.actions = (
          <TableActions
            handleDelete={() => deleteDialog(valueVariable.id)}
            handleEdit={() => handleOpenForm(valueVariable)}
          />
        );
      } else {
        valueVariable.actions = <TableActions handleEdit={() => handleOpenForm(valueVariable)} />;
      }
    });

    setValueVariables(apiValueVariables);
    openBackdrop(false);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo Variável de Valor");
    try {
      await valueVariableUseCases.delete(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Variável de Valor excluída com sucesso!",
        type: "success",
      });
      setValueVariables((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir Variável de Valor: Missing endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta variável de valor?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refresData={fetchData}
          valueVariableUseCases={valueVariableUseCases}
        />
      ) : (
        <>
          <PageListHeader
            title="Variável de Valor"
            titleAccess="Nova variável"
            searchFieldRef={searchFieldRef}
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
          />
          <Table searchRef={hiddenInputRef} columns={columns} data={valueVariables} />
        </>
      )}
    </Container>
  );
};

export default ValueVariable;
