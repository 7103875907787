import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ExitToApp, AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import AppContext from "~/presentation/AppContext";
import CloseIcon from "@mui/icons-material/Close";
import { Box, List, ListItem, ListItemText, Collapse, Button } from "@mui/material";
import { HashLink as RouterLink } from "react-router-hash-link";
import CountDownToken from "~/presentation/components/CountDownToken/CountDownToken";

const MobileNavigation = ({ open, openLoginDialog, navbarItems, toggleMobileDrawer, isLogged }) => {
  const [selectedLink, setSelectedLink] = useState();
  const location = useLocation();
  const listItemCount = useRef(0);
  const { handleLogout } = useContext(AppContext);

  const isItemActive = (name) => {
    const { hash, pathname } = location;
    if (pathname === "/" && name === "home" && hash === "") return true;

    if (hash === "" && pathname.includes("/cliente")) {
      if (pathname === "/cliente") {
        return name === "cliente";
      }
      return pathname.replace("/cliente", "").includes(name);
    }

    if (hash === "") {
      return location.pathname.includes(name);
    }

    return hash.includes(name);
  };

  const handleOpenSubLinks = (linkId) => {
    if (linkId === selectedLink) {
      setSelectedLink(undefined);
    } else {
      setSelectedLink(linkId);
    }
  };

  useEffect(() => {
    setSelectedLink(undefined);
  }, [open]);

  return (
    <Box sx={styles.container(open)}>
      <Box sx={styles.closeButton}>
        <CloseIcon onClick={toggleMobileDrawer} sx={styles.closeIcon} />
      </Box>
      <List sx={styles.list}>
        {navbarItems.map((item, index) => {
          listItemCount.current = index;
          return (
            <React.Fragment key={index}>
              <ListItem
                button
                component={RouterLink}
                to={item.link}
                sx={styles.listItem(open, index)}
                onClick={() => !item.link && handleOpenSubLinks(item.id)}
                className={isItemActive(item.name) ? "active" : ""}
              >
                <ListItemText primary={item.label} />
              </ListItem>
              {item.subLinks && (
                <Collapse in={selectedLink === item.id} timeout="auto" unmountOnExit>
                  <Box sx={styles.subLinksWrapper(item.subLinks.length, selectedLink === item.id)}>
                    {item.subLinks.map((subLink, subIndex) => (
                      <ListItem
                        key={"subLink-" + subIndex}
                        button
                        component={RouterLink}
                        to={subLink.link}
                        sx={styles.listItem(true, subIndex)}
                      >
                        <ListItemText primary={subLink.label} />
                      </ListItem>
                    ))}
                  </Box>
                </Collapse>
              )}
            </React.Fragment>
          );
        })}

        {isLogged ? (
          <Button
            sx={styles.ButtonLogin}
            onClick={handleLogout}
            startIcon={<ExitToApp style={{ fontSize: 22 }} color="#0d2338" />}
          >
            Sair
          </Button>
        ) : (
          <Button
            sx={styles.ButtonLogin}
            onClick={openLoginDialog}
            startIcon={<AccountCircleIcon style={{ fontSize: 25 }} color="#0d2338" />}
          >
            Área do Cliente
          </Button>
        )}
      </List>
    </Box>
  );
};

export default MobileNavigation;

const styles = {
  container: (open) => ({
    position: "fixed",
    right: 0,
    top: 0,
    bottom: 0,
    padding: "2% 0",
    overflow: "visible",
    width: "100%",
    zIndex: 12,
    backgroundColor: open ? "#1341a1" : "",
    display: open ? "block" : "none",
  }),
  closeButton: {
    width: "100%",
    marginBlock: 2,
    mr: 10,
    display: "flex",
    justifyContent: "flex-end",
  },
  closeIcon: {
    color: "#FFF",
    fontSize: 40,
  },
  list: {
    marginLeft: "10%",
    display: "grid",
    fontFamily: "Roboto, sans-serif",
  },
  listItem: (open, index) => ({
    width: "100%",
    padding: "16px 32px",
    display: "grid",
    gap: "8px",
    color: "#fafafa",
    textDecoration: "none",
    textTransform: "Capitalize",
    transition: `border-left 0.2s ease-in-out, ${
      open ? `transform ${index * 50 + 200}ms ease-in-out` : "display:none"
    }`,
    transform: open ? "translateX(0)" : "translateX(100%)",
    "&.active": {
      color: "#cf9455",
    },
  }),
  subListItem: {
    fontSize: "18px",
  },
  subLinksWrapper: (numberOfItems, open) => ({
    padding: "0 32px",
    background: "#1341a1",
    height: open ? numberOfItems * 60 : 0,
    overflow: "hidden",
    transition: "all 0.2s ease-in-out",
  }),
  ButtonLogin: {
    whiteSpace: "nowrap",
    borderRadius: "26px",
    background: "#1341a1", //#0d62a5
    color: "#fff",
    textAlign: "center",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 700,
    paddingBlock: "8px",
    paddingInline: "18px",
    display: "flex",
    alignItems: "center",
    marginBlock: 5,
  },
};
