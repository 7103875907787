import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Box, Button, CircularProgress, Container, Chip } from "@mui/material";

import BlogLayout from "~/presentation/layouts/BlogLayout";
import LastPostsSection from "~/presentation/views/Blog/BlogHome/Sections/LastPosts";
import MoreContentSection from "~/presentation/views/Blog/BlogHome/Sections/MoreContent";
import Footer from "~/presentation/components/Footer";
import blogNavbarItems from "~/presentation/views/Blog/data/blogNavbarItems";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import AppContext from "~/presentation/AppContext";
import ShouldAndSubscribeModals from "~/presentation/components/ShouldAndSubscribeModals";
import { usePaginatedState } from "~/presentation/hooks/usePaginatedState";
import { getSafeArray } from "~/presentation/utils/getSafeArray";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";
import { Close } from "@mui/icons-material";

const NUMBER_OF_LAST_POSTS = 9;
const NUMBER_OF_MOST_ACCESSED = 3;
const DEFAULT_IMAGE = "/assets/bg-contentblog.png";

const BlogHome = ({ blogUseCases, blogCategoryUseCases }) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const categoryId = query.get("categoryId");
  const categoriesContainerRef = useRef(null);
  const [isCategoriesContainerAtStart, setCategoriesContainerIsAtStart] = useState(true);
  const [isCategoriesContainerAtEnd, setIsCategoriesContainerAtEnd] = useState(false);

  const [highlight, setHighlight] = useState([]);
  const [lastPosts, setLastPosts] = useState([]);
  const [blogSeries, setBlogSeries] = useState([]);
  const [mostAccessedPosts, setMostAccessedPosts] = useState();
  const [blogPosts, setBlogPosts] = useState([]);
  const [blogCategories, setBlogCategories] = useState();
  const { isLogged } = useContext(AppContext);

  const dataLoaded = () => {
    return !!(highlight && lastPosts && blogSeries && mostAccessedPosts && blogPosts);
  };

  const getBlogPosts = async () => {
    const isClient = true;
    const queryParams = {
      pageIndex: 1,
      limit: 12,
    };
    const response = await blogUseCases.getBlogPosts(isClient, queryParams);
    setBlogPosts(response.posts);
  };

  const getLastBlogPosts = async () => {
    const { posts } = await blogUseCases.getLastBlogPosts(NUMBER_OF_LAST_POSTS);
    setLastPosts(posts);
  };

  const getHighlight = async () => {
    const { posts } = await blogUseCases.getHighlightBlogPost();
    setHighlight(posts[0]);
  };

  const getMostAccessedPosts = async () => {
    const { posts } = await blogUseCases.getMostAccessedPosts(NUMBER_OF_MOST_ACCESSED);
    setMostAccessedPosts(posts);
  };

  const getBlogSeries = async () => {
    const response = await blogCategoryUseCases.getBlogSeries();
    setBlogSeries(response);
  };
  const getBlogCategories = async () => {
    setBlogCategories(await blogCategoryUseCases.getBlogCategories());
  };
  const getBlogPostsByCategory = async (categoryId) => {
    const isClient = true;
    const queryParams = {
      pageIndex: 1,
      limit: 12,
      CategoryId: categoryId,
    };
    const response = await blogUseCases.getBlogPosts(isClient, queryParams);
    setContent(response.posts);
  };

  const handleNavigateToPost = (id, openModal) => {
    if (isLogged()) {
      history.push("/blog/post/" + id);
      return;
    }
    openModal(ShouldAndSubscribeModals);
  };

  const goToList = (serieId, search) => {
    const url = `/blog/list?`;
    const serie = serieId ? "serie=" + serieId : "";
    const searchStr = search ? "&search=" + search : "";
    const pageStr = "&page=1";
    const encodedUrl = encodeURI(url + serie + searchStr + pageStr);
    history.push(encodedUrl);
  };

  useEffect(() => {
    getHighlight();
    getLastBlogPosts();
    getMostAccessedPosts();
    getBlogPosts();
    getBlogSeries();
    getBlogCategories();
    if (categoryId) {
      getBlogPostsByCategory(categoryId);
    }
  }, [categoryId]);

  const getContent = (queryParams) => {
    const isClient = true;
    return blogUseCases.getBlogPosts(isClient, queryParams);
  };

  const {
    handleSearch,
    handlePage,
    numberOfPages,
    isLoading,
    content,
    setContent,
    page,
    handleFilterBycategory,
    clearCategory,
  } = usePaginatedState({
    getData: getContent,
    itemsPerPage: 9,
  });

  const handleSelectCategory = (categoryId) => {
    if (categoryId) {
      handleFilterBycategory(categoryId);
    } else {
      getBlogPosts();
    }
  };

  const handleCategoriesContainerScroll = (direction) => {
    const scrollAmount = 200;

    if (direction === "right") {
      categoriesContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    } else {
      categoriesContainerRef.current.scrollBy({
        left: -scrollAmount,
        behavior: "smooth",
      });
    }

    const currentScrollLeft =
      categoriesContainerRef.current.scrollLeft +
      (direction === "right" ? scrollAmount : -scrollAmount);
    const maxScrollLeft =
      categoriesContainerRef.current?.scrollWidth - categoriesContainerRef.current?.clientWidth;

    setCategoriesContainerIsAtStart(currentScrollLeft <= 0);
    setIsCategoriesContainerAtEnd(currentScrollLeft >= maxScrollLeft);
  };

  useEffect(() => {
    if (isCategoriesContainerAtEnd && categoryId && categoriesContainerRef.current) {
      categoriesContainerRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  }, [categoryId, isCategoriesContainerAtEnd]);

  return (
    <BlogLayout
      className="blog"
      navbarItems={blogNavbarItems}
      loggedNavbarItems={clientNavbarItems()}
    >
      {() => (
        <>
          <SectionTitlePost
            redirectURL="/blog"
            title="BLOG"
            onSearch={handleSearch}
            image={"/assets/multimedia-banner.png"}
            searchButtonBg="#ff6829"
            blur={0.1}
          />
          {!dataLoaded() ? (
            <Box
              sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "transparent",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <Container maxWidth="lg">
              <Box
                sx={{
                  width: "100%",
                  background: "transparent",
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  margin: "0 auto",
                  maxWidth: { xs: 300, sm: 550, md: 860, lg: 1200 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    rotate: "180deg",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCategoriesContainerScroll("left")}
                  disabled={isCategoriesContainerAtStart}
                >
                  <img src="/assets/icons/arrow-left-rounded.svg" alt="arrow-left" />
                </Box>
                <Box
                  ref={categoriesContainerRef}
                  sx={{
                    maxWidth: categoryId ? "80%" : "100%",
                    width: "100%",
                    display: "flex",
                    gap: 2,
                    justifyContent: "flex-start",
                    overflowX: "auto",
                    alignItems: "center",
                    transition: "scrollLeft 0.5s ease-in-out",
                    paddingBlock: { xs: 2, md: 2 },
                    paddingInline: { xs: 0, md: 2 },
                    "&::-webkit-scrollbar": {
                      width: "12px",
                      height: "2px",
                      background: "transparent",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "transparent",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "transparent",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "transparent",
                    },
                  }}
                >
                  {/* Mapeando as categorias do blog */}
                  {blogCategories?.map((category) => (
                    <Box
                      key={category.id}
                      sx={{
                        minWidth: "180px",
                        whiteSpace: "nowrap",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Chip
                        sx={{
                          fontFamily: "Montserrat",
                          color: "#000", // cor do texto
                          fontWeight: 600,
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          fontSize: 17,
                          backgroundColor: "transparent", // cor de fundo do Chip (opcional)
                          "& .MuiChip-label": {
                            fontSize: 17,
                          },
                          "&:hover": {
                            transform: "scale(1.03)",
                            backgroundColor: "#e6e6e6",
                          },
                          "&:active": {
                            transform: "scale(1.03)",
                            backgroundColor: "#e6e6e6",
                          },
                        }}
                        onClick={() => handleSelectCategory(category.id)}
                        value={category.id}
                        label={category.title}
                        clickable
                      />
                    </Box>
                  ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundImage:
                      "linear-gradient(to right, rgb(255 255 255 / 0), white var(--linaria-theme_spacing-macro40px))",
                  }}
                  onClick={() => handleCategoriesContainerScroll("right")}
                  disabled={isCategoriesContainerAtEnd}
                >
                  <img src="/assets/icons/arrow-left-rounded.svg" alt="arrow-left" />
                </Box>

                {categoryId && (
                  <Box
                    sx={{
                      minWidth: "200px",
                      whiteSpace: "nowrap",
                      display: "flex",
                      justifyContent: "center",
                      scrollBehavior: "smooth",
                      overflowX: "hidden",
                    }}
                  >
                    <Button
                      sx={{
                        fontFamily: "Montserrat",
                        color: "#6D6D6D",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      onClick={clearCategory}
                    >
                      <Close />
                      Limpar Filtro
                    </Button>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "auto",
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "transparent",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  <>
                    <LastPostsSection
                      lastPosts={getSafeArray({ array: content?.slice(0, 3) })}
                      // lastPosts={[]}
                      goToPost={(id) => handleNavigateToPost(id)}
                      defaultImage={DEFAULT_IMAGE}
                    />

                    <MoreContentSection
                      blogPosts={getSafeArray({ array: content?.slice(1) })}
                      // blogPosts={[]}
                      goToPost={(id) => handleNavigateToPost(id)}
                      goToList={goToList}
                      defaultImage={DEFAULT_IMAGE}
                      numberOfPages={numberOfPages}
                      goToPage={(value) => handlePage(value, categoryId)}
                      page={page}
                    />
                  </>
                )}
              </Box>
            </Container>
          )}
          <Footer />
        </>
      )}
    </BlogLayout>
  );
};

export default BlogHome;
