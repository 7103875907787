import React, { useReducer } from "react";
import { useEffect } from "react";
import PageListHeader from "../../components/PageListHeader";
import Table from "~/presentation/components/Table";
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { useRef } from "react";
import TableActions from "~/presentation/components/TableActions";
import { useContext } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { useHistory } from "react-router-dom";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";

const columns = [
  { name: "term", label: "Termo de busca" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const initialState = { terms: [], error: null };

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_SUCCESS":
      return { ...state, terms: action.payload };
    case "FETCH_ERROR":
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

const ListTerms = ({ termUseCases }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  useEffect(() => {
    fetchData();
  }, []);

  const history = useHistory();

  const fetchData = async () => {
    try {
      const terms = await termUseCases.getAll();

      const userIsSuperAdmin = context.haveSuperAdminAccess();

      terms?.forEach((term) => {
        if (userIsSuperAdmin) {
          term.actions = (
            <TableActions
              handleDelete={() => deleteDialog(term.id)}
              handleEdit={() => handleEdit(term.id)}
            />
          );
        } else {
          term.actions = <TableActions handleEdit={() => handleEdit(term.id)} />;
        }
      });
      dispatch({ type: "FETCH_SUCCESS", payload: terms });
    } catch (error) {
      dispatch({ type: "FETCH_ERROR", payload: error });
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este termo?`,
      callback: () => handleDelete(id),
    });
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo termo");
    try {
      await termUseCases.deleteTerm(id);
      await fetchData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir o termo",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const handleEdit = (id) => {
    history.push(`/admin/termos-de-pesquisa/editar/${id}`);
  };

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const handleAdd = () => history.push("/admin/termos-de-pesquisa/cadastrar");

  return (
    <Container maxWidth="100%">
      <PageListHeader
        title="Termos de Pesquisa"
        titleAccess="Novo Termo"
        searchFieldRef={searchFieldRef}
        handleAdd={handleAdd}
        handleSearch={handleSearch}
      />
      <Table
        searchRef={hiddenInputRef}
        columns={columns}
        data={state.terms}
        title="Termos de pesquisa"
      />
    </Container>
  );
};

export default ListTerms;
