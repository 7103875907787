import styled from "styled-components";
import { InputBase as MuiInputBase } from "@mui/material";
import { transparentize } from "polished";

export const SearchIconWrapper = styled.div`
  // padding: 10px;
  // height: 10%;
  position: relative;
  // top: 50%;
  right: 8px;
  // transform: translateY(-50%);
  // pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  background: none;
  width: ${({ type }) => (type === "secondary" ? "100%" : "fit-content")};
  height: ${({ type }) => (type === "secondary" ? "40px" : "min-content")};
  cursor: pointer;
  display: block;
  margin-top: 0.2rem;
`;

export const SearchFieldContainer = styled.div`
  position: relative;
  border-radius: ${({ borderRadius }) => borderRadius || "20px"};
  background: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : transparentize(0.95, "#383f48")};
  max-width: 100%;
  height: ${({ type }) => (type === "secondary" ? "40px" : "min-content")};

  display: flex;
  align-items: center;

  .MuiInputBase-root {
    width: 100%;
    padding: 0 0.2rem;
  }
`;

export const InputBase = styled(MuiInputBase)`
  .inputInput {
    transition: width 0.4s ease-in-out;
    color: ${({ theme, color }) => (color ? color : "#383f48")};
    padding: ${({ type }) => (type === "secondary" ? "0 0.6rem" : "0 0.6rem")};
    font-size: ${({ type }) => (type === "secondary" ? "0.7rem" : "0.9rem")};

    ::placeholder {
      color: inherit;
      opacity: ${({ type }) => (type === "secondary" ? "0" : "1")};
      font-size: ${({ type }) => (type === "secondary" ? "0.7rem" : "0.9rem")};
    }

    @media (min-width: 1250px) {
      font-size: ${({ type }) => (type === "secondary" ? "0.8rem" : "0.9rem")};

      ::placeholder {
        font-size: ${({ type }) => (type === "secondary" ? "0.8rem" : "0.9rem")};
      }
    }
  }
`;
