import React from "react";
import { exportCSV, exportXLSX } from "~/presentation/utils/exportTableDataToFiles";
import { Box, Button, Container } from "@mui/material";
import Table from "~/presentation/components/Table";

const statsFilename = {
  search: `pesquisas_${new Date().toISOString().split("T")[0]}`,
  "search-summary": `resumo-pesquisas_${new Date().toISOString().split("T")[0]}`,
  "content-access": `conteudos-acessados_${new Date().toISOString().split("T")[0]}`,
};

const statsTableTitle = {
  search: "Pesquisas no GT-Fácil realizadas pela empresa",
  "search-summary": "Resumo das pesquisas realizadas no GT-Fácil",
  "content-access": "Conteúdos acessados pela empresa",
};

const StatsTable = ({ columns, data, searchRef, searchType }) => {
  return (
    <Container maxWidth="100%">
      {data?.length > 0 && (
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "10px" }}
            onClick={() => exportCSV(data, columns, statsFilename[searchType])}
            disabled={data?.length === 0}
          >
            Exportar CSV
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => exportXLSX(data, columns, statsFilename[searchType])}
            disabled={data?.length === 0}
          >
            Exportar XLSX
          </Button>
        </Box>
      )}
      <Table
        searchRef={searchRef}
        columns={columns}
        data={data}
        title={statsTableTitle[searchType]}
      />
    </Container>
  );
};

export default StatsTable;
