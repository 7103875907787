import styled from "styled-components";
import BaseTextArea from "~/presentation/components/TextAreaLight";
import ButtonBase from "~/presentation/components/PrimaryButton";
import { Avatar as MuiAvatar } from "@mui/material";
import BaseSectionHeader from "~/presentation/views/Blog/components/SectionHeader";

export const Container = styled.div`
  background: #fafafa;
  width: 100%;
  padding: 0 max(10px, 9vw) max(150px, 9vw);

  * {
    font-family: "Montserrat", sans-serif;
    color: #272c33;
  }

  padding-top: 2rem;
`;

export const SectionHeader = styled(BaseSectionHeader)`
  margin-bottom: max(36px, 6vw);
  margin-top: 2rem;
`;

export const NewCommentBox = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const Avatar = styled(MuiAvatar).attrs(() => ({
  size: "small",
  color: "#022550",
}))`
  grid-area: A;

  &.MuiAvatar-root {
    background-color: ${(props) => props.color};
    height: max(32px, 4vw);
    width: max(32px, 4vw);
    font-size: max(16px, 1.8vw);
  }
`;

export const TextArea = styled(BaseTextArea).attrs(() => ({
  rows: 4,
}))``;

export const Button = styled.button`
  grid-area: B;
  justify-self: right;

  &.MuiButton-root {
    padding-left: 3vw;
    padding-right: 3vw;
    font-size: max(18px, 1.4vw);
  }

  cursor: pointer;
  color: #fff !important;
  padding: 0.8rem;
  border-radius: 40px;
  background-color: #ef6a08;
  color: #fff;
  border-color: #fd8c08;
  border-style: solid;
  border-width: 2px;
  margin-top: 1rem;
`;

export const CommentList = styled.div`
  display: grid;
  grid-gap: 20px;
`;

export const ThreadWrapper = styled.div`
  display: grid;
  grid-gap: 6px;

  background-color: #f47e11;
  border-radius: 0;

  padding: 1.5rem 2rem;

  border: 0.2rem solid #ffb767;
`;

export const MessageWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: ${({ index }) => (index !== 0 ? "0 max(18px, 2vw)" : 0)};

  .name-and-date {
    display: flex;
    align-items: flex-end;
    gap: max(14px, 1.2vw);
  }
`;

export const Name = styled.div`
  font-size: max(20px, 1.5vw);
  color: #fff;
  font-weight: 500;
`;

export const Date = styled.div`
  font-size: max(14px, 1.2vw);
  color: #bcbcbc;
  color: #fff;
`;

export const Text = styled.div`
  font-size: max(18px, 1.4vw);
  color: #fff;
  font-weight: 400;
`;

export const Title = styled.div`
  font-size: 2rem;
  color: #208ecb;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 2rem;
`;
