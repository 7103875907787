import React, {useContext, useState, useRef, useEffect} from "react";
import { Form, Formik } from "formik";
import {
  Button,
  FormWrapper,
  MoneyQuestion,
  SimulatorResultCard,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { floatToReais } from "~/presentation/utils/reais-parser";
import { useValuesChanged } from "~/presentation/views/GTFacil/Simulator/hooks/usValuesChanged";
import { reportMapper } from "./reportMapper";

const initialValues = {
  aliquotRetention: 0.0465,
};

export const CsllPisPasepCofinsCooperative = ({ contractorCategory }) => {
  const resultCardRef = useRef();
  const [result, setResult] = useState();
  const { simulatorValue, setInvalidSimulatorValue, handleGenerateReport } = useContext(SimulatorContext);
  const { isValuesChanged, setValues } = useValuesChanged();

  const getResult = (values) => {
    return simulatorValue * values.aliquotRetention;
  };

  if (contractorCategory.inRange(6, 11)) {
    return <SimulatorResultCard noTitleOrValue description="Não há retenção das contribuições." />;
  }

  const handleGenerateReportClick = (values) => {
    const response = reportMapper(values, result);
    handleGenerateReport(response);
  };

  useEffect(() => {
    if(result) {
      resultCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }
        action.setSubmitting(true);

        setValues(values);
        setResult(getResult(values));
        resultCardRef.current.scrollIntoView({ behavior: "smooth" });
        action.setSubmitting(false);
      }}
    >
      {({ values }) => {
        return (
          <Form>
            <FormWrapper>
              <MoneyQuestion
                text="Alíquota da retenção:"
                excerpt="A alíquota de retenção da CSLL, da COFINS e da contribuição para o PIS/Pasep é de 4,65%, correspondente à soma das alíquotas de 1%, 3% e 0,65%, respectivamente, conforme art. 31 da Lei n° 10.833/2003"
                value={values.aliquotRetention * 100}
                prefix="%"
                disabled
                allowNegative
              />
              <Button mt>Ver Resultado</Button>
              {result && isValuesChanged(values) ? (
                <SimulatorResultCard
                  componentRef={resultCardRef}
                  title="Valor da retenção da CSLL, PIS/Pasep e COFINS:"
                  value={floatToReais(result)}
                  description="O valor da retenção da CSLL, PIS/Pasep e COFINS corresponde ao resultado da multiplicação da alíquota pela base de cálculo."
                  generateReportButton={() => handleGenerateReportClick(values)}
                />
              ) : null}
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
