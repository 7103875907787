import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { makeLandingPageHome } from "~/main/factories/pages/landing-page/home";

const LandingPageRoutes = ({ match: { url } }) => {
  return (
    <Switch>
      <Route exact path={`${url}`} component={makeLandingPageHome} />
      <Redirect to={url} />
    </Switch>
  );
};

export default LandingPageRoutes;
