import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import PageListHeader from "../../components/PageListHeader";
import TableActions from "~/presentation/components/TableActions";
import Table from "~/presentation/components/Table";
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import { formatDateToTable } from "~/presentation/functions/date";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";

const SubscriptionTable = ({ subscriptionUseCases }) => {
  const [contacts, setContacts] = useState([]);
  const hiddenInputRef = useRef();
  const history = useHistory();
  const { openBackdrop } = useContext(BackdropContext);

  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      openBackdrop(true, "Carregando dados");
      const response = await subscriptionUseCases.getAll();
      if (response) {
        response.forEach((company) => {
          if (!company.isInCompany) {
            company.actions = (
              <TableActions
                handleSubscribe={() => {
                  // history.push passing all data of the company to new company register
                  history.push({
                    pathname: "/admin/empresa/cadastrar",
                    state: {
                      companyName: company.companyName,
                      cnpj: company.cnpj,
                      email: company.email,
                      mobilePhone: company.phone,
                      plan: company.planId,
                      categoryId: company.categoryId,
                    },
                  });
                }}
              />
            );
          }
        });
      }
      if (isMounted) setContacts(response);
      openBackdrop(false);
    }
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  const columns = [
    { name: "cnpj", label: "CNPJ" },
    { name: "companyName", label: "Nome da empresa" },
    { name: "name", label: "Nome do contato" },
    {
      name: "contactDate",
      label: "Data do contato",
      options: {
        customBodyRender: (value) => formatDateToTable(value),
      },
    },
    {
      name: "isInCompany",
      label: "Cadastrado",
      options: {
        filter: true,
        filterOptions: {
          names: ["Sim", "Não"],
        },
        customBodyRender: (value) => (value ? "Sim" : "Não"),
      },
    },
    { name: "actions", label: "Ações", options: { sortable: "false" } },
  ];

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  return (
    <Container maxWidth="100%">
      <PageListHeader title="Inscrições" handleSearch={handleSearch} />
      <Table searchRef={hiddenInputRef} columns={columns} data={contacts} title="Inscrições" />
    </Container>
  );
};

export default SubscriptionTable;
