import * as Yup from "yup";

function isObjectEmpty(obj) {
  return Object.keys(obj).length != 0;
}

export default Yup.object({
  title: Yup.string().required("Campo Obrigatório!"),
  type: Yup.object().test('type', 'Selecione um tipo de resposta', value => isObjectEmpty(value)).required("Campo obrigatório!"),
  publishDate: Yup.mixed().when("type", (type, schema) => type.id == 4 ?
    schema : schema.typeError("Selecione uma data!").required("Campo Obrigatório!"),
  ),
  videoUrl: Yup.mixed().when("type", (type, schema) =>
    type.id !== 4 ? schema : schema.required("Campo Obrigatório!"),
  ),
  category: Yup.mixed().when("type", (type, schema) => type.id === 3 ?
    schema.test('category', 'Selecione uma categoria', value => isObjectEmpty(value)).required("Campo obrigatório!") : schema,
  ),
  text: Yup.string().required("Campo Obrigatório!"),
  image: Yup.mixed().when("type", (type, schema) =>
    type.id != 4 ? schema.required("Campo Obrigatório!") : schema,
  ),
  qsoTable: Yup.mixed().when("type", (type, schema) =>
    type.id != 4 ? schema.required("Campo Obrigatório!") : schema,
  ),
  //  Yup.mixed().required("Campo Obrigatório!")
});
