import React, { useContext } from "react";
import AppContext from "~/presentation/AppContext";
// controller
import MessagesController from "~/presentation/views/Admin/components/MessagesController";
// data
import filtersList from "~/presentation/views/Admin/BlogComment/List/FilterList.js";
// components
import SearchField from "~/presentation/components/SearchField";
import PageHeader from "~/presentation/views/Admin/components/PageHeader";
import ListActions from "~/presentation/views/Admin/components/MessageListActions";
import MessageList from "~/presentation/views/Admin/components/MessageList";
import OpenPopover from "~/presentation/components/Popover";
// material ui
import { FilterList as FilterListIcon } from "@mui/icons-material";
//styles
import {
  Container,
  Wrapper,
  ListHeader,
  Pagination,
} from "~/presentation/views/Admin/BlogComment/List/styles";

const BlogComments = ({ commentUseCases }) => {
  const { haveSuperAdminAccess } = useContext(AppContext);

  const userIsSuperAdmin = haveSuperAdminAccess();

  const getComments = async () => {
    const comments = await commentUseCases.getAllComments();
    return comments;
  };

  const {
    mobile,
    selectedMessages,
    messages,
    setSelectedMessages,
    handleDelete,
    handleRefresh,
    handleSearch,
    numberOfPages,
    page,
    setPage,
    fetchMessagesWithFilter,
    CustomFilterList,
    handleSelectMessage,
    searchValue,
    handleClickMessage,
    filter,
  } = MessagesController({
    getMessages: getComments,
    filtersList,
    replyMessagePath: "/admin/comentarios/responder/",
    commentUseCases,
  });

  return (
    <>
      <PageHeader>
        <h1>Comentários do Blog</h1>
        {mobile && (
          <SearchField value={searchValue} handleSearch={(value) => handleSearch(value)} />
        )}
      </PageHeader>
      <Container>
        {!mobile && (
          <Wrapper className="filters">
            <div>
              <CustomFilterList />
            </div>
          </Wrapper>
        )}

        <Wrapper className="messages">
          <ListHeader>
            {userIsSuperAdmin ? (
              <ListActions
                selectedItems={selectedMessages}
                items={messages}
                onChange={setSelectedMessages}
                handleDelete={handleDelete}
                handleRefresh={handleRefresh}
                userIsSuperAdmin={userIsSuperAdmin}
                renderAfter={
                  mobile &&
                  (() => (
                    /* mobile: button to open filters menu */
                    <OpenPopover icon={FilterListIcon} label={filter.alias}>
                      {() => (
                        <Wrapper className="filters">
                          <CustomFilterList />
                        </Wrapper>
                      )}
                    </OpenPopover>
                  ))
                }
              />
            ) : (
              <ListActions
                selectedItems={selectedMessages}
                items={messages}
                onChange={setSelectedMessages}
                handleRefresh={handleRefresh}
                userIsSuperAdmin={userIsSuperAdmin}
                renderAfter={
                  mobile &&
                  (() => (
                    /* mobile: button to open filters menu */
                    <OpenPopover icon={FilterListIcon} label={filter.alias}>
                      {() => (
                        <Wrapper className="filters">
                          <CustomFilterList />
                        </Wrapper>
                      )}
                    </OpenPopover>
                  ))
                }
              />
            )}

            {!mobile && (
              <div>
                <SearchField value={searchValue} handleSearch={(value) => handleSearch(value)} />
              </div>
            )}
          </ListHeader>

          <MessageList
            messages={messages}
            selectedMessages={selectedMessages}
            handleSelectMessage={handleSelectMessage}
            disableGutters={mobile}
            onClick={handleClickMessage}
            title="title"
          />

          <Pagination
            size="small"
            count={numberOfPages}
            page={page}
            onChange={(event, selectedPage) => [
              setPage(page),
              fetchMessagesWithFilter({ page: selectedPage }),
            ]}
          />
        </Wrapper>
      </Container>
    </>
  );
};

export default BlogComments;
