import React from "react";
import { Container, Title, Content, ProgressWrapper } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress } from "@mui/material";

export const Card = ({ children, title, loading, border = true, handleClose }) => {
  return (
    <Container border={border}>
      <div style={{ display: "flex" }}>
        <Title>
          <p>{title}</p>
        </Title>
        {handleClose && (
          <Button style={{ backgroundColor: "#1341a1", borderRadius: 0 }} onClick={handleClose}>
            <CloseIcon style={{ color: "#fff" }} />
          </Button>
        )}
      </div>
      <Content>
        {loading ? (
          <ProgressWrapper className="absolute-center">
            <CircularProgress color="inherit" />
          </ProgressWrapper>
        ) : (
          children
        )}
      </Content>
    </Container>
  );
};
