import styled from "styled-components";

const LeftDescription = styled.div`

    max-width: 46rem;

z-index:2;
padding: 2rem 3rem;

span {
  font-weight: 700;
}

@media (max-width: 83.75rem){
    max-width: 100%;
}
`;

export const SelectedContentModalStyles = {
    LeftDescription
}
