import React from "react";
import useWindowSize from "~/presentation/hooks/useWindowSize";
import ModalBackground, {
  handleColorByWindowSize,
} from "~/presentation/views/LandingPage/Home/Modals/components/Bg";
import ModalContainer from "~/presentation/components/ModalContainer";
import {
  Header,
  Icon,
  Button,
  ContainerTextHeader,
  WrapperContent,
} from "~/presentation/views/LandingPage/Home/Modals/components/Wrappers";

// import CalculatorIcon2 from '~/presentation/views/LandingPage/Home/Modals/icons/CalculatorIcon';
// import CompassIcon from '~/presentation/views/LandingPage/Home/Modals/icons/CompassIcon';
// import MagnifyingGlassIcon from '~/presentation/views/LandingPage/Home/Modals/icons/MagnifyingGlassIcon';

import blogIcon from "~/presentation/views/LandingPage/Home/Modals/icons/blog.webp";

import alertaIcon from "~/presentation/views/LandingPage/Home/Modals/icons/alerta.webp";
import dicaRapidaIcon from "~/presentation/views/LandingPage/Home/Modals/icons/dica-rapida.webp";
import gtFacilIcon from "~/presentation/views/LandingPage/Home/Modals/icons/GTfacil.webp";
import gtResolveIcon from "~/presentation/views/LandingPage/Home/Modals/icons/GTresolve.webp";
import issIcon from "~/presentation/views/LandingPage/Home/Modals/icons/iss.webp";
import obrigacoesIcon from "~/presentation/views/LandingPage/Home/Modals/icons/obrigacoes.webp";

import { scrollToContactForm } from "~/presentation/utils/scrollToContactForm";

import { BlogModalStyles } from "./BlogModal.styles";

const blogSeries = [
  {
    id: 1,
    title: "Descomplicando +",
    icon: gtFacilIcon,
    text: "Vídeos que esclarecem a aplicação prática daquilo que se mostra complexo quando da simples leitura da legislação.",
  },
  {
    id: 2,
    title: "GT Resolve",
    icon: gtResolveIcon,
    text: "Exemplos de como o GT-Fácil deve ser explorado para a solução de dúvidas bastante comuns no dia a dia das empresas",
  },
  {
    id: 3,
    title: "Dica Rápida",
    icon: dicaRapidaIcon,
    text: "Orientação objetiva, em poucos parágrafos, sobre como proceder diante de dúvidas comuns acerca da apuração e recolhimento dos principais tributos incidentes na fonte.",
  },
  {
    id: 4,
    title: "Alerta GT",
    icon: alertaIcon,
    text: "Publicação específica sobre alguma novidade importante da legislação tributária ou da jurisprudência e que exige atenção das empresas para a mudança de seus procedimentos.",
  },
  {
    id: 5,
    title: "Caça-Obrigações",
    icon: obrigacoesIcon,
    text: "Comentários e vídeos sobre obrigações acessórias como eSocial, EFD-Reinf, DCTFWeb, DIRF, GFIP e tantas outras que empresas e entidades públicas devem apresentar.",
  },
  {
    id: 6,
    title: "ISS in Loco",
    icon: issIcon,
    text: "Explicações acerca de aspectos específicos da legislação do Imposto Sobre Serviços de mais de uma centena de municípios do Brasil.",
  },
];

const GTFacilModal = ({ closeModal }) => {
  const { width: screenWidth } = useWindowSize();

  const bgColorSelector = () => {
    return handleColorByWindowSize(screenWidth);
  };

  const handleOnClickContactButton = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=5571992084907&text=Ol%C3%A1!%20Vim%20do%20site%20Gest%C3%A3o%20Tribut%C3%A1ria%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20ferramenta%20GT-Reinf%20junto%20a%20um%20representante.",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <ModalContainer backgroundUrl="/assets/sample_photos/magnifying-glass-pc2.jpg">
      <ModalBackground color={bgColorSelector()} />
      <Header>
        <ContainerTextHeader>
          <Icon src={blogIcon} alt="Modal Icon" />
          <div>
            <span>Blog</span>
          </div>
        </ContainerTextHeader>
        <Button onClick={handleOnClickContactButton}>CONTRATE</Button>
      </Header>

      <WrapperContent>
        <BlogModalStyles.CenterDescription>
          <p>
            Tenha acesso às séries exclusivas produzidas pelo nosso time de especialistas para lhe
            orientar acerca das principais polêmicas relacionadas às retenções e encargos
            tributários incidentes na fonte. Além de comentários e vídeos sobre questões
            específicas, você conta com acesso às seguintes publicações periódicas:
          </p>
        </BlogModalStyles.CenterDescription>

        <BlogModalStyles.ListSeriesBlog>
          {blogSeries.map((serie) => (
            <BlogModalStyles.ContainerBlogSerie key={serie.id}>
              <BlogModalStyles.SerieIcon src={serie.icon} />
              <BlogModalStyles.SerieDescription>
                - {serie.title}: {serie.text}
              </BlogModalStyles.SerieDescription>
            </BlogModalStyles.ContainerBlogSerie>
          ))}
        </BlogModalStyles.ListSeriesBlog>
      </WrapperContent>
    </ModalContainer>
  );
};

export default GTFacilModal;
