import styled from "styled-components";

export const Container = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: max(1.1vw, 18px);
  background-color: #fafafa;
  box-shadow: -15px -15px 0px 0px #1341a1;
  max-width: 62%;
  padding: max(32px, 3vw) max(20px, 2.5vw);
  margin: 80px auto;
  margin-bottom: 20px;
  display: flex;
  flex-flow: column;

  > div {
    display: flex;
    flex-flow: column;
    margin-bottom: 20px;
    align-items: center;

    @media (min-width: 600px) {
      justify-content: space-between;
      flex-flow: row;
    }
  }
`;

export const Title = styled.p`
  font-weight: 500;
  margin-bottom: 24px;

  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;

export const Value = styled.p`
  font-size: max(1.3vw, 22px);
  white-space: nowrap;
`;
