import React from "react";
import { Button } from "../Button";

import { Container, Title, Value } from "./styles";
import {Typography} from "@mui/material";

export const SimulatorResultCard = ({
  title,
  value,
  description,
  componentRef,
  noTitleOrValue = false,
  generateReportButton
}) => {
  return (
      <Container ref={componentRef}>
        {noTitleOrValue === false && (
          <div>
            <Title>{title}</Title>
            <Value>{value}</Value>
          </div>
        )}
        {generateReportButton && (
          <Button onClick={generateReportButton} type="button">Gerar Relatório</Button>
        )}
        <Typography
         sx={{
           marginTop: "1.5rem",
           fontSize: "0.9rem",
         }}
        >
          {description}
        </Typography>
      </Container>
  );
};
