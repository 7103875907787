import { Formik } from "formik";
import React, { useState, useContext } from "react";
import { Card } from "~/presentation/views/GTFacil/components/Card";
import { Container, Checkbox, CheckboxesWrapper, Title, Warning, Button, Form } from "./styles";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { FormControlLabel } from "@mui/material";

export const GenerateReportModal = ({ answer, onSubmit }) => {
  const { openDialogBox } = useContext(DialogContext);

  const [submitError, setSubmitError] = useState();
  const initialValues = answer[0];

  const mappedAnswer = answer.map((item) => {
    return [
      {
        title: item.inss?.title,
        type: "inss",
        isDisabled: !item.inss?.content,
      },
      {
        title: item.irrf?.title,
        type: "irrf",
        isDisabled: !item.irrf?.content,
      },
      {
        title: item.csll?.title,
        type: "csll",
        isDisabled: !item.csll?.content,
      },
      {
        title: item.iss?.title,
        type: "iss",
        isDisabled: !item.iss?.content,
      },
    ];
  });

  const isDisabled = (values) => !Object.values(values).some((value) => value);

  return (
    <Container>
      <Card
        title="Gerar relatório"
        loading={!answer}
        border={false}
        handleClose={() => openDialogBox({ ...openDialogBox, open: false })}
      >
        {submitError ? (
          <Title>{submitError}</Title>
        ) : (
          <>
            <Title>Selecione os tributos para a geração do PDF</Title>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, action) => {
                action.setSubmitting(true);
                const reportData = { ...initialValues };
                Object.keys(values).forEach((key) => {
                  if (!values[key] && reportData[key]) {
                    reportData[key] = values[key];
                  }
                });
                try {
                  await onSubmit(reportData);
                } catch (err) {
                  setSubmitError(err.message);
                } finally {
                  openDialogBox({ ...openDialogBox, open: false });
                }
                action.setSubmitting(false);
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <CheckboxesWrapper>
                    {mappedAnswer.length ? (
                      mappedAnswer.flat().map((categoryAnswer) => (
                        <FormControlLabel
                          disabled={categoryAnswer.isDisabled}
                          key={categoryAnswer.type}
                          control={
                            <Checkbox
                              name={categoryAnswer.type}
                              checked={values[categoryAnswer.type]}
                              onChange={() => {
                                setFieldValue(categoryAnswer.type, !values[categoryAnswer.type]);
                              }}
                            />
                          }
                          label={
                            categoryAnswer.title
                              ? categoryAnswer.title
                              : categoryAnswer.type.toUpperCase()
                          }
                        />
                      ))
                    ) : (
                      <Warning>Não há dados para gerar o relatório</Warning>
                    )}
                  </CheckboxesWrapper>
                  {answer.length ? (
                    <Button disabled={isDisabled(values)} type="submit">
                      {answer.length && isDisabled(values)
                        ? "Selecione um Tributo"
                        : "Gerar Relatório"}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Form>
              )}
            </Formik>
          </>
        )}
      </Card>
    </Container>
  );
};
