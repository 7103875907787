import React from "react";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DateComponent = styled(DatePicker, {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ sx }) => ({
  width: sx?.width || "100%",
  backgroundColor: sx?.backgroundColor || "#FBFBFB", // Use theme for background color
  borderRadius: sx?.borderRadius || "3px",
  "& .MuiFormLabel-root, & .MuiInputBase-root, & .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root":
    {
      color: sx?.color || "#000", // Use theme for text color
      padding: sx?.padding || 0,
      fontFamily: "Montserrat !important",
      fontSize: sx?.fontSize || 14,
      borderRadius: sx?.borderRadius || "3px",
      border: sx?.border || "none",
      height: sx?.height || "54px",
      minWidth: "160px !important",
      "& input:disabled": {
        color: "#757575 !important", // Use theme for disabled color
      },
    },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  "& .MuiIconButton-root": {
    color: "#222222",
    marginRight: "10px",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:disabled": {
      color: "#757575 !important", // Use theme for disabled color
    },
  },
}));

const DateInput = (props) => {
  let format = "dd/MM/yyyy";
  let views = null;

  if (props.viewType === "month-year") {
    format = "MM/yyyy";
    views = ["year", "month"];
  }

  const data = props.value && new Date(props.value);

  return (
    <DateComponent
      value={data}
      onChange={props.onChange}
      renderInput={(params) => <TextField {...params} />}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      sx={props.styles}
      placeholder={
        props.placeholder || (props.viewType === "month-year" ? "MM/AAAA" : "DD/MM/AAAA")
      }
      variant="inline"
      format={format}
      views={views}
      maxDate={props.maxDate || new Date()}
      minDate={props.minDate || new Date("2016-01-01")}
    />
  );
};

export default DateInput;
