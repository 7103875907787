import React from "react";
import styled from "styled-components";

const GREY_RATIO = 690;
const GREY_TEXT = 250;
const GREY_TEXT_PLACEHOLDER = 650;

const Container = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius || "4px"};
  overflow: hidden;
  background: #fff;
  gap: 16px;
  padding: 0 0 0 14px;
  color: #ddd;
  width: 100%;
  box-shadow: ${({ boxShadow }) => boxShadow};

  textarea,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus {
    resize: none;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: inherit;
    color: #ddd;
    /* -webkit-text-fill-color: #ddd; */
    font-size: max(18px, 1.2vw);
    padding: 12px 0;
  }

  textarea::placeholder {
    color: #D1D1D1;
    font-size: max(18px, 1.2vw);
  }
`;

const TextArea = ({ children, icon: Icon, boxShadow, borderRadius, ...props }) => {
  return (
    <Container boxShadow={boxShadow} borderRadius={borderRadius}>
      {Icon && <Icon />}
      <textarea {...props}>{children}</textarea>
    </Container>
  );
};

export default TextArea;
