import { Company } from "~/domain/entities/company";
import { CompanyCategory } from "~/domain/entities/company-categoriy";
import { apiURL } from "../../usecases/api";

export class ApiCompanyUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getCompanies(isSummary = false) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Company?isSummary=${isSummary}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getCompaniesTable() {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Company`,
        method: "GET",
      });
      response.data.forEach((company) => {
        company.categoryName = company.category ? company.category.name : "sem categoria";
        company.planName = company.plan ? company.plan.name : "Sem plano";
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async deleteCompany(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/Company/${id}`,
      method: "DELETE",
    });
    return response.data;
  }

  async getCompanyById(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Company/${id}`,
        method: "GET",
      });
      let { data } = response;
      return new Company({
        id: data.id,
        active: data.active,
        address: data.address,
        category: new CompanyCategory(data.category.id, data.category.name),
        zipCode: data.zipCode,
        city: data.city,
        cnpj: data.cnpj,
        complement: data.complement,
        email: data.email,
        endDate: new Date(data.dateEnd),
        homePhone: data.homePhone,
        mobilePhone: data.mobilePhone,
        name: data.name,
        plan: data.plan,
        startDate: new Date(data.dateStart),
        dateCompanyRegistered: data.dateCompanyRegistered,
        state: data.state,
        status: data.status,
        contactName: data.contactName,
        contactCpf: data.contactCpf,
        //reinf
        totalSubstituteOrDismissed: data.totalSubstituteOrDismissed,
        legalNature: data.legalNature,
        isECD: data.isECD,
        isExemptionFrom: data.isExemptionFrom,
        isExemptionAgreement: data.isExemptionAgreement,
        isUnion: data.isUnion,
        efr: data.efr,
        cnpjEFR: data.cnpjEFR,
        taxClassification: data.taxClassification,
      });
    } catch (err) {
      console.log("Error", err);
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getCompanyCategories() {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Category`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getCompanyCategoryById(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Category/${id}`,
        method: "GET",
      });
      return new CompanyCategory(response.data.id, response.data.name);
    } catch (error) {
      console.log(error);
    }
  }

  async register(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Company/`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async update(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Company/`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getPlanDetails(companyId) {
    try {
      let company = await this.getCompanyById(companyId);
      return company.plan;
    } catch (error) {
      console.log(error);
    }
  }
}
