import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import {Box, Button, Container} from "@mui/material";
import { formatDateToTable } from "~/presentation/functions/date";
import CreateEdit from "./CreateEdit/CreateEdit";

const Blog = ({ blogUseCases, blogCategoryUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //data
  const [posts, setPosts] = useState([]);
  const [blogTags, setBlogTags] = useState([]);
  const [blogCategories, setBlogCategories] = useState([]);

  const [isDraft, setIsDraft] = useState(false);

  //Search
  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async (fetchDraftData = false) => {
    openBackdrop(true, "Carregando dados da tabela");
    const queryParams = {
      pageIndex: 1,
      limit: 100000,
      isDraft: fetchDraftData
    };
    const { posts } = await blogUseCases.getBlogPosts(false, queryParams);
    await getCategories();

    const userIsSuperAdmin = context.haveSuperAdminAccess();

    posts.forEach((content) => {
      if (userIsSuperAdmin) {
        content.actions = (
          <TableActions
            handleDelete={() => deleteDialog(content.id)}
            handleEdit={() => handleOpenForm(content)}
          />
        );
      } else {
        content.actions = <TableActions handleEdit={() => handleOpenForm(content)} />;
      }
    });
    setPosts(posts);

    openBackdrop(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const getTags = async () => {
    openBackdrop(true, "Carregando dados do formulário");
    let tags = await blogUseCases.getBlogTags();
    tags = dataToSelectMapper(tags);
    setBlogTags(tags);
    if (blogCategories.length === 0) {
      await getCategories();
    }
    openBackdrop(false);
  };

  const getCategories = async () => {
    let categories = await blogCategoryUseCases.getBlogCategories();
    categories = dataToSelectMapper(categories, "id", "title");
    setBlogCategories(categories);
  };

  const columns = [
    {
      name: "categoryId",
      label: "Categoria",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          let categoryName = blogCategories.find((category) => category.id === value).title;
          return categoryName;
        },
      },
    },
    { name: "title", label: "Título" },
    {
      name: "publishDate",
      label: "Data",
      options: {
        filter: false,
        customBodyRender: (value) => formatDateToTable(value),
      },
    },
    {
      name: "isDraft",
      label: "Rascunho",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (value === true ? "Sim" : "Não"),
      },
    },
    { name: "actions", label: "Ações", options: { sortable: "false" } },
  ];

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo post do blog");
    try {
      await blogUseCases.deleteBlogPost(id);
      setPosts((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir post: Missing endpoint",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este post?`,
      callback: () => handleDelete(id),
    });
  };

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refreshData={() => fetchData(isDraft)}
          blogUseCases={blogUseCases}
          blogTags={blogTags}
          blogCategories={blogCategories}
          getTags={getTags}
        />
      ) : (
        <>
          <PageListHeader
            title="Blog"
            titleAccess="Novo post"
            searchFieldRef={searchFieldRef}
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
          />
          <Box>
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => {
                setIsDraft(!isDraft);
                fetchData(!isDraft);
              }}
              style={{ marginRight: 10 }}
            >
              Ver {isDraft ? "Publicados" : "Rascunhos" }
            </Button>
          </Box>
          <Table
            searchRef={hiddenInputRef}
            columns={columns}
            data={posts}
            categories={blogCategories}
          />
        </>
      )}
    </Container>
  );
};

export default Blog;
