import React, { useContext } from "react";
import { useLocation } from "react-router";
import Footer from "~/presentation/components/Footer";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import { Menu } from "~/presentation/views/Client/MyPanel/components/Menu";
import { NewTaxInformation } from "./Pages/NewTaxInformation";
import { UserData } from "./Pages/UserData";
import { UserPlan } from "./Pages/UserPlan";
import { Container } from "@mui/material";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";

const DEFAULT_IMAGE = "/assets/escritorio.jpg";

const pages = [
  { id: 1, label: "Envio e Controle de IF's" },
  { id: 2, label: "Meu Plano" },
  { id: 3, label: "Meus Dados" },
];

const MyPanel = ({ userUseCases, companyUseCases, taxInformationUseCases }) => {
  const query = new URLSearchParams(useLocation().search);
  const page = query.get("page");

  const renderPages = () => {
    const pagesComponents = {
      1: (
        <NewTaxInformation
          taxInformationUseCases={taxInformationUseCases}
          companyUseCases={companyUseCases}
          userUseCases={userUseCases}
        />
      ),
      2: <UserPlan userUseCases={userUseCases} companyUseCases={companyUseCases} />,
      3: <UserData companyUseCases={companyUseCases} userUseCases={userUseCases} />,
    };

    return pagesComponents[page];
  };

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {() => (
        <>
          <SectionTitlePost
            redirectURL="/cliente/meu-painel?page=1"
            title="Meu Painel"
            image={DEFAULT_IMAGE}
            blur={0.8}
          />
          <Container maxWidth="lg" sx={{ minHeight: "100vh" }}>
            <Menu pages={pages} />
            {renderPages()}
          </Container>
          <Footer />
        </>
      )}
    </HomeLayout>
  );
};

export default MyPanel;
