import React from "react";
import { statsFormValidationSchema } from "~/presentation/views/Admin/Stats/Display/statsFormValidationSchema";
import { Form } from "~/presentation/views/Admin/Stats/Display/styles";
import { Field, Formik } from "formik";
import Select from "~/presentation/components/Select";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import DateInput from "~/presentation/components/DateInput";
import { Box } from "@mui/material";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import ErrorMessage from "~/presentation/components/ErrorMessage";

export const StatsForm = ({
  companiesData,
  usersData,
  handleSubmit,
  setStatsData,
  searchType,
  setSearchType,
}) => {
  return (
    <Formik
      initialValues={{
        searchType: "",
        companyId: "",
        initialDate: new Date(new Date().getFullYear(), 0, 1),
        finalDate: new Date(),
        userId: "",
        isDetail: false,
      }}
      validationSchema={statsFormValidationSchema}
      onSubmit={async (values, action) => {
        const submitValues = {
          companyId: values.companyId,
          searchType: values.searchType,
          userId: values.userId,
          initialDate: values.initialDate.toISOString().split("T")[0],
          finalDate: values.finalDate.toISOString().split("T")[0],
          isDetail: values.isDetail,
        };
        action.setSubmitting(true);
        await handleSubmit(submitValues);
        action.setSubmitting(false);
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <div className="form-grid">
            <div className="col-1_5">
              <Field
                name="searchType"
                component={Select}
                options={dataToSelectMapper([
                  { id: "search", name: "Pesquisas no GT-Fácil realizadas pela empresa" },
                  { id: "search-summary", name: "Resumo das pesquisas realizadas no GT-Fácil" },
                  { id: "content-access", name: "Conteúdos acessados pela empresa" },
                ])}
                placeholder={"Selecionar tipo de busca *"}
                onChange={(option) => {
                  setFieldValue("searchType", option.value);
                  setSearchType(option.value);
                  setStatsData([]);
                }}
              />
              <ErrorMessage name="searchType" />
            </div>
            <div className="col-1_5">
              <Field
                name="companyId"
                component={Select}
                options={companiesData}
                placeholder={"Selecione a empresa *"}
              />
              <ErrorMessage name="companyId" />
            </div>
            {searchType === "search" && (
              <>
                <div className="col-1_5">
                  <Field
                    name="userId"
                    component={Select}
                    options={usersData}
                    placeholder={"Selecione um usuário *"}
                  />
                  <ErrorMessage name="userId" />
                </div>
                <div className="col-1_2">
                  <DateInput
                    label="Data de início *"
                    value={values.initialDate}
                    onChange={(value) => setFieldValue("initialDate", value)}
                  />
                  <ErrorMessage name="initialDate" />
                </div>
                <div className="col-2_2">
                  <DateInput
                    label="Data final *"
                    value={values.finalDate}
                    onChange={(value) => setFieldValue("finalDate", value)}
                  />
                  <ErrorMessage name="finalDate" />
                </div>
              </>
            )}
            {searchType === "content-access" && (
              <>
                <Box className="col-1_5" display="flex" alignItems="center" gap={1}>
                  <Field
                    name="isDetail"
                    type="checkbox"
                    label="Detalhado"
                    onChange={(e) => setFieldValue("isDetail", e.target.checked)}
                  />
                  <label
                    htmlFor={"isDetail"}
                    onClick={() => setFieldValue("isDetail", !values.isDetail)}
                    style={{ cursor: "pointer" }}
                  >
                    Exibir conteúdo detalhado
                  </label>
                </Box>
              </>
            )}
          </div>

          <PrimaryButton style={{ marginBlock: 5 }} className="submit-btn" type="submit">
            Buscar dados
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  );
};
