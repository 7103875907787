export function filterByEqual(values, filter) {
  return !filter || filter === "all"
    ? values
    : values.filter((v) => v.filter === filter);
}

export function filterByInclude(values, search, field) {
  return !search
    ? values
    : values.filter((v) =>
        v[field]
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            search
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, ""),
          ),
      );
}
