import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "~/presentation/routes/PrivateRoute";
import AdminRoutes from "~/presentation/routes/AdminRoutes";
import LandingPageRoutes from "~/presentation/routes/LandingPageRoutes";
import BlogRoutes from "~/presentation/routes/BlogRoutes";
import ClientRoutes from "~/presentation/routes/ClientRoutes";
import GTFacilRoutes from "~/presentation/routes/GTFacilRoutes";
import ReinfRoutes from "~/presentation/routes/ReinfRoutes";


const Routes = () => (
  <Router>
    <Switch>
      <PrivateRoute
        path="/cliente"
        component={ClientRoutes}
        roles={['user', 'manager', 'admin', 'superAdmin']}
      />

      <PrivateRoute
        path="/gt-facil"
        component={GTFacilRoutes}
        roles={['user', 'manager', 'admin', 'superAdmin']}
      />

      <PrivateRoute
        path="/admin"
        component={AdminRoutes}
        roles={['admin', 'superAdmin']}
      />

      <PrivateRoute
        path="/blog"
        component={BlogRoutes}
        role={['user', 'manager', 'admin', 'superAdmin']}
      />

      <PrivateRoute
        path="/reinf"
        component={ReinfRoutes}
        roles={['user', 'manager', 'admin', 'superAdmin']}
      />

      <Route path="/" component={LandingPageRoutes} />
      <Route path="/" component={() => <h1>404 Not found</h1>} />

    </Switch>
  </Router>
);

export default Routes;
