import styled from "styled-components";

export const Title = styled.p`
  font-family: Montserrat !important;
  font-size: 22px;
  font-weight: 700 !important;
  color: #000000;
  text-align: left;
  display: flex;
  align-items: center;
  user-select: none;
  padding-block: 5px;
  gap: 4px;
`;

export const Subtitle = styled(Title)`
  font-size: 16px;
  cursor: pointer;
  padding-top: 0;
  color: #676767;
`;

export const GrayContainer = styled.div`
  margin: 5px auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
`;
