import React from "react";
import * as S from "~/presentation/components/SecondaryButton/styles";

const SecondaryButton = ({ children = "", variant, color = "gold", ...props }) => {
  if (variant === "outlined") {
    return (
      <S.OutlinedButton buttonColor={color} {...props}>
        {children}
      </S.OutlinedButton>
    );
  }

  return (
    <S.CondensedButton buttonColor={color} {...props}>
      {children}
    </S.CondensedButton>
  );
};

export default SecondaryButton;
