import * as Yup from "yup";

const requiredWhenInformCities = (value, schema) =>
  value === true ? schema.required("Campo Obrigatório!") : schema;

export default Yup.object({
  categoryId: Yup.mixed().required("Campo Obrigatório!"),
  companyType: Yup.mixed().required("Campo Obrigatório!"),
  cityHireId: Yup.mixed().when("isCity", requiredWhenInformCities),
  cityServiceId: Yup.mixed().when("isCity", requiredWhenInformCities),
  cityContractorId: Yup.mixed().when("isCity", requiredWhenInformCities)
});
