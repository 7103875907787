import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: 100%;
`;

export const Wrapper = styled.div`
  background-color: #fff;

  border-radius: 10px;
  overflow-y: auto;
  padding: 1.5vw;
  /* max-height: 100%; */
  height: calc(100% - 40px - 12px); /** 40: h2 height // 12px: margin-top */
  display: grid;
  gap: 16px;

  @media screen and (max-width: 350px) {
    height: calc(100% - 80px - 12px); /** 80: h2 height (2 lines) + 12px: margin-top */
  }

  /* preserve the padding bottom */
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
  }
`;

export const MessageWrapper = styled.div`
  display: grid;
  gap: 12px;
  padding: 0.5vw;

  .fromTo {
    > div {
      display: grid;
      grid-template-columns: min-content auto;

      > span {
        margin-left: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    @media screen and (max-width: 600px) {
      padding: 6px 12px;
    }
  }

  > hr {
    border: none;
    height: 1px;
    background: ${(props) => "#383f48"};
  }
`;

export const Chat = styled.div`
  display: grid;
  gap: 12px;

  .chatMessage {
    background: ${(props) => "#e1e1e1"};
    border-radius: 10px;
    padding: 1vw;

    .sender {
      margin-bottom: 6px;
    }
  }
`;
