import styled from "styled-components";

import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
} from "@mui/material";

export const List = styled(MuiList)`
  position: relative;
  overflow-y: auto;
`;

export const ListItem = styled(MuiListItem)`
  > div {
    display: grid;
    grid-template-columns: auto auto;
    gap: max(1vw, 10px);
  }
`;

export const ListItemIcon = styled(MuiListItemIcon)`
  &.MuiListItemIcon-root {
    min-width: unset;
  }
`;
