import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import { DeleteOutline, EditNote } from "@mui/icons-material";

export const BusinessPartnerTable = ({ handleOpenEdit, handleDelete, partnersData }) => {
  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = partnersData.slice(startIndex, endIndex);

  return (
    <Box sx={{ borderRadius: "10px", overflow: "hidden", marginInline: 3, marginBlock: 2 }}>
      <Table sx={{ borderCollapse: "separate", borderSpacing: "0" }}>
        <TableHead>
          <TableRow sx={styles.HeadTableRow}>
            <TableCell sx={styles.TableCellHead}>CNPJ</TableCell>
            <TableCell sx={styles.TableCellHead}>Nome Empresarial</TableCell>
            <TableCell sx={styles.TableCellHead}>Nome Fantasia</TableCell>
            <TableCell sx={styles.TableCellHead}>Inscrição Estadual</TableCell>
            <TableCell sx={styles.TableCellHead}>Inscrição Municipal</TableCell>
            <TableCell sx={styles.TableCellHead}>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentData?.length > 0 ? (
            currentData?.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell sx={{ ...styles.TableCell, minWidth: "200px" }}>
                    {formatCpfCnpj(item.cpfCnpj) || "-"}
                  </TableCell>
                  <TableCell sx={styles.TableCell}>{item.legalName || "-"}</TableCell>
                  <TableCell sx={styles.TableCell}>{item.tradeName || "-"}</TableCell>
                  <TableCell sx={styles.TableCell}>{item.ie || "-"}</TableCell>
                  <TableCell sx={styles.TableCell}>{item.im || "-"}</TableCell>
                  <TableCell sx={styles.TableCell}>
                    <Box sx={styles.TableCellIcons}>
                      <Tooltip title="Editar Parceiro">
                        <EditNote sx={styles.icon} onClick={() => handleOpenEdit(item)} />
                      </Tooltip>
                      <Tooltip title="Deletar Parceiro">
                        <DeleteOutline sx={styles.icon} onClick={() => handleDelete(item.id)} />
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={10}>
                <Typography style={{ textAlign: "center", fontSize: 14 }}>
                  Não existe eventos no período escolhido.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {partnersData?.length > 9 && (
        <Stack
          spacing={1}
          sx={{ background: "#F2F2F2", paddingRight: 8, borderRadius: "0px 0px 8px 8px" }}
        >
          <TablePagination
            component="div"
            count={partnersData?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Informações por página:"
            labelDisplayedRows={(paginationInfo) => {
              return `${paginationInfo.from}-${paginationInfo.to} de ${paginationInfo.count}`;
            }}
            rowsPerPageOptions={[10, 15, 20]}
            sx={{
              ".MuiTablePagination-selectLabel ": {
                margin: 0,
              },
              ".MuiTablePagination-displayedRows": {
                margin: 0,
              },
            }}
          />
        </Stack>
      )}
    </Box>
  );
};

const styles = {
  //LINHA TÍTULOS COLUNAS
  HeadTableRow: {},
  //LINHA TÍTULOS DAS COLUANS
  TableCellHead: {
    backgroundColor: "#1341A1",
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#ffffff",
    fontSize: 13,
    padding: "10px",
    fontWeight: 400,
    position: "relative",
    //DIVISOR COLUNAS HEAD
    "&:not(:first-child)::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "10%",
      bottom: "10%",
      left: 0,
      width: "3px",
      backgroundColor: "#142F68",
    },
  },
  //CADA LINHA (NOTA)
  TableCell: {
    fontFamily: "Montserrat ",
    fontSize: 14,
    paddingBlock: "0",
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
    borderBottom: "5px solid #F2F2F2",
  },
  TableCellIcons: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    fontSize: 14,
    paddingBlock: "12px",
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
  },
  //CADA LINHA
  TableRow: {},
  icon: {
    fontSize: "2rem",
    cursor: "pointer",
    ":hover": {
      color: "#1341a1",
    },
  },
};
