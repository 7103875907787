import React, { useState, useEffect } from "react";
// date picker context component
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import brLocale from "date-fns/locale/pt-BR";
// routes
import RoutesMain from "~/presentation/routes/index";
// context
import AppContext from "~/presentation/AppContext";
// styles
import { GlobalStyles } from "~/presentation/themes/styles.App";
import { makeAuthenticationUseCases } from "./factories/use-cases/authentication";
//chat talk to
import { TawkChat } from "~/presentation/components/TawkChat";
//context providers
import { SnackbarProvider } from "~/presentation/providers/SnackbarProvider";
import { BackdropProvider } from "~/presentation/providers/BackdropProvider";
import { DialogProvider } from "~/presentation/providers/DialogProvider";

function App() {
  const [user, setUser] = useState();
  const [didLogin, setDidLogin] = useState(true); //importante para renderizar o app quando existe Login novo

  const authenticationUseCases = makeAuthenticationUseCases();

  useEffect(() => {
    if (!localStorage.GT_user) {
      localStorage.clear();
      history.pushState(null, "/");
    }
  }, [localStorage]);

  const handleLogout = () => {
    authenticationUseCases.logout();
    setUser(null);
  };

  const handleLogin = async (values) => {
    return authenticationUseCases.auth(values);
  };

  const isLogged = () => {
    return authenticationUseCases.isLogged();
  };

  const haveSuperAdminOrAdminAccess = () => {
    const user = JSON.parse(localStorage.getItem("GT_user"));

    return user?.role === "superAdmin" || user?.role === "admin";
  };

  const haveSuperAdminAccess = () => {
    const user = JSON.parse(localStorage.getItem("GT_user"));

    return user.role === "superAdmin";
  };

  const contextValues = {
    user,
    setUser,
    handleLogin,
    handleLogout,
    isLogged,
    haveSuperAdminOrAdminAccess,
    haveSuperAdminAccess,
    didLogin,
    setDidLogin,
  };

  return (
    <AppContext.Provider value={contextValues}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
        <BackdropProvider>
          <SnackbarProvider>
            <DialogProvider>
              <GlobalStyles />
              <RoutesMain />
              <TawkChat />
            </DialogProvider>
          </SnackbarProvider>
        </BackdropProvider>
      </LocalizationProvider>
    </AppContext.Provider>
  );
}

export default App;
