import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: fit-content;
  background-image: url("/assets/maze5.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content min-content;
  justify-items: center;
  padding: max(10%, 40px) 3%;
  grid-gap: min(7vw, 51px);
`;

export const TitleWrapper = styled.div`
  display: grid;
  justify-items: center;

  h2 {
    font-family: Montserrat, sans-serif;
    font-size: 40px;
    line-height: 43px;
    letter-spacing: 0.06em;
    font-weight: 400;
    color: #fafafa;
    text-align: center;

    @media screen and (max-width: 600px) {
      font-size: 2rem;
    }

    span {
      font-weight: 600;
    }
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  align-items: start;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 32px;

  @media screen and (max-width: 1245px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, min-content);

    justify-items: center;
  }

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, min-content);

    justify-items: center;
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, min-content);
    grid-gap: 32px;
    justify-items: center;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, min-content);
    grid-gap: 32px;
    justify-items: center;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, min-content);
    grid-gap: 32px;
    justify-items: center;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-flow: column;
  justify-items: center;
  border-radius: 18px;
  background: linear-gradient(180deg, #e97c03 47.92%, #6a4113 100%);
  box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.25);
  padding: 28px;
  transition: transform 0.2s ease-in-out;
  width: 100%;
  min-height: 803px;
  height: 803px;
  max-width: 386px;

  &.customCard {
    background: linear-gradient(180deg, #fffdfc 0%, #d0cac3 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    *,
    .preTitle,
    .text,
    .currency,
    .period,
    .mainFeatures,
    .gtFacilTitle,
    .gtFeature {
      color: #173468;
    }
  }

  &:hover {
    /* transform: scale(1.05); */
    transform: translateY(-12px);
  }
`;

export const CardTitle = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  .preTitle {
    padding-left: 5px;
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.06em;
    font-family: "Montserrat", sans-serif;
    color: #fafafa;
  }
  .text {
    padding-left: 10px;
    font-family: "Yellowtail", cursive;
    font-size: 50px;
    line-height: 32px;
    color: #fff;
    text-align: left;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  height: 43px;
  .currency {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    margin-right: 5px;
  }
  .period {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    color: #fff;
    font-weight: 500;
  }
  * {
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    color: #fff;
    font-weight: 500;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  max-width: 313px;
  width: 100%;
  .button {
    width: 100%;
    height: 57px;
    border-radius: 18px;
    background: linear-gradient(180deg, #2455b2 0%, #102342 100%);
    color: #fff;
    text-align: center;
    font-family: Bebas Neue;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
  }
  margin-bottom: 1rem;
`;

export const YearPlanPrice = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 42px;
  flex-shrink: 0;
  margin-top: 20px;
  > p {
    width: 100%;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

export const ContentList = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  .mainFeaturesWrapper{
    margin-top: 20px;
  }

  .mainFeatures {
    margin-bottom: 5px;
    color: #FFF;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .gtFacilContainer {
    margin-top: 5px;
    display: flex;
    flex-flow: row nowrap;
  }

  .gtFacilTitle {
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    @media screen and (max-width: 600px) {
      font-size: 18px;
    }
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin 0;
    margin-bottom: 10px;
  }

  .gtFacilList {
    padding-left: 10px;
    padding-bottom: 10px;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
    .gtFeature {
      color: #FFF;
      font-family: Montserrat;
      font-size: 17px;
      font-style: normal;
      font-weight: 500;
    }
  }
`;

export const PersonalPlan = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  border-radius: 1.8rem;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
  transition: transform 0.2s ease-in-out;
  width: 100%;
  min-height: 39rem;
  height: 39rem;
  max-width: 77rem;

  @media (max-width: 768px) {
    min-height: 55rem;
    height: 55rem;
  }

  &.customCard {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    *,
    .preTitle,
    .text,
    .currency,
    .period,
    .mainFeatures,
    .gtFacilTitle,
    .gtFeature {
      color: #173468;
    }
  }

  &:hover {
    transform: translateY(-12px);
  }

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr; /* Single column layout */
    grid-template-rows: auto auto; /* Adjust rows as needed */
  }

  .PlanSide {
    background: url("/assets/tubiner-seu-plano.png") lightgray 0% / cover no-repeat;
    background-size: 100% 100%;
    border-radius: 0 18px 18px 0;

    @media screen and (max-width: 768px) {
      background: white;
      border-radius: 0 0 18px 18px;
    }
  }

  .ContentSide {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: left;
    border-radius: 18px 0 0 18px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 40px;
    background: white;

    @media screen and (max-width: 768px) {
      border-radius: 18px 18px 0 0;
      grid-row: 1; /* Place this in the first row */
      grid-column: 1; /* Place this in the first column */
    }

    h2 {
      color: #132a52;
      font-family: "Montserrat";
      font-size: 50px;
      font-style: italic;
      font-weight: 800;
      line-height: normal;

      @media screen and (max-width: 960px) {
        text-align: left;
      }
    }

    p {
      color: #000;
      font-family: Montserrat;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 18px;

      @media screen and (max-width: 960px) {
        text-align: left;
      }

      img {
        width: 27px;
        height: 16px;
        object-fit: contain;
        margin-right: 12px;
      }

      b {
        color: #000;
        font-family: Montserrat;
        font-size: 20px;
        font-style: italic;
        font-weight: 800;
        line-height: normal;
      }
    }
  }
`;

export const TagPlan = styled.div`
  height: 32px;
  background-color: #03c0e9;
  border-radius: 18px;
  color: white;
  font-size: 24px;
  font-family: "Bebas Neue";
  font-weight: 400;
  word-wrap: break-word;
  width: fit-content;
  padding: 0px 30px;
  position: absolute;
  top: -25px;
`;
