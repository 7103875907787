import * as Yup from "yup";

export default Yup.object({
  sendFrom: Yup.string()
    .typeError("Insira um email válido")
    .email("Insira um email válido")
    .required("Campo obrigatório"),
  sendTo: Yup.string()
    .email("Insira um email válido")
    .required("Campo obrigatório"),
  subject: Yup.string().required("Campo obrigatório"),
  message: Yup.string().required("Campo obrigatório"),
});
