import { useHistory, useLocation } from "react-router-dom";

export default () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const user = JSON.parse(localStorage.getItem("GT_user"));
  const ReinfAuthorizedCheck = JSON.parse(localStorage.getItem("GT_ReinfAcess"));
  const clientNavbarItems = [
    {
      id: 1,
      label: "Início",
      name: "cliente",
      link: "/cliente",
    },
    {
      id: 2,
      label: "GT-Fácil",
      name: "gt-facil",
      link: "/gt-facil",
    },
    {
      id: 3,
      label: "GT-Reinf",
      name: "gt-reinf",
      link: "/reinf",
      onClick: () => {
        if (ReinfAuthorizedCheck === false) {
          history.push({
            pathname: "/cliente",
            state: { showModal: true },
          });
        }
      },
    },
    {
      id: 4,
      label: "Conteúdo Selecionado",
      name: "conteudo-selecionado",
      link: pathname,
      subLinks: [
        {
          id: 1,
          link: "/cliente/conteudo-selecionado/legislacao",
          label: "Legislação",
        },
        {
          id: 2,
          link: "/cliente/conteudo-selecionado/artigo",
          label: "Artigos",
        },
        {
          id: 3,
          link: "/cliente/conteudo-selecionado/multimidia",
          label: "Multimídia",
        },
      ],
    },
    {
      id: 5,
      label: "Blog",
      name: "blog",
      link: "/blog",
    },
    {
      id: 6,
      label: "Outros",
      name: "outros",
      link: pathname,
      subLinks: user && [
        ...(["admin" /*, "manager"*/, "superAdmin"].includes(user.role) //apenas admins, até que cadastrei os posts
          ? [
              {
                id: 1,
                link: "/cliente/ajuda",
                label: "Ajuda",
              },
            ]
          : []),
        {
          id: 2,
          link: "/cliente/qso",
          label: "Quadro QSO",
        },
        /*{
          id: 3,
          link: "/cliente/fale-conosco",
          label: "Fale Conosco",
        },*/
        {
          id: 4,
          link: "/cliente/meu-painel?page=1",
          label: "Meu Painel",
        },
        ...(["admin" /*, "manager"*/, "superAdmin"].includes(user.role)
          ? [
              {
                id: 3,
                link: "/admin",
                label: "Admin",
              },
            ]
          : []),
      ],
    },
  ];

  return clientNavbarItems;
};
