import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: max(16px, 1vw);

  > h2 {
    font-size: max(32px, 2vw);
    text-align: center;
  }

  > p {
    text-align: center;
  }

  > span {
    width: 60px;
    height: 3px;
    background: #FE7F1E;
  }
`;
