import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Container, Wrapper, MessageWrapper } from "~/presentation/views/Admin/Email/Reply/styles";
// components
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import MessageReplyForm from "~/presentation/views/Admin/components/MessageReply";
// import {MessageChat} from "~/presentation/components/MessageChat";
import MessageFromToSubject from "~/presentation/components/MessageFromToSubject";
import { useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { CircularProgress } from "@mui/material";

const ReplyEmail = ({ emailUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const id = useParams().id;
  const history = useHistory();
  const [chat, setChat] = useState();

  const fetchChat = async () => {
    const response = await emailUseCases.getEmailChat(id);
    response.messages.forEach((m) => {
      m.datetime = m.time;
      m.sender = response[m.sender];
    });
    setChat(response);
  };

  const handleSubmit = async (values) => {
    backdropOpen(values.draft);
    try {
      await emailUseCases.emailReply(id, values);
    } catch (err) {
      openFeedbackFail(values.draft);
    }
    closeBackdrop();
  };

  const backdropOpen = (draft) => {
    openBackdrop(true, `${draft ? "Salvando" : "Enviando"} email`);
  };

  const closeBackdrop = () => openBackdrop(false);

  const openFeedbackFail = (draft) => {
    setFeedbackSnackbar({
      isOpen: true,
      message: `Erro ao ${draft ? "salvar" : "enviar"} email: missing endpoint`,
      type: "error",
    });
  };

  useEffect(() => {
    fetchChat();
  }, []);

  return (
    <Container>
      <HeaderForm title="Responder Email" backLink="/admin/email" />
      {chat ? (
        <Wrapper className="scrollbar-white">
          <MessageWrapper>
            <MessageFromToSubject
              from={chat.clientEmail}
              to={chat.companyEmail}
              subject={chat.subject}
            />
            {/* <MessageChat messages={chat?.messages} /> */}
          </MessageWrapper>
          <MessageReplyForm draft={chat.draft} handleSubmit={handleSubmit} />
        </Wrapper>
      ) : (
        <div className="absolute-center">
          <CircularProgress color="inherit" />
        </div>
      )}
    </Container>
  );
};

export default ReplyEmail;
