import styled from "styled-components";

const Container = styled.div`
  background: #fafafa;
  position: relative;
  width: 100%;

  > img {
    width: 100%;
  }

  .content {
    padding: max(10px, 9vw) max(10px, 9vw) max(10px, 6vw);
  }

  * {
    font-family: "Montserrat", sans-serif;
    color: #272c33;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 14.625rem;
  padding: 1rem 7rem;
  background-color: #081445;

  > div {
    width: 100%;
    margin: 0 auto;

    > span {
      color: #fff;

      text-align: center;
      font-family: Montserrat;
      font-size: 70px;
      font-style: normal;
      font-weight: 700;
      line-height: 119.4%;
    }
  }
`;

const Title = styled.div`
  font-size: max(24px, 3vw);
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 auto;
  padding: max(40px, 3vw) max(10px, 4vw);
`;

const AuthorAndDate = styled.div`
  font-size: max(18px, 1.4vw);
  padding: max(40px, 3vw) max(10px, 4vw);
  display: flex;
  flex-direction: column;

  > div {
    margin: auto 1% 1%;
  }

  > div:nth-child(1) {
    border-bottom: 1px solid;
    width: fit-content;
  }

  > div:nth-child(2) {
    display: flex;
  }
`;

const Tag = styled.div`
  background: #cf9455;
  color: #fafafa;
  padding: max(2px, 0.1vw) max(10px, 1.2vw);
  border-radius: 10vw;
  margin: 1%;
`;

const Text = styled.div`
  > p {
    font-size: 18px;
    margin-bottom: max(16px, 1vw);
    text-align: justify;
    color: #3a3a3a;
  }

  && strong {
    font-weight: bold;

    & * {
      font-weight: bold;
    }
  }

  @media (max-width: 50rem) {
    > p {
      padding-inline: 0rem;
    }
  }
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-block: 2rem;
  padding-inline: 2rem;
`;

const ImageContainer = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 12px;
  margin-bottom: 1.25rem;
  margin-top: 2rem;

  @media (max-width: 65rem) {
    margin-top: 2rem;
  }
`;

const ContainerSearch = styled.div`
  width: 22.6356rem;

  @media (max-width: 65rem) {
    width: 98%;
    min-width: auto;
  }
`;

const SectionBlue = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1.5rem;
  width: 100%;
  height: 13.5rem;
  background: #2699da;

  @media (max-width: 65rem) {
    justify-content: center;
  }
`;

const Content = styled.div`
  display: flex;

  @media (max-width: 56.25rem) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30rem;
  }
`;

export const PostContentStyles = {
  Container,
  Header,
  Title,
  AuthorAndDate,
  Tag,
  Text,
  VideoContainer,
  SectionBlue,
  ImageContainer,
  Content,
  ContainerSearch,
};
