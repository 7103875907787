import React from "react";

import { QuestionLabel } from "~/presentation/views/GTFacil/Simulator/components/QuestionLabel";
import { QuestionContainer } from "~/presentation/views/GTFacil/Simulator/components/QuestionContainer";
import SelectField from "~/presentation/components/Select";
import { SelectWrapper } from "./styles";

export const SelectQuestion = ({
  text,
  excerpt,
  value,
  onChange,
  options,
  vertical = false,
  menuPlacement,
  disabled,
}) => {
  return (
    <QuestionContainer vertical={vertical}>
      <QuestionLabel text={text} excerpt={excerpt} />
      <SelectWrapper>
        <SelectField
          onChange={onChange}
          options={options}
          field={{ value }}
          padding="4px 16px"
          menuPlacement={menuPlacement}
          placeholder="Selecione"
          disabled={disabled}
        />
      </SelectWrapper>
    </QuestionContainer>
  );
};
