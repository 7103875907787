import styled from "styled-components";
import { transparentize } from "polished";

import {
  List as MuiList,
  ListItem as MuiListItem,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";

export const List = styled(MuiList)`
  position: relative;
  overflow-y: auto;

  .listItemRelativeWrapper {
    position: relative;
  }

  .checkbox {
    position: absolute;
    left: ${(props) => (props.disableGutters ? "0px" : "16px")};
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
`;

export const CircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-root {
    color: ${(props) => "#383f48"};
  }
`;

export const ListItem = styled(MuiListItem)`
  &.MuiListItem-root {
    > div {
      /* position: relative; */
      min-width: 100%;
      width: 100%;
      display: grid;
      gap: max(1vw, 10px);
      align-items: center;
      /* grid-template-columns: min-content 20% auto min-content; */
      grid-template-columns: min-content auto min-content;
      padding-right: 10px;

      @media screen and (min-width: 768px) {
        padding-right: 0;
      }

      /* @media screen and (min-width: 480px) {
        grid-template-columns: min-content 20% auto min-content;
      } */

      .checkbox-spacement {
        width: 38px;
        height: 38px;
      }

      .titleAndSubtitle {
        width: 100%;
        overflow: hidden;

        .title,
        .subtitle {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .subtitle {
          color: ${(props) => transparentize(0.25, "#383f48")};
          font-size: smaller;
        }
      }
    }
  }
`;
