import React from 'react';
import { makeItemAnswerUseCases } from '~/main/factories/use-cases/item-answer';
import { makeTypeAnswerUseCases } from '~/main/factories/use-cases/types-answer';
import ItemAnswerEdit from '~/presentation/views/Admin/ItemAnswer/Edit';

export const makeItemAnswerEdit = (params) => (
    <ItemAnswerEdit
        {...params}
        itemAnswerUseCases={makeItemAnswerUseCases()}
        typesAnswerUseCases={makeTypeAnswerUseCases()}
    />
)