import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;

  .MuiButton-root {
    margin-right: 12px;
    color: "#222222";
  }
`;
