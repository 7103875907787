import styled from "styled-components";
import { Accordion } from "@mui/material";

const Container = styled.div`
  margin-top: 48px;
  display: flex;
  flex-flow: column;
  row-gap: 4px;
`;

const Title = styled.p`
  margin-block: 0;
  font-family: "Roboto", sans-serif;
  font-size: max(16px, 1.2vw);
  text-transform: uppercase;
`;

const Text = styled.div`
  font-size: max(16px, 1.2vw);
  font-weight: 300;

  & strong {
    font-weight: bold;

    & > * {
      font-weight: bold;
    }
  }
`;

const PopoverTrigger = styled.span`
  cursor: pointer;
  color: #0366d6;
  &:hover {
    text-decoration: underline;
  }
`;

const PopoverContent = styled.span`
  display: block;
  padding: 12px;

  & strong {
    font-weight: bold;

    & > * {
      font-weight: bold;
    }
  }
`;

const Link = styled.span`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #cf9455;
  }
`;

const Line = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: currentColor;
  align-self: center;
  margin-right: 8px;
`;

const AccordionComp = styled(Accordion)`
  &.MuiAccordion-root {
    box-shadow: none;
    background-color: #fafafa;

    ::before {
      display: none;
    }
  }

  .MuiAccordionSummary-root {
    color: ${({ expanded }) => (expanded ? "#fafafa" : "#000")};
    background-color: ${({ expanded }) => (expanded ? "#1341a1" : "#fafafa")};
    min-height: 48px;
    border: solid 1px #dedede;
    border-radius: 4px;

    &:hover {
      color: #fafafa;
      background-color: #1341a1;
    }

    &.Mui-expanded {
      box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.17);
      min-height: 48px;
    }

    .MuiAccordionSummary-content.Mui-expanded {
      margin: 12px 0;
    }
  }

  .MuiAccordionDetails-root {
    padding: 16px;
  }
`;

export const AnswerAccordionStyles = {
  Container,
  Title,
  Text,
  PopoverTrigger,
  PopoverContent,
  Link,
  Line,
  AccordionComp,
};
