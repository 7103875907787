import React, { useState } from "react";
import { Container, Grid, Box, Typography as Text } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";

export const PersonForm = () => {
  return (
    <Container maxWidth="xl">
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <Text sx={{ marginBlock: 6, fontSize: 18 }}>Em desenvolvimento...</Text>
        </Grid>
      </Grid>
    </Container>
  );
};
