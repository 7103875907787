import * as Yup from "yup";
import { validarTelefone } from "~/presentation/functions/phoneValidator";

const validation = Yup.object({
  legalName: Yup.string().required("Campo Obrigatório!"),
  cpfCnpj: Yup.string().required("Campo obrigatório!"),
  number: Yup.string().max(20, "Digite um Número válido até 20 caracteres"),
  homePhone: Yup.string("Campo obrigatório!")
    .min(10, "Digite um número válido")
    .test("phone", "Telefone inválido!", (value) => value && validarTelefone(value))
    .required("Campo obrigatório!"),
  email: Yup.string().email("Digite um email válido"),
});

export default validation;
