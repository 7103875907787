import React from "react";
// controller
import MessagesController from "~/presentation/views/Admin/components/MessagesController";
// data
import filtersList from "~/presentation/views/Admin/TaxInformation/List/FilterList.js";
// components
import ListActions from "~/presentation/views/Admin/components/MessageListActions";
import { Container, Box, Grid, Pagination } from "@mui/material";
// material ui
import { FilterList as FilterListIcon } from "@mui/icons-material";
import MessageInfoList from "../../components/MessageInfoList";
import OpenPopover from "~/presentation/components/Popover";
import PageListHeader from "../../components/PageListHeader";

const TaxInformation = ({ taxInformationUseCases }) => {
  const getTaxInformation = async () => {
    return await taxInformationUseCases.getAll();
  };

  const handleDeleteTaxInformation = (ids) => {
    return taxInformationUseCases.deleteTaxInformation(ids);
  };

  const {
    mobile,
    selectedMessages,
    messages,
    setSelectedMessages,
    handleDelete,
    handleRefresh,
    handleSearch,
    numberOfPages,
    page,
    setPage,
    fetchMessagesWithFilter,
    CustomFilterList,
    handleSelectMessage,
    searchValue,
    handleClickMessage,
    filter,
  } = MessagesController({
    getMessages: getTaxInformation,
    filtersList,
    replyMessagePath: "/admin/informacoes-fiscais/responder/",
    onDelete: handleDeleteTaxInformation,
  });

  return (
    <Container maxWidth={false}>
      <PageListHeader title="Informações Fiscais" handleSearch={handleSearch} />
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Box sx={styles.box}>{!mobile && <CustomFilterList />}</Box>
        </Grid>
        <Grid item xs={9}>
          <ListActions
            selectedItems={selectedMessages}
            items={messages}
            onChange={setSelectedMessages}
            handleDelete={handleDelete}
            handleRefresh={handleRefresh}
            renderAfter={
              mobile &&
              (() => (
                <OpenPopover icon={FilterListIcon} label={filter.alias}>
                  {() => <CustomFilterList />}
                </OpenPopover>
              ))
            }
          />
          <Box sx={styles.box}>
            <MessageInfoList
              messages={messages}
              selectedMessages={selectedMessages}
              handleSelectMessage={handleSelectMessage}
              disableGutters={mobile}
              onClick={handleClickMessage}
            />
          </Box>

          <Pagination
            size="small"
            count={numberOfPages}
            page={page}
            onChange={(event, selectedPage) => [
              setPage(page),
              fetchMessagesWithFilter({ page: selectedPage }),
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TaxInformation;

const styles = {
  box: {
    backgroundColor: "#E6E6E6",
    borderRadius: 2,
    height: "100%",
  },
};
