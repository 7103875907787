import React from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import { Container, Form } from "~/presentation/views/Admin/Service/CreateEdit/Form/styles";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
// schema
import validationSchema from "./validationSchema";
// import { ThemeContext } from "styled-components";
import MultiCheckBoxField from "~/presentation/components/MultiCheckBox";
import TextEditor from "~/presentation/components/TextEditor";

const AnnotationForm = ({ handleSubmit, initialValues, cnaes, terms }) => {
  const dataToSelectMapper = (data, valueKey = "id", labelKey = "description") => {
    return data.map((category) => {
      category.value = category[valueKey];
      category.label = category[labelKey];
      return category;
    });
  };

  const mappedCnaes = dataToSelectMapper(cnaes);
  const mappedTerms = dataToSelectMapper(terms, "id", "term");

  const defaultCnaes = !!initialValues.cnaes && dataToSelectMapper(initialValues.cnaes);
  const defaultTerms =
    !!initialValues.terms && dataToSelectMapper(initialValues.terms, "id", "term");

  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          const submitValues = {
            id: values.id,
            name: values.name,
            text: values.text,
            cnaes:
              values.cnaes?.map((item) => {
                let cnae = {
                  id: item.id,
                  code: item.code,
                  description: item.description,
                  serviceCodes: item.serviceCodes,
                };
                return cnae;
              }) || null,
            terms:
              values.terms?.map((term) => {
                return {
                  id: term.id,
                  term: term.term,
                  annotation: term.annotation || "",
                  synonym: term.synonym || "",
                };
              }) || null,
          };
          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <h2>Anotação</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <Field name="name" type="text" label="Nome *" as={TextInput} />
                <ErrorMessage name="name" />
              </div>
              <div className="col-1_5">
                <Field
                  name="cnaes"
                  placeholder="Cnaes"
                  component={MultiCheckBoxField}
                  allowSelectAll={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  defaultValue={defaultCnaes}
                  value={values.cnaes}
                  isMulti={true}
                  options={mappedCnaes}
                  onChange={(option) => setFieldValue("cnaes", option)}
                  getOptionLabel={(option) => {
                    if (option.value === "*") {
                      return "Selecionar todos";
                    }
                    if (
                      option.detailCode !== null &&
                      option.detailCode !== "" &&
                      option.detailCode !== undefined
                    ) {
                      return `${option.code || 0} (${option.detailCode}) - ${
                        option.description || option.label
                      }`;
                    }
                    return `${option.code || 0} - ${option.description || option.label}`;
                  }}
                />
              </div>
              <div className="col-1_5">
                <Field
                  name="terms"
                  placeholder="Termos de Pesquisa"
                  component={MultiCheckBoxField}
                  allowSelectAll={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  defaultValue={defaultTerms}
                  value={values.terms}
                  isMulti={true}
                  options={mappedTerms}
                  onChange={(termOption) => setFieldValue("terms", termOption)}
                  getOptionLabel={(option) => {
                    if (option.value === "*") {
                      return "Selecionar todos";
                    }
                    return option.term || option.label;
                  }}
                />
              </div>
              <div className="col-1_5">
                <TextEditor
                  initialValue={values.text}
                  name="text"
                  height={400}
                  handleEditorChange={(content) => {
                    setFieldValue("text", content);
                  }}
                />
                <ErrorMessage name="text" />
              </div>
            </div>
            <FormSubmitButtonsDock>
              <PrimaryButton className="submit-btn" type="submit">
                Salvar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AnnotationForm;
