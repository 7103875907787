import React from "react";
import {HashLink} from "react-router-hash-link";

const scrollWithOffset = (
  el,
  _scrollableElement,
  offset = -75,
  mobile,
  behavior = "smooth",
) => {
  if (mobile) el.scrollIntoView({behavior});
  else {
    const element = window;
    const yCoordinate = el.getBoundingClientRect().top + element.pageYOffset;
    const yOffset = offset;
    const scrollAmount = yCoordinate + yOffset;

    element.scrollTo({
      top: scrollAmount,
      behavior,
    });
  }
};

const NavHashLink = ({
  offset,
  mobile,
  scrollableElement,
  behavior,
  ...props
}) => (
  <HashLink
    {...props}
    scroll={(el) =>
      scrollWithOffset(el, scrollableElement, offset, mobile, behavior)
    }
  />
);

export default NavHashLink;
