import React from "react";
import MUIDataTable from "mui-datatables";
import { Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Table = ({ columns, data, searchRef, tableOptions = true, errorMsg = false }) => {
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            tableRoot: {
              borderRadius: "10px",
              overflow: "hidden", // borders arredondadas na tabela
            },
            tableBodyMaxHeight: {
              maxHeight: "calc(100% - 53px) !important",
              "&::-webkit-scrollbar": {
                width: "15px",
                backgroundColor: "#1341A1",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#D9D9D9",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                backgroundColor: "#a1a1a1",
              },
              "&::-webkit-scrollbar-thumb:hover ": {
                backgroundColor: "#D9D9D9",
              },
            },
          },
        },
        MUIDataTableBodyRow: {
          styleOverrides: {
            root: {
              "&.Mui-selected, &.Mui-selected:hover": {
                backgroundColor: "#F3F3F3", // cor das linhas ativas na seleção
              },
              borderBlock: "2px solid #CDCDCD",
              "&:hover": {
                background: "#000000",
              },
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            body: {
              background: "#E6E6E6",
              fontSize: 14,
              padding: 6,
              color: "#000",
              alignItems: "center",
              textAlign: "left",
              borderBottom: "5px solid #fff",
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              "&[data-testid='Search-iconButton']": {
                display: "none",
              },
            },
          },
        },

        MUIDataTableHeadCell: {
          styleOverrides: {
            //header
            root: {
              "& .MuiButtonBase-root": {
                justifyContent: "flex-start", // Aligns the button content to the left
                textAlign: "left",
              },
              textAlign: "left",
              button: {
                textTransform: "none", // Remover todas as letras maiúsculas
                padding: "0px",
                width: "100%",
                alignItems: "center",
                textAlign: "left",
                fontSize: 13,
                "&:hover": {
                  background: "transparent",
                },
              },
            },
            sortActive: {
              color: "#ffffff !important", // Color when sorted
            },
            fixedHeader: {
              color: "#ffffff !important", //cor texto header
              backgroundColor: "#001a50", //cor header
              padding: "13px 10px ", //tamanho cada "box" header
              cursor: "pointer",
              textAlign: "left", // Ensures the text aligns to the left
              "& .MuiTableSortLabel-root": {
                justifyContent: "flex-start", // Ensures the sort label aligns to the left
              },
            },
          },
        },

        MuiTableSortLabel: {
          styleOverrides: {
            root: {
              //header quando sorted
              width: "100%",
              textAlign: "left !important",
              "&.Mui-active .MuiTableSortLabel-icon": {
                //cor seta de sort header
                color: "#ffffff !important",
              },
            },
          },
        },

        MUIDataTableSelectCell: {
          styleOverrides: {
            root: {
              display: "none",
              "&:hover": {
                background: "#fff",
              },
            },
            headerCell: {
              background: "#F1F1F1 !important",
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            root: {
              color: "inherit",
              border: "none",
            },
            toolbar: {
              marginRight: "3rem",
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              display: !tableOptions ? "none" : "block",
              "& .MuiTypography-root": {
                display: "none", // Oculta o título
              },
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            icon: {
              color: "inherit",
            },
          },
        },
      },
    });

  const customSearcher = (searchText, handleSearch) => (
    <input
      style={{ display: "none" }}
      ref={searchRef}
      type="text"
      onChange={(event) => handleSearch(event.target.value)}
    />
  );

  return (
    <Box
      sx={{
        height: "calc(100% - 40px - 12px)",
        overflow: "hidden",
      }}
    >
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={"Do not remove the title"} // it seems that the library uses this title to reference elements. Removing the title creates a bug in the searchField.
          data={data}
          columns={columns}
          options={{
            filterType: "dropdown",
            responsive: "standard",
            isRowSelectable: false,
            viewColumnsChange: false,
            searchOpen: true, //essa props precisa ser true para a customSearcher funcionar
            print: tableOptions,
            download: tableOptions,
            filter: tableOptions,
            viewColumns: tableOptions,
            sort: true,
            sortDirection: "desc",
            customSearchRender: customSearcher,
            pagination: data?.length < 10 ? false : true,
            paginationRowsPerPageOptions: [10, 20, 50], // Opções de linhas por página
            paginationRowsPerPage: 10, // Padrão de linhas por página
            textLabels: {
              body: {
                noMatch: errorMsg || "Nenhum registro encontrado!",
              },
              pagination: {
                next: "Próxima página",
                previous: "Página Anterior",
                rowsPerPage: "Linhas por página",
                displayRows: "de",
              },
            },
          }}
        />
      </ThemeProvider>
    </Box>
  );
};

export default Table;
