import React, { useContext, useState, useEffect } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import {
  Container,
  Modal,
  Box,
  Grid,
  Typography as Text,
  MenuItem,
  Tooltip,
  Paper,
} from "@mui/material";
import { ApiDigitalCertUseCases } from "~/data/usecases/DigitalCert/apiDigitalCert";
import { EventSend } from "~/presentation/views/Reinf/SendReinf/components/EventSend/EventSend";
import { PriorityHigh } from "@mui/icons-material";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { HeaderSectionPage } from "~/presentation/views/Reinf/components/HeaderSectionPage/";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import {
  getMonths,
  getYears,
  getEFDReinfEvents,
  validateDocumentsBasedOnEFDReinfEvent,
} from "~/presentation/views/Reinf/Utils/utilsReinf";
import { getMonth, getLastDayOfMonth } from "~/presentation/views/Reinf/Utils/utilsDate";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import ActiveFilters from "./components/ActiveFilters/ActiveFilters";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import SentTableEvents from "./components/SentTable/SentTableEvents";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import SendR2099 from "./components/EventSend/SendR2099";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import DocTable from "./DocTable";
import ModalContainer from "../components/ModalContainer/ModalContainer";
import { DialogContext } from "~/presentation/providers/DialogProvider";

export const SendReinf = () => {
  const apiNfse = new ApiNfseUseCases(makeHttpClient());
  const apiReinf = new ApiReinfUseCases(makeHttpClient());
  const apiDigitalCert = new ApiDigitalCertUseCases(makeHttpClient());
  const { openBackdrop } = useContext(BackdropContext);
  const { openDialogBox } = useContext(DialogContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO

  //DATA
  const [currentNfseData, setCurrentNfseData] = useState([]);
  const [currentEventsData, setCurrentEventsData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  //ERROS CAMPOS
  const [errors, setErrors] = useState({});

  // OPTIONS
  const [allCertificates, setAllCertificates] = useState([]);
  const [isR2099, setIsR2099] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);

  // Filters
  const [filter, setFilter] = useState({
    certificate: "",
    month: "",
    year: "",
    event: "",
  });

  const handleChangeFilter = (updates) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...updates,
    }));
  };

  // Filters when clicked!
  const [sendFilter, setSendFilter] = useState({
    certificate: "",
    month: "",
    year: "",
    event: "",
  });

  //TABLE SELECTED
  const [tableType, setTableType] = useState("sendDoc");

  // VALID / INVALID NSFE'S
  const [validNfseData, setValidNfseData] = useState([]);
  const [invalidNfseData, setInvalidNfseData] = useState([]);

  // Status do Evento
  const [isEventClosed, setIsEventClosed] = useState(false);

  // OPEN TABLE
  const [openEventSendModal, setOpenEventSendModal] = useState(false);
  //
  //current time
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  //Refresh para a nota que é alterada ir para o array de apta ou inapata
  const refreshData = () => {
    setFilters();
  };

  //ERRO: Data inválida (FUTURO)
  const isSelectedYearCurrent = filter?.year === currentYear;
  const isDateInTheFuture = isSelectedYearCurrent && filter?.month > currentMonth;

  const fetchNfseData = async (data) => {
    const nfse = await apiNfse.getNfse(data);
    if (nfse.status === 200) {
      const apiNfseData = nfse.data;
      return apiNfseData;
    } else {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos documentos, verifique sua conexão",
        type: "error",
      });
    }
  };

  const fetchEventsData = async (data) => {
    const events = await apiReinf.getReinfProtocol(data);
    if (events.status === 200) {
      const eventsData = events.data;
      return eventsData;
    } else {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos eventos, verifique sua conexão",
        type: "error",
      });
    }
  };

  const handleClearDataAndOpenModal = () => {
    setOpenEventSendModal(true);
    handleFiltersNfseData([]);
    setCurrentEventsData([]);
    setDisplayTable(false);
  };

  const handleVerifyEventStatus = async (currentSelectedDate, currentSelectedEvent) => {
    const verifyStatusData = {
      companyId: JSON.parse(localStorage.getItem("GT_user") || "{}")?.companyId,
      calculationDate: currentSelectedDate,
    };

    setIsEventClosed(await apiReinf.verifyClosedPeriod(verifyStatusData, currentSelectedEvent));
  };

  const setFilters = () => {
    openBackdrop(true, "Aplicando filtros");

    const period = {
      start: `01 ${getMonth(filter?.month)} ${filter?.year}`,
      end: `${getLastDayOfMonth(filter?.year, filter?.month)} ${getMonth(
        filter?.month
      )} ${filter?.year}`,
    };

    // ⇣⇣⇣ params para o fetch dos documentos
    const paramsNfse = {
      ...period,
      isDateOfPayment: filter?.event === "r4020",
    };

    // ⇣⇣⇣ params para o fetch dos eventos
    const paramsEvent = {
      ...period,
      ...(filter?.event === "r2010"
        ? { reinfEvent: 3 }
        : filter?.event === "r4020"
          ? { reinfEvent: 10 }
          : {}),
    };

    if (filter?.event === "r2099") {
      handleApplyFiltersR099(period);
    }

    setSendFilter(filter);

    handleApplyFilters(paramsNfse, paramsEvent);
  };

  const handleApplyFilters = async (paramsNfse, paramsEvent) => {
    await handleVerifyEventStatus(`${filter?.year}-${filter?.month}-01`, filter?.event);
    setIsR2099(false);

    switch (filter?.event) {
      case "r2098":
        handleClearDataAndOpenModal();
        openBackdrop(false);
        return;
      case "r4099":
        handleClearDataAndOpenModal();
        openBackdrop(false);
        return;
      case "r2099":
        setDisplayTable(false);
        openBackdrop(false);
        return setIsR2099(true);
      case "r9000":
        setTableType("sent");
        break;
      default:
        break;
    }

    setDisplayTable(true);

    try {
      openBackdrop(true, "Carregando informação dos docs. fiscais");
      const responseNfse = await fetchNfseData(paramsNfse);
      handleFiltersNfseData(responseNfse);
      openBackdrop(true, "Carregando informação dos eventos");
      const responseEvents = await fetchEventsData(paramsEvent);
      handleFilterEventsData(responseEvents);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos dados, verifique sua conexão",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handleApplyFiltersR099 = async (period) => {
    const paramsR2010 = {
      ...period,
      reinfEvent: 3,
    };
    const events2010 = await fetchEventsData(paramsR2010);

    const paramsR4020 = {
      ...period,
      reinfEvent: 10,
    };
    const events4020 = await fetchEventsData(paramsR4020);
    var events = new Array(...events2010, ...events4020);
    setCurrentEventsData(events);
    openBackdrop(false);
  };

  // ⇣⇣⇣ 1ºRender -> Padrão: Selecionado o 1º certificado e ano atual
  useEffect(() => {
    const firstRender = async () => {
      openBackdrop(true, "Carregando");
      const certs = await getCertificates();
      setAllCertificates(certs);
      const firstCert = certs[0]?.id || "";
      handleChangeFilter({
        certificate: firstCert,
        year: currentYear,
      });
      openBackdrop(false);
    };

    firstRender();
  }, []);

  const getCertificates = async () => {
    const CertsData = await apiDigitalCert.getDigitalCerts();
    return CertsData;
  };

  const yearsOptions = getYears(currentYear - 8); //últimos 8 anos
  const monthsOptions = getMonths();
  const eventsOptions = getEFDReinfEvents();

  // ⇣⇣⇣ abrir e fechar modal de envio de documentos
  const handleOpen = () => setOpenEventSendModal(true);
  const handleClose = () => setOpenEventSendModal(false);

  const handleFiltersNfseData = (data) => {
    let filteredData = data;

    // ⇣⇣⇣ Na tabela de enviar/corrigir não podem aparecer documentos já enviadas
    filteredData = filteredData.filter((item) => !item["isR2010"] && !item["isR4020"]);

    if (filter?.event) {
      if (filter?.event === "r2010") {
        filteredData = filteredData.filter((item) => item["typeDoc"] !== 1);
      }

      // ⇣⇣⇣ validar documentos aptas / inaptas
      const event = filter?.event === "r2010" ? "r2010" : `r4020${QSO}`;
      filteredData = validateDocumentsBasedOnEFDReinfEvent(event, filteredData);

      const validDocumentsList = filteredData.filter((item) => item.isReady);
      const invalidDocumentsList = filteredData.filter((item) => !item.isReady);

      setValidNfseData(validDocumentsList);
      setInvalidNfseData(invalidDocumentsList);

      if (tableType === "sendDoc") {
        setCurrentNfseData(validDocumentsList);
        return;
      }

      setCurrentNfseData(invalidDocumentsList);
      return;
    }
    setCurrentNfseData(filteredData);
  };

  const handleFilterEventsData = (data) => {
    let filteredData = data;

    const possibleEventsToExclude = ["R2010", "R2020", "R2070", "R4010", "R4020", "R4040", "R4080"];

    if (filter?.event === "r9000") {
      filteredData = filteredData.filter((item) =>
        possibleEventsToExclude.includes(item.reinfEvent)
      );
    }

    setCurrentEventsData(filteredData);
  };

  //pagina tabela importante ser aqui para colocar em 0 ao alterar a tabela
  const [page, setPage] = useState(0);
  // ⇣⇣⇣ Para alterar a tabela que está a ser mostrada ao clicar no botão e alterar o tableType
  useEffect(() => {
    //sempre que se altera a tabela a pagina tem de voltar a 0
    setPage(0);
    setSelectedRows([]);
    switch (tableType) {
      case "sendDoc":
        setCurrentNfseData(validNfseData);
        break;
      case "correctDoc":
        setCurrentNfseData(invalidNfseData);
        break;
      case "sent":
        setCurrentNfseData([]);
        break;
      default:
        setCurrentNfseData(currentNfseData);
    }
  }, [sendFilter?.event, tableType]);

  const handleClearFilters = () => {
    const defaultFilters = { certificate: "", month: "", year: "", event: "" };
    handleChangeFilter(defaultFilters);
    setSendFilter(defaultFilters);
    setIsR2099(false);
  };

  useEffect(() => {
    setCurrentNfseData((prevState) =>
      prevState.map((nfse) => ({
        ...nfse,
        hasEventSentWithSameCnpj: !!currentEventsData.some(
          (event) => event.tag === nfse.businessPartnerCNPJ
        ),
      }))
    );
  }, [currentEventsData, tableType]);

  const handleChangeFieldValue = (props) => {
    const { newItem, index, field, byPass } = props;
    //campo hadChanges serve para mostrar ao usuário que a nota teve alterações
    //byPass é para useEffect (mudanças automaticas) não ativar o hadChanges
    let data = {};
    if (byPass) {
      data = { ...newItem };
    } else {
      data = { ...newItem, hadChanges: true };
    }
    setCurrentNfseData((prev) => {
      let updatedItems = [...prev];
      updatedItems[index] = {
        ...currentNfseData[index],
        ...data,
      };
      if (field) {
        clearError(`[${index}].${field}`);
      }
      return updatedItems;
    });
  };

  const handleChangeINSSfield = (props) => {
    const { newItem, parentIndex, nestedIndex, field, byPass } = props;
    //campo hadChanges serve para mostrar ao usuário que a nota teve alterações
    //byPass é para useEffect (mudanças automaticas) não ativar o hadChanges
    let updatedNfse = {};
    if (byPass) {
      updatedNfse = { ...currentNfseData[parentIndex] };
    } else {
      updatedNfse = { ...currentNfseData[parentIndex], hadChanges: true };
    }
    const updatedItemINSS = [...updatedNfse.itemsNFse];
    updatedItemINSS.splice(nestedIndex, 1, newItem);
    updatedNfse.itemsNFse = updatedItemINSS;
    setCurrentNfseData((prev) => {
      let updatedItems = [...prev];
      updatedItems[parentIndex] = updatedNfse;
      if (field) {
        clearError(`[${parentIndex}].itemsNFse[${nestedIndex}].${field}`);
      }
      return updatedItems;
    });
  };

  //Função para tirar o erro do campo quando ele começa a digitar
  const clearError = (field) => {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleSetDocReadyToSend = () => {
    const text =
      selectedRows.length === 1 ? "o documento" : "os " + selectedRows.length + " documentos";

    const selectedRowsIDs = selectedRows.map((item) => item.id);
    openDialogBox({
      message: `Deseja habilitar ${text} para envio?`,
      callback: () => {
        setValidNfseData((prev) => {
          let updatedData = [...prev];
          const newData = selectedRows.map((nfse) => {
            return { ...nfse, isReady: true };
          });
          updatedData = [...updatedData, ...newData];
          return updatedData;
        });
        setInvalidNfseData((prev) => {
          let prevData = [...prev];
          const filteredData = prevData.filter((item) => !selectedRowsIDs.includes(item.id));
          return filteredData;
        });
        setTableType("sendDoc");
      },
    });
  };

  const handleRenderByEvent = () => {
    if (isR2099) {
      return (
        <SendR2099
          filters={sendFilter}
          certificateData={allCertificates}
          isEventClosed={isEventClosed}
          handleVerifyEventStatus={handleVerifyEventStatus}
          currentEventsData={currentEventsData}
        />
      );
    }

    return (
      <>
        {displayTable && (
          <Grid container spacing={2} sx={{ padding: 3 }}>
            {sendFilter?.event !== "r9000" && (
              <>
                <Grid item xs={4}>
                  <RButton
                    onClick={() => {
                      setTableType("sendDoc");
                    }}
                    color={tableType === "sendDoc" ? "primary" : "secondary"}
                    variant={tableType === "sendDoc" ? "contained" : "outlined"}
                    sx={{
                      height: "60px",
                      border: tableType === "sendDoc" ? "1px solid #142F68" : "1px solid #CCCCCC",
                    }}
                  >
                    ENVIAR DOCUMENTO ({validNfseData.length})
                  </RButton>
                </Grid>
                <Grid item xs={4}>
                  <RButton
                    onClick={() => {
                      setTableType("correctDoc");
                    }}
                    color={tableType === "correctDoc" ? "primary" : "secondary"}
                    variant={tableType === "correctDoc" ? "contained" : "outlined"}
                    sx={{
                      height: "60px",
                      border:
                        tableType === "correctDoc" ? "1px solid #142F68" : "1px solid #CCCCCC",
                    }}
                  >
                    CORRIGIR INAPTAS PARA ENVIAR ({invalidNfseData.length})
                  </RButton>
                </Grid>
                <Grid item xs={4}>
                  <RButton
                    onClick={() => {
                      setTableType("sent");
                    }}
                    color={tableType === "sent" ? "primary" : "secondary"}
                    variant={tableType === "sent" ? "contained" : "outlined"}
                    sx={{
                      height: "60px",
                      border: tableType === "sent" ? "1px solid #142F68" : "1px solid #CCCCCC",
                    }}
                  >
                    ENVIADAS ({currentEventsData.length})
                  </RButton>
                </Grid>
              </>
            )}
          </Grid>
        )}
        {tableType === "correctDoc" && (
          <Box sx={{ maxWidth: "350px", marginLeft: 3, marginRight: 3 }}>
            <RButton onClick={handleSetDocReadyToSend} disabled={selectedRows.length === 0}>
              Habilitar Doc. para Envio
            </RButton>
          </Box>
        )}

        {sendFilter?.event && displayTable ? (
          <Box>
            {tableType === "sendDoc" || tableType === "correctDoc" ? (
              <DocTable
                data={currentNfseData}
                selectedEvent={sendFilter?.event}
                tableType={tableType}
                refreshData={refreshData}
                isEventClosed={isEventClosed}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                handleChangeFieldValue={handleChangeFieldValue}
                handleChangeINSSfield={handleChangeINSSfield}
                errors={errors}
                setErrors={setErrors}
                page={page}
                setPage={setPage}
              />
            ) : tableType === "sent" ? (
              <SentTableEvents
                certificateData={allCertificates}
                filter={sendFilter}
                refreshData={refreshData}
                currentEventsData={currentEventsData}
                validNfseData={validNfseData}
                isEventClosed={isEventClosed}
              />
            ) : (
              <p>Carregando...</p>
            )}
          </Box>
        ) : (
          <Grid container justifyContent="center">
            <Box sx={{ width: "40%", mt: 2 }}>
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 2,
                  borderRadius: 4,
                }}
              >
                <ReportProblemIcon sx={{ fontSize: 80, color: "#FFCC00", marginBlock: 1 }} />
                <Text
                  sx={{
                    textAlign: "center",
                    fontSize: 20,
                    fontWeight: 400,
                    marginBlock: 0,
                    paddingBlock: 2,
                  }}
                >
                  Para visualizar os documentos/eventos, selecione os <b>filtros</b>!
                </Text>
              </Paper>
            </Box>
          </Grid>
        )}
        <Container maxWidth={false} sx={{ paddingBlock: 2, display: "flex" }}>
          {tableType === "sendDoc" &&
            (!filter?.year || !filter?.month || !filter?.event || !filter?.certificate) && (
              <Text sx={{ mt: 2, display: "flex", alignItems: "center" }} color="error">
                <PriorityHigh />
                Para enviar selecione certificado, mês, ano e evento.
              </Text>
            )}
          {isEventClosed && (
            <Text sx={{ mt: 2, display: "flex", alignItems: "center" }} color="error">
              <PriorityHigh />
              {`Período ${filter?.month.toString().length === 1 ? `0${filter?.month}` : filter?.month}/${filter?.year} está fechado. Para fazer modificações no período é necessário o envio de um evento de REABERTURA.`}
            </Text>
          )}
          {tableType === "sendDoc" && (
            <RButton
              sx={styles.buttonSend}
              onClick={handleOpen}
              disabled={selectedRows.length === 0 || !displayTable || isEventClosed}
            >
              Enviar
            </RButton>
          )}
        </Container>
      </>
    );
  };

  return (
    <>
      <HeaderSectionPage title="Envio da EFD-Reinf" />
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 2,
          marginBottom: 1,
          paddingInline: 2,
        }}
      >
        <Grid item>
          <Tooltip title="Apagar todos os filtros" onClick={handleClearFilters}>
            <IconButton>
              <DeleteOutlineIcon sx={{ color: "#000000" }} fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item sx="auto">
          <ActiveFilters
            allCertificates={allCertificates}
            filter={filter}
            handleChangeFilter={handleChangeFilter}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          paddingBlock: 1,
          paddingInline: 3,
          display: "flex",
          alignItems: "end",
          justifyContent: "space-evenly",
        }}
      >
        <Grid item xs={12} sm={4} md={2.4}>
          <RButton
            type="filterSelect"
            title="Selecionar certificado"
            placeholder="Selecione o certificado digital"
            value={filter?.certificate}
            onChange={(event) => {
              handleChangeFilter({
                certificate: event.target.value,
              });
            }}
          >
            {allCertificates?.map((cert) => (
              <MenuItem key={cert.id} value={cert.id}>
                {cert.name}
              </MenuItem>
            ))}
          </RButton>
        </Grid>
        <Grid item xs={12} sm={4} md={2.4}>
          <RButton
            type="filterSelect"
            title="Mês"
            placeholder="Selecione o mês"
            value={filter?.month}
            onChange={(event) => {
              handleChangeFilter({ month: event.target.value });
            }}
            error={isDateInTheFuture}
            errorText="Escolha uma data no presente!"
          >
            {monthsOptions?.map((mounth) => (
              <MenuItem key={mounth.value} value={mounth.value}>
                {mounth.value} - {mounth.label}
              </MenuItem>
            ))}
          </RButton>
        </Grid>
        <Grid item xs={12} sm={4} md={2.4}>
          <RButton
            type="filterSelect"
            title="Ano"
            placeholder="Selecione o ano"
            value={filter?.year}
            onChange={(event) => {
              handleChangeFilter({ year: event.target.value });
            }}
          >
            {yearsOptions?.map((year) => (
              <MenuItem key={year.value} value={year.value}>
                {year.label}
              </MenuItem>
            ))}
          </RButton>
        </Grid>
        <Grid item xs={12} sm={4} md={2.4}>
          <RButton
            type="filterSelect"
            title="Evento a enviar"
            placeholder="Selecione o evento"
            value={filter?.event}
            onChange={(event) => {
              handleChangeFilter({ event: event.target.value });
            }}
          >
            {eventsOptions?.map((event) => (
              <MenuItem key={event.value} value={event.value}>
                {event.label}
              </MenuItem>
            ))}
          </RButton>
        </Grid>
        <Grid item xs={12} sm={4} md={2.4}>
          <RButton
            disabled={!filter?.year || !filter?.month || !filter?.event || isDateInTheFuture}
            onClick={setFilters}
          >
            APLICAR
          </RButton>
        </Grid>
      </Grid>
      {handleRenderByEvent()}
      <ModalContainer open={openEventSendModal} onClose={handleClose}>
        <EventSend
          data={selectedRows}
          filter={sendFilter}
          certificateData={allCertificates}
          closeModal={handleClose}
          refreshData={refreshData}
          handleVerifyEventStatus={handleVerifyEventStatus}
        />
      </ModalContainer>
    </>
  );
};

const styles = {
  title: {
    fontSize: 25,
    fontWeight: 600,
    letterSpacing: 0,
    color: "#242424",
    fontFamily: "Montserrat, sans-serif",
  },
  select: {
    minWidth: "100%",
    backgroundColor: "#E6E6E6",
  },
  buttonSend: {
    marginLeft: "auto",
    paddingBlock: 1.5,
    paddingInline: 12,
    width: "200px",
  },
  ButtonBox: {
    position: "fixed",
    zIndex: 999,
    right: "10px",
    display: "flex",
    flexDirection: "column",
    gap: 1,
    bottom: "280px",
    background: "white",
    borderRadius: "10px",
    padding: 1,
    border: "1px solid red",
  },
};
