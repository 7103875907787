import styled from "styled-components";

const colorFunc = (theme, color) => {
  return color === "white" ? "#fafafa" : "#1341a1";
};

export const Container = styled.div`
  width: 276px;
  height: 45px;
  flex-shrink: 0;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: #FF6829;
    border-radius: 4px;
    color: white;
    font-size: 24px;
    font-family: "Bebas Neue";
    font-weight: 400;
    overflow-wrap: break-word;
    width: fit-content;
    padding: 0px 30px;
    position: absolute;
    top: -25px;
  }
  
  h2 {
    color: #FFF;
    font-family: Bebas Neue;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
  }
  }
`;
