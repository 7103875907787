import styled from "styled-components";
import { Pagination as MuiPagination } from "@mui/material";
import { Inbox } from "@mui/icons-material";

export const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

export const ItemsWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-row-gap: ${({ rowGap }) => {
    return rowGap == 1
      ? "max(1vw, 8px)"
      : rowGap == 2
        ? "max(2vw, 16px)"
        : rowGap == 3
          ? "max(3vw, 32px)"
          : "0";
  }};
  grid-template-columns: 100%;

  @media screen and (min-width: 600px) {
    grid-template-columns: 70%;
  }

  @media screen and (min-width: 960px) {
    grid-template-columns: ${({ maxColumns }) => `repeat(${maxColumns || 1}, 1fr)`};
    grid-column-gap: ${({ columnGap }) => {
      return columnGap == 1
        ? "max(1vw, 8px)"
        : columnGap == 2
          ? "max(2vw, 16px)"
          : columnGap == 3
            ? "max(4vw, 32px)"
            : "0";
    }};
  }
`;

export const Pagination = styled(MuiPagination)`
  margin-top: 30px;
  align-self: center;
  padding: 30px;

  &.MuiPagination-root {
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiPaginationItem-root {
      color: inherit;

      &.Mui-selected {
        background-color: #1341a1;
        color: #fafafa;
      }
    }
  }
`;

export const Loading = styled.div`
  grid-column: 1/4;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NoPostsFound = styled.div`
  justify-self: center;
  display: flex;
  align-items: center;
  font-size: max(18px, 1.4vw);
  gap: max(16px, 1.2vw);
  margin: max(36px, 5vw) 0;
  padding: max(80px, 8vw) 0;
  flex-flow: column;
  text-align: center;

  @media screen and (min-width: 768px) {
    flex-flow: row;
  }

  @media screen and (min-width: 960px) {
    grid-column: 1/4;
  }
`;

export const InboxIcon = styled(Inbox)`
  &.MuiSvgIcon-root {
    fill: #1341a1;
  }
`;
