import styled from "styled-components";

export const Container = styled.div`
  z-index: 2;
  font-family: "Montserrat";
  min-height: 80px;
  height: fit-content;
  background: linear-gradient(180deg, #1341a1 0%, #000 100%);
  color: #fafafa;
  padding: 32px 6%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  row-gap: 16px;
  column-gap: 32px;

  @media screen and (min-width: 768px) {
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  }

  .text-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    span {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.06em;
      font-weight: 300;
      text-align: center;
      margin: 0 4px;
      font-weight: 400;

      &.light {
        font-weight: 200;
      }
    }
  }

  > img {
    width: 36px;
    height: 37px;
    margin: 0 36px;
  }
`;
