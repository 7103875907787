import React, { useContext, useEffect, useState } from "react"; // packages
// packages
import { Formik, Field } from "formik";
import { useHistory } from "react-router-dom";
// components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import TextEditor from "~/presentation/components/TextEditor";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import { Container, Form } from "~/presentation/views/Admin/Email/New/styles";
import AutocompleteSelect from "~/presentation/components/AutocompleteSelect";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
//context
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// schema
import validationSchema from "~/presentation/views/Admin/Email/New/validationSchema";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";

const NewEmailForm = ({ emailUseCases }) => {
  const history = useHistory();
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [GTEmails, setGTEmails] = useState([]);

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados");
    const response = await emailUseCases.getEmailAddresses();
    setGTEmails(response);
    closeBackdrop();
  };

  const handleSubmit = async (values) => {
    backdropOpen(values.draft);
    try {
      await emailUseCases.submitNewEmail(values);
    } catch (err) {
      openFeedbackFail(values.draft);
    } finally {
      closeBackdrop();
    }
  };

  const backdropOpen = (draft) => {
    openBackdrop(true, `${draft ? "Salvando" : "Enviando"} email`);
  };

  const closeBackdrop = () => openBackdrop(false);

  const openFeedbackFail = (draft) => {
    setFeedbackSnackbar({
      isOpen: true,
      message: `Erro ao ${draft ? "salvar" : "enviar"} email: missing endpoint`,
      type: "error",
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <HeaderForm title="Novo Email" backLink="/admin/email" />
      <Container className="scrollbar-white">
        <Formik
          initialValues={{
            sendFrom: "",
            sendTo: "",
            subject: "",
            message: "",
            draft: false,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, action) => {
            action.setSubmitting(true);
            await handleSubmit(values);
            action.setSubmitting(false);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="form-grid">
                <div className="col-1_5">
                  <Field
                    autocomplete="off"
                    name="sendFrom"
                    label="Email Rementente"
                    component={AutocompleteSelect}
                    value={values.sendFrom}
                    options={GTEmails}
                    onChange={(event, newValue) => {
                      setFieldValue("sendFrom", newValue);
                    }}
                  />

                  <ErrorMessage name="sendFrom" />
                </div>

                <div className="col-1_5">
                  <Field name="sendTo" type="text" label="Para" as={TextInput} />
                  <ErrorMessage name="sendTo" />
                </div>

                <div className="col-1_5">
                  <Field name="subject" type="text" label="Assunto" as={TextInput} />
                  <ErrorMessage name="subject" />
                </div>

                <div className="col-1_5">
                  <TextEditor
                    initialValue={values.message}
                    name="message"
                    height={400}
                    handleEditorChange={(content) => {
                      setFieldValue("message", content);
                    }}
                  />
                  <ErrorMessage name="message" />
                </div>
              </div>

              <FormSubmitButtonsDock>
                <PrimaryButton
                  className="draft"
                  type="submit"
                  onClick={() => setFieldValue("draft", true)}
                >
                  Salvar como rascunho
                </PrimaryButton>
                <PrimaryButton type="submit" onClick={() => setFieldValue("draft", false)}>
                  Enviar
                </PrimaryButton>
              </FormSubmitButtonsDock>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default NewEmailForm;
