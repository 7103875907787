import styled from "styled-components";
import {
  Button as MuiButton,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from "@mui/material";

export const DialogContent = styled(MuiDialogContent)`
  flex-flow: column;
  color: ${(props) => "#383f48"};

  .message {
    margin-bottom: 15px;
    text-align: center;
  }
`;

export const DialogActions = styled(MuiDialogActions)`
  &.MuiDialogActions-root {
    justify-content: center;
  }
`;

export const Button = styled(MuiButton)`
  .MuiButton-label {
    color: ${(props) => "#383f48"};
  }
`;
