import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../components/PageListHeader";
//functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { format } from "date-fns";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import { formatDateToTable } from "~/presentation/functions/date";
import CreateEdit from "./CreateEdit/CreateEdit";

const columns = [
  { name: "question", label: "Pergunta" },
  {
    name: "publishDate",
    label: "Data",
    options: {
      filter: false,
      customBodyRender: (value) => formatDateToTable(value),
    },
  },
  {
    name: "draft",
    label: "Rascunho",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => (value === true ? "Sim" : "Não"),
    },
  },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const Survey = ({ history, surveyUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [surveys, setSurveys] = useState([]);

  //Search
  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");

    const apiSurveys = await surveyUseCases.getAll();

    const userIsSuperAdmin = context.haveSuperAdminAccess();

    apiSurveys?.forEach((survey) => {
      if (userIsSuperAdmin) {
        survey.actions = (
          <TableActions
            handleDelete={() => deleteDialog(survey.id)}
            handleEdit={() => handleOpenForm(survey)}
          />
        );
      } else {
        survey.actions = <TableActions handleEdit={() => handleOpenForm(survey)} />;
      }
    });

    setSurveys(apiSurveys);
    openBackdrop(false);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo enquete");
    try {
      await surveyUseCases.delete(id);
      setSurveys((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir enquete: Missing endpoint.",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta enquete?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refresData={fetchData}
          surveyUseCases={surveyUseCases}
        />
      ) : (
        <>
          <PageListHeader
            title="Enquete"
            titleAccess="Nova enquete"
            searchFieldRef={searchFieldRef}
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
          />
          <Table searchRef={hiddenInputRef} columns={columns} data={surveys} title="Enquetes" />
        </>
      )}
    </Container>
  );
};

export default Survey;
