import React, { useContext, useEffect, useState } from "react";
import AppContext from "~/presentation/AppContext";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import cnpjSearch from "~/data/functions/cnpj-search";
import { ApiBusinessPartnerUseCases } from "~/data/usecases/businessPartner/businessPartner";
import { Grid, Box, Typography as Text, InputAdornment, IconButton, Tooltip } from "@mui/material";
import { Close, Search } from "@mui/icons-material";
import removeMask from "~/presentation/functions/removeMask";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import validation from "./validation";

export const PartnerForm = ({ onClose, partnerFormData, refreshData }) => {
  const apiBusinessPartner = new ApiBusinessPartnerUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const [errors, setErrors] = useState({});

  const [partnerData, setPartnerData] = useState({
    cpfCnpj: "",
    personType: 1, //PersonType (0 = PF, 1 = PJ)
    legalName: "",
    ie: "",
    im: "",
    tradeName: "",
    address: "",
    number: "",
    complement: "",
    municipalityCode: "",
    zipCode: "",
    homePhone: "",
    email: "",
  });

  useEffect(() => {
    if (partnerFormData.cpfCnpj) {
      setPartnerData(partnerFormData);
    }
  }, [partnerFormData]);

  const handleChange = (name, value) => {
    setPartnerData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCnpjSearch = async () => {
    const cnpj = removeMask(partnerData?.cpfCnpj);
    openBackdrop(true, "Buscando CNPJ...");
    try {
      const data = await cnpjSearch(cnpj);
      const legalName = data?.razao_social || "";
      const tradeName = data?.estabelecimento.nome_fantasia || "";
      const address =
        `${data?.estabelecimento.tipo_logradouro || ""} ${
          data?.estabelecimento.logradouro || ""
        }` || "";
      const number = data?.estabelecimento.numero || "";
      const complement = data?.estabelecimento.complemento || "";
      const municipalityCode =
        `${data?.estabelecimento.cidade.id || ""} ${"-"} ${
          data?.estabelecimento.cidade.nome || ""
        } ${"/"} ${data?.estabelecimento.estado.sigla || ""}` || "";
      const zipCode = data?.estabelecimento.cep;
      const homePhone =
        `${data?.estabelecimento.ddd1 || ""}${data?.estabelecimento.telefone1 || ""}` || "";
      const email = data?.estabelecimento.email || "";
      legalName && handleChange("legalName", legalName);
      tradeName && handleChange("tradeName", tradeName);
      address && handleChange("address", address);
      number && handleChange("number", number);
      complement && handleChange("complement", complement);
      municipalityCode && handleChange("municipalityCode", municipalityCode);
      zipCode && handleChange("zipCode", zipCode);
      homePhone && handleChange("homePhone", homePhone);
      email && handleChange("email", email);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro ao buscar CNPJ. ${error?.toString().split(":")[1] || error}`,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handleSubmit = async () => {
    openBackdrop(true, partnerData.id ? "Atualizando Parceiro..." : "Cadastrando Parceiro...");

    const dataToSend = {
      cpfCnpj: removeMask(partnerData.cpfCnpj) || "",
      personType: 1, //PersonType (0 = PF, 1 = PJ)
      ie: partnerData.ie || "",
      im: partnerData.im || "",
      legalName: partnerData.legalName || "",
      tradeName: partnerData.tradeName || "",
      address: partnerData.address || "",
      number: partnerData.number || "",
      complement: partnerData.complement || "",
      municipalityCode: partnerData?.municipalityCode?.split(" ")[0] || "",
      zipCode: removeMask(partnerData.zipCode) || "",
      homePhone: removeMask(partnerData.homePhone) || "",
      email: partnerData.email || "",
    };

    validation
      .validate(dataToSend, { abortEarly: false })
      .then(async () => {
        setErrors({});
        try {
          if (partnerData.id) {
            dataToSend.id = partnerData.id;
            await apiBusinessPartner.updateBusinessPartner(dataToSend, partnerData.id);
          } else {
            await apiBusinessPartner.createBusinessPartner(dataToSend);
          }
          setFeedbackSnackbar({
            isOpen: true,
            message: partnerData.id
              ? "Parceiro atualizado com sucesso"
              : "Novo parceiro cadastrado com sucesso",
            type: "success",
          });
          refreshData();
          onClose();
        } catch (error) {
          setFeedbackSnackbar({
            isOpen: true,
            message: `Erro ao processar dados: ${error.data}`,
            type: "error",
          });
        } finally {
          openBackdrop(false);
        }
      })
      .catch((errors) => {
        setErrors(
          errors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {})
        );
        setFeedbackSnackbar({
          isOpen: true,
          message: "Erros nos campos, corrija para poder enviar!",
          type: "warning",
        });
        openBackdrop(false);
      });
  };

  return (
    <Box sx={styles.Content}>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={11}>
          <Text style={styles.Title}>Adicionar novo contratado</Text>
        </Grid>
        <Grid
          item
          xs={6}
          sm={1}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <Close onClick={onClose} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Input
            type="mask"
            title="CNPJ do Contratado"
            mask={"99.999.999/9999-99"}
            name="cpfCnpj"
            value={partnerData.cpfCnpj}
            onChange={(e) => handleChange("cpfCnpj", e.target.value)}
            error={!!errors.cpfCnpj}
            errorText={errors.cpfCnpj}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Pesquisar CNPJ na RFB">
                    <IconButton
                      type="button"
                      sx={{
                        p: "10px",
                        backgroundColor: "#E6E6E6",
                      }}
                      aria-label="search"
                      onClick={handleCnpjSearch}
                    >
                      <Search
                        style={{
                          color: "#14213D",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            title="Nome Empresarial"
            name="legalName"
            value={partnerData.legalName}
            onChange={(e) => handleChange("legalName", e.target.value)}
            error={!!errors.legalName}
            errorText={errors.legalName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            title="Nome Fantasia"
            name="tradeName"
            value={partnerData.tradeName}
            onChange={(e) => handleChange("tradeName", e.target.value)}
            error={!!errors.tradeName}
            errorText={errors.tradeName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            title="Endereço"
            name="address"
            value={partnerData.address}
            onChange={(e) => handleChange("address", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            title="Número"
            name="number"
            value={partnerData.number}
            onChange={(e) => handleChange("number", e.target.value)}
            error={!!errors.number}
            errorText={errors.number}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            title="Inscrição Estadual"
            name="ie"
            value={partnerData.ie}
            onChange={(e) => handleChange("ie", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            title="Inscrição Municipal"
            name="im"
            value={partnerData.im}
            onChange={(e) => handleChange("im", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            disabled
            title="Cidade/ UF"
            name="municipalityCode"
            value={partnerData.municipalityCode}
            onChange={(e) => handleChange("municipalityCode", e.target.value)}
            error={!!errors.municipalityCode}
            errorText={errors.municipalityCode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="mask"
            title="CEP"
            name="cep"
            mask={"99999-999"}
            value={partnerData.zipCode}
            onChange={(e) => handleChange("zipCode", e.target.value)}
            error={!!errors.zipCode}
            errorText={errors.zipCode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="mask"
            title="Telefone"
            name="homePhone"
            mask={"(99) 9999-9999"}
            value={partnerData.homePhone}
            onChange={(e) => handleChange("homePhone", e.target.value)}
            error={!!errors.homePhone}
            errorText={errors.homePhone}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            type="text"
            title="Email"
            name="email"
            value={partnerData.email}
            onChange={(e) => handleChange("email", e.target.value)}
            error={!!errors.email}
            errorText={errors.email}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
        <RButton sx={{ width: "150px" }} type="button" variant="outlined" onClick={onClose}>
          Cancelar
        </RButton>
        <RButton sx={{ width: "150px" }} variant="contained" color="primary" onClick={handleSubmit}>
          {partnerData.id ? "Salvar" : "Cadastrar"}
        </RButton>
      </Box>
    </Box>
  );
};

const styles = {
  Content: {
    p: 4,
  },

  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
};
