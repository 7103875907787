import React, {useContext, useEffect, useRef, useState} from "react";
import { Formik, Form } from "formik";

import {
  SelectQuestion,
  Button,
  SimulatorResultCard,
  FormWrapper,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { floatToReais } from "~/presentation/utils/reais-parser";
import { reportMapper } from "./reportMapper";

export const IrrfPJNotSimples = ({aliquots}) => {
  const initialValues = {
    retentionAliquot: aliquots()[0],
  };
  const resultCardRef = useRef();
  const { simulatorValue, setInvalidSimulatorValue, handleGenerateReport, handleCacheReport } = useContext(SimulatorContext);
  const [result, setResult] = useState();
  const [lastSubmitValues, setLastSubmitValues] = useState("");

  const calculateResult = (values) =>
    simulatorValue * values.retentionAliquot.value;

  const valuesNotChangedSinceLastSubmit = (values) => {
    return JSON.stringify(lastSubmitValues) === JSON.stringify(values);
  };

  const handleGenerateReportClick = (values) => {
    const response = reportMapper(values, result)
    handleGenerateReport(response)
  }

  useEffect(() => {
    if(result) {
      resultCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }

        action.setSubmitting(true);
        setLastSubmitValues(values);

        const result = calculateResult(values);
        setResult(result);

        resultCardRef.current.scrollIntoView({ behavior: "smooth" });
        action.setSubmitting(false);

        const response = reportMapper(values, result);
        
        handleCacheReport(response);        
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <FormWrapper>
            <SelectQuestion
              vertical
              text="Alíquota da retenção:"
              excerpt="A alíquota de retenção é determinada pelo enquadramento do serviço no art. 714 ou no art. 716, do Decreto n° 9.580/18 (Regulamento do Imposto de Renda – RIR/2018). A alíquota será de 1,5% ou 1%, caso o serviço esteja previsto no art. 714 ou art. 716, respectivamente."
              onChange={(value) => setFieldValue("retentionAliquot", value)}
              value={values.retentionAliquot.value}
              options={aliquots()}
            />

            <Button mt>Ver Resultado</Button>

            {result && valuesNotChangedSinceLastSubmit(values) ? (
              <SimulatorResultCard
                componentRef={resultCardRef}
                title="Valor da retenção do IR:"
                value={floatToReais(result)}
                description="O valor da retenção do imposto de renda corresponde ao resultado da multiplicação da alíquota pela base de cálculo."
                generateReportButton={() => handleGenerateReportClick(values)}
              />
            ) : null}
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};
