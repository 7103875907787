import styled from "styled-components";

export const Container = styled.div`
  border: ${({ border }) => (border ? `solid 1px #dedede` : "none")};
  border-radius: 10px;
  overflow: hidden;
`;

export const Title = styled.div`
  padding: max(15px, 1.4vw) max(14px, 1.3vw);
  background: #1341a1;
  color: #fafafa;
  width: 100%;

  p {
    font-size: max(18px, 1.3vw);
    border-left: solid 4px #ff6829;
    padding-left: 8px;
    font-family: "Montserrat", sans-serif;
  }
`;

export const Content = styled.div`
  padding: max(20px, 3vw) max(12px, 1.2vw);
  position: relative;
`;

export const ProgressWrapper = styled.div``;
