import { Form as FormikForm } from "formik";
import styled from "styled-components";
import { Switch as MuiSwitch } from "@mui/material";
import BaseSelectField from "~/presentation/components/Select";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const Container = styled.div``;

export const Form = styled(FormikForm)`
  .form-grid {
    grid-gap: 20px;
  }
`;

export const Label = styled.p`
  font-size: max(20px, 1.5vw);
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
`;

export const Switch = styled(MuiSwitch)``;

export const SwitchWrapper = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  display: grid;
  grid-template-columns: auto min-content;
  justify-items: center;
  align-items: center;
  grid-template-areas:
    "A A"
    "B C";
  gap: 16px;

  @media screen and (min-width: 600px) {
    justify-items: flex-start;
    grid-template-areas: "A B C";
    grid-template-columns: auto min-content min-content;
  }

  ${Label} {
    grid-area: A;
  }

  [itemId="info-tooltip"] {
    grid-area: C;
  }

  #switch {
    grid-area: B;
    min-width: 140px;
    > span {
      font-size: max(16px, 1.3vw);
    }
  }
`;

export const SelectField = styled(BaseSelectField).attrs({
  padding: "4px 12px",
})`
  margin: 16px 8px 0;
`;

export const InfoIcon = styled(InfoOutlinedIcon)`
  margin: 0 12px;
  color: #000;
  transform: scale(0.9);
`;

export const SubmitButton = styled(PrimaryButton).attrs({
  backgroundColor: "#1341a1",
  size: "small",
})`
  &.MuiButton-root {
    margin-top: 32px;
    width: 100%;

    @media screen and (min-width: 600px) {
      width: 50%;
      margin: 16px auto 0;
    }

    .MuiButton-label {
      font-family: "Montserrat", sans-serif;
    }
  }
`;

export const CitySelectorsWrapper = styled.div.attrs({
  className: "col-1_5",
})`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${SelectField} {
    margin: 0;
  }

  ${Label} {
    font-size: max(16px, 1.2vw);
    margin-bottom: 8px;
  }
`;
