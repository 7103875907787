import React from 'react';
import { makeItemAnswerUseCases } from '~/main/factories/use-cases/item-answer';
import { makeTypeAnswerUseCases } from '~/main/factories/use-cases/types-answer';
import { ItemAnswerCreate } from '~/presentation/views/Admin/ItemAnswer/Create';

export const makeItemAnswerCreate = (params) => (
    <ItemAnswerCreate
        {...params}
        itemAnswerUseCases={makeItemAnswerUseCases()}
        typesAnswerUseCases={makeTypeAnswerUseCases()}
    />
)