import React, { memo } from "react";
import CurrencyInput from "react-currency-input";
import styled from "styled-components";
import { lighten } from "polished";

function textColor({ theme, disabled }) {
  return disabled ? lighten(0.1, "#383f48") : "#272c33";
}

const StyledCurrencyInput = styled(CurrencyInput)`
  text-align: end;
  padding: 10px 16px;
  border: none;
  outline: none;
  border-radius: 4px;
`;

const Container = styled.div`
  height: min-content;
  position: relative;
  display: flex;
  align-items: center;

  > span {
    left: 16px;
    position: absolute;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: ${textColor};
  }

  > input {
    min-width: 50px;
    width: 100%;
    color: ${textColor};
    background-color: ${({ theme, disabled }) => (disabled ? "#dedede" : "#fafafa")};
  }
`;

export const MoneyInput = memo(({ onChange, value, disabled, allowNegative, prefix = "R$" }) => {
  return (
    <Container disabled={disabled}>
      <span>{prefix}</span>

      <StyledCurrencyInput
        allowNegative={allowNegative}
        value={value}
        onChangeEvent={onChange}
        precision="2"
        decimalSeparator=","
        thousandSeparator="."
        disabled={disabled}
      />
    </Container>
  );
});
