import React, { useEffect, useContext, useState } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { formatDateToBrazilian } from "../../../Utils/utilsDate";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { ApiDigitalCertUseCases } from "~/data/usecases/DigitalCert/apiDigitalCert";
import {
  Container,
  Typography as Text,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  Stack,
  Collapse,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { ConsultSituation } from "./ConsultSituation";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";

export const ConsultR1000 = () => {
  const apiReinf = new ApiReinfUseCases(makeHttpClient());
  const apiDigitalCert = new ApiDigitalCertUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  //loading
  const [loading, setLoading] = useState(false);
  //dados eventos
  const [eventsData, setEventsData] = useState([]);
  //dados da situação dos eventos
  const [protocolDetails, setProtocolDetails] = useState({});
  //openCollapse para mostrar a situação de cada evento
  const [openCollapse, setOpenCollapse] = useState({});
  //paginação tabela
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    handleRenderAllInfo();
  }, []);

  //1º FETCH CERTIFICADO -> 2º FETCH EVENTOS -> 3º FAZER CONSULTA
  const handleRenderAllInfo = async () => {
    setLoading(true);
    //1º FETCH CERTIFICADO
    const certs = await fetchCertificates();
    const defaultCert = certs.find((cert) => cert.isDefault);
    //usando o certificado default ou o primeiro da lista
    const firstCert = defaultCert ? defaultCert.id : certs[0].id;
    //2º FETCH EVENTOS
    const events = await fetchEventsData();
    if (events.status === 200) {
      setEventsData(events.data);
      //3º FAZER CONSULTA
      if (firstCert) {
        handleConsult(events.data, firstCert);
      } else {
        setLoading(false);
        return showError("Erro ao carregar certificados");
      }
    } else {
      showError("Erro no retorno dos dados, verifique sua conexão");
    }
    setLoading(false);
  };

  const fetchCertificates = async () => {
    try {
      const CertsData = await apiDigitalCert.getDigitalCerts();
      return CertsData;
    } catch (error) {
      showError("Erro ao buscar certificados");
    }
  };

  const fetchEventsData = async () => {
    try {
      const events = await apiReinf.getReinfProtocol({ reinfEvent: 0 });
      return events;
    } catch (error) {
      showError("Erro no retorno dos dados, verifique sua conexão");
    }
  };

  const handleConsult = async (events, certificate) => {
    const promises = events.map(async (event) => {
      const params = {
        DigitalCertId: certificate,
        ProtocolNumber: event.protocol,
        IsProduction: false,
      };
      return apiReinf
        .getReinfConsult(params)
        .then((updatedItem) => {
          return { protocol: event.protocol, data: updatedItem };
        })
        .catch((error) => {
          throw error;
        });
    });

    try {
      const response = await Promise.all(promises);
      const newProtocolResponseMap = {};
      response.forEach(({ protocol, data }) => {
        newProtocolResponseMap[protocol] = data;
      });
      setProtocolDetails(newProtocolResponseMap);
    } catch (error) {
      showError("Erro no retorno dos dados, verifique sua conexão");
    }
  };

  const handleOpenCollapse = (protocol) => {
    setOpenCollapse((prev) => ({
      ...prev,
      [protocol]: !prev[protocol],
    }));
  };

  const showError = (message) => {
    return setFeedbackSnackbar({
      isOpen: true,
      message: message,
      type: "error",
    });
  };

  const columns = [
    { title: "" },
    { title: "Protocolo" },
    { title: "Data de Envio" },
    { title: "Status" },
  ];

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = eventsData.slice(startIndex, endIndex);

  return (
    <>
      <Container maxWidth="100%" sx={{ paddingBlock: 3 }}>
        <Text style={styles.Title}>R-1000 Enviados</Text>
        <Box sx={{ borderRadius: 3, overflow: "hidden" }}>
          <Table>
            <TableHead>
              <TableRow sx={styles.HeadTableRow}>
                {columns.map((item, index) => (
                  <TableCell key={index} sx={styles.TableHeader}>
                    {item.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.length > 0 ? (
                currentData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((event, index) => {
                    const responseDetails = protocolDetails[event.protocol];
                    const isOpen = openCollapse[event.protocol] || false;
                    const newStatus = protocolDetails[event.protocol]?.descriptionResponse;
                    const status = newStatus || event.status || "Não definida";
                    return (
                      <React.Fragment key={event.id}>
                        <TableRow>
                          <TableCell sx={styles.TableCell}>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleOpenCollapse(event.protocol)}
                              disabled={!responseDetails}
                            >
                              {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                            </IconButton>
                          </TableCell>
                          <TableCell sx={styles.TableCell}>{event.protocol || "-"}</TableCell>
                          <TableCell sx={styles.TableCell}>
                            {formatDateToBrazilian(event.sendingDate) || "-"}
                          </TableCell>
                          <TableCell sx={styles.TableCell}>{status || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={styles.CollapseContainer} colSpan="100%">
                            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                              <ConsultSituation
                                protocol={event.protocol}
                                responseDetails={responseDetails}
                              />
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan="100%"
                    sx={{
                      ...styles.TableCell,
                      height: "45px",
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={25} s color="inherit" />
                    ) : (
                      <Typography sx={{ fontFamily: "Montserrat ", fontSize: 14 }}>
                        Nenhum evento foi encontrado.
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {eventsData.length > 9 && (
            <Stack
              spacing={1}
              sx={{ background: "#F2F2F2", paddingRight: 8, borderRadius: "0px 0px 8px 8px" }}
            >
              <TablePagination
                component="div"
                count={eventsData.length}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                labelRowsPerPage="Itens por página:"
                rowsPerPageOptions={[10, 15, 20]}
              />
            </Stack>
          )}
        </Box>
      </Container>
    </>
  );
};

const styles = {
  Container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "60%",
    width: "60%",
    maxHeight: "80%",
    border: "solid 2px #EFF2F4",
    borderRadius: "18px",
    bgcolor: "#E8E8E8",
    boxShadow: 24,
    overflow: "auto",
  },
  CollapseContainer: {
    paddingBlock: "3px",
    backgroundColor: "#F2F2F2",
  },
  Title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "#242424",
    marginBottom: 2,
  },
  HeadTableRow: {
    backgroundColor: "#1341A1",
  },
  TableHeader: {
    backgroundColor: "#1341A1",
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#ffffff",
    fontSize: 13,
    padding: "10px",
    fontWeight: 400,
    position: "relative",
    "&:not(:first-child)::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "10%",
      bottom: "10%",
      left: 0,
      width: "3px",
      backgroundColor: "#142F68",
    },
  },
  TableCell: {
    fontFamily: "Montserrat ",
    fontSize: 14,
    padding: "6px",
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
  },
};
