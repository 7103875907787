import React from "react";
// components
import TextInput from "~/presentation/components/TextInput";
import ErrorMessage from "~/presentation/components/ErrorMessage";
// packages
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
// styles
import {
  Button,
  DialogActions,
  DialogContent,
} from "~/presentation/views/Admin/User/components/FormConfirmPassword/styles";

const FormConfirmPassword = ({ handleClose, handleConfirm }) => (
  <Formik
    initialValues={{ password: "" }}
    validationSchema={Yup.object({
      password: Yup.string().required("Campo Obrigatório!"),
    })}
    onSubmit={(values) => {
      handleConfirm(values.password);
      handleClose();
    }}
  >
    <Form>
      <DialogContent>
        <div className="message">Confirme sua senha para alterar o usuário</div>
        <Field name="password" type="password" label="Senha*" size="small" as={TextInput} />
        <ErrorMessage name="password" />
      </DialogContent>
      <DialogActions>
        <Button type="submit" autoFocus>
          Confirmar
        </Button>
        <Button onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Form>
  </Formik>
);

export default FormConfirmPassword;
