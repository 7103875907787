import React, { useEffect, useReducer, useState } from "react";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import TermForm from "../Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

const initialValues = { id: 0, term: "" };

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_TERM":
      return { ...state, term: action.payload.term };
    default:
      state;
  }
};

const EditTerm = ({ termUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [initialValues, setInitialValues] = useState();
  const { id } = useParams();
  const history = useHistory();

  const fetchData = async (id) => {
    openBackdrop(true, "Carregando dados do termos...");
    termUseCases.getTermById(id).then((value) => {
      setInitialValues(value);
    });
    openBackdrop(false);
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  const handleSubmit = async (values) => {
    try {
      openBackdrop(true, "Cadastrando novo termo");
      await termUseCases.updateTerm(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Termo atualizado com sucesso!",
        type: "success",
      });
      history.push("/admin/termos-de-pesquisa");
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: error,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Editar Termo" />
      {initialValues ? (
        <TermForm initialValues={initialValues} handleSubmit={handleSubmit} />
      ) : (
        <div />
      )}
    </Container>
  );
};

export default EditTerm;
