import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Stack,
  Box,
  CircularProgress,
  Checkbox,
  Divider,
  Grid,
  Typography as Text,
} from "@mui/material";
import SentTableDocs from "./components/SentTableDocs";
import RButton from "../../../components/Common/Buttons/RButton";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { ConsultSituation } from "./components/ConsultSituation/ConsultSituation";
import { KeyboardArrowDown, KeyboardArrowRight, Search } from "@mui/icons-material";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import { Tooltip } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { formatDateToBrazilian } from "../../../Utils/utilsDate";
import { EventSend } from "../EventSend/EventSend";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import { useTableFilters } from "../../../Hooks/useTableFilters";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";

function SentTableEvents({
  certificateData,
  filter,
  refreshData,
  currentEventsData,
  validNfseData,
  isEventClosed,
}) {
  const apiReinf = new ApiReinfUseCases(makeHttpClient());
  const apiNfse = new ApiNfseUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const [eventsData, setEventsData] = useState({});
  const [notasValues, setNotasValues] = useState([]);
  const [totalAmountBC, setTotalAmountBC] = useState([]);
  const [totalAmountIR, setTotalAmountIR] = useState([]);
  const [totalAmountCSRF, setTotalAmountCSRF] = useState([]);
  const [totalAmountINSS, setTotalAmountINSS] = useState([]);
  const [protocolTotals, setProtocolTotals] = useState({}); //state totalizador c/protocolo
  const [isSituation, setIsSituation] = useState(false);
  const [protocolResponseMap, setProtocolResponseMap] = useState({});
  const [loading, setLoading] = useState(false);
  const { handleSearchTable } = useTableFilters();

  // perfil da empresa
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO

  const eventsToCheckSituation =
    eventsData.length > 0 &&
    eventsData?.filter(
      (item) => (item.codeStatus === 1 || item.codeStatus === 2) && !item.receiptNumber
    );

  //pesquisa
  const [searchTerm, setSearchTerm] = useState("");

  const selectedEvent = filter?.event;
  const selectedCert = filter?.certificate;

  //LINHAS SELECIONADAS
  const [selectedRows, setSelectedRows] = useState([]);

  const [nfseDataWithEventSent, setNfseDataWithEventSent] = useState([]);

  const handleConsult = async () => {
    const promises = eventsToCheckSituation?.map(async (item) => {
      const params = {
        DigitalCertId: selectedCert,
        ProtocolNumber: item.protocol,
        IsProduction: false,
      };
      return apiReinf
        .getReinfConsult(params)
        .then((updatedItem) => {
          return { protocol: item.protocol, data: updatedItem };
        })
        .catch((error) => {
          throw error;
        });
    });

    try {
      setLoading(true);
      const response = await Promise.all(promises);
      const newProtocolResponseMap = {};
      response.forEach(({ protocol, data }) => {
        newProtocolResponseMap[protocol] = data;
      });
      setProtocolResponseMap(newProtocolResponseMap);
      setIsSituation(true);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "ERRO! Verifique o certificado e período escolhido!",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEventsData(currentEventsData);
    currentEventsData.forEach(async (event, index) => {
      handleOpenCollapse(index, true);
      await fetchNotas(event.protocol);
    });
    handleSelectAllClick({ target: { checked: false } });
  }, [currentEventsData]);

  //ABERTURA E FECHO DE CADA FICHA (COLLAPSE)
  const [openCollapse, setOpenCollapse] = useState({});
  //Open/Close Collapse
  const handleOpenCollapse = (index, isOpen) => {
    setOpenCollapse((prev) => ({
      ...prev,
      [index]: !isOpen,
    }));
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleSearchTable(value, currentEventsData, setEventsData);
  };

  //Selecionar TODAS as documentos
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      setSelectedRows(eventsData);
    } else {
      setSelectedRows([]);
    }
  };

  //Selecionar nota individual
  const handleCheckboxChange = (event, item) => {
    if (event.target.checked) {
      setSelectedRows((prev) => [...prev, item]);
    } else {
      setSelectedRows((prev) =>
        prev.filter((selectedItem) => selectedItem.protocol !== item.protocol)
      );
    }
  };

  //para por no checkbox de cada linha
  const isSelected = (item) => selectedRows.some((i) => i.protocol === item.protocol);

  //para o checkbox "titulo" do head
  const numSelected = selectedRows.length;
  const rowCount = eventsData.length;

  // OPEN TABLE
  const [openEventSendModal, setOpenEventSendModal] = useState(false);

  // ⇣⇣⇣ abrir e fechar modal de envio de documentos
  const handleOpen = () => setOpenEventSendModal(true);
  const handleClose = () => setOpenEventSendModal(false);

  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = eventsData.length > 0 && eventsData.slice(startIndex, endIndex);

  const renderCodeIcon = (codeStatus, descriptionResponse) => {
    if (codeStatus === 2) {
      return (
        <Tooltip title={descriptionResponse}>
          <IconButton>
            <CheckCircleOutlineOutlinedIcon color="success" />
          </IconButton>
        </Tooltip>
      );
    }

    if (codeStatus > 2) {
      return (
        <Tooltip title={descriptionResponse}>
          <IconButton>
            <ErrorOutlineOutlinedIcon color="error" />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={descriptionResponse || "Aguardando consulta..."}>
        <IconButton>
          <PendingOutlinedIcon color="primary" />
        </IconButton>
      </Tooltip>
    );
  };

  const renderStatus = (status, message, codeStatus, descriptionResponse) => {
    if (status === 2) {
      return (
        <Tooltip title={message}>
          <IconButton>
            <CheckCircleOutlineOutlinedIcon color="success" />
          </IconButton>
        </Tooltip>
      );
    } else {
      return loading ? (
        <Box sx={{ p: "7px" }}>
          <CircularProgress size={20} color="inherit" />
        </Box>
      ) : (
        renderCodeIcon(codeStatus, descriptionResponse)
      );
    }
  };

  useEffect(() => {
    if (selectedEvent !== "r9000") {
      setNfseDataWithEventSent(
        validNfseData.filter(
          (nfse) => !!currentEventsData.some((event) => event.tag === nfse.businessPartnerCNPJ)
        )
      );
    }
  }, [currentEventsData, validNfseData]);

  const fetchNotas = async (protocol) => {
    try {
      const response = await apiNfse.getNfseByProtocol(protocol);
      if (response) {
        setNotasValues((prevValues) => {
          const newValues = [...prevValues, ...response];
          const totalAmountBC = response.reduce((total, curr) => total + curr.amountBC, 0);
          const totalAmountIR = response.reduce((total, curr) => total + curr.amountIR, 0);
          const totalAmountCSRF = response.reduce((total, curr) => total + curr.amountCSRF, 0);
          const totalAmountINSS = response.reduce((total, curr) => total + curr.amountINSS, 0);

          setProtocolTotals((prevTotals) => ({
            ...prevTotals,
            [protocol]: {
              amountBC: totalAmountBC,
              amountIR: totalAmountIR,
              amountCSRF: totalAmountCSRF,
              amountINSS: totalAmountINSS,
            },
          }));
          return newValues;
        });
        return response;
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos dados, verifique sua conexão",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAutomatedRectification = async () => {
    const promises = eventsData.map(async (item) => {
      const nfseData = await fetchNotas(item.protocol);
      return { ...item, nfseData };
    });

    try {
      setLoading(true);
      openBackdrop(true, "Adicionando documentos fiscais que não foram enviados");
      setSelectedRows([]);
      const response = await Promise.all(promises);
      currentEventsData.forEach((event, index) => {
        handleOpenCollapse(index, true);
      });
      const eventsCollapseStatus = [];
      const updatedEvents = response.map((item, index) => {
        const nfseData = validNfseData.filter((nfse) => nfse.businessPartnerCNPJ === item.tag);
        if (nfseData.length > 0) {
          eventsCollapseStatus.push({ index: index, isOpen: false });
          return {
            ...item,
            nfseData: [...item.nfseData, ...nfseData],
          };
        }
        eventsCollapseStatus.push({ index: index, isOpen: true });
        return item;
      });

      setEventsData(updatedEvents);
      eventsCollapseStatus.forEach((item) => {
        if (!item.isOpen) {
          setSelectedRows((prev) => [...prev, updatedEvents[item.index]]);
        }
        handleOpenCollapse(item.index, item.isOpen);
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos dados, verifique sua conexão",
        type: "error",
      });
    } finally {
      setLoading(false);
      openBackdrop(false);
    }
  };

  const handleSubmit = async () => {
    if (selectedEvent === "r2010" || selectedEvent === "r4020") {
      const promises = selectedRows.map(async (item) => {
        if (!item.nfseData) {
          const nfseData = await fetchNotas(item.protocol);
          return { ...item, nfseData };
        }

        return item;
      });

      setSelectedRows(await Promise.all(promises));
    }

    handleOpen();
  };

  useEffect(() => {
    const totals = { amountBC: 0, amountIR: 0, amountCSRF: 0, amountINSS: 0 };
    Object.values(protocolTotals).forEach((protocolTotal) => {
      totals.amountBC += protocolTotal.amountBC || 0;
      totals.amountIR += protocolTotal.amountIR || 0;
      totals.amountCSRF += protocolTotal.amountCSRF || 0;
      totals.amountINSS += protocolTotal.amountINSS || 0;
    });
    setTotalAmountBC(totals.amountBC);
    setTotalAmountIR(totals.amountIR);
    setTotalAmountCSRF(totals.amountCSRF);
    setTotalAmountINSS(totals.amountINSS);
  }, [protocolTotals]);

  return (
    <>
      <Grid container sx={{ justifyContent: "space-between", paddingInline: 3 }}>
        {selectedEvent !== "r9000" && (
          <Grid item xs={12} sm={6} md={5} lg={4} sx={styles.SituationButtonContainer}>
            <RButton
              disabled={eventsData?.length === 0 || nfseDataWithEventSent.length === 0}
              variant={"contained"}
              sx={styles.Button}
              onClick={handleAutomatedRectification}
            >
              Adicionar Documentos
            </RButton>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4} lg={3} sx={styles.search}>
          <Box sx={styles.searchContainer}>
            <input
              style={styles.searchInput}
              type="text"
              onChange={handleSearch}
              placeholder="Pesquisar..."
              value={searchTerm}
            />
            <Search />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "right", paddingTop: 2 }}>
          <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
            <Text sx={styles.text}>
              Totalizador: <span>{formatNumberToRealString(totalAmountBC)}</span>
            </Text>
            {selectedEvent === "r4020" && (
              <>
                <Text sx={styles.text}>
                  Totalizador IR: <span>{formatNumberToRealString(totalAmountIR)}</span>
                </Text>
                {QSO === "D" && (
                  <Text sx={styles.text}>
                    Totalizador CSRF: <span>{formatNumberToRealString(totalAmountCSRF)}</span>
                  </Text>
                )}
              </>
            )}
            {selectedEvent === "r2010" && (
              <Text sx={styles.text}>
                Totalizador INSS: <span>{formatNumberToRealString(totalAmountINSS)}</span>
              </Text>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ borderRadius: "10px", overflow: "hidden", marginInline: 3, marginBlock: 2 }}>
        <Table sx={{ borderCollapse: "separate", borderSpacing: "0" }}>
          <TableHead>
            <TableRow sx={styles.HeadTableRow}>
              {selectedEvent !== "r9000" && <TableCell sx={styles.TableCellHead}></TableCell>}
              <TableCell sx={{ ...styles.TableCellHead, padding: 0 }}>
                <Checkbox
                  color="default"
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              <TableCell sx={styles.TableCellHead}>Protocolo</TableCell>
              <TableCell sx={styles.TableCellHead}>Nº Recibo</TableCell>
              <TableCell sx={styles.TableCellHead}>Data do envio</TableCell>
              <TableCell sx={styles.TableCellHead}>Email do usuário</TableCell>
              <TableCell sx={styles.TableCellHead}>Evento</TableCell>
              <TableCell sx={styles.TableCellHead}>Totalizador</TableCell>
              {selectedEvent === "r4020" && (
                <>
                  <TableCell sx={styles.TableCellHead}>Total IR</TableCell>
                  {QSO === "D" && <TableCell sx={styles.TableCellHead}>Total CSRF</TableCell>}
                </>
              )}
              {selectedEvent === "r2010" && (
                <TableCell sx={styles.TableCellHead}>Total INSS</TableCell>
              )}
              <TableCell sx={styles.TableCellHead}>Situação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.length > 0 ? (
              currentData?.map((item, index) => {
                const isOpen = openCollapse[index] || false;
                const responseDetails = protocolResponseMap[item.protocol];
                const codeStatus = responseDetails?.codeResponse;
                const descriptionResponse = responseDetails?.descriptionResponse;
                const isRowSelected = isSelected(item);
                const hasReceiptNumber = item?.receiptNumber;
                const protocolTotal = protocolTotals[item.protocol] || 0; // Total do protocolo
                return (
                  <React.Fragment key={index}>
                    <TableRow sx={{ cursor: selectedEvent === "r9000" ? "auto" : "pointer" }}>
                      {selectedEvent !== "r9000" && (
                        <TableCell sx={styles.TableCell}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              if (selectedEvent === "r9000") {
                                return;
                              }
                              if (!isRowSelected && !isOpen)
                                handleCheckboxChange({ target: { checked: true } }, item);
                              handleOpenCollapse(index, isOpen);
                            }}
                          >
                            {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                          </IconButton>
                        </TableCell>
                      )}
                      <TableCell sx={{ ...styles.TableCell, padding: 0 }}>
                        <Checkbox
                          color="default"
                          onChange={(event) => handleCheckboxChange(event, item)}
                          checked={isRowSelected}
                          disabled={!hasReceiptNumber}
                        />
                      </TableCell>
                      <TableCell sx={styles.TableCell}>{item.protocol}</TableCell>
                      <TableCell sx={styles.TableCell}>{item.receiptNumber}</TableCell>
                      <TableCell sx={styles.TableCell}>
                        {formatDateToBrazilian(item.sendingDate)}
                      </TableCell>
                      <TableCell sx={styles.TableCell}>{item.userSent}</TableCell>
                      <TableCell sx={styles.TableCell}>{`R-${item?.reinfEvent.slice(
                        1,
                        5
                      )}`}</TableCell>
                      <TableCell sx={styles.TableCell}>
                        {formatNumberToRealString(protocolTotals[item.protocol]?.amountBC || 0)}
                      </TableCell>
                      {selectedEvent === "r4020" && (
                        <>
                          <TableCell sx={styles.TableCell}>
                            {formatNumberToRealString(protocolTotals[item.protocol]?.amountIR || 0)}
                          </TableCell>
                          {QSO === "D" && (
                            <TableCell sx={styles.TableCell}>
                              {formatNumberToRealString(
                                protocolTotals[item.protocol]?.amountCSRF || 0
                              )}
                            </TableCell>
                          )}
                        </>
                      )}
                      {selectedEvent === "r2010" && (
                        <TableCell sx={styles.TableCell}>
                          {formatNumberToRealString(protocolTotals[item.protocol]?.amountINSS || 0)}
                        </TableCell>
                      )}
                      <TableCell sx={styles.TableCell}>
                        {renderStatus(
                          item.codeStatus,
                          item.status,
                          codeStatus,
                          descriptionResponse
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={styles.CollapseContainer} colSpan={12}>
                        {isOpen && (
                          <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            {isSituation ? (
                              <ConsultSituation
                                protocol={item.protocol}
                                responseDetails={responseDetails}
                                code={item.codeStatus}
                                receipt={item.receiptNumber}
                                message={item.status}
                              />
                            ) : (
                              <SentTableDocs
                                protocol={item.protocol}
                                filter={filter}
                                validNfseData={validNfseData}
                                status={item.codeStatus}
                                certificateData={certificateData}
                                receiptNumber={item.receiptNumber}
                                refreshData={refreshData}
                                isEventClosed={isEventClosed}
                                automatedData={eventsData[index]?.nfseData || null}
                                selecetedRowsData={setSelectedRows}
                                setEventsData={setEventsData}
                              />
                            )}
                          </Collapse>
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell sx={{ ...styles.TableCell, padding: "10px" }} colSpan="100%">
                  <p style={{ textAlign: "center" }}>Não existe eventos no período escolhido.</p>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {eventsData.length > 9 && (
          <Stack
            spacing={1}
            sx={{ background: "#F2F2F2", paddingRight: 8, borderRadius: "0px 0px 8px 8px" }}
          >
            <TablePagination
              component="div"
              count={eventsData?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Informações por página:"
              rowsPerPageOptions={[10, 15, 20]}
              sx={{
                ".MuiTablePagination-selectLabel ": {
                  margin: 0,
                },
                ".MuiTablePagination-displayedRows": {
                  margin: 0,
                },
              }}
            />
          </Stack>
        )}
      </Box>
      <Box sx={{ paddingRight: 3, display: "flex", justifyContent: "right" }}>
        <RButton
          sx={{ width: "200px" }}
          onClick={handleSubmit}
          disabled={selectedRows.length === 0}
        >
          Enviar
        </RButton>
      </Box>
      <ModalContainer open={openEventSendModal} onClose={handleClose}>
        <EventSend
          data={selectedRows}
          filter={{
            ...filter,
            event:
              selectedEvent === "r2010" || selectedEvent === "r4020"
                ? `automated-${selectedEvent}`
                : selectedEvent,
          }}
          certificateData={certificateData}
          closeModal={handleClose}
          refreshData={refreshData}
        />
      </ModalContainer>
    </>
  );
}

export default SentTableEvents;

const styles = {
  Button: {
    width: "fit-content",
    borderRadius: "8px",
    boxShadow: 0,
    textTransform: "uppercase",
  },
  text: {
    fontFamily: "Montserrat",
    fontSize: 14,
    fontWeight: 600,
    color: "#404040",
  },
  //LINHA TÍTULOS COLUNAS
  HeadTableRow: {},
  //LINHA TÍTULOS DAS COLUANS
  TableCellHead: {
    backgroundColor: "#1341A1",
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#ffffff",
    fontSize: 13,
    padding: "10px",
    fontWeight: 400,
    position: "relative",
    //DIVISOR COLUNAS HEAD
    "&:not(:first-child)::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "10%",
      bottom: "10%",
      left: 0,
      width: "3px",
      backgroundColor: "#142F68",
    },
  },
  //CADA LINHA (NOTA)
  TableCell: {
    fontFamily: "Montserrat ",
    fontSize: 14,
    padding: "6px",
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
  },
  //CADA LINHA
  TableRow: {},
  //COLAPSE
  CollapseContainer: {
    paddingBlock: "3px",
    backgroundColor: "#F2F2F2",
  },
  search: {
    marginLeft: "auto",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#DCDCDC",
    paddingRight: 2,
    paddingLeft: 2,
    borderRadius: "5px",
    height: "45px",
  },
  searchInput: {
    fontFamily: "Montserrat ",
    border: "none",
    background: "transparent",
    width: "100%",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  serachIcon: {
    color: "#1341A1",
  },
};
