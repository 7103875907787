import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ItemAnswerForm from "../Form";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

const ItemAnswerEdit = ({ typesAnswerUseCases, itemAnswerUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);

  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const id = useParams().id;
  const history = useHistory();
  const location = useLocation();
  const itemAnswer = location.itemAnswer;

  const [initialValues, setInitialValues] = useState();
  const [typesAnswer, setTypesAnswer] = useState([]);

  const fetchData = async () => {
    openBackdrop(true, "Carregando detalhes do item de resposta");
    const apiItemAnswer = !itemAnswer ? await itemAnswerUseCases.getById(id) : itemAnswer;
    setInitialValues({
      id: apiItemAnswer.id,
      title: apiItemAnswer.title,
      text: apiItemAnswer.text,
      gtAnswerItemType: dataToSelectMapper([apiItemAnswer.gtAnswerItemType])[0],
    });
    const apiTypesAnswer = await typesAnswerUseCases.getTypesAnswer();
    setTypesAnswer(apiTypesAnswer);
    openBackdrop(false);
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Editando serviço");
    try {
      await itemAnswerUseCases.update(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Item de resposta atualizado com sucesso!",
        type: "success",
      });
      history.push("/admin/itens-de-resposta");
    } catch (err) {
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao atualizar item de resposta",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Editar item de resposta" backLink="/admin/itens-de-resposta" />

      {initialValues ? (
        <ItemAnswerForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          data={typesAnswer}
        />
      ) : (
        <div />
      )}
    </Container>
  );
};

export default ItemAnswerEdit;
