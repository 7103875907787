import React, { useContext, useEffect, useState } from "react";
import { HeaderSectionPage } from "../components/HeaderSectionPage";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { formatDateToBrazilian, getDateEnd, getDateStart } from "../Utils/utilsDate";
import ActiveFilters from "./components/ActiveFilters/ActiveFilters";
import RButton from "../components/Common/Buttons/RButton";
import Input from "../components/Common/Inputs";
import { DeleteOutline, Search } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTableFilters } from "../Hooks/useTableFilters";

function EventsHistory() {
  const apiReinf = new ApiReinfUseCases(makeHttpClient());
  const [eventsData, setEventsData] = useState([]);
  const [storageData, setStorageData] = useState([]);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { handleSearchTable } = useTableFilters();

  //valores padrão
  const today = new Date();
  const sixMonths = new Date(new Date().setMonth(new Date().getMonth() - 6));

  // Filters
  const [filter, setFilter] = useState({
    start: sixMonths,
    end: today,
  });

  const handleChangeFilter = (newValues) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...newValues,
    }));
  };

  //current time
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const handleClearFilters = () => {
    handleChangeFilter({ start: null, end: null });
  };

  useEffect(() => {
    handleFilters(filter); //fazer o 1º fetch padrão
  }, []);

  const handleFilters = () => {
    openBackdrop(true, "Aplicando filtros");

    const start = getDateStart(filter?.start);
    const end = getDateEnd(filter?.end);

    const params = {
      start,
      end,
    };

    setDataIntoState(params);
  };

  const setDataIntoState = async (params) => {
    try {
      openBackdrop(true, "Carregando informação dos eventos");
      const response = await getData(params);
      const data = response?.reverse(); //para o mais recente ficar em cima na tabela
      setEventsData(data);
      setStorageData(data);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos dados, verifique sua conexão",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const getData = async (params) => {
    const events = await apiReinf.getProtocolHistory(params);
    if (events) {
      return events;
    } else {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos eventos, verifique sua conexão",
        type: "error",
      });
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleSearchTable(value, storageData, setEventsData);
  };

  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = eventsData.slice(startIndex, endIndex);

  const minDate = new Date(currentYear - 10, currentMonth, 1); //6 anos atrás
  const maxDate = new Date(); //Hoje

  //ERRO: Data inválida (FUTURO)
  const isStartDateInTheFuture = filter?.start > maxDate;
  const isEndDateInTheFuture = filter?.end > maxDate;
  const isDateWrongText = "Selecione uma data no presente.";
  //ERRO: Data inicial maior do que final
  const isStartDateHigherThanEnd = filter?.start > filter?.end;
  const isStartDateHigherThanEndText =
    isStartDateHigherThanEnd && "Data inicial superior à data final.";
  // ERRO: Data inválida (não totalmente preenchida)
  const isStartDateInvalid = !filter?.start || filter?.start == "Invalid Date";
  const isEndDateInvalid = !filter?.end || filter?.end == "Invalid Date";

  //INVALIDAR BOTÃO APLICAR
  const disabledBtn =
    isStartDateInTheFuture ||
    isEndDateInTheFuture ||
    isStartDateHigherThanEnd ||
    isStartDateInvalid ||
    isEndDateInvalid;

  return (
    <>
      <HeaderSectionPage title="Histórico de Eventos" />
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 2,
          marginBottom: 1,
          paddingInline: 2,
        }}
      >
        <Grid item>
          <Tooltip title="Apagar todos os filtros" onClick={handleClearFilters}>
            <IconButton>
              <DeleteOutline sx={{ color: "#000000" }} fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item sx="auto">
          <ActiveFilters filter={filter} handleChangeFilter={handleChangeFilter} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          paddingTop: 1,
          paddingInline: 3,
        }}
      >
        <Grid item xs={12} sm={6} md={3} sx={styles.filterContainer}>
          <p style={{ fontSize: 12, fontStyle: "italic" }}>Data Início</p>
          <Input
            styles={styles.dateInput}
            minDate={minDate}
            maxDate={filter?.end || maxDate}
            type="date"
            viewType="month-year"
            value={filter?.start}
            onChange={(date) => {
              handleChangeFilter({ start: date });
            }}
            styleControl={{ height: "47px" }}
            error={isStartDateInTheFuture || isStartDateHigherThanEndText}
            errorText={(isStartDateInTheFuture && isDateWrongText) || isStartDateHigherThanEndText}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={styles.filterContainer}>
          <p style={{ fontSize: 12, fontStyle: "italic" }}>Data Final</p>
          <Input
            styles={styles.dateInput}
            minDate={filter?.start || minDate}
            maxDate={maxDate}
            type="date"
            viewType="month-year"
            value={filter?.end}
            onChange={(date) => {
              handleChangeFilter({ end: date });
            }}
            styleControl={{ height: "47px" }}
            error={isEndDateInTheFuture || isStartDateHigherThanEndText}
            errorText={(isEndDateInTheFuture && isDateWrongText) || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ ...styles.filterContainer, marginBottom: "12px" }}>
          <p style={{ fontSize: 12, fontStyle: "italic" }}>Pesquisa</p>
          <Box sx={styles.searchContainer}>
            <input
              style={styles.searchInput}
              type="text"
              onChange={handleSearch}
              placeholder="Pesquisar..."
              value={searchTerm}
            />
            <Search />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={3} sx={{ marginTop: "1.45rem" }}>
          <RButton
            disabled={disabledBtn}
            onClick={handleFilters}
            sx={{
              height: "45px",
            }}
          >
            APLICAR
          </RButton>
        </Grid>
      </Grid>
      <Box sx={{ borderRadius: "10px", overflow: "hidden", marginInline: 3, marginBlock: 2 }}>
        <Table sx={{ borderCollapse: "separate", borderSpacing: "0" }}>
          <TableHead>
            <TableRow sx={styles.HeadTableRow}>
              <TableCell sx={styles.TableCellHead}>Protocolo</TableCell>
              <TableCell sx={styles.TableCellHead}>Nº Recibo</TableCell>
              <TableCell sx={styles.TableCellHead}>Data do envio</TableCell>
              <TableCell sx={styles.TableCellHead}>Email do usuário</TableCell>
              <TableCell sx={styles.TableCellHead}>Evento</TableCell>
              <TableCell sx={styles.TableCellHead}>Situação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData?.length > 0 ? (
              currentData?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell sx={styles.TableCell}>{item.protocol || "-"}</TableCell>
                      <TableCell sx={styles.TableCell}>{item.receiptNumber || "-"}</TableCell>
                      <TableCell sx={styles.TableCell}>
                        {item.sendDate ? formatDateToBrazilian(item.sendDate) : "-"}
                      </TableCell>
                      <TableCell sx={styles.TableCell}>{item.userSend || "-"}</TableCell>
                      <TableCell sx={styles.TableCell}>{item.reinfEvent || "-"}</TableCell>
                      <TableCell sx={styles.TableCell}>{item.situation || "-"}</TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })
            ) : (
              <TableRow>
                <TableCell sx={styles.TableCell} colSpan="100%">
                  <Typography sx={{ fontFamily: "Montserrat" }}>
                    {!filter.month || !filter.year
                      ? "Selecione o período de datas para filtrar."
                      : "Não existe eventos no período escolhido."}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {eventsData?.length > 9 && (
          <Stack
            spacing={1}
            sx={{ background: "#F2F2F2", paddingRight: 8, borderRadius: "0px 0px 8px 8px" }}
          >
            <TablePagination
              component="div"
              count={eventsData?.length || 0}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Informações por página:"
              rowsPerPageOptions={[10, 15, 20]}
              sx={{
                ".MuiTablePagination-selectLabel ": {
                  margin: 0,
                },
                ".MuiTablePagination-displayedRows": {
                  margin: 0,
                },
              }}
            />
          </Stack>
        )}
      </Box>
    </>
  );
}

export default EventsHistory;

const styles = {
  Button: {
    width: "fit-content",
    borderRadius: "8px",
    boxShadow: 0,
    textTransform: "uppercase",
  },
  //LINHA TÍTULOS COLUNAS
  HeadTableRow: {},
  //LINHA TÍTULOS DAS COLUANS
  TableCellHead: {
    backgroundColor: "#1341A1",
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#ffffff",
    fontSize: 13,
    padding: "10px",
    fontWeight: 400,
    position: "relative",
    //DIVISOR COLUNAS HEAD
    "&:not(:first-child)::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "10%",
      bottom: "10%",
      left: 0,
      width: "3px",
      backgroundColor: "#142F68",
    },
  },
  //CADA LINHA (NOTA)
  TableCell: {
    fontFamily: "Montserrat",
    fontSize: 14,
    paddingBlock: "12px",
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
    borderBottom: "5px solid #F2F2F2",
  },
  //CADA LINHA
  TableRow: {},
  //COLAPSE
  CollapseContainer: {
    paddingBlock: "3px",
    backgroundColor: "#F2F2F2",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#DCDCDC",
    paddingRight: 2,
    paddingLeft: 2,
    borderRadius: "5px",
    height: "45px",
    marginTop: "5px",
  },
  searchInput: {
    fontFamily: "Montserrat",
    fontSize: 14,
    border: "none",
    background: "transparent",
    width: "100%",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  serachIcon: {
    color: "#1341A1",
  },
  dateInput: {
    backgroundColor: "#DCDCDC",
    borderRadius: "5px",
    height: "45px",
  },
};
