import React from "react";
import {makeBlogUseCase} from "~/main/factories/use-cases/blog";
import BlogHome from "~/presentation/views/Blog/BlogHome";
import {makeBlogCategoryUseCases} from "~/main/factories/use-cases/blog-category";

export const makeBlogHome = (params) => {
  return (
    <BlogHome
      {...params}
      blogUseCases={makeBlogUseCase()}
      blogCategoryUseCases={makeBlogCategoryUseCases()}
    />
  );
};
