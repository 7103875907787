import React from "react";
import { makeAnnotationUseCases } from "~/main/factories/use-cases/annotation";
import { makeCnaesUseCases } from "~/main/factories/use-cases/cnaes";
import { makeTermUseCases } from "~/main/factories/use-cases/term";
import AnnotationCreate from "~/presentation/views/Admin/Annotation/Create";

export const makeAnnotationCreate = (params) => (
  <AnnotationCreate
    {...params}
    cnaesUseCases={makeCnaesUseCases()}
    annotationUseCases={makeAnnotationUseCases()}
    termUseCases={makeTermUseCases()}
  />
);
