import React, { useContext } from "react";
import AppContext from "~/presentation/AppContext";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import {
  Container,
  Wrapper,
  ContentWrapper,
  TitleWrapper,
  IconAndTextWrapper,
  Form,
  FormWrapper,
  SentMessage,
  ButtonWrapper,
} from "~/presentation/views/LandingPage/Home/Sections/Contact/styles";
import {
  Call as CallIcon,
  MailOutline as MailOutlineIcon,
  Person as PersonIcon,
  Check as CheckIcon,
} from "@mui/icons-material/";
import { Formik, Field } from "formik";
import TextInput from "~/presentation/components/TextInputLight";
import TextArea from "~/presentation/components/TextAreaLight";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import ErrorMessage from "~/presentation/components/ErrorMessage";
import validationSchema from "~/presentation/views/LandingPage/Home/Sections/Contact/validationSchema";
import { useState } from "react";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

const Contact = ({ id, onSubmitContactForm }) => {
  const context = useContext(AppContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [sentMessage, setSentMessage] = useState(false);

  const sendAnimation = () => {
    const wrapper = document.querySelector("[data-animation='wrapper']");
    const container = document.querySelector("[data-animation='container']");
    const animDuration = 1000;

    wrapper.animate(
      [
        { transform: "scale(1)" },
        { transform: "scale(0.8) translateX(0%)" },
        { transform: "scale(0.8) translateX(130%)" },
      ],
      {
        duration: animDuration,
        easing: "cubic-bezier(0.68, -0.55, 0.265, 1.55)",
        fill: "forwards",
      }
    );
    // remove wrapper section after animation
    setTimeout(() => {
      container.removeChild(wrapper);
    }, animDuration);
  };

  const sentMsgAnimation = () => {
    const sentMsg = document.querySelector("[data-animation='sent-message']");

    // opacity animation for thank container
    sentMsg.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: 1500,
      easing: "ease-in-out",
      fill: "forwards",
      delay: 400,
    });
  };

  useEffect(() => {
    if (sentMessage) {
      sendAnimation();
      sentMsgAnimation();
    }
  }, [sentMessage]);

  return (
    <Container>
      <Wrapper id={id}>
        <img src="/assets/bg/bg-opensoluc.png" alt="quotation marks bg" />
        <ContentWrapper>
          <div>
            <TitleWrapper>
              <h2>Contato</h2>
              <div className="line" />
            </TitleWrapper>
            <p>
              Mantenha contato conosco através do preenchimento do formulário ao lado ou pelos
              seguintes canais de contato:
            </p>
            <IconAndTextWrapper>
              <CallIcon fontSize="small" />
              <span>0800 888 1482</span>
            </IconAndTextWrapper>
            <IconAndTextWrapper>
              <MailOutlineIcon fontSize="small" />
              <span>contato@gestaotributaria.com.br</span>
            </IconAndTextWrapper>
          </div>

          <FormWrapper data-animation="container">
            {sentMessage && (
              <SentMessage data-animation="sent-message">
                <CheckIcon />
                <div>
                  <div>Mensagem enviada.</div>
                  <div>Em breve entraremos em contato!</div>
                </div>
              </SentMessage>
            )}

            <Formik
              initialValues={{
                name: "",
                email: "",
                subject: "",
                message: "",
                phoneNumber: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, action) => {
                action.setSubmitting(true);
                try {
                  await onSubmitContactForm(values);
                  setSentMessage(true);
                } catch (error) {
                  setFeedbackSnackbar({
                    isOpen: true,
                    message: error?.response?.data
                      ? error?.response?.data
                      : "Erro ao enviar mensagem",
                    type: "error",
                  });
                }
                action.setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form data-animation="wrapper">
                  <div className="form-grid">
                    <div className="col-1_3">
                      <Field
                        icon={PersonIcon}
                        placeholder="Nome"
                        name="name"
                        type="text"
                        as={TextInput}
                      />
                      <ErrorMessage name="name" />
                    </div>

                    <div className="col-3_5">
                      <Field
                        icon={MailOutlineIcon}
                        placeholder="E-mail"
                        name="email"
                        type="text"
                        as={TextInput}
                      />
                      <ErrorMessage name="email" />
                    </div>

                    <div className="col-1_5">
                      <Field placeholder="Assunto" name="subject" type="text" as={TextInput} />
                      <ErrorMessage name="subject" />
                    </div>

                    <div className="col-1_5">
                      <Field placeholder="Celular" name="phoneNumber" type="text" as={TextInput} />
                      <ErrorMessage name="phoneNumber" />
                    </div>

                    <div className="col-1_5">
                      <Field placeholder="Mensagem" name="message" rows={6} as={TextArea} />
                      <ErrorMessage name="message" />
                    </div>
                    <div className="col-1_5">
                      <ButtonWrapper>
                        <button className="button" type="submit">
                          {isSubmitting ? (
                            <CircularProgress color="inherit" size="22px" />
                          ) : (
                            "ENVIAR"
                          )}
                        </button>
                      </ButtonWrapper>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </FormWrapper>
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

export default Contact;
