import * as Yup from "yup";
import removeMask from "~/presentation/functions/removeMask";
import { validarCNPJ } from "~/presentation/functions/cnpjValidator";
import { validarTelefone } from "~/presentation/functions/phoneValidator";
import { validarEmail } from "~/presentation/functions/emailValidator";

export default Yup.object({
  categoryId: Yup.string().required("Campo obrigatório!"),
  name: Yup.string().required("Campo obrigatório!"),
  cnpj: Yup.string()
    // .transform((value, originalValue) => originalValue && removeMask(value))
    .test("cnpj", "CNPJ inválido!", (value) => validarCNPJ(value))
    .required("Campo obrigatório!"),
  address: Yup.string().required("Campo obrigatório!"),
  cep: Yup.string()
    .transform((value, originalValue) => originalValue && removeMask(value))
    .min(8, "Digite um CEP válido!")
    .required("Campo obrigatório!"),
  city: Yup.string().required("Campo obrigatório!"),
  state: Yup.string().required("Campo obrigatório!"),
  email: Yup.string()
    // .email("Digite um email válido")
    .test("email", "Email inválido!", (value) => validarEmail(value))
    .required("Campo obrigatório!"),
  phone: Yup.string()
    .transform((value, originalValue) => originalValue && removeMask(value))
    .min(10, "Digite um número válido")
    .test("phone", "Telefone inválido!", (value) => value && validarTelefone(value))
    .required("Campo obrigatório!"),
  mobilePhone: Yup.string()
    .transform((value, originalValue) => originalValue && removeMask(value))
    .min(11, "Digite um número válido"),
  plan: Yup.string().required("Campo obrigatório!"),
  dateStart: Yup.date().required("Campo obrigatório"),
  dateEnd: Yup.date()
    .when(
      "dateStart",
      (dateStart, yup) =>
        dateStart &&
        yup.min(dateStart, "A data final do contrato não pode ser anterior à data inicial")
    )
    .typeError("Invalid Ended date")
    .required("Campo obrigatório"),
  status: Yup.boolean(),
  // allow_ifs: Yup.boolean(),
  // substitute: Yup.string().required("Campo obrigatório!"),
});
