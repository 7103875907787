import styled from "styled-components";
import PrimaryButton from "~/presentation/components/PrimaryButton";

export const Button = styled(PrimaryButton).attrs({
  backgroundColor: "#1341a1",
  size: "small",
  type: "submit",
})`
  &.MuiButton-root {
    align-self: center;
    min-width: 200px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: ${({ mt }) => (mt ? "40px" : "")};

    @media (min-width: 600px) {
      max-width: fit-content;
    }
  }

  .MuiButton-label {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;
