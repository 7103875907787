import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import AnnotationForm from "../Form";
import { useHistory } from "react-router-dom";
import { handleErrors } from "~/presentation/utils/errorsHandling";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

const initialValues = {
  id: 0,
  name: "",
  text: "",
  cnaes: [],
  terms: [],
};

const AnnotationCreate = ({ annotationUseCases, cnaesUseCases, termUseCases }) => {
  const [cnaes, setCnaes] = useState([]);
  const [terms, setTerms] = useState([]);

  const history = useHistory();

  const { openBackdrop } = useContext(BackdropContext);

  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const fetchData = async () => {
    try {
      const [cnaesListResponse, termsListResponse] = await Promise.all([
        cnaesUseCases.listCnaes(),
        termUseCases.getAll(),
      ]);

      setCnaes(cnaesListResponse.data);
      setTerms(termsListResponse);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function handleSubmit(values) {
    openBackdrop(true, "Cadastrando resposta");
    const { data } = await annotationUseCases.createAnnotation(values);
    const errors = handleErrors(data);
    openBackdrop(false);
    if (!errors.length) {
      history.push("/admin/anotacoes");
      setFeedbackSnackbar({
        isOpen: true,
        message: "Anotação cadastrada com sucesso!",
        type: "success",
      });
    } else {
      setFeedbackSnackbar({
        isOpen: true,
        message: errors.join(", "),
        type: "error",
      });
    }
  }

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Cadastrar Anotação" />
      <AnnotationForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        cnaes={cnaes}
        terms={terms}
      />
    </Container>
  );
};

export default AnnotationCreate;
