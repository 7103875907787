import React, { useContext, useState, useRef } from "react";
import { uploadFile } from "react-s3";
import { Field, Form, Formik } from "formik";
import ErrorMessage from "~/presentation/components/ErrorMessage";
import TextInput from "~/presentation/components/TextInput";

import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import validationSchema from "./validationSchema";
import randomString from "~/presentation/utils/randomString";
import { Button, Typography as Text, Box } from "@mui/material";
import { Send, Upload } from "@mui/icons-material";

import { Close } from "@mui/icons-material";
import { DialogContext } from "~/presentation/providers/DialogProvider";

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION_BUCKET,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

export const NewTIForm = ({ taxInformationUseCases, companyId, getRemainingTaxInformation }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const user = JSON.parse(localStorage.getItem("GT_user"));

  // const [selectedFile, setSelectedFile] = useState();

  // const uploadedImage = useRef(null);
  // Dentro do seu componente:
  const fileInputRef = useRef(null);

  // Função para chamar o clique do input:
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (files) => {
    try {
      if (files.length > 0) {
        let uploadedFiles = [];
        for (let index = 0; index < files.length; index++) {
          const renamedFile = new File([files[index]], `${randomString()}-${files[index].name}`);
          const upload = await uploadFile(renamedFile, config);
          const uploadedFile = {
            filename: upload.key.split("-")[1],
            url: upload.location,
          };
          uploadedFiles.push(uploadedFile);
          if (index === files.length - 1) return uploadedFiles;
        }
      } else {
        return false;
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao fazer upload.",
        type: "error",
      });
      return error;
    }
  };

  const handleSubmit = async (values) => {
    openDialogBox({
      message: `Deseja mesmo enviar esta IF?`,
      callback: async () => {
        try {
          openBackdrop(true, "Enviando IF");
          await taxInformationUseCases.sendTaxInformation(values);
          setFeedbackSnackbar({
            isOpen: true,
            message: "IF enviada com sucesso!",
            type: "success",
          });
        } catch (error) {
          setFeedbackSnackbar({
            isOpen: true,
            message: "Erro ao enviar IF : " + error.response.data,
            type: "error",
          });
        } finally {
          openBackdrop(false);
          getRemainingTaxInformation();
        }
      },
    });
  };

  return (
    <>
      <Formik
        initialValues={{ subject: "", message: "", files: [] }}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          let submitValues = { ...values, id: 0, userId: user.id };

          let uploads = {};

          if (values.files) {
            uploads = await handleFileUpload(values.files);
          }

          if (uploads.length > 0) {
            submitValues = {
              ...submitValues,
              files: uploads.length > 0 ? uploads : null,
            };
          }

          await handleSubmit(submitValues);

          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Text sx={styles.titleModal}>Assunto *</Text>
                <Field name="subject" type="text" as={TextInput} />
                <ErrorMessage name="subject" />

                <Text sx={styles.titleModal}>Mensagem *</Text>
                <Field name="message" type="text" multiline rows={6} as={TextInput} />
                <ErrorMessage name="message" />
              </Box>

              <Box sx={{ display: "flex", mt: 2, justifyContent: "space-between" }}>
                {values.files.length <= 0 && (
                  <Button variant="contained" startIcon={<Upload />} onClick={handleButtonClick}>
                    Upload de arquivos
                  </Button>
                )}

                <input
                  type="file"
                  name="file"
                  id="file"
                  multiple
                  ref={fileInputRef}
                  onChange={(event) => {
                    event.preventDefault();
                    setFieldValue("files", [...event.target.files]);
                  }}
                  style={{ display: "none" }}
                />

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {values.files &&
                    values.files.map((file) => (
                      <Text sx={styles.subtitle} key={file.name}>
                        {file.name}
                      </Text>
                    ))}
                  {values.files &&
                    values.files.map((file) => (
                      <Button
                        key={file.name}
                        onClick={() => {
                          setFieldValue("files", []);
                        }}
                        sx={{
                          padding: 2,
                          marginInline: 2,
                          height: 40,
                          width: 40,
                          minWidth: "auto",
                          borderRadius: 20,
                        }}
                      >
                        <Close />
                      </Button>
                    ))}
                </Box>

                <ErrorMessage name="files" />
                <Button
                  sx={{ backgroundColor: "#081445", ml: 2 }}
                  type="submit"
                  variant="contained"
                  startIcon={<Send />}
                  backgroundColor={"#081445"}
                >
                  Enviar
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const styles = {
  subtitle: {
    fontSize: 12,
    fontWeight: 700,
    width: "100%",
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
  },
  titleModal: {
    fontSize: 18,
    fontWeight: 500,
    width: "60%",
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
};
