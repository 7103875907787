import React, { useEffect, useState } from "react";
import { Title } from "../../../styles";
import { Grid, Box } from "@mui/material";
import handleMoney from "~/presentation/views/Reinf/components/HandleMoney/handleMoney";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";

function IR({
  values,
  nfseIndex,
  incomeNatureOptions,
  errors,
  isBcIRWrong,
  inputDisabled,
  modalCalculateRetention,
  handleChangeFieldValue,
}) {
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO

  //se é nota de produto NFe
  const isNFe = values?.typeDoc === 1;

  //bcIR
  const isBcIRWrongText =
    isBcIRWrong && "Valor da base IR não pode ser maior do que o Valor de Operação do documento!";

  //aliquotIR
  const aliquotValue = (values?.amountIR / values?.bcIR) * 100;
  const aliquotsABC = ["0.24", "1.20", "1.50", "2.40", "4.80"];
  const aliquotsD = ["1.00", "1.50"];
  const possibleAliquots = QSO === "D" ? [...aliquotsD] : [...aliquotsABC];
  const isAliquotValid = possibleAliquots?.includes(aliquotValue.toFixed(2));
  const isAliquotWrong = values?.bcIR > 0 && values?.amountIR > 0 && !isAliquotValid;
  const isAliquotWrongText = isAliquotWrong && "O valor da retenção pode estar incorreto";

  //ERROR: data no futuro
  const isDateInTheFuture = values?.dateOfPayment > new Date();
  const isDateWrong = isDateInTheFuture && "Escolha uma data no presente";

  return (
    <Box sx={styles.container}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Title>Retenção de IR</Title>
        {isNFe && modalCalculateRetention}
      </Box>
      <Box
        sx={{
          paddingTop: 2,
          paddingInline: 4,
          paddingBottom: 3,
          background: "#D9D9D9",
          borderRadius: 4,
          marginB: 0,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} lg={3}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions?.find((item) => item.id == values?.incomeNatureId)}
              onChange={(option) => {
                const { id, incomeDescription } = option;
                handleChangeFieldValue({
                  newItem: { incomeNatureId: id, incomeNatureDescription: incomeDescription },
                  index: nfseIndex,
                  field: "incomeNatureId",
                });
              }}
              error={!!errors[`[${nfseIndex}].incomeNatureId`]}
              errorText={errors[`[${nfseIndex}].incomeNatureId`]}
              disabled={inputDisabled === "R4020C" || inputDisabled === "R4020D"}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="date"
              title="Data do Crédito/Pagamento"
              value={values?.dateOfPayment || null}
              onChange={(date) => {
                handleChangeFieldValue({
                  newItem: { dateOfPayment: date },
                  index: nfseIndex,
                  field: "dateOfPayment",
                });
              }}
              error={!!errors[`[${nfseIndex}].dateOfPayment`] || isDateWrong}
              errorText={errors[`[${nfseIndex}].dateOfPayment`] || isDateWrong}
              disabled={inputDisabled === "R4020D"}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              title="Valor da Base do IRRF"
              value={values?.bcIR}
              onChange={(e) => {
                const value = handleMoney(e.target.value);
                handleChangeFieldValue({
                  newItem: { bcIR: value },
                  index: nfseIndex,
                  field: "bcIR",
                });
              }}
              error={!!errors[`[${nfseIndex}].bcIR`] || isBcIRWrongText}
              errorText={errors[`[${nfseIndex}].bcIR`] || isBcIRWrongText}
              disabled={inputDisabled === "R4020C" || inputDisabled === "R4020D"}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              title="Valor da Retenção do IRRF"
              value={values?.amountIR}
              onChange={(e) => {
                const value = handleMoney(e.target.value);
                handleChangeFieldValue({
                  newItem: { amountIR: value },
                  index: nfseIndex,
                  field: "amountIR",
                });
              }}
              error={!!errors[`[${nfseIndex}].amountIR`]}
              errorText={errors[`[${nfseIndex}].amountIR`]}
              disabled={inputDisabled === "R4020C" || inputDisabled === "R4020D"}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              prefix="%"
              title="Alíquota"
              value={values?.aliquotIR}
              disabled
              error={isAliquotWrongText}
              errorText={isAliquotWrongText}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              title="Valor da Retenção não efetuada"
              value={values?.amountNotRetained}
              onChange={(e) => {
                const value = handleMoney(e.target.value);
                handleChangeFieldValue({
                  newItem: { amountNotRetained: value },
                  index: nfseIndex,
                  field: "amountNotRetained",
                });
              }}
              error={!!errors[`[${nfseIndex}].amountNotRetained`]}
              errorText={errors[`[${nfseIndex}].amountNotRetained`]}
              disabled={inputDisabled === "R4020C" || inputDisabled === "R4020D"}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    paddingBlock: 2,
  },
};
export default IR;
