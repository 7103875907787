import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
// packages
// components
import MessageReplyForm from "~/presentation/views/Admin/components/MessageReply";
import MessageFromToSubject from "~/presentation/components/MessageFromToSubject";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

//
import { useContext } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { AccordionHeader } from "~/presentation/components/MessageChat/styles";
import { format } from "date-fns";
import parse from "html-react-parser";
import { uploadFile } from "react-s3";
import randomString from "~/presentation/utils/randomString";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Container,
  Box,
  Typography,
} from "@mui/material";
import HeaderForm from "../../components/HeaderForm/HeaderForm";

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION_BUCKET,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const ReplyTaxInformation = ({ taxInformationUseCases }) => {
  const id = useParams().id;
  const history = useHistory();
  const [chat, setChat] = useState();
  const [accordionsExpanded, setAccordionsExpanded] = useState([]);
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const isExpanded = (id) => accordionsExpanded.includes(id);

  const fetchChat = async () => {
    const response = await taxInformationUseCases.getById(id);
    setChat(response);
  };

  const handleFileUpload = async (files) => {
    try {
      if (files) {
        let uploadedFiles = [];
        for (let index = 0; index < files.length; index++) {
          const renamedFile = new File([files[index]], `${randomString()}-${files[index].name}`);
          const upload = await uploadFile(renamedFile, config);
          const uploadedFile = {
            filename: upload.key.split("-")[1],
            url: upload.location,
          };
          uploadedFiles.push(uploadedFile);
          if (index === files.length - 1) return uploadedFiles;
        }
      } else {
        return false;
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao fazer upload.",
        type: "error",
      });
      return error;
    }
  };

  const handleSubmit = async (values) => {
    backdropOpen(values.draft);
    try {
      let submitValues = {
        id: 0,
        message: values.response,
        taxInformationId: id,
        userId: context.user.id,
        isMarkFinally: !values.draft,
        files: values.files,
      };
      await taxInformationUseCases.sendReply(submitValues);
      history.push("/admin/informacoes-fiscais");
    } catch (error) {
      console.log(error);
      setFeedbackFail(values.draft);
    }
    closeBackdrop();
  };

  const handleReopen = async () => {
    await openBackdrop(true, "Reabrindo IF");
    try {
      await taxInformationUseCases.reopen(id);
      history.push("/admin/informacoes-fiscais");
    } catch (error) {
      console.log(error);
      setFeedbackFail(true);
    }
    closeBackdrop();
  };

  const toggleAccordionExpanded = (id) => {
    if (isExpanded(id)) {
      setAccordionsExpanded([...accordionsExpanded].filter((accId) => accId !== id));
    } else {
      setAccordionsExpanded([...accordionsExpanded, id]);
    }
  };

  const backdropOpen = (isDraft) => {
    openBackdrop(true, `${isDraft ? "Salvando" : "Enviando"} IF`);
  };

  const closeBackdrop = () => openBackdrop(false);

  const setFeedbackFail = (isDraft) => {
    setFeedbackSnackbar({
      isOpen: true,
      message: `Erro ao ${isDraft ? "salvar" : "enviar"} IF: missing endpoint`,
      type: "error",
    });
  };

  useEffect(() => {
    fetchChat();
  }, []);

  return (
    <Container maxWidth={false}>
      <HeaderForm title="Responder IF" backLink="/admin/informacoes-fiscais" />
      {chat ? (
        <>
          <>
            <MessageFromToSubject from={chat.email} to="Gestão Tributária" subject={chat.subject} />
            {Array.isArray(chat?.files) && chat?.files.length > 0 && (
              <Box sx={styles.box}>
                <Typography sx={styles.subTitle}>Arquivos</Typography>
                {chat.files?.map((file, index) => (
                  <Box key={`file:${index}`} style={{ marginBlock: 1 }}>
                    <a
                      href={file.url}
                      download
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        color: "#000",
                      }}
                    >
                      <InsertDriveFileIcon />
                      {file.filename} - Clique para baixar
                    </a>
                  </Box>
                ))}
                <hr
                  style={{ marginTop: 10, border: "none", height: 1, backgroundColor: "#27292a" }}
                />
              </Box>
            )}
            <Accordion
              key={"message-chat-accordion-" + id}
              square={false}
              expanded={isExpanded(id)}
              onChange={() => toggleAccordionExpanded(id)}
              className={"accordion-root"}
              sx={styles.box}
            >
              <AccordionSummary>
                <AccordionHeader>
                  <div>
                    <Typography sx={styles.subTitle}>
                      Remetente da IF: <Typography sx={styles.text}>{chat.email}</Typography>
                    </Typography>

                    <small className="messageDateTime">
                      {`${format(new Date(chat.publishDate), "dd/MM/yyyy")} às ${new Date(
                        chat.publishDate
                      ).toLocaleTimeString("pt-BR")}`}
                    </small>
                  </div>

                  <small className={`messagePreview ${isExpanded(id) && "hide"}`}>
                    {parse(chat.message)}
                  </small>
                </AccordionHeader>
              </AccordionSummary>
              <AccordionDetails>
                <div>{parse(chat.message)}</div>
              </AccordionDetails>
              {chat.responses?.map((response, index) => (
                <AccordionDetails key={`response:${index}`}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {response.files?.map((file, index) => (
                      <div key={`file:${index}`} style={{ marginBlock: 5 }}>
                        <a
                          href={file.url}
                          download
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            color: "white",
                            paddingBlock: 5,
                          }}
                        >
                          <InsertDriveFileIcon />
                          {file.filename} - Clique para baixar
                        </a>
                      </div>
                    ))}
                    <div style={{ marginBlock: 10 }}>{parse(response.message)}</div>
                    <div style={{ marginBlock: 10, fontSize: 12 }}>
                      {format(new Date(response.responseDate), "dd/MM/yyyy")}
                    </div>
                    <div> - {response.authorName || response.email}</div>
                  </div>
                </AccordionDetails>
              ))}
            </Accordion>
          </>
          {chat.status !== 3 ? (
            <MessageReplyForm
              draft={chat.draft}
              handleSubmit={handleSubmit}
              handleUpload={handleFileUpload}
              taxInfo={true}
            />
          ) : (
            <div style={{ marginInline: 10 }}>
              <p style={{ marginBottom: 30 }}>Solicitação Finalizada!</p>
              <FormSubmitButtonsDock>
                <PrimaryButton type="button" onClick={handleReopen}>
                  Reabrir Solicitação
                </PrimaryButton>
              </FormSubmitButtonsDock>
            </div>
          )}
        </>
      ) : (
        <div className="absolute-center">
          <CircularProgress color="inherit" />
        </div>
      )}
    </Container>
  );
};

export default ReplyTaxInformation;

const styles = {
  box: {
    backgroundColor: "#E6E6E6",
    borderRadius: 2,
    marginBlock: 2,
    paddingBlock: 3,
    paddingInline: 2,
  },
  subTitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: "#242424",
    paddingBlock: 0.2,
  },
  text: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 15,
    fontWeight: 500,
    color: "#242424",
    paddingBlock: 0.2,
  },
};
