import React from 'react';
import { makeTypeAnswerUseCases } from '~/main/factories/use-cases/types-answer';
import TypeAnswerTable from '~/presentation/views/Admin/TypeAnswer/Table';


export const makeTypesAnswerTable = (params) => (
    <TypeAnswerTable
        {...params}
        typesAnswerUseCases={makeTypeAnswerUseCases()}
    />
)