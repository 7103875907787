import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
  margin-bottom: auto;

  .formMessage {
    font-size: 16px;
    line-height: 133.19%;
    letter-spacing: 0.02em;
    font-weight: 300;

    > b {
      font-weight: normal;
    }
  }
`;
