import styled from "styled-components";
import { IconButton } from "@mui/material";

export const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 80%;
  margin: 0 auto;
  top: 0;
  left: 0;
  z-index: 4;
  /* pointer-events: ${({ open }) => (open ? "unset" : "none")}; */
  display: ${({ open }) => (open ? "block" : "none")};

  > div.background {
    background: #14213d;
    height: 100%;

    .MuiSvgIcon-root {
      color: #fafafa;
    }
  }

  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  top: 50%;
  max-height: 90vh;
  overflow: auto;
`;

export const CloseBtn = styled(IconButton)`
  &.MuiIconButton-root {
    color: #fafafa;
    position: absolute;
    z-index: 5;
    right: 0%;
    top: 0%;
  }
`;

export const OverlayContainerModal = styled.div`
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100svh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  opacity: ${({ open }) => (open ? "1" : "0")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  overflow: hidden;
`;
