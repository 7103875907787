import React from "react";
import {Container} from "./styles";
import useWindowSize from "~/presentation/hooks/useWindowSize";

const Logo = () => <img src="/assets/logo_gt_branco_mini.png" alt="logo gt" />;

const Text1 = () => (
  <div className="text-wrapper">
    <span className="light">Este é um serviço da </span>
    <span> Open Soluções Tributárias</span>
  </div>
);

const Text2 = () => (
  <div className="text-wrapper">
    <span>2020 © Gestão Tributária </span>
    <span className="light"> Todos os direitos reservados</span>
  </div>
);

const Footer = () => {
  const {width: windowWidth} = useWindowSize();
  if (windowWidth < 768) {
    return (
      <Container>
        <Logo />
        <div>
          <Text1 />
          <Text2 />
        </div>
      </Container>
    );
  } else {
    return (
      <Container>
        <Text1 />
        <Logo />
        <Text2 />
      </Container>
    );
  }
};

export default Footer;
