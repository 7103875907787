import React, {useState} from "react";
import ShouldSubscribeModal from "../ShouldSubscribeModal";
import SubscribeModal from "~/presentation/views/LandingPage/Home/Modals/SubscribeModal";

const ShouldAndSubscribeModals = () => {
  const [step, setStep] = useState(0);
  const handleNavigate = () => {
    setStep(1);
  };

  return step === 0 ? (
    <ShouldSubscribeModal handleNavigate={handleNavigate} />
  ) : (
    <SubscribeModal />
  );
};

export default ShouldAndSubscribeModals;
