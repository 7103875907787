import React, { useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import Form from "./Form";

function RegisterEdit({
  data,
  onCloseForm,
  refresData,
  userUseCases,
  companies,
  userGroups,
  fetchCompanys,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  useEffect(() => {
    if (companies.length === 0 || userGroups.length === 0) {
      fetchCompanys();
    }
  }, []);

  const handleSubmit = async (values, resetForm) => {
    openBackdrop(true, `${isCreate ? "Registando" : "Editando"}  usuário`);
    const { companyId, name, email, homePhone, mobilePhone, role } = values;
    try {
      if (isCreate) {
        await userUseCases.register(values);
      } else {
        await userUseCases.updateUser(data.id, {
          companyId,
          name,
          email,
          homePhone,
          mobilePhone,
          role,
        });
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Usuário ${isCreate ? "registado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refresData();
      if (isCreate) resetForm();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const getInitialValues = () => {
    if (data) {
      return {
        name: data.name,
        email: data.email,
        role: data.role,
        company: data.companyId,
        password: "********************",
        confirm_password: "********************",
        phone: data.homePhone,
        mobile_phone: data.mobilePhone,
      };
    }
    return {
      company: "",
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      phone: "",
      mobile_phone: "",
    };
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Registar" : "Editar"} usuário`} backFnc={onCloseForm} />
      <Form
        handleSubmit={handleSubmit}
        initialValues={getInitialValues()}
        editing={data?.id}
        companies={companies}
        userGroups={userGroups}
      />
    </>
  );
}

export default RegisterEdit;
