import React from 'react';
import {
  TextWrapper,
  Container,
  VideoContainer,
  OuterContainer,
} from '~/presentation/views/LandingPage/Home/Sections/About/styles';
import { Fade } from 'react-reveal';
import AboutModal from '~/presentation/views/LandingPage/Home/Modals/AboutModal';

const About = ({ id, openModal }) => {
  const handleOpen = () => {
    openModal(AboutModal);
  };

  return (
    <OuterContainer id={id}>
      <Container id={id}>
        <div>
          <Fade bottom distance="20%">
            <VideoContainer>
              <iframe
                src="https://player.vimeo.com/video/478875163?title=0&byline=0&portrait=0"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </VideoContainer>
            <TextWrapper>
              <div id="column">
                <span>APRESENTAÇÃO</span>
                <h2>Gestão Tributária</h2>
                <p>
                  O Gestão Tributária é um sistema <i>web</i> que surgiu no
                  intuito de auxiliar entidades públicas e privadas na apuração
                  dos principais tributos incidentes sobre a contratação de
                  terceiros (pessoas físicas e jurídicas). Nossa solução é
                  focada nas retenções e encargos tributários relativos ao INSS,
                  IRRF, CSLL, PIS/Pasep, Cofins e ISS.
                </p>
              </div>
              <div id="rightLine" />
            </TextWrapper>
          </Fade>
        </div>
      </Container>
    </OuterContainer>
  );
};

export default About;
