import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch,
  Typography as Text,
} from "@mui/material";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import RButton from "../../../components/Common/Buttons/RButton";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { EventDetailsModal } from "~/presentation/views/Reinf/SendReinf/components/EventSend/EventDetailsModal";
import { formatDateToISO } from "~/presentation/views/Reinf/Utils/utilsDate";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { Close } from "@mui/icons-material";
import { ApiCompanyUseCases } from "~/data/usecases/company/apiCompany";
import { PriorityHigh } from "@mui/icons-material";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";

const initialItems = [
  { id: "R-2010", label: "Contratou serviços sujeitos à retenção de INSS?", checked: false },
  { id: "R-2020", label: "Prestou serviços sujeitos à retenção de INSS?", checked: false },
  {
    id: "R-2030",
    label:
      "A associação desportiva que mantém equipe de futebol profissional possui informações sobre recursos recebidos?",
    checked: false,
  },
  {
    id: "R-2040",
    label:
      "Possui informações sobre repasses efetuados à associação desportiva que mantém equipe de futebol profissional?",
    checked: false,
  },
  {
    id: "R-2050",
    label: "O produtor rural PJ/Agroindústria possui informações de comercialização de produção?",
    checked: false,
  },
  { id: "R-2055", label: "Possui informações sobre aquisição de produção rural?", checked: false },
  {
    id: "R-2060",
    label:
      "Possui informações sobre a apuração da contribuição previdenciária sobre a receita bruta (CPRB)?",
    checked: false,
  },
];

const SendR2099 = ({
  filters,
  certificateData,
  isEventClosed,
  handleVerifyEventStatus,
  currentEventsData,
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const companyUseCases = new ApiCompanyUseCases(makeHttpClient());
  const apiSendReinf = new ApiReinfUseCases(makeHttpClient());

  const [items, setItems] = useState(initialItems);
  const [responseDetails, setResponseDetails] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [digitalCertificate, setDigitalCertificate] = useState();
  const [UserCompany, setUserCompany] = useState();
  const [isProduction, setIsProduction] = useState(false);

  const isNoMovment = currentEventsData.length === 0;

  const isProductionOptions = [
    { value: false, label: "Produção Restrita" },
    { value: true, label: "Produção" },
  ];

  const handleToggle = (id) => () => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item))
    );
  };

  useEffect(() => {
    if (responseDetails) {
      setModalOpen(true);
    }
  }, [responseDetails]);

  const getCompany = async () => {
    const companyIdFromLocalStorage = JSON.parse(
      localStorage.getItem("GT_user") || "{}"
    )?.companyId;

    const result = await companyUseCases.getCompanyById(companyIdFromLocalStorage);
    setUserCompany(result);
  };

  const companyIdFromLocalStorage = JSON.parse(localStorage.getItem("GT_user") || "{}")?.companyId;
  const competence = formatDateToISO(filters?.year, filters?.month);

  const handleSubmit = async () => {
    openBackdrop(true, "Enviando dados do Evento...");
    if (!companyIdFromLocalStorage) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao enviar: Usuário não possui empresa",
        type: "error",
      });
      openBackdrop(false);
      return;
    }

    const formmatedValues = {
      companyId: companyIdFromLocalStorage,
      digitalCertId: filters?.certificate,
      calculationDate: competence,
      isProduction: isProduction,
      isR2010: items[0].checked,
      isR2020: items[1].checked,
      isR2030: items[2].checked,
      isR2040: items[3].checked,
      isR2050: items[4].checked,
      isR2055: items[5].checked,
      isR2060: items[6].checked,
    };
    sendReinf2099(formmatedValues);
  };

  const sendReinf2099 = async (params) => {
    try {
      const response = await apiSendReinf.sendEvent(params, "r2099");
      setResponseDetails(response);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Evento registrado com sucesso",
        type: "success",
      });
    } catch (error) {
      console.error("Erro ao enviar evento: ", error);
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro ao enviar: ${error.data}`,
        type: "error",
      });
    } finally {
      handleVerifyEventStatus(competence, "r2099");
      openBackdrop(false);
    }
  };

  useEffect(() => {
    getCompany();
    setDigitalCertificate(certificateData.find((cert) => cert.id === filters?.certificate));
  }, []);

  return (
    <>
      <Grid container spacing={2} sx={{ padding: 3 }}>
        <Grid item xs={10} sm={6} md={3}>
          <RButton
            onClick={() => {
              setModalOpen(true);
            }}
            sx={{
              height: "60px",
            }}
            disabled={!isNoMovment || isEventClosed}
          >
            FECHAR R-2099 SEM MOVIMENTO
          </RButton>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Box sx={{ width: "100%", mx: 3, padding: 4, backgroundColor: "#f2f2f2", borderRadius: 4 }}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
              borderRadius: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: 2,
                borderRadius: 8,
              }}
            >
              <h6>Informações de Fechamento</h6>
              <List>
                {items.map((item, index) => (
                  <Box key={item.id}>
                    <ListItem>
                      <ListItemText primary={item.label} />
                      <ListItemSecondaryAction>
                        <Switch
                          edge="end"
                          size="medium"
                          onChange={handleToggle(item.id)}
                          checked={item.checked}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    {index < items.length - 1 && <Divider />}
                  </Box>
                ))}
              </List>
            </Box>
          </Paper>
        </Box>
        <Container maxWidth={false} sx={{ paddingBlock: 2, display: "flex", mb: 6 }}>
          {isEventClosed && (
            <Text sx={{ mt: 2, display: "flex", alignItems: "center" }} color="error">
              <PriorityHigh />
              {`Período ${filters?.month.toString().length === 1 ? `0${filters?.month}` : filters?.month}/${filters?.year} está fechado. Para fazer modificações no período é necessário o envio de um evento de REABERTURA.`}
            </Text>
          )}
          <RButton
            sx={styles.buttonSend}
            onClick={() => setModalOpen(true)}
            disabled={
              !filters?.year ||
              !filters?.month ||
              !filters?.event ||
              !filters?.certificate ||
              isEventClosed ||
              isNoMovment
            }
          >
            Enviar
          </RButton>
        </Container>
      </Grid>
      <ModalContainer open={modalOpen} onClose={setModalOpen}>
        <Box sx={styles.ConfirmationModal}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={11}>
              <Text sx={styles.Title}>DADOS DO EVENTO R-2099 {isNoMovment && "SEM MOVIMENTO"}</Text>
            </Grid>
            <Grid
              item
              xs={6}
              sm={1}
              sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
            >
              <Close onClick={() => setModalOpen(false)} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                type="text"
                title="Estabelecimento"
                name="companyName"
                value={UserCompany?.name || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                type="text"
                title="Certificado Digital"
                name="digitalCertId"
                value={digitalCertificate?.name}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                type="select"
                title="Tipo de ambiente"
                name="isProduction"
                options={isProductionOptions}
                onChange={(option) => setIsProduction(option.value)}
                value={isProductionOptions.find((option) => option.value === isProduction)}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
            <RButton
              sx={{ width: "150px" }}
              type="button"
              variant="outlined"
              onClick={() => setModalOpen(false)}
            >
              Cancelar
            </RButton>
            <RButton sx={{ width: "150px" }} onClick={handleSubmit}>
              Enviar
            </RButton>
          </Box>
        </Box>
      </ModalContainer>
      {responseDetails && (
        <ModalContainer scroll={true} open={modalOpen}>
          <EventDetailsModal
            open={modalOpen}
            onClose={() => {
              setModalOpen(false);
              setResponseDetails(null);
            }}
            eventDetails={responseDetails}
          />
        </ModalContainer>
      )}
    </>
  );
};

export default SendR2099;

const styles = {
  title: {
    fontSize: 25,
    fontWeight: 600,
    letterSpacing: 0,
    color: "#242424",
    fontFamily: "Montserrat, sans-serif",
  },
  select: {
    minWidth: "100%",
    backgroundColor: "#E6E6E6",
  },
  buttonSend: {
    marginLeft: "auto",
    paddingBlock: 1.5,
    paddingInline: 12,
    width: "200px",
  },
  ButtonBox: {
    position: "fixed",
    zIndex: 999,
    right: "10px",
    display: "flex",
    flexDirection: "column",
    gap: 1,
    bottom: "280px",
    background: "white",
    borderRadius: "10px",
    padding: 1,
    border: "1px solid red",
  },
  Container: {
    paddingBlock: "2px",
    backgroundColor: "#F2F2F2",
  },
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    marginBottom: 1,
  },
  subTitle: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  ConfirmationModal: {
    p: 4,
  },
};
