import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from "styled-components";

export const Container = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 300;

  > div {
    display: flex;
    align-items: flex-start;
  }
`;

export const InfoIcon = styled(InfoOutlinedIcon).attrs({
  fontSize: "small",
})`
  margin: 0 12px 0 auto;
  color: #000;
  transform: scale(0.9);
`;

export const Excerpt = styled.div`
  margin-top: 8px;
  height: ${({ show }) => (show ? "fit-content" : 0)};
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  opacity: ${({ show }) => (show ? 1 : 0)};

  > span {
    font-size: 14px;
  }
`;
