import { apiURL } from "../../usecases/api";
export class ApiServiceCode {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async listService() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ServiceCode`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getServiceCodeById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ServiceCode/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async createService(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ServiceCode`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async updateServiceCode(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ServiceCode`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error.response;
    }
  }

  async deleteServiceCode(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ServiceCode/${id}`,
        method: "DELETE",
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}
