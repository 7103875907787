import React from "react";
import { makeIssAnswerUseCases } from "~/main/factories/use-cases/iss-answers";
// import { makeAnswerUseCases } from '~/main/factories/use-cases/answers';
// import { makeCityUseCases } from '~/main/factories/use-cases/city';
// import { makeCnaesUseCases } from '~/main/factories/use-cases/cnaes';
// import { makeCompanyUseCases } from '~/main/factories/use-cases/company';
// import { makeItemAnswerUseCases } from '~/main/factories/use-cases/item-answer';
import { makeTypeAnswerUseCases } from "~/main/factories/use-cases/types-answer";
import IssAnswerEdit from "~/presentation/views/Admin/IssAnswer/Edit";
// import { makeStateUseCases } from '~/main/factories/use-cases/state';
// import AnswerEdit from '~/presentation/views/Admin/Answer/Edit';

export const makeIssAnswerEdit = (params) => (
  <IssAnswerEdit
    {...params}
    makeIssAnswerUseCases={makeIssAnswerUseCases()}
    typesAnswerUseCases={makeTypeAnswerUseCases()}
    // companyUseCases={makeCompanyUseCases()}
    // cnaesUseCases={makeCnaesUseCases()}
    // answerUseCases={makeAnswerUseCases()}
    // stateUseCases={makeStateUseCases()}
    // cityUseCases={makeCityUseCases()}
  />
);
