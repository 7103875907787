import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import TextInput from "~/presentation/components/TextInput";
import Select from "~/presentation/components/Select";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextEditor from "~/presentation/components/TextEditor";
import validationSchemaItem from "./validationSchemaItem";

export const ItemModal = ({ item, itemAnswers, handleSaveItem, groups }) => {
  const { content } = item;

  const initialValues = {
    id: content.id,
    isDefault: content.isDefault.toString(),
    blockCode: content.blockCode,
    groups: content.groups,
    servicesCode: content.servicesCode,
    itemTypeId: content.itemTypeId,
    itemTypeName: content.itemTypeName,
    text: content.text,
  };

  const groupsMapped = (data) => {
    return data.map((group) => {
      return {
        value: group.id,
        label: group.value,
      };
    });
  };

  const defaultGroups = content.groups && groupsMapped(content.groups);

  const filterGroups = (groups) => {
    const selectedGroups = groups?.map((group) => {
      return {
        id: group.id ? group.id : group.value,
        value: group.label ? group.label : group.value,
      };
    });

    return selectedGroups;
  };

  const mappedGroups = groups && groupsMapped(groups);

  return (
    <div style={{ padding: 20 }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaItem}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          const submitValues = {
            id: values.id,
            isDefault: values.isDefault === "true" ? true : false,
            blockCode: values.blockCode,
            groups: filterGroups(values.groups),
            servicesCode: values.servicesCode,
            itemTypeId: values.itemTypeId,
            itemTypeName: values.itemTypeName,
            text: values.text,
          };

          await handleSaveItem(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="form-grid" style={{ paddingBottom: 20 }}>
              <div className="col-1_3">
                <h3>Padrão</h3>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="isDefault"
                    name="isDefault"
                    value={values.isDefault}
                    row
                    onChange={(event) => setFieldValue("isDefault", event.target.value)}
                  >
                    <FormControlLabel value={"true"} control={<Radio />} label="Sim" />
                    <FormControlLabel value={"false"} control={<Radio />} label="Não" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="col-1_5">
                <Field
                  name="itemTypeId"
                  label="Itens de resposta"
                  placeholder="Itens de resposta *"
                  component={Select}
                  value={itemAnswers.find((x) => x.id === values.itemTypeId)}
                  options={itemAnswers}
                  onChange={(option) => {
                    setFieldValue("itemTypeId", option.id);
                    setFieldValue("itemTypeName", option.title);
                  }}
                  getOptionLabel={(option) => option.title}
                />
                <ErrorMessage name="itemTypeId" />
              </div>
              <div className="col-1_5">
                <Field
                  name="servicesCode"
                  label="Código de serviço"
                  type="text"
                  onChange={(event) => setFieldValue("servicesCode", event.target.value)}
                  as={TextInput}
                />
                <ErrorMessage name="servicesCode" />
              </div>
              <div className="col-1_5">
                <Field
                  name="groups"
                  label="Grupo"
                  placeholder="Grupo"
                  isMulti={true}
                  options={mappedGroups}
                  component={Select}
                  onChange={(value) => {
                    setFieldValue("groups", value);
                  }}
                  defaultValue={defaultGroups}
                />
                <ErrorMessage name="groups" />
              </div>
              <div className="col-1_5">
                <Field
                  name="blockCode"
                  label="Bloco"
                  type="text"
                  onChange={(event) => setFieldValue("blockCode", event.target.value)}
                  as={TextInput}
                />
              </div>
              <div className="col-1_5">
                <TextEditor
                  name="text"
                  initialValue={values.text}
                  height={400}
                  handleEditorChange={(content) => {
                    setFieldValue("text", content);
                  }}
                />
                <ErrorMessage name="text" />
              </div>
            </div>
            <FormSubmitButtonsDock>
              <PrimaryButton className="submit-btn" type="submit">
                Salvar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </div>
  );
};
