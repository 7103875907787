import { apiURL } from "../../usecases/api";
export class ApiTypeAnswer {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getTypesAnswer() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/type-item-answer`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getTypeAnswer(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/type-item-answer/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async createTypeAnswer(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/type-item-answer`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async updateTypeAnswer(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answer/type-item-answer`,
        method: "PUT",
        data,
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async deleteTypeAnswer(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/answers/type-item-answer/${id}`,
        method: "DELETE",
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}
