import React, { useContext, useEffect, useState } from "react";
import {
  Description as DescriptionIcon,
  Dialpad as DialpadIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery } from "~/presentation/hooks/useQuery";
import { Card } from "~/presentation/views/GTFacil/components/Card";
import { GTLayout } from "~/presentation/views/GTFacil/components/GTLayout";
import { GenerateReportModal } from "~/presentation/views/GTFacil/Answer/components/GenerateReportModal";
import { AnswerContext } from "~/presentation/views/GTFacil/Answer/answer-context";
import { AnswerAccordion } from "~/presentation/views/GTFacil/Answer/components/AnswerAccordion/AnswerAccordion";
import { AnswerCard } from "~/presentation/views/GTFacil/Answer/components/AnswerCard";
import { Simulator } from "~/presentation/views/GTFacil/Simulator";
import { AnswerStyles } from "./Answer.styles";
import AppContext from "~/presentation/AppContext";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import generateGtFacilReport from "~/reports/gtfacil-report";
import { Dialog, DialogContent, lighten, Typography } from "@mui/material";
import { InfoIcon } from "../Search/components/SearchForm/styles";
import { companyTypes } from "./AnswerData";
import { Text, TextInnerHtml } from "~/presentation/views/GTFacil/components/SearchCard/styles";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";

const CONSTANT_OBSERVATION_LABEL_COMPONENT = `<b style='font-weight: 500;'>Observação: </b>`;

export const Answer = ({
  gtFacilUseCases,
  companyUseCases,
  cityUseCases,
  valueVariableUseCases,
  dialogUseCases,
  contentUseCases,
  // cnaeUseCases,
}) => {
  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));

  const location = useLocation();

  const [currentCompany, setCurrentCompany] = useState({});
  const [contractorCategory, setContractorCategory] = useState();
  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState({
    inss: false,
    irrf: false,
    csll: false,
    iss: false,
  });
  const [hiredType, setHiredType] = useState();
  const [answers, setAnswers] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [cities, setCities] = useState();
  const [fontSize, setFontSize] = useState(18);
  const [isOpen, setOpenDialog] = useState(false);

  useEffect(() => {
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const vwToPixels = viewportWidth * 0.013;
    const calculatedFontSize = Math.max(18, vwToPixels);
    setFontSize(calculatedFontSize);
  }, []);

  const updateFontSize = () => {
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const vwToPixels = viewportWidth * 0.013;
    const calculatedFontSize = Math.max(18, vwToPixels);
    setFontSize(calculatedFontSize);
  };

  const query = useQuery();
  const { haveSuperAdminOrAdminAccess } = useContext(AppContext);
  const history = useHistory();
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);
  const { backdropLoading, openBackdrop } = useContext(BackdropContext);

  useEffect(() => {
    window.addEventListener("resize", updateFontSize);
    return () => {
      window.removeEventListener("resize", updateFontSize);
    };
  }, []);

  const queryParams = {
    cnaeId: query.get("cnaeId"),
    categoryId: query.get("categoryId"),
    companyType: query.get("companyType"),
    isSimples: query.get("isSimples") === "true",
    cityHireId: query.get("cityHireId"),
    cityServiceId: query.get("cityServiceId"),
    cityContractorId: query.get("cityContractorId"),
    isBranch: query.get("isBranch"),
    searchTermId: query.get("searchTermId"),
  };

  const getCnaeFromLocation = () => {
    setData(location?.state?.data);
  };

  const getAnswers = async () => {
    try {
      openBackdrop(true, "Buscando respostas...");
      const { cnaeId, searchTermId } = queryParams;

      let body;

      if (searchTermId) {
        body = !queryParams.searchTermId ? { ...queryParams, searchTermId: data.id } : queryParams;
      } else {
        body = !queryParams.cnaeId ? { ...queryParams, cnaeId: data.id } : queryParams;
      }

      if (!body.cnaeId && !body.searchTermId) {
        openBackdrop(false);
        return;
      }
      const response = await gtFacilUseCases.getAnswerBy(body);
      if (response.data) {
        setAnswers(response?.data);
        openBackdrop(false);
      } else {
        setFeedbackSnackbar({
          isOpen: true,
          message: response.response.data,
          type: "error",
        });
        openBackdrop(false);
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: error.response?.data
          ? error.response?.data
          : "Erro ao realizar a pesquisa, tente novamente dentro de alguns minutos.",
        type: "error",
      });
      openBackdrop(false);
    }
  };

  const getContractorCategory = async () => {
    if (!queryParams.categoryId) return;
    const result = await companyUseCases.getCompanyCategoryById(queryParams.categoryId);
    setContractorCategory(result);
  };

  const getUserCompany = async () => {
    const result = await companyUseCases.getCompanyById(companyId);
    setCurrentCompany(result);
  };

  const getHiredType = () => {
    const type =
      queryParams?.companyType &&
      companyTypes.find((item) => item.value === Number(queryParams.companyType));
    setHiredType(type);
  };

  const answerCategories = [
    {
      name: "inss",
      label: "INSS na Cessão de Mão de Obra ou Empreitada",
      color: "#ff8040",
      disabled: disabled.inss,
    },
    {
      name: "irrf",
      label: "IRRF de Pessoas Jurídicas",
      color: "#ffbf40",
      disabled: disabled.irrf,
    },
    {
      name: "csll",
      label: "CSLL, PIS e COFINS",
      color: "#83bf30",
      disabled: disabled.csll,
    },
    {
      name: "iss",
      label: "ISS na Contratação de Pessoas Jurídicas",
      color: "#bf303c",
      disabled: disabled.iss,
    },
  ];

  const getCities = async () => {
    const { cityHireId, cityServiceId, cityContractorId } = queryParams;

    const citiesIds = [cityContractorId, cityHireId, cityServiceId];
    const shouldNotLoadCities = citiesIds.some((city) => !city);

    if (shouldNotLoadCities) {
      setCities([]);
      return;
    } else {
      const result = await Promise.all(
        citiesIds.length > 0
          ? citiesIds.map(async (city) => await cityUseCases.getCityById(city))
          : []
      );

      setCities([
        {
          label: "Município do Contratante:",
          name: `${result[0].name} - ${result[0].uf}`,
        },
        {
          label: "Município do Contratado:",
          name: `${result[1].name} - ${result[1].uf}`,
        },
        {
          label: "Município da Prestação:",
          name: `${result[2].name} - ${result[2].uf}`,
        },
      ]);
    }
  };

  const renderIsSimples = () => (queryParams.isSimples ? "Sim" : "Não");

  const renderAnswerItems = () => {
    return haveSuperAdminOrAdminAccess() ? (
      <>
        <InfoIcon onClick={() => setOpenDialog(true)} />
        <Dialog
          open={isOpen}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            {answers[0]?.items?.map((a, idx) => {
              return <Typography key={idx}>{`${a.name} - ${a.description}`}</Typography>;
            })}
          </DialogContent>
        </Dialog>
      </>
    ) : null;
  };

  const handleClick = (selected) => {
    setSelectedAnswer(selected);
  };

  const generateObservation = (values) => {
    const consultationData = location?.state?.data;
    let observation =
      consultationData.search === "cnae"
        ? consultationData.observation
        : `${values.cnae.code} - ${values.cnae.description}`;
    observation =
      consultationData.search !== "cnae" && values.cnae?.detailCode
        ? `${values.cnae.code} (${values.cnae?.detailCode}) - ${values.cnae.description}`
        : observation;
    return observation;
  };

  const generateSubitem = (values) => {
    const consultationData = location?.state?.data;
    let subItem = "";
    if (consultationData.search && consultationData.observation) {
      subItem +=
        consultationData.search === "cnae"
          ? `Relacionado ao subitem: ${values.cnae?.serviceCodes[0]?.code}`
          : `Relacionado ao CNAE: ${values.cnae?.code}`;
      subItem =
        consultationData.search !== "cnae" && values.cnae?.detailCode
          ? `${subItem} (${values.cnae?.detailCode})`
          : subItem;
    }
    return subItem;
  };

  const generateServiceCode = (values) => {
    const consultationData = location?.state?.data;
    let serviceCode =
      consultationData.search === "cnae" ? values.cnae.code : values.cnae?.serviceCodes[0]?.code;
    if (consultationData.search === "cnae" && values?.cnae?.detailCode) {
      serviceCode += ` (${values?.cnae?.detailCode})`;
    }
    return serviceCode;
  };

  const handleSubmitGenerateReport = async (values) => {
    const { inss, irrf, iss, csll, number } = values;
    const consultationData = location?.state?.data;

    let serviceCode = "";
    let subItem = "";
    let observation = "";
    let descriptionText = "";
    let serviceDescription = "";

    if (consultationData.search !== "freeSearch") {
      serviceCode = generateServiceCode(values);
      subItem = generateSubitem(values);
      observation = generateObservation(values);
      descriptionText =
        consultationData.search === "cnae"
          ? values.cnae.description
          : values.cnae?.serviceCodes[0]?.description;
      serviceDescription = `${descriptionText.endsWith(".") ? descriptionText : descriptionText + "."} ${subItem}`;
    }

    const data = {
      category: values.category,
      serviceCode,
      serviceDescription,
      observation: observation || "",
      contractType: hiredType.label,
      isSimples: queryParams.isSimples,
      inss: inss,
      irrf: irrf,
      iss: iss,
      csll: csll,
      number,
      cityContractor: cities[0]?.name ? cities[0]?.name : "",
      cityHire: cities[1]?.name ? cities[1]?.name : "",
      cityService: cities[2]?.name ? cities[2]?.name : "",
    };

    generateGtFacilReport(data);
  };

  const handleGenerateReport = () => {
    if (!answers) {
      return setFeedbackSnackbar({
        isOpen: true,
        message: "Não há respostas para gerar o relatório",
        type: "error",
      });
    }
    openDialogBox({
      // eslint-disable-next-line react/display-name
      customComponent: () => (
        <GenerateReportModal answer={answers} onSubmit={handleSubmitGenerateReport} />
      ),
      fullWidth: true,
      maxWidth: "sm",
    });
  };

  const handleSimulator = () => {
    openDialogBox({
      customComponent: () => (
        <Simulator
          cnae={data}
          answer={answers}
          hiredType={hiredType}
          contractorCategory={contractorCategory}
          isSimples={queryParams.isSimples}
          companyType={queryParams.companyType}
          handleClose={() => openDialogBox({ ...openDialogBox, open: false })}
        />
      ),
      fullWidth: true,
      maxWidth: "md",
    });
  };

  useEffect(() => {
    if (!location?.state?.data) history.push("/gt-facil");
    getCnaeFromLocation();
  }, [location]);

  useEffect(() => {
    if (data.id) {
      getAnswers();
    }
  }, [data]);

  useEffect(() => {
    if (hiredType?.value === 2) {
      setDisabled({ ...disabled, csll: true });
    }
  }, [hiredType]);

  useEffect(() => {
    getContractorCategory();
    getHiredType();
    getCities();
    getUserCompany();
  }, []);

  const getItem = (item) => {
    if (item != null && item.label.includes("INSS") && queryParams.companyType === "1") {
      item.label = "INSS dos Contribuintes Individuais";
    }
    return item;
  };

  return (
    <AnswerContext.Provider
      value={{
        gtFacilUseCases,
        companyUseCases,
        cityUseCases,
        valueVariableUseCases,
        dialogUseCases,
        contentUseCases,
      }}
    >
      <GTLayout title="Consulte a tributação de seus contratos com o GT-Fácil">
        <AnswerStyles.Container>
          {/* <SearchCard getSearch={getSearch} /> */}
          <Card
            title="Dados da operação consultada"
            // loading={isOperationDataLoading()}
          >
            <AnswerStyles.TextWrapper>
              {data.search === "freeSearch" ? (
                <AnswerStyles.Text>
                  <b>Pesquisa específica:</b> {data.term}
                </AnswerStyles.Text>
              ) : (
                <>
                  <AnswerStyles.Text>
                    <b>{data.search === "cnae" ? "Código CNAE:" : "Código na LC 116/2003:"} </b>{" "}
                    {data?.code} {data?.detailCode && `(${data?.detailCode})`} - {data?.description}
                  </AnswerStyles.Text>
                  <AnswerStyles.Text>
                    <b>
                      {data.search === "cnae" ? "Relacionado ao subitem:" : "Relacionado ao CNAE:"}
                    </b>{" "}
                    {data.observation}
                  </AnswerStyles.Text>
                </>
              )}
              <AnswerStyles.Text>
                <b>Natureza Jurídica do Contratante:</b> {contractorCategory?.name}
              </AnswerStyles.Text>
              <AnswerStyles.Text>
                <b>Tipo do Contratado:</b> {hiredType?.label}
              </AnswerStyles.Text>
              <AnswerStyles.Text>
                <b>Optante do Simples Nacional:</b> {renderIsSimples()}
              </AnswerStyles.Text>
              {cities?.map(({ label, name }) => (
                <AnswerStyles.Text key={label + name}>
                  <b>{label}</b> {name}
                </AnswerStyles.Text>
              ))}
              {data.annotation ? (
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                  <Text style={{ marginRight: 2 }}>
                    <b>Observação: </b>{" "}
                    <TextInnerHtml dangerouslySetInnerHTML={{ __html: data.annotation }} />
                  </Text>
                </div>
              ) : null}
              {answers && renderAnswerItems()}
            </AnswerStyles.TextWrapper>
          </Card>

          <Card title="Confira abaixo a tributação do item pesquisado" loading={backdropLoading}>
            {answers && (
              <AnswerStyles.AnswerCardsWrapper>
                {answerCategories.map((item, index) => {
                  const formattedItem = getItem(item);
                  formattedItem.color =
                    item.name === selectedAnswer ? item.color : lighten(item.color, 0.33);
                  return (
                    <AnswerCard
                      key={index}
                      disabled={item.disabled}
                      isPF={queryParams.companyType === "2"}
                      onClick={() => handleClick(item.name)}
                      answer={formattedItem}
                    />
                  );
                })}
              </AnswerStyles.AnswerCardsWrapper>
            )}
            {selectedAnswer && (
              <AnswerAccordion answers={answers} selectedCategory={selectedAnswer} />
            )}
            <AnswerStyles.ButtonsWrapper>
              <AnswerStyles.Button
                onClick={handleSimulator}
                disabled={!currentCompany?.plan?.isCalculationSimulator || !answers}
              >
                <DialpadIcon fontSize="small" />
                Simulador de Cálculo
              </AnswerStyles.Button>
              <AnswerStyles.Button
                onClick={handleGenerateReport}
                disabled={!currentCompany?.plan?.isGenerateReport || !answers}
              >
                <DescriptionIcon fontSize="small" />
                Gerar Relatório
              </AnswerStyles.Button>
              <AnswerStyles.Button
                onClick={() => {
                  history.push("/gt-facil");
                }}
              >
                <SearchIcon fontSize="small" />
                Nova Pesquisa
              </AnswerStyles.Button>
            </AnswerStyles.ButtonsWrapper>
          </Card>
        </AnswerStyles.Container>
      </GTLayout>
    </AnswerContext.Provider>
  );
};
