export const gray = "#F0EEEF";
export const lightGray = "#fafafa";
export const fontColorItem = "#555555";

const answerCategories = [
  {
    name: "inss",
    color: "#ff8040",
  },
  {
    name: "irrf",
    color: "#ffbf40",
  },
  {
    name: "irpj, csll, pis/pasep e cofins",
    color: "#ffbf40",
  },
  {
    name: "csll, pis/pasep e cofins",
    color: "#83bf30",
  },
  {
    name: "csll",
    color: "#83bf30",
  },
  {
    name: "iss",
    color: "#bf303c",
  },
];

export const getBackgroundTitle = (title) => {
  const element = answerCategories.find(
    (item) => item.name?.toUpperCase() === title?.toUpperCase()
  );

  return element?.color || "#fff";
};

const createCommonBody = (data) => {
  const body = [
    [
      {
        text: "DADOS DA OPERAÇÃO CONSULTADA",
        alignment: "center",
        color: fontColorItem,
        fillColor: "#dddddd",
        colSpan: 2,
        paddingTop: 20,
        marginTop: 8,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
      {},
    ],
    [
      {
        text: "N° da consulta:",
        fontSize: 12,
        fillColor: gray,
        margin: 5,
        color: fontColorItem,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
      {
        text: data?.number,
        margin: 5,
        fillColor: lightGray,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
    ],
    [
      {
        text: "Tipo de serviço ou aquisição:",
        fontSize: 12,
        fillColor: gray,
        margin: 5,
        color: fontColorItem,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
      {
        text: data.serviceTypeDescription,
        margin: 5,
        fillColor: lightGray,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
    ],
  ];

  if (data.observation) {
    body.push([
      {
        text: "Observação:",
        fontSize: 12,
        fillColor: gray,
        margin: 5,
        color: fontColorItem,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
      {
        text: data.observation,
        margin: 5,
        fillColor: lightGray,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
    ]);
  }

  body.push([
    {
      text: "Natureza do Contratante:",
      fontSize: 12,
      fillColor: gray,
      margin: 5,
      color: fontColorItem,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
    },
    {
      text: data.natureOfContractor,
      margin: 5,
      fillColor: lightGray,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
    },
  ]);

  body.push([
    {
      text: "Tipo do contratado:",
      fontSize: 12,
      fillColor: gray,
      margin: 5,
      color: fontColorItem,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
    },
    {
      text: data.contractTypeDescription,
      margin: 5,
      fillColor: lightGray,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
    },
  ]);

  body.push([
    {
      text: "Optante do Simples Nacional:",
      fontSize: 12,
      fillColor: gray,
      margin: 5,
      color: fontColorItem,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
    },
    {
      text: data.nationalSimple,
      margin: 5,
      fillColor: lightGray,
      borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
    },
  ]);

  if(data?.grossValueFormatted) {
    body.push([
      {
        text: "Valor Bruto da Operação:",
        fontSize: 12,
        fillColor: gray,
        margin: 5,
        color: fontColorItem,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
      {
        text: data.grossValueFormatted,
        margin: 5,
        fillColor: lightGray,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
    ]);
  }

  if (data.cityContractor) {
    body.push([
      {
        text: "Município do Contratante",
        fontSize: 12,
        fillColor: gray,
        margin: 5,
        color: fontColorItem,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
      {
        text: data.cityContractor,
        margin: 5,
        fillColor: lightGray,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
    ]);
  }

  if (data.cityHire) {
    body.push([
      {
        text: "Município do Contratado",
        fontSize: 12,
        fillColor: gray,
        margin: 5,
        color: fontColorItem,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
      {
        text: data.cityHire,
        margin: 5,
        fillColor: lightGray,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
    ]);
  }

  if (data.cityService) {
    body.push([
      {
        text: "Município da Prestação",
        fontSize: 12,
        fillColor: gray,
        margin: 5,
        color: fontColorItem,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
      {
        text: data.cityService,
        margin: 5,
        fillColor: lightGray,
        borderColor: ["#ffffff", "#ffffff", "#ffffff", "#ffffff"],
      },
    ]);
  }

  return body;
};

const fillColor = (rowIndex, node, columnIndex) => {
  if (rowIndex !== 0) {
    return rowIndex % 2 === 0 ? "#ECFFFB" : "#E6F1ED";
  }
};

const heightHandler = (rowIndex) => {
  return 15;
};

var cellStyle = {
  padding: 5,
  fontSize: 10,
  wrap: true,
};

var tableStyle = {
  margin: [0, 0, 0, 10],
  hLineColor: "#fff",
};

export { createCommonBody, fillColor, heightHandler, tableStyle, cellStyle };
