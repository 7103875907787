import styled from "styled-components";
import { Form as FormikForm } from "formik";
import {
  Radio as MuiRadio,
  FormLabel as MuiFormLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material";

export const Container = styled.div`
  background-color: #fff;

  border-radius: 10px;
  height: calc(100% - 40px - 12px); /** 40: h2 height // 12px: margin-top */
  margin-top: 12px;
  overflow-y: auto;
  padding: 20px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-row-gap: 20px;

  /* preserve the padding bottom */
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
  }
`;

export const Form = styled(FormikForm)`
  display: grid;
  grid-gap: 16px;

  .submit-btn {
    width: 100%;
    justify-self: unset;

    @media screen and (min-width: 600px) {
      width: 25%;
      justify-self: center;
    }
  }
`;

export const Radio = styled(MuiRadio)`
  &.MuiRadio-root,
  &.MuiRadio-root.Mui-checked {
    color: ${(props) => "#383f48"};
  }
`;

export const FormLabel = styled(MuiFormLabel)`
  &.MuiFormLabel-root,
  &.MuiFormLabel-root.Mui-focused {
    color: ${(props) => "#383f48"};
  }
`;

export const Checkbox = styled(MuiCheckbox)`
  &.MuiCheckbox-root,
  &.MuiCheckbox-root.Mui-checked {
    color: ${(props) => "#383f48"};
  }
`;
