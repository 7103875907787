import React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Close } from "@mui/icons-material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { FormHelperText } from "@mui/material";

// ⇣⇣⇣⇣⇣⇣⇣⇣ SOBRE A ESTILIZAÇÃO DOS BOTÕES: ⇣⇣⇣⇣⇣⇣⇣⇣
// basta adicionar a props sx={{css}} para escrever css necessário.

function renderButtonComponent(type, props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1341A1", // azul
      },
      secondary: {
        main: "#ff6829", //orange
      },
      error: {
        main: "#d32f2f", //red
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            // Estilos globais para todos os botões
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: 14,
            width: "100%",
            padding: "10px 20px",
            textTransform: "unset",
            "&:hover": {
              scale: "1.01",
            },
            "&:disabled": {
              color: "#0D0D0D!important",
              background: "#b6b6b6",
              border: "none",
            },
          },
          contained: {
            // Estilos para botões "contained"
            color: "#fff", // Cor do texto
            height: "100%",
            a: {
              color: "#fff", // Cor do texto
              fontWeight: 500,
              "&:hover": {
                color: "#fff", // Cor do texto
                textDecoration: "none",
              },
            },
          },
          outlined: {
            // Estilos para botões "outlined"
          },
        },
      },
    },
  });

  switch (type) {
    case "select":
      return (
        <>
          <p style={{ fontSize: 12, fontStyle: "italic", alignSelf: "start" }}>{props.title}</p>
          <Select
            sx={FilterButtonStyle(props)}
            value={props.value}
            onChange={props.onChange}
            variant={props.variant || "outlined"}
            IconComponent={KeyboardArrowDownIcon}
            displayEmpty // Essa prop permite que o placeholder seja mostrado mesmo quando o valor é uma string vazia
          >
            <MenuItem value="">
              <em style={styles.FilterDelete}>
                <Close fontSize="small" /> Apagar
              </em>
            </MenuItem>
            <MenuItem value="" disabled sx={{ display: "none" }}>
              <em>{props.placeholder || "Selecione uma opção"}</em>
              {/*só para ter como "nome" do botão*/}
            </MenuItem>
            {props.children} {/* Sé preciso por <MenuItem> com cada opção*/}
          </Select>
          {props.error && <FormHelperText sx={styles.ErrorText}>{props.errorText}</FormHelperText>}
        </>
      );

    default:
      return (
        <ThemeProvider theme={theme}>
          <Button
            variant={props.variant || "contained"}
            color={props.color || "primary"}
            onClick={props.onClick}
            {...props}
          >
            {props.children}
          </Button>
        </ThemeProvider>
      );
  }
}

// ⇡⇡⇡⇡⇡⇡ AVISO SOBRE A ESTILIZAÇÃO DOS BOTÕES NO TOPO DO DOCUMENTO ⇡⇡⇡⇡⇡⇡

function ButtonCommon({ type, ...props }) {
  return <>{renderButtonComponent(type, props)}</>;
}
export default ButtonCommon;

const styles = {
  FilterDelete: {
    fontSize: 14,
    opacity: 0.8,
    fontWeight: "400",
    color: "#d32f2f",
    display: "flex",
    alignItems: "center",
  },
  ErrorText: {
    color: "#d32f2f",
    fontFamily: "Montserrat",
    position: "absolute",
  },
};

const FilterButtonStyle = ({ error, sx }) => ({
  fontFamily: "Montserrat",
  fontSize: 14,
  width: "100%",
  backgroundColor: "#DDDDDD",
  boxShadow: "none",
  color: "#222222",
  cursor: "pointer",
  padding: 1,
  ".MuiOutlinedInput-notchedOutline ": {
    border: error ? "2px solid #d32f2f" : 0,
  },
  ".MuiInputBase-input": {
    minHeight: "0 !important",
    lineHeight: "0 !important",
    backgroundColor: "transparent",
  },
  "&.MuiOutlinedInput-root": {
    border: 0,
  },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: 0,

    boxShadow: "none",
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: error ? "2px solid #d32f2f" : 0,

    boxShadow: "none",
  },
  "&.MuiOutlinedInput-root.Mui-active .MuiOutlinedInput-notchedOutline": {
    border: error ? "2px solid #d32f2f" : 0,

    boxShadow: "none",
  },
  ".MuiSelect-icon": {
    color: "#222222",
  },
  ...sx,
});
