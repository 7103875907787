import styled from "styled-components";

export const Container = styled.div`
  min-height: 70vh;
  height: min-content;
  background-image: linear-gradient(114.74deg, #152340 34.25%, #14213d 86.96%);
  display: grid;
  grid-auto-rows: min-content auto;
  padding: max(40px, 6.7%) 6.3% max(36px, 6%) 6.8%;
`;

export const Header = styled.div`
  display: grid;
  margin-top: 8%;
  grid-template-columns: auto;
  align-items: center;
  justify-items: center;
  color: #fafafa;
  width: 100%;

  > hr {
    display: none;
  }

  @media screen and (min-width: 600px) {
    grid-template-columns: auto max-content auto;
    gap: max(30px, 2%);

    > hr {
      display: block;
      height: 3px;
      width: 100%;
      background: #fafafa;
    }
  }
  > h2 {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 2.3rem;

    @media screen and (max-width: 600px) {
      font-size: 1.7rem;
  }
`;

export const Main = styled.div`
  margin-top: 2%;
  display: grid;
  grid-template-rows: auto auto auto;
  align-items: center;
  justify-items: center;

  @media screen and (min-width: 768px) {
    grid-template-rows: auto;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  }
`;

export const CardWrapper = styled.div`
  height: min-content;
  max-width: 380px;
  display: grid;
  justify-items: center;
  margin-inline: 10px;
  color: #fafafa;
`;

export const ButtonAbout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 313px;
  width: 40%;
  padding-top: 40px;
  margin-bottom: -40px;

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }

  @media screen and (min-width: 769px) {
    margin-bottom: -40px;
  }

  .button {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    background: transparent;
    border: 3px solid #34A6B6;
    color: #FFF;
    text-align: center;
    font-family: "Bebas Neue";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
}
`;
