import React, { useEffect, useRef, useState } from "react";
import { Title } from "../../../styles";
import { Box, Grid } from "@mui/material";
import { Subtitle } from "../../../styles";
import { KeyboardArrowDown } from "@mui/icons-material";
import handleMoney from "~/presentation/views/Reinf/components/HandleMoney/handleMoney";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";

function CSRF({
  values,
  incomeNatureOptions,
  errors,
  isBcSCRFWrong,
  inputDisabled,
  nfseIndex,
  handleChangeFieldValue,
}) {
  const [show, setShow] = useState(false);

  //ERROR: bcCSRF
  const isBcSCRFWrongText =
    isBcSCRFWrong && "Valor da base CSRF não pode ser maior do que o Valor Bruto do doc!";

  //ERROR: alíquota tem sempre de dar 4.65 que é a soma das % de CSLL+PIS+CONFINS
  const aliquotValidFormula =
    values?.aliquotCSRF?.toFixed(2) == 4.65 || values?.aliquotCSRF?.toFixed(2) == 1.0;
  const isAmountPositive = values?.amountCSRF > 0;
  const isAmountNotRetainedNull = values?.amountNotRetained === 0;
  const isAliquotaWrong = isAmountPositive && isAmountNotRetainedNull && !aliquotValidFormula;
  const isAliquotaWrongText = isAliquotaWrong && "Alíquota deve dar 4.65%";

  //ERROR: amount
  const isAmountWrong = values?.amountCSRF > values?.bcCSRF;
  const isAmountWrongText = isAmountWrong && "A retenção não pode ser maior do que a Base.";

  //ERROR: data no futuro
  const isDateInTheFuture = values?.dateOfPayment > new Date();
  const isDateWrong = isDateInTheFuture && "Escolha uma data no presente";

  return (
    <Box sx={styles.container}>
      <Title>Retenção de CSRF</Title>
      <Box
        sx={{
          paddingTop: 3,
          paddingInline: 4,
          paddingBottom: show ? 4 : 2,
          background: "#D9D9D9",
          borderRadius: 4,
          marginBlock: 0,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} lg={3}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions.find((item) => item.id == values?.incomeNatureId)}
              onChange={(option) => {
                const { id, incomeDescription } = option;
                handleChangeFieldValue({
                  newItem: { incomeNatureId: id, incomeNatureDescription: incomeDescription },
                  index: nfseIndex,
                  field: "incomeNatureId",
                });
              }}
              error={!!errors[`[${nfseIndex}].incomeNatureId`]}
              errorText={errors[`[${nfseIndex}].incomeNatureId`]}
              disabled={inputDisabled === "R4020D"}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="date"
              title="Data do Crédito/Pagamento"
              value={values?.dateOfPayment}
              onChange={(date) => {
                handleChangeFieldValue({
                  newItem: { dateOfPayment: date },
                  index: nfseIndex,
                  field: "dateOfPayment",
                });
              }}
              error={!!errors[`[${nfseIndex}].dateOfPayment`] || isDateWrong}
              errorText={errors[`[${nfseIndex}].dateOfPayment`] || isDateWrong}
              disabled={inputDisabled === "R4020D"}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              title="Valor da Base da CSRF"
              value={values?.bcCSRF}
              onChange={(e) => {
                const value = handleMoney(e.target.value);
                handleChangeFieldValue({
                  newItem: { bcCSRF: value },
                  index: nfseIndex,
                  field: "bcCSRF",
                });
              }}
              error={!!errors[`[${nfseIndex}].bcCSRF`] || isBcSCRFWrongText}
              errorText={errors[`[${nfseIndex}].bcCSRF`] || isBcSCRFWrongText}
              disabled={inputDisabled === "R4020D"}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              title="Valor da Retenção da CSRF"
              value={values?.amountCSRF}
              error={!!errors[`[${nfseIndex}].amountCSRF`] || isAmountWrongText}
              errorText={errors[`[${nfseIndex}].amountCSRF`] || isAmountWrongText}
              disabled
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              prefix="%"
              title="Alíquota"
              value={values?.aliquotCSRF}
              error={!!errors[`[${nfseIndex}].aliquotCSRF`] || isAliquotaWrongText}
              errorText={errors[`[${nfseIndex}].aliquotCSRF`] || isAliquotaWrongText}
              disabled
            />
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2 }}>
          <Subtitle onClick={() => setShow(!show)}>
            <KeyboardArrowDown
              style={{
                fontSize: "2.4rem",
                rotate: show ? "0deg" : "-90deg",
                transition: "200ms",
                color: "#A7A7A7",
                marginBlock: 2,
              }}
            />
            Valores de CSLL, PIS/Pasep e COFINS
          </Subtitle>

          {show && (
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do CSLL"
                  value={values?.amountCSLL}
                  percentageValue={
                    values?.bcCSRF > 0
                      ? handleMoney((values?.amountCSLL / values?.bcCSRF) * 100).toFixed(2)
                      : "0.00"
                  }
                  onChange={(e) => {
                    const value = handleMoney(e.target.value);
                    handleChangeFieldValue({
                      newItem: { amountCSLL: value },
                      index: nfseIndex,
                      field: "amountCSLL",
                    });
                  }}
                  disabled={inputDisabled === "R4020D"}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do PIS/Pasep"
                  value={values?.amountPIS}
                  percentageValue={
                    values?.bcCSRF > 0
                      ? handleMoney((values?.amountPIS / values?.bcCSRF) * 100).toFixed(2)
                      : "0.00"
                  }
                  onChange={(e) => {
                    const value = handleMoney(e.target.value);
                    handleChangeFieldValue({
                      newItem: { amountPIS: value },
                      index: nfseIndex,
                      field: "amountPIS",
                    });
                  }}
                  disabled={inputDisabled === "R4020D"}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do COFINS"
                  value={values?.amountCOFINS}
                  percentageValue={
                    values?.bcCSRF > 0
                      ? handleMoney((values?.amountCOFINS / values?.bcCSRF) * 100).toFixed(2)
                      : "0.00"
                  }
                  onChange={(e) => {
                    const value = handleMoney(e.target.value);
                    handleChangeFieldValue({
                      newItem: { amountCOFINS: value },
                      index: nfseIndex,
                      field: "amountCOFINS",
                    });
                  }}
                  disabled={inputDisabled === "R4020D"}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção não efetuada"
                  value={values?.amountNotRetained}
                  onChange={(e) => {
                    const value = handleMoney(e.target.value);
                    handleChangeFieldValue({
                      newItem: { amountNotRetained: value },
                      index: nfseIndex,
                      field: "amountNotRetained",
                    });
                  }}
                  disabled={inputDisabled === "R4020D"}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
}
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    paddingBlock: 2,
  },
  BoxStyle: {
    display: "flex",
    alignItems: "end",
    width: "100%",
    gap: 1,
  },
  gridStylesDark: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  percentageStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    maxWidth: "80px",
    paddingBottom: "2px",
  },
};

export default CSRF;
