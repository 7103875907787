import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// // components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../../components/PageListHeader";
// //functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
// //utils
// import typeTax from "~/presentation/utils/typeTax";

const IssAnswerTable = ({ makeIssAnswerUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const history = useHistory();

  const [issAnswers, setIssAnswers] = useState([]);
  // //const [categories, setCategories] = useState([]);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");

    const { data = [] } = await makeIssAnswerUseCases.getIssAnswers();
    data.forEach((answer) => {
      answer.actions = (
        <TableActions
          // handleDelete={() => deleteDialog(answer.id)}
          handleEdit={() => handleEdit(answer.id)}
        />
      );
    });
    setIssAnswers(data);
    openBackdrop(false);
  };

  const cpomLabel = (value) => (value === 0 ? "Nenhum" : value === 1 ? "CPOM 1" : "CPOM 2");

  const columns = [
    {
      name: "cityName",
      label: "Nome da Cidade",
    },
    {
      name: "isLegislation",
      label: "Legislaçao",
      options: {
        filter: false,
        customBodyRender: (value) => (value === true ? "Sim" : "Não"),
      },
    },
    {
      name: "isSpecificSubstituition",
      label: "Substituição Específica",
      options: {
        filter: false,
        customBodyRender: (value) => (value === true ? "Sim" : "Não"),
      },
    },
    {
      name: "isDispensedEROS",
      label: "ERO Dispensado",
      options: {
        filter: false,
        customBodyRender: (value) => (value === true ? "Sim" : "Não"),
      },
    },
    {
      name: "isDispensedCPOM",
      label: "CPOM Dispensado",
      options: {
        filter: false,
        customBodyRender: (value) => (value === true ? "Sim" : "Não"),
      },
    },
    {
      name: "CPOM",
      label: "CPOM",
      options: {
        filter: false,
        customBodyRender: (value) => cpomLabel(value),
      },
    },
    {
      name: "actions",
      label: "Ações",
      options: { sortable: "false" },
    },
  ];

  const handleEdit = (id) => {
    history.push(`/admin/respostas/respostas-iss/editar/${id}`);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo resposta");
    try {
      await makeIssAnswerUseCases.deleteIssAnswer(id);
      await fetchData();
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir resposta: Missing endpoint.",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta resposta?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };
  // const handleAdd = () => history.push('/admin/respostas/respostas-iss/cadastrar');
  return (
    <Container maxWidth="100%">
      <PageListHeader
        title="Resposta do ISS"
        titleAccess="Nova resposta"
        searchFieldRef={searchFieldRef}
        // handleAdd={handleAdd}
        handleSearch={handleSearch}
      />
      {issAnswers ? (
        <Table
          searchRef={hiddenInputRef}
          columns={columns}
          data={issAnswers}
          title="Respostas do Iss"
        />
      ) : (
        <div />
      )}
    </Container>
  );
};

export default IssAnswerTable;
