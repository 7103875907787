import React from "react";
import {
  Inbox as InboxIcon,
  InsertDriveFile as FileIcon,
  Send as SendIcon,
  Star as StarIcon,
  MailOutline as MailOutlineIcon,
} from "@mui/icons-material";

const renderIcon = (Icon, props) => <Icon {...props} />;

/** Items to be rendered as filter options for Email component */
const emailFilterList = [
  {
    id: 1,
    alias: "Caixa de Entrada",
    name: "inbox",
    icon: (props) => renderIcon(InboxIcon, props),
  },
  {
    id: 2,
    alias: "Enviados",
    name: "sent",
    icon: (props) => renderIcon(SendIcon, props),
  },
  {
    id: 3,
    alias: "Importantes",
    name: "important",
    icon: (props) => renderIcon(StarIcon, props),
  },
  {
    id: 4,
    alias: "Rascunhos",
    name: "drafts",
    icon: (props) => renderIcon(FileIcon, props),
  },
  {
    id: 5,
    alias: "Todos os Emails",
    name: "all",
    icon: (props) => renderIcon(MailOutlineIcon, props),
  },
];

export default emailFilterList;
