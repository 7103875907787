import styled from "styled-components";
import { Form as FormikForm } from "formik";
import BasePrimaryButton from "~/presentation/components/PrimaryButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const Container = styled.div`
  display: flex;
  flex-flow: column;

  .errorMessage {
    font-size: 14px;
  }
`;

export const PrimaryButton = styled(BasePrimaryButton)`
  &.MuiButton-root {
    margin-top: 32px;
    width: 50%;
    margin-left: 25%;
  }
`;

export const Form = styled(FormikForm)`
  width: 100%;
  margin: 0 auto;
`;

export const InfoIcon = styled(InfoOutlinedIcon)`
  margin: 0 12px;
  color: #1341a1;
  transform: scale(0.9);
`;
