import React, { useEffect, useState } from "react";
import { Title, Subtitle } from "../../../../../../components/Layouts/styles";
import { Box, Grid } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import handleMoney from "~/presentation/views/Reinf/components/HandleMoney/handleMoney";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";

function CSRF({ item, onChangeItem, index, nfseData, errors, disabled, incomeNatureOptions }) {
  const [show, setShow] = useState(false);

  //   //ERROR: bcCSRF
  const isBcSCRFWrong = item?.bcCSRF > item?.amountBC;
  const isBcSCRFWrongText =
    isBcSCRFWrong && "Valor da base CSRF não pode ser maior do que o Valor Bruto do doc!";

  //   //ERROR: alíquota tem sempre de dar 4.65 que é a soma das % de CSLL+PIS+CONFINS
  const aliquotValidFormula = item?.aliquotCSRF?.toFixed(2) == 4.65;
  const isAliquotaWrong = item?.amountCSRF > 0 && !aliquotValidFormula;
  const isAliquotaWrongText = isAliquotaWrong && "Alíquota deve dar 4.65%";

  //   //ERROR: amount
  const isAmountWrong = item?.amountCSRF > item?.bcCSRF;
  const isAmountWrongText = isAmountWrong && "A retenção não pode ser maior do que a Base.";

  //ERROR: data no futuro
  const isDateInTheFuture = item?.dateOfPayment > new Date();
  const isDateWrong = isDateInTheFuture && "Escolha uma data no presente";

  useEffect(() => {
    onChangeItem(
      {
        ...nfseData[index],
        aliquotCSRF: (item?.amountCSRF / item?.bcCSRF) * 100,
        amountCSRF: item?.amountCSLL + item?.amountPIS + item?.amountCOFINS,
      },
      index,
      "amountCSRF",
      true
    );
  }, [item?.bcCSRF, item?.amountCSRF, item?.amountCSLL, item?.amountPIS, item?.amountCOFINS]);

  return (
    <Box sx={styles.container}>
      <Title>Retenção de CSRF</Title>
      <Box
        sx={{
          paddingTop: 3,
          paddingInline: 4,
          paddingBottom: show ? 4 : 2,
          background: "#D9D9D9",
          borderRadius: 4,
          marginBlock: 0,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} lg={3}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions.find((i) => i.id == item.incomeNatureId)}
              onChange={(option) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    incomeNatureId: option.id,
                    incomeNatureDescription: option.incomeDescription,
                  },
                  index,
                  "incomeNatureId"
                );
              }}
              error={!!errors[`[${index}].incomeNatureId`]}
              errorText={errors[`[${index}].incomeNatureId`]}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="date"
              title="Data do Crédito/Pagamento"
              value={item.dateOfPayment}
              onChange={(date) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    dateOfPayment: date,
                  },
                  index,
                  "dateOfPayment"
                );
              }}
              error={!!errors[`[${index}].dateOfPayment`] || isDateWrong}
              errorText={errors[`[${index}].dateOfPayment`] || isDateWrong}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              title="Valor da Base da CSRF"
              value={item.bcCSRF}
              onChange={(e) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    bcCSRF: handleMoney(e.target.value),
                  },
                  index,
                  "bcCSRF"
                );
              }}
              error={!!errors[`[${index}].bcCSRF`] || isBcSCRFWrongText}
              errorText={errors[`[${index}].bcCSRF`] || isBcSCRFWrongText}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              title="Valor da Retenção da CSRF"
              value={item.amountCSRF}
              onChange={(e) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    amountCSRF: handleMoney(e.target.value),
                  },
                  index,
                  "amountCSRF"
                );
              }}
              error={!!errors[`[${index}].amountCSRF`] || isAmountWrongText}
              errorText={errors[`[${index}].amountCSRF`] || isAmountWrongText}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              prefix="%"
              title="Alíquota"
              value={item.aliquotCSRF}
              onChange={(e) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    aliquotCSRF: handleMoney(e.target.value),
                  },
                  index
                );
              }}
              error={isAliquotaWrongText}
              errorText={isAliquotaWrongText}
              disabled
            />
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2 }}>
          <Subtitle onClick={() => setShow(!show)}>
            <KeyboardArrowDown
              style={{
                fontSize: "2.4rem",
                rotate: show ? "0deg" : "-90deg",
                transition: "200ms",
                color: "#A7A7A7",
                marginBlock: 2,
              }}
            />
            Valores de CSLL, PIS/Pasep e COFINS
          </Subtitle>

          {show && (
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do CSLL"
                  value={item.amountCSLL}
                  percentageValue={
                    item?.bcCSRF > 0
                      ? handleMoney((item?.amountCSLL / item?.bcCSRF) * 100).toFixed(2)
                      : "0.00"
                  }
                  onChange={(e) => {
                    onChangeItem(
                      {
                        ...nfseData[index],
                        amountCSLL: handleMoney(e.target.value),
                      },
                      index
                    );
                  }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do PIS/Pasep"
                  value={item.amountPIS}
                  percentageValue={
                    item?.bcCSRF > 0
                      ? handleMoney((item?.amountPIS / item?.bcCSRF) * 100).toFixed(2)
                      : "0.00"
                  }
                  onChange={(e) => {
                    onChangeItem(
                      {
                        ...nfseData[index],
                        amountPIS: handleMoney(e.target.value),
                      },
                      index
                    );
                  }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do COFINS"
                  value={item.amountCOFINS}
                  percentageValue={
                    item?.bcCSRF > 0
                      ? handleMoney((item?.amountCOFINS / item?.bcCSRF) * 100).toFixed(2)
                      : "0.00"
                  }
                  onChange={(e) => {
                    onChangeItem(
                      {
                        ...nfseData[index],
                        amountCOFINS: handleMoney(e.target.value),
                      },
                      index
                    );
                  }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção não efetuada"
                  value={item.amountNotRetained}
                  onChange={(e) => {
                    onChangeItem(
                      {
                        ...nfseData[index],
                        amountNotRetained: handleMoney(e.target.value),
                      },
                      index
                    );
                  }}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
}
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginBlock: 1,
  },
  BoxStyle: {
    display: "flex",
    alignItems: "end",
    width: "100%",
    gap: 1,
  },
  gridStylesDark: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  },
  percentageStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    maxWidth: "80px",
    paddingBottom: "2px",
  },
};

export default CSRF;
