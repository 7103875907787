import React, { useState, useContext } from "react";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// packages
import { uploadFile } from "react-s3";
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import Select from "~/presentation/components/Select";
import QSOTable from "~/presentation/components/QSOTable";
import {
  Container,
  DropzoneContainer,
  Form,
} from "~/presentation/views/Admin/Content/CreateEdit/Form/styles";
import DateInput from "~/presentation/components/DateInput";
import TextEditor from "~/presentation/components/TextEditor";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
import DropUploadZone from "~/presentation/components/DropUploadZone";
// import { Container as DropzoneContainer } from "~/presentation/components/DropUploadZone/styles";
// schema
import validationSchema from "~/presentation/views/Admin/Content/CreateEdit/Form/validationSchema";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CloseIcon from "@mui/icons-material/Close";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import randomString from "~/presentation/utils/randomString";
import { format } from "date-fns";

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION_BUCKET,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const ContentForm = ({ handleSubmit, initialValues, categories, types }) => {
  const [selectedFile, setSelectedFile] = useState();
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const defaultCategory = !initialValues?.category?.id
    ? null
    : dataToSelectMapper([initialValues?.category])[0];

  const changeHandler = (file) => {
    const renamedFile = new File([file], `${randomString()}-${file.name}`);
    setSelectedFile(renamedFile);
  };

  const handleImageUpload = async () => {
    try {
      if (selectedFile) {
        const upload = await uploadFile(selectedFile, config);
        return upload;
      }
      return false;
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao fazer upload.",
        type: "error",
      });
      return error;
    }
  };

  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          let submitValues = {
            id: values.id,
            title: values.title,
            autor: values.autor,
            type: values.type,
            category: values.category,
            publishDate: format(values.publishDate, "yyyy-MM-dd HH:mm"),
            summary: values.summary,
            image: values.image,
            source: values.source,
            videoUrl: values.videoUrl,
            audioUrl: values.audioUrl,
            text: values.text,
            isDraft: values.isDraft,
            qsoTable: values.qsoTable,
          };
          const upload = await handleImageUpload();
          if (upload?.result?.ok || !values.audioUrl) {
            submitValues = {
              ...values,
              audioUrl: upload.location ? upload.location : values.audioUrl,
              category:
                values.type.id != 3
                  ? null
                  : {
                      id: values.category?.value,
                      name: values.category?.label,
                    },
            };
            await handleSubmit(submitValues);
          }
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <h2>Nova Publicação</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <Field
                  name="type"
                  component={Select}
                  options={types}
                  placeholder={"Tipo da publicação *"}
                />
                <ErrorMessage name="type" />
              </div>
              <div className="col-1_5">
                <Field name="title" type="text" label="Título *" as={TextInput} />
                <ErrorMessage name="title" />
              </div>
              {Number(values?.type?.id) === 4 ? (
                // campos para tipo multimidia: Título, capa, link e texto.
                <>
                  <h2>Vídeo</h2>
                  <div className="col-1_5">
                    <Field name="videoUrl" type="text" label="Url do Vídeo *" as={TextInput} />
                    <ErrorMessage name="videoUrl" />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-1_3">
                    <Field name="source" type="text" label="Fonte" as={TextInput} />
                    <ErrorMessage name="source" />
                  </div>
                  <div className="col-3_5">
                    <DateInput
                      getHours
                      label="Data da publicação *"
                      value={values.publishDate}
                      onChange={(value) => setFieldValue("publishDate", value)}
                    />
                    <ErrorMessage name="publishDate" />
                  </div>{" "}
                  {/* campos exclusivos para legislação */}
                  {values.type.id === 3 && (
                    <>
                      <div className="col-1_5">
                        <Field
                          name="category"
                          component={Select}
                          defaultValue={defaultCategory}
                          options={categories}
                          placeholder={"Categoria da Legislação"}
                          onChange={(value) => {
                            setFieldValue("category", value);
                          }}
                        />
                        <ErrorMessage name="category" />
                      </div>
                      <h2 className="col-1_5">Audio</h2>
                      <div className="col-1_5">
                        {!values.audioUrl ? (
                          <>
                            <Field
                              name="audioUrl"
                              type="file"
                              label="Audio"
                              accept="audio/mpeg"
                              as={DropUploadZone}
                              handleChange={(file) => {
                                changeHandler(file);
                                setFieldValue("audioUrl", file.name);
                              }}
                            />
                            <ErrorMessage name="audioUrl" />
                          </>
                        ) : (
                          <DropzoneContainer>
                            <div
                              style={{ position: "relative", width: "100%", height: "auto" }}
                              onClick={() => setFieldValue("audioUrl", null)}
                            >
                              <div style={{ position: "absolute", right: 0, top: 0 }}>
                                <CloseIcon />
                              </div>
                            </div>
                            <>
                              <FileCopyIcon key={1} className="mb_12" />
                              <h3 key={2} className="mb_12">
                                Arquivo Selecionado:
                              </h3>
                              <p key={3} className="fileName">
                                {values.audioUrl}
                              </p>
                            </>
                          </DropzoneContainer>
                        )}
                      </div>
                      <h2 className="col-1_5">
                        Resumo <ErrorMessage name="summary" />
                      </h2>
                      <div className="col-1_5">
                        <Field name="summary" type="text" label="Resumo" as={TextInput} />
                      </div>
                    </>
                  )}
                  <h2 className="col-1_5">Imagem*</h2>
                  <div className="col-1_5">
                    <Field name="image" type="text" label="URL da imagem" as={TextInput} />
                    <ErrorMessage name="image" />
                  </div>
                  <div className="col-1_5">
                    <h2 className="mb_12">Quadro QSO *</h2>
                    <div className="scroll-x">
                      <QSOTable
                        handleChange={(table) => setFieldValue("qsoTable", table)}
                        values={values.qsoTable}
                      />
                    </div>
                  </div>
                </>
              )}
              <h2 className="col-1_5">
                Texto* <ErrorMessage name="text" />
              </h2>
              <div className="col-1_5">
                <TextEditor
                  initialValue={values.text}
                  name="text"
                  height={400}
                  handleEditorChange={(content) => {
                    setFieldValue("text", content);
                  }}
                />
              </div>
            </div>

            <FormSubmitButtonsDock>
              <PrimaryButton
                className="draft"
                type="submit"
                onClick={() => setFieldValue("isDraft", true)}
              >
                Salvar como rascunho
              </PrimaryButton>
              <PrimaryButton type="submit" onClick={() => setFieldValue("isDraft", false)}>
                Publicar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ContentForm;
