import React from "react";
import {makeLegislationCategoryUseCases} from "~/main/factories/use-cases/legislation-category";
import CreateLegislationCategory from "~/presentation/views/Admin/LegislationCategory/Create";

export const makeCreateLegislationCategory = (params) => {
  return (
    <CreateLegislationCategory
      {...params}
      legislationCategoryUseCases={makeLegislationCategoryUseCases()}
    />
  );
};
