import styled from "styled-components";

const Container = styled.div`
  margin: 1rem;
`;

const Title = styled.div`
  color: #3a3a3a;
  padding-inline: 14px;
  padding-block: 12px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 128.9%;
  cursor: pointer;
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;

  > div {
    width: 80%;
    height: 0.6rem;
    background-color: #e15b23;
    border-radius: 1rem;
  }

  > span {
    display: block;
    width: 20%;
    background-color: #e15b23;
    height: 2px;
  }
`;

export const Date = styled.div`
  color: #3a3a3a;
  font-family: Montserrat;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 128.9%;
  padding-inline: 14px;
  padding-block: 8px;
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-inline: 2rem;
  border-radius: 12px 12px 0 0;
`;

const ImageContainer = styled.img`
  position: relative;
  width: 100%;
  object-fit: contain;

  border-radius: 12px 12px 0 0;
`;

export const DescriptionPostStyles = {
  Container,
  Title,
  Divider,
  Date,
  VideoContainer,
  ImageContainer,
};
