import React from "react";

import { Container, CardsWrapper, Pagination, NoPostsFound, InboxIcon, Loading } from "./styles";
import { Skeleton, Typography } from "@mui/material";

const CardsList = ({
  content,
  numberOfPages,
  goToPage,
  goToPost,
  page,
  dataLoaded,
  headerTitle,
  headerFontSize = "small",
  headerPadding = 0,
  notFoundMessage,
  cardComponent: CardComponent,
  defaultImage,
}) => {
  const skeletonArray = Array.from({ length: 7 });
  return (
    <Container>
      <CardsWrapper>
        {!dataLoaded ? (
          skeletonArray.map((_, index) => <Skeleton key={index} variant="rounded" height={160} />)
        ) : content?.length > 0 ? (
          [
            content.map((c) => (
              <CardComponent
                item={c}
                key={`content-card-${c.id}`}
                imageSrc={c.image || defaultImage}
                // title={limitTextSize(c.title, 40)}
                onClick={() => goToPost(c.id)}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultImage;
                }}
              />
            )),
            <Pagination
              key="pagination"
              size="small"
              count={numberOfPages}
              onChange={(_, selectedPage) => {
                goToPage(selectedPage);
              }}
              page={Number(page)}
            />,
          ]
        ) : (
          <NoPostsFound>
            <InboxIcon />
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: 400,
                fontFamily: "Montserrat",
              }}
            >
              {notFoundMessage}
            </Typography>
          </NoPostsFound>
        )}
      </CardsWrapper>
    </Container>
  );
};

export default CardsList;
