import React from "react";
import { Formik, Field } from "formik";
import TextInput from "~/presentation/components/TextInput";
import ErrorMessage from "~/presentation/components/ErrorMessage";
import { Form } from "./styles";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import validationSchema from "./validationSchema";
import { formatValue, formatRealStringToNumber } from "~/presentation/utils/formatCurrency";
import Select from "~/presentation/components/Select";
import DateInput from "~/presentation/components/DateInput";
import {Grid} from "@mui/material";

const typeOptions = [
  { value: 0, label: "Usuário"},
  { value: 1, label: "IF"},
  { value: 2, label: "REINF"},
  { value: 3, label: "GTFacil"}
];

export const AddtionalCompanyPlanForm = ({ initialValues, handleSubmit, id }) => {
  return (
    <Grid item xs={6} sm={12}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          const submitValues = {
            type: values.type,
            amount: values.amount,
            limitDate: values.limitDate,
            companyId: values.companyId,
            price: !values.price ? 0 : formatRealStringToNumber(values.price),
          };

          if(id) submitValues.id = parseInt(id);

          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <div className="form-grid">
              <div className="col-1_5">
                <div className="wrapper">
                  <div className="form-grid">
                    <div className="col-1_3">
                      <div className="mb_12">
                        <Field
                          name="companyName"
                          type="text"
                          label="Empresa"
                          as={TextInput}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </div>
                      <div className="mb_12">
                        <Field
                          name="amount"
                          type="number"
                          label="Quantidade *"
                          as={TextInput}
                        />
                        <ErrorMessage name="amount"/>
                      </div>
                      <div className="mb_12">
                        <Field
                          name="price"
                          type="text"
                          label="Preço *"
                          as={TextInput}
                          onChange={(e) => {
                            const {value} = e.target;
                            setFieldValue("price", formatValue(value));
                          }}
                        />
                        <ErrorMessage name="price"/>
                      </div>
                    </div>
                    <div className="col-3_5">
                      <div className="mb_12">
                        <Field
                          name="type"
                          component={Select}
                          options={typeOptions}
                          placeholder={"Selecione o Tipo de Plano *"}
                          onChange={(type) => setFieldValue("type", type.value)}
                        />
                        <ErrorMessage name="type"/>
                      </div>
                      <div className="mb_12">
                        <DateInput
                          label="Data de validade"
                          value={values.limitDate}
                          onChange={(value) => setFieldValue("limitDate", value)}
                        />
                        <ErrorMessage name="limitDate"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PrimaryButton className="submit-btn" type="submit">
              Salvar
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
