import React, { useEffect, useState } from "react";

function useCreateLotes() {
  const getLotes = (data, field, maxPerLote = 25) => {
    const [lotes, setLotes] = useState([]);

    useEffect(() => {
      const grupos = {};

      //crio grupos da "data" que tem o campo "field" em comum
      data.forEach((item) => {
        const key = item[field];
        if (!grupos[key]) {
          grupos[key] = [];
        }
        grupos[key].push(item);
      });

      const pequenosGrupos = [];
      const finalLotesArray = [];

      //pegar nos grupos e criar lotes
      Object.keys(grupos).forEach((key) => {
        const itensGrupo = grupos[key];

        //se grupo no forem mais de 40, dividir em lotes até 25
        if (itensGrupo.length > 40) {
          for (let i = 0; i < pequenosGrupos.length; i += maxPerLote) {
            finalLotesArray.push(pequenosGrupos.slice(i, i + maxPerLote));
          }
          //se grupo no forem mais de 25, dividir em lotes até 15
        } else if (itensGrupo.length > 25) {
          for (let i = 0; i < pequenosGrupos.length; i += 15) {
            finalLotesArray.push(pequenosGrupos.slice(i, i + 15));
          }
          //se grupo no forem mais de 10, criar o lote e não mexer
        } else if (itensGrupo.length >= 10) {
          finalLotesArray.push(itensGrupo);
          //todos os outros enviar para um grupo "grupo pequenos"
        } else {
          pequenosGrupos.push(...itensGrupo);
        }
      });

      //pegar no "grupo pequenos" e fazer lotes de 25
      for (let i = 0; i < pequenosGrupos.length; i += maxPerLote) {
        finalLotesArray.push(pequenosGrupos.slice(i, i + maxPerLote));
      }

      setLotes(finalLotesArray);
    }, [data, field, maxPerLote]);

    return lotes;
  };

  return { getLotes };
}

export default useCreateLotes;
