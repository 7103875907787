import React, { useState, useContext } from "react";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import DropUploadZone from "~/presentation/components/DropUploadZone";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import randomString from "~/presentation/utils/randomString";
import { Container } from "@mui/material";
import HeaderForm from "../../components/HeaderForm/HeaderForm";

const Answers = ({ issUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [file, setFile] = useState();

  const handleFile = (file) => {
    const renamedFile = new File([file], `${randomString()}-${file.name}`);
    setFile(renamedFile);
  };

  const handleSubmit = async () => {
    openBackdrop(true, "Salvando respostas de ISS");
    try {
      await issUseCases.saveAnswer(file);
    } catch (error) {
      console.log(error);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar respostas de ISS: Missing Endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Importar Respostas ISS" />
      <div className="dropUploadZone">
        <DropUploadZone handleChange={handleFile} />
      </div>
      <PrimaryButton className="submitBtn" onClick={handleSubmit} disabled={!file}>
        Enviar
      </PrimaryButton>
    </Container>
  );
};

export default Answers;
