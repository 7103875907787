import CurrencyInput from "react-currency-input";
import styled from "styled-components";

export const StyledCurrencyInput = styled(CurrencyInput)`
  border: none;
  outline: none;
  background-color: transparent;

  &:disabled {
    color: #757575 !important;
  }
`;

export const Container = styled.div`
  font-family: "Montserrat";
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ backgroundColor }) => backgroundColor || "#fbfbfb"};
  border-radius: ${({ borderRadius }) => borderRadius || "3px"};
  margin: 5px 0px;
  padding: 4px 10px;
  width: 100%;
  height: 54px;
  box-shadow: 0;
  gap: 8px;
  border: ${({ border, error }) => (error ? "1px solid #d32f2f" : border || "0")};

  > input {
    width: 100%;
    background-color: transparent;
    color: #000;
    font-family: "Montserrat";
    font-size: 14px;
  }
`;
