import React from "react";
import {makeLegislationCategoryUseCases} from "~/main/factories/use-cases/legislation-category";
import EditLegislationCategory from "~/presentation/views/Admin/LegislationCategory/Edit";

export const makeEditLegislationCategory = (params) => {
  return (
    <EditLegislationCategory
      {...params}
      legislationCategoryUseCases={makeLegislationCategoryUseCases()}
    />
  );
};
