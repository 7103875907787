import styled from "styled-components";
import { Form as FormikForm } from "formik";

export const Form = styled(FormikForm)`
  display: grid;
  grid-gap: 16px;
`;

export const FilesInputWrapper = styled.div`
  margin: 24px;

  > input {
    margin-bottom: 16px;
  }

  > p {
    font-size: max(14px, 1vw);
    font-family: "Montserrat", sans-serif;
    color: #383f48;
  }
`;
