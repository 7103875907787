import styled from "styled-components";
import HL from "~/presentation/views/LandingPage/components/NavHashLink";

export const Container = styled.div`
  position: relative;
  background-image: url("/assets/maze5.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* altura como 100% da altura da tela */

  .iconarrow {
    @media (max-width: 1260px) {
      padding-bottom: 5rem;
    }
  }

  .content {
    z-index: 1;
    display: flex;
    flex-flow: column;
    gap: 62px;
    grid-template-rows: repeat(3, min-content);
    align-items: center;
    color: ${(props) => "#fff"};
    text-align: center;
    width: fit-content;
    max-width: 80%;
    padding-top: 53px;

    .titleWrapper {
      width: 85%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-family: "Montserrat", serif;
        font-weight: 500;
        font-size: 67px;
        text-align: center;
        text-shadow: 0px 5px 5px rgba(51, 51, 51, 0.25);
        margin-top: 30px;
        line-height: 1.2;

      }

      @media (max-width: 768px) {
        h1 {
          font-size: 40px;
        }
        h2 {
          font-size: 18px;
        }
      }

      @media (max-width: 480px) {
        h1 {
          font-size: 28px;
        }
        h2 {
          font-size: 14px;
        }
      }
    }
  .button {
    margin-top: 23px;
    margin-bottom: 48px;
    width: 100%;
    border-radius: 26px;
    background: #ef5f00;
    color: #fff;
    font-size: 25px;
    padding-inline: 10px;
    padding-block: 5px;
    font-weight: 500;
    transition: background-color 0.3s;
    box-shadow: 0 0 15px #fff;
    padding: 25px;
    &:hover {
      background: #ff6702;
      box-shadow: 0 0 15px #ff6702;
    }

    span {
      font-family: "Bebas Neue";
    }
  }
`;

export const ShaddowBg = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background: ${({
    deg = 180,
    color1 = "transparent",
    color2 = "transparent",
    percent1 = 0,
    percent2 = 100,
  }) => `
  linear-gradient(
    ${deg}deg,
    ${color1} ${percent1}%,
    ${color2} ${percent2}%)
  `};
`;

export const HashLink = styled(HL)`
  width: fit-content;
`;

export const IconArrow = styled.img`
  width: 2.5rem;
  height: 1.55rem;
  opacity: 0.7;
  animation: pulse 1s infinite; // Aplica a animação chamada 'pulse' ao componente

  @keyframes pulse {
    0% {
      transform: translateY(0);
    }
    60% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

export const ContainerImg = styled.div`
  width: 4rem;
  padding: 0.6rem;
  background-color: #fff;
  border-radius: 50%;
`;

export const HomeContainer = styled.div``;
