import { apiURL } from "../../usecases/api";

export class ApiEstablishmentUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getEstablishment(companyId) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Establishment?companyId=${companyId}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response;
      } else {
        console.log("Error:", error.message);
        throw new Error(error.mesage);
      }
    }
  }

  async getEstablishmentById(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Establishment/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response;
      } else {
        console.log("Error:", error.message);
        throw new Error(error.mesage);
      }
    }
  }

  async registEstablishment(data) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Establishment`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response;
      } else {
        console.log("Error:", error.message);
        throw new Error(error.mesage);
      }
    }
  }

  async updateEstablishment(data) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Establishment`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response;
      } else {
        console.log("Error:", error.message);
        throw new Error(error.mesage);
      }
    }
  }

  async deleteEstablishmentById(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Establishment/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response;
      } else {
        console.log("Error:", error.message);
        throw new Error(error.mesage);
      }
    }
  }
}
