import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiBusinessPartnerUseCases } from "~/data/usecases/businessPartner/businessPartner";
import { HeaderSectionPage } from "../../components/HeaderSectionPage";
import RButton from "../../components/Common/Buttons/RButton";
import { Grid, Box, Typography as Text } from "@mui/material";
import { CompanyForm } from "./components/CompanyForm/CompanyForm";
import { PersonForm } from "./components/PersonForm/PersonForm";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import ModalContainer from "../../components/ModalContainer/ModalContainer";

export const CreateNfse = () => {
  const history = useHistory();
  const apiBusinessPartner = new ApiBusinessPartnerUseCases(makeHttpClient());
  const [perfilType, setPerfilType] = useState("");
  const [open, setOpen] = React.useState(true);
  const [partners, setPartners] = useState();
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const getPartners = async () => {
    const result = await apiBusinessPartner.listBusinessPartner();
    setPartners(result);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleBackdropClick = () => {
    history.push("/reinf/painel-doc-fiscais");
  };

  const handleFormSelect = (type) => {
    setPerfilType(type);
    handleClose();
  };

  const renderFormAddDoc = () => {
    switch (perfilType) {
      case "CompanyForm":
        return (
          <CompanyForm
            partners={partners}
            handleReturn={handleBackdropClick}
            refreshData={refreshData}
          />
        );
      case "PersonForm":
        return <PersonForm />;
      default:
        return null;
    }
  };

  useEffect(() => {
    handleOpen();
    getPartners();
  }, []);

  const refreshData = () => {
    getPartners();
  };

  return (
    <>
      <HeaderSectionPage title="Adicionar Documentos Fiscais" />
      <ModalContainer
        sx={{ maxWidth: "600px" }}
        open={open}
        onClose={handleClose}
        onBackdropClick={handleBackdropClick}
      >
        <Box sx={styles.Modal}>
          <Text sx={styles.title}>Qual o perfil do contratado?</Text>
          <Grid container>
            <Grid xs={10} style={styles.ButtonsContainer}>
              <RButton
                variant="contained"
                color="primary"
                onClick={() => {
                  // handleFormSelect("PersonForm")
                  setFeedbackSnackbar({
                    isOpen: true,
                    message: "Página em desenvolvimento...",
                    type: "info",
                  });
                }}
              >
                Pessoa Física
              </RButton>
              <RButton
                variant="contained"
                color="primary"
                onClick={() => handleFormSelect("CompanyForm")}
              >
                Pessoa Jurídica
              </RButton>
            </Grid>
          </Grid>
        </Box>
      </ModalContainer>
      {renderFormAddDoc()}
    </>
  );
};

const styles = {
  Modal: {
    padddingInline: 10,
    paddingBlock: 5,
  },
  ButtonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    fontFamily: "Montserrat",
    marginTop: "10px",
    margin: "0 auto",
    padddingInline: 10,
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.4rem",
    fontWeight: "bold",
    color: "#242424",
    padddingInline: 3,
    paddingBottom: 3,
  },
};
