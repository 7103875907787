import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  border: 2px solid #383f48;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${darken(0.06, "#dedede")};
  }
  &:active {
    background-color: ${darken(0.09, "#dedede")};
  }

  &.dragActive {
    border-color: #26c6da;
    color: #26c6da;
  }

  p.fileName {
    text-decoration: underline;
  }
`;
