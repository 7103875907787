import { apiURL } from "../../usecases/api";

export class ApiLegislationCategoryUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getLegislationCategories() {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/category`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      console.log("Missing endpoint: ", err);
    }
  }

  async deleteLegislationCategory(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/category/${id}`,
      method: "DELETE",
    });
    return response.data;
  }

  async createLegislationCategory(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/category`,
      method: "POST",
      data,
    });
    return response.data;
  }

  async updateLegislationCategory(id, data) {
    console.log(data);
    const response = await this.httpClient.request({
      url: `${apiURL}/category/`,
      method: "PUT",
      data,
    });
    return response.data;
  }

  async getLegislationCategoryById(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/category/${id}`,
      method: "GET",
    });
    return response.data;
  }
}
