import { Add } from "@mui/icons-material";
import { Grid, IconButton, Typography, Paper } from "@mui/material";
import React from "react";
import SearchField from "~/presentation/components/SearchField";

const PageListHeader = ({
  handleAdd,
  searchFieldRef,
  handleSearch,
  titleAccess,
  title,
  doSearchWhenUserClickInTheIcon,
  onClickSearchIcon,
  filterComp,
}) => {
  return (
    <Paper elevation={1} sx={styles.Box}>
      <Grid container spacing={1} sx={{ alignItems: "center", marginBottom: 1 }}>
        <Grid item xs={6} md={4}>
          <Typography sx={styles.title}>{title}</Typography>
        </Grid>
        <Grid item xs={6} md={3}>
          {filterComp}
        </Grid>
        {handleSearch && (
          <Grid item xs={11} sm={5} md={4}>
            <SearchField
              parentRef={searchFieldRef}
              handleSearch={handleSearch}
              doSearchWhenUserClickInTheIcon={doSearchWhenUserClickInTheIcon}
              onClickSearchIcon={onClickSearchIcon}
            />
          </Grid>
        )}
        {handleAdd && (
          <Grid item xs={1}>
            <IconButton onClick={handleAdd}>
              <Add titleAccess={titleAccess} />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default PageListHeader;

const styles = {
  Box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
    marginBottom: 2,
    marginTop: 2,
    paddingBlock: 1,
    paddingInline: 1,
    "@media (min-width: 600px)": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },

  title: {
    fontFamily: "Montserrat, sans-serif",
    textAlign: "left",
    fontSize: 28,
    fontWeight: 600,
    color: "#242424",
  },
};
