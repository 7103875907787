import styled from "styled-components";

export const OuterContainer = styled.div`
  margin-bottom: -39px;
  padding-left: 30px;
  padding-right: 30px;
  z-index: 2;

  &::before {
    content: "";
    position: absolute;
    background-color: #e97c03;
    height: 80%;
    top: 50%;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    transform: translateZ(20px);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    transform: translateY(-50%);
  }
`;
export const Container = styled.div`
  position: relative;
  background-color: #ffffff;
  display: flex;
  padding: 10% 12%;  
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
  z-index: 2;

  @media screen and (min-width: 600px) {
    padding: 6% 8%;
  }

  .bgImg {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 75%;
    width: 609px;
    height: 415px;
    background: url("/assets/Business-people-working-on-laptop-462164.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 2;    

    @media screen and (max-width: 1100px) {
      display: none;
    
  }

  .bgImg::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: 1;
    transform: translateZ(-20px);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  }

  .linearGradient {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    );
  }

  /** Max Width */
  @media screen and (max-width: 960px) {
    min-height: 100vh;

    .linearGradient {
      background: linear-gradient(
        179deg,
        #ffffff 45%,
        rgba(255, 255, 255, 0) 75%
      );
    }
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-areas:
    "title"
    "content";
  grid-template-columns: auto;
  gap: 34px;
  width: 100%;
  grid-template-rows: min-content min-content;

  @media screen and (min-width: 960px) {
    grid-template-areas:
      "line title"
      "line content";
    grid-template-columns: 3px auto;
    align-self: center;
    width: 50%;
    grid-template-rows: min-content min-content;
  }

  @media screen and (min-width: 1160px) {
    width: 45%;
  }

  .line {
    grid-area: line;
    background-color: #1341a1;
    width: 3px;
    height: 100%;

    @media screen and (max-width: 960px) {
      display: none;
    }
  }

  .title {
    font-family: "Montserrat";
    color: #18294d;
    font-size: 26px;
    grid-area: title;
    display: flex;
    flex-flow: column;
    align-items: center;

    @media screen and (min-width: 960px) {
      align-items: flex-start;
    }

    > h2 {
      font-family: "Montserrat";
      font-size: 26px;
      color: #18294d;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 10px;
      text-align: justify;
      text-align: center;

      @media screen and (min-width: 960px) {
        text-align: left;
      }

      @media screen and (max-width: 600px) {
        font-size: 1rem;
      }
    }
  }

  .content {
    grid-area: content;

    > p {
      font-family: "Montserrat";
      color: #18294d;
      font-size: 16px;
      line-height: 130%;
      text-align: left;
    }
  }
`;
