import React from 'react';
import { makeAnswerUseCases } from '~/main/factories/use-cases/answers';
import { makeCityUseCases } from '~/main/factories/use-cases/city';
import { makeCnaesUseCases } from '~/main/factories/use-cases/cnaes';
import { makeCompanyUseCases } from '~/main/factories/use-cases/company';
import { makeItemAnswerUseCases } from '~/main/factories/use-cases/item-answer';
import { makeStateUseCases } from '~/main/factories/use-cases/state';
import { makeTermUseCases } from '~/main/factories/use-cases/term';
import { AnswerCreate } from '~/presentation/views/Admin/Answer/Create';

export const makeAnswerCreate = (params) => (
    <AnswerCreate
        {...params}
        companyUseCases={makeCompanyUseCases()}
        cnaesUseCases={makeCnaesUseCases()}
        answerUseCases={makeAnswerUseCases()}
        itemAnswerUseCases={makeItemAnswerUseCases()}
        stateUseCases={makeStateUseCases()}
        cityUseCases={makeCityUseCases()}
        termUserCases={makeTermUseCases()}
    />
)