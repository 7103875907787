import React from "react";
import {
  Container,
  Header,
  Main,
  ButtonAbout,
} from "~/presentation/views/LandingPage/Home/Sections/Products/styles";
import { Fade } from "react-reveal";
import { Typography as Text, Box } from "@mui/material";
import useMobile from "~/presentation/hooks/useMobile";
import GTFacilModal from "~/presentation/views/LandingPage/Home/Modals/GTFacilModal";
import SelectedContentModal from "~/presentation/views/LandingPage/Home/Modals/SelectedContentModal";
import BlogModal from "~/presentation/views/LandingPage/Home/Modals/BlogModal";
import CalculatorIcon from "~/presentation/views/LandingPage/icons/CalculatorIcon";
import TextIcon from "~/presentation/views/LandingPage/icons/TextIcon";
import TaskIcon from "~/presentation/views/LandingPage/icons/TaskIcon";
import DialogIcon from "~/presentation/views/LandingPage/icons/DialogIcon";
import ReinfInfoModal from "~/presentation/views/LandingPage/Home/Modals/ReinfInfoModal";

const products = [
  {
    icon: CalculatorIcon,
    title: "GT-Fácil",
    excerpt:
      "Um poderoso recurso de pesquisa desenvolvido para orientar empresas e entidades públicas e privadas na tomada de decisões em relação à tributação de seus contratos.",
    modalComponent: GTFacilModal,
  },
  {
    icon: TaskIcon,
    title: "GT-Reinf",
    excerpt:
      "Tenha em mãos o poderoso sistema de envio rápido e fácil da EFD-Reinf que garante maior eficiência no cumprimento das suas obrigações fiscais.",
    modalComponent: ReinfInfoModal,
  },
  {
    icon: TextIcon,
    title: "Conteúdo Selecionado",
    excerpt:
      "Selecionamos os conteúdos mais relevantes e publicamos aqui apenas o que diz respeito ao INSS, IRRF, Contribuições Sociais (CSLL, PIS/Pasep e COFINS) e ISS.",
    modalComponent: SelectedContentModal,
  },
  {
    icon: DialogIcon,
    title: "Blog",
    excerpt:
      "Tenha acesso às séries exclusivas produzidas pelo nosso time de especialistas para lhe atualizar acerca das principais polêmicas envolvendo a matéria.",
    modalComponent: BlogModal,
  },
];

const Product = ({ id, openModal }) => {
  const [mobile] = useMobile();

  const chooseAnimationDelay = (index) => {
    if (mobile) return 0;
    else return 200 * index;
  };

  return (
    <Container id={id}>
      <Fade bottom distance="10%">
        <div
          style={{
            color: "#3ED1DB",
            textAlign: "center",
            fontFamily: "Bebas Neue",
            fontSize: "29px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            marginTop: "8%",
            marginBottom: "-8%",
            paddingTop: "2rem",
          }}
        >
          SOLUÇÕES DO SISTEMA WEB GESTÃO TRIBUTÁRIA
        </div>
        <Header>
          <hr />
          <h2>Principais Recursos</h2>
          <hr />
        </Header>
      </Fade>
      <Main>
        {products.map((p, index) => (
          <Fade
            bottom
            distance="20%"
            delay={chooseAnimationDelay(index)}
            key={"landing-page-products-card-" + index}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <>
                <p.icon />
                <Text
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    paddingBlock: 3,
                    fontSize: 20,
                    color: "#fff",
                  }}
                >
                  {p.title}
                </Text>
                <Text
                  sx={{
                    fontFamily: "Montserrat",
                    fontWeight: 300,
                    paddingBlock: 2,
                    fontSize: 16,
                    color: "#fff",
                    padding: 3,
                  }}
                >
                  {p.excerpt}
                </Text>
              </>
              <ButtonAbout>
                <button className="button" onClick={() => openModal(p.modalComponent)}>
                  Saiba Mais
                </button>
              </ButtonAbout>
            </Box>
          </Fade>
        ))}
      </Main>
    </Container>
  );
};

export default Product;
