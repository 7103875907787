import React, { useEffect, useState } from "react";

import {Text, TextInnerHtml, TextWrapper} from "./styles";
import { Card } from "~/presentation/views/GTFacil/components/Card";


export const SearchCard = ({ getSearch, searchType }) => {
  const [search, setSearch] = useState();
  const [fontSize, setFontSize] = useState(18);

  useEffect(() => {
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const vwToPixels = viewportWidth * 0.013;
    const calculatedFontSize = Math.max(18, vwToPixels);
    setFontSize(calculatedFontSize);
  }, []);

  const updateFontSize = () => {
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    const vwToPixels = viewportWidth * 0.013;
    const calculatedFontSize = Math.max(18, vwToPixels);
    setFontSize(calculatedFontSize);
  };

  const loadSearch = async () => {
    setSearch(await getSearch());
  };

  const renderPreTitle = () => {
    switch (searchType) {
      case "cnae":
        return "Código CNAE";
      case "lc116":
        return "Código na LC 116/2003";
      default:
        return "Código na LC 116/2003";
    }
  };

  const renderCode = () => {
    const { code, detailCode } = search;
    if (code && detailCode) {
      return `${code} (${detailCode}) - `;
    } else {
      return `${code} - `;
    }
  };

  useEffect(() => {
    loadSearch();
    window.addEventListener("resize", updateFontSize);
    return () => {
      window.removeEventListener("resize", updateFontSize);
    };
  }, []);

  useEffect(() => {
    if(search?.observation){
      localStorage.setItem("selected-subitem", search?.observation.substring(0, 4));
    }else{
      localStorage.removeItem("selected-subitem");
    }
  }, search);

  return (
    <Card title="Sua pesquisa foi realizada por:" loading={!search}>

      {search && searchType === "cnae" && (
        <TextWrapper>
          <Text>
            <b>{renderPreTitle()}:</b> {renderCode()}
            {search.description}
          </Text>
          <Text>
            <b>Relacionado ao subitem da LC 116/2003:</b> {search.observation}
          </Text>
          {search.annotation ? (
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Text style={{ marginRight: 2 }}>
                <b>Observação: </b> <TextInnerHtml dangerouslySetInnerHTML={{ __html: search.annotation }} />
              </Text>
            </div>
          ) : null}
        </TextWrapper>
      )}
      {search && searchType === "answer" && (
        <TextWrapper>
          <Text>
            <b>{renderPreTitle()}:</b> {renderCode()}{" "} {search.description}
            {search.gtAnswerName}
          </Text>
          <Text>
            <b>Relacionado ao CNAE:</b> {search.observation}
          </Text>
          {search.annotation ? (
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Text style={{ marginRight: 2 }}>
                <b>Observação: </b> <TextInnerHtml dangerouslySetInnerHTML={{ __html: search.annotation }} />
              </Text>
            </div>
          ) : null}
        </TextWrapper>
      )}

{search && searchType === "freeSearch" && (
        <TextWrapper>
          <Text>
            <b>Pesquisa específica:</b> {search.term}
          </Text>
        </TextWrapper>
      )}
    </Card>
  );
};
