import * as Yup from 'yup';
import { validarEmail } from '~/presentation/functions/emailValidator';
import removeMask from '~/presentation/functions/removeMask';
import { validatePassword } from '~/presentation/utils/validatePassword';
import { CONSTANT_FIELD_REQUIRED_LABEL } from '~/presentation/utils/constants';

const validationSchema = editing =>
  Yup.object({
    company: Yup.string().required(CONSTANT_FIELD_REQUIRED_LABEL),
    name: Yup.string().required(CONSTANT_FIELD_REQUIRED_LABEL),
    role: Yup.string().required(CONSTANT_FIELD_REQUIRED_LABEL),
    email: Yup.string()
      .test('email', 'Email inválido!', value => validarEmail(value))
      .required(CONSTANT_FIELD_REQUIRED_LABEL),
    password: Yup.string()
      .test(
        'password',
        'A senha deve conter no mínimo 8 caracteres, letra maiúscula, letra minúscula, número e caractere especial.',
        value => validatePassword({ password: value, editing }),
      )
      .required(CONSTANT_FIELD_REQUIRED_LABEL),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'As senhas devem ser iguais.')
      .required(CONSTANT_FIELD_REQUIRED_LABEL),
    phone: Yup.string()
      .transform((value, originalValue) => originalValue && removeMask(value))
      .min(10, 'Digite um número válido')
      .required(CONSTANT_FIELD_REQUIRED_LABEL),
    mobile_phone: Yup.string()
      .transform((value, originalValue) => originalValue && removeMask(value))
      .min(11, 'Digite um número válido')
      .required(CONSTANT_FIELD_REQUIRED_LABEL),
  });

export default validationSchema;
