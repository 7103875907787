import React from "react";
// packages
import {Formik} from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextEditor from "~/presentation/components/TextEditor";
import {
  Container,
  Form,
} from "~/presentation/views/Admin/GTFacil/Dialog/Form/styles";
// schema
import validationSchema from "~/presentation/views/Admin/GTFacil/Dialog/Form/validationSchema";

const DialogForm = ({handleSubmit, initialValues}) => {
  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          await handleSubmit(values);
          action.setSubmitting(false);
        }}
      >
        {({values, setFieldValue}) => (
          <Form>
            <h2>Texto do diálogo</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <TextEditor
                  initialValue={values.text}
                  name="text"
                  height={400}
                  handleEditorChange={(content) => {
                    setFieldValue("text", content);
                  }}
                />
                <ErrorMessage name="text" />
              </div>
            </div>

            <PrimaryButton className="submit-btn" type="submit">
              Publicar
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default DialogForm;
