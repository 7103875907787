import React from "react";
import { AlertComponent } from "~/presentation/components/FeedbackSnackbar/styles";
import { IconButton, Snackbar, Slide } from "@mui/material";
import { Close } from "@mui/icons-material";

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

const SimpleSnackbar = ({
  isOpen = false,
  message = "Message!",
  type = "warning", //error, warning, info, success
  onClose,
}) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <Close fontSize="small" />
            </IconButton>
          </>
        }
      >
        <AlertComponent variant="filled" onClose={handleClose} severity={type}>
          {message}
        </AlertComponent>
      </Snackbar>
    </div>
  );
};

export default SimpleSnackbar;
