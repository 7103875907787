import React, { useContext, useState } from "react";
import { Badge, Box, Button, IconButton, Popover } from "@mui/material";
import { TextField } from "~/presentation/components/TextInput/styles";
import { FilterAlt } from "@mui/icons-material";
import { ThemeContext } from "styled-components";

export const Filters = ({ onFilter }) => {
  const apptheme = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    ContentType: "",
    ItemContent: "",
    CnaeCode: "",
    CompanyCategory: "",
  });
  const [appliedFilters, setAppliedFilters] = useState([]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const formatCnaeCode = (code) => {
    //peqsuisa de detalhamentode cnae, aplicando ; na escrita
    return code.replace(/\s*\(\s*/, ";").replace(/\s*\)\s*/, "");
  };

  const handleApplyFilter = () => {
    const formattedFilters = {
      ...filters,
      CnaeCode: formatCnaeCode(filters.CnaeCode),
    };
    onFilter(formattedFilters);
    setAnchorEl(null);
    updateAppliedFilters(formattedFilters);
  };

  const handleClearFilters = () => {
    setFilters({
      ContentType: "",
      ItemContent: "",
      CnaeCode: "",
      CompanyCategory: "",
    });
    onFilter({});
    setAppliedFilters([]);
    setAnchorEl(null);
  };

  const updateAppliedFilters = (currentFilters) => {
    const applied = [];
    Object.keys(currentFilters).forEach((key) => {
      if (currentFilters[key]) {
        applied.push(key);
      }
    });
    setAppliedFilters(applied);
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" justifyContent="flex-end" flex={1}>
        <IconButton aria-describedby={id} variant="text" color="inherit" onClick={handleOpen}>
          <Badge badgeContent={appliedFilters.length} color="primary">
            <FilterAlt titleAccess={"Filtrar"} />
          </Badge>
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Box p={2} bgcolor={"#dfdfdf"}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <h5 style={{ color: "#383f48" }}>Filtros</h5>
            </Box>
            <TextField
              label="Título do tipo de item"
              name="ContentType"
              value={filters.ContentType}
              onChange={handleFilterChange}
              style={{ marginBottom: 16 }}
            />
            <TextField
              label="Conteúdo do tipo de item"
              name="ItemContent"
              value={filters.ItemContent}
              onChange={handleFilterChange}
              style={{ marginBottom: 16 }}
            />
            <TextField
              label="Código do CNAE"
              name="CnaeCode"
              value={filters.CnaeCode}
              onChange={handleFilterChange}
              style={{ marginBottom: 16 }}
            />
            <TextField
              label="Categoria da empresa"
              name="CompanyCategory"
              value={filters.CompanyCategory}
              onChange={handleFilterChange}
            />
            <Box mt={2} display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
              <Button variant="outlined" color="error" onClick={handleClearFilters}>
                Limpar filtros
              </Button>
              <Button variant="outlined" color="primary" onClick={handleApplyFilter}>
                Filtrar
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};
