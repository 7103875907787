import React from "react";
import { Container, IconWrapper } from "./styles";
import { Button } from "@mui/material";

export const Card = ({ icon, title, text, onClick, buttonVariant }) => {
  return (
    <Container>
      <IconWrapper>
        <span className="line" />
        <img src={icon} />
        <span className="line" />
      </IconWrapper>
      <h3>{title}</h3>
      <p>{text}</p>
      <Button
        variant={buttonVariant}
        onClick={onClick}
        sx={{
          color: "#cf9455",
          borderColor: "#cf9455",
          textTransform: "capitalize",
          fontWeight: 500,
          width: 150,
          p: 1,
          fontSize: 15,
          "&:hover": {
            color: "#fff",
            borderColor: "#cf9455",
            background: "#cf9455",
          },
        }}
      > Explore
      </Button>
    </Container>
  );
};
