import React from "react";
import AppContext from "~/presentation/AppContext";
// import { htmlToText } from "~/presentation/functions/htmlToText";
import { format } from "date-fns";

import { NewCommentBox, TextArea, CommentList, Date as DateComponent } from "./styles";
import { Box, Typography as Text, Button } from "@mui/material";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
const getAvatarName = (str) => {
  return str.toUpperCase().slice(0, 2);
};

const CommentsSection = ({ handleComment, comments = [] }) => {
  const { setFeedbackSnackbar } = React.useContext(SnackbarContext);
  const { user, openBackdrop } = React.useContext(AppContext);
  const userNameFirstLetters = getAvatarName(user.name);
  const [message, setMessage] = React.useState("");

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const handleSubmit = () => {
    if (message.trim() !== "") {
      handleComment(message);
      setMessage("");
    } else {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Ops, você esqueceu de escrever seu comentário!",
        type: "error",
      });
    }
  };

  const formatDate = (date) => {
    let formattedDate = new Date(date);
    formattedDate = format(formattedDate, "dd/MM/yyyy");
    return formattedDate;
  };

  return (
    <Box>
      {/* <SectionHeader text="Comentários" /> */}
      <NewCommentBox>
        {/* <Avatar>{userNameFirstLetters}</Avatar> */}
        <TextArea
          value={message}
          onChange={handleChange}
          placeholder="Deixe seu comentário aqui..."
          required
        />
        <Button sx={styles.button} onClick={handleSubmit}>
          ENVIAR COMENTÁRIO
        </Button>
      </NewCommentBox>
      <CommentList>
        {comments ?? <Text sx={styles.title}>Comentários</Text>}
        {!!comments && <pre>{JSON.stringify(comments, undefined, 2)}</pre> &&
          comments?.map((thread) => (
            <>
              <Box key={"thread-" + thread.id}>
                <Box
                  sx={{ background: "#E1E1E1", padding: 2, borderRadius: 2 }}
                  index={thread.id}
                  key={"message-" + thread.id}
                >
                  {/* <Avatar>{userNameFirstLetters}</Avatar> */}

                  <Text sx={[styles.commentTitle, styles.montserrat]}>
                    {thread.userComment.email}
                  </Text>
                  <Text sx={[styles.commentText, styles.montserrat]}>{thread.commentText}</Text>
                  <Text sx={[styles.commentDate, styles.montserrat]}>
                    {thread?.publishDate && `Comentário feito em ${formatDate(thread.publishDate)}`}
                  </Text>
                </Box>
              </Box>
            </>
          ))}
      </CommentList>
    </Box>
  );
};
const styles = {
  montserrat: { fontFamily: "Montserrat" },
  title: {
    color: "#1F1F1F",
    fontSize: 30,
    fontFamily: "Montserrat",
    fontWeight: 500,
    textTransform: "uppercase",
    marginTop: 4,
    marginBottom: 0,
  },
  subtitle: {
    color: "#000",
    fontSize: 18,
    fontWeight: 300,
    fontFamily: "Montserrat",
    marginTop: 2,
    marginBottom: 1,
  },
  contentText: {
    fontFamily: "Montserrat",
    fontSize: 16,
    lineHeight: 1.6,
  },
  button: {
    backgroundColor: "#081445",
    fontFamily: "Montserrat",
    fontSize: 16,
    color: "#fff",
    padding: 2,
    marginBlock: 2,
    "&:hover": {
      backgroundColor: "#031a6d",
    },
  },
  commentTitle: {
    color: "1F1F1F",
    fontWeight: 500,
    fontSize: 18,
    mb: 0.5,
  },
  commentText: {
    color: "#787878",
    fontSize: 16,
  },
  commentDate: {
    fontSize: 14,
    color: "#787878",
    mt: 1,
  },
};

export default CommentsSection;
