import React, { useEffect } from "react";
import {
  Container,
  IconArrow,
  HomeContainer,
} from "~/presentation/views/LandingPage/Home/Sections/Home/style";
import { Fade } from "react-reveal";
import { Grid, Modal, Typography as Text, Button, Link, Box } from "@mui/material";

const Home = ({ id, mobile }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const shouldBeShowModal = () => {
    const dataLimit = new Date("2025/04/02");
    const today = new Date();

    if (today < dataLimit) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (shouldBeShowModal()) {
      const timer = setTimeout(() => {
        handleOpen();
      }, 2000);

      return () => clearTimeout(timer); // Limpa o timer ao desmontar
    }
  }, []);

  return (
    <HomeContainer>
      {shouldBeShowModal() && (
        <Modal open={open} onClose={handleClose}>
          <Link
            href="https://api.whatsapp.com/send?phone=5571992084907&text=Quero%20saber%20mais%20sobre%20a%20ferramenta%20GT-Reinf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box sx={styles.modal}>
              <Box
                component="img"
                src="/assets/popupreinf.jpg"
                alt="Modal Background"
                sx={styles.image}
              />
            </Box>
          </Link>
        </Modal>
      )}
      <Container id={id} shouldBeShowBanner={shouldBeShowModal()}>
        <Fade>
          <div className="content">
            <div className="titleWrapper">
              <h1>
                O melhor sistema <i style={{ fontFamily: "Montserrat", fontWeight: 500 }}>web</i>{" "}
                para quem trabalha com Retenções Tributárias.
              </h1>
              <Text sx={styles.subtitle}>
                Simulador de retenções online, sistema de{" "}
                <i style={{ fontFamily: "Montserrat", fontWeight: 600, fontStyle: "normal" }}>
                  envio rápido da EFD-Reinf
                </i>
                , artigos e legislação focados nas incidências de INSS, Imposto de Renda,
                Contribuições Sociais e ISS na fonte.
              </Text>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://api.whatsapp.com/send?phone=5571992084907&text=Ol%C3%A1!%20Quero%20experimentar%20o%20GT-F%C3%A1cil%20junto%20a%20um%20representante%20da%20Open%20Solu%C3%A7%C3%B5es%20Tribut%C3%A1rias"
              >
                {" "}
                <Button
                  variant="contained"
                  sx={{
                    marginTop: "23px",
                    marginBottom: "48px",
                    width: "100%",
                    borderRadius: "26px",
                    backgroundColor: "#ef5f00",
                    color: "#fff",
                    fontSize: "25px",
                    paddingInline: "12px 12px 12px",
                    fontWeight: 500,
                    transition: "background-color 0.3s",
                    boxShadow: "0 0 15px #fff",
                    "&:hover": {
                      backgroundColor: "#ff6702",
                      boxShadow: "0 0 15px #ff6702",
                    },
                  }}
                >
                  <span style={{ fontFamily: "Bebas Neue" }}>QUERO EXPERIMENTAR AGORA</span>
                </Button>
              </a>
              <div className="iconarrow">
                <IconArrow src="/assets/icons/arrow.png" alt="Ícone de seta" />
                {/* </Link> */}
              </div>
            </div>
          </div>
        </Fade>
      </Container>
    </HomeContainer>
  );
};

export default Home;

const styles = {
  subtitle: {
    fontFamily: "Montserrat, serif",
    fontWeight: 500,
    fontSize: 23,
    textAlign: "center",
    lineHeight: 1.5,
  },
  //modal styles
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "700px",
    width: "100%",
    maxHeight: "80%",
    borderRadius: "18px",
    overflow: "auto",
    boxShadow: 24,
    bgcolor: "#E8E8E8",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "18px",
  },
};
