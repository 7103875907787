import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { CheckCircleOutlineOutlined } from "@mui/icons-material";

export const ConsultSituation = ({ protocol, responseDetails, code, receipt, message }) => {
  const normalizedDetails = Array.isArray(responseDetails) ? responseDetails : [responseDetails];
  const handleDownloadXml = (xmlContent, protocol) => {
    if (!xmlContent) return;

    const blob = new Blob([xmlContent], { type: "text/xml" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = `ProtocoloEfdReinf_${protocol}.xml`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  if (code === 2 && receipt) {
    return (
      <Box sx={{ padding: 3 }}>
        <Grid sx={styles.container} container spacing={2}>
          <Grid item xs={12} sm={9} md={10}>
            <Typography style={styles.Title}>Detalhes do protocolo {protocol}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
            <Typography sx={styles.subtitle}>Recibo nº</Typography>
            <Typography sx={styles.description}>{receipt}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={styles.subtitle}>Descrição da resposta</Typography>
            <Typography
              sx={{ ...styles.description, display: "flex", alignItems: "center", gap: 1 }}
            >
              {message}.
              <CheckCircleOutlineOutlined color="success" fontSize="small" />
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      {normalizedDetails.map((detail, responseIndex) => (
        <Box key={responseIndex}>
          <Grid sx={styles.container} container spacing={2}>
            <Grid item xs={12} sm={9} md={10}>
              <Typography style={styles.Title}>Detalhes do protocolo {protocol}</Typography>
            </Grid>
            {detail?.xml && (
              <Grid item xs={12} sm={3} md={2}>
                <RButton onClick={() => handleDownloadXml(detail.xml, protocol)}>
                  Baixar XML
                </RButton>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography sx={styles.subtitle}>Descrição da resposta</Typography>
              <Typography sx={styles.description}>
                {`${detail.codeResponse} - ${detail.descriptionResponse}`}
              </Typography>
            </Grid>

            {detail?.events?.map((event, index) => (
              <Grid item xs={12} key={index}>
                <Typography sx={styles.subtitle}>Evento</Typography>
                <Typography sx={styles.description}>
                  {`Protocolo: ${event.protocolNumber}, N. do Recibo: ${
                    event.receiptNumber || "Não disponível"
                  }`}
                </Typography>
              </Grid>
            ))}
            {detail?.occurrences?.map((occ, index) => (
              <Grid item xs={12} key={index}>
                <Typography sx={styles.subtitle}>Ocorrências</Typography>
                <Typography
                  sx={styles.description}
                >{`${occ.description} - ${occ.text}`}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

const styles = {
  Title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 24,
    fontWeight: 700,
    color: "#404040",
  },
  subtitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: "#404040",
    pt: 2,
  },
  description: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "justify",
    pt: 1,
  },
  closeButton: {
    marginTop: 2,
    marginLeft: "auto",
    width: "30%",
  },
  container: {
    paddingBlock: 2,
    paddingLeft: 4,
    paddingRight: 4,
  },
};
