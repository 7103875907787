import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";

import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import PageListHeader from "../components/PageListHeader";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import CreateEdit from "./CreateEdit/CreateEdit";

const columns = [
  { name: "title", label: "Título" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const BlogCategory = ({ blogCategoryUseCases }) => {
  const { haveSuperAdminAccess } = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [categories, setCategories] = useState([]);

  //Search
  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const categories = await blogCategoryUseCases.getBlogCategories();

    const userIsSuperAdmin = haveSuperAdminAccess();

    categories.forEach((category) => {
      if (userIsSuperAdmin) {
        category.actions = (
          <TableActions
            handleDelete={() => deleteDialog(category.id)}
            handleEdit={() => handleOpenForm(category)}
          />
        );
      } else {
        category.actions = <TableActions handleEdit={() => handleOpenForm(category)} />;
      }
    });

    setCategories(categories);
    openBackdrop(false);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo categoria");
    try {
      await blogCategoryUseCases.deleteBlogCategory(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: `Categoria eliminada com sucesso!`,
        type: "success",
      });
      setCategories((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro " + err?.response?.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta categoria?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <CreateEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refresData={fetchData}
          blogCategoryUseCases={blogCategoryUseCases}
        />
      ) : (
        <>
          <PageListHeader
            title="Categoria"
            titleAccess="Nova categoria"
            searchFieldRef={searchFieldRef}
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
          />
          <Table searchRef={hiddenInputRef} columns={columns} data={categories} />
        </>
      )}
    </Container>
  );
};

export default BlogCategory;
