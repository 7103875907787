import { Fade } from "@mui/material";
import React from "react";
import {
  DialogComponent,
  DialogContentComponent,
  DialogActionsComponent,
  ButtonComponent,
} from "~/presentation/components/DialogBox/styles";

const AlertDialog = ({
  message = "Deseja exibir uma mensagem?",
  handleConfirm,
  handleDeny,
  confirmText = "Sim",
  denyText = "Não",
}) => (
  <>
    <DialogContentComponent>
      <div className="message">{message}</div>
    </DialogContentComponent>
    <DialogActionsComponent>
      <ButtonComponent onClick={handleConfirm} autoFocus>
        {confirmText}
      </ButtonComponent>
      <ButtonComponent onClick={handleDeny}>{denyText}</ButtonComponent>
    </DialogActionsComponent>
  </>
);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const DialogBox = ({ config, setDialogBox }) => {
  const {
    customComponent: CustomComponent = false,
    open = false,
    callback = () => console.log("no function"),
    negativeCallback = () => console.log("no function"),
    message,
    confirmText,
    denyText,
    fullScreen,
    blockScreen = false, //block screen é para não deixar sair do modal até responder, como no Re-login quando o token expira
    maxWidth = false, // 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
    fullWidth = false,
  } = config;

  const handleConfirm = () => {
    callback();
    setDialogBox({ ...config, open: false });
  };

  const handleDeny = () => {
    negativeCallback();
    setDialogBox({ ...config, open: false });
  };

  const resetDialog = () => {
    setDialogBox({});
  };

  return (
    <DialogComponent
      TransitionComponent={Transition}
      open={open}
      onClose={blockScreen ? null : handleDeny}
      onExited={resetDialog}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      {CustomComponent ? (
        <CustomComponent handleDeny={handleDeny} />
      ) : (
        <AlertDialog
          handleConfirm={handleConfirm}
          handleDeny={handleDeny}
          confirmText={confirmText}
          denyText={denyText}
          message={message}
        />
      )}
    </DialogComponent>
  );
};

export default DialogBox;
