import React, { useState, useEffect } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiExplanatoryVideoUseCases } from "~/data/usecases/explanatory-videos/api-explanatory-video";
import Footer from "~/presentation/components/Footer";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { parseISO, format } from "date-fns";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputBase,
  Container,
  Paper,
  Stack,
  Pagination,
  Skeleton,
} from "@mui/material";
import {
  CalculatorIcon,
  DialogIcon,
  TaskIconColor,
} from "~/presentation/views/Client/ClientHome/Sections/Features/icons";

const categories = [
  {
    title: "GT-Reinf",
    icon: TaskIconColor,
    description:
      "A Reinf registra retenções na fonte e dados sobre serviços tomados/prestados por empresas.",
  },
  {
    title: "GT-Fácil",
    icon: CalculatorIcon,
    description:
      "Sua tributação em relação ao INSS, IRRF, CSLL, PIS/Pasep, COFINS e ISS conforme o tipo de serviço.",
  },
  {
    title: "IF's",
    icon: DialogIcon,
    description:
      "Informações Fiscais são dados e documentos necessários para cumprir obrigações tributárias e fiscalizações.",
  },
];

export const ExplanatoryVideos = () => {
  const postsUseCases = new ApiExplanatoryVideoUseCases(makeHttpClient());
  const [posts, setPosts] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const postPerPage = 8;

  const handleChange = (isExpanded, panel) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getPosts = async (data) => {
    setLoading(true);
    const result = await postsUseCases.get(data);
    setPosts(result);
    setLoading(false);
  };

  useEffect(() => {
    getPosts();
  }, []);

  //lógica de páginação
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // lógica de filtro de pesquisa
  const filteredPosts = posts.filter(
    (post) =>
      post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const startIndex = (page - 1) * postPerPage;
  const endIndex = startIndex + postPerPage;
  const currentPosts = filteredPosts.slice(startIndex, endIndex);

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {() => (
        <>
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#0d62a5",
              paddingBlock: 8,
            }}
          >
            <Container maxWidth="lg">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6}>
                  <Stack spacing={2} sx={{ textAlign: "left" }}>
                    <Typography variant="h1" sx={styles.h1}>
                      Central de Ajuda
                    </Typography>
                    <Typography variant="body1" sx={styles.paragraph}>
                      Aqui você encontrará respostas para as perguntas mais frequentes e guias
                      detalhados para solucionar qualquer problema que possa encontrar em nosso
                      sistema. Explore nossos artigos e descubra como tirar o máximo proveito de
                      nossas funcionalidades.
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Container maxWidth="lg" sx={{ paddingBottom: 5 }}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Typography variant="h2" sx={{ ...styles.title, paddingTop: 4 }}>
                  Principais Ferramentas
                </Typography>
              </Grid>
              {/* categorias dos cards */}
              {categories.map((category, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Paper elevation={2} sx={styles.textBox}>
                    <Stack
                      spacing={3}
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        paddingBlock: 5,
                        paddingInline: 3,
                      }}
                    >
                      <Box
                        component={category.icon}
                        sx={{ width: 50, height: 60, objectFit: "contain" }}
                      />
                      <Typography variant="h6" sx={styles.title}>
                        {category.title}
                      </Typography>
                      <Typography variant="body2" sx={styles.description}>
                        {category.description}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grid>
              ))}

              <Grid item xs={12}>
                <Paper
                  component="form"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#E9E9E9",
                    paddingInline: "30px",
                    fontSize: "30px",
                    fontWeight: 500,
                    border: "none",
                    borderRadius: "20px",
                    height: 50,
                  }}
                >
                  <InputBase
                    sx={{ flex: 1, color: "#000000" }}
                    placeholder="Buscar nas perguntas frequentes"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <SearchIcon />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h2" sx={styles.title}>
                  Perguntas Frequentes
                </Typography>
              </Grid>
              <Grid item xs={12} sx={styles.accordionsContainer}>
                {loading ? (
                  Array.from(new Array(8)).map((_, index) => (
                    <Box key={index} sx={styles.accordion}>
                      <Skeleton variant="rectangular" width="100%" height={50} />
                    </Box>
                  ))
                ) : currentPosts.length < 1 ? (
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    Não encontramos resultados correspondentes
                  </Typography>
                ) : (
                  currentPosts.map((post, index) => (
                    <Accordion
                      key={index}
                      sx={styles.accordion}
                      expanded={expanded === `panel${index + 1}`}
                      onChange={(event, isExpanded) =>
                        handleChange(isExpanded, `panel${index + 1}`)
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index + 1}-content`}
                        id={`panel${index + 1}-header`}
                      >
                        <Typography sx={{ ...styles.title, fontSize: 16 }}>{post.title}</Typography>
                      </AccordionSummary>
                      <AccordionDetails sx={styles.accordionDetails}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              sx={{ ...styles.description, textAlign: "right", fontSize: 14 }}
                            >
                              Última atualização -{" "}
                              {format(parseISO(post.publishDate), "dd/MM/yyyy")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              sx={{
                                ...styles.innerHTMLContentStyle,
                              }}
                              dangerouslySetInnerHTML={{ __html: post.text }}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))
                )}
              </Grid>
              <Grid item xs={12} alignItems="center">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(posts.length / postPerPage)}
                    page={page}
                    onChange={handlePageChange}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      "& .MuiPaginationItem-root": {
                        color: "#1341A1",
                        "&.Mui-selected": {
                          backgroundColor: "#1341A1",
                          color: "#fff",
                        },
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Container>
          <Footer />
        </>
      )}
    </HomeLayout>
  );
};

const styles = {
  h1: {
    fontSize: 40,
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    paddingBottom: 2,
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 26,
    fontWeight: "bold",
    color: "#242424",
  },
  innerHTMLContentStyle: {
    // estilos da descrição HTML
    "&& strong": {
      "font-weight": "bold",

      "& *": {
        "font-weight": "bold",
      },
    },
  },
  paragraph: { fontSize: 18, color: "#94BEEA" },
  textBox: {
    borderRadius: 5,
    height: 320,
    width: "100%",
    color: "#000",
    backgroundColor: "#ededed",
  },
  description: { color: "#9B9B9B", fontSize: 17, paddingTop: "10px", textAlign: "center" },

  accordionsContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: 320,
    height: "100%",
  },

  accordion: {
    backgroundColor: "#E9E9E9",
    border: "1px solid #0d62a5",
    width: "100%",
    borderRadius: "15px !important",

    "&:before": {
      display: "none",
    },
    color: "#7F7F7F",
    marginBlock: 2,
  },
  accordionDetails: {
    overflow: "auto",
    paddingBlock: 5,
    paddingInline: 10,
    color: "#000",
  },
};
