import React, { useState } from "react";
import { Fade } from "react-reveal";
import {
  CloseBtn,
  Container,
  OverlayContainerModal,
} from "~/presentation/views/LandingPage/components/Modal/styles";
import { RemoveScroll } from "react-remove-scroll";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";

const ANIMATION_TIMEOUT = 700;

const Modal = ({ open, closeModal, resetModal, component: Component }) => {
  const [fullOpen, setFullOpen] = useState(false);

  useEffect(() => {
    if (open === true) setFullOpen(true);
    else
      setTimeout(() => {
        setFullOpen(false);
        resetModal();
      }, ANIMATION_TIMEOUT - 100);
  }, [open]);

  return (
    <RemoveScroll enabled={fullOpen}>
      <OverlayContainerModal open={fullOpen}>
        <Container open={fullOpen}>
          <Fade duration={ANIMATION_TIMEOUT} when={open === true} distance="20%" top>
            <div className="background">
              <CloseBtn onClick={closeModal}>
                <CloseIcon />
              </CloseBtn>

              {/* component to be rendered inside modal */}
              {typeof Component === "function" ? <Component closeModal={closeModal} /> : Component}
              {/*  */}
            </div>
          </Fade>
        </Container>
      </OverlayContainerModal>
    </RemoveScroll>
  );
};

export default Modal;
