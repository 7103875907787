import React, { useState, useEffect, useContext } from "react";
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import MaskedInput from "~/presentation/components/MaskedInput";
import {
  Container,
  DataWrapper,
  FormWrapper,
  Form,
  Avatar,
} from "~/presentation/views/Admin/Profile/Display/styles";
//functions
import removeMask from "~/presentation/functions/removeMask";
//context
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import validationSchema from "~/presentation/views/Admin/Profile/Display/validationSchema";

const Profile = ({ userUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);

  const [initialValues, setInitialValues] = useState();

  const fetchLoggedUser = async () => {
    const { id, role } = JSON.parse(localStorage.getItem("GT_user"));
    const apiUser = await userUseCases.getUserById(id);
    return {
      name: apiUser.name,
      email: apiUser.email,
      company: apiUser.companyId,
      password: "********************",
      confirm_password: "********************",
      manager: `${apiUser.isManager}`,
      phone: apiUser.homePhone,
      mobile_phone: apiUser.mobilePhone,
      role: role === "admin" ? "Administrador do Sistema" : "Super Admin",
    };
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados de perfil");
    setInitialValues(await fetchLoggedUser());
    openBackdrop(false);
  };

  const handleSubmit = (values) => {};

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      {initialValues && (
        <>
          <DataWrapper>
            <Avatar>
              <img src="/assets/default_avatar.png" alt="user avatar" />
            </Avatar>
            <div>
              <h1>{initialValues.name}</h1>
              <h3>{initialValues.email}</h3>
              <h3>{initialValues.role}</h3>
            </div>
          </DataWrapper>
          <FormWrapper className="scrollbar-white">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={async (values, action) => {
                action.setSubmitting(true);
                openBackdrop(true, "Cadastrando usuário");

                const submitValues = {
                  // obrigatórios
                  companyId: Number(values.company),
                  name: values.name,
                  email: values.email,
                  password: values.password,
                  isManager: values.manager === "true" ? true : false,
                  // facultativos
                  homePhone: removeMask(values.phone),
                  mobilePhone: removeMask(values.mobile_phone),
                };

                const submitted = await handleSubmit(submitValues);

                action.setSubmitting(false);
                openBackdrop(false);

                if (submitted) action.resetForm();
              }}
            >
              {() => (
                <Form>
                  <h2>Meu Perfil</h2>
                  <div className="form-grid">
                    <div className="col-1_5">
                      <Field name="name" type="text" label="Nome *" as={TextInput} />
                      <ErrorMessage name="name" />
                    </div>

                    <div className="col-1_5">
                      <Field name="email" type="text" label="Email *" as={TextInput} />
                      <ErrorMessage name="email" />
                    </div>

                    <div className="col-1_5">
                      <Field
                        name="phone"
                        type="text"
                        mask="(99) 9999-9999"
                        label="Telefone"
                        as={MaskedInput}
                      />
                      <ErrorMessage name="phone" />
                    </div>

                    <div className="col-1_5">
                      <Field
                        name="mobile_phone"
                        type="text"
                        mask="(99) 99999-9999"
                        label="Celular"
                        as={MaskedInput}
                      />
                      <ErrorMessage name="mobile_phone" />
                    </div>

                    <div className="col-1_3">
                      <Field name="password" type="password" label="Senha *" as={TextInput} />
                      <ErrorMessage name="password" />
                    </div>

                    <div className="col-3_5">
                      <Field
                        name="confirm_password"
                        type="password"
                        label="Confirmar a Senha *"
                        as={TextInput}
                      />
                      <ErrorMessage name="confirm_password" />
                    </div>
                  </div>

                  <PrimaryButton className="submit-btn" type="submit">
                    Editar
                  </PrimaryButton>
                </Form>
              )}
            </Formik>
          </FormWrapper>
        </>
      )}
    </Container>
  );
};

export default Profile;
