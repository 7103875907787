import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { CheckCircleOutline, Error, Pending } from "@mui/icons-material";
export const ConsultSituation = ({ protocol, responseDetails }) => {
  const normalizedDetails = Array.isArray(responseDetails) ? responseDetails : [responseDetails];
  const handleDownloadXml = (xmlContent, protocol) => {
    if (!xmlContent) return;

    const blob = new Blob([xmlContent], { type: "text/xml" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = `ProtocoloEfdReinf_${protocol}.xml`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const code = responseDetails?.codeResponse;
  const icon =
    code === 2 ? (
      <CheckCircleOutline color="success" fontSize="small" />
    ) : code === 1 ? (
      <Pending fontSize="small" />
    ) : (
      <Error color="error" fontSize="small" />
    );

  return (
    <Box sx={{ padding: 3 }}>
      {normalizedDetails.map((detail, responseIndex) => (
        <Box key={responseIndex}>
          <Grid sx={styles.container} container spacing={2}>
            <Grid item xs={12} sm={9} md={10}>
              <Typography style={styles.Title}>Detalhes do protocolo {protocol}</Typography>
            </Grid>
            {detail?.xml && (
              <Grid item xs={12} sm={3} md={2}>
                <RButton onClick={() => handleDownloadXml(detail.xml, protocol)}>
                  Baixar XML
                </RButton>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography sx={styles.subtitle}>Descrição da resposta</Typography>
              <Typography
                sx={{ ...styles.description, display: "flex", gap: "2px", alignItems: "center" }}
              >
                {`${detail?.codeResponse} - ${detail?.descriptionResponse}`}
                {icon}
              </Typography>
            </Grid>
            {detail?.events?.map((event, index) => (
              <Grid item xs={12} key={index}>
                <Typography sx={styles.subtitle}>Evento {event?.id}</Typography>
                <Typography sx={styles.description}>Protocolo: {event?.protocolNumber}</Typography>
              </Grid>
            ))}
            {detail?.occurrences?.map((occ, index) => (
              <Grid item xs={12} key={index}>
                <Typography sx={styles.subtitle}>Ocorrências</Typography>
                <Typography
                  sx={styles.description}
                >{`${occ.description} - ${occ.text}`}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

const styles = {
  Title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 24,
    fontWeight: 700,
    color: "#404040",
  },
  subtitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: "#404040",
    pt: 2,
  },
  description: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontWeight: 500,
    textAlign: "justify",
    pt: 1,
  },
  container: {
    paddingBlock: 2,
    paddingLeft: 4,
    paddingRight: 4,
  },
};
