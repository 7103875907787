import React, { useEffect, useState } from "react";

import { NewTIForm } from "./components/NewTIForm";
import {
  Avatar,
  Box,
  Divider,
  Typography as Text,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TablePagination,
  Stack,
  Modal,
  Grid,
  TextField,
} from "@mui/material";
import { Close, Download } from "@mui/icons-material";

import { create } from "@mui/material/styles/createTransitions";

export const NewTaxInformation = ({ taxInformationUseCases, companyUseCases, userUseCases }) => {
  const user = JSON.parse(localStorage.getItem("GT_user"));
  const [remainingTI, setRemainingTI] = useState();
  const [taxData, setTaxData] = useState();
  const [taxInfo, setTaxInfo] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedTaxInfo, setSelectedTaxInfo] = useState();
  const [filteredTax, setFilteredTax] = useState([]);

  const getRemainingTaxInformation = async () => {
    const response = await taxInformationUseCases.getRemainingTaxInformation(user.companyId);
    setRemainingTI(response);
  };

  const getTaxInfo = async () => {
    const taxResult = await taxInformationUseCases.getTaxInformationListByCompany(user.companyId);
    setTaxInfo(taxResult);
    setFilteredTax(taxResult);
  };

  const getTax = async () => {
    const result = await userUseCases.getTaxData();
    setTaxData(result);
  };

  const handleSelect = async (id) => {
    setOpen(true);
    const selectedInfo = await taxInformationUseCases.getById(id);
    if (selectedInfo) {
      selectedInfo.datetime = selectedInfo.ResponseDate;
      setSelectedTaxInfo(selectedInfo);
    }
  };
  // translation status text values in table
  const statusTranslations = {
    Inbox: "Em análise",
    Answered: "Respondida",
    Finished: "Finalizada",
    // status iFS
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    getTaxInfo();
    getRemainingTaxInformation();
    getTax();
  }, []);

  const handleSearch = (searchValue) => {
    if (searchValue === null || searchValue === undefined || searchValue.trim() === "") {
      // Mostrar todos os usuários
      setFilteredTax(taxInfo);
    } else {
      // Filtrar usuários
      const filteredIfs = taxInfo.filter((u) =>
        u?.subject.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredTax(filteredIfs);
    }
  };
  return (
    <>
      <Grid container spacing={2} sx={{ paddingBottom: 3 }}>
        <Grid sx={styles.item} item xs={12} sm={4}>
          <Paper elevation={2} sx={styles.ifs}>
            <Text>Nº DE IFS TOTAL</Text>
            <Box sx={styles.border}></Box>
            <Text>{taxData?.total}</Text>
          </Paper>
        </Grid>
        <Grid sx={styles.item} item xs={12} sm={4}>
          <Paper elevation={2} sx={styles.ifs}>
            <Text> Nº DE IF’s USADAS</Text>
            <Box sx={styles.border}></Box>
            <Text>{taxData?.total - taxData?.balance}</Text>
          </Paper>
        </Grid>
        <Grid sx={styles.item} item xs={12} sm={4}>
          <Paper sx={styles.ifs}>
            <Text> Nº DE IF’s DISPONÍVEIS</Text>
            <Box sx={styles.border}></Box>
            {taxData?.balance}
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          border: "2px solid #EFF2F4",
          backgroundColor: "#EFF2F4",
          borderRadius: 2,
          paddingTop: 4,
          paddingBottom: 3,
          paddingInline: 4,
        }}
        alignItems="center"
      >
        <Grid xs={12} md={6} sx={{ pr: 6 }}>
          <Box sx={styles.containerInfo}>
            <Box>
              <Text sx={styles.title}>Nova</Text>
              <Text sx={styles.headerText}>IF</Text>
            </Box>
            <Text sx={styles.subtitle}>
              Para solicitar nova Informação <br /> Fiscal (IF) utilize o formulário ao lado.
            </Text>
          </Box>
          <Divider
            orientation="horizontal"
            variant="fullWidth"
            sx={{ backgroundColor: "#EFF2F4", my: 2 }}
          />
          <Box sx={styles.containerInfo}>
            <Avatar
              alt="3 dias úteis"
              src="/assets/3-dias-uteis-clock.png"
              sx={{ width: 100, height: 100 }}
            />
            <Text sx={styles.text}>
              Após o envio da mensagem você receberá a resposta em até 3 (três) dias úteis.
            </Text>
          </Box>
        </Grid>
        <Grid xs={12} md={6}>
          <NewTIForm
            taxInformationUseCases={taxInformationUseCases}
            getRemainingTaxInformation={getRemainingTaxInformation}
          />
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", gap: 2, mt: 6, mb: 1, alignItems: "center", rowGap: 0 }}>
        <Text variant="h3" sx={styles.title}>
          Controle de IFs
        </Text>
      </Box>
      <TextField
        sx={styles.searchField}
        label="Buscar por nome"
        onChange={(e) => handleSearch(e.target.value)}
      />
      <Paper elevation={2}>
        <Table>
          <TableHead sx={styles.TableHead}>
            <TableRow sx={styles.TableRow}>
              <TableCell sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]} align="center">
                Data de Envio
              </TableCell>
              <TableCell sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]} align="center">
                Assunto
              </TableCell>
              <TableCell sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]} align="center">
                Autor
              </TableCell>
              <TableCell sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]} align="center">
                E-mail
              </TableCell>
              <TableCell sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]} align="center">
                Status
              </TableCell>
              <TableCell sx={[styles.TableCell, { fontWeight: 600, fontSize: 18 }]} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTax
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((info, index) => (
                <TableRow key={index} sx={styles.TableRow}>
                  <TableCell sx={styles.TableCell}>
                    {info?.publishDate
                      ? new Date(info.publishDate).toLocaleDateString("pt-BR")
                      : ""}
                  </TableCell>
                  <TableCell sx={styles.TableCell}>{info?.subject}</TableCell>
                  <TableCell sx={styles.TableCell}>{info?.authorName}</TableCell>
                  <TableCell sx={styles.TableCell}>{info?.email}</TableCell>
                  <TableCell sx={styles.TableCell}>
                    {info ? statusTranslations[info.statusText] || info.statusText : ""}
                  </TableCell>
                  <TableCell sx={styles.TableCell} align="center">
                    <Button sx={styles.Button} name="details" onClick={() => handleSelect(info.id)}>
                      Ver detalhes
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
      <Stack spacing={2}>
        <TablePagination
          component="div"
          count={filteredTax?.length || 0}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
          labelRowsPerPage="IFs por página:"
          rowsPerPageOptions={[10, 15, 20]}
          sx={{
            ".MuiTablePagination-selectLabel ": {
              margin: 0,
            },
            ".MuiTablePagination-displayedRows": {
              margin: 0,
            },
          }}
        />
      </Stack>
      <Modal open={open} onClose={handleClose}>
        <Box sx={styles.Modal}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={11}>
              <Text variant="h3" sx={styles.title}>
                Detalhes da IF
              </Text>
            </Grid>
            <Grid
              item
              xs={6}
              sm={1}
              sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
            >
              <Close onClick={handleClose} />
            </Grid>
            {selectedTaxInfo && (
              <>
                <Grid item xs={12} sm={2}>
                  <Text sx={styles.titleModal}>Data:</Text>
                  <Box sx={styles.containerFields}>
                    <Text>{new Date(selectedTaxInfo.publishDate).toLocaleDateString("pt-BR")}</Text>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Text sx={styles.titleModal}>Status:</Text>
                  <Box sx={styles.containerFields}>
                    <Text>
                      {selectedTaxInfo
                        ? statusTranslations[selectedTaxInfo.statusText] ||
                          selectedTaxInfo.statusText
                        : ""}
                    </Text>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Text sx={styles.titleModal}>Autor:</Text>
                  <Box sx={styles.containerFields}>
                    <Text>{selectedTaxInfo.authorName}</Text>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Text sx={styles.titleModal}>e-mail:</Text>
                  <Box sx={styles.containerFields}>
                    <Text>{selectedTaxInfo.email}</Text>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Text sx={styles.titleModal}>Assunto:</Text>
                  <Box sx={styles.containerFields}>
                    <Text>{selectedTaxInfo.subject}</Text>
                  </Box>
                </Grid>
                {/* Renderiza a lista de arquivos, se houver */}
                {selectedTaxInfo.files && selectedTaxInfo.files.length > 0 && (
                  <Grid item xs={12} sm={4}>
                    <Text sx={styles.titleModal}>Arquivos:</Text>
                    <Box sx={styles.containerFields}>
                      {selectedTaxInfo.files.map((file, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Button
                            variant="contained"
                            startIcon={<Download />}
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {file.filename}
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Text sx={styles.titleModal}>Mensagem:</Text>
                  <Box sx={styles.containerFields}>
                    <div dangerouslySetInnerHTML={{ __html: selectedTaxInfo.message }}></div>
                  </Box>
                </Grid>
                {/* Renderiza as respostas da IF, se houver */}
                {selectedTaxInfo.responses && selectedTaxInfo.responses.length > 0 && (
                  <Grid container spacing={2} sx={{ mt: 1, p: 2 }}>
                    <Grid item xs={12}>
                      <Text variant="h3" sx={styles.title}>
                        Resposta da IF
                      </Text>
                    </Grid>
                    {selectedTaxInfo.responses.map((response, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12} sm={4}>
                          <Text sx={styles.titleModal}>Data da Resposta:</Text>
                          <Box sx={styles.containerFields}>
                            <Text>
                              {new Date(response.responseDate).toLocaleDateString("pt-BR")}
                            </Text>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Text sx={styles.titleModal}>Respondido por:</Text>
                          <Box sx={styles.containerFields}>
                            <Text>{response.authorName}</Text>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Text sx={styles.titleModal}>e-mail:</Text>
                          <Box sx={styles.containerFields}>
                            <Text>{response.email}</Text>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Text sx={styles.titleModal}>Mensagem:</Text>
                          <Box sx={styles.containerFields}>
                            <div dangerouslySetInnerHTML={{ __html: response.message }}></div>
                          </Box>
                        </Grid>
                        {/* Renderiza os arquivos da resposta, se houver */}
                        {response.files && response.files.length > 0 && (
                          <Grid item xs={12}>
                            <Text sx={styles.titleModal}>Arquivos da Resposta:</Text>
                            {response.files.map((file, fileIndex) => (
                              <Box key={fileIndex} sx={styles.containerFields}>
                                <Text sx={styles.titleModal}>{file.filename}</Text>
                                <Button
                                  variant="contained"
                                  startIcon={<Download />}
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Download da IF
                                </Button>
                              </Box>
                            ))}
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

const styles = {
  box: {
    border: "2px solid #EFF2F4",
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  containerInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 6,
    width: "100%",
  },
  ifs: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#EFF2F4",
    padding: "20px 20px",
    borderRadius: 2,
  },
  border: {
    borderLeft: "2px solid #9EA5AA",
    height: 20,
  },
  text: {
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    color: "#242424",
    fontFamily: "Montserrat, sans-serif",
    mt: 0,
    mb: 0,
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
  headerText: {
    fontSize: 70,
    fontWeight: 800,
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
    lineHeight: 1,
  },

  //Table styles
  TableHead: {
    justifyContent: "flex-start",
    fontWeight: 500,
    backgroundColor: "#EFF2F4",
  },
  TableCell: {
    fontSize: "0.9rem",
    textAlign: "left",
    backgroundColor: "#EFF2F4",
    color: "#021148",
    fontFamily: "Montserrat",
    "&:hover": {
      color: "#0e4292",
    },
  },
  TableRow: {
    backgroundColor: "#EFF2F4",
    "&:hover": {
      backgroundColor: "#ccc",
    },
    height: "70px",
  },
  Button: {
    background: "inherit",
    paddingInline: 4,
    paddingBlock: 1,
    color: "#1976d2",
  },
  Actions: {
    "&:hover": {
      color: "#0e4292",
    },
  },
  //modal details
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "80%",
    width: "100%",
    maxHeight: "80%",
    border: "solid 2px #EFF2F4",
    borderRadius: "5px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 5,
    overflow: "auto",
  },
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  titleModal: {
    fontSize: 18,
    fontWeight: 500,
    width: "60%",
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
  searchField: {
    backgroundColor: "#EFF2F4",
    width: "100%",
    margin: 0,
    border: "none",
  },
};
