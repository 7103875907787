import React, { useContext } from "react";
import CreatableSelect from "react-select/creatable";
import { customStyles } from "~/presentation/components/Select/styles";
import { ThemeContext } from "styled-components";

const CreatableMultiSelectField = ({
  options,
  field,
  placeholder,
  form,
  onChange,
  padding,
  isMulti = false,
  value,
  ...rest
}) => {
  const appTheme = useContext(ThemeContext);

  const handleChange = (selectedOption) => {
    if (selectedOption) {
      form.setFieldValue(field.name, selectedOption.value);
      if (onChange) {
        onChange(selectedOption);
      }
    }
  };

  return (
    <CreatableSelect
      isMulti
      placeholder={placeholder}
      options={options}
      onChange={(option) => {
        if (onChange) {
          onChange(option);
        } else {
          handleChange(option);
        }
      }}
      defaultValue={value}
      onBlur={field.onBlur}
      formatCreateLabel={(inputValue) => `Criar "${inputValue}"`}
      appTheme={appTheme}
      styles={customStyles(appTheme, { padding })}
      noOptionsMessage={() => "Não há opções"}
      theme={(theme) => ({
        ...theme,
        colors: {
          primary50: "#d7d7d7",
          primary25: "#dedede",
          primary: "#1e88e5",
        },
      })}
      {...rest}
    />
  );
};

export default CreatableMultiSelectField;
