import styled from "styled-components";
import Alert from "@mui/material/Alert";

//estilizar cores
export const AlertComponent = styled(Alert)`
  &.MuiAlert-root {
    border-radius: 40px;
  }

  &.MuiAlert-filledSuccess {
    background-color: darken(0.01, ${(props) => "#26c6da"});
  }

  &.MuiAlert-filledInfo {
    background-color: darken(0.01, ${(props) => "#1e88e5"});
  }

  &.MuiAlert-filledWarning {
    background-color: darken(0.01, ${(props) => "#ffb22b"});
  }

  &.MuiAlert-filledError {
    background-color: darken(0.01, ${(props) => "#fc4b6c"});
  }
`;
