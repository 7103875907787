import styled from "styled-components";

export const ImageInputWrapper = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 56px; /*${({ image }) => (image ? "unset" : "100%")};*/
  max-height: 330px;
  background: ${(props) => "#eee"};
  overflow: hidden;

  > input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  > div {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    border: solid 1px ${(props) => "#383f48"};
    opacity: ${({ image }) => (image ? "0.2" : "1")};
    transition: all 0.2s ease-in-out;
    font-size: 16px;
  }

  > img {
    width: 100%;
  }

  &:hover {
    > div {
      opacity: 1;
    }
  }
`;
