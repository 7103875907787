import React, { useState } from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import {
  Container,
  Form,
  Checkbox,
} from "~/presentation/views/Admin/Category/CreateEdit/Form/styles";
// schema
import validationSchema from "~/presentation/views/Admin/Category/CreateEdit/Form/validationSchema";
import ImageInput from "~/presentation/components/ImageInput";
import { uploadFile } from "react-s3";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { useContext } from "react";
import randomString from "~/presentation/utils/randomString";
import { FormControlLabel } from "@mui/material";

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION_BUCKET,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const CategoryForm = ({ handleSubmit, initialValues }) => {
  const uploadedImage = React.useRef(null);

  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const [selectedFile, setSelectedFile] = useState();

  const handleUpload = async () => {
    try {
      const upload = await uploadFile(selectedFile, config);
      return upload;
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao fazer upload.",
        type: "error",
      });
      return error;
    }
  };

  const handleImageUpload = (event) => {
    const [file] = event.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      const renamedFile = new File([file], `${randomString()}-${file.name}`);
      setSelectedFile(renamedFile);
    }
  };

  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          const submitValues = {
            ...values,
            imageUrl: null,
          };
          if (selectedFile !== null && selectedFile !== undefined) {
            const upload = await handleUpload();
            if (upload.result.ok) {
              submitValues.imageUrl = upload.location;
            }
          }
          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <h2>Categoria</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <Field name="title" type="text" label="Título" as={TextInput} />
                <ErrorMessage name="title" />
              </div>
              <div className="col-1_5">
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isSerie}
                        onChange={() => {
                          if (values.isSerie) {
                            setFieldValue("image", "");
                            setFieldValue("description", "");
                          }
                          setFieldValue("isSerie", !values.isSerie);
                        }}
                        name="isSerie"
                      />
                    }
                    label="Marcar Categoria como Série"
                  />
                </div>
              </div>
              {values.isSerie && (
                <>
                  <div className="col-1_5">
                    <Field name="description" type="text" label="Descrição *" as={TextInput} />
                    <ErrorMessage name="description" />
                  </div>
                  <div className="col-1_3">
                    <h2 className="mb_12">Imagem da Publicação *</h2>
                    <ImageInput
                      title="Selecionar imagem"
                      imageFile={values.imageUrl}
                      onChange={(event) => {
                        handleImageUpload(event);
                        setFieldValue("image", event.target.files[0]);
                      }}
                      uploadedImageRef={uploadedImage}
                    />
                    <ErrorMessage name="image" />
                  </div>
                </>
              )}
            </div>

            <PrimaryButton className="submit-btn" type="submit">
              Publicar
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CategoryForm;
