import React, { useState } from "react";
import { TableNfse } from "../Table";
import { useHistory } from "react-router";
import { HeaderSectionPage } from "../../components/HeaderSectionPage";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { ModalImport } from "../../components/ModalImport/ModalImport";
import ModalContainer from "../../components/ModalContainer/ModalContainer";

export const ViewHomeNf = () => {
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setRefreshTable((prev) => !prev);
  };

  return (
    <>
      <HeaderSectionPage
        title="Painel de Documentos Fiscais"
        pageReturn="/reinf"
        buttonPrimary={
          <RButton
            variant="contained"
            component="span"
            startIcon={<FolderCopyOutlinedIcon />}
            onClick={handleOpen}
            sx={{
              textTransform: "none",
              borderRadius: "20px",
              height: "40px",
              fontFamily: "Montserrat",
            }}
          >
            Importar arquivos
          </RButton>
        }
        buttonSecond={
          <RButton
            variant="contained"
            onClick={() => history.push("/reinf/criar-doc-fiscal")}
            sx={{
              textTransform: "none",
              borderRadius: "20px",
              height: "40px",
              fontFamily: "Montserrat",
            }}
          >
            Adicionar Doc. Manualmente
          </RButton>
        }
      />
      <ModalContainer scroll={true} open={modalOpen} onClose={handleClose}>
        <ModalImport handleClose={handleClose} />
      </ModalContainer>
      <TableNfse importRefresh={refreshTable} />
    </>
  );
};
