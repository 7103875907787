import React, { useState, useContext, useEffect } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import AppContext from "~/presentation/AppContext";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog } from "@mui/material";
import { useTokenCheck } from "~/presentation/hooks/useTokenCheck";
import { DialogContext } from "../providers/DialogProvider";
import LoginBox from "../views/LandingPage/Home/LoginBox";

const PrivateRoute = ({ component: Component, roles, redirectTo, ...rest }) => {
  const [open, setOpen] = useState(false);
  const { setUser, isLogged } = useContext(AppContext);
  const { currentUser, isTokenValid } = useTokenCheck();
  const { openDialogBox } = useContext(DialogContext);

  const handleStorageChange = (event) => {
    if (event.key === "GT_token" || (event.type === "storage" && event.newValue === null)) {
      console.log("StorageChange");
      //window.location.reload(); // Reload the app to update the PrivateRoute component
    }
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const localStorageUser = localStorage.getItem("GT_user");
    setUser({ ...currentUser, ...JSON.parse(localStorageUser) });
  }, []);

  const handleButtonClick = () => {
    window.open("https://gestaotributaria.com.br", "_blank");
  };

  const renderLoginBox = () => (
    <LoginBox
      handleClose={() => openDialogBox({ ...openDialogBox, open: false })}
      history={history}
      isReLogin
    />
  );
  const openLoginDialog = () => {
    openDialogBox({
      disableScrollLock: true,
      customComponent: () => renderLoginBox(),
      blockScreen: true,
    });
  };

  const isUserLogged = isLogged();

  //se existe usuário e token (isUserLogged) e o token é inválido mostra o Re-login
  if (isUserLogged && currentUser && !isTokenValid) {
    openLoginDialog();
  }

  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          //se não houver usuário, o usuário irá ser considerado "não logado" e vai para "/"
          if (!currentUser) {
            return <Redirect to={{ pathname: "/" }} />;
          }

          if (
            props.location.pathname.includes("conteudo-selecionado") &&
            !["user", "admin", "manager", "superAdmin"].includes(currentUser.role)
          ) {
            setOpen(true);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            return <Redirect to={{ pathname: "/cliente" }} />;
          }

          if (roles && roles.indexOf(currentUser.role) === -1) {
            return <Redirect to={{ pathname: "/cliente" }} />;
          }

          return <Component {...props} />;
        }}
      />
      <div>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <div style={{ position: "relative" }}>
            <CloseIcon
              onClick={() => setOpen(false)}
              style={{
                position: "absolute",
                top: 8,
                left: 10,
                color: "#ccc",
                cursor: "pointer",
              }}
            />
            <img
              src="/assets/PopUp-fixing.png"
              alt="lock"
              style={{ width: "100%", height: "100%" }}
            />
            <Button
              style={{
                background: "linear-gradient(#efd467, #bd9800)",
                position: "absolute",
                top: "78%",
                left: "20%",
                transform: "translate(-50%, -50%)",
                color: "black",
                width: "30%",
                height: "10%",
              }}
              onClick={handleButtonClick}
            >
              Clique aqui
            </Button>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default PrivateRoute;
