import styled from "styled-components";

export const CardsWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: auto;
  grid-row-gap: 40px;

  @media screen and (min-width: 768px) {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
