import React from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import brLocale from "date-fns/locale/pt-BR";

const styles = {
  width: "100%",
  "& .MuiFormLabel-root, & .MuiInputBase-root, & .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root":
    {
      color: "#383f48",
      borderColor: "#383f48",
    },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    color: "#383f48",
    borderColor: "#383f48",
  },

  "& .MuiIconButton-root": {
    color: "#383f48",
  },
};

const DateInput = ({
  variant = "inline",
  label = "Data",
  format = "dd/MM/yyyy",
  value,
  onChange,
  maxDate,
  getHours,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={brLocale}>
      {getHours ? (
        <DateTimePicker
          sx={styles}
          variant={variant}
          label={label}
          value={value}
          onChange={onChange}
          disableToolbar={true}
          disableFuture
          timezone="America/Sao_Paulo"
          minDate={new Date("2014-01-01")}
        />
      ) : (
        <DatePicker
          sx={styles}
          renderInput={(params) => <TextField {...params} />}
          variant={variant}
          label={label}
          format={format}
          value={value}
          onChange={onChange}
          disableToolbar={true}
          maxDate={maxDate || null}
          minDate={new Date("2014-01-01")}
        />
      )}
    </LocalizationProvider>
  );
};

export default DateInput;
