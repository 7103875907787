import React, { useContext, useState, useEffect } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { ApiDigitalCertUseCases } from "~/data/usecases/DigitalCert/apiDigitalCert";
import { HeaderSectionPage } from "~/presentation/views/Reinf/components/HeaderSectionPage";
import { AddNewCertificate } from "~/presentation/views/Reinf/DigitalCertificate/New/index";
import {
  Box,
  Typography as Text,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Stack,
  Tooltip,
  Switch,
} from "@mui/material";
import { EditCertificate } from "./Edit/EditCertificate";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { EditNote } from "@mui/icons-material";
import ModalContainer from "../components/ModalContainer/ModalContainer";

export const ViewDigitalCertificate = () => {
  const apiDigitalCert = new ApiDigitalCertUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);

  //modal create
  const [open, setOpen] = useState(false);
  //modal edit
  const [openEdit, setOpenEdit] = useState(false);

  //todos os certificados
  const [certInfo, setCertInfo] = useState([]);

  //certificado clicado para modal
  const [editCertInfo, setEditCertInfo] = useState(null);

  //tabela
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const fetchCertInfo = async () => {
    try {
      openBackdrop(true, "Carregando dados");
      const certResult = await apiDigitalCert.getDigitalCerts();
      setCertInfo(certResult);
      if (certResult.length === 1) {
        //se um certificado no array, então esse é marcado isDefault
        const singleCert = certResult[0];
        if (!singleCert.isDefault) {
          singleCert.isDefault = true;
          await apiDigitalCert.updateDigitalCert(singleCert);
          setFeedbackSnackbar({
            isOpen: true,
            message: "Certificado único configurado como padrão",
            type: "success",
          });
        }
      }
    } catch (error) {
      console.error("Erro ao buscar certificados:", error);
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    fetchCertInfo();
  }, []);

  const handleOpen = () => setOpen(true);

  const handleOpenEdit = (cert) => () => {
    setEditCertInfo(cert);
    setOpenEdit(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleChangeSwitch = async (certId, isDefault) => {
    const updatedCert = certInfo.find((cert) => cert.id === certId);
    if (!updatedCert) {
      console.error("Certificado não encontrado!");
      return;
    }

    const updatedCertInfo = certInfo.map((cert) =>
      cert.id === certId ? { ...cert, isDefault: isDefault } : cert
    );
    setCertInfo(updatedCertInfo);
    updatedCert.isDefault = isDefault;

    try {
      await apiDigitalCert.updateDigitalCert(updatedCert);

      setFeedbackSnackbar({
        isOpen: true,
        message: isDefault ? "Certificado Padrão configurado" : "Certificado Padrão retirado",
        type: "success",
      });

      await fetchCertInfo();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao atualizar certificado: " + error.response.data,
        type: "error",
      });
    }
  };

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const currentData = certInfo?.slice(startIndex, endIndex);

  return (
    <>
      <HeaderSectionPage
        title="Certificados digitais"
        iconLeft
        buttonPrimary={<RButton onClick={handleOpen}>Cadastrar novo</RButton>}
      />
      <ModalContainer open={open} onClose={handleClose}>
        <AddNewCertificate handleClose={handleClose} refreshData={fetchCertInfo} />
      </ModalContainer>
      <ModalContainer open={openEdit} onClose={handleCloseEdit}>
        <EditCertificate
          data={editCertInfo}
          handleClose={handleCloseEdit}
          refreshData={fetchCertInfo}
          setCertInfo={setCertInfo}
        />
      </ModalContainer>
      <Box sx={{ borderRadius: 3, overflow: "hidden", marginBlock: 5, marginInline: 2 }}>
        <Table>
          <TableHead>
            <TableRow sx={styles.HeadTableRow}>
              <TableCell sx={styles.TableHeader} align="center">
                Nome
              </TableCell>
              <TableCell sx={styles.TableHeader} align="center">
                Proprietário
              </TableCell>
              <TableCell sx={styles.TableHeader} align="center">
                Data de validade
              </TableCell>
              <TableCell sx={styles.TableHeader} align="center">
                Certificado Padrão
              </TableCell>
              <TableCell sx={styles.TableHeader} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((cert, index) => (
                <TableRow key={index}>
                  <TableCell sx={styles.TableCell}>{cert?.name}</TableCell>
                  <TableCell sx={styles.TableCell}>{cert?.ownerName}</TableCell>
                  <TableCell sx={styles.TableCell}>
                    {cert?.expirationDate
                      ? new Date(cert.expirationDate).toLocaleDateString("pt-BR")
                      : ""}
                  </TableCell>
                  <TableCell sx={[styles.TableCell, { width: 200 }]}>
                    <Tooltip title="Defina como um certificado principal para as ações do sistema.">
                      <Switch
                        disabled={certInfo?.length === 1 || cert?.isDefault}
                        checked={cert?.isDefault}
                        onChange={(e) => handleChangeSwitch(cert.id, e.target.checked)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={styles.TableCell} align="center">
                    <EditNote
                      sx={{
                        fontSize: "3rem",
                        cursor: "pointer",
                        ":hover": {
                          color: "#1341a1",
                        },
                      }}
                      onClick={handleOpenEdit(cert)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        {certInfo?.length > 9 && (
          <Stack
            spacing={1}
            sx={{ background: "#F2F2F2", paddingRight: 8, borderRadius: "0px 0px 8px 8px" }}
          >
            <TablePagination
              component="div"
              count={certInfo?.length || 0}
              page={page}
              onPageChange={(event, newPage) => setPage(newPage)}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
              labelRowsPerPage="Certificados por página:"
              rowsPerPageOptions={[10, 15, 20]}
              sx={{
                ".MuiTablePagination-selectLabel ": {
                  margin: 0,
                },
                ".MuiTablePagination-displayedRows": {
                  margin: 0,
                },
              }}
            />
          </Stack>
        )}
      </Box>
    </>
  );
};

const styles = {
  box: {
    border: "2px solid #EFF2F4",
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },

  border: {
    borderLeft: "2px solid #9EA5AA",
    height: 20,
  },
  text: {
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
    width: "60%",
    paddingLeft: 5,
  },
  title: {
    fontSize: 30,
    fontWeight: 500,
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    width: "60%",
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
    paddingLeft: 5,
  },
  headerText: {
    fontSize: 70,
    fontWeight: 800,
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },

  //Table styles
  HeadTableRow: {
    backgroundColor: "#1341A1",
  },
  TableHeader: {
    backgroundColor: "#1341A1",
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#ffffff",
    fontSize: 13,
    padding: "10px",
    fontWeight: 400,
    position: "relative",
    "&:not(:first-child)::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "10%",
      bottom: "10%",
      left: 0,
      width: "3px",
      backgroundColor: "#142F68",
    },
  },
  TableCell: {
    fontFamily: "Montserrat ",
    fontSize: 14,
    padding: "6px",
    color: "#000000",
    textAlign: "center",
    marginBlock: 2,
    backgroundColor: "#D9D9D9",
    borderBottom: "2px solid #fff",
  },
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  titleModal: {
    fontSize: 18,
    fontWeight: 500,
    width: "60%",
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
};
