import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import SearchField from "~/presentation/components/SearchField";
import PageHeader from "~/presentation/views/Admin/components/PageHeader";

//functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";

const columns = [
  { name: "number", label: "Número" },
  { name: "text", label: "Texto" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const DialogTable = ({ history, gtFacildialogUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();
  const [dialogs, setDialogs] = useState();

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const apiDialogs = await gtFacildialogUseCases.getDialogs();

    apiDialogs.forEach((dialog) => {
      dialog.actions = (
        <TableActions
          handleDelete={() => deleteDialog(dialog.id)}
          handleEdit={() => handleEdit(dialog.id)}
        />
      );
    });

    setDialogs(apiDialogs);
    openBackdrop(false);
  };

  const handleEdit = (id) => {
    history.push(`/admin/gt-facil/dialogo/editar/${id}`);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo Diálogo");
    try {
      await gtFacildialogUseCases.delete(id);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir diálogo: Missing endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este diálogo?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  // const cleanSearch = () => {
  //   triggerInputChange(hiddenInputRef.current);
  //   triggerInputChange(searchFieldRef.current);
  // };

  return (
    <>
      <PageHeader>
        <h1>Diálogos</h1>
        <SearchField parentRef={searchFieldRef} handleSearch={(value) => handleSearch(value)} />
      </PageHeader>
      <Table searchRef={hiddenInputRef} columns={columns} data={dialogs} />
    </>
  );
};

export default DialogTable;
