import * as Yup from "yup";

export default Yup.object({
  categoryId: Yup.number().required("Campo obrigatório!"),
  title: Yup.string().required("Campo Obrigatório!"),
  autor: Yup.string().required("Campo Obrigatório!"),
  publishDate: Yup.mixed().when("type", (type, schema) =>
    type === "4"
      ? schema
      : schema.typeError("Selecione uma data!").required("Campo Obrigatório!"),
  ),
  image: Yup.mixed().required("Campo Obrigatório!"),
  // videoUrl: Yup.string().required("Campo Obrigatório!"),
  text: Yup.string().required("Campo Obrigatório!"),
});
