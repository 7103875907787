import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import { getDocType } from "~/presentation/views/Reinf/Utils/utilsReinf";

function ActiveFilters({
  orderBy,
  documentSituation,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  formatDateToBrazilian,
  setOrderBy,
  setDocumentSituation,
  documentType,
  setDocumentType,
}) {
  const date = initialDate && finalDate;
  return (
    <Box sx={styles.container}>
      {orderBy && (
        <Box sx={styles.FilterContainer}>
          <Box>
            <em style={{ marginRight: 3, fontWeight: 700 }}>Filtrar por:</em> Data de{" "}
            {orderBy === "dateOfIssue" ? "Emissão" : "Pagamento"}
          </Box>
          <Close
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setOrderBy("");
            }}
          />
        </Box>
      )}
      {date && (
        <Box sx={styles.FilterContainer}>
          <Box>
            <em style={{ marginRight: 3, fontWeight: 700 }}>Período:</em>{" "}
            {`${formatDateToBrazilian(initialDate)} - ${formatDateToBrazilian(finalDate)}`}
          </Box>
          <Close
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setInitialDate(null);
              setFinalDate(null);
            }}
          />
        </Box>
      )}
      {documentSituation && (
        <Box sx={styles.FilterContainer}>
          <Box>
            <em style={{ marginRight: 3, fontWeight: 700 }}>Situação do doc:</em>
            {documentSituation === "isR2010"
              ? "R-2010"
              : documentSituation === "isR4020"
                ? "R-4020"
                : "Não enviado."}
          </Box>
          <Close
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setDocumentSituation("");
            }}
          />
        </Box>
      )}
      {[0, 1, 2, 3, 4, 99].includes(documentType) && (
        <Box sx={styles.FilterContainer}>
          <Box>
            <em style={{ marginRight: 3, fontWeight: 700 }}>Tipo de doc:</em>
            {getDocType(documentType)}
          </Box>
          <Close
            fontSize="small"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setDocumentType("");
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default ActiveFilters;

const styles = {
  container: {
    display: "grid",
    gap: 1,
    "@media (min-width: 780px)": {
      gridTemplateColumns: "275px 275px",
    },
    "@media (min-width: 1140px)": {
      display: "flex",
    },
  },
  FilterContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#E6E6E6",
    padding: "2px 8px",
    borderRadius: 20,
    justifyContent: "space-between",
    gap: 3,
    fontSize: 12,
    color: "black",
    maxWidth: "275px",
    "@media (min-width: 1140px)": {
      width: "fit-content",
    },
  },
};
