import React from "react";
import { Box, Modal } from "@mui/material";

const ModalContainer = ({ open, onClose, children, scroll, sx, ...props }) => {
  //se onClose === null é impossivel sair do modal clicando no background
  const modalStyles = scroll ? { ...styles.modal, ...styles.scroll } : { ...styles.modal };
  return (
    <Modal open={open} onClose={onClose} {...props}>
      <Box sx={{ ...modalStyles, ...sx }}>{children}</Box>
    </Modal>
  );
};

export default ModalContainer;

const styles = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "800px",
    width: "90%",
    maxHeight: "650px",
    borderRadius: "8px 4px 4px 8px",
    bgcolor: "#E8E8E8",
    boxShadow: 24,
  },
  scroll: {
    scrollBehavior: "smooth",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "14px",
      background: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      background: "#E1E1E1",
      borderRadius: "0 50px 50px 0",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#999999",
      cursor: "pointer",
      borderRadius: "3px",
      minHeight: "100px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#666666",
    },
  },
};
