import React, { useEffect, useState, useContext } from "react";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PostContentStyles } from "~/presentation/views/Blog/Post/Sections/PostContent/PostContent.styles";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
// import EmbedVideo from "~/presentation/components/EmbedVideo/index";
import MostAccessedSection from "~/presentation/views/Blog/Post/Sections/MostAccessedSection";
import CommentsSection from "../../Sections/Comments";
import { makeBlogUseCase as blogUseCases } from "~/main/factories/use-cases/blog";
import { makeBlogCategoryUseCases as blogCategoryUseCases } from "~/main/factories/use-cases/blog-category";
import AppContext from "~/presentation/AppContext";
import { Box, Grid, Typography as Text, Divider, Container } from "@mui/material";

const PostContent = ({ post, handleComment, postComments }) => {
  const history = useHistory();
  const DATE = new Date(post.publishDate);
  const formattedDate = format(DATE, "dd/MM/yyyy");
  const [mostAccessedPosts, setMostAccessedPosts] = useState([]);
  const [search, setSeatch] = useState();
  const TELEGRAM_IMG = "/assets/canalTelegram.jpg";
  const { isLogged } = useContext(AppContext);

  const getAutorOfPost = () => {
    const { autor } = post;
    if (autor) {
      return `${" "}Por: ${autor}${" "}`;
    }
  };

  const getMostAccessedPosts = async () => {
    const { posts } = await blogUseCases().getMostAccessedPosts(4);
    setMostAccessedPosts(posts);
  };

  const handleNavigateToPost = (id, openModal) => {
    if (isLogged()) {
      history.push("/blog/post/" + id);
      return;
    }

    // openModal(ShouldAndSubscribeModals);
  };

  const getFilters = async () => {
    return await blogCategoryUseCases().getBlogSeries();
  };

  useEffect(() => {
    getMostAccessedPosts();
  }, []);

  const decodeHtmlEntities = (text) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return textarea.value;
  };

  const capitalizeFirstLetter = (text) => {
    if (!text) return text;
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  let cleanedText = decodeHtmlEntities(post.text);
  cleanedText = cleanedText.trim();
  cleanedText = capitalizeFirstLetter(cleanedText);

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} md={9}>
            <Text sx={styles.title}>{post?.title}</Text>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: { md: 2, xs: 0 },
                alignItems: { md: "center", xs: "flex-start" },
              }}
            >
              <Text sx={styles.subtitle}>{formattedDate && `Publicado em: ${formattedDate}`}</Text>
              <Text sx={styles.authorName}>{getAutorOfPost()}</Text>
            </Box>

            <Divider sx={{ width: "40%", marginBlock: 1 }}></Divider>
            {post?.videoUrl && (
              <Box style={{ width: "100%", paddingBlock: 20, height: 583, maxWidth: 970 }}>
                <iframe
                  src={post?.videoUrl}
                  title={post?.title}
                  frameBorder="0"
                  width="100%"
                  height="100%"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                  allowFullScreen
                />
              </Box>
            )}
            {!post?.videoUrl && post?.image && (
              <PostContentStyles.ImageContainer src={post?.image} alt="post-image" />
            )}
            <Text sx={styles.contentText}>
              <span dangerouslySetInnerHTML={{ __html: cleanedText }}></span>
            </Text>
            <Text sx={[styles.title, { mt: 6 }]}>Deixe seu comentário</Text>
            <CommentsSection handleComment={handleComment} comments={postComments} />
          </Grid>
          <Grid item xs={12} md={3}>
            <MostAccessedSection
              mostAccessedPosts={mostAccessedPosts.slice(0, 3)}
              goToPost={(id) => handleNavigateToPost(id, undefined)}
              defaultImage={""}
            />
            <Box
              component="a"
              href="https://t.me/+j00d6m6nZ1tkMGYx"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: "block",
                cursor: "pointer",
                margin: "0 auto 3rem auto",
              }}
            >
              <Box component="img" src={TELEGRAM_IMG} sx={styles.imgTelegram} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

const styles = {
  title: {
    color: "#3A3A3A",
    fontSize: 28,
    fontFamily: "Montserrat",
    fontWeight: 600,
    paddingBlock: 2,
  },
  subtitle: {
    color: "#000",
    fontSize: 18,
    fontWeight: 300,
    fontFamily: "Montserrat",
    // marginTop: 2,
    // marginBottom: 1,
  },
  authorName: {
    color: "#000",
    fontSize: 18,
    fontWeight: 300,
    fontFamily: "Montserrat",
  },
  contentText: {
    fontFamily: "Montserrat",
    fontSize: 16,
    lineHeight: 1.6,
    textAlign: "justify",
    "&::first-letter": {
      textTransform: "uppercase",
    },
    // estilos da descrição HTML respeitando strongs do html
    "&& strong": {
      "font-weight": "bold",

      "& *": {
        "font-weight": "bold",
      },
    },
  },
  imgTelegram: {
      justifySelf: "center",
      borderRadius: "6px",
      objectFit: "cover",
      marginTop: 5,
      transition: "transform 0.3s ease-out, background-color 0.3s ease-out",
      "&:hover": {
        transform: "scale(1.01)",
      },
  },
};

export default PostContent;
