import styled from "styled-components";

const Container = styled.div``;

const TabsHeader = styled.div`
  display: flex;
  gap: 6px;
  flex-flow: column;

  @media (min-width: 600px) {
    gap: 4px;
    flex-flow: row;
    padding: 0 max(10px, 0.6vw);
  }
`;

const TabPanelWrapper = styled.div`
  padding: max(32px, 1.8vw) max(10px, 1.4vw) max(16px, 0.9vw);
  max-width: 100%;

  @media (min-width: 600px) {
    border-top: solid 1px #bcbcbc;
    height: 100%;
    overflow-y: auto;
  }
`;

const Tab = styled.div`
  cursor: ${({ isDisabled }) => (isDisabled ? "alias" : "pointer")};
  background: ${({ selected }) => (selected ? "#1341a1" : "transparent")};
  color: ${({ selected, isDisabled }) => {
    if (isDisabled) return "#bcbcbc";
    if (selected) return "#fafafa";
    else return "#000";
  }};
  font-size: max(22px, 1.4vw);
  font-family: "Roboto", sans-serif;
  padding: max(10px, 0.4vw) max(22px, 1.4vw);
  border: solid 1px #bcbcbc;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({ theme, selected, isDisabled }) => {
      if (isDisabled) return "transparent";
      if (selected) return "#1341a1";
      else return "#d7d7d7";
    }};
  }

  @media (min-width: 600px) {
    border: unset;
    border-radius: unset;
    border-top: solid 1px #bcbcbc;
    border-left: solid 1px #bcbcbc;
    border-right: solid 1px #bcbcbc;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const TabsStyles = {
  Container,
  TabsHeader,
  TabPanelWrapper,
  Tab,
};
