import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  makeGTFacilHome,
  makeGTFacilSearch,
  makeGTFacilAnswer,
} from "~/main/factories/pages/gt-facil";

const GTFacilRoutes = ({ match: { url } }) => {
  return (
    <Switch>
      <Route exact path={`${url}`} component={makeGTFacilHome} />
      <Route exact path={`${url}/pesquisa`} component={makeGTFacilSearch} />
      <Route exact path={`${url}/resposta`} component={makeGTFacilAnswer} />
    </Switch>
  );
};

export default GTFacilRoutes;
