import React from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import Select from "~/presentation/components/Select";
import { Container, Form } from "~/presentation/views/Admin/Service/CreateEdit/Form/styles";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
// schema
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import companyType from "~/presentation/utils/companyType";
import typeTax from "~/presentation/utils/typeTax";
import validationSchema from "./validationSchema";
import MultiCheckBoxField from "~/presentation/components/MultiCheckBox";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";

const AnswerForm = ({ handleSubmit, initialValues, cnaes, itemsAnswer, categories, terms }) => {
  const dataToSelectMapperDescription = (
    data,
    valueKey = "companyType",
    labelKey = "description"
  ) => {
    return data.map((category) => {
      category.value = category[valueKey];
      category.label = category[labelKey];
      return category;
    });
  };

  const dataToSelectMapperCnaes = (data, valueKey = "id", labelKey = "description") => {
    return data.map((category) => {
      category.value = category[valueKey];
      category.label = category[labelKey];
      return category;
    });
  };

  const dataToSelectMapperTerms = (data, valueKey = "id", labelKey = "term") => {
    return data?.map((term) => {
      term.value = term[valueKey];
      term.label = term[labelKey];
      return term;
    });
  };

  const mappedCnaes = dataToSelectMapperCnaes(cnaes);
  const mappedGtAnswerItems = dataToSelectMapper(itemsAnswer);

  const defaultCnaes = !!initialValues.cnaes && dataToSelectMapperCnaes(initialValues.cnaes);
  const defaultGtAnswerItems =
    !!initialValues.gtAnswerItems && dataToSelectMapper(initialValues.gtAnswerItems);
  const defaultCompanyType =
    !!initialValues.companyTypes && dataToSelectMapperDescription(initialValues.companyTypes);
  const defaultCategories =
    !!initialValues.categories && dataToSelectMapper(initialValues.categories);
  const defaultTerms =
    !!initialValues.searchTerms && dataToSelectMapperTerms(initialValues.searchTerms);
  const defaultIsSimple = JSON.stringify(initialValues.isSimple);

  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          const submitValues = {
            id: values.id,
            gtAnswerName: values.gtAnswerName,
            description: values.description,
            typeTax: values.typeTax,
            isSimple: values.isSimple === "true" ? true : false,
            companyTypes:
              values.companyTypes?.map((item) => {
                let companyType = {
                  companyType: item.value,
                };
                return companyType;
              }) || [],
            categories:
              values.categories?.map((category) => {
                let cat = {
                  id: category.id,
                  name: category.name,
                  letter: category.letter,
                };
                return cat;
              }) || null,
            cityId: values.cityId,
            cnaes:
              values.cnaes?.map((item) => {
                let cnae = {
                  id: item.id,
                  code: item.code,
                  description: item.description,
                  serviceCodes: item.serviceCodes,
                };
                return cnae;
              }) || null,
            gtAnswerItems:
              values.gtAnswerItems?.map((item) => {
                let gtAnswerItem = {
                  id: item.id,
                  title: item.title,
                };
                return gtAnswerItem;
              }) || null,
            searchTerms:
              values.terms?.map((item) => {
                let term = {
                  id: item.id,
                  term: item.term,
                };
                return term;
              }) || null,
          };
          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <h2>Resposta</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <Field name="gtAnswerName" type="text" label="Nome *" as={TextInput} />
                <ErrorMessage name="gtAnswerName" />
              </div>

              <div className="col-1_5">
                <Field name="description" type="text" label="Descrição" as={TextInput} />
                <ErrorMessage name="description" />
              </div>

              <div className="col-1_5">
                <Field
                  name="categories"
                  placeholder="Categoria *"
                  component={Select}
                  defaultValue={defaultCategories}
                  isMulti={true}
                  options={dataToSelectMapper(categories)}
                  onChange={(option) => setFieldValue("categories", option)}
                />
                <ErrorMessage name="categories" />
              </div>

              <div className="col-1_5">
                <Field
                  name="companyTypes"
                  placeholder="Tipo de empresa *"
                  defaultValue={defaultCompanyType}
                  component={Select}
                  isMulti={true}
                  options={dataToSelectMapper(companyType)}
                  onChange={(option) => setFieldValue("companyTypes", option)}
                />
                <ErrorMessage name="companyTypes" />
              </div>

              <div className="col-1_3">
                <Field
                  name="typeTax"
                  placeholder="Tipo de taxa *"
                  options={typeTax}
                  component={Select}
                />
                <ErrorMessage name="typeTax" />
              </div>

              <div className="col-3_5">
                <Field
                  name="terms"
                  placeholder="Termos de pesquisa"
                  component={Select}
                  defaultValue={defaultTerms}
                  isMulti={true}
                  options={dataToSelectMapperTerms(terms)}
                  onChange={(option) => setFieldValue("terms", option)}
                />
                <ErrorMessage name="categories" />
              </div>
              <div className="col-1_5">
                <Field
                  name="cnaes"
                  placeholder="Cnaes"
                  component={MultiCheckBoxField}
                  allowSelectAll={true}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  defaultValue={defaultCnaes}
                  isMulti={true}
                  options={mappedCnaes}
                  onChange={(option) => setFieldValue("cnaes", option)}
                  getOptionLabel={(option) => {
                    if (option.value === "*") {
                      return "Selecionar todos";
                    }
                    if (
                      option.detailCode !== null &&
                      option.detailCode !== "" &&
                      option.detailCode !== undefined
                    ) {
                      return `${option.code || 0} (${option.detailCode}) - ${option.description || option.label}`;
                    }
                    return `${option.code || 0} - ${option.description || option.label}`;
                  }}
                />
              </div>
              <div className="col-1_5">
                <Field
                  name="gtAnswersItems"
                  label="Itens de resposta"
                  placeholder="Itens de resposta *"
                  component={Select}
                  defaultValue={defaultGtAnswerItems}
                  isMulti={true}
                  options={mappedGtAnswerItems}
                  onChange={(option) => setFieldValue("gtAnswerItems", option)}
                  getOptionLabel={(option) => option.title}
                />
                <ErrorMessage name="gtAnswerItems" />
              </div>

              <div className="col-1_3" hidden>
                <h3>É simples nacional?</h3>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="isSimple"
                    row
                    defaultValue={defaultIsSimple}
                    onChange={(event) => setFieldValue("isSimple", event.target.value)}
                  >
                    <FormControlLabel value={"true"} control={<Radio />} label="Sim" />
                    <FormControlLabel value={"false"} control={<Radio />} label="Não" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <FormSubmitButtonsDock>
              <PrimaryButton className="submit-btn" type="submit">
                Salvar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AnswerForm;
