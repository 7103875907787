import styled from "styled-components";
import BaseSectionHeader from "~/presentation/views/Blog/components/SectionHeader";
import SecondarySearchField from "~/presentation/components/SecondarySearchField";
import { Pagination as MuiPagination } from "@mui/material";
import { Inbox } from "@mui/icons-material";

export const Container = styled.div`
  background: #fafafa;
  padding: max(40px, 6vw) max(10px, 5vw);

  h3 {
    color: #1341a1;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: max(1.6vw, 18px);
  }
`;

export const SectionHeader = styled(BaseSectionHeader)``;

export const SearchAndFilterWrapper = styled.div`
  padding: max(40px, 3vw) max(16px, 5vw) max(60px, 2.5vw);
  display: grid;
  grid-template-columns: auto;
  grid-gap: max(40px, 6vw);
  grid-template-areas: "S" "F";
  background: #1341a1;
  border-radius: max(8px, 1vw);
  margin-top: max(30px, 4vw);
  margin-bottom: max(20px, 2vw);

  h3 {
    color: #fafafa;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: auto auto;
    grid-template-areas: "F S";
  }
`;

export const SearchFieldWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  gap: max(1.6vw, 18px);
  grid-area: S;
`;

export const SearchField = styled(SecondarySearchField).attrs({
  invertedColors: true,
})``;

export const FiltersWrapper = styled.div`
  display: grid;
  grid-gap: max(1.6vw, 18px);
  align-items: center;
  grid-area: F;
`;

export const CategoriesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: max(0.9vw, 10px);
`;

export const CategoryButton = styled.button`
  outline: none;
  background: transparent;
  border: solid #fafafa 2px;
  border-radius: 50px;
  padding: max(0.3vw, 4px) max(1vw, 12px);
  font-size: max(16px, 1.2vw);
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s ease-in-out;
  color: #fafafa;
  cursor: pointer;

  &:hover,
  &.focused {
    background: #fafafa;
    color: #022550;
  }
`;

export const ClearFilterButton = styled(CategoryButton)`
  border-color: #fc4b6c;
  color: #fc4b6c;

  &:hover {
    background: #fc4b6c;
    color: #fafafa;
  }
`;

export const CardsWrapper = styled.div`
  padding: max(40px, 3vw) max(6px, 3vw);
  display: grid;
  justify-content: center;
  grid-row-gap: max(6vw, 40px);
  grid-template-columns: 100%;

  @media screen and (min-width: 600px) {
    grid-template-columns: 70%;
  }

  @media screen and (min-width: 960px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2%;
  }
`;

export const Pagination = styled(MuiPagination)`
  justify-self: center;
  padding: 30px;

  @media screen and (min-width: 960px) {
    grid-column: 1/4;
  }

  &.MuiPagination-root {
    display: flex;
    align-items: center;
    .MuiPaginationItem-root {
      color: inherit;

      &.Mui-selected {
        background-color: #1341a1;
        color: #fafafa;
      }
    }
  }
`;

export const InboxIcon = styled(Inbox)`
  &.MuiSvgIcon-root {
    fill: #1341a1;
  }
`;

export const NoPostsFound = styled.div`
  justify-self: center;
  display: flex;
  align-items: center;
  font-size: max(18px, 1.4vw);
  gap: max(16px, 1.2vw);
  margin-top: max(36px, 5vw);
  padding: max(80px, 8vw) 0;
  flex-flow: column;
  text-align: center;

  @media screen and (min-width: 768px) {
    flex-flow: row;
  }

  @media screen and (min-width: 960px) {
    grid-column: 1/4;
  }
`;
