import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { useHistory, useParams } from "react-router-dom";
import TypeAnswerForm from "../Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

export const TypeAnswerEdit = ({ typesAnswerUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);

  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const id = useParams().id;
  const history = useHistory();

  const [initialValues, setInitialValues] = useState();

  const fetchData = async () => {
    openBackdrop(true, "Carregando detalhes da tabela");
    const apiTypeAnswer = await typesAnswerUseCases.getTypeAnswer(id);
    setInitialValues(apiTypeAnswer);
    openBackdrop(false);
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Editando serviço");
    try {
      await typesAnswerUseCases.updateTypeAnswer(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Tipo de resposta atualizado com sucesso!",
        type: "success",
      });
      history.push("/admin/tipos-de-resposta");
    } catch (err) {
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao atualizar o tipo de resposta",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Editar tipo de resposta" backLink="/admin/tipos-de-resposta" />

      {initialValues ? (
        <TypeAnswerForm initialValues={initialValues} handleSubmit={handleSubmit} />
      ) : (
        <div />
      )}
    </Container>
  );
};
