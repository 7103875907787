import React from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import {
  Container,
  Form,
} from "~/presentation/views/Admin/ExplanatoryVideo/CreateEdit/Form/styles";
// schema
import DateInput from "~/presentation/components/DateInput";
import TextEditor from "~/presentation/components/TextEditor";
import validationSchema from "~/presentation/views/Admin/ExplanatoryVideo/CreateEdit/Form/validationSchema";

const ExplanatoryVideoForm = ({ handleSubmit, initialValues }) => {
  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          let submitValues = {
            ...values,
            videoUrl: "-",
            description: values.description || "",
          };
          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <h2>Informações</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <Field name="title" type="text" label="Título" as={TextInput} />
                <ErrorMessage name="title" />
              </div>

              <div className="col-1_5">
                <DateInput
                  label="Data da publicação"
                  value={values.publishDate}
                  maxDate={new Date()}
                  onChange={(value) => setFieldValue("publishDate", value)}
                />
                <ErrorMessage name="publishDate" />
              </div>

              <h2>
                Contéudo do tutorial <ErrorMessage name="text" />
              </h2>
              <div className="col-1_5">
                <TextEditor
                  initialValue={values.text}
                  name="text"
                  label="Contéudo do tutorial"
                  height={400}
                  handleEditorChange={(content) => {
                    setFieldValue("text", content);
                  }}
                />
              </div>
            </div>

            <PrimaryButton className="submit-btn" type="submit">
              Salvar
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ExplanatoryVideoForm;
