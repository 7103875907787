import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column;

  .dropUploadZone {
    align-self: center;
    margin: auto 0;
    height: 40%;
    width: 100%;
    text-align: center;

    @media screen and (min-width: 600px) {
      width: 60%;
    }
  }

  .submitBtn {
    align-self: center;
    width: 100%;

    @media screen and (min-width: 600px) {
      width: 30%;
      margin: 0 6px;
    }
  }
`;
