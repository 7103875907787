import React from "react";
import { useFormikContext } from "formik";

import {
  hasINSSInTheServiceOptions,
  witchArticleIN_RFB_971_2009Options,
  additionalAliquotRetentionOptions,
  aliquotRetentionOptions,
} from "./formOptions";

import {
  CheckboxQuestion,
  MoneyQuestion,
  RadioQuestion,
  SelectQuestion,
} from "~/presentation/views/GTFacil/Simulator/components";

export const HasInssInTheService = ({ onChange }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <RadioQuestion
      text="Tem retenção de INSS na cessão de mão de obra ou empreitada?"
      onChange={(value) => {
        setFieldValue("hasINSSInTheService", value);
        onChange();
      }}
      firstValue={hasINSSInTheServiceOptions.true}
      secondValue={hasINSSInTheServiceOptions.false}
      thirdLabel={"Estou com dúvidas"}
      thirdValue={hasINSSInTheServiceOptions.doubt}
      onlyTwoOptions={false}
      value={values.hasINSSInTheService}
    />
  );
};

export const WitchArticleIN_RFB_971_2009 = ({ onChange }) => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <RadioQuestion
      name="witchArticleIN_RFB_971_2009"
      text="O serviço se encontra previsto em qual dos artigos na IN RFB 2.110/2022?"
      onChange={(value) => {
        setFieldValue("witchArticleIN_RFB_971_2009", value);
        onChange();
      }}
      firstLabel="Art. 111 da IN 2.110/22"
      secondLabel="Art. 112 da IN 2.110/22"
      firstValue={witchArticleIN_RFB_971_2009Options.art117}
      secondValue={witchArticleIN_RFB_971_2009Options.art118}
      value={values.witchArticleIN_RFB_971_2009}
    />
  );
};

export const Art118Details = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <CheckboxQuestion
      name="art118Details"
      text="Quais características estão presentes no contrato?"
      onChange={(value, name) => setFieldValue(`art118Details.${name}`, value)}
      checkboxes={[
        {
          name: "employeeSubordination",
          value: values.art118Details.employeeSubordination,
          label:
            "Colocação à disposição de trabalhadores com subordinação destes ao contratante",
        },
        {
          name: "continuousService",
          value: values.art118Details.continuousService,
          label: "Serviço contínuo",
        },
        {
          name: "inContractorDependenciesOrIndicated",
          value: values.art118Details.inContractorDependenciesOrIndicated,
          label: "Nas dependências do tomador ou indicadas por ele",
        },
      ]}
    />
  );
};

export const HasMaterialOrEquipmentExclusion = () => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  return (
    <RadioQuestion
      text="Há exclusão de materiais/equipamentos?"
      excerpt="A exclusão de materiais e/ou equipamentos está prevista nos arts. 116 a 119 da IN RFB 2.110/2022."
      onChange={(value) => {
        setFieldValue("hasMaterialOrEquipmentExclusion", value);
        setFieldValue(
          "materialOrEquipmentExclusionValue",
          initialValues.materialOrEquipmentExclusionValue
        );
      }}
      value={values.hasMaterialOrEquipmentExclusion}
    />
  );
};

export const MaterialOrEquipmentExclusionValue = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <MoneyQuestion
      text="Valor dos materiais e/ou equipamentos destacados na nota fiscal:"
      excerpt="Embora não seja o único requisito para se admitir a exclusão de materiais e/ou equipamentos da base de cálculo da retenção, é imprescindível que o valor relativo a esta parcela seja destacado pelo prestador na nota fiscal de prestação de serviço, conforme prevê o art. 119, parágrafo único, da IN RFB 2.110/2022 e a solução de Consulta Cosit nº 253, de 26 de maio de 2017"
      value={values.materialOrEquipmentExclusionValue}
      onChange={(_event, _maskedValue, floatValue) => {
        setFieldValue("materialOrEquipmentExclusionValue", floatValue);
      }}
    />
  );
};

export const HasTransportationOrAlimentationBenefitsDeduction = () => {
  const { values, setFieldValue, initialValues } = useFormikContext();
  return (
    <RadioQuestion
      text="Há dedução de vale-transporte e/ou despesas com alimentação?"
      excerpt=" A dedução de vale-transporte ou despesas com alimentação está prevista no art. 120 da IN RFB 2.110/2022. Ela não depende de previsão contratual, mas deve estar destacada na nota fiscal."
      onChange={(value) => {
        setFieldValue(
          "hasTransportationOrAlimentationBenefitsDeduction",
          value
        );
        setFieldValue(
          "transportationOrAlimentationBenefitsDeductionValue",
          initialValues.transportationOrAlimentationBenefitsDeductionValue
        );
      }}
      value={values.hasTransportationOrAlimentationBenefitsDeduction}
    />
  );
};

export const TransportationOrAlimentationBenefitsDeductionValue = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <MoneyQuestion
      text="Valor de vale-transporte ou despesas com alimentação:"
      value={values.transportationOrAlimentationBenefitsDeductionValue}
      onChange={(_event, _maskedValue, floatValue) => {
        setFieldValue(
          "transportationOrAlimentationBenefitsDeductionValue",
          floatValue
        );
      }}
    />
  );
};

export const BaseRetentionCalcValue = ({ value }) => (
  <MoneyQuestion
    text="Base de cálculo da retenção previdenciária:"
    value={value}
    excerpt="A base de cálculo é o valor bruto da nota fiscal excluídos e deduzidos os valores dos materiais/equipamentos e do vale-transporte ou despesa com alimentação, respectivamente."
    allowNegative
    disabled
  />
);

export const AliquotRetention = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <SelectQuestion
      text="Alíquota da retenção:"
      excerpt="A alíquota será de 3,5% apenas na hipótese de o prestador estar enquadrado no regime da desoneração da folha de salários. Para isso, além do destaque na nota fiscal, ele deve apresentar declaração nos moldes do Anexo III da IN RFB 2.053/2021. Confira no art. 11, §§ 4º e 5º da referida norma."
      onChange={(value) => setFieldValue("aliquotRetention", value)}
      value={values.aliquotRetention.value}
      options={aliquotRetentionOptions}
    />
  );
};

export const HasAdditionalAliquotRetention = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <RadioQuestion
      text="Há incidência da alíquota adicional do art. 131 da IN 2.110/22?"
      excerpt="As alíquotas adicionais incidem quando a atividade do segurado na empresa contratante for exercida em condições especiais que prejudiquem sua saúde ou integridade física, de forma a possibilitar a concessão de aposentadoria especial após 15, 20 ou 25 anos de trabalho."
      onChange={(value) =>
        setFieldValue("hasAdditionalAliquotRetention", value)
      }
      value={values.hasAdditionalAliquotRetention}
    />
  );
};

export const AdditionalAliquotRetention = () => {
  const { values, setFieldValue } = useFormikContext();
  return (
    <SelectQuestion
      text="Qual a alíquota adicional?"
      excerpt="As alíquotas adicionais serão de 4%, 3% ou 2%, caso ensejem a concessão da aposentadoria especial após 15, 20 ou 25 anos de trabalho, respectivamente."
      value={values.additionalAliquotRetention.value}
      onChange={(value) => setFieldValue("additionalAliquotRetention", value)}
      options={additionalAliquotRetentionOptions}
    />
  );
};
