import React, { useContext, useState, useRef, useEffect } from "react";
import { Form, Formik } from "formik";
import {
  Button,
  FormWrapper,
  MoneyQuestion,
  RadioQuestion,
  SimulatorResultCard,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { floatToReais } from "~/presentation/utils/reais-parser";
import { useValuesChanged } from "~/presentation/views/GTFacil/Simulator/hooks/usValuesChanged";
import { reportMapper } from "./reportMapper";
import { getBaseCalculus, getDependentsValue, getIrrfResult } from "./calculator";
import aliquotData from "../../common/irrf/table-range";

import { SelectQuestion } from "../../../components";

const retentionAliquotOptions = [
  {
    value: 0.0,
    label: "0% - Valor de R$ 0,00 até R$ 2112,00.",
  },
  {
    value: 0.075,
    label: "7,5% - Valor de R$ 2.259,21 até R$ 2.826,65, com parcela a deduzir de R$ 169,44",
  },
  {
    value: 0.15,
    label: "15% - Valor de R$ 2.826,66 até R$ 3.751,05, com parcela a deduzir de R$ 381,44",
  },
  {
    value: 0.225,
    label: "22,5% - Valor de R$ 3.751,06 até R$ 4.664,68, com parcela a deduzir de R$ 662,77",
  },
  { value: 0.275, label: "27,5% - Acima de R$ 4.664,68, com parcela a deduzir de R$ 896,00" },
];

export const TransportTypes = {
  cargo: "cargo",
  passenger: "passenger",
};

const initialValues = {
  isTransportService: "false",
  transportType: TransportTypes.cargo, // cargo | passenger
  // aliquotRetention: 0.015,
  hasMaterialExclusion: "false",
  materialExclusionValue: 0.0,
  numberOfDependents: 0,
  retentionAliquot: retentionAliquotOptions[0],
};

export const IrrfCooperative = () => {
  const resultCardRef = useRef();
  const [result, setResult] = useState();
  const { simulatorValue, setInvalidSimulatorValue, handleGenerateReport, handleCacheReport } = useContext(SimulatorContext);
  const { isValuesChanged, setValues } = useValuesChanged();
  const [percent, setPercent] = useState();

  /* DEPRECATED */
  const getResult = (values) => {
    return (simulatorValue - values.materialExclusionValue) * values.aliquotRetention;
  };

  const handleGenerateReportClick = (values) => {
    const response = reportMapper(values, result, simulatorValue);
    handleGenerateReport(response);
  };


  const handleSelectRange = (baseCalculus) => {
    const selectedRange = Object.values(aliquotData).find((data) => {
      return baseCalculus >= data.minValue && baseCalculus <= data.maxValue;
    });
    // Return the aliquot if a range was found, undefined otherwise
    setPercent(selectedRange.aliquot);

    return selectedRange ? selectedRange.aliquot : undefined;
  };

  useEffect(() => {}, [percent]);

  useEffect(() => {
    if(result) {
      resultCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }
        action.setSubmitting(true);

        setValues(values);

        const result = getIrrfResult(values, simulatorValue, percent);
        setResult(result);

        resultCardRef.current.scrollIntoView({ behavior: "smooth" });

        const retentionAliquot = {
          label: "",
          value: percent,
        };

        for (let item of retentionAliquotOptions) {
          if (item.value === percent) {
            retentionAliquot.label = item.label;
          }
        }

        const response = reportMapper(values, result, simulatorValue, retentionAliquot);
        handleCacheReport(response);

        action.setSubmitting(false);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <FormWrapper>
              <RadioQuestion
                name="isTransportService"
                text="O rendimento é decorrente do serviço de transporte?"
                onChange={(value) => setFieldValue("isTransportService", value)}
                value={values.isTransportService}
              />
              {values.isTransportService === "true" && (
                <RadioQuestion
                  name="transportType"
                  text="O transporte é de:"
                  onChange={(value) => setFieldValue("transportType", value)}
                  value={values.transportType}
                  firstLabel="Carga"
                  firstValue={TransportTypes.cargo}
                  secondLabel="Passageiro"
                  secondValue={TransportTypes.passenger}
                />
              )}
              <MoneyQuestion
                text="Base de cálculo da retenção do IR:"
                value={getBaseCalculus(values, simulatorValue)}
                disabled
              />
              {/* <MoneyQuestion
                text="Alíquota da retenção:"
                excerpt="O art. 652, do Decreto n° 3.000/99 (Regulamento do Imposto de Renda - RIR/99), dispõe que estão sujeitas à incidência do IR na fonte à alíquota de 1,5% (um e meio por cento) as importâncias pagas ou creditadas por pessoas jurídicas a cooperativas de trabalho, associações de profissionais ou assemelhadas, relativas a serviços pessoais que lhes forem prestados por associados destas ou colocados à disposição."
                value={values.aliquotRetention * 100}
                prefix="%"
                disabled
                allowNegative
              /> */}

              <SelectQuestion
                text="Alíquota de retenção do IR:"
                value={handleSelectRange(getBaseCalculus(values, simulatorValue))}
                options={retentionAliquotOptions}
                menuPlacement="top"
                disabled
              />
              <RadioQuestion
                text="Tem exclusão dos materiais? "
                onChange={(value) => {
                  setFieldValue("hasMaterialExclusion", value === "true");
                  setFieldValue("materialExclusionValue", initialValues.materialExclusionValue);
                }}
                value={values.hasMaterialExclusion.toString()}
                excerpt="É possível destacar o valor dos serviços separadamente dos valores relativos a materiais, de modo que a incidência do IRRF recaia somente sobre o primeiro. Esse entendimento pode ser inferido do próprio texto do art. 652 do RIR/99, ao restringir a incidência apenas aos serviços pessoais dos cooperados, e é confirmado pelo Ato Declaratório Normativo COSIT n° 1/93."
              />
              {values.hasMaterialExclusion === true ? (
                <MoneyQuestion
                  text="Valor dos materiais"
                  value={values.materialExclusionValue}
                  onChange={(_event, _maskedValue, floatValue) => {
                    setFieldValue("materialExclusionValue", floatValue);
                  }}
                />
              ) : null}

              <Button mt>Ver Resultado</Button>
              {(result && isValuesChanged(values)) || result == 0 ? (
                <SimulatorResultCard
                  componentRef={resultCardRef}
                  title="Valor da retenção do IRRF:"
                  value={floatToReais(result)}
                  description="O valor da retenção do imposto de renda corresponde ao resultado da subtração do valor bruto da nota fiscal pelo valor dos materiais a serem excluídos, multiplicado pela alíquota de retenção."
                  generateReportButton={() => handleGenerateReportClick(values)}
                />
              ) : null}
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
