function handleMoney(money) {
  if (typeof money === "string") {
    const result = money.split(".").join("").replace(",", ".");
    return Number(result);
  } else {
    return Number(money);
  }
}

export default handleMoney;
