import React from "react";
import InputMask from "react-input-mask";
import TextInput from "~/presentation/components/TextInput";

const MaskedInput = (props) => (
  <InputMask {...props}>
    {(inputProps) => <TextInput {...inputProps} />}
  </InputMask>
);

export default MaskedInput;
