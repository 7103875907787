import React from "react";

import { Container, Disabled } from "./styles";

export const AnswerCard = ({ answer, onClick, disabled, isPF = false }) => {
  const getLabel = (isPF, label) => {
    if (isPF) {
      if (label.includes("INSS")) {
        label = "INSS de Contribuintes Individuais";
      }
      if (label.includes("IRRF")) {
        label = "IRRF de Pessoas Físicas";
      }
      if (label.includes("ISS na")) {
        label = "ISS na Contratação de Pessoas Físicas";
      }
    }
    return label;
  };

  return (
    <Container color={answer.color} selected={false} onClick={onClick} disabled={disabled}>
      {getLabel(isPF, answer.label)}
    </Container>
  );
};
