import * as Yup from "yup";

export default Yup.object({
    gtAnswerName: Yup.string().required("Campo obrigatório!"),
    typeTax: Yup.number().typeError("Selecione um imposto").required("Selecione um imposto"),
    isSimple: Yup.boolean(),
    categories: Yup.array().of(
        Yup.object().shape({
            id: Yup.number(),
            name: Yup.string(),
            letter: Yup.string()
        }),
    )
        .min(1, 'Insira ao menos uma categoria')
        .typeError("Campo obrigatório!")
        .required("Campo obrigatório!"),
    companyTypes: Yup.array().of(
        Yup.object().shape({
            id: Yup.number(),
            name: Yup.string(),
        }),
    )
        .min(1, 'Insira ao menos um tipo de empresa')
        .typeError("Campo obrigatório!")
        .required("Campo obrigatório!"),
    gtAnswerItems: Yup.array().of(
        Yup.object().shape({
            id: Yup.number(),
            text: Yup.string(),
        }),
    )
        .min(1, 'Insira ao menos um tipo de reposta')
        .typeError("Campo obrigatório!")
        .required("Campo obrigatório!"),
});
