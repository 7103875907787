export const customStyles = (appTheme, props) => ({
  control: (provided, state) => ({
    ...provided,
    background: "unset",
    borderColor: "#383f48",
    ":focus-within": {
      boxShadow: `0 0 0 1px #383f48`,
    },
    ":hover": {
      boxShadow: `0 0 0 0.5px #373e47`,
      borderColor: "#373e47",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: props.padding || "12px",
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: "9999",
    background: "#eee",
    borderRadius: "10px",
    color: "#383f48",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#383f48",
    fontSize: "1rem",
    fontFamily: "sans-serif", // padrao material-ui
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#383f48",
    fontSize: "1rem",
    fontFamily: "sans-serif", // padrao material-ui
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    color: "#383f48",
    fontFamily: "sans-serif", // padrao material-ui
  }),
  option: (provided, state) => ({
    ...provided,
    color: "#383f48",
    fontFamily: "sans-serif", // padrao material-ui
  }),
  input: (provided) => ({
    ...provided,
    color: "#383f48",
    fontSize: "1rem",
  }),
});
