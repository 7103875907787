import styled from "styled-components";
import { transparentize } from "polished";

export const AccordionHeader = styled.div`
  display: grid;
  gap: 6px;
  width: 100%;

  > div:nth-child(1) {
    display: grid;
    grid-template-columns: auto min-content;

    .senderEmail {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .messageDateTime {
      white-space: nowrap;
    }
  }

  .messagePreview {
    color: ${(props) => transparentize(0.25, "#383f48")};
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 100px);

    /** hide all html of the email except for the first line */
    > * {
      display: none;

      &:nth-child(1) {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
    }

    &.hide {
      opacity: 0;
    }
  }
`;
