import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Form from "~/presentation/views/Admin/LegislationCategory/Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

const initialValues = {
  name: "",
  letter: "",
};

const CreateCategory = ({ legislationCategoryUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const history = useHistory();

  const handleSubmit = async (values) => {
    openBackdrop(true, "Cadastrando nova categoria de legislação");
    try {
      await legislationCategoryUseCases.createLegislationCategory(values);
      history.push("/admin/categoria-legislacao");
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar categoria: missing endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Categoria de Legislação" />
      <Form initialValues={initialValues} handleSubmit={handleSubmit} />
    </Container>
  );
};

export default CreateCategory;
