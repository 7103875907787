import React, { useState, useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { ApiDigitalCertUseCases } from "~/data/usecases/DigitalCert/apiDigitalCert";
import { ApiCompanyUseCases } from "~/data/usecases/company/apiCompany";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { Box, Grid, Typography as Text } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { Close } from "@mui/icons-material";
import { EventDetailsModal } from "../../../../SendReinf/components/EventSend/EventDetailsModal";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";
import DetailedError from "~/presentation/components/DetailedError";

export const SendR1000 = ({
  closeModal,
  operationNumber,
  ItemCompanyId,
  callBackFnc,
  refreshData,
}) => {
  const apiSendReinf = new ApiReinfUseCases(makeHttpClient());
  const apiDigitalCert = new ApiDigitalCertUseCases(makeHttpClient());
  const companyUseCases = new ApiCompanyUseCases(makeHttpClient());
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  //dados empresa
  const user = JSON.parse(localStorage.getItem("GT_user"));
  const CompanyUser = user?.companyId;
  const [UserCompany, setUserCompany] = useState();

  //array de certificados
  const [certificate, setCertificate] = useState([]);

  //modal de resposta envio R1000
  const [modalOpen, setModalOpen] = useState(false);
  const [responseDetails, setResponseDetails] = useState(null);

  //messagem de erro detalhada
  const [detailedError, setDetailedError] = useState(null);

  //dados params para envio
  const [eventData, setEventData] = useState({
    companyId: CompanyUser,
    digitalCertId: "",
    isProduction: false, // por padrão inicia em false
    operation: operationNumber,
    itemId: ItemCompanyId,
  });

  const TitleText =
    operationNumber === 1
      ? "INCLUSÃO"
      : operationNumber === 2
        ? "ALTERAÇÃO"
        : operationNumber === 3
          ? "EXCLUSÃO"
          : "";

  const getCompany = async () => {
    const result = await companyUseCases.getCompanyById(CompanyUser);
    setUserCompany(result);
  };

  const getCertificates = async () => {
    openBackdrop(true, "Carregando dados");
    const CertsData = await apiDigitalCert.getDigitalCerts();
    setCertificate(CertsData);
    const defaultCert = CertsData.find((cert) => cert.isDefault);
    const cert = defaultCert.id || CertsData[0].id;
    handleOnChange("digitalCertId", cert);
    openBackdrop(false);
  };

  //1º render
  useEffect(() => {
    getCertificates();
    getCompany();
  }, []);

  const handleOnChange = (field, value) => {
    setEventData({
      ...eventData,
      [field]: value,
    });
  };

  const handleSubmit = async () => {
    openBackdrop(true, "Enviando dados do Evento");
    try {
      const response = await apiSendReinf.sendEvent(eventData, "r1000");
      setResponseDetails(response);
      if (response) {
        setModalOpen(true);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: "Evento registrado com sucesso",
        type: "success",
      });
      if (callBackFnc && response.codeResponse === 2) {
        callBackFnc(ItemCompanyId);
      }
    } catch (error) {
      console.error("Erro ao enviar evento: ", error);
      setDetailedError(error.data);
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro ao enviar evento: ${error.data?.title || "Erro"}`,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const isProductionoptions = [
    { value: false, label: "Produção Restrita" },
    { value: true, label: "Produção" },
  ];

  return (
    <Box sx={styles.content}>
      <Grid container spacing={2}>
        {detailedError ? (
          <Grid item xs={12}>
            <DetailedError errorContent={detailedError} />
          </Grid>
          ) : <></>
        }
        <Grid item xs={6} sm={11}>
          <Text style={styles.Title}>DADOS DO EVENTO {TitleText} R-1000</Text>
        </Grid>
        <Grid
          item
          xs={6}
          sm={1}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <Close onClick={closeModal} />
        </Grid>
        <Grid item xs={12}>
          <Input
            type="text"
            title="Estabelecimento"
            name="companyName"
            value={UserCompany?.name || ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            type="select"
            title="Selecionar Certificado"
            name="digitalCertId"
            placeholder="Selecione um certificado"
            options={certificate}
            getOptionLabel={(option) => option.name}
            onChange={(option) => handleOnChange("digitalCertId", option.id)}
            value={certificate.find((cert) => cert.id === eventData?.digitalCertId)}
            error={!eventData?.digitalCertId}
            errorText={!eventData?.digitalCertId && "Campo Obrigatório"}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            type="select"
            title="Tipo de Ambiente"
            name="isProduction"
            options={isProductionoptions}
            onChange={(option) => handleOnChange("isProduction", option.value)}
            value={isProductionoptions.find((option) => option.value === eventData?.isProduction)}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
        <RButton sx={{ width: "150px" }} type="button" variant="outlined" onClick={closeModal}>
          Cancelar
        </RButton>
        <RButton
          sx={{ width: "150px" }}
          variant="contained"
          color="primary"
          disabled={!eventData?.companyId || !eventData?.digitalCertId}
          onClick={handleSubmit}
        >
          Enviar R-1000
        </RButton>
      </Box>
      <ModalContainer open={modalOpen}>
        <EventDetailsModal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
            closeModal();
            refreshData();
          }}
          eventDetails={responseDetails}
        />
      </ModalContainer>
    </Box>
  );
};

const styles = {
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  content: {
    p: 4,
  },
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "800px",
    width: "90%",
    maxHeight: "80%",
    borderRadius: "18px",
    bgcolor: "#E8E8E8",
    boxShadow: 24,
    overflow: "auto",
  },
};
