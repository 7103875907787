import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = ({ initialValue, name, handleEditorChange, height = 500 }) => {
  return (
    <Editor
      initialValue={initialValue || ""}
      textareaName={name}
      onEditorChange={handleEditorChange}
      apiKey="iu3uxsk52x1ac9e0e4vuatjj40rivarq3ach4yl0kdchad3f"
      init={{
        height: height,
        plugins: [
          `print preview paste importcss searchreplace autolink autosave 
                save directionality code visualblocks visualchars fullscreen 
                image link media template codesample table charmap hr pagebreak 
                nonbreaking anchor toc insertdatetime advlist lists wordcount 
                imagetools textpattern noneditable help charmap quickbars 
                emoticons`,
        ],
        toolbar: `undo redo | bold italic underline strikethrough | fontselect 
                fontsizeselect formatselect | alignleft aligncenter alignright 
                alignjustify | outdent indent |  numlist bullist | forecolor 
                backcolor removeformat | pagebreak | charmap emoticons | 
                fullscreen  preview save print | insertfile image media template 
                link anchor codesample | ltr rtl`,
        menubar: "file edit view insert format tools table help",
        toolbar_sticky: true,
        file_picker_callback: (callback, value, meta) =>
          console.log("callback ", callback, "value ", value, "meta ", meta),
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        contextmenu: "link image imagetools table",
      }}
    />
  );
};

export default TextEditor;
