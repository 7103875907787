import buildQueryString from "~/presentation/utils/buildQueryString";
import { apiURL } from "../../usecases/api";

export class ApiBlogUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getBlogPosts(isClient = false, queryParams) {
    try {
      let url = `${apiURL}/blog/posts?isClient=${isClient}&IsSummaryOnly=false`;
      url += `&${buildQueryString(queryParams)}`;
      let response = await this.httpClient.request({
        url: url,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async getBlogTags() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/tags`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async getLastBlogPosts(number) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/posts?order=asc&limit=${number}&IsSummaryOnly=false`,
        method: "GET",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async deleteBlogPost(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/blog/${id}`,
      method: "DELETE",
    });
    return response.data;
  }

  async getBlogById(id) {
    // let response = { data: blogPosts.find((post) => post.id == id) };
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async getBlogPostToEdit(id) {
    // let response = { data: blogPosts.find((post) => post.id == id) };
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/${id}`,
        method: "GET",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async getHighlightBlogPost() {
    // let response = { data: blogPosts.find((post) => post.id == 1) };
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/posts?highlight=true`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async getMostAccessedPosts(number) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/blog/posts?orderBy=desc&fieldorder=views&limit=${number}`,
        method: "GET",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async updateBlogPost(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/`,
        method: "PUT",
        data,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async createBlogPost(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/`,
        method: "POST",
        data,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async uploadPost(image) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/blog/upload`,
        method: "POST",
        data: image,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }
}
