import styled from "styled-components";

export const OuterContainer = styled.div`
  position: relative;
  background-image: url("/assets/maze5.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: -150px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 85%;
  padding: 5% 5% 5% 5%; /* Utilizei porcentagens para o padding */
  background: white;
  align-items: center;
  margin: 0 auto; /* Centralize horizontalmente */
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    background: transparent;
    border: 3px solid #08b7de;
  }

  > div {
    display: grid;
    grid-template-columns: 50% 50%; /* Divisão em duas colunas de 50% cada */
    grid-column-gap: 4%;
    width: 100%; /* Ocupa toda a largura disponível */

    @media screen and (max-width: 1040px) {
      display: grid;
      grid-template-columns: 100% 0%; /* Divisão em duas colunas de 50% cada */
      justify-content: space-between;
    }
  }
`;

export const VideoContainer = styled.div`
  grid-area: video;
  position: relative;

  box-shadow: "-20px -19px 0px 0px #14213D";

  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 55%;

  @media screen and (max-width: 768px) {
    justify-self: flex-end;
    max-width: 100%;
    max-height: 50%;
  }

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const TextWrapper = styled.div`
  grid-area: text;
  display: grid;
  gap: 3%;
  grid-template-columns: auto 3px;
  grid-template-areas: "column line";
  position: relative;

  @media screen and (max-width: 1040px) {
    display: none;
  }

  #column {
    grid-area: column;
    display: flex;
    flex-flow: column;
    height: 100%;

    > span {
      font-family: "Bebas Neue";
      font-weight: 300;
      font-size: 27px;
      font-weight: regular;
      color: #052a48;
      line-height: 80%;

      @media screen and (max-width: 960px) {
        text-align: center;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    > h2 {
      font-family: "Montserrat";
      color: #4c4c4c;
      font-weight: bold;
      font-size: 38px;
      line-height: 30px;
      text-align: justify;

      @media screen and (max-width: 960px) {
        text-align: center;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    > p {
      font-family: "Montserrat";
      font-weight: regular;
      font-size: 18px;
      line-height: 147.4%;
      text-align: left;
      margin: 10px 0 0 0;

      @media screen and (max-width: 960px) {
        text-align: center;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    #knowMore {
      margin-top: 32px;
      @media screen and (max-width: 960px) {
        margin: 32px auto 0;
      }
    }
  }

  #rightLine {
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
`;
