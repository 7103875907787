import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export const exportCSV = (data, columns, fileName) => {
  if (!data || !columns) return;
  let csvContent = columns?.map(col => col.label).join(',') + '\n';

  data?.forEach(row => {
    let rowContent = columns?.map(col => `"${row[col.name]}"`).join(',');
    csvContent += rowContent + '\n';
  });

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, `${fileName}.csv`);
};

export const exportXLSX = (data, columns, fileName) => {
  if (!data || !columns) return;
  const workSheet = XLSX.utils.json_to_sheet(data?.map(row => {
    let rowData = {};
    columns?.forEach(col => {
      rowData[col.label] = row[col.name];
    });
    return rowData;
  }));

  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, 'Página 1');
  const wbout = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });

  const blob = new Blob([wbout], { type: 'application/octet-stream' });
  saveAs(blob, `${fileName}.xlsx`);
};