import { apiURL } from "../../usecases/api";
export class ApiNfseUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async uploadNfse(file) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      let response = await this.httpClient.request({
        url: `${apiURL}/Nfse/UploadXml`,
        method: "POST",
        data: formData,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async uploadSIAFI(fileXML, file) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileXML", fileXML);
      let response = await this.httpClient.request({
        url: `${apiURL}/Nfse/Upload/SIAFI`,
        method: "POST",
        data: formData,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async uploadSheet(file, isReplace) {
    try {
      const formData = new FormData();
      formData.append("file", file);
      let response = await this.httpClient.request({
        url: `${apiURL}/Nfse/Upload/Sheet?isReplace=${isReplace}`,
        method: "POST",
        data: formData,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getNfse(data) {
    try {
      // Monta a URL com ou sem query params
      const url = data
        ? `${apiURL}/Nfse?${new URLSearchParams(data).toString()}`
        : `${apiURL}/Nfse`;
      const response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getNfseById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse/${id}`,
        method: "GET",
      });

      if (response.status === 200) {
        return response.data; // Assuming your data is in the 'data' field of the response.
      } else {
        throw new Error("API request failed with status: " + response.status);
      }
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  }

  async getNfseByProtocol(protocol) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse/reinf/${protocol}`,
        method: "GET",
      });

      if (response.status === 200) {
        return response.data; // Assuming your data is in the 'data' field of the response.
      } else {
        throw new Error("API request failed with status: " + response.status);
      }
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  }

  async updateNfse(nfseObject) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse`,
        method: "PUT",
        data: nfseObject, // Adicione o objeto Nfse no corpo da solicitação
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async createNfse(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  async deleteNfse(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/Nfse/${id}`,
        method: "DELETE",
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
