import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
// layout
import ReinfDashboardLayout from "~/presentation/layouts/ReinfDashboardLayout";

import { ReinfHome } from "~/presentation/views/Reinf/Home/index";
import { ViewDigitalCertificate } from "~/presentation/views/Reinf/DigitalCertificate";
import { SendReinf } from "../views/Reinf/SendReinf";
//R-1000
import { CreateNfse } from "~/presentation/views/Reinf/DocPanel/Create/CreateNfse";
//R-2010
import { ViewHomeNf } from "~/presentation/views/Reinf/DocPanel/Home";
import { PanelR1000 } from "../views/Reinf/Establishments";
import EventsHistory from "../views/Reinf/EventsHistory/EventsHistory";
import BusinessPartner from "~/presentation/views/Reinf/BusinessPartner/BusinessPartner";
import Reports from "~/presentation/views/Reinf/Reports/Reports";

const ReinfRoutes = ({ match: { url } }) => {
  return (
    <ReinfDashboardLayout>
      <Switch>
        <Route exact path={`${url}`} component={ReinfHome} />

        {/*Certificado Digital */}
        <Route exact path={`${url}/certificado-digital`} component={ViewDigitalCertificate} />

        {/*Série R-1000 + Estabelecimentos */}
        <Route exact path={`${url}/estabelecimentos-r1000`} component={PanelR1000} />

        {/*Parceiro de negócio*/}
        <Route exact path={`${url}/parceiros-negocio`} component={BusinessPartner} />

        {/*Painel doc fiscais + importação*/}
        <Route exact path={`${url}/painel-doc-fiscais`} component={ViewHomeNf} />

        {/*Criar Doc Fiscal*/}
        <Route exact path={`${url}/criar-doc-fiscal`} component={CreateNfse} />

        {/*Tabela Correção e Envio de Notas + Evetnos enviados*/}
        <Route exact path={`${url}/eventos-efd-reinf`} component={SendReinf} />

        {/*Relatórios*/}
        <Route exact path={`${url}/relatorios`} component={Reports} />

        {/*Histórico Eventos 98 99*/}
        <Route exact path={`${url}/historico-eventos`} component={EventsHistory} />

        <Redirect to={url} />
      </Switch>
    </ReinfDashboardLayout>
  );
};

export default ReinfRoutes;
