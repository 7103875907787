import React, { useEffect, useState } from "react";
import { Title, Subtitle } from "../../../../../../components/Layouts/styles";
import { Box, Grid } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { KeyboardArrowDown } from "@mui/icons-material";
import handleMoney from "~/presentation/views/Reinf/components/HandleMoney/handleMoney";

function IRPJ({
  item,
  onChangeItem,
  index,
  nfseData,
  errors,
  disabled,
  incomeNatureOptions,
  modalCalculateRetention,
}) {
  const [show, setShow] = useState(false);

  //FÓRMULA: Retenções
  const amountIRPJpercentage =
    item?.amountOperation > 0
      ? ((item?.amountIR / item?.amountOperation) * 100)?.toFixed(2)
      : "0.00";
  const amountCSLLpercentage =
    item?.amountOperation > 0
      ? ((item?.amountCSLL / item?.amountOperation) * 100)?.toFixed(2)
      : "0.00";
  const amountPISpercentage =
    item?.amountOperation > 0
      ? ((item?.amountPIS / item?.amountOperation) * 100)?.toFixed(2)
      : "0.00";
  const amountCONFINSpercentage =
    item?.amountOperation > 0
      ? ((item?.amountCOFINS / item?.amountOperation) * 100)?.toFixed(2)
      : "0.00";

  //FÓRMULA: aliquotIR
  const aliquotIRformula =
    Number(amountCSLLpercentage) +
    Number(amountPISpercentage) +
    Number(amountCONFINSpercentage) +
    Number(amountIRPJpercentage);

  //ERROR: aliquotIR
  const possibleAliquots = ["0.00", "5.85", "4.89", "1.24", "2.20", "3.40", "4.65", "7.05", "9.45"];
  const isAliquotValid = possibleAliquots?.includes(aliquotIRformula.toFixed(2));

  const isAliquotWrong =
    item?.amountAggregate > 0 && item?.amountNotRetained === 0 && !isAliquotValid;
  const isAliquotWrongText = isAliquotWrong && "Valor da retenção está incorreto.";

  //ERROR: amountAggregate não pode ser maior do que a base
  const isAmountAggregateWrong = item?.amountAggregate > item?.bcIR;
  const isAmountAggregateWrongText =
    isAmountAggregateWrong && "A Retenção não pode ser maior do que a Base!";

  //ERROR: data no futuro
  const isDateInTheFuture = item?.dateOfPayment > new Date();
  const isDateWrong = isDateInTheFuture && "Escolha uma data no presente";

  return (
    <Box sx={styles.container}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <Title>Retenção do IRPJ e Cont. Sociais</Title>
        {item?.typeDoc && modalCalculateRetention}
      </Box>

      <Box
        sx={{
          paddingTop: 4,
          paddingInline: 4,
          paddingBottom: show ? 4 : 2,
          background: "#d9d9d9",
          borderRadius: 4,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} lg={3}>
            <Input
              type="select"
              title="Natureza do Rendimento"
              options={incomeNatureOptions}
              getOptionLabel={(option) => `${option.incomeCode} - ${option.incomeDescription}`}
              placeholder="Selecione a natureza do rendimento"
              value={incomeNatureOptions.find((i) => i.id == item.incomeNatureId)}
              onChange={(option) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    incomeNatureId: option.id,
                    incomeNatureDescription: option.incomeDescription,
                  },
                  index,
                  "incomeNatureId"
                );
              }}
              error={!!errors[`[${index}].incomeNatureId`]}
              errorText={errors[`[${index}].incomeNatureId`]}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="date"
              title="Data do Crédito/Pagamento"
              value={item.dateOfPayment}
              onChange={(date) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    dateOfPayment: date,
                  },
                  index,
                  "dateOfPayment"
                );
              }}
              error={!!errors[`[${index}].dateOfPayment`] || isDateWrong}
              errorText={errors[`[${index}].dateOfPayment`] || isDateWrong}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              title="Valor da Base Agregada"
              value={item.amountOperation}
              disabled
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              title="Valor da Retenção Agregada"
              value={item.amountAggregate}
              onChange={(e) => {
                onChangeItem(
                  {
                    ...nfseData[index],
                    amountAggregate: handleMoney(e.target.value),
                  },
                  index,
                  "amountAggregate"
                );
              }}
              error={!!errors[`[${index}].amountAggregate`] || isAmountAggregateWrongText}
              errorText={errors[`[${index}].amountAggregate`] || isAmountAggregateWrongText}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <Input
              type="amount"
              prefix="%"
              title="Alíquota"
              value={item.aliquotIR}
              error={isAliquotWrongText}
              errorText={isAliquotWrongText}
              disabled
            />
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2 }}>
          <Subtitle onClick={() => setShow(!show)}>
            <KeyboardArrowDown
              style={{
                fontSize: "2.4rem",
                rotate: show ? "0deg" : "-90deg",
                transition: "200ms",
                color: "#A7A7A7",
                marginBlock: 2,
              }}
            />
            Valores de IRPJ, CSLL, PIS/Pasep e COFINS
          </Subtitle>

          {show && (
            <Grid container spacing={2}>
              <Grid item xs={4} lg={3}>
                <Input
                  type="amount"
                  title="Base de cálculo do IRPJ"
                  value={item?.bcIR}
                  onChange={(e) => {
                    onChangeItem(
                      {
                        ...nfseData[index],
                        bcIR: e.target.value,
                      },
                      index,
                      "bcIR"
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do IRPJ"
                  value={item.amountIR}
                  percentageValue={amountIRPJpercentage} //amountIRPJpercentage
                  onChange={(e) => {
                    onChangeItem(
                      {
                        ...nfseData[index],
                        amountIR: handleMoney(e.target.value),
                      },
                      index
                    );
                  }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do CSLL"
                  value={item.amountCSLL}
                  percentageValue={amountCSLLpercentage} //amountCSLLpercentage
                  onChange={(e) => {
                    onChangeItem(
                      {
                        ...nfseData[index],
                        amountCSLL: handleMoney(e.target.value),
                      },
                      index
                    );
                  }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do PIS/Pasep"
                  value={item.amountPIS}
                  percentageValue={amountPISpercentage} //amountPISpercentage
                  onChange={(e) => {
                    onChangeItem(
                      {
                        ...nfseData[index],
                        amountPIS: handleMoney(e.target.value),
                      },
                      index
                    );
                  }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção do COFINS"
                  value={item.amountCOFINS}
                  percentageValue={amountCONFINSpercentage} //amountCONFINSpercentage
                  onChange={(e) => {
                    onChangeItem(
                      {
                        ...nfseData[index],
                        amountCOFINS: handleMoney(e.target.value),
                      },
                      index
                    );
                  }}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={4} lg={3}>
                <Input
                  type="amount"
                  title="Valor da Retenção não efetuada"
                  value={item.amountNotRetained}
                  onChange={(e) => {
                    onChangeItem(
                      {
                        ...nfseData[index],
                        amountNotRetained: handleMoney(e.target.value),
                      },
                      index
                    );
                  }}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default IRPJ;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginBlock: 1,
  },
};
