import styled from "styled-components";

export const Container = styled.div`
  height: 200px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: max(10px, 1vw);

  h3,
  p {
    font-family: "Montserrat", sans-serif;
    text-align: center;
  }

  h3 {
    font-size: max(20px, 1.5vw);
    margin-top: max(15px, 1vw);
  }

  p {
    font-size: max(16px, 1.2vw);
    padding: 0;
    min-height: 150px;
    max-height: 150px;

    @media screen and (min-width: 600px) {
      padding: 0 18%;
    }

    @media screen and (max-width: 768px) {
      min-height: auto;
      max-height: auto;
      // margin-bottom: 1rem;
    }
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: auto;
  align-items: center;

  .line {
    height: 2px;
    background: #dedede;
  }

  > img {
    margin: 10px;
    height: 70px;
    object-fit: contain;
    grid-row: 1;
    grid-column: 2;
  }
`;
