import styled from "styled-components";
import { Form as FormikForm } from "formik";
import { IconButton as MuiIconButton } from "@mui/material";

export const Container = styled.div`
  background-color: #fff;

  border-radius: 10px;
  height: calc(100% - 40px - 12px); /** 40: h2 height // 12px: margin-top */
  margin-top: 12px;
  overflow-y: auto;
  padding: 20px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-row-gap: 20px;

  /* preserve the padding bottom */
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
  }

  .answersWrapper {
    background: ${(props) => "#e1e1e1"};
    padding: 20px;
    border-radius: 4px;
  }
`;

export const IconButton = styled(MuiIconButton)`
  &.MuiIconButton-root {
    color: ${(props) => "#383f48"};
  }
`;

export const RemoveButton = styled.div`
  position: absolute;
  right: -20px;
  top: -20px;

  .MuiIconButton-root {
    color: ${(props) => "#fc4b6c"};
    .MuiIconButton-label {
      padding: 2px;
      background-color: ${(props) => "#e1e1e1"};
      /* background-color: ${(props) => "#dfdfdf"}; */
    }
  }
`;

export const Form = styled(FormikForm)`
  display: grid;
  grid-gap: 16px;
`;
