import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeaderWithFilter from "~/presentation/views/Admin/components/PageListHeaderWithFilter";
//functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
//utils
import typeTax from "~/presentation/utils/typeTax";
import { Filters } from "~/presentation/views/Admin/Answer/Table/Filters";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";

const AnswerTable = ({ history, answerUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [answers, setAnswers] = useState([]);
  //const [categories, setCategories] = useState([]);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const fetchData = async (filters) => {
    openBackdrop(true, "Carregando dados da tabela");

    const { data = [] } = await answerUseCases.listAnswersSummary(filters);
    // const apiCategories = await companyUseCases.getCompanyCategories();
    // setCategories(apiCategories);

    const userIsSuperAdmin = context.haveSuperAdminAccess();

    data.forEach((answer) => {
      if (userIsSuperAdmin) {
        answer.actions = (
          <TableActions
            handleDelete={() => deleteDialog(answer.id)}
            handleEdit={() => handleEdit(answer.id)}
            handleCopy={() => handleCopyToNewAnswer(answer)}
          />
        );
      } else {
        answer.actions = (
          <TableActions
            handleEdit={() => handleEdit(answer.id)}
            handleCopy={() => handleCopyToNewAnswer(answer)}
          />
        );
      }
    });
    setAnswers(data);
    openBackdrop(false);
  };

  const handleCopyToNewAnswer = (answer) => {
    let newCopiedAnswer = {
      id: answer.id,
      gtAnswerName: answer.gtAnswerName,
      description: answer.description,
      typeTax: answer.typeTax,
      isSimple: answer.isSimple,
      companyTypes: answer.companyTypes,
      categories: answer.categories,
      cityId: answer.cityId,
      cnaes: answer.cnaes,
      gtAnswerItems: answer.gtAnswerItems,
    };
    history.push("/admin/respostas/cadastrar", { answer: newCopiedAnswer });
  };

  const columns = [
    {
      name: "gtAnswerName",
      label: "Nome",
    },
    {
      name: "description",
      label: "Descrição",
      sortOrder: {
        direction: "asc",
        compare: (a, b) => parseFloat(a) - parseFloat(b),
      },
    },
    {
      name: "typeTax",
      label: "Taxa",
      options: {
        filter: false,
        customBodyRender: (value) => {
          let type = typeTax.filter((item) => item.value == value)[0];
          return type.label;
        },
      },
    },
    {
      name: "isSimple",
      label: "Simples Nacional",
      options: {
        filter: false,
        customBodyRender: (value) => (value === true ? "Sim" : "Não"),
      },
    },
    { name: "actions", label: "Ações", options: { sortable: "false" } },
  ];

  const handleEdit = (id) => {
    history.push(`/admin/respostas/editar/${id}`);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo resposta");
    try {
      await answerUseCases.deleteAnswer(id);
      await fetchData({ itemContent: "" });
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir resposta: Missing endpoint.",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta resposta?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData({ itemContent: "" });
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };
  const handleAdd = () => history.push("/admin/respostas/cadastrar");

  return (
    <Container maxWidth="100%">
      <PageListHeaderWithFilter
        title="Resposta"
        titleAccess="Nova resposta"
        searchFieldRef={searchFieldRef}
        handleAdd={handleAdd}
        handleSearch={handleSearch}
        doSearchWhenUserClickInTheIcon
        onClickSearchIcon={(value) => handleSearch(value.itemContent)}
        filterComp={<Filters onFilter={fetchData} />}
      />
      {answers ? (
        <Table searchRef={hiddenInputRef} columns={columns} data={answers} title="Respostas" />
      ) : (
        <div />
      )}
    </Container>
  );
};

export default AnswerTable;
