import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { useHistory, useParams } from "react-router-dom";
import AnnotationForm from "../Form";
import { handleErrors } from "~/presentation/utils/errorsHandling";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

const AnnotationEdit = ({ annotationUseCases, cnaesUseCases, termUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);

  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const id = useParams().id;
  const history = useHistory();

  const [initialValues, setInitialValues] = useState();
  const [cnaes, setCnaes] = useState([]);
  const [terms, setTerms] = useState([]);

  useEffect(() => {
    let isSubscribed = true;
    const fetchData = async () => {
      try {
        openBackdrop(true, "Carregando dados");
        const { data: annotation } = await annotationUseCases.getAnnotation(id);
        if (isSubscribed) {
          setInitialValues(annotation);
        }
        const [cnaesListResponse, termsListResponse] = await Promise.all([
          cnaesUseCases.listCnaes(),
          termUseCases.getAll(),
        ]);

        if (isSubscribed) {
          setCnaes(cnaesListResponse.data);
          setTerms(termsListResponse);
        }
      } catch (error) {
        setFeedbackSnackbar({
          isOpen: true,
          message: "Anotação não encontrada!",
          type: "warning",
        });
      } finally {
        if (isSubscribed) {
          openBackdrop(false);
        }
      }
    };
    fetchData();
    return () => {
      isSubscribed = false;
    };
  }, []);

  async function handleSubmit(values) {
    openBackdrop(true, "Editando resposta");
    const { data } = await annotationUseCases.updateAnnotation(values);
    const errors = handleErrors(data);
    openBackdrop(false);
    if (!errors.length) {
      history.push("/admin/anotacoes");
      setFeedbackSnackbar({
        isOpen: true,
        message: "Anotação atualizada com sucesso!",
        type: "success",
      });
    } else {
      setFeedbackSnackbar({
        isOpen: true,
        message: errors.join(", "),
        type: "error",
      });
    }
  }

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Editar Anotação" backLink="/admin/anotacoes" />
      {initialValues ? (
        <AnnotationForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          cnaes={cnaes}
          terms={terms}
        />
      ) : (
        <div />
      )}
    </Container>
  );
};

export default AnnotationEdit;
