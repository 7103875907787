import styled from "styled-components";
import { Form as FormikForm } from "formik";
import { FormLabel as MuiFormLabel, Checkbox as MuiCheckbox } from "@mui/material";

export const Container = styled.div`
  background-color: #fff;

  border-radius: 10px;
  height: calc(100% - 40px - 12px); /** 40: h2 height // 12px: margin-top */
  margin-top: 12px;
  overflow-y: auto;
  padding: 20px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-row-gap: 20px;

  /* preserve the padding bottom */
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
  }
`;

export const Form = styled(FormikForm)`
  display: grid;
  grid-gap: 16px;
`;

export const FormLabel = styled(MuiFormLabel)`
  &.MuiFormLabel-root,
  &.MuiFormLabel-root.Mui-focused {
    color: ${(props) => "#383f48"};
  }
`;

export const Checkbox = styled(MuiCheckbox)`
  &.MuiCheckbox-root,
  &.MuiCheckbox-root.Mui-checked {
    color: ${(props) => "#383f48"};
  }
`;

export const SubContainer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;

  &.expand-true {
    max-height: 400px;
  }

  .wrapper {
    background: ${(props) => "#e1e1e1"};
    padding: 20px;
    /* margin: 6px 0 12px; */
  }
`;
