import React from "react";
import { QuestionLabel } from "~/presentation/views/GTFacil/Simulator/components/QuestionLabel";
import Checkbox from "~/presentation/components/Checkbox";
import { Container, CheckboxesWrapper, ErrorMessage } from "./styles";
import { FormControlLabel } from "@mui/material";

const defaultCheckbox = [{ value: false, label: "Teste checkbox" }];

export const CheckboxQuestion = ({
  name,
  text,
  excerpt,
  checkboxes = defaultCheckbox,
  onChange,
}) => {
  return (
    <Container>
      <QuestionLabel text={text} excerpt={excerpt} />
      <CheckboxesWrapper>
        {checkboxes.map(({ label, value, name }) => (
          <FormControlLabel
            key={`checkbox-${label}-${value}`}
            control={
              <Checkbox label={label} onChange={() => onChange(!value, name)} checked={value} />
            }
            label={label}
          />
        ))}
        {name && <ErrorMessage name={name} />}
      </CheckboxesWrapper>
    </Container>
  );
};
