import React from "react";
import Footer from "~/presentation/components/Footer";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import { ContactForm } from "~/presentation/views/Client/ContactUs/components/ContactForm";
import { Call as CallIcon, MailOutline as MailOutlineIcon } from "@mui/icons-material/";
import { Container, FirstContent, SecondContent, AddressAndNewsLetterSubscribe } from "./styles";
import { NewsletterSubscribeForm } from "../components/NewsletterSubscribeForm";

const ContactUs = ({ contactUseCases }) => {
  const handleSubmit = async (values) => {
    await contactUseCases.contactUsForm(values);
  };

  const onSubscribe = async (value) => {
    await contactUseCases.newsletterSubscribe(value);
  };

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {() => (
        <>
          <Container>
            <FirstContent>
              <div className="line" />
              <div className="wrapper">
                <h1>
                  Tem alguma <b>questão</b>?
                </h1>
                <p>
                  Mantenha contato conosco através do
                  <b>&nbsp;preenchimento do formulário&nbsp;</b>
                  ao lado ou pelos seguintes <b>&nbsp;canais de contato</b>:
                </p>
                <div>
                  <MailOutlineIcon fontSize="small" />
                  contato@gestaotributaria.com.br
                </div>
                <div>
                  <CallIcon fontSize="small" />
                  &nbsp;0800 888 1482 <b>&nbsp;ou&nbsp;</b> (71) 3342-2822
                </div>
              </div>
            </FirstContent>
            <SecondContent>
              <ContactForm onSubmit={handleSubmit} />
            </SecondContent>
          </Container>
          <AddressAndNewsLetterSubscribe>
            <div className="card">
              <div className="address">
                <h3>Contato</h3>
                <p>
                  Rua Frederico Simões, 125 ; Sala 401 ; Edf. Liz Empresarial, caminho das Arvores,
                  Salvador, BA CEP: 41820-774.
                </p>
              </div>
              {/* <div className="newsletter">
                <NewsletterSubscribeForm onSubscribe={onSubscribe} />
              </div> */}
            </div>
          </AddressAndNewsLetterSubscribe>
          <Footer />
        </>
      )}
    </HomeLayout>
  );
};

export default ContactUs;
