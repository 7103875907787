import React, { useState, useEffect, useRef, useContext } from "react";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { withRouter } from "react-router-dom";
import {
  MailOutline as MailOutlineIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
} from "@mui/icons-material";
import {
  IconButton,
  MenuList,
  Avatar,
  Box,
  MenuItem,
  Typography as Text,
  Link,
} from "@mui/material";
import windowSize from "react-window-size";
import Menu from "~/presentation/components/Menu";
import { ApiCompanyUseCases } from "~/data/usecases/company/apiCompany";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import AppContext from "~/presentation/AppContext";
import CountDownToken from "../CountDownToken/CountDownToken";

const ReinfToolbar = ({ history }) => {
  const { handleLogout } = useContext(AppContext);
  const user = JSON.parse(localStorage.getItem("GT_user"));
  const ReinfAuthorizedCheck = JSON.parse(localStorage.getItem("GT_ReinfAcess"));
  const companyUseCases = new ApiCompanyUseCases(makeHttpClient());
  const [UserCompany, setUserCompany] = useState(null);
  const profileBtnRef = useRef(null);
  const CompanyUser = user.companyId;
  const userFirstLetter = localStorage.GT_user
    ? JSON.parse(localStorage.GT_user).name.charAt(0)
    : "";

  useEffect(() => {
    const getCompany = async () => {
      const result = await companyUseCases.getCompanyById(CompanyUser);
      setUserCompany(result);
    };

    getCompany();
  }, [CompanyUser]);

  useEffect(() => {
    if (ReinfAuthorizedCheck === false) {
      history.push({
        pathname: "/cliente",
        state: { showModal: true },
      });
    }
  }, [UserCompany, history]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.textContainar}>
        <Text sx={styles.text}>Usuário | {user?.name}</Text>
        <Text sx={styles.text}>
          {UserCompany?.name} | {UserCompany?.cnpj ? formatCpfCnpj(UserCompany.cnpj) : ""}
        </Text>
      </Box>
      <Box sx={styles.iconsContainer}>
        <Link
          underline="none"
          sx={{ ...styles.text, ...styles.link }}
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/cliente/ajuda")}
        >
          Instruções de uso do módulo
        </Link>
        <CountDownToken />
        <Menu
          anchorRef={profileBtnRef}
          anchorButton={(props) => (
            <IconButton {...props}>
              <Avatar color={"#254C9F"} sx={styles.avatar}>
                {userFirstLetter}
              </Avatar>
            </IconButton>
          )}
        >
          {(props) => (
            <MenuList id="profile-options-list" {...props}>
              <MenuItem onClick={() => history.push("/cliente")}>Ir para Início</MenuItem>
              <MenuItem onClick={handleLogout}>
                Sair
                <PowerSettingsNewIcon fontSize="small" />
              </MenuItem>
            </MenuList>
          )}
        </Menu>
      </Box>
    </Box>
  );
};

export default withRouter(windowSize(ReinfToolbar));

const styles = {
  container: {
    height: "100%",
    width: "100%",
    maxHeight: "50px",
    display: "grid",
    gridTemplateColumns: "auto auto",
    alignItems: "center",
    gap: "6px",
  },
  iconsContainer: {
    display: "flex",
    justifySelf: " flex-end",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  avatar: {
    height: "23px",
    width: "23px",
  },
  textContainar: {
    width: "150px",
    "@media (min-width: 400px)": {
      width: "100%",
    },
    "@media (min-width: 600px)": {
      fontSize: 12,
      width: "150px",
    },
    "@media (min-width: 800px)": {
      width: "300px",
    },
    "@media (min-width: 900px)": {
      width: "100%",
    },
  },
  text: {
    fontFamily: "Montserrat",
    color: "#fff",
    fontSize: 8,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    "@media (min-width: 600px)": {
      fontSize: 12,
    },
  },
  link: {
    textAlign: "right",
    width: "10ch",
    "@media (min-width: 600px)": {
      fontSize: 12,
    },
    "@media (min-width: 900px)": {
      width: "100%",
    },
  },
};
