import React, {useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Collapse,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

const defaultStyles = {
  container: {
    padding: 1.5,
    maxWidth: "100%",
    display: "flex",
    alignItems: "start",
  },
  box: {
    backgroundColor: "#f8d7da",
    border: "1px solid #f16976",
    borderRadius: 1,
    padding: 1,
  },
  icon: {
    marginTop: 0.5
  },
  content: {
    marginLeft: 1.5
  },
  button: {
    marginTop: 1,

    "&:hover": {
      backgroundColor: "inherit",
      textDecoration: "underline",
    }
  },
  details: {
    marginTop: 1.5,
    padding: 1.5,
    maxHeight: 150,
    backgroundColor: "white",
    borderRadius: 1,
    overflowY: "auto",
  }
};

const DetailedError = ({
  errorContent,
  showAsModal = false,
  open = false,
  onClose = () => {
  },
  customStyles = {}
}) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleToggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const styles = {
    container: customStyles.container || defaultStyles.container,
    box: customStyles.box || defaultStyles.box,
    icon: customStyles.icon || defaultStyles.icon,
    content: customStyles.content || defaultStyles.content,
    button: customStyles.button || defaultStyles.button,
    details: customStyles.details || defaultStyles.details,
  };

  if (typeof errorContent?.details === "string") {
    errorContent.details = [errorContent?.details || "Nenhum detalhe disponível"];
  }

  const ErrorContent = () => (
    <Box sx={styles.container}>
      <ErrorIcon color="error" sx={styles.icon}/>
      <Box sx={styles.content}>
        <Typography variant="h6">{errorContent?.title || "Erro"}</Typography>
        <Typography variant="body1">{errorContent?.message || "Ocorreu um erro."}</Typography>
        <Button onClick={handleToggleDetails} sx={styles.button}>
          {showDetails ? "Esconder detalhes" : "Mostrar detalhes"}
        </Button>
        <Collapse in={showDetails}>
          <Box sx={styles.details}>
            {errorContent?.details?.map((detail, index) => (
              <Typography key={index} variant="body2">
                {detail}
              </Typography>
            ))}
          </Box>
        </Collapse>
      </Box>
    </Box>
  );

  if (showAsModal) {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Erro</DialogTitle>
        <DialogContent>
          <ErrorContent/>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">Fechar</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Box sx={styles.box}>
      <ErrorContent/>
    </Box>
  );
};

export default DetailedError;
