import styled from "styled-components";

export const Arrow = styled.div`
  position: absolute;
  margin-top: -6px;
  right: 10%;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fefefe;
`;

export const Anchor = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
