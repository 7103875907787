import React, { useContext, useRef, useState } from "react";
import { Container } from "~/presentation/layouts/BlogLayout/styles";
import AppContext from "~/presentation/AppContext";
//components
import Navbar from "~/presentation/components/Navbar/Navbar";
import MobileNavigation from "~/presentation/views/LandingPage/components/MobileNavigation/MobileNavigation";
import Modal from "~/presentation/views/LandingPage/components/Modal";
// hooks
import useMobile from "~/presentation/hooks/useMobile";
import LoginBox from "~/presentation/views/LandingPage/Home/LoginBox";
import { DialogContext } from "~/presentation/providers/DialogProvider";

const BlogLayout = ({ navbarItems, loggedNavbarItems = navbarItems, noNavbarSpace, children }) => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [modalState, setModalState] = useState({
    open: false,
    component: null,
  });
  const [mobile] = useMobile(960);
  const homeContainerRef = useRef();
  const { isLogged } = useContext(AppContext);
  const { openDialogBox } = useContext(DialogContext);

  const toggleMobileDrawer = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const openModal = (component) => {
    setModalState({ open: true, component });
  };

  const closeModal = () => {
    setModalState({ ...modalState, open: false });
  };

  const resetModal = () => {
    setModalState({ open: false, component: null });
  };

  const getNavbarItems = () => {
    return isLogged() ? loggedNavbarItems : navbarItems;
  };

  const renderLoginBox = () => (
    <LoginBox
      handleClose={() => openDialogBox({ ...openDialogBox, open: false })}
      history={history}
    />
  );

  const openLoginDialog = () => {
    openDialogBox({
      disableScrollLock: true,
      customComponent: () => renderLoginBox(),
    });
  };

  return (
    <>
      <Modal
        open={modalState.open}
        closeModal={closeModal}
        resetModal={resetModal}
        component={modalState.component}
      />
      {mobile && (
        <MobileNavigation
          open={mobileDrawerOpen}
          openLoginDialog={openLoginDialog}
          toggleMobileDrawer={toggleMobileDrawer}
          navbarItems={getNavbarItems()}
          isLogged={isLogged()}
        />
      )}
      <Container
        mobile={mobile}
        ref={homeContainerRef}
        onClick={() => mobileDrawerOpen && toggleMobileDrawer()}
        className={mobileDrawerOpen && "drawerOpen"}
      >
        <Navbar
          parentRef={homeContainerRef}
          toggleMobileDrawer={toggleMobileDrawer}
          openLoginDialog={openLoginDialog}
          navbarItems={getNavbarItems()}
          mobile={mobile}
          isLogged={isLogged()}
        />
        {!noNavbarSpace && <div className="navbar-space" id="top" />}
        {children({ mobile, openModal })}
      </Container>
    </>
  );
};

export default BlogLayout;
