import React, { useContext, useEffect, useState } from "react";
import { Subtitle, Title } from "../../../styles";
import { KeyboardArrowDown } from "@mui/icons-material";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { Box } from "@mui/material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { Grid } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import handleMoney from "../../../../HandleMoney/handleMoney";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import { Skeleton } from "@mui/material";
import { DialogContext } from "~/presentation/providers/DialogProvider";

const INSS = ({
  values,
  serviceClassificationOptions,
  errors,
  isBcINSSWrong,
  inputDisabled,
  nfseIndex,
  handleChangeFieldValue,
  handleChangeINSSfield,
}) => {
  const { openDialogBox } = useContext(DialogContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [showItem, setShowItem] = useState({});

  const newItemsNFseObject = {
    id: 0,
    serviceClassificationId: 0,
    serviceClassification: "",
    bc: 0,
    amount: 0,
    aliquot: 0,
    principalRententionAmount: 0,
    subcontractorAmount: 0,
    annotation: "",
    serviceAmount15: 0,
    serviceAmount20: 0,
    serviceAmount25: 0,
    additionalRetentionAmount: 0,
    additionalNotRetentionAmount: 0,
  };

  const addMore = () => {
    values?.itemsNFse?.length < 9
      ? handleChangeFieldValue({
          newItem: { itemsNFse: [...values.itemsNFse, newItemsNFseObject] },
          index: nfseIndex,
        })
      : setFeedbackSnackbar({
          isOpen: true,
          message: "9 items é o máximo!",
          type: "info",
        });
  };

  const deleteItem = (index) => {
    const filteredItemINSS = values?.itemsNFse.filter((item, i) => i !== index);
    handleChangeFieldValue({
      newItem: { itemsNFse: filteredItemINSS },
      index: nfseIndex,
    });
  };

  const handleDelete = (item, index) => {
    if (values?.itemsNFse?.length === 1) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "No mínimo tem de haver um item.",
        type: "info",
      });
    } else {
      if (JSON.stringify(item) === JSON.stringify(newItemsNFseObject)) {
        deleteItem(index);
      } else {
        openDialogBox({
          message: `Deseja mesmo excluir este item?`,
          callback: () => {
            deleteItem(index);
          },
        });
      }
    }
  };

  const handleClickItem = (index) => {
    setShowItem((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Inverte o estado show do item clicado
    }));
  };

  const isBcINSSWrongText =
    isBcINSSWrong &&
    "O valor da soma das bases de cálculo não pode ser superior ao Valor da Operação.";

  return (
    <Box sx={styles.container}>
      <Title>Retenção de INSS</Title>

      <Box
        sx={{
          marginBottom: 2,
          paddingInline: 4,
          paddingTop: 2,
          paddingBottom: 2,
          background: "#D9D9D9",
          borderRadius: 4,
        }}
      >
        <Box sx={styles.geralValues.container}>
          <Box sx={styles.geralValues.box}>
            <p style={styles.geralValues.text}>Valor Principal da Base INSS</p>
            <span style={styles.geralValues.value}>{formatNumberToRealString(values?.bcINSS)}</span>
          </Box>
          <Box sx={styles.geralValues.box}>
            <p style={styles.geralValues.text}>Valor Principal da Retenção INSS</p>
            <span style={styles.geralValues.value}>
              {formatNumberToRealString(values?.amountINSS)}
            </span>
          </Box>
          <Box sx={styles.geralValues.box}>
            <p style={styles.geralValues.text}>Alíquota Principal do INSS</p>
            <span style={styles.geralValues.value}>% {values?.aliquotINSS?.toFixed(2)}</span>
          </Box>
        </Box>

        {values?.itemsNFse?.length > 0 ? (
          values?.itemsNFse?.map((data, index) => {
            const isBcOrAmountPositive = data.bc > 0 || data.amount > 0;
            const isAliquotValid = data?.aliquot?.toFixed(2) == values?.aliquotINSS?.toFixed(2);
            const isAliquotWrong =
              isBcOrAmountPositive &&
              !isAliquotValid &&
              `Retenção/Base deve dar ${values?.aliquotINSS}. Veja campo CPRB.`;
            const isServiceClassificationWrong =
              isBcOrAmountPositive && data.serviceClassificationId === 0 && "Campo Obrigatório!";
            return (
              <Box
                sx={{
                  marginBlock: 2,
                  padding: 2,
                  background: "#C9C9C9",
                  borderRadius: 4,
                }}
                key={index}
              >
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Input
                      type="select"
                      title="Tipo de Serviço"
                      placeholder="Selecione um tipo de serviço"
                      options={serviceClassificationOptions}
                      getOptionLabel={(option) => `${option.id} - ${option.description}`}
                      value={serviceClassificationOptions.find(
                        (item) => item.id === data.serviceClassificationId
                      )}
                      onChange={(option) => {
                        const { id, description } = option;
                        handleChangeINSSfield({
                          newItem: {
                            ...data,
                            serviceClassificationId: id,
                            serviceClassification: description,
                          },
                          parentIndex: nfseIndex,
                          nestedIndex: index,
                          field: "serviceClassificationId",
                        });
                      }}
                      error={
                        !!errors[`[${nfseIndex}].itemsNFse[${index}].serviceClassificationId`] ||
                        isServiceClassificationWrong
                      }
                      errorText={
                        errors[`[${nfseIndex}].itemsNFse[${index}].serviceClassificationId`] ||
                        isServiceClassificationWrong
                      }
                      disabled={inputDisabled === "R2010"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Input
                      title="Base de Cálculo"
                      type="amount"
                      value={data.bc}
                      onChange={(e) => {
                        const value = handleMoney(e.target.value);
                        const positive = value > 0 && data.amount > 0;
                        const aliquot = positive ? (data.amount / value) * 100 : 0;
                        handleChangeINSSfield({
                          newItem: {
                            ...data,
                            bc: value,
                            aliquot: aliquot,
                          },
                          parentIndex: nfseIndex,
                          nestedIndex: index,
                          field: "bc",
                        });
                      }}
                      error={!!errors[`[${nfseIndex}].itemsNFse[${index}].bc`] || isBcINSSWrongText}
                      errorText={
                        errors[`[${nfseIndex}].itemsNFse[${index}].bc`] || isBcINSSWrongText
                      }
                      disabled={inputDisabled === "R2010"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Input
                      title="Retenção"
                      type="amount"
                      value={data.amount}
                      onChange={(e) => {
                        const value = handleMoney(e.target.value);
                        const positive = value > 0 && data.bc > 0;
                        const aliquot = positive ? (value / data.bc) * 100 : 0;
                        handleChangeINSSfield({
                          newItem: {
                            ...data,
                            amount: value,
                            aliquot: aliquot,
                          },
                          parentIndex: nfseIndex,
                          nestedIndex: index,
                          field: "amount",
                        });
                      }}
                      error={!!errors[`[${nfseIndex}].itemsNFse[${index}].amount`]}
                      errorText={errors[`[${nfseIndex}].itemsNFse[${index}].amount`]}
                      disabled={inputDisabled === "R2010"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Input
                      title="Alíquota"
                      type="amount"
                      prefix="%"
                      value={data.aliquot}
                      error={
                        !!errors[`[${nfseIndex}].itemsNFse[${index}].aliquot`] || isAliquotWrong
                      }
                      errorText={
                        errors[`[${nfseIndex}].itemsNFse[${index}].aliquot`] || isAliquotWrong
                      }
                      disabled
                    />
                  </Grid>
                </Grid>

                <Box sx={{ marginTop: 1 }}>
                  <Subtitle onClick={() => handleClickItem(index)}>
                    <KeyboardArrowDown
                      style={{
                        fontSize: "2.4rem",
                        rotate: showItem[index] ? "0deg" : "-90deg",
                        transition: "200ms",
                        color: "#A7A7A7",
                        marginBlock: 2,
                      }}
                    />
                    Outras informações
                  </Subtitle>
                  {showItem[index] && (
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={4} lg={3}>
                          <Box>
                            <Input
                              title="Valor da Ret. Principal não efetuada"
                              type="amount"
                              value={data.principalRententionAmount}
                              onChange={(e) => {
                                const value = handleMoney(e.target.value);
                                handleChangeINSSfield({
                                  newItem: {
                                    ...data,
                                    principalRententionAmount: value,
                                  },
                                  parentIndex: nfseIndex,
                                  nestedIndex: index,
                                  field: "principalRententionAmount",
                                });
                              }}
                              disabled={inputDisabled === "R2010"}
                            />
                          </Box>
                          <Box>
                            <Input
                              title="Valor da Retenção de Subcontratação"
                              type="amount"
                              value={data.subcontractorAmount}
                              onChange={(e) => {
                                const value = handleMoney(e.target.value);
                                handleChangeINSSfield({
                                  newItem: {
                                    ...data,
                                    subcontractorAmount: value,
                                  },
                                  parentIndex: nfseIndex,
                                  nestedIndex: index,
                                  field: "subcontractorAmount",
                                });
                              }}
                              disabled={inputDisabled === "R2010"}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={8} lg={9}>
                          <Input
                            title="Anotações"
                            type="textarea"
                            styles={{ height: 42 }}
                            minRows={3}
                            maxlength="500"
                            value={data.annotation}
                            onChange={(e) => {
                              const value = e.target.value;
                              handleChangeINSSfield({
                                newItem: {
                                  ...data,
                                  annotation: value,
                                },
                                parentIndex: nfseIndex,
                                nestedIndex: index,
                                field: "annotation",
                              });
                            }}
                            disabled={inputDisabled === "R2010"}
                          />
                        </Grid>
                      </Grid>

                      <Subtitle
                        style={{
                          margin: "20px 0px 10px 0px",
                          cursor: "auto",
                          userSelect: "none",
                        }}
                      >
                        Inserir valor da retenção adicional (2%, 3% ou 4%)
                      </Subtitle>
                      <Grid container spacing={2}>
                        <Grid item xs={4} lg={3}>
                          <Input
                            title="Valor de 15 anos de contribuição"
                            type="amount"
                            value={data.serviceAmount15}
                            onChange={(e) => {
                              const value = handleMoney(e.target.value);
                              const total =
                                value * 0.04 +
                                data.serviceAmount20 * 0.03 +
                                data.serviceAmount25 * 0.02;
                              handleChangeINSSfield({
                                newItem: {
                                  ...data,
                                  serviceAmount15: value,
                                  additionalRetentionAmount: total,
                                },
                                parentIndex: nfseIndex,
                                nestedIndex: index,
                                field: "serviceAmount15",
                              });
                            }}
                            disabled={inputDisabled === "R2010"}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Input
                            title="Valor de 20 anos de contribuição"
                            type="amount"
                            value={data.serviceAmount20}
                            onChange={(e) => {
                              const value = handleMoney(e.target.value);
                              const total =
                                data.serviceAmount15 * 0.04 +
                                value * 0.03 +
                                data.serviceAmount25 * 0.02;
                              handleChangeINSSfield({
                                newItem: {
                                  ...data,
                                  serviceAmount20: value,
                                  additionalRetentionAmount: total,
                                },
                                parentIndex: nfseIndex,
                                nestedIndex: index,
                                field: "serviceAmount20",
                              });
                            }}
                            disabled={inputDisabled === "R2010"}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Input
                            title="Valor de 25 anos de contribuição"
                            type="amount"
                            value={data.serviceAmount25}
                            onChange={(e) => {
                              const value = handleMoney(e.target.value);
                              const total =
                                data.serviceAmount15 * 0.04 +
                                data.serviceAmount20 * 0.03 +
                                value * 0.02;
                              handleChangeINSSfield({
                                newItem: {
                                  ...data,
                                  serviceAmount25: value,
                                  additionalRetentionAmount: total,
                                },
                                parentIndex: nfseIndex,
                                nestedIndex: index,
                                field: "serviceAmount25",
                              });
                            }}
                            disabled={inputDisabled === "R2010"}
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Input
                            title="Valor da Retenção Adicional"
                            type="amount"
                            value={data.additionalRetentionAmount}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={4} lg={3}>
                          <Input
                            title="Valor da Ret. Adicional não efetuada"
                            type="amount"
                            value={data.additionalNotRetentionAmount}
                            onChange={(e) => {
                              const value = handleMoney(e.target.value);
                              handleChangeINSSfield({
                                newItem: {
                                  ...data,
                                  additionalNotRetentionAmount: value,
                                },
                                parentIndex: nfseIndex,
                                nestedIndex: index,
                                field: "additionalNotRetentionAmount",
                              });
                            }}
                            disabled={inputDisabled === "R2010"}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginLeft: "auto",
                      marginTop: 10,
                      gap: 10,
                    }}
                  >
                    <RButton
                      variant="outlined"
                      sx={{ width: "120px", borderRadius: "8px", boxShadow: 0 }}
                      onClick={() => handleDelete(data, index)}
                    >
                      REMOVER
                    </RButton>
                    <RButton
                      sx={{ width: "120px", borderRadius: "8px", boxShadow: 0 }}
                      onClick={addMore}
                    >
                      ADICIONAR
                    </RButton>
                  </Box>
                </Box>
              </Box>
            );
          })
        ) : (
          <Skeleton
            sx={{
              marginBlock: 2,
              padding: 2,
              background: "#C9C9C9",
              borderRadius: 4,
            }}
            variant="rounded"
            width="100%"
            height={100}
          />
        )}
      </Box>
    </Box>
  );
};

export default INSS;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: 2,
  },
  geralValues: {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: 2,
      marginBottom: 3,
      marginTop: 1,
    },
    box: {
      display: "flex",
      alignItems: "center",
      userSelect: "none",
      cursor: "auto",
      width: "100%",
      height: "100%",
      gap: 1,
    },
    text: {
      fontWeight: 700,
      fontSize: 14,
    },
    value: {
      paddingBlock: 2,
      paddingInline: 6,
      width: "50%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "none",
      borderRadius: 5,
      backgroundColor: "#C1C1C1",
      overflow: "hidden",
    },
  },
};
