import React from "react";
import { Formik, Field } from "formik";
import TextInput from "~/presentation/components/TextInput";
import Checkbox from "~/presentation/components/Checkbox";
import ErrorMessage from "~/presentation/components/ErrorMessage";
import { Container, Form } from "./styles";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import validationSchema from "./validationSchema";
import { formatValue, formatRealStringToNumber } from "~/presentation/utils/formatCurrency";
import { FormControlLabel } from "@mui/material";

export const PlanForm = ({ initialValues, handleSubmit }) => {
  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          const submitValues = {
            id: parseInt(values.id),
            name: values.name,
            isAllowReinf: values.isAllowReinf,
            isConsultISS: values.isConsultISS,
            isGenerateReport: values.isGenerateReport,
            isAllowTaxInformation: values.isAllowTaxInformation,
            consultLimit: values.consultLimit,
            taxInformationNumber: values.taxInformationNumber,
            numberOfUsers: values.numberOfUsers,
            isCalculationSimulator: values.isCalculationSimulator,
            isSpecificSubstitutionAnalysis: false,
            isCustom: values.isCustom,
            valor: !values.valor ? 0 : formatRealStringToNumber(values.valor),
          };

          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <h2>Plano Personalizado</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <div className="wrapper">
                  <div className="form-grid">
                    <div className="col-1_3">
                      <div className="mb_12">
                        <Field
                          name="name"
                          label="Nome"
                          as={TextInput}
                          type="text"
                          //onKeyUp={e => console.log(e)}
                        />
                        <ErrorMessage name="name" />
                      </div>
                      <div className="mb_12">
                        <Field
                          name="numberOfUsers"
                          type="number"
                          label="Número de Usuários"
                          as={TextInput}
                        />

                        <ErrorMessage name="numberOfUsers" />
                      </div>

                      <div className="mb_12">
                        <Field
                          name="valor"
                          type="text"
                          label="Preço"
                          as={TextInput}
                          onChange={(e) => {
                            const { value } = e.target;
                            setFieldValue("valor", formatValue(value));
                          }}
                        />

                        <ErrorMessage name="valor" />
                      </div>
                    </div>
                    <div className="col-3_5">
                      <div className="mb_12">
                        <Field
                          name="consultLimit"
                          type="number"
                          label="Limite de consultas"
                          as={TextInput}
                        />
                        <ErrorMessage name="consultLimit" />
                      </div>
                      <div className="mb_12">
                        <Field
                          name="taxInformationNumber"
                          type="number"
                          label="N° de informações fiscais"
                          as={TextInput}
                        />

                        <ErrorMessage name="taxInformationNumber" />
                      </div>
                    </div>
                    <div className="col-1_3">
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={true}
                              checked={values.isCustom}
                              onChange={() => setFieldValue("isCustom", !values.isCustom)}
                              name="isCustom"
                            />
                          }
                          label="Plano Personalizado"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isAllowTaxInformation}
                              onChange={() =>
                                setFieldValue(
                                  "isAllowTaxInformation",
                                  !values.isAllowTaxInformation
                                )
                              }
                              name="isAllowTaxInformation"
                            />
                          }
                          label="Permitir informações fiscais"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isGenerateReport}
                              onChange={() =>
                                setFieldValue("isGenerateReport", !values.isGenerateReport)
                              }
                              name="isGenerateReport"
                            />
                          }
                          label="Gerar Relatórios"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isCalculationSimulator}
                              onChange={() =>
                                setFieldValue(
                                  "isCalculationSimulator",
                                  !values.isCalculationSimulator
                                )
                              }
                              name="isCalculationSimulator"
                            />
                          }
                          label="Simulador de Cálculo"
                        />
                      </div>

                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isConsultISS}
                              onChange={() => setFieldValue("isConsultISS", !values.isConsultISS)}
                              name="isConsultISS"
                            />
                          }
                          label="Consulta ISS"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isAllowReinf}
                              onChange={() => setFieldValue("isAllowReinf", !values.isAllowReinf)}
                              name="isAllowReinf"
                            />
                          }
                          label="Possui REINF"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PrimaryButton className="submit-btn" type="submit">
              Salvar
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
