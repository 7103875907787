import React from "react";
import { ActionsWrapper } from "~/presentation/components/TableActions/styles";
// components
import { IconButton } from "@mui/material";
// icons
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AddIcon from "@mui/icons-material/Add";

const TableActions = ({
  user,
  handleDelete,
  handleEdit,
  handlePromoteOrDemote,
  handleCopy,
  handleSubscribe,
}) => (
  <ActionsWrapper>
    {handleDelete && (
      <IconButton onClick={handleDelete}>
        <DeleteOutlineIcon />
      </IconButton>
    )}
    {handleEdit && (
      <IconButton onClick={handleEdit}>
        <EditIcon />
      </IconButton>
    )}
    {handlePromoteOrDemote && (
      <IconButton onClick={handlePromoteOrDemote}>
        {user.role === "user" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      </IconButton>
    )}
    {handleCopy && (
      <IconButton onClick={handleCopy}>
        <FileCopyIcon />
      </IconButton>
    )}
    {handleSubscribe && (
      <IconButton onClick={handleSubscribe}>
        <AddIcon />
      </IconButton>
    )}
  </ActionsWrapper>
);

export default TableActions;
