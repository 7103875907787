import styled from "styled-components";
import BaseTextInput from "~/presentation/components/TextInputLight";
import BaseTextArea from "~/presentation/components/TextAreaLight";
import BaseSecondaryButton from "~/presentation/components/SecondaryButton";
import { FormikCustomErrorMessage } from "~/presentation/components/ErrorMessage";
import { darken } from "@mui/material";

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: max(24px, 1.6vw);

  > h3 {
    font-family: "Montserrat", sans-serif;
    color: #fafafa;
    font-size: max(24px, 1.6vw);
    width: min-content;
    white-space: nowrap;
    align-self: center;
    border-bottom: "1px solid #fafafa";
  }

  .form-grid {
    > div {
      display: flex;
      flex-flow: column;
      position: relative;
    }

    label {
      font-family: "Montserrat", sans-serif;
      color: #fafafa;
      font-size: max(16px, 1.2vw);
      margin: 0 0 max(6px, 0.4vw) max(10px, 0.8vw);
    }
  }
`;

export const TextInput = styled(BaseTextInput).attrs({
  gridTemplateColuns: "auto",
})``;

export const TextArea = styled(BaseTextArea).attrs({})``;

export const SecondaryButton = styled(BaseSecondaryButton)`
  align-self: center;
  padding: 10px 50px;
  margin-top: max(32px, 3vw);
  font-family: "Montserrat", sans-serif;

  &:hover {
    background: #cf9455;
    border: solid 1px #cf9455;
    color: #fafafa;
    padding: 10px 50px;
    transform: scale(0.96);
  }
`;

export const ErrorMessage = styled(FormikCustomErrorMessage)`
  position: absolute;
  bottom: -18px;
  right: 16px;
  font-size: max(14px, 1.2vw);
  color: #fafafa;
`;
