import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../../components/PageListHeader";
//functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";

const columns = [
  { name: "title", label: "Título" },
  { name: "order", label: "Ordem" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const TypeAnswerTable = ({ history, typesAnswerUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [typesAnswer, setTypesAnswer] = useState([]);
  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");

    const apiTypesAnswer = await typesAnswerUseCases.getTypesAnswer();

    const userIsSuperAdmin = context.haveSuperAdminAccess();

    apiTypesAnswer?.forEach((typeAnswer) => {
      if (userIsSuperAdmin) {
        typeAnswer.actions = (
          <TableActions
            handleDelete={() => deleteDialog(typeAnswer.id)}
            handleEdit={() => handleEdit(typeAnswer.id)}
          />
        );
      } else {
        typeAnswer.actions = <TableActions handleEdit={() => handleEdit(typeAnswer.id)} />;
      }
    });
    setTypesAnswer(apiTypesAnswer);
    openBackdrop(false);
  };

  const handleEdit = (id) => {
    history.push(`/admin/tipos-de-resposta/editar/${id}`);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo tipo de resposta");
    try {
      await typesAnswerUseCases.deleteTypeAnswer(id);
      await fetchData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir tipo de resposta: Missing endpoint.",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este serviço?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const handleAdd = () => history.push("/admin/tipos-de-resposta/cadastrar");

  return (
    <Container maxWidth="100%">
      <PageListHeader
        title="Tipo de resposta"
        titleAccess="Novo tipo"
        searchFieldRef={searchFieldRef}
        handleAdd={handleAdd}
        handleSearch={handleSearch}
      />
      {!!typesAnswer && (
        <Table
          searchRef={hiddenInputRef}
          columns={columns}
          data={typesAnswer}
          title="Tipos de Resposta"
        />
      )}
    </Container>
  );
};

export default TypeAnswerTable;
