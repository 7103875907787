import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// packages
import { useHistory, useParams } from "react-router-dom";
// components
import Form from "~/presentation/views/Admin/LegislationCategory/Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

const EditLegislationCategory = ({ legislationCategoryUseCases }) => {
  const id = useParams().id;
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [initialValues, setInitialValues] = useState();

  const history = useHistory();

  const fetchData = async (id) => {
    openBackdrop(true, "Carregando dados da categoria de legislação");
    setInitialValues(await legislationCategoryUseCases.getLegislationCategoryById(id));
    openBackdrop(false);
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Atualizando categoria");
    try {
      await legislationCategoryUseCases.updateLegislationCategory(id, values);
      history.push("/admin/categoria-legislacao");
    } catch (error) {
      console.log(error);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao editar categoria: missing endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Editar Categoria de Legislação" backLink="/admin/categoria-legislacao" />

      {initialValues ? <Form initialValues={initialValues} handleSubmit={handleSubmit} /> : <div />}
    </Container>
  );
};

export default EditLegislationCategory;
