import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: Montserrat, sans-serif;
        font-weight: normal;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out; */
    }

    html, body, #root{
      /* height: max(100%, max-content); */
      height: 100%;
      background: #fafafa;
    }

    a {
        text-decoration: none;
    }

    input {
        font-family: sans-serif;
    }

    .flex {
        display: flex;

        &.align-end { align-items: flex-end }
        &.center { align-items: center }
        &.justify-end { justify-content: flex-end }
        &.space-between { justify-content: space-between }
        &.justify-center { justify-content: center }
        &.column { flex-flow: column }
    }

    .text-center {
        text-align: center;
    }

    .form-grid {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: repeat(4, 1fr);
        align-content: flex-start;

        .col-1_5, .col-1_4, .col-1_3, .col-1_2, .col-2_5, .col-2_4, .col-2_3, .col-3_5, .col-3_4, .col-4_5 {
            grid-column: 1/5;
        }

        @media screen and (min-width: 600px) {
        /* 1  */
            .col-1_5 {
                grid-column: 1/5;
            }
            .col-1_4 {
                grid-column: 1/4;
            }
            .col-1_3{
                grid-column: 1/3;
            }
            .col-1_2{
                grid-column: 1/2;
            }
        /* 2 */
            .col-2_5 {
                grid-column: 2/5;
            }
            .col-2_4 {
                grid-column: 2/4;
            }
            .col-2_3{
                grid-column: 2/3;
            }
        /* 3 */
            .col-3_5 {
                grid-column: 3/5;
            }
            .col-3_4 {
                grid-column: 3/4;
            }
        /* 4 */
            .col-4_5{
                grid-column: 4/5;
            }

        }
    }

    .mt_12 { margin-top: 12px }
    .mb_12 { margin-bottom: 12px }
    .ml_12 { margin-left: 12px }
    .mr_12 { margin-right: 12px }

    .scroll-x {
        width: 100%;
        overflow-x: auto;
    }

    .relative {
        position: relative;
    }

    /** Must be inside of an absolute or relative parent */
    .absolute-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%)
    }


    /* Change Autocomplete styles in Chrome*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        -webkit-text-fill-color: #38348"};
        -webkit-box-shadow: 0 0 0px 1000px trasparent inset;
        transition: background-color 5000s ease-in-out 0s;
        font-size: unset;
    }

    .scrollbar-gray {
        &::-webkit-scrollbar{
            width: 6px;
        }
            &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 10px 10px #ddd};
            border: solid 11px transparent;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb{
            box-shadow: inset 0 0 10px 10px #aaa};
            border: solid 11px transparent;
            border-radius: 30px;
        }
    }

    .scrollbar-white {
        &::-webkit-scrollbar{
            width: 6px;
        }
            &::-webkit-scrollbar-track{
            box-shadow: inset 0 0 10px 10px "#ddd";
            border: solid 11px transparent;
            border-radius: 30px;
        }
        &::-webkit-scrollbar-thumb{
            box-shadow: inset 0 0 10px 10px #aaa};
            border: solid 11px transparent;
            border-radius: 30px;
        }
    }
    /* Global Styles - Pode ser adicionado em um arquivo CSS global */
    ::-webkit-scrollbar {
      width: 6px; /* Largura da barra de rolagem */
      height: 6px; /* Altura da barra de rolagem */
    }
    
    ::-webkit-scrollbar-track {
      background: #f1f1f1; /* Cor do fundo da barra de rolagem */
      border-radius: 10px; /* Bordas arredondadas */
    }
    
    ::-webkit-scrollbar-thumb {
      background: #888; /* Cor do "polegar" da barra de rolagem */
      border-radius: 10px; /* Bordas arredondadas */
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background: #555; /* Cor do "polegar" da barra de rolagem ao passar o mouse */
    }
    
`;
