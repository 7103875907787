import React, { useState, useEffect } from "react";
import { Field, Formik, Form } from "formik";
import ModalContainer from "~/presentation/components/ModalContainer";
import {
  Bg,
  ModalContentWrapper,
  Header,
  FormContainer,
  TextInput,
  InputLabel,
  InputWrapper,
  Button,
  AutocompleteSelect,
} from "~/presentation/views/LandingPage/Home/Modals/SubscribeModal/styles";
import {
  Call as CallIcon,
  MailOutline as MailOutlineIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
} from "@mui/icons-material/";
import { ErrorMessage } from "~/presentation/components/ErrorMessage";
import InputMask from "react-input-mask";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import removeMask from "~/presentation/functions/removeMask";

const MaskedInput = (props) => (
  <InputMask {...props}>{(inputProps) => <TextInput {...inputProps} />}</InputMask>
);

const SubscribeModal = ({ plan, onSubscription, planUseCases, companyUseCases }) => {
  const [plans, setPlans] = useState([]);
  const [categories, setCategories] = useState([]);

  const fetchFormData = async () => {
    const plans = dataToSelectMapper(await planUseCases.getPlans(true));
    setPlans(plans);
    const categories = dataToSelectMapper(await companyUseCases.getCompanyCategories());
    setCategories(categories);
  };

  useEffect(() => {
    fetchFormData();
  }, []);

  return (
    <ModalContainer backgroundUrl="/assets/triangles_bg.jpg">
      <Bg />
      <ModalContentWrapper>
        <Header>
          <h1>Contratar</h1>
          <p>Preencha os campos abaixo para solicitar uma proposta</p>
        </Header>
        <FormContainer>
          <Formik
            initialValues={{
              id: 0,
              name: "",
              cnpj: "",
              companyName: "",
              email: "",
              phone: "",
              categoryId: null,
              planId: "",
            }}
            // validationSchema={validationSchema}
            onSubmit={async (values, action) => {
              action.setSubmitting(true);
              values.cnpj = removeMask(values.cnpj);
              values.cnpj = values.cnpj.replace("/", "");
              await onSubscription(values);
              values.categoryId = 0;
              values.planId = 0;
              values.id = 0;
              values.email = "";
              values.cnpj = "";
              values.companyName = "";
              values.phone = "";
              values.name = "";
              action.setSubmitting(false);
            }}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="form-grid">
                  <InputWrapper className="col-1_3">
                    <InputLabel htmlFor="cnpj">CNPJ</InputLabel>
                    <Field
                      icon={() => <PersonIcon fontSize="small" />}
                      name="cnpj"
                      type="text"
                      mask="99.999.999/9999-99"
                      as={MaskedInput}
                    />
                    <ErrorMessage name="cnpj" />
                  </InputWrapper>

                  <InputWrapper className="col-3_5">
                    <InputLabel htmlFor="companyName">EMPRESA</InputLabel>
                    <Field
                      icon={() => <BusinessIcon fontSize="small" />}
                      name="companyName"
                      type="text"
                      as={TextInput}
                    />
                    <ErrorMessage name="companyName" />
                  </InputWrapper>

                  <InputWrapper className="col-1_3">
                    <InputLabel htmlFor="name">NOME</InputLabel>
                    <Field
                      icon={() => <PersonIcon fontSize="small" />}
                      name="name"
                      type="text"
                      as={TextInput}
                    />
                    <ErrorMessage name="name" />
                  </InputWrapper>

                  <InputWrapper className="col-3_5">
                    <InputLabel htmlFor="email">EMAIL</InputLabel>
                    <Field
                      icon={() => <MailOutlineIcon fontSize="small" />}
                      name="email"
                      type="text"
                      as={TextInput}
                    />
                    <ErrorMessage name="email" />
                  </InputWrapper>

                  <InputWrapper className="col-1_3">
                    <InputLabel htmlFor="phone">TELEFONE</InputLabel>
                    <Field
                      icon={() => <CallIcon fontSize="small" />}
                      name="phone"
                      type="text"
                      mask="(99) 99999-9999"
                      as={MaskedInput}
                    />
                    <ErrorMessage name="phone" />
                  </InputWrapper>

                  <InputWrapper className="col-3_5">
                    <InputLabel htmlFor="planId">PLANO</InputLabel>
                    <Field
                      name="planId"
                      component={AutocompleteSelect}
                      options={plans}
                      // value={values.planId}
                      onChange={(e, newValue) => {
                        setFieldValue("planId", newValue.value);
                      }}
                      getOptionLabel={(option) => `${option.label}`}
                    />
                    <ErrorMessage name="planId" />
                  </InputWrapper>

                  <InputWrapper className="col-1_5">
                    <InputLabel htmlFor="categoryId">CATEGORIA</InputLabel>
                    <Field
                      name="categoryId"
                      component={AutocompleteSelect}
                      options={categories}
                      // value={values.categoryId}
                      onChange={(e, newValue) => {
                        setFieldValue("categoryId", newValue.value);
                      }}
                      getOptionLabel={(option) => `${option.label}`}
                    />
                    <ErrorMessage name="categoryId" />
                  </InputWrapper>

                  <InputWrapper className="col-1_5">
                    <Button type="submit" variant="outlined">
                      ENVIAR
                    </Button>
                  </InputWrapper>
                </div>
              </Form>
            )}
          </Formik>
        </FormContainer>
      </ModalContentWrapper>
    </ModalContainer>
  );
};

export default SubscribeModal;
