import React, { useContext } from "react";
import { PlanForm } from "./Form";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import { Box } from "@mui/material";
import HeaderForm from "../../components/HeaderForm/HeaderForm";

function RegisterEdit({ data, onCloseForm, refresData, planUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const isCreate = data === null;

  const getInitialValues = () => {
    if (data) {
      return {
        id: data.id,
        name: data.name,
        isAllowReinf: data.isAllowReinf,
        isConsultISS: data.isConsultISS,
        isGenerateReport: data.isGenerateReport,
        isAllowTaxInformation: data.isAllowTaxInformation,
        consultLimit: data.consultLimit,
        taxInformationNumber: data.taxInformationNumber,
        numberOfUsers: data.numberOfUsers,
        isCalculationSimulator: data.isCalculationSimulator,
        isSpecificSubstitutionAnalysis: data.isSpecificSubstitutionAnalysis,
        isCustom: data.isCustom,
        valor: formatNumberToRealString(data.valor),
      };
    }
    return {
      id: 0,
      name: "",
      isAllowReinf: false,
      isConsultISS: true,
      isGenerateReport: true,
      consultLimit: 0,
      taxInformationNumber: 0,
      numberOfUsers: 0,
      isCalculationSimulator: true,
      isSpecificSubstitutionAnalysis: true,
      totalSubstituteOrDismissed: "",
      isCustom: true,
      valor: 0,
    };
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} plano`);
    try {
      if (isCreate) {
        await planUseCases.createPlan(values);
      } else {
        await planUseCases.updatePlan(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Plano ${isCreate ? "cadastrado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refresData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro. ${error?.response?.data}`,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };
  return (
    <>
      <Box sx={{ marginBlock: 2 }}>
        <HeaderForm title={`${isCreate ? "Cadastrar" : "Editar"} Empresa`} backFnc={onCloseForm} />
      </Box>
      <PlanForm initialValues={getInitialValues()} handleSubmit={handleSubmit} />
    </>
  );
}

export default RegisterEdit;
