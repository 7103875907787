import React from "react";
import styled from "styled-components";

const GREY_RATIO = 690;
const GREY_TEXT = 250;
const GREY_TEXT_PLACEHOLDER = 650;

const Container = styled.div`
  border-radius: ${({ borderRadius }) => borderRadius || "4px"};
  overflow: hidden;
  background: ${({ backgroundColor }) => backgroundColor || "#fff"};
  display: flex;
  grid-template-columns: ${({ gridTemplateColuns }) => gridTemplateColuns || "min-content auto"};
  align-items: center;
  gap: 16px;
  padding: ${({ padding }) => padding || "0 0 0 14px"};
  color: ${({ theme, color }) => color || "#ddd"};
  max-width: 100%;

  input {
    border: none;
    outline: none;
    background: inherit;
    color: ${({ theme, color }) => color || "#ddd"};
    font-size: 0.9rem;
    padding: 14px 0;
    flex-grow: 1;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      font-size: 0.9rem;
      -webkit-text-fill-color: ${({ theme, color }) => color || "#ddd"};
    }
  }

  input::placeholder {
    color: ${({ theme, color }) => color || "#D1D1D1"};
  }
`;

const TextInput = ({
  children,
  icon: Icon,
  backgroundColor,
  color,
  padding,
  borderRadius,
  gridTemplateColuns,
  ...props
}) => {
  return (
    <Container
      padding={padding}
      backgroundColor={backgroundColor}
      color={color}
      borderRadius={borderRadius}
      gridTemplateColuns={gridTemplateColuns}
    >
      {Icon && <Icon />}
      <input {...props}>{children}</input>
    </Container>
  );
};

export default TextInput;
