import styled from "styled-components";
import { parseNestedColor } from "~/presentation/functions/parseNestedColor";

export const Button = styled.button`
  width: fit-content;
  padding: 10px 20px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 0.9rem;
  font-weight: 500;

  &:active {
    transform: scale(0.96);
  }
`;

export const CondensedButton = styled(Button)`
  background: ${({ theme, buttonColor }) => parseNestedColor(theme, buttonColor)};
  border: ${({ theme, buttonColor }) => "solid 1px" + parseNestedColor(theme, buttonColor)};
  color: #fafafa;

  &:hover {
    background: transparent;
    border: solid 2px ${({ theme, buttonColor }) => parseNestedColor(theme, buttonColor)};
    color: ${({ theme, buttonColor }) => parseNestedColor(theme, buttonColor)};
    padding: 9px 19px;
  }
`;

export const OutlinedButton = styled(Button)`
  background: transparent;
  border: ${({ theme, buttonColor }) => "solid 1px" + parseNestedColor(theme, buttonColor)};
  color: ${({ theme, buttonColor }) => parseNestedColor(theme, buttonColor)};

  &:hover {
    background: ${({ theme, buttonColor }) => parseNestedColor(theme, buttonColor)};
    color: #fafafa;
  }
`;
