import React, {useContext} from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { AddtionalCompanyPlanForm } from "../Form";
import {Box, Grid, Typography as Text} from "@mui/material";
import {Close} from "@mui/icons-material";

let initialValues = {
  amount: 0,
  price: 0,
  limitDate: null
};

export const RegisterAdditionalCompanyPlan = ({
  additionalCompanyPlanUseCases,
  closeModal,
  companyName,
  companyId,
  refetchData,
}) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  initialValues = {
    ...initialValues,
    companyId: companyId,
    companyName: companyName,
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Cadastrando adicional de plano");
    try {
      await additionalCompanyPlanUseCases.createAdditionalCompanyPlan(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Adicional de plano cadastrado com sucesso!",
        type: "success",
      });
      await refetchData();
      closeModal();
    } catch (err) {
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar adicional de plano",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <Box sx={styles.Modal}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={11}>
          <Text style={styles.Title}>Adicional de Plano da Empresa</Text>
        </Grid>
        <Grid
          item
          xs={6}
          sm={1}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <Close onClick={closeModal} />
        </Grid>
        <AddtionalCompanyPlanForm id={0} initialValues={initialValues} handleSubmit={handleSubmit}/>
      </Grid>
    </Box>
  );
};

const styles = {
  Modal: {
    position: "absolute",
    background: "#fff",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "800px",
    width: "90%",
    maxHeight: "80%",
    overflow: "auto",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  },
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
};
