import styled from "styled-components";

export const FormWrapper = styled.div`
  display: grid;
  grid-row-gap: 32px;

  @media (min-width: 600px) {
    grid-row-gap: 12px;
  }
`;
