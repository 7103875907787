import styled from "styled-components";

const Links = styled.div`
  display: flex;

  > span {
    margin-left: auto;
    cursor: pointer;
    letter-spacing: 0.02em;
    font-size: 0.8rem;
    line-height: 133.19%;
  }
`;

const RecoverText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

export const ForgetPassFormStyles = {
  Links,
  RecoverText,
};
