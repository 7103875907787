import React from "react";
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import Select from "~/presentation/components/Select";
import MaskedInput from "~/presentation/components/MaskedInput";
import { Container, Form } from "./styles";
//functions
import removeMask from "~/presentation/functions/removeMask";
import validationSchema from "./validationSchema";

const UserForm = ({ handleSubmit, initialValues, editing, companies, userGroups }) => {
  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(editing)}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          await handleSubmit(
            {
              companyId: Number(values.company),
              name: values.name,
              email: values.email,
              password: values.password,
              role: values.role,
              homePhone: removeMask(values.phone),
              mobilePhone: removeMask(values.mobile_phone),
            },
            action.resetForm
          );
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <h2>Dados do Usuário</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <Field
                  name="company"
                  component={Select}
                  options={companies}
                  placeholder={"Selecione a empresa *"}
                />
                <ErrorMessage name="company" />
              </div>

              <div className="col-1_5">
                <Field name="name" type="text" label="Nome *" as={TextInput} />
                <ErrorMessage name="name" />
              </div>

              <div className="col-1_3">
                <Field name="email" type="text" label="Email *" as={TextInput} />
                <ErrorMessage name="email" />
              </div>

              <div className="col-3_4">
                <Field
                  name="phone"
                  type="text"
                  mask="(99) 9999-9999"
                  label="Telefone"
                  as={MaskedInput}
                />
                <ErrorMessage name="phone" />
              </div>

              <div className="col-4_5">
                <Field
                  name="mobile_phone"
                  type="text"
                  mask="(99) 99999-9999"
                  label="Celular"
                  as={MaskedInput}
                />
                <ErrorMessage name="mobile_phone" />
              </div>

              <div className="col-1_3">
                <Field
                  name="password"
                  type="password"
                  label="Senha *"
                  as={TextInput}
                  disabled={editing}
                />
                <ErrorMessage name="password" />
              </div>

              <div className="col-3_5">
                <Field
                  name="confirm_password"
                  type="password"
                  label="Confirmar a Senha *"
                  as={TextInput}
                  disabled={editing}
                />
                <ErrorMessage name="confirm_password" />
              </div>
              <div className="col-1_3">
                <Field
                  name="role"
                  component={Select}
                  options={userGroups}
                  placeholder={"Grupo de Usuário *"}
                  onChange={(role) => {
                    setFieldValue("role", role.value);
                  }}
                />
                <ErrorMessage name="role" />
              </div>
            </div>
            <PrimaryButton className="submit-btn" type="submit">
              Salvar
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default UserForm;
