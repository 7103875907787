import React from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import Checkbox from "~/presentation/components/Checkbox";
import TextInput from "~/presentation/components/TextInput";
import TextEditor from "~/presentation/components/TextEditor";
import { Container, Form } from "./styles";
// schema
import validationSchema from "./validationSchema";
import { FormControlLabel } from "@mui/material";

const ValueVariableForm = ({ handleSubmit, initialValues }) => {
  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          const submiteData = {
            id: values.id,
            name: values.name,
            code: values.code,
            value: values.value,
            isHtml: values.isHtml,
            isPopup: values.isPopup,
          };
          await handleSubmit(submiteData);
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <h2>Informações</h2>
            <div className="form-grid">
              <div className="col-1_2">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={true}
                      checked={values.isPopup}
                      onChange={() => setFieldValue("isPopup", !values.isPopup)}
                      name="isPopup"
                    />
                  }
                  label="Popup"
                />
              </div>
              {values.isPopup ? (
                <div className="col-2_3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={true}
                        checked={values.isHtml}
                        onChange={() => setFieldValue("isHtml", !values.isHtml)}
                        name="isHtml"
                      />
                    }
                    label="Conteúdo em texto"
                  />
                </div>
              ) : null}

              <div className="col-1_3">
                <Field name="code" type="text" label="Código *" as={TextInput} />
                <ErrorMessage name="code" />
              </div>
              <div className="col-3_5">
                <Field name="name" type="text" label="Nome da variável*" as={TextInput} />
                <ErrorMessage name="name" />
              </div>
              {values.isHtml ? (
                <div className="col-1_5">
                  <TextEditor
                    initialValue={values.value}
                    name="value"
                    height={400}
                    handleEditorChange={(content) => {
                      setFieldValue("value", content);
                    }}
                  />
                  <ErrorMessage name="text" />
                </div>
              ) : (
                <div className="col-1_5">
                  <Field name="value" type="text" label="Valor da variável *" as={TextInput} />
                  <ErrorMessage name="value" />
                </div>
              )}
            </div>

            <PrimaryButton className="submit-btn" type="submit">
              Salvar
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ValueVariableForm;
