import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  gap: 6px;

  @media screen and (min-width: 600px) {
    display: flex;
    justify-content: center;
  }

  > button,
  .MuiButton-root {
    width: 100%;

    @media screen and (min-width: 600px) {
      width: 30%;
      margin: 0 6px;
    }

    &.draft {
      background-color: ${(props) => "#ffb22b"};
    }
  }
`;
