import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10.4rem;
  padding: 1rem 1rem;
  background-color: #081445;
  background: url(${({ $image }) => $image});
  background-position: 50% 35%;
  background-size: cover;
  ${({ $marginToHeader }) => {
    if ($marginToHeader) {
      return css`
        margin-top: 4.375rem;
      `;
    }
  }}
  padding: 0;
`;

const Blur = styled.div`
  height: 100%;
  width: 100%;
  background-color: #021148;
  opacity: ${({ blur }) => blur || 0.8};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

const ContainerContent = styled.div`
  display: flex;
  margin: 0 auto;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div > span {
    color: #fff;
    cursor: pointer;
    text-align: left;
    font-family: Montserrat;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 119.4%;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    width: 100%;

    > div > span {
      font-size: 2.75rem;
    }
  }

  @media (min-width: 1024px) {
    > div > span {
      font-size: 3.25rem;
    }
  }
`;

export const SectionTitlePostStyles = {
  Container,
  ContainerContent,
  Blur,
};
