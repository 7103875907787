import React, { useState } from "react";

import { Container, InfoIcon, Excerpt } from "./styles";

export const QuestionLabel = ({ text, excerpt }) => {
  const [show, setShow] = useState(false);

  const toggleExcerpt = () => {
    setShow((prev) => !prev);
  };

  return (
    <Container>
      <div>
        <p>{text}</p>
        {excerpt && <InfoIcon onClick={toggleExcerpt} />}
      </div>
      {excerpt && (
        <Excerpt show={show}>
          <span>{excerpt}</span>
        </Excerpt>
      )}
    </Container>
  );
};
