import React, {useContext, useState, useRef, useEffect} from "react";
import { Form, Formik } from "formik";
import {
  Button,
  FormWrapper,
  MoneyQuestion,
  PercentageQuestion,
  RadioQuestion,
  SimulatorResultCard,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { floatToReais } from "~/presentation/utils/reais-parser";
import { useValuesChanged } from "~/presentation/views/GTFacil/Simulator/hooks/usValuesChanged";
import { reportMapper } from "./reportMapper";
import { validationSchema } from "~/presentation/views/GTFacil/Simulator/forms/Individual/iss/validationSchema.js";

const initialValues = {
  hasMaterialExclusion: "false",
  materialExclusionValue: 0.0,
  retentionAliquotPercentage: 0.0,
};

export const IssCooperative = () => {
  const resultCardRef = useRef();
  const [result, setResult] = useState();
  const { simulatorValue, setInvalidSimulatorValue, handleGenerateReport, handleCacheReport } = useContext(SimulatorContext);
  const { isValuesChanged, setValues } = useValuesChanged();

  const getBaseRetentionCalcValue = (values) => {
    return simulatorValue - values.materialExclusionValue;
  };

  const getResult = (values) => {
    return (
      getBaseRetentionCalcValue(values) *
      (values.retentionAliquotPercentage / 100)
    );
  };


  const handleGenerateReportClick = (values) => {
    const response = reportMapper(values, result)
    handleGenerateReport(response)
  }

  useEffect(() => {
    if(result) {
      resultCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }
        action.setSubmitting(true);

        setValues(values);
        const result = getResult(values);
        setResult(result);

        resultCardRef.current.scrollIntoView({ behavior: "smooth" });

        action.setSubmitting(false);
        const response = reportMapper(values, result);
        handleCacheReport(response);          
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form>
            <FormWrapper>
              <RadioQuestion
                text="Há exclusão de materiais, subempreitadas ou outras parcelas da base de cálculo?"
                onChange={(value) => {
                  setFieldValue("hasMaterialExclusion", value === "true");
                  setFieldValue(
                    "materialExclusionValue",
                    initialValues.materialExclusionValue
                  );
                }}
                value={values.hasMaterialExclusion.toString()}
                excerpt="O art. 7°, § 2°, I, da Lei Complementar n° 116/2003, prevê a exclusão da base de cálculo dos materiais fornecidos pelo prestador dos serviços previstos nos itens 7.02 e 7.05 da lista de serviços. Por sua vez, o art. 9°, § 2°, do Decreto Lei n° 406/68 estabelece que, para os serviços dos itens 19 e 20 (correspondentes aos atuais 7.02 e 7.05 da lista anexa à LC 116/2003), além da exclusão dos materiais da base de cálculo, é possível a exclusão das subempreitadas já tributadas pelo imposto. Entretanto, é necessário verificar se a legislação do município ratifica e regulamenta tais exclusões. Para exclusão de quaisquer outras parcelas e necessário verificar se a legislação do município competente para a cobrança do ISS autoriza."
              />

              {values.hasMaterialExclusion === true ? (
                <MoneyQuestion
                  text="Valor da exclusão (autorizada em lei municipal)"
                  value={values.materialExclusionValue}
                  onChange={(_event, _maskedValue, floatValue) => {
                    setFieldValue("materialExclusionValue", floatValue);
                  }}
                />
              ) : null}

              <MoneyQuestion
                text="Base de cálculo da retenção do ISS:"
                excerpt="A base de cálculo é o valor bruto da nota fiscal excluídos os valores dos materiais, subempreitadas ou outras parcelas."
                value={getBaseRetentionCalcValue(values)}
                disabled
                allowNegative
              />

              <PercentageQuestion
                name="retentionAliquotPercentage"
                text="Qual a alíquota da retenção?"
                excerpt="A alíquota de retenção é determinada pela lei do município competente para a cobrança do imposto, que deve respeitar as alíquotas mínima e máxima correspondentes a 2% e 5%, respectivamente, conforme disposição da Emenda Constitucional nº 37/2002 e Lei Complementar n° 116/2003."
                value={values.retentionAliquotPercentage}
                onChange={(_event, _maskedValue, floatValue) => {
                  setFieldValue("retentionAliquotPercentage", floatValue);
                }}
              />

              <Button mt>Ver Resultado</Button>
              {result && isValuesChanged(values) ? (
                <SimulatorResultCard
                  componentRef={resultCardRef}
                  title="Valor da retenção do ISS:"
                  value={floatToReais(result)}
                  description="O valor da retenção do ISS é aquele que deve ser abatido do valor a ser pago para o contratado, correspondente ao resultado da multiplicação da alíquota pela base de cálculo."
                  generateReportButton={() => handleGenerateReportClick(values)}
                />
              ) : null}
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
