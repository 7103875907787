import React from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import Checkbox from "~/presentation/components/Checkbox";
import Select from "~/presentation/components/Select";
import { Container, Form } from "~/presentation/views/Admin/Service/CreateEdit/Form/styles";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
// schema
import validationSchema from "~/presentation/views/Admin/Service/CreateEdit/Form/validationSchema";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import { FormControlLabel } from "@mui/material";

const ServiceForm = ({ handleSubmit, initialValues, cnaes }) => {
  const defaultCnaes = dataToSelectMapper(initialValues.cnaes) || null;
  const mappedCnaes = dataToSelectMapper(cnaes);
  const groups = [
    { label: "RG", value: "RG" },
    { label: "EMT1", value: "EMT1" },
    { label: "EMT2", value: "EMT2" },
    { label: "ERF", value: "ERF" },
    { label: "ERO", value: "ERO" },
    { label: "ELO", value: "ERO" },
  ];

  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          const submitValues = {
            id: values.id,
            code: values.code,
            description: values.description,
            group: values.group,
            isDefaultISS: values.isDefaultISS,
            cnaes:
              values.cnaes?.map((item) => {
                let cnaes = {
                  id: item.id,
                  code: item.code,
                  description: item.description,
                  serviceCodes: item.serviceCodes,
                };
                return cnaes;
              }) || null,
          };
          action.setSubmitting(true);
          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <h2>Serviço</h2>
            <div className="form-grid">
              <div className="col-1_2">
                <Field name="code" label="Código*" as={TextInput} />
                <ErrorMessage name="code" />
              </div>
              <div className="col-2_5">
                <Field name="description" type="text" label="Descrição*" as={TextInput} />
                <ErrorMessage name="description" />
              </div>
              <div className="col-1_2">
                <Field
                  name="group"
                  label="Grupo"
                  placeholder="Grupo"
                  options={groups}
                  component={Select}
                />
              </div>

              <div className="col-2_5">
                <Field
                  name="cnaes"
                  label="Cnaes"
                  placeholder="Cnaes"
                  component={Select}
                  defaultValue={defaultCnaes}
                  isMulti={true}
                  options={mappedCnaes}
                  onChange={(option) => setFieldValue("cnaes", option)}
                  getOptionLabel={(option) => {
                    if (
                      option.detailCode !== null &&
                      option.detailCode !== "" &&
                      option.detailCode !== undefined
                    ) {
                      return `${option.code || 0} (${option.detailCode}) - ${option.description || option.label}`;
                    }
                    return `${option.code || 0} - ${option.description || option.label}`;
                  }}
                />
              </div>
              <div className="col-1_5">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isDefaultISS}
                      onChange={() => setFieldValue("isDefaultISS", !values.isDefaultISS)}
                      name="isDefaultISS"
                    />
                  }
                  label="Resposta padrão do ISS"
                />
              </div>
            </div>

            <FormSubmitButtonsDock>
              <PrimaryButton className="submit-btn" type="submit">
                Salvar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ServiceForm;
