import React, { useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import Form from "~/presentation/views/Admin/Survey/CreateEdit/Form";

function CreateEdit({ data, onCloseForm, refresData, surveyUseCases }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  const getInitialValues = () => {
    const newAnswerArray = [{ id: 0, text: "", isCorrect: true }];
    if (data) {
      return {
        id: data.id,
        question: data.question,
        draft: data.draft,
        publishDate: new Date(data.publishDate),
        answers: data.answers.length > 0 ? data.answers : newAnswerArray,
      };
    }
    return {
      id: 0,
      question: "",
      draft: false,
      publishDate: new Date(),
      answers: newAnswerArray,
    };
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} nova enquete`);
    try {
      if (isCreate) {
        await surveyUseCases.create(values);
      } else {
        await surveyUseCases.update(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Enquete ${isCreate ? "criada" : "editada"}!`,
        type: "success",
      });
      onCloseForm();
      await refresData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Criar" : "Editar"} Enquete`} backFnc={onCloseForm} />
      <Form initialValues={getInitialValues()} handleSubmit={handleSubmit} />
    </>
  );
}

export default CreateEdit;
