import React from "react";
import { TabsStyles } from "~/presentation/components/Tabs/Tab.styles";
import {Box} from "@mui/material";

export function Tabs({ tabs }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (newValue, isDisabled) => {
    if (isDisabled) return;
    setValue(newValue);
  };

  const isSelected = (v) => v === value;

  return (
    <TabsStyles.Container>
      <TabsStyles.TabsHeader>
        {tabs.map(({ title, isDisabled }, index) => (
          <TabsStyles.Tab
            onClick={() => handleChange(index, isDisabled)}
            key={`tab-header-${title}-${index}`}
            selected={isSelected(index)}
            isDisabled={isDisabled}
          >
            {title}
          </TabsStyles.Tab>
        ))}
      </TabsStyles.TabsHeader>
      <Box
        sx={{
          height: "calc(100vh - 390px)",
          overflowY: "auto",
        }}
      >
        <TabsStyles.TabPanelWrapper>
          {tabs.map(
            ({ panel: Panel }, index) => isSelected(index) && <Panel key={`tabs-panel-${index}`} />
          )}
        </TabsStyles.TabPanelWrapper>
      </Box>
    </TabsStyles.Container>
  );
}
