import styled from "styled-components";
import { Form as FormikForm } from "formik";
import { Radio as MuiRadio, FormLabel as MuiFormLabel } from "@mui/material";

export const Container = styled.div`
  background-color: #fff;

  border-radius: 10px;
  height: 100%;
  padding: 20px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-row-gap: 20px;

  /* preserve the padding bottom */
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
  }
`;

export const Form = styled(FormikForm)`
  display: grid;
  grid-gap: 16px;

  .submit-btn {
    width: 100%;
    justify-self: unset;

    @media screen and (min-width: 600px) {
      width: 25%;
      justify-self: center;
    }
  }
`;

export const Radio = styled(MuiRadio)`
  &.MuiRadio-root,
  &.MuiRadio-root.Mui-checked {
    color: ${(props) => "#383f48"};
  }
`;

export const FormLabel = styled(MuiFormLabel)`
  &.MuiFormLabel-root,
  &.MuiFormLabel-root.Mui-focused {
    color: ${(props) => "#383f48"};
  }
`;

export const SubContainer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;

  &.expand-true {
    max-height: 400px;
  }

  .wrapper {
    background: ${(props) => "#e1e1e1"};
    padding: 20px;
    margin: 6px 0 12px;
  }
`;
