import { handleErrors } from "~/presentation/utils/errorsHandling";
import { apiURL } from "../../usecases/api";
export class ApiPlanUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getPlans(ispublic = false) {
    try {
      let response = await this.httpClient.request({
        url: ispublic ? `${apiURL}/Plan/Public` : `${apiURL}/Plan/`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getPlan(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Plan/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async updatePlan(plan) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Plan`,
        method: "PUT",
        data: plan,
      });
      return response.data;
    } catch (err) {
      console.log("🚀 ~ file: apiPlan.js:51 ~ ApiPlanUseCases ~ updatePlan ~ err:", err);
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async deletePlan(id) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Plan/${id}`,
        method: "DELETE",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async createPlan(plan) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/Plan/`,
        method: "POST",
        data: plan,
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }
}
