import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  min-height: 100%;
  height: fit-content;
  background: ${({ backgroundUrl }) => `url("${backgroundUrl}")`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fafafa;
`;

const ModalContainer = ({ children, backgroundUrl, ...props }) => (
  <Container backgroundUrl={backgroundUrl} {...props}>
    {children}
  </Container>
);
export default ModalContainer;
