import styled from "styled-components";
import { transparentize } from "polished";
import { IconButton as MuiIconButton } from "@mui/material";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content) auto;
`;

export const IconButton = styled(MuiIconButton)`
  &.MuiIconButton-root {
    background-color: ${(props) => props.theme[props.backgroundColor]};
    &:hover {
      background-color: ${(props) =>
        props.backgroundColor && transparentize(0.5, props.theme[props.backgroundColor])};
    }
    &.Mui-disabled {
      .MuiSvgIcon-root {
        color: ${(props) => transparentize(0.5, "#383f48")};
      }
    }
  }
`;
