import { transparentize } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  height: 1100px;
  background: url("/assets/client-home-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-Inline: 80px;
  padding-Block: 80px;
  row-gap: max(32px, 5vw);

  @media screen and (min-width: 768px) {
    height: 53rem;
  }

  h2,
  h3,
  p,
  span,
  .material-icons {
    color: #fafafa;
  }

  h2,
  h3,
  p {
    text-shadow: 2px 2px 4px #272c33;
  }
`;
