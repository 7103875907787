import styled from "styled-components";
import { darken, lighten } from "@mui/material";
import BaseButton from "~/presentation/components/SecondaryButton";
import { CircularProgress as MuiCircularProgress } from "@mui/material";

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: max(30px, 2.2vw);

  h3 {
    font-size: max(20px, 2vw);
    font-family: "Montserrat", sans-serif;
    font-weight: normal;
    text-align: center;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  border: solid 1px #dedede;
  padding-left: 20px;
  width: 100%;
  position: relative;

  input {
    border: none;
    outline: none;
    font-size: max(16px, 1.2vw);
    height: 42.8px;
  }

  span.error {
    font-size: max(14px, 1vw);
    position: absolute;
    bottom: min(-18px, -2vw);
    color: #fc4b6c;
  }

  @media screen and (min-width: 470px) {
    width: max(200px, 15vw);
  }
`;

export const InputAndButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 32px;
  width: 100%;
  position: relative;

  @media screen and (min-width: 470px) {
    flex-direction: row;
    width: fit-content;
  }
`;

export const Button = styled(BaseButton)`
  position: relative;

  &:hover {
    background: #cf9455;
    border: solid 1px #cf9455;
    color: #fafafa;
    padding: 10px 20px;
  }

  &:active {
    transform: none;
  }
`;

export const CircularProgress = styled(MuiCircularProgress)`
  &.MuiCircularProgress-root {
    position: absolute;
    color: #272c33;
    right: -32px;
  }
`;
