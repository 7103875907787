import React, { useEffect } from "react";
import { useQuery } from "~/presentation/hooks/useQuery";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GTLayout } from "~/presentation/views/GTFacil/components/GTLayout";
import { Card } from "~/presentation/views/GTFacil/components/Card";
import { Container } from "./styles";
import { resetScroll } from "~/presentation/functions/resetScroll";
import { SearchForm } from "~/presentation/views/GTFacil/Search/components/SearchForm";
import { SearchCard } from "../components/SearchCard";

export const Search = ({ gtFacilUseCases, companyUseCases, cityUseCases, cnaesUseCases }) => {
  const location = useLocation();
  const query = useQuery();
  const history = useHistory();
  const queryParams = {
    cnaeId: query.get("cnaeId"),
    answerId: query.get("answerId"),
    searchTermId: query.get("searchTermId"),
  };

  const getSearch = async () => {
    if (queryParams.cnaeId) {
      return location.state.cnae;
    } else if (queryParams.searchTermId){
      return location.state.term;
    }else {
      return location.state.answer;
    }
  };

  const getSearchType = queryParams.cnaeId ? "cnae" : queryParams.searchTermId ? "freeSearch" : "answer";

  const handleSearchSubmit = async (data) => {
    let completedData = { ...data, cnaeId: queryParams.cnaeId, searchTermId: queryParams.searchTermId };
    const { cnaeId, answerId, searchTermId } = queryParams;

    let url = `/gt-facil/resposta`;
    const queryString = Object.keys(completedData)
      .filter((key) => completedData[key] !== null)
      .map((key) => `${key}=${completedData[key]}`)
      .join("&");

    url += `?${queryString}`;

    let cnaeOrLC = cnaeId
    ? { ...location.state.cnae, search: "cnae" }
    : searchTermId ? { ...location.state.term, search: "freeSearch" }
    : { ...location.state.answer, search: "lc116" };

    history.push(url, {
      data: cnaeOrLC,
    });
  };

  useEffect(() => {
    resetScroll();
  }, []);

  return (
    <GTLayout title="Consulte a tributação de seus contratos com o GT-Fácil">
      <Container>
        <SearchCard getSearch={getSearch} searchType={getSearchType} />
        <Card title="Dados da operação a ser consultada:">
          <SearchForm
            getSearch={getSearch}
            companyUseCases={companyUseCases}
            gtFacilUseCases={gtFacilUseCases}
            cityUseCases={cityUseCases}
            onSubmit={handleSearchSubmit}
          />
        </Card>
      </Container>
    </GTLayout>
  );
};
