import React from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import TermForm from "../Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

const initialValues = { id: 0, term: "" };

const CreateTerm = ({ termUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const history = useHistory();

  const handleSubmit = async (values) => {
    try {
      openBackdrop(true, "Cadastrando novo termo");
      await termUseCases.createTerm(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Termo criado com sucesso!",
        type: "success",
      });
      history.push("/admin/termos-de-pesquisa");
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: error,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Cadastrar Termo" />
      <TermForm initialValues={initialValues} handleSubmit={handleSubmit} />
    </Container>
  );
};

export default CreateTerm;
