import React, { useEffect, useState } from "react";
import { Check } from "@mui/icons-material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import { Box, Button } from "@mui/material";
import handleMoney from "../../../../HandleMoney/handleMoney";

function Nota({
  values,
  errors,
  businessPartnerOptions,
  isUnconditionalDiscountWrong,
  serieError,
  setSerieError,
  inputDisabled,
  nfseIndex,
  handleChangeFieldValue,
}) {
  const [showInputs, setShowInputs] = useState(false);

  const handleClick = () => {
    setShowInputs(!showInputs);
  };
  //filtrando parceiros de negócio CNPJ
  const cnpjPartners = businessPartnerOptions.filter(partner => partner.personType === 1);


  //ERROR: O desconto incondicional não pode ser maior do que o valor bruto da nota!
  const isUnconditionalDiscountWrongText =
    isUnconditionalDiscountWrong &&
    "O desconto incondicional não pode ser maior do que o valor bruto da nota!";

  //ERROR: só para mostrar se por engano escreverem o desconto maior do que o valor bruto
  const isAmountOperationNegative = values?.amountOperation < 0;
  const isAmountOperationNegativeText = isAmountOperationNegative && "Valor não pode ser negativo!";

  return (
    <>
      {values.doc === "Planilha" && (
        <Button onClick={handleClick} sx={{ color: "#1341A1" }}>
          {showInputs ? "Ocultar campos do cabeçalho" : "Editar campos do cabeçalho"}
        </Button>
      )}
      {showInputs && (
        <>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              justifyContent: "space-evenly",
              alignItems: "start",
              marginBottom: 2,
              paddingInline: 4,
              paddingTop: 2,
              paddingBottom: 2,
              background: "#D9D9D9",
              borderRadius: 4,
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Input
                type="date"
                title="Data de emissão"
                value={values?.dateOfIssue}
                onChange={(date) => {
                  handleChangeFieldValue({
                    newItem: { dateOfIssue: date },
                    index: nfseIndex,
                    field: "dateOfIssue",
                  });
                }}
                error={!!errors[`[${nfseIndex}].dateOfIssue`]}
                errorText={errors[`[${nfseIndex}].dateOfIssue`]}
                disabled={inputDisabled}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Input
                type="select"
                title="CPNJ/CPF do Contratado"
                options={cnpjPartners}
                getOptionLabel={(option) => `${formatCpfCnpj(option.cpfCnpj)} - ${option.legalName}`}
                placeholder="Selecione um Contratado"
                value={cnpjPartners?.find((item) => item.id == values?.businessPartnerId)}
                onChange={(option) => {
                  const { id } = option;
                  handleChangeFieldValue({
                    newItem: { businessPartnerId: id },
                    index: nfseIndex,
                    field: "businessPartnerId",
                  });
                }}
                disabled={inputDisabled}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Input
                type="text"
                title="Nº Doc"
                value={values?.nFseNumber}
                onChange={(e) => {
                  const value = e.target.value;
                  handleChangeFieldValue({
                    newItem: { nFseNumber: value },
                    index: nfseIndex,
                    field: "nFseNumber",
                  });
                }}
                disabled={inputDisabled}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Input
                type="amount"
                title="Valor da operação"
                value={values?.amountOperation}
                onChange={(e) => {
                  const value = handleMoney(e.target.value);
                  handleChangeFieldValue({
                    newItem: { amountOperation: value },
                    index: nfseIndex,
                    field: "amountOperation",
                  });
                }}
                disabled={inputDisabled}
              />
            </Box>
          </Box>
        </>
      )}
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-evenly",
          paddingInline: 1,
          alignItems: "start",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Input
            type="amount"
            title="Valor Bruto do Doc. Fiscal"
            value={values?.amountBC}
            onChange={(e) => {
              const value = handleMoney(e.target.value);
              handleChangeFieldValue({
                newItem: { amountBC: value },
                index: nfseIndex,
                field: "amountBC",
              });
            }}
            error={!!errors[`[${nfseIndex}].amountBC`]}
            errorText={errors[`[${nfseIndex}].amountBC`]}
            disabled={inputDisabled}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Input
            type="amount"
            title="(-) Desconto Condicional"
            value={values?.conditionalDiscount}
            onChange={(e) => {
              const value = handleMoney(e.target?.value);
              handleChangeFieldValue({
                newItem: { conditionalDiscount: value },
                index: nfseIndex,
                field: "conditionalDiscount",
              });
            }}
            error={!!errors[`[${nfseIndex}].conditionalDiscount`]}
            errorText={errors[`[${nfseIndex}].conditionalDiscount`]}
            disabled={inputDisabled}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Input
            type="amount"
            title="(-) Desconto Incondicional"
            value={values?.unconditionalDiscount}
            onChange={(e) => {
              const value = handleMoney(e.target.value);
              handleChangeFieldValue({
                newItem: { unconditionalDiscount: value },
                index: nfseIndex,
                field: "unconditionalDiscount",
              });
            }}
            error={
              !!errors[`[${nfseIndex}].unconditionalDiscount`] || isUnconditionalDiscountWrongText
            }
            errorText={
              errors[`[${nfseIndex}].unconditionalDiscount`] || isUnconditionalDiscountWrongText
            }
            disabled={inputDisabled}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Input
            type="amount"
            title="Valor Total da Operação"
            allowNegative
            value={values?.amountOperation}
            error={!!errors[`[${nfseIndex}].amountOperation`] || isAmountOperationNegativeText}
            errorText={errors[`[${nfseIndex}].amountOperation`] || isAmountOperationNegativeText}
            disabled
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Input
            type="text"
            title="Série NF"
            value={values?.serie}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 5) {
                handleChangeFieldValue({
                  newItem: { serie: value },
                  index: nfseIndex,
                  field: "serie",
                });
                setSerieError(null);
              } else {
                setSerieError("Número de série deve ter no máximo 5 dígitos.");
              }
            }}
            error={!!errors[`[${nfseIndex}].serie`] || serieError}
            errorText={errors[`[${nfseIndex}].serie`] || serieError}
            disabled={inputDisabled}
          />
        </Box>
        <Box>
          {/*Esse campo se refere a um percentual de 11% ou 3,5%. O mais comum é de 11%. Se não há marcação sub entende-se ser 11% */}
          <p style={style.Label}>CPRB?</p>
          <Box
            style={style.checkBox}
            onClick={() => {
              if (inputDisabled) {
                return;
              }
              const lastValue = values?.isCPRB;
              handleChangeFieldValue({
                newItem: { isCPRB: lastValue ? false : true },
                index: nfseIndex,
              });
            }}
          >
            {values?.isCPRB && <Check fontSize="large" />}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Nota;

const style = {
  checkBox: {
    width: "43px",
    height: "43px",
    background: "#FBFBFB",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    marginTop: 8,
  },
  Label: {
    fontFamily: "Montserrat",
    color: "black",
    fontWeight: "700",
    fontSize: 12,
    textAlign: "left",
    maxWidth: "unset",
    marginBottom: 5,
  },
};
