import { Field, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorMessage from "~/presentation/components/ErrorMessage";
import {
  Switch,
  SwitchWrapper,
  Container,
  Form,
  Label,
  SelectField,
  InfoIcon,
  SubmitButton,
  CitySelectorsWrapper,
} from "./styles";
import Select from "~/presentation/components/Select";
import validationSchema from "./validation-schema";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import AppContext from "~/presentation/AppContext";
import { Search as SearchIcon } from "@mui/icons-material";
import KeyboardReturnRoundedIcon from "@mui/icons-material/KeyboardReturnRounded";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import OpenTooltip from "~/presentation/components/Tooltip";
import { Button } from "@mui/material";

const initialValues = {
  categoryId: null,
  companyType: null,
  isSimples: false,
  isCity: false,
  cityContractorId: null,
  cityServiceId: null,
  cityHireId: null,
};

const InfoTooltip = ({ text }) => (
  <OpenTooltip itemID="info-tooltip" text={text} placement="right">
    <InfoIcon fontSize="small" />
  </OpenTooltip>
);

const contractorTooltipText =
  "A natureza jurídica do contratante já está pré-selecionada de acordo com seu cadastro no sistema. “Empresa Privada” pode ter perfis jurídicos diferentes e figurar no cartão do CNPJ com diversos códigos de natureza jurídica (sociedade simples, limitada, anônima, empresário individual, etc.) É possível alterar o perfil do contratante, mas as respostas apresentadas podem ser diferentes.";
const hiredTooltipText =
  "Selecione o perfil do contratado, se é pessoa física, jurídica, cooperativa de trabalho ou MEI. “Pessoa Jurídica” abrange as sociedades em geral, o empresário individual, o EIRELI, as associações e fundações privadas e os serviços sociais autônomos.";
const isSimplesTooltipText =
  "Caso o contratado seja “Pessoa Jurídica”, selecione se é optante do Simples Nacional. Nos demais casos não é necessário.";
const informCitiesTooltipText =
  "Caso o contratado seja “Pessoa Jurídica”, especifique o município da pesquisa. Disponível no plano Diamante.";

export const SearchForm = ({
  companyUseCases,
  gtFacilUseCases,
  cityUseCases,
  onSubmit,
  getSearch,
}) => {
  const [search, setSearch] = useState();

  const loadSearch = async () => {
    setSearch(await getSearch());
  };

  const context = useContext(AppContext);
  const { openDialogBox } = useContext(DialogContext);
  const history = useHistory();

  const { companyId } = JSON.parse(localStorage.getItem("GT_user"));

  const [cities, setCities] = useState([]);
  const [companyCategories, setCompanyCategories] = useState([]);
  const [hiredTypes, setHiredTypes] = useState([]);
  const [currentCompany, setCurrentCompany] = useState({});
  const [simplesChecked, setSimplesChecked] = useState(false);
  const [cityChecked, setCityChecked] = useState(false);
  const [searchValues, setSearchValues] = useState(initialValues);

  const getCompanyCategories = async () => {
    const result = await companyUseCases.getCompanyCategories();
    setCompanyCategories(dataToSelectMapper(result));
  };

  const getHiredTypes = async () => {
    const result = [
      { value: 0, label: "Pessoa Jurídica (PJ)" },
      { value: 1, label: "Microempreendedor Individual (MEI)" },
      { value: 2, label: "Pessoa Física (PF)" },
      { value: 3, label: "Cooperativa de Trabalho" },
    ];
    setHiredTypes(result);
  };

  const getUserCompany = async () => {
    const result = await companyUseCases.getCompanyById(companyId);
    setCurrentCompany(result);
  };

  const getCities = async () => {
    const result = await cityUseCases.getAll();
    setCities(result);
  };

  useEffect(() => {
    getCompanyCategories();
    getHiredTypes();
    getUserCompany();
    getCities();
    loadSearch();
  }, []);

  useEffect(() => {
    setSearchValues({
      ...initialValues,
      categoryId: currentCompany?.category?.id,
      companyType: 0,
    });
  }, [currentCompany]);

  const groupValidations = (group, data) => {
    let question = "";
    let city = "";
    const { contractorId, serviceId, hireId } = data;
    if (group === "RG" && hireId !== contractorId) {
      city = cities.find((c) => c.id === hireId);
      question = `O tomador possui unidade em ${city.name}?`;
      return question;
    } else if (group === "ERF" && /*hireId*/ contractorId !== serviceId) {
      city = cities.find((c) => c.id === serviceId);
      question = `O tomador possui unidade em ${city.name}?`;
      return question;
    }
    return "";
  };

  const handleValidateDataBeforeSubmit = (values, onSubmit) => {
    if (values.cityContractorId && values.cityServiceId && values.cityHireId) {
      const { group } = search;
      const data = {
        contractorId: values.cityContractorId,
        serviceId: values.cityServiceId,
        hireId: values.cityHireId,
      };
      const question = groupValidations(group, data);
      let submitValues = { ...values };
      if (!question) return onSubmit(submitValues);
      openDialogBox({
        message: question,
        callback: () => {
          submitValues = { ...values, isBranch: true };
          onSubmit(submitValues);
        },
        negativeCallback: () => {
          submitValues = { ...values, isBranch: false };
          onSubmit(submitValues);
        },
      });
    } else {
      onSubmit(values);
    }
  };

  return (
    <Container>
      <Formik
        initialValues={searchValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, action) => {
          action.setSubmitting(true);
          handleValidateDataBeforeSubmit(values, onSubmit);
          action.setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="form-grid">
              <div className="col-1_5">
                <Label style={{ marginBottom: 10 }}>
                  Natureza Jurídica do Contratante:
                  <InfoTooltip text={contractorTooltipText} />
                </Label>
                <Field
                  name="categoryId"
                  component={Select}
                  options={companyCategories}
                  placeholder={"Selecione..."}
                  onChange={(option) => setFieldValue("categoryId", option.value)}
                />
                <ErrorMessage name="categoryId" />
              </div>

              <div className="col-1_5">
                <Label style={{ marginBottom: 10 }}>
                  Tipo do Contratado:
                  <InfoTooltip text={hiredTooltipText} />
                </Label>
                <Field
                  name="companyType"
                  component={Select}
                  options={hiredTypes}
                  placeholder={"Selecione..."}
                  onChange={(option) => {
                    setFieldValue("companyType", option.value);
                    setSimplesChecked(option.value === 1);
                    setFieldValue("isSimples", option.value === 1);
                  }}
                />
                <ErrorMessage name="companyType" />
              </div>

              <div className="col-1_5">
                {(() => {
                  const simples = values?.companyType === 0;
                  return (
                    <SwitchWrapper>
                      <Label>Optante do Simples Nacional:</Label>
                      <div id="switch">
                        <Switch
                          defaultChecked={false}
                          checked={simplesChecked}
                          disabled={!simples}
                          name="isSimples"
                          color="primary"
                          onChange={(event) => {
                            values.isSimples = event.target.checked;
                            setSimplesChecked(event.target.checked);
                          }}
                        />
                      </div>
                      <InfoTooltip text={isSimplesTooltipText} />
                    </SwitchWrapper>
                  );
                })()}
              </div>

              <div className="col-1_5">
                {(() => {
                  const hiredTypeAllowCities = values?.hiredType?.id === 2;
                  const disabled = !(hiredTypeAllowCities && currentCompany?.plan?.id === 5);
                  return (
                    <SwitchWrapper>
                      <Label>Deseja informar os municípios envolvidos na operação?</Label>
                      <div id="switch">
                        <Switch
                          name="isCity"
                          color="primary"
                          checked={cityChecked}
                          onChange={(event) => {
                            const shouldShowCities = event.target.checked;
                            values.isCity = shouldShowCities;
                            setCityChecked(shouldShowCities);

                            if (!shouldShowCities) {
                              setFieldValue("cityContractorId", "");
                              setFieldValue("cityHireId", "");
                              setFieldValue("cityServiceId", "");
                            }
                          }}
                          // disabled={!currentCompany?.plan?.isConsultISS}
                        />
                      </div>
                      <InfoTooltip text={informCitiesTooltipText} />
                    </SwitchWrapper>
                  );
                })()}
              </div>

              {values.isCity && (
                <CitySelectorsWrapper>
                  <div>
                    <Label>Município do Contratante:</Label>
                    <Field
                      name="cityContractorId"
                      component={SelectField}
                      options={cities}
                      placeholder={"Digite o nome do Município"}
                      open={true}
                      onChange={(option) => {
                        setFieldValue("cityContractorId", option.id);
                      }}
                      getOptionLabel={(option) => `${option.name} - ${option.uf}`}
                      getOptionValue={(option) => option}
                      // value={values.cityContractorId}
                    />
                    <ErrorMessage name="cityContractorId" />
                  </div>

                  <div>
                    <Label>Município do Contratado:</Label>
                    <Field
                      name="cityHireId"
                      component={SelectField}
                      options={cities}
                      placeholder={"Digite o nome do Município"}
                      onChange={(option) => {
                        setFieldValue("cityHireId", option.id);
                      }}
                      getOptionLabel={(option) => `${option.name} - ${option.uf}`}
                      getOptionValue={(option) => option}
                      // value={values.cityHireId}
                    />
                    <ErrorMessage name="cityHireId" />
                  </div>

                  <div>
                    <Label>Município da Prestação:</Label>
                    <Field
                      name="cityServiceId"
                      component={SelectField}
                      options={cities}
                      placeholder={"Digite o nome do Município"}
                      onChange={(option) => {
                        setFieldValue("cityServiceId", option.id);
                      }}
                      getOptionLabel={(option) => `${option.name} - ${option.uf}`}
                      getOptionValue={(option) => option}
                      // value={values.cityServiceId}
                    />
                    <ErrorMessage name="cityServiceId" />
                  </div>
                </CitySelectorsWrapper>
              )}

              {/* <div className="col-1_5 flex">
                <SubmitButton type="submit"><SearchIcon/>PESQUISAR</SubmitButton>
              </div> */}
              <div className="col-1_5 flex">
                <SubmitButton type="submit" style={{ fontSize: "16px" }}>
                  <SearchIcon />
                  PESQUISAR
                </SubmitButton>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  history.push("/gt-facil");
                }}
              >
                <KeyboardReturnRoundedIcon />
                Reiniciar pesquisa
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
