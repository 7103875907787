import React from "react";
import { Box, Skeleton, Typography } from "@mui/material";

const DEFAULT_IMAGE = "/assets/bgartigos.png";

export const MostAccessedSection = ({ mostAccessedPosts, goToPost }) => {
  const skeletonArray = Array.from({ length: 3 });

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>Mais Acessados</Typography>
      {mostAccessedPosts.length > 0 ? (
        mostAccessedPosts?.map((post) => {
          const year = post?.publishDate && new Date(post?.publishDate).getUTCFullYear();
          const author = post.autor && "por " + post.autor + ".";
          return (
            <Box
              key={"most-accessed-line-" + post.id}
              onClick={() => goToPost(post.id)}
              sx={styles.card.container}
            >
              <Box
                component="img"
                sx={styles.card.img}
                src={post.imageSrc || DEFAULT_IMAGE}
                onError={(event) => {
                  event.target.src = DEFAULT_IMAGE || "";
                  event.onerror = null;
                }}
                alt="small-card-image"
              />
              <Box sx={{ padding: "0.6rem 1.6rem" }}>
                <Typography
                  sx={{
                    ...styles.card.text,
                    lineHeight: "24.38px",
                    letterSpacing: "-0.03em",
                    fontSize: "1rem",
                    fontWeight: 600,
                  }}
                >
                  {post.title}
                </Typography>
                {post.publishDate && (
                  <Typography
                    sx={{
                      ...styles.card.text,
                      lineHeight: "19.5px",
                      letterSpacing: "-0.03em",
                      fontSize: "0.9rem",
                      fontWeight: 300,
                      fontStyle: "italic",
                    }}
                  >
                    Publicado em {year} {author}
                  </Typography>
                )}
              </Box>
            </Box>
          );
        })
      ) : (
        <Box>
          {skeletonArray.map((_, index) => (
            <Skeleton sx={styles.skeleton} key={index} variant="rounded" height={130} />
          ))}
        </Box>
      )}
    </Box>
  );
};

const styles = {
  skeleton: {
    marginBlock: 4,
  },
  title: {
    fontFamily: "Montserrat",
    letterSpacing: "-0.03em",
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  card: {
    container: {
      background: "#E1E1E1",
      borderRadius: "6px",
      marginTop: 2,
      marginBottom: 4,
      display: "flex",
      flexDirection: "column",
      gap: 1,
      cursor: "pointer",
      transition: "transform 0.3s ease-out, background-color 0.3s ease-out",
      "&:hover": {
        transform: "scale(1.01)",
        backgroundColor: "#E9E9E9",
      },
    },
    img: {
      width: "100%",
      height: 120,
      objectFit: "cover",
      borderRadius: "6px 6px 0 0 ",
    },
    text: {
      fontFamily: "Montserrat",
      textAlign: "left",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      whiteSpace: "normal",
    },
  },
};
