import React from "react";
import {TextField} from "~/presentation/components/TextInput/styles";

const TextInput = ({
  label,
  variant,
  color,
  backgroundColor,
  borderRadius,
  borderColor,
  height,
  fontSize,
  ...props
}) => {
  return (
    <TextField
      label={label}
      variant={variant || "outlined"}
      color={color}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      borderColor={borderColor}
      height={height}
      fontSize={fontSize}
      {...props}
    />
  );
};

export default TextInput;
