import React from "react";
import { ErrorMessage as ErrMsg } from "formik";

import styled from "styled-components";

const Wrapper = styled.span`
  font-family: sans-serif;
  color: #d32f2f;
  font-size: clamp(13px, 1vw, 14px);
`;

export const ErrorMessage = ({ children }) => (
  <Wrapper className="errorMessage">{children}</Wrapper>
);

export const FormikCustomErrorMessage = (props) => (
  <Wrapper className="errorMessage" {...props}>
    <ErrMsg {...props} />
  </Wrapper>
);

export default FormikCustomErrorMessage;
