import styled from "styled-components";
import { darken } from "polished";
import { Button as MuiButton } from "@mui/material";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 70px repeat(5, 50px) 100px repeat(2, 50px);
  grid-template-rows: repeat(6, 42px);
  align-items: center;
  justify-items: center;
  background-color: ${darken(0.05, "#eee")};
  padding: 12px;
  border-radius: 10px;
  width: fit-content;

  span {
    text-align: center;
  }
`;

export const Button = styled(MuiButton)`
  &.MuiButton-root {
    min-width: unset;
    width: 100%;
    height: 100%;
    color: #383f48;
    background-color: #383f48;
  }
`;
