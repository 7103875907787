import React, { useContext, useEffect, useState } from "react";
import { formatDateToBrazilian } from "../../Utils/utilsDate";
import { formatNumberToRealString } from "../../Utils/utilsCurrencyvalue";
import { formatCpfCnpj, getDocType } from "../../Utils/utilsReinf";
import RenderLayout from "../../components/Layouts/RenderLayout";
import { ApiServiceClassification } from "~/data/usecases/serviceClassification/apiServiceClassification";
import { ApiIncomeNature } from "~/data/usecases/incomeNature/incomeNature";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import {
  Delete,
  DeleteForever,
  FileOpenOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight,
  ReportProblem,
  Update,
} from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  Tooltip,
  TableRow,
  Box,
} from "@mui/material";
import { ApiBusinessPartnerUseCases } from "~/data/usecases/businessPartner/businessPartner";

function DocTable({
  onDelete,
  data,
  handleChangeFieldValue,
  handleChangeINSSfield,
  errors,
  setErrors,
}) {
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO
  const QSOAB = QSO === "AB" || QSO === "B";
  const QSOD = QSO === "D";

  const apiServiceclass = new ApiServiceClassification(makeHttpClient());
  const apiIncomeNature = new ApiIncomeNature(makeHttpClient());
  const apiBusinessPartner = new ApiBusinessPartnerUseCases(makeHttpClient());

  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  //SELECT INPUT OPTIONS
  const [serviceClassificationOptions, setServiceClassificationOptions] = useState([]); //select Classificação Serviço
  const [incomeNatureOptions, setIncomeNatureOptions] = useState([]); //select Natureza Rendimento
  const [businessPartnerOptions, setBusinessPartnerOptions] = useState([]); //lista de contratados

  //ABERTURA E FECHO DE CADA FICHA (COLLAPSE)
  const [openCollapse, setOpenCollapse] = useState({});
  //PAGINAÇÃO
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = data.length > 0 && data.slice(startIndex, endIndex);

  //Open/Close Collapse
  const handleOpenCollapse = (index, isOpen) => {
    const targetTagName = event.target.tagName.toLowerCase();
    if (targetTagName === "svg" || targetTagName === "path") {
      return; // Impedir a abertura do collapse se clicar no ícone de exclusão
    }
    setOpenCollapse((prev) => ({
      ...prev,
      [index]: !isOpen,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setOpenCollapse({});
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderFileIcon = (R2010, R4020, nfseChanged) => {
    const sent = R2010 || R4020;
    if (nfseChanged) {
      return (
        <Tooltip title="Existem dados que ainda não foram salvos">
          <ReportProblem sx={{ color: "#DCAD23" }} />
        </Tooltip>
      );
    }
    if (sent) {
      return (
        <Tooltip title={`Doc. Fiscal já enviado no ${R2010 ? "R-2010" : "R-4020"}.`}>
          <FileOpenOutlined color="action" fontSize="small" />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title="Doc. Fiscal ainda não foi enviado.">
          <Update color="action" fontSize="small" />
        </Tooltip>
      );
    }
  };

  const renderDeleteIcon = (sent = false, id = null) => {
    if (sent) {
      return (
        <div>
          <Tooltip title="Doc. Fiscal vinculado a um evento enviado">
            <DeleteForever color="action" style={{ cursor: "auto" }} />
          </Tooltip>
        </div>
      );
    } else {
      return (
        <Tooltip title="Excluir Doc. fiscal">
          <Delete
            color="action"
            onClick={() => {
              onDelete(id);
            }} // Passa o ID da NFSe para a função de exclusão
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    const fetchDataOptions = async () => {
      try {
        const serviceClassResponse = await apiServiceclass.listServiceClassification();
        const incomeNatureResponse = await apiIncomeNature.listIncomeNature();
        const businessPartnerResponse = await apiBusinessPartner.listBusinessPartner();

        if (serviceClassResponse) {
          if (Array.isArray(serviceClassResponse)) {
            setServiceClassificationOptions(serviceClassResponse);
          } else {
            setFeedbackSnackbar({
              isOpen: true,
              message: "Os dados de classificação de serviço não foram carregados",
              type: "error",
            });
          }
        }

        if (incomeNatureResponse) {
          if (Array.isArray(incomeNatureResponse)) {
            setIncomeNatureOptions(incomeNatureResponse);
          } else {
            setFeedbackSnackbar({
              isOpen: true,
              message: "Os dados de Natureza de Rend. não foram carregados",
              type: "error",
            });
          }
        }
        if (businessPartnerResponse) {
          if (Array.isArray(businessPartnerResponse)) {
            setBusinessPartnerOptions(businessPartnerResponse);
          } else {
            setFeedbackSnackbar({
              isOpen: true,
              message: "os dados do contratado não foram carregados",
              type: "error",
            });
          }
        }
      } catch (error) {
        setFeedbackSnackbar({
          isOpen: true,
          message: "Erro ao buscar as opções de seleção.",
          type: "error",
        });
      }
    };
    fetchDataOptions();
  }, []);

  return (
    <Box sx={{ borderRadius: "10px", overflow: "hidden", marginInline: 3, marginBlock: 2 }}>
      <Table sx={{ borderCollapse: "separate", borderSpacing: "0" }}>
        <TableHead>
          <TableRow>
            <TableCell sx={styles.TableCellHead}></TableCell>
            <TableCell sx={styles.TableCellHead}>Status</TableCell>
            <TableCell sx={styles.TableCellHead}>NºDoc</TableCell>
            <TableCell sx={styles.TableCellHead}>CPNJ/CPF do Contratado</TableCell>
            <TableCell sx={styles.TableCellHead}>Nome do Contratado</TableCell>
            <TableCell sx={styles.TableCellHead}>Data de emissão</TableCell>
            <TableCell sx={styles.TableCellHead}>Data de pagamento</TableCell>
            <TableCell sx={styles.TableCellHead}>Valor da Operação</TableCell>
            <TableCell sx={styles.TableCellHead}>Ret. INSS</TableCell>
            <TableCell sx={styles.TableCellHead}>{QSOAB ? "Ret. Agregada" : "Ret. IR"}</TableCell>
            {QSOD && <TableCell sx={styles.TableCellHead}>Ret. CSRF</TableCell>}
            <TableCell sx={styles.TableCellHead}>CPNJ/CPF do Contratante</TableCell>
            <TableCell sx={styles.TableCellHead}>Tipo de Doc.</TableCell>
            <TableCell sx={styles.TableCellHead}>
              <Delete />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentData.length > 0 ? (
            currentData?.map((item, index) => {
              //este realIndex assegura passar o index real do array data para o component
              //que será issencial para a função onChange dos campos
              //  !!! NÃO RETIRAR !!!
              const realIndex = startIndex + index;
              const isOpen = openCollapse[index] || false;
              const sent = item?.isR2010 || item?.isR4020;
              //saber se a nota já teve alteração
              const nfseChanged = item?.hadChanges;
              //style para nos alteradas
              const styledColor = "#FAE4A2"; //amarelo claro
              const styledBorder = `2px solid #F3B80C`; //amarelo torrado (escuro)
              const styledCell = {
                fontFamily: "Montserrat ",
                fontSize: 14,
                padding: "6px",
                color: "#000000",
                textAlign: "center",
                borderBlock: nfseChanged ? styledBorder : "none",
              };

              return (
                <React.Fragment key={index}>
                  <TableRow
                    sx={{
                      cursor: "pointer",
                      backgroundColor: nfseChanged ? styledColor : "#D9D9D9",
                    }}
                    onClick={() => {
                      handleOpenCollapse(index, isOpen);
                    }}
                  >
                    <TableCell
                      sx={{ ...styledCell, borderLeft: nfseChanged ? styledBorder : "none" }}
                      onClick={() => {
                        setOpenCollapse((prev) => ({
                          ...prev,
                          [index]: !isOpen,
                        }));
                      }}
                    >
                      <IconButton aria-label="expand row" size="small">
                        {isOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                      </IconButton>
                    </TableCell>
                    <TableCell sx={styledCell}>
                      <Box sx={{ paddingTop: "3px" }}>
                        {renderFileIcon(item.isR2010, item.isR4020, nfseChanged)}
                      </Box>
                    </TableCell>
                    <TableCell sx={styledCell}>{item.nFseNumber}</TableCell>
                    <TableCell sx={styledCell}>{formatCpfCnpj(item.businessPartnerCNPJ)}</TableCell>
                    <TableCell sx={styledCell}>{item.businessPartnerName}</TableCell>
                    <TableCell sx={styledCell}>{formatDateToBrazilian(item.dateOfIssue)}</TableCell>
                    <TableCell sx={styledCell}>
                      {item.dateOfPayment ? formatDateToBrazilian(item.dateOfPayment) : "-"}
                    </TableCell>
                    <TableCell sx={styledCell}>
                      {formatNumberToRealString(item.amountOperation)}
                    </TableCell>
                    <TableCell sx={styledCell}>
                      {formatNumberToRealString(item.amountINSS)}
                    </TableCell>
                    <TableCell sx={styledCell}>
                      {formatNumberToRealString(QSOAB ? item.amountAggregate : item.amountIR)}
                    </TableCell>
                    {QSOD && (
                      <TableCell sx={styledCell}>
                        {formatNumberToRealString(item.amountCSRF)}
                      </TableCell>
                    )}
                    <TableCell sx={styledCell}>{formatCpfCnpj(item.companyCNPJ)}</TableCell>
                    <TableCell sx={styledCell}>{getDocType(item.typeDoc)}</TableCell>
                    <TableCell
                      sx={{ ...styledCell, borderRight: nfseChanged ? styledBorder : "none" }}
                    >
                      {renderDeleteIcon(sent, item.id)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={styles.CollapseContainer} colSpan="100%">
                      <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <RenderLayout
                          nfseData={item}
                          nfseIndex={realIndex}
                          serviceClassificationOptions={serviceClassificationOptions}
                          incomeNatureOptions={incomeNatureOptions}
                          businessPartnerOptions={businessPartnerOptions}
                          handleChangeFieldValue={handleChangeFieldValue}
                          handleChangeINSSfield={handleChangeINSSfield}
                          errors={errors}
                          setErrors={setErrors}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })
          ) : (
            <TableRow>
              <TableCell sx={styles.TableCell} colSpan="100%">
                <p style={{ textAlign: "center" }}>Nenhuma informação encontrada...</p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {data.length > 9 && (
        <Stack
          spacing={1}
          sx={{ background: "#F2F2F2", paddingRight: 8, borderRadius: "0px 0px 8px 8px" }}
        >
          <TablePagination
            component="div"
            count={data?.length || 0}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Informações por página:"
            rowsPerPageOptions={[10, 15, 20]}
            sx={{
              ".MuiTablePagination-selectLabel ": {
                margin: 0,
              },
              ".MuiTablePagination-displayedRows": {
                margin: 0,
              },
            }}
          />
        </Stack>
      )}
    </Box>
  );
}

export default DocTable;

const styles = {
  //LINHA TÍTULOS COLUNAS
  HeadTableRow: {},
  //LINHA TÍTULOS DAS COLUANS
  TableCellHead: {
    backgroundColor: "#1341A1",
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#ffffff",
    fontSize: 13,
    padding: "10px",
    fontWeight: 400,
    position: "relative",
    //DIVISOR COLUNAS HEAD
    "&:not(:first-child)::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "10%",
      bottom: "10%",
      left: 0,
      width: "3px",
      backgroundColor: "#142F68",
    },
  },
  TableCell: {
    fontFamily: "Montserrat ",
    fontSize: 14,
    padding: "10px",
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
  },
  //CADA LINHA
  TableRow: {},
  //COLAPSE
  CollapseContainer: {
    paddingBlock: "3px",
    backgroundColor: "#F2F2F2",
  },
};
