import styled from "styled-components";
import { Radio as MuiRadio } from "@mui/material";
import BasePrimaryButton from "~/presentation/components/PrimaryButton";

export const Container = styled.div`
  .errorMessage {
    font-size: 14px;
  }
`;

export const Radio = styled(MuiRadio)`
  &.MuiRadio-root,
  &.MuiRadio-root.Mui-checked {
    color: ${(props) => "#383f48"};
  }
`;

export const RadiosWrapper = styled.div`
  margin: 32px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-row-gap: 8px;

  @media screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 250px);
    grid-template-rows: min-content 1fr;
    grid-auto-flow: column;
  }
`;

export const PrimaryButton = styled(BasePrimaryButton).attrs({
  fullWidth: true,
})``;

export const SuccessButton = styled(BasePrimaryButton).attrs({
  backgroundColor: "green",
})`
  &.MuiButton-root {
    width: 50%;
    margin-left: 25%;
  }
`;
