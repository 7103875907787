import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";

import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import PageListHeader from "../../components/PageListHeader";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";

const columns = [
  { name: "name", label: "Nome" },
  { name: "letter", label: "Letra" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const LegislationCategoryTable = ({ history, legislationCategoryUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [categories, setCategories] = useState([]);
  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const categories = await legislationCategoryUseCases.getLegislationCategories();

    const userIsSuperAdmin = context.haveSuperAdminAccess();

    categories.forEach((category) => {
      if (userIsSuperAdmin) {
        category.actions = (
          <TableActions
            handleDelete={() => deleteDialog(category.id)}
            handleEdit={() => handleEdit(category.id)}
          />
        );
      } else {
        category.actions = <TableActions handleEdit={() => handleEdit(category.id)} />;
      }
    });

    setCategories(categories);
    openBackdrop(false);
  };

  const handleEdit = (id) => {
    history.push(`/admin/categoria-legislacao/editar/${id}`);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo categoria");
    try {
      await legislationCategoryUseCases.deleteLegislationCategory(id);
      fetchData();
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir categoria: Missing endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta categoria?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  const handleAdd = () => history.push("/admin/categoria-legislacao/cadastrar");

  return (
    <Container maxWidth="100%">
      <PageListHeader
        title="Categoria de Legislação"
        titleAccess="Nova categoria"
        searchFieldRef={searchFieldRef}
        handleAdd={handleAdd}
        handleSearch={handleSearch}
      />
      <Table searchRef={hiddenInputRef} columns={columns} data={categories} />
    </Container>
  );
};

export default LegislationCategoryTable;
