import { filterByEqual, filterByInclude } from "~/data/functions/fakeApiFilters";
import fakeEmailList from "./fake-email-list.json";
import { format } from "date-fns";

export class ApiEmailUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getEmails(page, size, filter, search) {
    let response = {
      data: fakeEmailList.map((e) => ({
        id: e.id,
        subject: e.subject,
        excerpt: e.excerpt,
        createdAt: e.createdAt,
        status: e.status,
      })),
    };

    try {
      await this.httpClient.request({
        url: `/email?status=${filter}&limit=${size}&search=${search}&page=${page}`,
        method: "GET",
      });
    } catch (err) {
      console.log(err);
    }
    let emails = response.data;

    emails.forEach((e) => {
      e.date = format(new Date(e.createdAt), "dd/MM/yyyy");
      e.filter = e.status;
    });

    emails = filterByEqual(emails, filter);
    emails = filterByInclude(emails, search, "subject");

    return {
      page: page,
      numberOfPages: Math.ceil(emails.length / size),
      results: emails.splice(size * (page - 1), size),
    };
  }

  async submitNewEmail(data) {
    const response = await this.httpClient.request({
      url: "/email",
      method: "POST",
      data,
    });
    return response.data;
  }

  async getEmailAddresses() {
    let response = {
      data: [
        "contato@gestaotributaria.com.br",
        "site@gestaotributaria.com.br",
        "financeiro@gestaotributaria.com.br",
        "if@gestaotributaria.com.br",
      ],
    };

    try {
      await this.httpClient.request({
        url: "/email/addresses",
        method: "GET",
      });
    } catch (err) {
      console.log(err);
    }
    return response.data;
  }

  async getEmailChat(id) {
    let response = {
      data: fakeEmailList.find((l) => l.id == id),
    };
    try {
      await this.httpClient.request({
        url: `/email/chat/${id}`,
        method: "GET",
      });
    } catch (err) {
      console.log(err);
    }
    return response.data;
  }

  async emailReply(chatId, data) {
    const response = await this.httpClient.request({
      url: `/email/reply/${chatId}`,
      method: "PUT",
      data,
    });
    return response.data;
  }

  async deleteEmails(ids) {
    const response = await this.httpClient.request({
      url: "/email",
      method: "DELETE",
      data: { ids },
    });
    return response.data;
  }
}
