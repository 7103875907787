import React, {useContext, useEffect, useRef, useState} from "react";
import { Formik, Form } from "formik";

import {
  Button,
  FormWrapper,
  RadioQuestion,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { floatToReais } from "~/presentation/utils/reais-parser";
import { ISSCalculator } from "~/presentation/views/GTFacil/Simulator/entities/iss-calculator";
import { validationSchema } from "~/presentation/views/GTFacil/Simulator/forms/Individual/iss/validationSchema.js";
import {
  BaseRetentionCalcValue,
  HasMaterialExclusion,
  ISSResultCard,
  MaterialExclusionValue,
  RetentionAliquotPercentage,
} from "~/presentation/views/GTFacil/Simulator/forms/common/iss/questions";
import { reportMapper } from "~/presentation/views/GTFacil/Simulator/forms/Individual/iss/reportMapper.js";

const initialValues = {
  hasIssRetention: true,
  hasExclusions: false,
  exclusionValue: 0,
  retentionAliquotPercentage: 0,
  isAliquotHighlighted: true,
};

export const IssIndividual = () => {
  const resultCardRef = useRef();
  const { simulatorValue, setInvalidSimulatorValue, handleGenerateReport, handleCacheReport } = useContext(SimulatorContext);
  const [result, setResult] = useState();
  const [lastSubmitValues, setLastSubmitValues] = useState("");

  const issCalculatorFactory = (values) =>
    new ISSCalculator({
      simulatorValue,
      exclusionValue: values.exclusionValue,
      retentionAliquotPercentage: values.retentionAliquotPercentage,
    });

  const valuesNotChangedSinceLastSubmit = (values) => {
    return JSON.stringify(lastSubmitValues) === JSON.stringify(values);
  };

  const handleGenerateReportClick = (values) => {
    const response = reportMapper(values, result);
    handleGenerateReport(response);
  };

  useEffect(() => {
    if(result) {
      resultCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }
        action.setSubmitting(true);
        setLastSubmitValues(values);
        const issCalculator = issCalculatorFactory(values);
        setResult(issCalculator.issRetentionValue);
        resultCardRef.current.scrollIntoView({ behavior: "smooth" });

        action.setSubmitting(false);

        const response = reportMapper(issCalculator, issCalculator.issRetentionValue);

        handleCacheReport(response);
      }}
    >
      {({ setFieldValue, values }) => {
        const issCalculator = issCalculatorFactory(values);

        return (
          <Form>
            <FormWrapper>
              <RadioQuestion
                text="Tem retenção de ISS?"
                onChange={(value) => setFieldValue("hasIssRetention", value === "true")}
                value={JSON.stringify(values.hasIssRetention)}
              />
              {values.hasIssRetention === true ? (
                <>
                  <HasMaterialExclusion
                    onChange={() => {
                      setFieldValue("exclusionValue", initialValues.exclusionValue);
                    }}
                  />

                  {values.hasExclusions === true ? <MaterialExclusionValue /> : null}
                  <BaseRetentionCalcValue value={issCalculator.baseRetentionCalcValue} />

                  {values.isAliquotHighlighted ? <RetentionAliquotPercentage /> : null}

                  <Button mt>Ver Resultado</Button>
                  {result && valuesNotChangedSinceLastSubmit(values) ? (
                    <ISSResultCard
                      value={floatToReais(result)}
                      componentRef={resultCardRef}
                      handleGenerateReportClick={() => handleGenerateReportClick(issCalculator)}
                    />
                  ) : null}
                </>
              ) : null}
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
