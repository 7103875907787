import styled from "styled-components";
import PrimaryButton from "~/presentation/components/PrimaryButton";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: max(20px, 1.5vw);
`;

const TextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: max(8px, 0.5vw);
`;

const Text = styled.div`
  font-size: max(18px, 1.3vw);
  font-weight: 300;
  line-height: 1.5;

  > b {
    font-weight: 500;
  }
`;

const AnswerCardsWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, 1fr);

  @media screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  @media screen and (min-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  }
`;

const Button = styled(PrimaryButton).attrs({
  backgroundColor: "#1341a1",
  size: "small",
})`
  .MuiButton-label {
    display: flex;
    gap: 5px;
    align-items: center;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
  margin-top: 32px;
  justify-content: flex-end;

  @media screen and (min-width: 600px) {
    flex-flow: row;
    margin-right: 16px;
  }
`;

export const AnswerStyles = {
  Container,
  Text,
  TextWrapper,
  AnswerCardsWrapper,
  Button,
  ButtonsWrapper
}