import React from "react";
import Add from "@mui/icons-material/Add";
import SearchField from "~/presentation/components/SearchField";
import { Grid, IconButton, Paper, Typography } from "@mui/material";

const PageListHeaderWithFilter = ({
  handleAdd,
  searchFieldRef,
  handleSearch,
  titleAccess,
  title,
  doSearchWhenUserClickInTheIcon,
  onClickSearchIcon,
  filterComp,
}) => {
  return (
    <>
      <Paper elevation={1} sx={styles.Box}>
        <Grid container alignItems={"center"} spacing={1}>
          <Grid item sm={8}>
          <Typography sx={styles.title}>{title}</Typography>
          </Grid>
          {handleSearch ? (
            <Grid item sm={3}>
              <SearchField
                parentRef={searchFieldRef}
                handleSearch={handleSearch}
                doSearchWhenUserClickInTheIcon={doSearchWhenUserClickInTheIcon}
                onClickSearchIcon={onClickSearchIcon}
              />
            </Grid>
          ) : (
            <Grid item sm={3} />
          )}
          <Grid item>{filterComp}</Grid>
          {handleAdd ? (
            <Grid item>
              <IconButton onClick={handleAdd}>
                <Add titleAccess={titleAccess} />
              </IconButton>
            </Grid>
          ) : (
            <Grid item />
          )}
        </Grid>
      </Paper>
    </>
  );
};

export default PageListHeaderWithFilter;

const styles = {
  Box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
    marginBottom: 2,
    marginTop: 2,
    paddingBlock: 1,
    paddingInline: 1,
    "@media (min-width: 600px)": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },

  title: {
    fontFamily: "Montserrat, sans-serif",
    textAlign: "left",
    fontSize: 28,
    fontWeight: 600,
    color: "#242424",
  },
};
