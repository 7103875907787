import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  gap: 32px;
`;

export const ValueInputWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  flex-flow: column;

  @media (min-width: 600px) {
    flex-flow: row;
  }
`;

export const Label = styled.p`
  font-size: max(22px, 1.4vw);
  margin-right: 8px;
  font-family: "Roboto", sans-serif;
`;

export const Text = styled.p`
  font-size: max(18px, 1.3vw);
  font-family: "Roboto", sans-serif;
`;

export const ContainerErrorGrossValue = styled.div``;