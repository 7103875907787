import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  height: auto;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content;
  transition: all 0.2s ease-in-out;

  .navbar-space {
    height: ${({ mobile }) => (mobile ? "75px" : "70px")};
    width: 100%;
    background: #fafafa;
  }

  .loading {
    align-self: center;
    justify-self: center;
  }

  /* Rule that makes this container overflow instead of window on mobile */
  overflow-y: ${({ mobile }) => (mobile ? "auto" : "unset")};

  &.drawerOpen {
    transform: scale(0.9) translateX(-50%);
  }
`;
