import React, { useContext } from "react";
import { Field, Form, Formik } from "formik";
import AppContext from "~/presentation/AppContext";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import validationSchema from "~/presentation/views/Client/ContactUs/components/ContactForm/schema";

import { Container, TextInput, TextArea, SecondaryButton, ErrorMessage } from "./styles";
import { CircularProgress } from "@mui/material";

const initialValues = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

export const ContactForm = ({ onSubmit }) => {
  const context = useContext(AppContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const handleSubmit = async (values) => {
    try {
      await onSubmit(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Mensagem enviada com sucesso!",
        type: "success",
      });
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao enviar mensagem: Missing endpoint",
        type: "error",
      });
    }
  };

  return (
    <Container>
      <h3>FALE CONOSCO:</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          await handleSubmit(values);
          action.setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form data-animation="wrapper">
            <div className="form-grid">
              <div className="col-1_5">
                <label>NOME:</label>
                <Field name="name" type="text" as={TextInput} />
                <ErrorMessage name="name" />
              </div>

              <div className="col-1_5">
                <label>E-MAIL:</label>
                <Field name="e-mail" type="text" as={TextInput} />
                <ErrorMessage name="email" />
              </div>

              <div className="col-1_5">
                <label>ASSUNTO:</label>
                <Field name="subject" type="text" as={TextInput} />
                <ErrorMessage name="subject" />
              </div>

              <div className="col-1_5">
                <label>MENSAGEM:</label>
                <Field name="message" rows={5} as={TextArea} />
                <ErrorMessage name="message" />
              </div>
              <div className="col-1_5">
                <SecondaryButton type="submit" size="small">
                  {isSubmitting ? <CircularProgress color="inherit" size="22px" /> : "ENVIAR"}
                </SecondaryButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
