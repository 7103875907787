import styled from "styled-components";
import SectionHeaderBase from "~/presentation/views/Blog/components/SectionHeader";
import { Pagination as MuiPagination } from "@mui/material";

export const Container = styled.div`
  // padding: 0% 2% 5% 2%;
  min-width: 58.75rem;

  @media (max-width: 1250px) {
    min-width: auto;
    margin-top: 8rem;
  }
`;

export const CardsWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-row-gap: max(6vw, 40px);
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3rem;

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

export const SectionHeader = styled(SectionHeaderBase)`
  margin-bottom: max(2.5vw, 25px);
`;

export const ButtonWrapper = styled.div`
  align-self: center;
  margin: 4rem auto;
  width: fit-content;

  > button {
    background-color: #ff6829;
    color: #fff;
  }
`;

export const Button = styled.button`
  outline: none;
  background: transparent;
  border: solid #ff6829 2px;
  border-radius: 50px;
  padding: 1rem;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  padding: 10px 15px;
  color: #ff6829;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  margin: 0 auto 2rem auto;
`;

export const Pagination = styled(MuiPagination)`
  justify-self: center;
  padding: 30px;
  width: fit-content;
  margin: 1rem auto 0 auto;

  @media screen and (min-width: 960px) {
    grid-column: 1/4;
  }

  &.MuiPagination-root {
    display: flex;
    align-items: center;
    color: #fff;
    .MuiPaginationItem-root {
      color: #1341a1;

      &.Mui-selected {
        background-color: #fafafa;
        color: #1341a1;
      }
    }
  }
`;
