import React, { useContext, useEffect, useRef, useState } from "react";
import {Container, Box, Grid} from "@mui/material";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import StatsTable from "~/presentation/views/Admin/Stats/Display/StatsTable";
import { statsTableColumns } from "~/presentation/views/Admin/Stats/Display/statsTableColumns";
import { StatsForm } from "~/presentation/views/Admin/Stats/Display/StatsForm";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import PageListHeader from "../../components/PageListHeader";

const Stats = ({ statsUseCases, companyUseCases, userUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const hiddenInputRef = useRef();

  const [searchType, setSearchType] = useState("");
  const [companiesData, setCompaniesData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [statsData, setStatsData] = useState([]);

  useEffect(() => {
    getSelectOptions();
  }, []);

  const getSelectOptions = async () => {
    openBackdrop(true, "Carregando...");
    try {
      const response = await Promise.all([
        companyUseCases.getCompanies(true),
        userUseCases.getUsers(),
      ]);
      setCompaniesData(dataToSelectMapper(response[0]));
      setUsersData(dataToSelectMapper(response[1]));
    } catch (err) {
      console.log(err);
    }
    openBackdrop(false);
  };

  const handleSubmit = async (formData) => {
    openBackdrop(true, "Carregando dados da tabela");
    switch (searchType) {
      case "search":
        setStatsData(
          await statsUseCases.getStatsSearch(
            formData.companyId,
            formData.userId,
            formData.initialDate,
            formData.finalDate
          )
        );
        break;

      case "search-summary":
        setStatsData(await statsUseCases.getStatsSearchSummary(formData.companyId));
        break;

      case "content-access":
        setStatsData(
          await statsUseCases.getStatsContentAccess(formData.companyId, formData.isDetail)
        );
        break;

      default:
        break;
    }
    openBackdrop(false);
  };

  return (
    <Container maxWidth={false} style={{marginBottom: "2rem"}}>
      <PageListHeader title="Visualizações" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StatsForm
            companiesData={companiesData}
            usersData={usersData}
            handleSubmit={handleSubmit}
            setStatsData={setStatsData}
            searchType={searchType}
            setSearchType={setSearchType}
          />
        </Grid>
        <Grid item xs={12}>
          <StatsTable
            data={statsData}
            columns={statsTableColumns[searchType]}
            searchRef={hiddenInputRef}
            searchType={searchType}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Stats;
