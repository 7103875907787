import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Typography as Text,
  Grid,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useUploadXml } from "../../Hooks/useUploadXml";
import SIAFI from "./components/SIAFI";
import NOTA from "./components/NOTA";
import SHEET from "./components/SHEET";

export const ModalImport = ({ handleClose }) => {
  const { handleNOTAS, handleSHEET, handleSIAFI, files, setFiles } = useUploadXml();
  const [importType, setImportType] = useState("NOTA");
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO"));

  const handleChangeImportType = (e) => {
    setImportType(e.target.value);
    setFiles([]);
  };

  const renderImportType = () => {
    if (importType === "SIAFI") {
      return (
        <SIAFI
          handleSIAFI={handleSIAFI}
          files={files}
          setFiles={setFiles}
          handleClose={handleClose}
        />
      );
    }

    if (importType === "NOTA") {
      return (
        <NOTA
          handleNOTAS={handleNOTAS}
          files={files}
          setFiles={setFiles}
          handleClose={handleClose}
        />
      );
    }

    if (importType === "SHEET") {
      return (
        <SHEET
          handleSHEET={handleSHEET}
          files={files}
          setFiles={setFiles}
          handleClose={handleClose}
        />
      );
    }
  };

  return (
    <Container style={styles.Form}>
      <Grid container spacing={2}>
        <Grid item xs={10} sm={11}>
          <Text style={styles.Title}>Importar Documentos Fiscais</Text>
        </Grid>
        <Grid
          item
          xs={2}
          sm={1}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <Close onClick={handleClose} />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Tipo de arquivo</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={importType}
              onChange={handleChangeImportType}
            >
              <FormControlLabel value="NOTA" control={<Radio />} label="NFS-e/NF-e (xml)" />
              {(QSO === "A" || QSO === "B") && ( // Opção apenas para linhas A e B
                <FormControlLabel value="SIAFI" control={<Radio />} label="Documento SIAFI" />
              )}
              <FormControlLabel
                value="SHEET"
                control={<Radio />}
                label="Planilha Padrão GT (Excel)"
              />
              {/* <FormControlLabel value=".csv" control={<Radio />} label="Ordens Bancárias (csv)" /> */}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      {renderImportType()}
    </Container>
  );
};

const styles = {
  Form: {
    padding: "2rem",
  },
  Title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 20,
    fontWeight: 600,
    color: "#404040",
    display: "flex",
    alignItems: "center",
  },
};
