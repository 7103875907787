import React from "react";
import { Formik } from "formik";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextEditor from "~/presentation/components/TextEditor";
import ErrorMessage from "~/presentation/components/ErrorMessage";
import { Form, FilesInputWrapper } from "~/presentation/views/Admin/components/MessageReply/styles";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
import validationSchema from "~/presentation/views/Admin/components/MessageReply/validationSchema";

const MessageReply = ({ draft, handleSubmit, handleUpload, message, taxInfo = false }) => {
  let response = draft?.text || message;
  return (
    <Formik
      initialValues={{
        response: response ? response : "",
        draft: draft ? true : false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, action) => {
        action.setSubmitting(true);
        let submitValues = values;
        if (taxInfo) {
          const uploads = await handleUpload(values.files);
          if (!values.files || uploads.length > 0) {
            submitValues = {
              ...submitValues,
              files: uploads.length > 0 ? uploads : null
            };
            await handleSubmit(submitValues);
          } else {
            await handleSubmit(submitValues);
          }
          action.setSubmitting(false);
        }
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          {taxInfo && (
            <FilesInputWrapper>
              <input
                name="file"
                type="file"
                id="file"
                multiple
                onChange={(event) => {
                  event.preventDefault();
                  setFieldValue("files", [...event.target.files]);
                }}
              />
              {values.files &&
                values.files.map((file) => (
                  <p key={file.name}>• {file.name}</p>
                ))}
              <ErrorMessage name="files" />
            </FilesInputWrapper>
          )}
          <TextEditor
            initialValue={values.response}
            name="response"
            height={400}
            handleEditorChange={(content) => {
              setFieldValue("response", content);
            }}
          />
          <ErrorMessage name="response" />

          <FormSubmitButtonsDock>
            <PrimaryButton
              className="draft"
              type="submit"
              onClick={() => setFieldValue("draft", true)}
            >
               {!taxInfo ? "Salvar rascunho" : "Responder"}
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              onClick={() => setFieldValue("draft", false)}
            >
              {!taxInfo ? "Enviar" : "Responder e Finalizar"}
            </PrimaryButton>
          </FormSubmitButtonsDock>
        </Form>
      )}
    </Formik>
  );
};

export default MessageReply;
