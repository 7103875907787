import React from "react";
import styled from "styled-components";
import { Backdrop, CircularProgress } from "@mui/material";

const BackdropComponent = styled(Backdrop)`
  &.root {
    z-index: 1301;
    color: #fff;

    .content {
      display: flex;
      flex-flow: column;
      align-items: center;
    }

    .circular-progress {
      margin-bottom: 20px;
    }
  }
`;

export default function SimpleBackdrop({ open, message = "", openBackdrop }) {
  const handleClose = () => {
    openBackdrop(false);
  };

  return (
    <BackdropComponent className={"root"} open={open} onClick={handleClose}>
      <div className={"content"}>
        <CircularProgress className={"circular-progress"} color="inherit" />
        <p>{message}...</p>
      </div>
    </BackdropComponent>
  );
}
