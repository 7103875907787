import React, { useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import ServiceForm from "./Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";

function CreateEdit({ data, onCloseForm, refresData, serviceUseCases, getSelectOptions, cnaes }) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  useEffect(() => {
    if (cnaes.length === 0) {
      getSelectOptions();
    }
  }, []);

  const getInitialValues = () => {
    if (data) {
      return data;
    }
    return {
      id: 0,
      description: "",
      isDefaultISS: false,
      code: "",
      cnaes: [],
    };
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, `${isCreate ? "Criando" : "Editando"} serviço`);
    try {
      if (isCreate) {
        await serviceUseCases.createService(values);
      } else {
        await serviceUseCases.updateServiceCode(values);
      }
      onCloseForm();
      await refresData();
      setFeedbackSnackbar({
        isOpen: true,
        message: `Serviço ${isCreate ? "criado" : "atualizado"} com sucesso!`,
        type: "success",
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm title={`${isCreate ? "Cadastrar" : "Editar"} serviço`} backFnc={onCloseForm} />
      <ServiceForm initialValues={getInitialValues()} handleSubmit={handleSubmit} cnaes={cnaes} />
    </>
  );
}

export default CreateEdit;
