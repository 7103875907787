import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { useHistory, useParams } from "react-router-dom";
import IssAnswerForm from "../Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

const IssAnswerEdit = ({ makeIssAnswerUseCases, typesAnswerUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);

  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const id = useParams().id;

  const [initialValues, setInitialValues] = useState();
  const [itemsAnswer, setItemsAnswer] = useState([]);
  const [groups, setGroups] = useState([]);

  const fetchData = async () => {
    try {
      openBackdrop(true, "Carregando dados");
      const apiTypeAnswers = await typesAnswerUseCases.getTypesAnswer();
      setItemsAnswer(apiTypeAnswers);
      const response = await makeIssAnswerUseCases.getIssAnswer(id);

      const initialvalues = {
        id: response.data.id,
        isLegislation: response.data.isLegislation.toString(),
        isDispensedEROS: response.data.isDispensedEROS.toString(),
        isDispensedCPOM: response.data.isDispensedCPOM.toString(),
        cpom: response.data.cpom,
        isSpecificSubstituition: response.data.isSpecificSubstituition.toString(),
        cityId: response.data.cityId,
        cityName: response.data.cityName,
        items: response.data.items,
      };
      setInitialValues(initialvalues);

      const groupsResponse = await makeIssAnswerUseCases.getGroups();
      setGroups(groupsResponse.data);
      openBackdrop(false);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteItemAnswer = async (id) => {
    try {
      openBackdrop(true, "Deletando item");
      await makeIssAnswerUseCases.deleteIssAnswerItem(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Item deletado com sucesso!",
        type: "success",
      });
      await fetchData();
    } catch (err) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao deletar o item",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Editando resposta");
    try {
      await makeIssAnswerUseCases.updateIssAnswer(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Resposta atualizada com sucesso!",
        type: "success",
      });
      await fetchData();
    } catch (err) {
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao atualizar a resposta",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Editar resposta" backLink="/admin/respostas" />
      {initialValues ? (
        <IssAnswerForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          itemsAnswer={itemsAnswer}
          groups={groups}
          handleDelete={deleteItemAnswer}
        />
      ) : (
        <div />
      )}
    </Container>
  );
};

export default IssAnswerEdit;
