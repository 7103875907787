import React from "react";
import Footer from "~/presentation/components/Footer";
import PaginatedList from "~/presentation/components/PaginatedList";
import { usePaginatedState } from "~/presentation/hooks/usePaginatedState";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import VideoItem from "./components/VideoItem";
import { Container, Box, CircularProgress, Skeleton } from "@mui/material";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";
const ITEMS_PER_PAGE = 4;
const MAX_COLUMNS = 1;

const Multimedia = ({ contentUseCases }) => {
  const getMultimedia = (queryParams) => {
    return contentUseCases.getAllMultimedia(queryParams);
  };

  const { content, handlePage, handleSearch, isLoading, numberOfPages, page } = usePaginatedState({
    getData: getMultimedia,
    itemsPerPage: ITEMS_PER_PAGE,
  });

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {({ mobile }) => (
        <>
          <SectionTitlePost
            mobile={mobile}
            onSearch={handleSearch}
            image="/assets/multimedia-banner.png"
            title="Multimídia"
            redirectURL="/cliente/conteudo-selecionado/multimidia"
            searchPlaceholder="Pesquisar Publicação"
            searchButtonBg="#ff6829"
            blur={0.1}
          />
          {!content ? (
            <Box
              sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <>
              <Container
                maxWidth="lg"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {isLoading ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                ) : (
                  <>
                    {content && (
                      <PaginatedList
                        isLoading={isLoading}
                        items={content}
                        itemComponent={(item) => (
                          <VideoItem
                            index={item?.id}
                            url={item?.videoUrl}
                            title={item?.title}
                            text={item?.text}
                            mobile={mobile}
                            autor={item?.author}
                            publishDate={item?.publishDate}
                          />
                        )}
                        numberOfPages={numberOfPages}
                        onPageChange={handlePage}
                        page={page}
                        maxColumns={MAX_COLUMNS}
                        rowGap={3}
                        notFoundMessage="Não foram encontrados Vídeos correspondentes à pesquisa."
                      />
                    )}
                  </>
                )}
              </Container>
            </>
          )}
          <Footer />
        </>
      )}
    </HomeLayout>
  );
};

export default Multimedia;
