import styled from "styled-components";
import { Form as FormikForm } from "formik";
import {
  Radio as MuiRadio,
  FormLabel as MuiFormLabel,
  Checkbox as MuiCheckbox,
} from "@mui/material";

export const Container = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  gap: 2%;
  grid-template-rows: auto auto;
  grid-template-areas:
    "DATA"
    "FORM";

  @media screen and (min-width: 768px) {
    grid-template-columns: 39% 59%;
    grid-template-areas: "DATA FORM";
  }
`;

export const DataWrapper = styled.div`
  grid-area: DATA;
  border-radius: 10px;
  padding: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;

  @media screen and (min-width: 500px) {
    flex-flow: row;
    justify-content: space-evenly;
  }

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
    flex-flow: column;
  }

  > div:nth-child(2) {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
`;

export const Avatar = styled.div`
  margin: 1vw;
  width: 30%;

  @media screen and (min-width: 768px) {
    width: 60%;
    max-width: 200px;
  }

  > img {
    width: 100%;
    height: 100%;
  }
`;

export const FormWrapper = styled.div`
  grid-area: FORM;
  background-color: #fff;

  border-radius: 10px;
  height: calc(100%);
  overflow-y: auto;
  padding: 20px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-row-gap: 20px;

  /* preserve the padding bottom */
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
  }
`;

export const Form = styled(FormikForm)`
  display: grid;
  grid-gap: 16px;

  .submit-btn {
    width: 100%;
    justify-self: unset;

    @media screen and (min-width: 600px) {
      width: 25%;
      justify-self: center;
    }
  }
`;

export const Radio = styled(MuiRadio)`
  &.MuiRadio-root,
  &.MuiRadio-root.Mui-checked {
    color: ${(props) => "#383f48"};
  }
`;

export const FormLabel = styled(MuiFormLabel)`
  &.MuiFormLabel-root,
  &.MuiFormLabel-root.Mui-focused {
    color: ${(props) => "#383f48"};
  }
`;

export const Checkbox = styled(MuiCheckbox)`
  &.MuiCheckbox-root,
  &.MuiCheckbox-root.Mui-checked {
    color: ${(props) => "#383f48"};
  }
`;
