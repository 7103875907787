import styled from 'styled-components';
import { transparentize } from 'polished';

const CenterDescription = styled.div`
  width: 100%;
  text-align: center;

  z-index: 2;
  padding: 2rem 3rem;

  span {
    font-weight: 700;
  }
`;

const ListSeriesBlog = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-around;
  z-index: 2;
`;

const ContainerBlogSerie = styled.div`
  width: 100%;
  max-width: 20rem;
`;

const SerieIcon = styled.img`
  margin-bottom: 1rem;
`;

const SerieDescription = styled.div``;

export const BlogModalStyles = {
  ListSeriesBlog,
  CenterDescription,
  ContainerBlogSerie,
  SerieIcon,
  SerieDescription,
};
