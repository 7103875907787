import styled from "styled-components";

export const Container = styled.div`
  min-height: 80vh;

  background: #fafafa;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  * {
    margin: 20px;
  }
`;

export const ExpandWrapper = styled.div`
  height: ${({ expand }) => (expand ? "auto" : 0)};
`;
