import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import parse from "html-react-parser";
import PageListHeader from "../../components/PageListHeader";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { Container } from "@mui/material";

const limitCaracteres = (value) => {
  if (value?.length > 50) {
    return value.substring(0, 50) + "...";
  }
  return value;
};

const AnnotationTable = ({ annotationUseCases }) => {
  const history = useHistory();

  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [notes, setNotes] = useState([]);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const columns = [
    {
      name: "name",
      label: "Nome",
    },
    {
      name: "text",
      label: "Texto",
      options: {
        filter: false,
        customBodyRender: (value) => {
          const text = parse(value);
          const textParsed = text?.props?.children;
          return textParsed ? limitCaracteres(textParsed) : null;
        },
      },
    },
    { name: "actions", label: "Ações", options: { sortable: "false" } },
  ];

  const fetchData = async () => {
    const { data = [] } = await annotationUseCases.getAnnotations();

    const userIsSuperAdmin = context.haveSuperAdminAccess();

    data.forEach((answer) => {
      if (userIsSuperAdmin) {
        answer.actions = (
          <TableActions
            handleDelete={() => deleteDialog(answer.id)}
            handleEdit={() => handleEdit(answer.id)}
          />
        );
      } else {
        answer.actions = <TableActions handleEdit={() => handleEdit(answer.id)} />;
      }
    });
    setNotes(data);
  };

  const handleEdit = (id) => {
    history.push(`/admin/anotacoes/editar/${id}`);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo resposta");
    try {
      await annotationUseCases.deleteAnnotation(id);
      setNotes((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir anotação",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta resposta?`,
      callback: () => handleDelete(id),
    });
  };

  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };
  const handleAdd = () => history.push("/admin/anotacoes/cadastrar");

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container maxWidth="100%">
      <PageListHeader
        title="Anotações"
        titleAccess="Nova resposta"
        searchFieldRef={searchFieldRef}
        handleAdd={handleAdd}
        handleSearch={handleSearch}
      />
      {notes && (
        <Table
          //  searchRef={hiddenInputRef}
          columns={columns}
          data={notes}
          title="Anotações"
        />
      )}
    </Container>
  );
};
export default AnnotationTable;
