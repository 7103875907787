import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  Wrapper,
  MessageWrapper,
} from "~/presentation/views/Admin/BlogComment/Reply/styles";

import MessageReplyForm from "~/presentation/views/Admin/components/MessageReply";
import MessageFromToSubject from "~/presentation/components/MessageFromToSubject";

import { format } from "date-fns";
import { AccordionHeader } from "~/presentation/components/MessageChat/styles";
import parse from "html-react-parser";
import useMobile from "~/presentation/hooks/useMobile";

import { useContext } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from "@mui/material";
import HeaderForm from "../../components/HeaderForm/HeaderForm";

const ReplyBlogComment = ({ commentUseCases }) => {
  const [accordionsExpanded, setAccordionsExpanded] = useState([]);
  const [chat, setChat] = useState();
  const [mobile] = useMobile(768);
  const isExpanded = (id) => accordionsExpanded.includes(id);
  const id = useParams().id;
  const history = useHistory();
  const { user } = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const fetchChat = async () => {
    const response = await commentUseCases.getCommentById(id);
    setChat(response);
  };

  const toggleAccordionExpanded = (id) => {
    if (isExpanded(id)) {
      setAccordionsExpanded([...accordionsExpanded].filter((accId) => accId !== id));
    } else {
      setAccordionsExpanded([...accordionsExpanded, id]);
    }
  };

  const formattedDatetime = (datetime) =>
    format(new Date(datetime), mobile ? "HH:mm dd/MM" : "HH:mm dd/MM/yyyy");

  const createComment = async (values) => {
    backdropOpen(values.draft);
    try {
      const submitValues = {
        id: 0,
        publishDate: new Date(),
        commentText: values.response,
        userId: user.id,
        postId: chat.postComment.id,
        commentResponseId: chat.id,
        isDraft: values.draft,
      };
      await commentUseCases.submitCommentResponse(submitValues);
    } catch (error) {
      openErrorFeedback(values.draft);
    } finally {
      closeBackdrop();
      // history.push("/admin/comentarios");
    }
  };

  const updateComment = async (values) => {
    backdropOpen(values.draft);
    try {
      const submitValues = {
        id: id,
        publishDate: chat.publishDate,
        commentText: values.response,
        userId: chat.userComment.id,
        postId: chat.postComment.id,
        commentResponseId: chat.commentResponse.id,
        isDraft: values.draft,
      };
      await commentUseCases.updateCommentResponse(submitValues);
    } catch (error) {
      console.error(error);
      openErrorFeedback(values.draft);
    } finally {
      closeBackdrop();
    }
  };

  const handleSubmit = async (values) => {
    if (id && chat.status === 2) {
      await updateComment(values);
    } else {
      await createComment(values);
    }
    history.push("/admin/comentarios");
  };

  const openErrorFeedback = (isDraft) => {
    setFeedbackSnackbar({
      isOpen: true,
      message: `Erro ao ${isDraft ? "salvar" : "enviar"} mensagem: missing endpoint`,
      type: "error",
    });
  };

  const backdropOpen = (isDraft) => {
    openBackdrop(true, `${isDraft ? "Salvando" : "Enviando"} mensagem`);
  };

  const closeBackdrop = () => openBackdrop(false);

  useEffect(() => {
    fetchChat();
  }, []);

  return (
    <Container>
      <HeaderForm title="Responder Comentário" backLink="/admin/comentarios" />
      {chat ? (
        <Wrapper className="scrollbar-white">
          <MessageWrapper>
            <MessageFromToSubject
              from={user.email}
              to={chat.userComment.email}
              subject={chat.postComment.title}
            />
            {chat.status !== 2 && (
              <Accordion
                square={false}
                expanded={isExpanded(id)}
                onChange={() => toggleAccordionExpanded(id)}
                className={"accordion-root"}
                sx={{
                  width: "100%",
                  backgroundColor: "#e1e1e1",
                  color: "#383f48",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary>
                  <AccordionHeader>
                    <div>
                      <div className="senderEmail">{chat.userComment.email}</div>
                      <small className="messageDateTime">
                        {formattedDatetime(chat.publishDate)}
                      </small>
                    </div>

                    <small className={`messagePreview ${isExpanded(id) && "hide"}`}>
                      {parse(chat.commentText)}
                    </small>
                  </AccordionHeader>
                </AccordionSummary>
                <AccordionDetails>
                  <div>{parse(chat.commentText)}</div>
                </AccordionDetails>
              </Accordion>
            )}
          </MessageWrapper>
          {user.id === chat.userComment.id && (
            <MessageReplyForm
              draft={chat.status === 2 ? true : false}
              handleSubmit={handleSubmit}
              message={chat.status === 2 ? chat.commentText : ""}
            />
          )}
        </Wrapper>
      ) : (
        <div className="absolute-center">
          <CircularProgress color="inherit" />
        </div>
      )}
    </Container>
  );
};

export default ReplyBlogComment;
