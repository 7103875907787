import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "~/presentation/AppContext";
import { Box, Container, Typography } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";

const AdminHome = () => {
  const { handleLogout, user } = useContext(AppContext);
  const history = useHistory();

  return (
    <Container maxWidth="100%" sx={styles.container}>
      <Typography
        sx={{
          ...styles.text,
          fontSize: "2rem",
          fontWeight: 500,
          "@media (min-width: 480px)": {
            fontSize: "3rem",
          },
        }}
      >
        Admin Painel
      </Typography>
      <Typography sx={{ ...styles.text, fontSize: "1rem" }}>
        {user && user.name} é um admin!
      </Typography>
      <Box sx={styles.box}>
        <ButtonCommon onClick={() => history.push("/cliente")}>Voltar para Cliente</ButtonCommon>
        <ButtonCommon variant="outlined" onClick={handleLogout}>
          Sair
        </ButtonCommon>
      </Box>
    </Container>
  );
};

export default AdminHome;

const styles = {
  container: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    color: "black",
  },
  text: {
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  box: {
    display: "flex",
    gap: 1,
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
    marginTop: 2,
    flexDirection: "column",
    "@media (min-width: 480px)": {
      flexDirection: "row",
    },
  },
};
