import styled from "styled-components";
import { transparentize } from "polished";

export const Container = styled.div`
  position: relative;
  background-image: url("/assets/bg/houndstooth-pattern.png");
  display: grid;
  grid-template-rows: min-content auto;
  row-gap: 65px;
  align-items: center;
  padding: 65px 13% 45px 12%;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fafafa;
  }

  .title {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;

    h2 {
      font-family: "Montserrat";
      text-transform: uppercase;
      font-style: normal;
      font-weight: normal;
      font-size: 37px;
      line-height: 20px;
      text-align: justify;
      color: #122141;
      margin-bottom: 15px;

      @media screen and (max-width: 600px) {
        font-size: 1.5rem;
      }
    }

    .separator {
      height: 2px;
      width: 176px;
      background-color: #cf9455;
    }
  }

  .radialGradient {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: radial-gradient(
      116.88% 86.33% at 50% 50%,
      #ffffff 13.22%,
      rgba(238, 238, 238, 0) 100%
    );
  }

  .slider {
    outline: none;
    padding-bottom: 100px;

    .slider-control-bottomcenter {
      > ul > li.paging-item {
        padding: 0 10px;

        > button {
          outline: none;
          fill: #cf9455 !important;
          > svg {
            transform: scale(2);
          }
        }
      }
    }
  }
`;
