import React from "react";
import {makeAdditionalCompanyPlanUseCases} from "~/main/factories/use-cases/additional-company-plan";
import { AdditionalCompanyPlanList } from "~/presentation/views/Admin/AdditionalCompanyPlan/Table";

export const makeAdditionalCompanyPlanList = (params) => {
    return (
        <AdditionalCompanyPlanList
            {...params}
            additionalCompanyPlanUseCases={makeAdditionalCompanyPlanUseCases()}
        />
    );
};
