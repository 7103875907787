import * as Yup from "yup";

export default Yup.object({
  title: Yup.string().required("Campo Obrigatório!"),
  description: Yup.mixed().when("isSerie", (isSerie, schema) =>
    !isSerie ? schema : schema.required("Campo Obrigatório!"),
  ),
  image: Yup.mixed().when("isSerie", (isSerie, schema) =>
    !isSerie ? schema : schema.required("Campo Obrigatório!"),
  ),
});
