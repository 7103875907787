import React from "react";
import {
  Container,
  Logo,
  TitleWrapper,
  Line,
  Title,
  Subtitle,
  Button,
  ButtonBackground,
} from "./styles";

const ShouldSubscribeModal = ({handleNavigate}) => {
  return (
    <Container backgroundUrl="/assets/modal-background.png">
      <ButtonBackground />
      <Logo />
      <TitleWrapper>
        <Line />
        <Title>
          Conteúdo <span>exclusivo</span> para assinantes
        </Title>
      </TitleWrapper>
      <Subtitle>
        Para se tornar um assinante, entre em contato com a nossa equipe através
        do número <b>0800 888 1482</b> ou pelo nosso canal de atendimento ao
        cliente. Inscreva-se!
      </Subtitle>
      <Button onClick={handleNavigate}>Quero receber mais informações</Button>
    </Container>
  );
};

export default ShouldSubscribeModal;
