import React from "react";
import { Field } from "formik";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TextInput from "~/presentation/components/TextInput";
import { Box, IconButton, Switch, Tooltip } from "@mui/material";

function AnswerContainer({ answer, index, setFieldValue, values }) {
  const isCorrectSet = values?.answers?.filter((item) => item.isCorrect);
  const isOnlyOneCorrect = isCorrectSet.length === 1;
  const isAnswerTheCorrectOne = answer.isCorrect;

  const checkBox = ({ field, form, ...props }) => {
    return (
      <Tooltip title={isAnswerTheCorrectOne ? "Resposta correta" : "Resposta incorreta"}>
        <Switch {...field} {...props} />
      </Tooltip>
    );
  };

  const handleOnChangeCorrect = (e) => {
    if (isAnswerTheCorrectOne && isOnlyOneCorrect) {
      return; //se só existe uma resposta e ela é correta, não deixar desmarcar
    }
    setFieldValue(`answers[${index}].isCorrect`, e.target.checked);
  };

  const handleDeleteAnswers = () => {
    let newAnswers = [...values.answers];
    newAnswers.splice(index, 1); //eliminar resposta
    if ((isAnswerTheCorrectOne && isOnlyOneCorrect) || isCorrectSet.length === 0) {
      newAnswers[0].isCorrect = true; //sempre deixar uma resposta como correta
    }
    return setFieldValue("answers", newAnswers);
  };

  return (
    <Box sx={styles.container}>
      <Field
        name={`answers[${index}].text`}
        type="text"
        label={`Resposta ${index + 1}*`}
        as={TextInput}
      />
      <Box sx={styles.actions}>
        <Box sx={styles.correctAnswer}>
          <Field
            name={`answers[${index}].isCorrect`}
            type="checkbox"
            as={checkBox}
            onChange={handleOnChangeCorrect}
          />
        </Box>
        {values.answers.length > 1 && (
          <Box sx={styles.deleteBtn}>
            <IconButton onClick={handleDeleteAnswers}>
              <Tooltip title="Eliminar resposta">
                <RemoveCircleOutlineIcon fontSize="small" color="error" />
              </Tooltip>
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default AnswerContainer;

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "2px",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  correctAnswer: {},
  deleteBtn: {
    color: "#e1e1e1",
  },
};
