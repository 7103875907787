import styled from "styled-components";
import { transparentize } from "polished";
import { Pagination as MuiPagination } from "@mui/material";

export const Container = styled.div`
  height: calc(100% - 40px - 12px); /** 40: h2 height // 12px: margin-top */
  display: grid;
  gap: 20px;

  @media screen and (min-width: 768px) {
    grid-template-columns: minmax(min-content, 25%) auto;
  }
`;

export const Wrapper = styled.div`
  background-color: #fff;

  border-radius: 10px;
  overflow-y: auto;
  padding: 1vw;
  max-height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 8% 84% 8%;

  /* preserve the padding bottom */
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
  }

  .divider {
    border: none;
    height: 1px;
    width: 100%;
    background-color: ${(props) => "#383f48"};
    margin: 12px 0 4px;
  }

  .MuiSvgIcon-root {
    color: ${(props) => "#383f48"};
  }

  &.filters {
    @media screen and (max-width: 768px) {
      grid-template-rows: auto auto;
    }
  }

  &.messages {
    @media screen and (min-width: 600px) {
      min-width: 450px;
    }
  }
`;

export const ListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    > div:first-child {
      padding-left: 15px;
    }
  }
`;

export const Pagination = styled(MuiPagination)`
  &.MuiPagination-root {
    display: flex;
    align-items: center;
    .MuiPaginationItem-root {
      color: inherit;

      &.Mui-selected {
        background-color: ${(props) => transparentize(0.75, "#1341a1")};
      }
    }
  }
`;
