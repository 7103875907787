import styled from "styled-components";

export const ActionsWrapper = styled.div`
  display: flex;
  width: fit-content;
  /* flex-flow: column; */
  align-items: center;

  .MuiIconButton-root {
    &:nth-child(1):hover {
      color: ${(props) => "#fc4b6c"};
    }
    &:nth-child(2):hover {
      color: ${(props) => "#ffb22b"};
    }
  }
`;
