import { Formik, Form } from "formik";
import React, {useContext, useEffect, useRef, useState} from "react";
import { floatToReais } from "~/presentation/utils/reais-parser";
import {
  Button,
  FormWrapper,
  SimulatorResultCard,
} from "~/presentation/views/GTFacil/Simulator/components";
import { SimulatorContext } from "~/presentation/views/GTFacil/Simulator/context/simulator-context";
import { INSSCalculator } from "~/presentation/views/GTFacil/Simulator/entities/inss-calculator";
import { validationSchema } from "./validationSchema.js";
import {
  hasINSSInTheServiceOptions,
  witchArticleIN_RFB_971_2009Options,
  additionalAliquotRetentionOptions,
  aliquotRetentionOptions,
} from "../../common/inss/formOptions";
import {
  HasInssInTheService,
  WitchArticleIN_RFB_971_2009,
  Art118Details,
  HasMaterialOrEquipmentExclusion,
  MaterialOrEquipmentExclusionValue,
  HasTransportationOrAlimentationBenefitsDeduction,
  TransportationOrAlimentationBenefitsDeductionValue,
  BaseRetentionCalcValue,
  AliquotRetention,
  HasAdditionalAliquotRetention,
  AdditionalAliquotRetention,
} from "~/presentation/views/GTFacil/Simulator/forms/common/inss/questions";
import { reportMapper } from "./reportMapper.js";

const initialValues = {
  hasINSSInTheService: hasINSSInTheServiceOptions.true,
  witchArticleIN_RFB_971_2009: null,
  art118Details: {
    employeeSubordination: true,
    continuousService: true,
    inContractorDependenciesOrIndicated: true,
  },
  hasMaterialOrEquipmentExclusion: "false",
  materialOrEquipmentExclusionValue: 0.0,
  hasTransportationOrAlimentationBenefitsDeduction: "false",
  transportationOrAlimentationBenefitsDeductionValue: 0.0,
  aliquotRetention: aliquotRetentionOptions[0],
  hasAdditionalAliquotRetention: "false",
  additionalAliquotRetention: additionalAliquotRetentionOptions[0],
};

export const InssPJNotSimples = () => {
  const resultCardRef = useRef();
  const [result, setResult] = useState();
  const [lastSubmitValues, setLastSubmitValues] = useState("");
  const { simulatorValue, setInvalidSimulatorValue, handleGenerateReport, handleCacheReport } = useContext(SimulatorContext);

  const valuesNotChangedSinceLastSubmit = (values) => {
    return JSON.stringify(lastSubmitValues) === JSON.stringify(values);
  };

  const inssCalculatorFactory = (values) => new INSSCalculator(mapValuesToCalculatorProps(values));

  const mapValuesToCalculatorProps = (values) => ({
    materialOrEquipmentExclusionValue: values.materialOrEquipmentExclusionValue,
    transportationOrAlimentationBenefitsDeductionValue:
      values.transportationOrAlimentationBenefitsDeductionValue,
    simulatorValue: simulatorValue,
    aliquotRetention: values.aliquotRetention.value,
    additionalAliquotRetention:
      values.hasAdditionalAliquotRetention === "true" ? values.additionalAliquotRetention.value : 0,
  });

  const handleGenerateReportClick = (values) => {
    const response = reportMapper(values, result);
    handleGenerateReport(response);
  };

  const dontShowQuestionsUnless = (values) => {
    if (values.hasINSSInTheService === "true") return true;
    else if (
      values.hasINSSInTheService === "doubt" &&
      values.witchArticleIN_RFB_971_2009 === "art117"
    )
      return true;
    else if (
      values.witchArticleIN_RFB_971_2009 === "art118" &&
      values.art118Details.employeeSubordination === true &&
      values.art118Details.continuousService === true &&
      values.art118Details.inContractorDependenciesOrIndicated === true
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if(result) {
      resultCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [result]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }

        action.setSubmitting(true);
        setLastSubmitValues(values);

        const inssCalculator = inssCalculatorFactory(values);

        setResult(inssCalculator.retentionValue);

        resultCardRef.current.scrollIntoView({ behavior: "smooth" });
        action.setSubmitting(false);
        
        const response = reportMapper(inssCalculator, inssCalculator.retentionValue);

        handleCacheReport(response);
      }}
    >
      {({ setFieldValue, values }) => {
        const inssCalculator = inssCalculatorFactory(values);

        return (
          <Form>
            <FormWrapper>
              <HasInssInTheService
                onChange={() => {
                  setFieldValue(
                    "witchArticleIN_RFB_971_2009",
                    initialValues.witchArticleIN_RFB_971_2009
                  );
                  setFieldValue("art118Details", initialValues.art118Details);
                }}
              />
              {values.hasINSSInTheService === hasINSSInTheServiceOptions.doubt ? (
                <>
                  <WitchArticleIN_RFB_971_2009
                    onChange={() => {
                      setFieldValue("art118Details", initialValues.art118Details);
                    }}
                  />

                  {values.witchArticleIN_RFB_971_2009 ===
                  witchArticleIN_RFB_971_2009Options.art118 ? (
                    <Art118Details />
                  ) : null}
                </>
              ) : null}
              {dontShowQuestionsUnless(values) && <HasMaterialOrEquipmentExclusion />}

              {values.hasMaterialOrEquipmentExclusion === "true" ? (
                <MaterialOrEquipmentExclusionValue />
              ) : null}

              {dontShowQuestionsUnless(values) && (
                <HasTransportationOrAlimentationBenefitsDeduction />
              )}

              {values.hasTransportationOrAlimentationBenefitsDeduction === "true" ? (
                <TransportationOrAlimentationBenefitsDeductionValue />
              ) : null}

              {dontShowQuestionsUnless(values) && (
                <BaseRetentionCalcValue value={inssCalculator.baseRetentionCalcValue} />
              )}
              {dontShowQuestionsUnless(values) && <AliquotRetention />}
              {dontShowQuestionsUnless(values) && <HasAdditionalAliquotRetention />}
              {values.hasAdditionalAliquotRetention === "true" ? (
                <AdditionalAliquotRetention />
              ) : null}

              <Button>Ver Resultado</Button>

              {result && valuesNotChangedSinceLastSubmit(values) ? (
                <SimulatorResultCard
                  componentRef={resultCardRef}
                  title="Valor da retenção previdenciária (INSS):"
                  value={floatToReais(result)}
                  description="O valor da retenção previdenciária corresponde ao resultado da multiplicação da alíquota da contribuição pela base de cálculo."
                  generateReportButton={() => handleGenerateReportClick(inssCalculator)}
                />
              ) : null}
            </FormWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};
