import React, { useContext, useRef, useState } from "react";
import { uploadFile } from "react-s3";
import { Formik, Field } from "formik";
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import Select from "~/presentation/components/Select";
import QSOTable from "~/presentation/components/QSOTable";
import { Container, Form, Checkbox } from "~/presentation/views/Admin/Blog/CreateEdit/Form/styles";
import DateInput from "~/presentation/components/DateInput";
import TextEditor from "~/presentation/components/TextEditor";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
import validationSchema from "~/presentation/views/Admin/Blog/CreateEdit/Form/validationSchema";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import ImageInput from "~/presentation/components/ImageInput";
import CreatableMultiSelectField from "~/presentation/components/CreatableMultiSelect";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import randomString from "~/presentation/utils/randomString";
import { FormControlLabel } from "@mui/material";
import { format } from "date-fns";

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION_BUCKET,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const BlogForm = ({ handleSubmit, initialValues, categories, tags }) => {
  const uploadedImage = useRef(null);
  const [selectedFile, setSelectedFile] = useState();
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const handleImageUpload = async () => {
    try {
      if (selectedFile) {
        const upload = await uploadFile(selectedFile, config);
        return upload;
      } else {
        return false;
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao fazer upload.",
        type: "error",
      });
      return error;
    }
  };

  const changeHandler = (event) => {
    const [file] = event.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      const renamedFile = new File([file], `${randomString()}-${file.name}`);
      setSelectedFile(renamedFile);
    }
  };

  const filterTags = (tags) => {
    const selectedTags = tags.map((tag) => {
      if (!tag.id) {
        return {
          id: 0,
          name: tag.value,
        };
      } else {
        return {
          id: tag.id,
          name: tag.name,
        };
      }
    });
    return selectedTags;
  };

  const defaultTags = (tags) => {
    const newTags = tags.filter((tag) => tag.__isNew__ === true);
    const allTags = dataToSelectMapper(tags.filter((tag) => !!tag.id)).concat(newTags);
    return allTags;
  };

  function embedVideo(url) {
    if (url === null || url === undefined) {
      return null;
    }
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/(watch\?)?(v=)?(.+)$/;
    const vimeoRegex = /^(https?:\/\/)?(player\.)?vimeo\.com\/(video\/)?(\d+)(\?.*)?$/;
    const vimeoManageRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/(manage\/videos\/)?(\d+)$/;

    if (url.match(youtubeRegex)) {
      const videoId = url.match(youtubeRegex)[6];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (url.match(vimeoRegex)) {
      const videoId = url.match(vimeoRegex)[4];
      return `https://player.vimeo.com/video/${videoId}`;
    } else if (url.match(vimeoManageRegex)) {
      const videoId = url.match(vimeoManageRegex)[5];
      return `https://player.vimeo.com/video/${videoId}`;
    } else {
      return null;
    }
  }

  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          let convertedUrl = embedVideo(values.videoUrl);
          let submitValues = {
            id: values.id,
            title: values.title,
            autor: values.autor,
            image: values.image,
            videoUrl: values.videoUrl,
            addToMedia: values.addToMedia,
            publishDate: format(values.publishDate, "yyyy-MM-dd HH:mm:ss"),
            tags: values.tags,
            text: values.text,
            isHighlight: values?.isHighlight || false,
            numberOfComments: values?.numberOfComments || 0,
            categoryId: values.categoryId,
            qsoTable: values.qsoTable,
            isDraft: values.isDraft,
          };
          const upload = await handleImageUpload();
          if (values.image || upload.result.ok) {
            submitValues = {
              ...values,
              tags: filterTags(submitValues.tags),
              videoUrl: convertedUrl,
              image: upload.location ? upload.location : values.image,
            };
            await handleSubmit(submitValues);
          }
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <h2>Nova Publicação</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <Field
                  component={Select}
                  options={categories}
                  placeholder={"Categoria *"}
                  onChange={(value) => setFieldValue("categoryId", Number(value.id))}
                  name="categoryId"
                />
                <ErrorMessage name="categoryId" />
              </div>
              <div className="col-1_5">
                <Field name="title" type="text" label="Título *" as={TextInput} />
                <ErrorMessage name="title" />
              </div>
              <div className="col-1_5">
                <Field name="autor" type="text" label="Autor da Publicação *" as={TextInput} />
                <ErrorMessage name="autor" />
              </div>
              <div className="col-1_3">
                <DateInput
                  getHours
                  label="Data da publicação *"
                  value={values.publishDate}
                  onChange={(value) => {
                    setFieldValue("publishDate", value);
                  }}
                />
                <ErrorMessage name="publishDate" />
              </div>
              <div className="col-3_5">
                <Field
                  component={CreatableMultiSelectField}
                  options={tags}
                  placeholder={"Tags *"}
                  defaultValue={defaultTags(values?.tags)}
                  // value={values.tags}
                  isMulti={true}
                  onChange={(value) => setFieldValue("tags", value)}
                  name="tags"
                />
              </div>
              <div className="col-1_3">
                <h2 className="mb_12">Imagem da Publicação *</h2>
                <ImageInput
                  title="Selecionar imagem"
                  imageFile={values.image}
                  onChange={(event) => {
                    changeHandler(event);
                    setFieldValue("image", event.target.files[0].name);
                  }}
                  uploadedImageRef={uploadedImage}
                />
                <ErrorMessage name="image" />
              </div>
              <div className="col-3_5">
                <h2 className="mb_12">Link do Vídeo</h2>
                <Field name="videoUrl" type="text" label="Url do Vídeo *" as={TextInput} />
                {/* <ErrorMessage name="videoUrl" /> */}
              </div>
              <div className="col-1_5">
                <h2 className="mb_12">Multimídia</h2>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.addToMedia}
                        onChange={() => setFieldValue("addToMedia", !values.addToMedia)}
                        name="addToMedia"
                      />
                    }
                    label="Adicionar publicação em Multimídia"
                  />
                </div>
              </div>
              <div className="col-1_5">
                <h2 className="mb_12">Quadro QSO *</h2>
                <div className="scroll-x">
                  <QSOTable
                    handleChange={(table) => setFieldValue("qsoTable", table)}
                    values={values.qsoTable}
                  />
                </div>
              </div>
              <h2>
                Texto* <ErrorMessage name="text" />
              </h2>
              <div className="col-1_5">
                <TextEditor
                  initialValue={values.text}
                  name="text"
                  height={400}
                  handleEditorChange={(content) => {
                    setFieldValue("text", content);
                  }}
                />
              </div>
            </div>

            <FormSubmitButtonsDock>
              <PrimaryButton
                className="draft"
                type="submit"
                onClick={() => setFieldValue("isDraft", true)}
              >
                Salvar como rascunho
              </PrimaryButton>
              <PrimaryButton type="submit" onClick={() => setFieldValue("isDraft", false)}>
                Publicar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default BlogForm;
