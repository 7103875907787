import React, { useState, useEffect } from "react";
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import Select from "~/presentation/components/Select";
import DateInput from "~/presentation/components/DateInput";
import MaskedInput from "~/presentation/components/MaskedInput";
import { Container, Radio, Form } from "./styles";
import { makeAdditionalCompanyPlanList as MakeAdditionalCompanyPlanList } from "~/main/factories/pages/admin/additional-company-plan";
//functions
import removeMask from "~/presentation/functions/removeMask";
import validationSchema from "./validationSchema";
import cnpjSearch from "~/data/functions/cnpj-search";
import { FormControl, FormControlLabel, RadioGroup } from "@mui/material";

const CompanyForm = ({
  initialValues,
  handleSubmit,
  plans,
  categories,
  states,
  legalNatureOptions,
  citiesCache,
  id,
}) => {
  const [cities, setCities] = useState([]);

  const handlerCities = async (stateId) => {
    let tmp = citiesCache.filter((x) => x.stateId === stateId);
    setCities(tmp);
  };

  useEffect(() => {
    if (initialValues?.state !== 0) {
      handlerCities(initialValues.state);
    }
  }, []);

  const cnpjHandle = async (value) => {
    if (value.cnpj && value.cnpj.length > 0) {
      let cnpj = removeMask(value.cnpj);
      if (cnpj.length > 14) {
        cnpjSearch(cnpj)
          .then((data) => {
            value.name = data.razao_social;
            value.address = `${data.estabelecimento.tipo_logradouro} ${data.estabelecimento.logradouro}, ${data.estabelecimento.numero} - ${data.estabelecimento.bairro}`;
            value.complement = data.estabelecimento.complemento;
            value.cep = data.estabelecimento.cep;
            value.state = states.filter((x) => x.id === data.estabelecimento.estado.ibge_id);
            value.phone = `${data.estabelecimento.ddd1}${data.estabelecimento.telefone1}`;
            value.email = data.estabelecimento.email;
            value.state = data.estabelecimento.estado.ibge_id;
            if (data.estabelecimento.cidade) {
              handlerCities(data.estabelecimento.estado.ibge_id).then((_) => {
                let city = citiesCache.filter(
                  (x) => x.codeIBGE === data.estabelecimento.cidade.ibge_id
                )[0];
                value.city = city.id;
              });
            }
            let code = `${data.natureza_juridica.id.substring(0, 3)}-${data.natureza_juridica.id[3]}`;
            let nature = legalNatureOptions.filter((x) => x.code === code)[0];
            if (nature) {
              value.legalNatureId = nature.id;
            }
          })
          .catch(() => {});
      }
    }
  };

  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          const submitValues = {
            id: values.id,
            categoryId: Number(values.categoryId),
            legalNatureId: Number(values.legalNatureId),
            name: values.name,
            cnpj: removeMask(values.cnpj),
            address: values.address,
            complement: values.complement,
            zipCode: removeMask(values.cep),
            cityId: values.city,
            email: values.email,
            homePhone: removeMask(values.phone),
            mobilePhone: removeMask(values.mobilePhone),
            planId: Number(values.plan),
            dateCompanyRegistered: !values.dateCompanyRegistered
              ? new Date()
              : values.dateCompanyRegistered,
            dateStart: values.dateStart,
            dateEnd: values.dateEnd,
            active: values.active === "true" ? true : false,
            totalSubstituteOrDismissed: values.totalSubstituteOrDismissed
              ? values.totalSubstituteOrDismissed.map((item) => ({
                  cityId: item.id,
                  name: item.name,
                }))
              : null,
          };
          action.setSubmitting(true);
          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <h2>Dados da Empresa</h2>
            <div className="form-grid">
              <div className="col-1_2">
                <Field
                  name="cnpj"
                  type="text"
                  mask="99.999.999/9999-99"
                  label="CNPJ *"
                  as={MaskedInput}
                  onKeyUp={(e) => cnpjHandle(values)}
                />
                <ErrorMessage name="cnpj" />
              </div>

              <div className="col-2_5">
                <Field name="name" type="text" label="Nome da empresa *" as={TextInput} />
                <ErrorMessage name="name" />
              </div>
              <div className="col-1_3">
                <Field
                  name="legalNatureId"
                  component={Select}
                  options={legalNatureOptions}
                  placeholder="Natureza Jurídica"
                  getOptionLabel={(option) => `${option.code} - ${option.description}`}
                />
              </div>
              <div className="col-3_5">
                <Field
                  name="categoryId"
                  component={Select}
                  options={categories}
                  placeholder={"Selecione a categoria *"}
                />
                <ErrorMessage name="categoryId" />
              </div>
              <div className="col-1_4">
                <Field name="address" type="text" label="Endereço *" as={TextInput} />
                <ErrorMessage name="address" />
              </div>

              <div className="col-4_5">
                <Field name="cep" type="text" mask="99999-999" label="CEP *" as={MaskedInput} />
                <ErrorMessage name="cep" />
              </div>

              <div className="col-1_2">
                <Field
                  name="state"
                  component={Select}
                  options={states}
                  placeholder={"Selecione o estado *"}
                  onChange={(state) => {
                    setFieldValue("state", state.id);
                    handlerCities(state.id);
                  }}
                />
                <ErrorMessage name="state" />
              </div>

              <div className="col-2_3">
                <Field
                  name="city"
                  placeholder={"Selecione a cidade *"}
                  component={Select}
                  options={cities}
                />
                <ErrorMessage name="city" />
              </div>

              <div className="col-3_5">
                <Field label="Complemento" name="complement" type="text" as={TextInput} />
                <ErrorMessage name="complement" />
              </div>

              <div className="col-1_3">
                <Field name="email" type="email" label="Email *" as={TextInput} />
                <ErrorMessage name="email" />
              </div>

              <div className="col-3_4">
                <Field
                  name="phone"
                  type="text"
                  mask="(99) 99999-9999"
                  label="Telefone *"
                  as={MaskedInput}
                />
                <ErrorMessage name="phone" />
              </div>

              <div className="col-4_5">
                <Field
                  name="mobilePhone"
                  type="text"
                  mask="(99) 99999-9999"
                  label="Celular"
                  as={MaskedInput}
                />
                <ErrorMessage name="mobilePhone" />
              </div>
            </div>
            <h2>Plano</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <Field name="plan" component={Select} options={plans} placeholder={"Plano *"} />
                <ErrorMessage name="plan" />
              </div>

              <div className="col-1_2">
                <DateInput
                  label="Data de início *"
                  value={values.dateStart}
                  maxDate={new Date()}
                  onChange={(value) => setFieldValue("dateStart", value)}
                />
                <ErrorMessage name="dateStart" />
              </div>

              <div className="col-2_3">
                <DateInput
                  label="Data de término *"
                  value={values.dateEnd}
                  onChange={(value) => setFieldValue("dateEnd", value)}
                />
                <ErrorMessage name="dateEnd" />
              </div>
              <div className="col-1_5">
                <Field
                  name="totalSubstituteOrDismissed"
                  label="Substituto total ou dispensado"
                  placeholder="Substituto total ou dispensado"
                  component={Select}
                  defaultValue={initialValues.totalSubstituteOrDismissed}
                  isMulti={true}
                  options={citiesCache}
                  onChange={(option) => {
                    setFieldValue("totalSubstituteOrDismissed", option);
                  }}
                  getOptionLabel={(option) => option?.name + "(" + option?.uf + ")"}
                />
              </div>
              <div className="col-1_3">
                <h2>Status</h2>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="active"
                    name="active"
                    value={values.active}
                    onChange={(event) => setFieldValue("active", event.target.value)}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="Ativo" />
                    <FormControlLabel value={false} control={<Radio />} label="Inativo" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            {id && (
              <MakeAdditionalCompanyPlanList
                company={{
                  id: id,
                  name: values.name,
                }}
              />
            )}

            <PrimaryButton className="submit-btn" type="submit">
              Salvar
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CompanyForm;
