import React from "react";
import { makeAnnotationUseCases } from "~/main/factories/use-cases/annotation";
import { makeCnaesUseCases } from "~/main/factories/use-cases/cnaes";
import { makeTermUseCases } from "~/main/factories/use-cases/term";
import AnnotationEdit from "~/presentation/views/Admin/Annotation/Edit";

export const makeAnnotationEdit = (params) => (
  <AnnotationEdit
    {...params}
    cnaesUseCases={makeCnaesUseCases()}
    annotationUseCases={makeAnnotationUseCases()}
    termUseCases={makeTermUseCases()}
  />
);
