import React, { useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import CnaeForm from "./Form";

function CreateEdit({
  data,
  onCloseForm,
  refresData,
  cnaesUseCases,
  fetchSelectData,
  services,
  cnaesList,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const isCreate = !data;

  useEffect(() => {
    if (services.length === 0 || cnaesList.length === 0) {
      fetchSelectData();
    }
  }, []);

  const getInitialValues = () => {
    if (data) {
      return data;
    }
    return {
      id: 0,
      code: "",
      description: "",
      detailCode: "",
      synonym: "",
      comment: "",
      cnaeAnswersLinked: null,
      serviceCodes: null,
    };
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Cadastrando cnae");
    try {
      if (isCreate) {
        await cnaesUseCases.createCnae(values);
      } else {
        await cnaesUseCases.updateCnae(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Cnae ${isCreate ? "cadastrado" : "editado"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refresData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <>
      <HeaderForm title="Editar cnae" backFnc={onCloseForm} />
      <CnaeForm
        initialValues={getInitialValues()}
        handleSubmit={handleSubmit}
        services={services}
        cnaes={cnaesList}
      />
    </>
  );
}

export default CreateEdit;
