import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeStateUseCases } from "~/main/factories/use-cases/state";
import { makeCityUseCases } from "~/main/factories/use-cases/city";
import { makeLegalNatureUseCases } from "~/main/factories/use-cases/legal-nature";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import TableActions from "~/presentation/components/TableActions";
import RegisterEdit from "./RegisterEdit/RegisterEdit";
import PageListHeader from "../components/PageListHeader";
import { Container } from "@mui/material";
import Table from "~/presentation/components/Table";

const columns = [
  { name: "name", label: "Nome" },
  { name: "email", label: "Email" },
  { name: "planName", label: "Plano" },
  {
    name: "active",
    label: "Ativo",
    options: { customBodyRender: (value) => (value ? "Sim" : "Não") },
  },
  { name: "categoryName", label: "Categoria" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

function Company({ companyUseCases, planUseCases }) {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);
  const stateUseCases = makeStateUseCases();
  const cityUseCases = makeCityUseCases();
  const legalNatureUseCases = makeLegalNatureUseCases();

  //todas as empresas
  const [companies, setCompanies] = useState([]);

  //Select options
  const [plans, setPlans] = useState([]);
  const [categories, setCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [legalNatureOptions, setLegalNatureOptions] = useState([]);
  const [citiesCache, setCitiesCache] = useState([]);

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");
    const response = await companyUseCases.getCompaniesTable(await planUseCases.getPlans());

    if (response) {
      const userIsSuperAdmin = context.haveSuperAdminAccess();

      response.forEach((company) => {
        if (userIsSuperAdmin) {
          company.actions = (
            <TableActions
              handleDelete={() => deleteDialog(company?.id)}
              handleEdit={() => {
                handleOpenForm(company);
              }}
            />
          );
        } else {
          company.actions = (
            <TableActions
              handleEdit={() => {
                handleOpenForm(company);
              }}
            />
          );
        }
      });
    }

    setCompanies(response);
    openBackdrop(false);
  };

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo empresa");
    try {
      await companyUseCases.deleteCompany(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Empresa excluída!",
        type: "success",
      });
      setCompanies((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro: " + error.response.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir esta empresa?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);
  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  //Só queremos que faça o fetch quando o usuário clicar pela 1º vez para editar
  //Depois disso, queremos que não haja fetch
  const handleGetFormOptions = async () => {
    openBackdrop(true, "Carregando dados do formulário");
    if (plans.length === 0) {
      const result = dataToSelectMapper(await loadPlans());
      setPlans(result);
    }
    if (categories.length === 0) {
      const result = dataToSelectMapper(await loadCategories());
      setCategories(result);
    }
    if (states.length === 0) {
      const result = dataToSelectMapper(await loadStates());
      setStates(result);
    }
    if (legalNatureOptions.length === 0) {
      const result = dataToSelectMapper(await loadLegalNatureOptions());
      setLegalNatureOptions(result);
    }
    if (citiesCache.length === 0) {
      const result = dataToSelectMapper(await loadCities());
      setCitiesCache(result);
    }
    openBackdrop(false);
  };

  const loadPlans = async () => {
    return await planUseCases.getPlans();
  };

  const loadCategories = async () => {
    return await companyUseCases.getCompanyCategories();
  };

  const loadStates = async () => {
    return await stateUseCases.getStates();
  };

  const loadCities = async () => {
    return await cityUseCases.getAll();
  };

  const loadLegalNatureOptions = async () => {
    return await legalNatureUseCases.list();
  };

  return (
    <Container maxWidth="100%">
      {openForm ? (
        <RegisterEdit
          data={formProps}
          onCloseForm={handleCloseForm}
          refresData={fetchData}
          onGetFornOptions={handleGetFormOptions}
          plans={plans}
          categories={categories}
          states={states}
          legalNatureOptions={legalNatureOptions}
          citiesCache={citiesCache}
        />
      ) : (
        <>
          <PageListHeader
            title="Empresa"
            titleAccess="Nova empresa"
            searchFieldRef={searchFieldRef}
            handleAdd={() => handleOpenForm()}
            handleSearch={handleSearch}
          />
          <Table searchRef={hiddenInputRef} columns={columns} data={companies} title="Empresas" />;
        </>
      )}
    </Container>
  );
}

export default Company;
