import React from "react";
import {
  TextField,
  InputLabel,
  FormControl,
  Box,
  FormHelperText,
  TextareaAutosize,
} from "@mui/material";
import Select from "react-select";
import InputMask from "react-input-mask";
import DateInput from "../DateInput";
import { MoneyField } from "../MoneyField";

// ⇣⇣⇣⇣⇣⇣⇣⇣ SOBRE A ESTILIZAÇÃO DOS INPUTS: ⇣⇣⇣⇣⇣⇣⇣⇣
// styles={{}} para "date", "select"
// style={{}} para "text", "textarea", "number", "mask" e "password"
// "amount" para já é diferente (ver)
// basta adicionar a props styles={{css}} ou style={{css}} no component e escrever as props de css.
// Irá adicionar e sobrepor individualmente cada props que quisermos,
// e mantendo as outras "padrão" caso não quisermos modificá-las.

// ⇣⇣⇣⇣⇣⇣⇣⇣ SOBRE ERROS: ⇣⇣⇣⇣⇣⇣⇣⇣
// error quando true para dar border vermelha
// errorText="texto" mensagem de erro/aviso

function renderInputComponent(type, props) {
  switch (type) {
    case "text":
      return (
        <Box component="form" sx={[styles.content, { marginBottom: "2px" }]}>
          <InputLabel sx={{ ...styles.title, color: props.error ? "#d32f2f" : "#222222" }}>
            {props.title}
          </InputLabel>
          <FormControl
            sx={[
              styles.FormControl,
              { border: props.error ? "1px solid #d32f2f" : 0, height: "57px" },
            ]}
            variant="outlined"
            error={props.error}
          >
            <TextField
              sx={InputStyles(props.styles)}
              variant="outlined"
              placeholder={props.placeholder}
              {...props}
            />
            {props.error && (
              <FormHelperText sx={styles.ErrorText}>{props.errorText}</FormHelperText>
            )}
          </FormControl>
        </Box>
      );

    case "email":
      return (
        <Box component="form" sx={[styles.content, { marginBottom: "2px" }]}>
          <InputLabel sx={{ ...styles.title, color: props.error ? "#d32f2f" : "#222222" }}>
            {props.title}
          </InputLabel>
          <FormControl
            sx={[
              styles.FormControl,
              { border: props.error ? "1px solid #d32f2f" : 0, height: "52px" },
            ]}
            variant="outlined"
            error={props.error}
          >
            <TextField
              type="email"
              sx={InputStyles(props.styles)}
              variant="outlined"
              placeholder={props.placeholder}
              {...props}
            />
            {props.error && (
              <FormHelperText sx={styles.ErrorText}>{props.errorText}</FormHelperText>
            )}
          </FormControl>
        </Box>
      );

    case "textarea":
      return (
        <Box component="form" sx={styles.content}>
          <InputLabel sx={{ ...styles.title, color: props.error ? "#d32f2f" : "#222222" }}>
            {props.title}
          </InputLabel>
          <FormControl
            sx={[styles.FormControl, { border: props.error ? "1px solid #d32f2f" : 0 }]}
            fullWidth
            variant="outlined"
            error={props.error}
          >
            <TextareaAutosize
              minRows={props.minRows}
              maxlength={props.maxlength}
              style={TextareaStyles(props.styles)}
              placeholder={props.placeholder}
              {...props}
            />
          </FormControl>
          {props.error && <FormHelperText sx={styles.ErrorText}>{props.errorText}</FormHelperText>}
        </Box>
      );

    case "mask":
      return (
        <Box component="form" sx={styles.content}>
          <InputLabel sx={{ ...styles.title, color: props.error ? "#d32f2f" : "#222222" }}>
            {props.title}
          </InputLabel>
          <FormControl
            sx={[styles.FormControl, { border: props.error ? "1px solid #d32f2f" : 0 }]}
            fullWidth
            variant="outlined"
            error={props.error}
          >
            <InputMask sx={InputStyles(props.styles)} {...props}>
              {(inputProps) => <TextField {...inputProps} />}
            </InputMask>
          </FormControl>
          {props.error && (
            <FormHelperText sx={{ ...styles.ErrorText, ml: 2 }}>{props.errorText}</FormHelperText>
          )}
        </Box>
      );

    case "select":
      return (
        <Box component="form" sx={styles.content}>
          <InputLabel sx={{ ...styles.title, color: props.error ? "#d32f2f" : "#222222" }}>
            {props.title}
          </InputLabel>
          <FormControl
            fullWidth
            variant="outlined"
            error={props.error}
            sx={[
              styles.FormControl,
              { border: props.error ? "1px solid #d32f2f" : 0, height: "57px" },
            ]}
          >
            <Select
              options={props.options}
              onChange={props.onChange}
              value={props.value}
              styles={styleSelectInput(props.style)}
              noOptionsMessage={() => "Não há opções"}
              isDisabled={props.disabled}
              {...props}
            />
          </FormControl>
          {props.error && (
            <FormHelperText sx={{ ...styles.ErrorText, paddingLeft: "12px", paddingTop: "4px" }}>
              {props.errorText}
            </FormHelperText>
          )}
        </Box>
      );

    case "number":
      return (
        <Box component="form" sx={styles.content}>
          <InputLabel sx={{ ...styles.title, color: props.error ? "#d32f2f" : "#222222" }}>
            {props.title}
          </InputLabel>
          <FormControl
            sx={[
              styles.FormControl,
              { border: props.error ? "1px solid #d32f2f" : 0, height: "56px" },
            ]}
            fullWidth
            variant="outlined"
            error={props.error}
          >
            <TextField
              sx={InputStyles(props.styles)}
              type="number"
              variant="outlined"
              placeholder={props.placeholder}
              {...props}
            />
            {props.error && (
              <FormHelperText sx={styles.ErrorText}>{props.errorText}</FormHelperText>
            )}
          </FormControl>
        </Box>
      );

    case "amount":
      return (
        <Box component="form" sx={styles.content}>
          <InputLabel
            sx={{ ...styles.title, color: props.error ? "#d32f2f" : "#222222" }}
            htmlFor="outlined-adornment-amount"
          >
            {props.title}
          </InputLabel>
          <FormControl fullWidth variant="outlined" error={props.error}>
            <MoneyField
              border={props.border}
              borderRadius={props.borderRadius}
              backgroundColor={props.backgroundColor}
              disabled={props.disabled}
              value={props.value}
              onChangeEvent={props.onChange}
              error={props.error}
              percentageValue={props.percentageValue}
              prefix={props.prefix || "R$"}
            />
            {props.error && (
              <FormHelperText sx={styles.ErrorText}>{props.errorText}</FormHelperText>
            )}
          </FormControl>
        </Box>
      );

    case "date":
      return (
        <Box component="form" sx={styles.content}>
          {props.title && (
            <InputLabel
              sx={{ ...styles.title, color: props.error ? "#d32f2f" : "#222222" }}
              htmlFor="outlined-adornment-amount"
            >
              {props.title}
            </InputLabel>
          )}
          <FormControl
            fullWidth
            variant="outlined"
            error={props.error}
            sx={[
              styles.FormControl,
              {
                border: props.error ? "1px solid #d32f2f" : 0,
                height: "57px",
                borderRadius: "5px",
                ...props.styleControl,
              },
            ]}
          >
            <DateInput {...props} />
            {props.error && (
              <FormHelperText sx={styles.ErrorText}>{props.errorText}</FormHelperText>
            )}
          </FormControl>
        </Box>
      );

    case "password":
      return (
        <Box component="form" sx={styles.content}>
          <InputLabel sx={{ ...styles.title, color: props.error ? "#d32f2f" : "#222222" }}>
            {props.title}
          </InputLabel>
          <FormControl
            sx={[
              styles.FormControl,
              { border: props.error ? "1px solid #d32f2f" : 0, height: "56px" },
            ]}
            variant="outlined"
            error={props.error}
          >
            <TextField
              sx={InputStyles(props.styles)}
              type="password"
              variant="outlined"
              placeholder={props.placeholder}
              {...props}
            />
            {props.error && (
              <FormHelperText sx={styles.ErrorText}>{props.errorText}</FormHelperText>
            )}
          </FormControl>
        </Box>
      );

    default:
      return (
        <Box component="form" sx={styles.content}>
          <InputLabel sx={{ ...styles.title, color: props.error ? "#d32f2f" : "#222222" }}>
            {"Defina o Type Input: select, text, number, amount, mask, aliquot"}
          </InputLabel>
          <TextField
            sx={InputStyles(props.styles)}
            variant="outlined"
            placeholder={props.placeholder}
            {...props}
          />
        </Box>
      );
  }
}

// ⇡⇡⇡⇡⇡⇡ AVISO SOBRE A ESTILIZAÇÃO DOS INPUTS E ERROS NO TOPO DO DOCUMENTO ⇡⇡⇡⇡⇡⇡

function Input({ type, ...props }) {
  return <>{renderInputComponent(type, props)}</>;
}

export default Input;

const styles = {
  content: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: 12,
    fontWeight: 700,
    textAlign: "left",
    fontFamily: "Montserrat",
    color: "black",
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  FormControl: {
    marginTop: "5px",
    padding: 0,
    borderRadius: "3px",
  },
  ErrorText: {
    color: "#d32f2f",
    fontFamily: "Montserrat",
  },
};

const InputStyles = (styles) => ({
  padding: "4px 10px",
  width: "100%",
  height: "54px !important",
  backgroundColor: "#FBFBFB",
  boxShadow: 0,
  color: "#000 !important",
  borderRadius: "3px",
  "input:-webkit-autofill": { "-webkit-box-shadow": "0 0 0 1000px transparent inset" },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
    fontFamily: "Montserrat !important",
    height: "54px !important",
    fontSize: 14,
    "-webkit-text-fill-color": "#757575",
  },
  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    height: "54px !important",
    fontFamily: "Montserrat !important",
    alignSelf: "start",
  },
  ".css-16z0l1v-MuiFormControl-root-MuiTextField-root": {
    border: "1px solid #FBFBFB",
    boxShadow: 0,
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: 0,
    boxShadow: 0,
  },
  ".MuiOutlinedInput-root": {
    boxShadow: 0,
    height: "54px !important",
    fontSize: 14,
    fontFamily: "Montserrat !important",
  },
  ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
    boxShadow: 0,
  },
  ".MuiInputBase-root": {
    height: "100%",
    paddingBottom: 1,
    fontSize: 14,
    fontFamily: "Montserrat !important",
  },
  ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
    height: "54px !important",
    color: "black", //text on input
    paddingInLine: 1,
    fontSize: 14,
    fontFamily: "Montserrat !important",
  },
  ...styles,
});

const TextareaStyles = (styles) => ({
  fontFamily: "Montserrat",
  fontSize: 14,
  width: "100%",
  backgroundColor: "#FBFBFB",
  boxShadow: 0,
  color: "#000",
  border: 0,
  borderRadius: "3px",
  padding: "5px 10px",
  resize: "none",
  "& .MuiInputBase-root.Mui-disabled": {
    color: "#000",
  },
  ...styles,
});

const styleSelectInput = (style) => ({
  control: () => ({
    backgroundColor: "#FBFBFB",
    borderRadius: "3px",
    height: "54px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: 14,
    ":focus-within": {
      border: style?.borderOnHover || "unset",
    },
    ":hover": {
      borderColor: "unset",
      outline: "none",
      border: style?.borderOnHover || "unset",
    },
    ...style,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "9999",
    background: style?.backgroundColor || "#FBFBFB",
    borderRadius: "3px",
    fontSize: 14,
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    color: style?.color || "black",
  }),
  option: (provided) => ({
    ...provided,
    background: "transparent",
    color: style?.color || "black",
    textAlign: "left",
    ":hover": {
      background: style?.hoverColor || "#CDCDCD",
      cursor: "pointer",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: style?.color || "black",
    padding: "6px 10px",
  }),
  dropdownIndicator: (style) => ({
    ...style,
    color: style?.color || "#504B4B",
    cursor: "pointer",
  }),
});
