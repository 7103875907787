import React from "react";
import { useHistory } from "react-router-dom";
// controller
import MessagesController from "~/presentation/views/Admin/components/MessagesController";
// data
import filtersList from "~/presentation/views/Admin/Email/List/EmailFilterList.js";
// components
import PrimaryButton from "~/presentation/components/PrimaryButton";
import SearchField from "~/presentation/components/SearchField";
import PageHeader from "~/presentation/views/Admin/components/PageHeader";
import ListActions from "~/presentation/views/Admin/components/MessageListActions";
import MessageList from "~/presentation/views/Admin/components/MessageList";
// material ui
import { FilterList as FilterListIcon, Add as AddIcon } from "@mui/icons-material";
//styles
import {
  Container,
  Wrapper,
  ListHeader,
  Pagination,
} from "~/presentation/views/Admin/Email/List/styles";
import OpenPopover from "~/presentation/components/Popover";

const Email = ({ emailUseCases }) => {
  const history = useHistory();

  const loadEmails = async (page, size, filter, search) => {
    return await emailUseCases.getEmails(page, size, filter, search);
  };

  const handleDeleteEmail = async (ids) => {
    return emailUseCases.deleteEmails(ids);
  };

  const {
    mobile,
    selectedMessages,
    messages,
    setSelectedMessages,
    handleDelete,
    handleRefresh,
    handleSearch,
    numberOfPages,
    page,
    setPage,
    fetchMessagesWithFilter,
    CustomFilterList,
    handleSelectMessage,
    searchValue,
    handleClickMessage,
    filter,
  } = MessagesController({
    getMessages: loadEmails,
    onDelete: handleDeleteEmail,
    filtersList,
    replyMessagePath: "/admin/email/responder/",
  });

  return (
    <>
      <PageHeader>
        <h1>Emails</h1>
        {mobile && (
          <SearchField value={searchValue} handleSearch={(value) => handleSearch(value)} />
        )}
      </PageHeader>

      <Container>
        {!mobile && (
          <Wrapper className="filters">
            <PrimaryButton
              onClick={() => history.push("/admin/email/novo")}
              fullWidth
              backgroundColor="#1341a1"
              size="small"
            >
              Novo Email
            </PrimaryButton>
            <div>
              <hr className="divider" />
              <CustomFilterList />
            </div>
          </Wrapper>
        )}

        <Wrapper className="messages">
          <ListHeader>
            <ListActions
              selectedItems={selectedMessages}
              items={messages}
              onChange={setSelectedMessages}
              handleDelete={handleDelete}
              handleRefresh={handleRefresh}
              renderAfter={
                mobile &&
                (() => (
                  /* mobile: button to open filters menu */
                  <OpenPopover icon={FilterListIcon} label={filter.alias}>
                    {() => (
                      <Wrapper className="filters">
                        <CustomFilterList />
                      </Wrapper>
                    )}
                  </OpenPopover>
                ))
              }
            />

            {mobile ? (
              /* mobile button to new email */
              <div className="mr_12">
                <PrimaryButton
                  backgroundColor="#1341a1"
                  onClick={() => history.push("/admin/email/novo")}
                  size="small"
                >
                  Novo <AddIcon fontSize="small" />
                </PrimaryButton>
              </div>
            ) : (
              <div>
                <SearchField value={searchValue} handleSearch={(value) => handleSearch(value)} />
              </div>
            )}
          </ListHeader>

          <MessageList
            messages={messages}
            selectedMessages={selectedMessages}
            handleSelectMessage={handleSelectMessage}
            disableGutters={mobile}
            onClick={handleClickMessage}
          />

          <Pagination
            size="small"
            count={numberOfPages}
            onChange={(event, selectedPage) => [
              setPage(page),
              fetchMessagesWithFilter({ page: selectedPage }),
            ]}
            page={page}
          />
        </Wrapper>
      </Container>
    </>
  );
};

export default Email;
