// import fakeUsers from "./fake-users.json";
import { apiURL } from "../../usecases/api";
export class ApiUserUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getUsers() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/User/`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getUserByCompanyId(companyId) {
    // let response = { data: fakeUsers };
    let response;
    try {
      response.data = await this.httpClient.request({
        url: `${apiURL}/user/${companyId}`,
        method: "GET",
      });
    } catch (error) {
      console.log(error);
    }
    return response.data;
  }

  async getUserById(id) {
    let response;
    try {
      response = await this.httpClient.request({
        url: `${apiURL}/User/${id}`,
        method: "GET",
      });
    } catch (error) {
      console.log(error);
    }
    return response.data;
  }

  async deleteUser(id) {
    const response = await this.httpClient.request({
      url: `${apiURL}/User/${id}`,
      method: "DELETE",
    });
    return response.data;
  }

  async deleteUserOfCompany(id, companyId) {
    let response = { data: null };
    try {
      response = await this.httpClient.request({
        url: `${apiURL}/${id}/${companyId}`,
        method: "DELETE",
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
    return response.data;
  }

  async promoteOrDemoteUser({ id, action, password }) {
    const response = await this.httpClient.request({
      url: `${apiURL}/User/promoteToAdmin/${id}`,
      data: {
        password,
        action,
      },
      method: "PUT",
    });
    return response.data;
  }

  async register(data) {
    let response = { data: null };
    try {
      response = await this.httpClient.request({
        url: `${apiURL}/User`,
        data,
        method: "POST",
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
    return response.data;
  }

  getUserGroups() {
    const { role } = JSON.parse(localStorage.getItem("GT_user"));
    let groups = [{ label: "Usuário", value: "user" }];
    switch (role) {
      case "manager":
        groups.push({ label: "Gerente", value: "manager" });
        break;
      case "admin":
        groups.push({ label: "Gerente", value: "manager" });
        groups.push({ label: "Administrador", value: "admin" });
        break;
      case "superAdmin":
        groups.push({ label: "Administrador", value: "admin" });
        groups.push({ label: "Gerente", value: "manager" });
        groups.push({ label: "Super Administrador", value: "superAdmin" });
        break;
    }
    return groups;
  }

  async updateUser(id, data) {
    let response = { data: null };
    try {
      response = await this.httpClient.request({
        url: `${apiURL}/User/${id}`,
        data,
        method: "PUT",
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
    return response.data;
  }

  async getTaxData() {
    let response;
    try {
      response = await this.httpClient.request({
        url: `${apiURL}/taxinformation/balance/`,
        method: "GET",
      });
    } catch (error) {
      console.log(error);
    }
    return response.data;
  }
}
