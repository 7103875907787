import React, { useContext } from "react";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
// import Select from "react-select";
import { default as MultiCheckBoxSelect } from "react-select";
import { customStyles } from "~/presentation/components/Select/styles";
import { ThemeContext } from "styled-components";
import PropTypes from "prop-types";

const MultiCheckBoxField = (props) => {
  const {
    options,
    field,
    placeholder,
    form,
    onChange,
    padding,
    label,
    // name,
    // defaultValue,
    allowSelectAll,
    closeMenuOnSelect,
    hideSelectedOptions,
    ...rest
  } = props;

  const appTheme = useContext(ThemeContext);

  // const getValue = () => {
  //   if (!options) return "";
  //   const foundOption = options.find((option) => {
  //     console.log(option.value, field.value);
  //     return JSON.stringify(option.value) === JSON.stringify(field?.value[0]?.value)
  //   });
  //   console.log("foundOption", foundOption);
  //   return foundOption;
  // };

  const LimitedChipsContainer = ({ children, hasValue, ...props }) => {
    if (!hasValue) {
      return <components.ValueContainer {...props}>{children}</components.ValueContainer>;
    }

    const CHIPS_LIMIT = 2;
    const [chips, otherChildren] = children;
    const overflowCounter = chips.slice(CHIPS_LIMIT).length;
    const displayChips = chips.slice(overflowCounter, overflowCounter + CHIPS_LIMIT);

    return (
      <components.ValueContainer {...props}>
        {displayChips}

        {overflowCounter > 0 && `+ ${overflowCounter}`}

        {otherChildren}
      </components.ValueContainer>
    );
  };

  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const Option = (props) => (
    <div>
      <components.Option {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );

  const allOptions = [props.allOption, ...options];
  const animatedComponents = makeAnimated();

  if (props.allowSelectAll) {
    return (
      <MultiCheckBoxSelect
        components={{
          Option,
          MultiValue,
          ValueContainer: LimitedChipsContainer,
          animatedComponents,
        }}
        isMulti={true}
        allowSelectAll={allowSelectAll}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        placeholder={placeholder}
        label={label}
        options={allOptions}
        onChange={(selected) => {
          if (
            selected !== null &&
            selected.length > 0 &&
            selected[selected.length - 1].value === props.allOption.value
          ) {
            return onChange(options);
          }
          return onChange(selected);
        }}
        name={field.name}
        value={form?.values?.cnaes}
        onBlur={field.onBlur}
        appTheme={appTheme}
        styles={customStyles(appTheme, { padding })}
        noOptionsMessage={() => "Não há opções"}
        theme={(theme) => ({
          ...theme,
          colors: {
            primary50: "#d7d7d7",
            primary25: "#dedede",
            primary: "#1e88e5",
          },
        })}
        {...rest}
      />
    );
  }
  return <MultiCheckBoxSelect {...props} />;
};

MultiCheckBoxField.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

MultiCheckBoxField.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default MultiCheckBoxField;
