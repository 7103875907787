import React from "react";

import Checkbox from "~/presentation/components/Checkbox";

// styles
import { Container } from "~/presentation/components/QSOTable/styles";

const options = [
  { column: "label", row: "Todos" },
  { column: "inss1", row: ["A", "B", "C", "D"] },
  { column: "inss2", row: ["A", "B", "C", "D"] },
  { column: "inss3", row: ["A", "B", "C", "D"] },
  { column: "irrf1", row: ["A", "B", "C", "D"] },
  { column: "irrf2", row: ["A", "B", "C", "D"] },
  { column: "csllPisCofins", row: ["A", "B", "C", "D"] },
  { column: "iss1", row: ["A", "B", "C", "D"] },
  { column: "iss2", row: ["A", "B", "C", "D"] },
  { column: "label", row: "Tributos" },
  { column: "tributes", row: "INSS 1" },
  { column: "tributes", row: "INSS 2" },
  { column: "tributes", row: "INSS 3" },
  { column: "tributes", row: "IRRF 1" },
  { column: "tributes", row: "IRRF 2" },
  { column: "tributes", row: "CSLL, PIS e COFINS" },
  { column: "tributes", row: "ISS 1" },
  { column: "tributes", row: "ISS 2" },
  { column: "label", row: "A" },
  { column: "inss1", row: "A" },
  { column: "inss2", row: "A" },
  { column: "inss3", row: "A" },
  { column: "irrf1", row: "A" },
  { column: "irrf2", row: "A" },
  { column: "csllPisCofins", row: "A" },
  { column: "iss1", row: "A" },
  { column: "iss2", row: "A" },
  { column: "label", row: "B" },
  { column: "inss1", row: "B" },
  { column: "inss2", row: "B" },
  { column: "inss3", row: "B" },
  { column: "irrf1", row: "B" },
  { column: "irrf2", row: "B" },
  { column: "csllPisCofins", row: "B" },
  { column: "iss1", row: "B" },
  { column: "iss2", row: "B" },
  { column: "label", row: "C" },
  { column: "inss1", row: "C" },
  { column: "inss2", row: "C" },
  { column: "inss3", row: "C" },
  { column: "irrf1", row: "C" },
  { column: "irrf2", row: "C" },
  { column: "csllPisCofins", row: "C" },
  { column: "iss1", row: "C" },
  { column: "iss2", row: "C" },
  { column: "label", row: "D" },
  { column: "inss1", row: "D" },
  { column: "inss2", row: "D" },
  { column: "inss3", row: "D" },
  { column: "irrf1", row: "D" },
  { column: "irrf2", row: "D" },
  { column: "csllPisCofins", row: "D" },
  { column: "iss1", row: "D" },
  { column: "iss2", row: "D" },
];

export default function CustomizedDividers({ handleChange, values }) {
  const handleSelect = (column, row) => {
    let newValues = { ...values };

    if (Array.isArray(row)) {
      // if is array, select all options
      if (row.every((item) => values[column].includes(item))) {
        newValues[column] = []; // if all options are already selected, remove all
      } else {
        newValues[column] = row; // else, select all
      }
    } else if (values[column].includes(row)) {
      newValues[column] = values[column].filter((oldRow) => oldRow !== row);
    } else {
      newValues[column] = [...values[column], row];
    }
    handleChange(newValues);
  };

  const isChecked = (column, row) => {
    if (Array.isArray(row)) {
      if (row.every((item) => values[column].includes(item))) return true;
      else return false;
    } else return values[column].includes(row);
  };

  return (
    <Container>
      {options.map(({ column, row }) =>
        column === "label" ? (
          <h3 key={column + row}>{row}</h3>
        ) : column === "tributes" ? (
          <span key={column + row}>{row}</span>
        ) : (
          <Checkbox
            key={column + row}
            checked={isChecked(column, row)}
            onChange={() => handleSelect(column, row)}
          />
        )
      )}
    </Container>
  );
}
