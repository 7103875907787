import React from "react";
import { Container, Grid, Box, Typography as Text } from "@mui/material";
import { Close } from "@mui/icons-material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";

export const EventDetailsModal = ({ open, onClose, eventDetails }) => {
  const normalizedDetails = Array.isArray(eventDetails) ? eventDetails : [eventDetails];

  const handleDownloadXml = (xmlContent) => {
    if (!xmlContent) return;

    const blob = new Blob([xmlContent], { type: "text/xml" });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = "ProtocoloEfdReinf.xml";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  return (
    <Container sx={styles.content}>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={11}>
          <Text style={styles.Title}>Detalhes do protocolo de Envio</Text>
        </Grid>
        <Grid
          item
          xs={6}
          sm={1}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <Close onClick={onClose} />
        </Grid>
        {normalizedDetails?.map((detail, responseIndex) => (
          <Grid item xs={6} sm={11} key={responseIndex}>
            <Grid item xs={12}>
              <Text sx={styles.subtitle}>Descrição da resposta</Text>
              <Text
                sx={styles.description}
              >{`${detail.codeResponse} - ${detail.descriptionResponse}`}</Text>
            </Grid>
            {detail?.events?.map((event, index) => (
              <Grid item xs={12} key={index}>
                <Text sx={styles.subtitle}>Evento</Text>
                <Text sx={styles.description}>{`Protocolo: ${event.protocolNumber}, N. do Recibo: ${
                  event.receiptNumber || "Não disponível"
                }`}</Text>
              </Grid>
            ))}
            {detail.occurrences?.map((occ, index) => (
              <Grid item xs={12} key={index}>
                <Text sx={styles.subtitle}>Ocorrências</Text>
                <Text sx={styles.description}>{`${occ.description} - ${occ.text}`}</Text>
              </Grid>
            ))}
            {detail.xml && (
              <Grid item xs={12} sx={{ pt: 2 }}>
                <RButton sx={{ width: "30%" }} onClick={() => handleDownloadXml(detail.xml)}>
                  Baixar XML
                </RButton>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
        <RButton sx={{ width: "150px" }} type="button" variant="outlined" onClick={onClose}>
          Fechar
        </RButton>
      </Box>
    </Container>
  );
};

const styles = {
  content: {
    padding: "2rem",
  },
  Title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 24,
    fontWeight: 700,
    color: "#404040",
    display: "flex",
    alignItems: "center",
  },
  subtitle: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 16,
    fontWeight: 600,
    color: "#404040",
    pt: 2,
  },
  description: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 14,
    fontWeight: 500,
    textAlign: "justify",
    pt: 1,
  },
};
