import React, { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AutocompleteSelect from "~/presentation/components/AutocompleteSelect";
import { Card as ContentCard } from "~/presentation/views/GTFacil/components/Card";
import { GTLayout } from "../components/GTLayout";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import { Box, debounce, Typography, Stack } from "@mui/material";
import ButtonCommon from "~/presentation/components/Common/Button";

export const Home = ({ gtFacilUseCases, cnaesUseCases }) => {
  const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [options, setOptions] = useState([]);
  const history = useHistory();

  const user = JSON.parse(localStorage.getItem("GT_user")); // permissões
  const initialFilters = [
    {
      name: "cnae",
      label: "Pesquisa por CNAE",
    },
    {
      name: "lc116",
      label: "Pesquisa pela LC 116/2003",
    },
    ...(user.role === "superAdmin" || user.role === "admin"
      ? [
          {
            name: "specificSearch",
            label: "Pesquisa Específica",
          },
        ]
      : []),
  ];

  const [filters, setFilters] = useState(initialFilters);
  const [selectedFilter, setSelectedFilter] = useState(filters[0].name);

  useEffect(() => {
    selectFilter(filters[0]?.name);
  }, []);

  const cnaeLoad = async (iscnae = true) => {
    try {
      setLoadingFilter(true);
      const cnaes = await gtFacilUseCases.getCnaeGT(iscnae);
      setOptions(dataToSelectMapper(cnaes));
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingFilter(false);
    }
  };

  const loadTerms = async () => {
    try {
      setLoadingFilter(true);
      const terms = await gtFacilUseCases.getSearchTerms();
      setOptions(dataToSelectMapper(terms));
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFilter(false);
    }
  };

  const selectFilter = async (filter) => {
    if (loadingFilter) return;

    setSelectedFilter(filter);
    switch (filter) {
      case "cnae":
      case "lc116":
        cnaeLoad(filter === "cnae");
        break;
      default:
        loadTerms();
    }
  };

  const handleSearch = async (value) => {
    let shouldOpenAutoComplete = true;
    if (selectedFilter === "specificSearch" && value.length < 3) {
      shouldOpenAutoComplete = false;
    }
    setIsAutoCompleteOpen(shouldOpenAutoComplete);
  };

  const handleSearchClick = (item) => {
    let url = `/gt-facil/pesquisa`;
    if (selectedFilter === "cnae") {
      history.push({
        pathname: url,
        search: `?cnaeId=${item.id}`,
        state: { cnae: item },
      });
    } else if (selectedFilter === "freeSearch" || selectedFilter === "specificSearch") {
      history.push({
        pathname: url,
        search: `?searchTermId=${item.id}`,
        state: { term: item },
      });
    } else {
      history.push({
        pathname: url,
        search: `?answerId=${item.id}`,
        state: { answer: item },
      });
    }
  };

  const debouncedSearchHandler = useMemo(() => debounce(handleSearch, 400), [selectedFilter]);

  const renderOptions = (props, option) => {
    const detailCode = option.detailCode ? ` (${option.detailCode})` : "";
    const { key, ...optionProps } = props;

    return (
      <Box key={`${key}-${option.id}`} {...optionProps}>
        {selectedFilter === "specificSearch" ? (
          <Typography sx={{ fontWeight: 700, fontFamily: "Montserrat" }}>{option.term}</Typography>
        ) : (
          <>
            <Box sx={{ fontWeight: 700, fontFamily: "Montserrat" }}>
              {`${option.code}${detailCode} - ${option.description}`}
              <Typography
                sx={{ fontStyle: "italic", fontSize: 12, color: "grey", fontFamily: "Montserrat" }}
              >
                <strong>
                  {selectedFilter === "cnae" ? "Relacionado ao subitem:" : "Relacionado ao CNAE:"}{" "}
                </strong>
                {option.observation}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    );
  };

  return (
    <GTLayout title="Consulte a tributação de seus contratos com o GT-Fácil">
      <ContentCard title="Como deseja realizar a pesquisa?">
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
          {filters.map((filter, index) => (
            <ButtonCommon
              sx={{
                maxWidth: "fit-content",
                backgroundColor: filter.name === selectedFilter ? "#1341a1" : "#fff",
                color: filter.name === selectedFilter ? "#fff" : "#1341a1",
                border: "1px solid #1341a1",
                "&:hover": {
                  backgroundColor: filter.name === selectedFilter ? "#1341a1" : "#fff",
                  color: filter.name === selectedFilter ? "#fff" : "#1341a1",
                },
              }}
              key={`${filter.name}-${index}`}
              onClick={() => {
                selectFilter(filter.name);
              }}
            >
              {filter.label}
            </ButtonCommon>
          ))}
        </Stack>
        <Box sx={{ margin: "28px 0" }}>
          <AutocompleteSelect
            freeSolo
            open={isAutoCompleteOpen}
            onOpen={() => setIsAutoCompleteOpen(selectedFilter !== "specificSearch")}
            onClose={() => setIsAutoCompleteOpen(false)}
            isDisabled={loadingFilter}
            options={options}
            label="Pesquisar"
            onChange={(e, value) => handleSearchClick(value)}
            noOptionsText="Nenhuma opção encontrada."
            inputProps={{
              onChange: (event) => {
                event.persist();
                debouncedSearchHandler(event.target.value);
              },
            }}
            getOptionLabel={(option) =>
              selectedFilter === "specificSearch"
                ? option.term
                : `${option.code} - ${option.description} - ${option.synonym} - ${option.codeNumbers}`
            }
            renderOption={(props, option) => {
              return renderOptions(props, option);
            }}
          />
        </Box>
        <Typography sx={{ fontSize: 14 }}>
          * Selecione ou clique em um botão para filtrar a pesquisa.
        </Typography>
      </ContentCard>
    </GTLayout>
  );
};
