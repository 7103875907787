import {format} from "date-fns";

export const statsTableColumns = {
  "search": [
    {
      name: 'date',
      label: 'Data',
      options: {
        filter: false,
        customBodyRender: value => {
          let formatedDate = new Date(value);
          formatedDate = format(formatedDate, 'dd/MM/yyyy');
          return formatedDate;
        },
      },
    },
    { name: 'userName', label: 'Usuário' },
    { name: 'companyName', label: 'Empresa' },
    { name: 'cnae', label: 'CNAE' },
    { name: 'term', label: 'Termo' },
    { name: 'categoryName', label: 'Categoria' },
    { name: 'branch', label: 'Filial' },
    { name: 'simples', label: 'Simples' },
    { name: 'cityHireName', label: 'Cidade Contratante' },
    { name: 'cityServiceName', label: 'Cidade Prestadora' },
    { name: 'cityContractorName', label: 'Cidade Contratada' },
  ],
  "search-summary": [
    { name: 'userName', label: 'Usuário' },
    {
      name: 'fisrtSearch',
      label: 'Primeira busca',
      options: {
        filter: false,
        customBodyRender: value => {
          let formatedDate = new Date(value);
          formatedDate = format(formatedDate, 'dd/MM/yyyy');
          return formatedDate;
        },
      },
    },
    {
      name: 'lastSearch',
      label: 'Última busca',
      options: {
        filter: false,
        customBodyRender: value => {
          let formatedDate = new Date(value);
          formatedDate = format(formatedDate, 'dd/MM/yyyy');
          return formatedDate;
        },
      },
    },
    { name: 'companyName', label: 'Empresa' },
    { name: 'amount', label: 'Quantidade' },
    { name: 'percent', label: 'Porcentagem' },
  ],
  "content-access": [
    { name: 'title', label: 'Título' },
    { name: 'amount', label: 'Quantidade' },
  ]
};