import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import {
  makeArticle,
  makeArticlePost,
  makeClientHome,
  makeContactUs,
  makeExplanatoryVideo,
  makeLegislation,
  makeLegislationPost,
  makeMultimediaHome,
  makeMyPanel,
  makeQSO,
} from "~/main/factories/pages/client";

const ClientRoutes = ({ match: { url } }) => {
  return (
    <Switch>
      <Route exact path={`${url}`} component={makeClientHome} />
      {/** legislation routes */}
      <Route exact path={`${url}/conteudo-selecionado/legislacao/`} component={makeLegislation} />
      <Route
        exact
        path={`${url}/conteudo-selecionado/legislacao/post/:id`}
        component={makeLegislationPost}
      />
      {/** article routes */}
      <Route exact path={`${url}/conteudo-selecionado/artigo/`} component={makeArticle} />
      <Route
        exact
        path={`${url}/conteudo-selecionado/artigo/post/:id`}
        component={makeArticlePost}
      />
      {/* multimedia routes */}
      <Route exact path={`${url}/conteudo-selecionado/multimidia`} component={makeMultimediaHome} />
      {/* explanatory videos routes */}
      <Route exact path={`${url}/ajuda`} component={makeExplanatoryVideo} />
      {/* qso routes */}
      <Route exact path={`${url}/qso`} component={makeQSO} />

      {/* contact us routes */}
      <Route exact path={`${url}/fale-conosco`} component={makeContactUs} />

      {/* my panel routes */}
      <Route exact path={`${url}/meu-painel`} component={makeMyPanel} />

      <Redirect to={url} />
    </Switch>
  );
};

export default ClientRoutes;
