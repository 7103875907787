import React, { useState, useContext } from "react";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import { AnswersStyles } from "~/presentation/views/Admin/GTFacil/Answers/Answers.styles";
import DropUploadZone from "~/presentation/components/DropUploadZone";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
export const Answers = ({ gtFacilUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [file, setFile] = useState();

  const handleFile = (uploadedFile) => {
    setFile(uploadedFile);
  };

  // const handleUpload = async () => {
  //   try {
  //     const upload = await uploadFile(selectedFile, config)
  //     return upload
  //   } catch (error) {
  //     setFeedbackSnackbar({
  //       isOpen: true,
  //       message: "Erro ao fazer upload.",
  //       type: "error",
  //     });
  //     return error
  //   }
  // }

  const handleSubmit = async () => {
    openBackdrop(true, "Salvando respostas de GT-Fácil");
    try {
      await gtFacilUseCases.saveAnswers(file);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar respostas de GT-Fácil: Missing Endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <AnswersStyles.Container>
      <h1>Importar Respostas de GT-Fácil</h1>
      <div className="dropUploadZone">
        <DropUploadZone handleChange={handleFile} />
      </div>
      <PrimaryButton className="submitBtn" onClick={handleSubmit} disabled={!file}>
        Enviar
      </PrimaryButton>
    </AnswersStyles.Container>
  );
};
