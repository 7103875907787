import { Form, Formik } from "formik";
import React, {useContext, useEffect, useRef, useState} from "react";
import { floatToReais } from "~/presentation/utils/reais-parser";
import {
  Button,
  FormWrapper,
  MoneyQuestion,
  RadioQuestion,
  SelectQuestion,
  SimulatorResultCard,
} from "../../../components";
import { SimulatorContext } from "../../../context/simulator-context";
import { getBaseCalculus, getDependentsValue, getIrrfResult } from "./calculator";
import { reportMapper } from "./reportMapper";
import aliquotData from "../../common/irrf/table-range";

const retentionAliquotOptions = [
  {
    value: 0.0,
    label: "0% - Valor de R$ 0,00 até R$ 2112,00.",
  },
  {
    value: 0.075,
    label: "7,5% - Valor de R$ 2.259,21 até R$ 2.826,65, com parcela a deduzir de R$ 169,44",
  },
  {
    value: 0.15,
    label: "15% - Valor de R$ 2.826,66 até R$ 3.751,05, com parcela a deduzir de R$ 381,44",
  },
  {
    value: 0.225,
    label: "22,5% - Valor de R$ 3.751,06 até R$ 4.664,68, com parcela a deduzir de R$ 662,77",
  },
  { value: 0.275, label: "27,5% - Acima de R$ 4.664,68, com parcela a deduzir de R$ 896,00" },
];

const numberOfDependentsOptions = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
];

export const TransportTypes = {
  cargo: "cargo",
  passenger: "passenger",
};

const initialValues = {
  isTransportService: "false",
  transportType: TransportTypes.cargo, // cargo | passenger
  hasDependents: "false",
  numberOfDependents: numberOfDependentsOptions[0],
  hasFoodPension: "false",
  foodPensionValue: 0,
  socialSecurityMonthPaymentValue: 0,
  retentionAliquot: retentionAliquotOptions[0],
};

export const IrrfIndividual = () => {
  const resultCardRef = useRef();
  const { simulatorValue, setInvalidSimulatorValue, inssIndividualValue, handleGenerateReport, handleCacheReport } =
    useContext(SimulatorContext);
  const [result, setResult] = useState();
  const [lastSubmitValues, setLastSubmitValues] = useState();
  const [percent, setPercent] = useState();

  const valuesNotChangedSinceLastSubmit = (values) => {
    return JSON.stringify(lastSubmitValues) === JSON.stringify(values);
  };

  const handleGenerateReportClick = (values) => {
    const response = reportMapper(values, result, simulatorValue);
    handleGenerateReport(response);
  };

 /* const aliquotData = {
    range1: {
      minValue: 0,
      maxValue: 2259.20,
      aliquot: 0.0,
      deductionParcel: 0,
    },
    range2: {
      minValue: 2259.21,
      maxValue: 2826.65,
      aliquot: 0.075,
      deductionParcel: 169.44,
    },
    range3: {
      minValue: 2826.66,
      maxValue: 3751.05,
      aliquot: 0.15,
      deductionParcel: 381.44,
    },
    range4: {
      minValue: 3751.06,
      maxValue: 4664.68,
      aliquot: 0.225,
      deductionParcel: 662.77,
    },
    range5: {
      minValue: 4664.69,
      maxValue: Number.POSITIVE_INFINITY,
      aliquot: 0.275,
      deductionParcel: 896.00,
    },
  };*/

  const handleSelectRange = (baseCalculus) => {
    const selectedRange = Object.values(aliquotData).find((data) => {
      return baseCalculus >= data.minValue && baseCalculus <= data.maxValue;
    });
    // Return the aliquot if a range was found, undefined otherwise
    setPercent(selectedRange.aliquot);
    return selectedRange ? selectedRange.aliquot : undefined;
  };

  useEffect(() => {
    if(result || lastSubmitValues) {
      resultCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [result, lastSubmitValues]);

  return (
    <Formik
      initialValues={{
        ...initialValues,
        socialSecurityMonthPaymentValue: inssIndividualValue,
      }}
      onSubmit={(values, action) => {
        if (!simulatorValue) {
          setInvalidSimulatorValue(true);
          return;
        }
        action.setSubmitting(true);

        setLastSubmitValues(values);

        const irrfResult = getIrrfResult(values, simulatorValue, percent);
        setResult(irrfResult);

        resultCardRef.current.scrollIntoView({ behavior: "smooth" });

        const retentionAliquot = {
          label: "",
          value: percent,
        };

        for (let item of retentionAliquotOptions) {
          if (item.value === percent) {
            retentionAliquot.label = item.label;
          }
        }

        const response = reportMapper(values, irrfResult, simulatorValue, retentionAliquot);
        handleCacheReport(response);

        action.setSubmitting(false);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <FormWrapper>
            <RadioQuestion
              name="isTransportService"
              text="O rendimento é decorrente do serviço de transporte?"
              onChange={(value) => setFieldValue("isTransportService", value)}
              value={values.isTransportService}
            />
            {values.isTransportService === "true" && (
              <RadioQuestion
                name="transportType"
                text="O transporte é de:"
                onChange={(value) => setFieldValue("transportType", value)}
                value={values.transportType}
                firstLabel="Carga"
                firstValue={TransportTypes.cargo}
                secondLabel="Passageiro"
                secondValue={TransportTypes.passenger}
              />
            )}
            <RadioQuestion
              name="hasDependents"
              text="Há dependentes?"
              onChange={(value) => {
                setFieldValue("hasDependents", value);
                setFieldValue("numberOfDependents", numberOfDependentsOptions[0]);
              }}
              value={values.hasDependents}
            />
            {values.hasDependents === "true" && (
              <SelectQuestion
                text="Há quantos dependentes?"
                excerpt="O regulamento do Imposto de Renda, em seu art. 77, prevê a possibilidade de dedução da base de cálculo de quantia fixada na legislação por dependente, sendo necessário que o contribuinte forneça declaração para a fonte pagadora."
                onChange={(value) => setFieldValue("numberOfDependents", value)}
                value={values.numberOfDependents.value}
                options={numberOfDependentsOptions}
              />
            )}
            <MoneyQuestion
              text="Valor da dedução dos dependentes:"
              excerpt="O valor da dedução será o equivalente a R$ 189,59 (cento e oitenta e nove reais e cinquenta e nove centavos) por dependente, a partir de abril de 2015."
              value={getDependentsValue(values)}
              disabled
            />
            <RadioQuestion
              name="hasFoodPension"
              text="Há pagamento de pensão alimentícia?"
              excerpt="O regulamento do Imposto de Renda prevê em seu art. 78, a possibilidade de dedução da importância paga a título de pensão alimentícia. É necessário que a pensão seja fixada em sentença/acordo judicial ou em escritura pública de divórcio extrajudicial averbada em cartório."
              onChange={(value) => {
                setFieldValue("hasFoodPension", value);
                setFieldValue("foodPensionValue", 0);
              }}
              value={values.hasFoodPension}
            />
            {values.hasFoodPension === "true" && (
              <MoneyQuestion
                text="Valor da pensão alimentícia:"
                value={values.foodPensionValue}
                onChange={(_event, _maskedValue, floatValue) => {
                  setFieldValue("foodPensionValue", floatValue);
                }}
              />
            )}
            <MoneyQuestion
              text="Valor pago no mês à Previdência Social:"
              excerpt="O Regulamento do Imposto de Renda prevê em seu art. 67, l, a possibilidade de dedução das contribuições para a Previdência Social da União, dos Estados, do DF e dos Municípios."
              value={values.socialSecurityMonthPaymentValue}
              onChange={(_event, _maskedValue, floatValue) => {
                setFieldValue("socialSecurityMonthPaymentValue", floatValue);
              }}
            />
            <MoneyQuestion
              text="Base de cálculo da retenção do IR:"
              value={getBaseCalculus(values, simulatorValue)}
              disabled
            />
            <SelectQuestion
              text="Alíquota de retenção do IR:"
              value={handleSelectRange(getBaseCalculus(values, simulatorValue))}
              options={retentionAliquotOptions}
              menuPlacement="top"
              disabled
            />
            <Button>Ver Resultado</Button>

            {(result && valuesNotChangedSinceLastSubmit(values)) || result == 0 ? (
              <SimulatorResultCard
                componentRef={resultCardRef}
                title="Valor da retenção do IR:"
                value={floatToReais(result)}
                description={`O valor da retenção do IR é aquele que deve ser abatido do valor a ser pago para o contratado e corresponde ao resultado da multiplicação da alíquota de retenção pela base de cálculo, menos a "parcela a deduzir" indicada na Tabela Progressiva.`}
                generateReportButton={() => handleGenerateReportClick(values)}
              />
            ) : null}
          </FormWrapper>
        </Form>
      )}
    </Formik>
  );
};
