import React, { useState, useEffect, useContext, useRef } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import PageListHeader from "../../components/PageListHeader";
// functions
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Container } from "@mui/material";
import { useTableFilters } from "~/presentation/hooks/useTableFilters";

const columns = [
  { name: "gtAnswerItemType.title", label: "Tipo" },
  { name: "title", label: "Título" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

const ItemAnswerTable = ({ history, itemAnswerUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);
  const { handleSearchTable } = useTableFilters();

  const [storage, setStorage] = useState([]);
  const [itemsAnswer, setItemsAnswer] = useState([]);
  const searchFieldRef = useRef();

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");

    const apiItemsAnswer = await itemAnswerUseCases.getAll();

    const userIsSuperAdmin = context.haveSuperAdminAccess();

    apiItemsAnswer?.forEach((itemAnswer) => {
      if (userIsSuperAdmin) {
        itemAnswer.actions = (
          <TableActions
            handleDelete={() => deleteDialog(itemAnswer.id)}
            handleEdit={() => handleEdit(itemAnswer)}
          />
        );
      } else {
        itemAnswer.actions = <TableActions handleEdit={() => handleEdit(itemAnswer)} />;
      }
    });
    setItemsAnswer(apiItemsAnswer);
    setStorage(apiItemsAnswer);
    openBackdrop(false);
  };

  const handleEdit = (itemAnswer) => {
    history.push({
      pathname: `/admin/itens-de-resposta/editar/${itemAnswer.id}`,
      itemAnswer,
    });
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo tipo de resposta");
    try {
      await itemAnswerUseCases.delete(id);
      await fetchData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir tipo de resposta: Missing endpoint.",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este serviço?`,
      callback: () => handleDelete(id),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (value) => {
    handleSearchTable(value, storage, setItemsAnswer);
  };

  const handleAdd = () => history.push("/admin/itens-de-resposta/cadastrar");
  return (
    <Container maxWidth="100%">
      <PageListHeader
        title="Item de resposta"
        titleAccess="Novo item"
        searchFieldRef={searchFieldRef}
        handleAdd={handleAdd}
        handleSearch={handleSearch}
      />
      <Table columns={columns} data={itemsAnswer} title="Tipos de Resposta" />
    </Container>
  );
};

export default ItemAnswerTable;
