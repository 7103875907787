import styled from "styled-components";

export const InputWrapper = styled.div`
  background: #e0e0e0;
  display: flex;
  border-radius: 4px;
  align-items: center;
  overflow: hidden;
  padding-left: max(16px, 2vw);
  cursor: text;
  height: 4rem;
  width: 24rem;
  border-radius: 11px;
  position: relative;
  margin-left: 0.5rem;

  input {
    border: none;
    outline: none;
    background: transparent;
    width: 75%;
    font-size: clamp(0.8rem, 1.4vw, 40px);
  }

  > svg {
    position: absolute;
    width: max(2vw, 20px);
    right: 30%;
    height: auto;
    z-index: 2;

    cursor: pointer;
  }

  button {
    outline: none;
    border: none;
    background: #ff6829;
    color: #fafafa;
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    // svg {
    //   width: 45%;
    //   height: auto;
    //   z-index: 2;
    // }

    // span {
    //   z-index: 0;
    //   width: clamp(20px, 2vw, 40px);
    //   height: clamp(20px, 2vw, 40px);
    //   position: absolute;
    //   left: -7%;
    //   transform: rotate(45deg);
    //   background: #1341a1;
    // }
  }
`;

export const ImgSearch = styled.img`
  position: absolute;
  width: max(2vw, 10px);
  right: 30%;
  height: auto;
  z-index: 2;

  cursor: pointer;
`;
