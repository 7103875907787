import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import ItemAnswerForm from "../Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

const initialValues = {
  id: 0,
  title: "",
  text: "",
  gtAnswerItemType: {},
};

export const ItemAnswerCreate = ({ typesAnswerUseCases, itemAnswerUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);

  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [typesAnswer, setTypesAnswer] = useState([]);

  const history = useHistory();

  const fetchData = async () => {
    openBackdrop(true, "Carregando tipos de resposta");
    try {
      const apiTypesAnswer = await typesAnswerUseCases.getTypesAnswer();
      setTypesAnswer(apiTypesAnswer);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao buscar tipos de resposta",
        type: "error",
      });
      console.log(error);
    } finally {
      openBackdrop(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    openBackdrop(true, "Cadastrando item de resposta");
    try {
      await itemAnswerUseCases.create(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Item de resposta cadastrado com sucesso!",
        type: "success",
      });
      history.push("/admin/itens-de-resposta");
    } catch (err) {
      console.log(err);
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar item de resposta",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Cadastrar Item de Resposta" />
      <ItemAnswerForm
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        data={typesAnswer}
      />
    </Container>
  );
};
