import React, { useState, useContext, useEffect } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import {
  Container,
  Typography as Text,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  TablePagination,
  Tooltip,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { EditNote, UploadFileOutlined, DeleteOutline } from "@mui/icons-material";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { ItemCompany } from "./ItemCompany";
import { SendR1000 } from "../SendR1000/SendR1000";
import { ApiItemCompany } from "~/data/usecases/itemCompany/apiItemCompany";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";

export const ListItemsR1000 = ({ matrizCompany }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  //TEMS R1000
  const itemCompanyUseCases = new ApiItemCompany(makeHttpClient());
  const [itemCompany, setItemCompany] = useState([]);

  //tabel
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [loading, setLoading] = useState(true);

  //ID para modal delete/envio
  const [selectedItemId, setSelectedItemId] = useState([]);
  //numero opeação enviado para o modal de envio, 1-inclusão 3-exclusão
  const [operationNumber, setOperationNumber] = useState(null);
  //Dados Item para modal edit
  const [itemData, setItemData] = useState([]);

  const getItemCompany = async () => {
    setLoading(true);
    try {
      const result = await itemCompanyUseCases.listItemCompany();
      setItemCompany(result);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro carregar informações," + error,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getItemCompany();
  }, []);

  //MODAL DE EDIT
  const [openEditModal, setOpenEditModal] = useState(false);
  const handleOpenEditModal = (item) => {
    const id = item?.id || null;
    setSelectedItemId(id);
    setItemData(item);
    setOpenEditModal(true);
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedItemId(null);
  };

  //MODAL DE ENVIO
  const [openSendModal, setOpenSendModal] = useState(false);
  const handleOpenSendModal = (itemId, operation) => {
    setSelectedItemId(itemId);
    setOperationNumber(operation);
    setOpenSendModal(true);
  };
  const handleCloseSendModal = () => {
    setOpenSendModal(false);
    setSelectedItemId(null);
  };

  const handleDeleteItem = (itemId, isR1000) => {
    //caso isR1000 false vai direto delete no back
    if (!isR1000) {
      handleDelete(itemId);
    } else {
      //caso seja true ele abre modal envio R1000 exclusão
      // + delete no back após como callback
      handleOpenSendModal(itemId, 3);
    }
  };

  const handleDelete = async (itemId) => {
    openBackdrop(true, "Excluindo");
    try {
      await itemCompanyUseCases.deleteItemCompany(itemId);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Deletado com sucesso!",
        type: "success",
      });
      setItemCompany((prev) => prev.filter((item) => item.id !== itemId));
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao deletar: " + (error.message || "Erro desconhecido"),
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  // Objetivo: chamar o SendR1000 e após o envio bem sucedido
  // fazer a alteração no back da informação com a função enviada na callBackFnc
  const getModalSendR1000Props = () => {
    switch (operationNumber) {
      case 1:
        return { operationNumber: 1, callBackFnc: false };
      case 3:
        return { operationNumber: 3, callBackFnc: (operation) => handleDelete(operation) };
      default:
        return { operationNumber: 1, callBackFnc: false };
    }
  };

  //renderizar props modal inclusão ou modal exclusão
  const modalProps = getModalSendR1000Props();

  // Calcular os índices de início e fim com base na página e nas linhas por página
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = itemCompany.slice(startIndex, endIndex);

  return (
    <>
      <ModalContainer open={openSendModal} onClose={handleCloseSendModal}>
        <SendR1000
          closeModal={handleCloseSendModal}
          ItemCompanyId={selectedItemId}
          refreshData={getItemCompany}
          {...modalProps}
        />
      </ModalContainer>
      <ModalContainer open={openEditModal} onClose={handleCloseEditModal}>
        <ItemCompany
          closeModal={handleCloseEditModal}
          itemCompanyId={selectedItemId}
          refreshData={getItemCompany}
          item={itemData}
        />
      </ModalContainer>
      <Container maxWidth="100%" sx={{ marginBottom: 5 }}>
        <Stack direction="row" spacing={2} sx={{ paddingBlock: 2 }}>
          <Text style={styles.Title}>Evento R-1000</Text>
          <RButton sx={{ width: "120px", p: 1 }} onClick={() => handleOpenEditModal()}>
            Incluir
          </RButton>
        </Stack>
        <Box sx={{ borderRadius: 3, overflow: "hidden" }}>
          <Table>
            <TableHead>
              <TableRow sx={styles.HeadTableRow}>
                <TableCell sx={styles.TableCellHead} align="center">
                  Início da Validade
                </TableCell>
                <TableCell sx={styles.TableCellHead} align="center">
                  Fim da Validade
                </TableCell>
                <TableCell sx={styles.TableCellHead} align="center">
                  Estabelecimento
                </TableCell>
                <TableCell sx={styles.TableCellHead} align="center">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.length > 0 ? (
                currentData.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell sx={styles.TableCell}>
                      {new Date(item?.startDate)
                        .toLocaleDateString("pt-BR", {
                          month: "numeric",
                          year: "numeric",
                        })
                        .padStart(8)}
                    </TableCell>
                    <TableCell sx={styles.TableCell}>
                      {item?.endDate
                        ? new Date(item?.endDate)
                            .toLocaleDateString("pt-BR", {
                              month: "numeric",
                              year: "numeric",
                            })
                            .padStart(8)
                        : "-"}
                    </TableCell>
                    <TableCell sx={styles.TableCell}>{matrizCompany?.name}</TableCell>
                    <TableCell sx={styles.TableCellIcons}>
                      <Tooltip title="Editar Informações do contribuinte">
                        <EditNote sx={styles.icon} onClick={() => handleOpenEditModal(item)} />
                      </Tooltip>
                      <Tooltip title="Enviar R-1000">
                        <UploadFileOutlined
                          sx={styles.icon}
                          onClick={() => handleOpenSendModal(item.id, 1)}
                        />
                      </Tooltip>
                      <Tooltip title="Deletar informações">
                        <DeleteOutline
                          sx={styles.icon}
                          onClick={() => {
                            handleDeleteItem(item.id, item.isR1000);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan="100%"
                    sx={{
                      ...styles.TableCell,
                      height: "45px",
                    }}
                  >
                    {loading ? (
                      <CircularProgress size={25} s color="inherit" />
                    ) : (
                      <Typography sx={{ fontFamily: "Montserrat ", fontSize: 14 }}>
                        Nenhum evento foi encontrado.
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {itemCompany?.length > 4 && (
            <Stack
              spacing={1}
              sx={{ background: "#F2F2F2", paddingRight: 8, borderRadius: "0px 0px 8px 8px" }}
            >
              <TablePagination
                component="div"
                count={itemCompany?.length || 0}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                labelRowsPerPage="Informações por página:"
                rowsPerPageOptions={[5, 10, 15, 20]}
                sx={{
                  ".MuiTablePagination-selectLabel ": {
                    margin: 0,
                  },
                  ".MuiTablePagination-displayedRows": {
                    margin: 0,
                  },
                }}
              />
            </Stack>
          )}
        </Box>
      </Container>
    </>
  );
};

const styles = {
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  icon: {
    fontSize: "2rem",
    cursor: "pointer",
    ":hover": {
      color: "#1341a1",
    },
  },
  HeadTableRow: {},
  //LINHA TÍTULOS DAS COLUANS
  TableCellHead: {
    backgroundColor: "#1341A1",
    fontFamily: "Montserrat",
    textAlign: "center",
    color: "#ffffff",
    fontSize: 13,
    padding: "10px",
    fontWeight: 400,
    position: "relative",
    //DIVISOR COLUNAS HEAD
    "&:not(:first-child)::after": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "10%",
      bottom: "10%",
      left: 0,
      width: "3px",
      backgroundColor: "#142F68",
    },
  },
  //CADA LINHA (NOTA)
  TableCell: {
    fontFamily: "Montserrat ",
    fontSize: 14,
    padding: "6px",
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#D9D9D9",
  },
  TableCellIcons: {
    padding: "6px",
    backgroundColor: "#D9D9D9",
    display: "flex",
    gap: 1,
    justifyContent: "center",
  },
  addButton: {
    color: "#1341A1",
    fontSize: "2rem",
    cursor: "pointer",
    "&:hover": {
      color: "#000",
    },
  },
  Actions: {
    "&:hover": {
      color: "#0e4292",
    },
  },
  containerFields: {
    backgroundColor: "#EFF2F4",
    borderRadius: 2,
    padding: "20px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  titleModal: {
    fontSize: 18,
    fontWeight: 500,
    width: "60%",
    color: "#021148",
    fontFamily: "Montserrat, sans-serif",
    alignItems: "center",
  },
};
