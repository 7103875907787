import React, { useContext, useEffect } from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import AutocompleteSelect from "~/presentation/components/AutocompleteSelect";
import TextInput from "~/presentation/components/TextInput";
import Select from "~/presentation/components/Select";
//context
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// styles
import {
  Container,
  Form,
  Radio,
  RadioGroup,
} from "~/presentation/views/Admin/ISS/UpdateCities/styles";
import { useState } from "react";
// import TotalRetentionTable from "~/presentation/views/Admin/ISS/components/TotalRetentionTable";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";

const UpdateCitiesForm = ({ cityUseCases, companyUseCases }) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);

  const initialValues = {
    city: null,
    iss: 0,
    totalRetentions: null,
  };

  const getCity = async (city) => {
    return {
      city: city,
      iss: city.iss,
      totalRetentions: city.totalRetentions, // 18 categorias s, n
    };
  };

  const getCities = async () => {
    openBackdrop(true, "Carregando cidades");
    const cities = await cityUseCases.getAll();
    setCities(cities);
    openBackdrop(false);
  };

  const getCategories = async () => {
    const categories = await companyUseCases.getCompanyCategories();
    const mapperCategories = dataToSelectMapper(categories);
    setCategories(mapperCategories);
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Atualizando município");
    const data = convertToCity(values);
    await cityUseCases.updateCity(data);
    openBackdrop(false);
    setFeedbackSnackbar({
      isOpen: true,
      message: "Município atualizado com sucesso",
      type: "success",
    });
  };

  const convertToCity = (value) => {
    if (value === undefined || !value) {
      return null;
    }
    const city = {
      ...value.city,
      iss: value.iss === undefined ? 0 : value.iss,
      totalRetentions: value.totalRetentions === undefined ? [] : value.totalRetentions,
    };
    return city;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCities();
      getCategories();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <h1>Atualizar Municípios</h1>
      <Container className="scrollbar-white">
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, action) => {
            action.setSubmitting(true);
            openBackdrop(true, "Cadastrando diálogo");

            const submitValues = {
              ...values,
              totalRetentions: values.totalRetentions.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  letter: item.letter,
                };
              }),
            };
            await handleSubmit(submitValues);

            action.setSubmitting(false);
            openBackdrop(false);
            action.resetForm();
          }}
        >
          {({ values, setFieldValue, setValues }) => (
            <Form>
              <div className="form-grid">
                <h2>Município</h2>
                <div className="col-1_5">
                  <Field
                    name="city"
                    label="Selecionar Município"
                    component={AutocompleteSelect}
                    value={values.city}
                    options={cities}
                    onChange={async (event, newValue) => {
                      if (newValue) setValues(await getCity(newValue));
                      else setValues(initialValues);
                    }}
                    getOptionLabel={(option) => `${option.name} (${option.uf})`}
                  />
                  <ErrorMessage name="city" />
                </div>
                {values.city && (
                  <>
                    <div className="col-1_2">
                      <Field name="iss" type="number" label="Alíquota do ISS" as={TextInput} />
                      <ErrorMessage name="iss" />
                    </div>
                    <div className="col-2_5">
                      <Field
                        name="totalRetentions"
                        label="Retenção Total"
                        placeholder="Retenção Total"
                        component={Select}
                        isMulti={true}
                        options={categories}
                        onChange={(option) => setFieldValue("totalRetentions", option)}
                        defaultValue={dataToSelectMapper(values.totalRetentions)}
                      />
                    </div>
                  </>
                )}
              </div>
              {values.city && (
                <PrimaryButton className="submit-btn" type="submit">
                  Publicar
                </PrimaryButton>
              )}
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default UpdateCitiesForm;
