import React, { useState, useContext } from "react";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import { Container } from "~/presentation/views/Admin/GTFacil/CNAE/styles";
import DropUploadZone from "~/presentation/components/DropUploadZone";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
const CNAE = ({ gtFacilUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [file, setFile] = useState();

  const handleFile = (uploadedFile) => {
    setFile(uploadedFile);
  };

  const handleSubmit = async () => {
    openBackdrop(true, "Salvando arquivo de CNAE");
    try {
      await gtFacilUseCases.saveCNAE(file);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar arquivo de CNAE: Missing Endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <Container>
      <h1>Importar arquivo de CNAE</h1>
      <div className="dropUploadZone">
        <DropUploadZone handleChange={handleFile} />
      </div>
      <PrimaryButton className="submitBtn" onClick={handleSubmit} disabled={!file}>
        Enviar
      </PrimaryButton>
    </Container>
  );
};

export default CNAE;
