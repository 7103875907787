import React from "react";
import { Fade } from "react-reveal";
import { Container, Grid, Typography as Text } from "@mui/material";

export const HeaderSectionPage = ({ title, buttonPrimary, buttonSecond, iconLeft }) => {
  return (
    <Container maxWidth="100%" sx={{ backgroundColor: "#E6E6E6", p: 3 }}>
      <Fade>
        <Grid container spacing={2}>
          <Grid item xs={12} sm="auto" style={{ marginRight: !iconLeft && "auto" }}>
            <Text sx={styles.title}>{title}</Text>
          </Grid>
          {buttonPrimary && (
            <Grid item xs={12} sm="auto">
              {buttonPrimary}
            </Grid>
          )}
          {buttonSecond && (
            <Grid item xs={12} sm="auto" style={{ marginRight: !iconLeft && "10px" }}>
              {buttonSecond}
            </Grid>
          )}
        </Grid>
      </Fade>
    </Container>
  );
};

const styles = {
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#242424",
  },
};
