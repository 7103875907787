import fakeCitiesList from "./fake-cities-list.json";
import { apiURL } from "../../usecases/api";

export class ApiCityUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getAll(isNotPD = true) {
    let response = {
      data: fakeCitiesList,
    };
    try {
      response = await this.httpClient.request({
        url: `${apiURL}/city?isNotPD=${isNotPD}`,
        method: "GET",
      });
    } catch (error) {
      console.log(error);
    }
    return response.data;
  }

  async getCityById(id) {
    // let response = {data: fakeCitiesList.find((c) => c.id == id)};
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/city/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      console.log("Missing endpoint: ", err);
    }
  }

  async updateCity(data) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/city`,
        method: "PUT",
        data,
      });
      return response;
    } catch (err) {
      console.log("Missing endpoint: ", err);
    }
  }
}
