import React, { useState, useContext, useEffect } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { ApiReinfUseCases } from "~/data/usecases/reinf/apiReinf";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { EventDetailsModal } from "./EventDetailsModal";
import { Box, Modal, Grid, Typography as Text } from "@mui/material";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import { Close } from "@mui/icons-material";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";

export const SendR2098 = ({
  nfseDate,
  DigitalCertificate,
  UserCompany,
  CompanyUserId,
  EventSend,
  competence,
  selectedYear,
  selectedMonth,
  closeModal,
  refreshData,
  handleVerifyEventStatus,
}) => {
  const context = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const apiSendReinf = new ApiReinfUseCases(makeHttpClient());
  const [modalOpen, setModalOpen] = useState(false);
  const [responseDetails, setResponseDetails] = useState(null);
  const [eventData, setEventData] = useState({
    companyId: CompanyUserId,
    digitalCertId: DigitalCertificate?.id,
    isProduction: false, // por padrão inicia em
    calculationDate: competence,
  });

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    const value = selectedOption ? selectedOption.value : "";
    setEventData((prev) => ({ ...prev, [name]: value }));
  };

  const isProductionOptions = [
    { value: false, label: "Produção Restrita" },
    { value: true, label: "Produção" },
  ];

  useEffect(() => {
    if (responseDetails) {
      setModalOpen(true);
    }
  }, [responseDetails]);

  const handleSubmit = async () => {
    const updatedEventData = {
      ...eventData,
    };

    openBackdrop(true, "Enviando dados do Evento R-2098...");
    try {
      const response = await apiSendReinf.sendEvent(updatedEventData, "r2098");
      setResponseDetails(response);

      setFeedbackSnackbar({
        isOpen: true,
        message: "Evento registrado com sucesso",
        type: "success",
      });
    } catch (error) {
      console.error("Erro ao enviar o evento R-2098: ", error);
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro ao enviar: ${error.data}`,
        type: "error",
      });
    } finally {
      handleVerifyEventStatus(competence, "r2098");
      openBackdrop(false);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={11}>
          <Text style={styles.Title}>DADOS DO EVENTO R-2098</Text>
        </Grid>
        <Grid
          item
          xs={6}
          sm={1}
          sx={{ display: "flex", justifyContent: "flex-end", cursor: "pointer" }}
        >
          <Close onClick={closeModal} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Text>{EventSend}</Text>
          <Input
            type="text"
            title="Estabelecimento"
            name="companyName"
            value={UserCompany?.name || ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            type="text"
            title="Certificado Digital"
            name="digitalCertId"
            value={DigitalCertificate?.name}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            type="select"
            title="Tipo de ambiente"
            name="isProduction"
            options={isProductionOptions}
            onChange={(option) => handleSelectChange(option, { name: "isProduction" })}
            value={isProductionOptions.find((option) => option.value === eventData.isProduction)}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", gap: 1, marginTop: 4 }}>
        <RButton sx={{ width: "150px" }} type="button" variant="outlined" onClick={closeModal}>
          Cancelar
        </RButton>
        <RButton sx={{ width: "150px" }} variant="contained" color="primary" onClick={handleSubmit}>
          Reabrir evento
        </RButton>
      </Box>
      {responseDetails && (
        <ModalContainer scroll={true} open={modalOpen} onClose={closeModal}>
          <EventDetailsModal
            open={modalOpen}
            onClose={() => {
              setModalOpen(false);
              closeModal();
            }}
            eventDetails={responseDetails}
          />
        </ModalContainer>
      )}
    </>
  );
};
const styles = {
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    userSelect: "none",
  },
  subTitle: {
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  Modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "700px",
    width: "100%",
    maxHeight: "80%",
    borderRadius: "18px",
    bgcolor: "#E8E8E8",
    boxShadow: 24,
    overflow: "auto",
  },
};
