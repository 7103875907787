import styled from "styled-components";
import {ModalContentWrapper} from "~/presentation/views/LandingPage/Home/Modals/components/Wrappers";

export const ContentWrapper = styled(ModalContentWrapper)`
  display: grid;
  grid-template-rows: min-content auto;
  column-gap: 12vw;
  row-gap: 10vw;

  > img {
    justify-self: center;
    min-width: 130px;
    width: 12.1vw;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: min-content auto;
    grid-template-rows: auto;

    > img {
      justify-self: flex-start;
    }
  }
`;

export const TextWrapper = styled.div`
  display: grid;

  > h1 {
    margin: 3% 0 8.7% 0;
    font-family: "Playfair Display", serif;
    letter-spacing: 0.06em;
    font-weight: normal;
    font-size: 32px;
    line-height: 43px;

    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  > p {
    margin-bottom: 2.4%;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 1rem;
    line-height: 130%;
    text-align: justify;
    letter-spacing: 0.02em;

    &:last-of-type {
      margin-bottom: 10%;
    }
  }
`;
