import React, { useState, useContext, useEffect, useRef } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import { formatCpfCnpj } from "~/presentation/views/Reinf/Utils/utilsReinf";
import { Container, Grid, Box, Typography as Text, Stack } from "@mui/material";
import removeMask from "~/presentation/functions/removeMask";
import handleMoney from "~/presentation/views/Reinf/components/HandleMoney/handleMoney";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import { AddPartner } from "../AddPartner/AddPartner";
import ModalContainer from "~/presentation/views/Reinf/components/ModalContainer/ModalContainer";

export const CompanyForm = ({ partners, handleReturn, refreshData }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const apiNfse = new ApiNfseUseCases(makeHttpClient());
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [open, setOpen] = useState(false);
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    refreshData();
  };

  const [nFseNumberError, setNfseNumberError] = useState(null);

  const filterOptions = (options, { inputValue }) => {
    const normalizedInput = removeMask(inputValue).toLowerCase();
    return options.filter(
      (option) =>
        removeMask(option.cnpj).includes(normalizedInput) ||
        removeMask(option.legalName).toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const [nfseData, setNfseData] = useState({
    businessPartnerId: "",
    nFseNumber: "",
    dateOfIssue: new Date(),
    amountBC: "",
    typeDoc: QSO === "D" ? 0 : null,
  });

  const isTypeDocEmpy = QSO !== "D" && nfseData.typeDoc === null;

  const isSomeFieldEmpty =
    !nfseData?.businessPartnerId || !nfseData?.dateOfIssue || !nfseData?.amountBC || isTypeDocEmpy;

  const handleChange = (e) => {
    const newValue = e.target.value;
    const maxDigits = newValue.length <= 15;
    if (maxDigits) {
      setNfseData({ ...nfseData, nFseNumber: newValue });
      setNfseNumberError(null);
    } else {
      setNfseNumberError("Deve ter no máximo 15 dígitos.");
    }
  };

  const handleDateChange = (name, date) => {
    setNfseData((prev) => ({
      ...prev,
      [name]: date ? new Date(date) : "",
    }));
  };

  useEffect(() => {
    setNfseData((prev) => ({
      ...prev,
      businessPartnerId: selectedPartner?.id,
    }));
  }, [selectedPartner]);

  const handleSubmit = async () => {
    openBackdrop(true, "Cadastrando Documento Fiscal...");
    try {
      const response = await apiNfse.createNfse(nfseData);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Doc. cadastrado com sucesso",
        type: "success",
      });
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar.",
        type: "error",
      });
    } finally {
      openBackdrop(false);
      handleReturn();
    }
  };

  const messageErrorText = "Campo Obrigatório";

  const documentOptions = [
    {
      value: 1,
      title: "Nota Fiscal de Produto Eletrônica (NF-e)",
    },
    {
      value: 0,
      title: "Nota Fiscal de Serviço Eletrônica (NFs-e)",
    },
  ];

  return (
    <>
      <Container maxWidth="xl" sx={{ paddingBlock: 3 }}>
        <Box sx={{ backgroundColor: "#E6E6E6", borderRadius: 2 }}>
          <Grid container spacing={2} sx={{ paddingInline: 4, paddingBlock: 2 }}>
            <Grid item xs={4} sm={11}>
              <Stack direction="row" spacing={2}>
                <Text style={styles.Title}>Informações do Doc. Fiscal</Text>
                <RButton sx={{ height: "50px", width: "210px" }} onClick={handleOpen}>
                  Adicionar Contratado
                </RButton>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={partners}
                getOptionLabel={(option) => `${formatCpfCnpj(option.cnpj)} - ${option.legalName}`}
                filterOptions={filterOptions}
                value={selectedPartner}
                noOptionsText={"Ainda não existe um parceiro cadastrado"}
                onChange={(event, newValue) => {
                  setSelectedPartner(newValue);
                }}
                renderInput={(params) => (
                  <Input
                    sx={{ alignItems: "center" }}
                    style={styles.inputs}
                    title="Selecionar Contratado Cadastrado"
                    type="text"
                    startIcon={<SearchIcon />}
                    placeholder="Pesquisar por CNPJ / Razão Social"
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                style={{ ...styles.inputs, borderOnHover: "1px solid #E6E6E6" }}
                disabled={QSO === "D"}
                type="select"
                title="Tipo de Documento Fiscal"
                options={documentOptions}
                getOptionLabel={(option) => option.title}
                placeholder="Selecione o tipo de documento"
                value={documentOptions.find((item) => item.value === nfseData?.typeDoc)}
                onChange={(option) => {
                  setNfseData({ ...nfseData, typeDoc: option.value });
                }}
                error={nfseData?.typeDoc === null}
                errorText={nfseData?.typeDoc === null && messageErrorText}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Input
                style={styles.inputs}
                title="Nº do Doc. Fiscal"
                type="number"
                name="nFseNumber"
                value={nfseData.nFseNumber}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleChange(e);
                  }
                }}
                error={nFseNumberError}
                errorText={nFseNumberError}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Input
                styles={{ ...styles.inputs, height: "54px" }}
                title="Data de Emissão"
                type="date"
                value={nfseData.dateOfIssue}
                onChange={(date) => handleDateChange("dateOfIssue", date)}
                error={!nfseData?.dateOfIssue}
                errorText={!nfseData?.dateOfIssue && messageErrorText}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Input
                border="1px solid #E6E6E6"
                backgroundColor="#fff"
                title="Valor Bruto"
                type="amount"
                name="amountBC"
                value={nfseData.amountBC}
                onChange={(e) => {
                  setNfseData({ ...nfseData, amountBC: handleMoney(e.target.value) });
                }}
                error={!nfseData?.amountBC}
                errorText={!nfseData?.amountBC && messageErrorText}
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "end", gap: 1, p: 4 }}>
            <RButton sx={{ width: "150px" }} variant="outlined" onClick={handleReturn}>
              Sair
            </RButton>
            <RButton sx={{ width: "150px" }} onClick={handleSubmit} disabled={isSomeFieldEmpty}>
              Salvar
            </RButton>
          </Box>
        </Box>
      </Container>
      <ModalContainer scroll={true} open={open} onClose={handleClose}>
        <AddPartner onClose={handleClose} />
      </ModalContainer>
    </>
  );
};

const styles = {
  content: {
    paddingBlock: 5,
  },
  inputs: {
    border: "1px solid #E6E6E6",
    borderRadius: "3px",
    background: "#fff",
  },
  Title: {
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontWeight: 700,
    color: "#404040",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  buttonsContainer: {
    justifyContent: "right",
    display: "flex",
    gap: 1,
    mt: 6,
  },
};
