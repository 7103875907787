import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  border-radius: 8px;
  // border: 4px solid #00B8E0;
  // background: rgba(50, 18, 177, 0.40);
  width: 100%;


  padding: max(4%, 40px) 4%;
  display: grid;
  max-width: 25rem;
  min-width: 20rem;

  }
`;

export const Line = styled.div`
  background: #1341a1;
  height: 70%;
  align-self: center;

  @media screen and (min-width: 600px) {
    margin-top: 1.2vw;
  }

  @media screen and (min-width: 600px) and (max-width: 960px) {
    &:first-of-type {
      display: none;
    }
  }
`;

export const ListOfPosts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 39px;
  border: 4px solid #fd8c08;

  > span {
    color: #0d1e3a;

    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 1rem;
  }
`;
