import { formatDateToBrazilian } from "../Utils/utilsDate";

export function useTableFilters() {
  const docPeriodFilter = (data, order, start, end) => {
    const result = data.filter(
      (item) => item[order] > start.toJSON() && item[order] < end.toJSON()
    );
    return result;
  };

  const docSituationFilter = (data, value) => {
    let result = [...data];
    //nenhum evento
    if (value === "never") {
      result = result.filter((item) => !item["isR2010"] && !item["isR4020"]);
    }
    //R2010 não tem notas de produto NF-e
    if (value === "isR2010") {
      result = data.filter((item) => item[value]);
    }
    if (value === "isR4020") {
      result = data.filter((item) => item[value]);
    }
    return result;
  };

  const docSetOrder = (data, field) => {
    const result = data?.sort((a, b) => {
      const dateA = new Date(a[field]);
      const dateB = new Date(b[field]);
      return dateB - dateA; // Para ordem decrescente
    });
    return result;
  };

  const docTypeFilter = (data, field, value) => {
    return data.filter((item) => item[field] === value);
  };

  //ncessário no handleSearch
  const normalizeText = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9\s]/g, "")
      .toUpperCase();
  };

  //pega em qualquer data e procura lá o value, o setData é a info mostrada ao usuário
  const handleSearchTable = (value, data, setData) => {
    //normaliza o value enviado no input pelo usuário
    const normalizedValue = normalizeText(value);

    const filtered = data.filter((item) => {
      for (let key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          let fieldValue = item[key];

          //se é uma data queremos que ela fique "yyyy-mm-dd"
          if (key.includes("Date")) {
            fieldValue = formatDateToBrazilian(fieldValue);
          }

          // Normalizar o valor de cada campo do data
          const normalizedFieldValue = normalizeText(String(fieldValue));

          // Verificar se o valor de pesquisa está presente no campo normalizado
          if (normalizedFieldValue.includes(normalizedValue)) {
            return true;
          }
        }
      }
      return false;
    });

    //adiciona no setData as notas onde campos foram true
    setData(filtered);
  };

  return {
    docPeriodFilter,
    docSituationFilter,
    docSetOrder,
    docTypeFilter,
    handleSearchTable,
  };
}
