import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { makeCompanyUseCases } from "~/main/factories/use-cases/company";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { addYears } from "date-fns";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import CompanyForm from "./Form";
import { Box } from "@mui/material";

function RegisterEdit({
  data,
  onCloseForm,
  refresData,
  onGetFornOptions,
  plans,
  categories,
  states,
  legalNatureOptions,
  citiesCache,
}) {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const companyUseCases = makeCompanyUseCases();
  const location = useLocation();
  const isCreate = data === null;

  useEffect(() => {
    onGetFornOptions();
  }, []);

  const getDefaultValues = (obj) => {
    //tratar das totalSubstituteOrDismissed que vem do back para não dar erro
    if (obj) {
      const ids = obj.map((item) => item.cityId);
      const result = citiesCache.filter((item) => ids.includes(item.id));
      return result;
    }
  };

  const setCorrectValues = () => {
    if (isCreate) {
      if (location.state) {
        return {
          id: 0,
          address: "",
          complement: "",
          name: location.state.companyName,
          cnpj: location.state.cnpj,
          cep: "",
          categoryId: location.state.categoryId,
          mobilePhone: location.state.mobilePhone,
          plan: location.state.plan,
          state: "",
          city: "",
          phone: "",
          email: location.state.email,
          dateStart: new Date(),
          dateEnd: addYears(new Date(), 1),
          dateCompanyRegistered: new Date(),
          active: true,
          totalSubstituteOrDismissed: [],
        };
      } else {
        return {
          id: 0,
          address: "",
          complement: "",
          name: "",
          cnpj: "",
          cep: "",
          categoryId: "", // id da categoria
          mobilePhone: "",
          plan: "", // id do plano
          state: "",
          city: "",
          phone: "",
          email: "",
          dateStart: new Date(), // (automático para dia atual)
          dateEnd: addYears(new Date(), 1), // (automático para +1 ano)
          dateCompanyRegistered: new Date(),
          active: true, // (ativa, inativa),
          totalSubstituteOrDismissed: [],
        };
      }
    } else {
      if (data) {
        return {
          id: data.id,
          address: data.address,
          complement: data.complement,
          name: data.name,
          cnpj: data.cnpj,
          cep: data.zipCode,
          categoryId: data.category.id,
          mobilePhone: data.mobilePhone,
          plan: data.plan.id, // id do plano
          state: data.state.id,
          city: data.city.id,
          phone: data.homePhone,
          email: data.email,
          dateStart: new Date(data.dateStart),
          dateEnd: new Date(data.dateEnd),
          legalNatureId: data?.legalNature?.id,
          totalSubstituteOrDismissed: getDefaultValues(data.totalSubstituteOrDismissed),
          active: data.active, // (ativa, inativa)
        };
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      openBackdrop(true, `${isCreate ? "Cadastrando" : "Editando"} empresa`);
      if (isCreate) {
        await companyUseCases.register(values);
      } else {
        await companyUseCases.update(values);
      }
      setFeedbackSnackbar({
        isOpen: true,
        message: `Empresa ${isCreate ? "cadastrada" : "editada"} com sucesso!`,
        type: "success",
      });
      onCloseForm();
      await refresData();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro. " + error?.response?.data,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <>
      <Box sx={{ marginBlock: 2 }}>
        <HeaderForm title={`${isCreate ? "Cadastrar" : "Editar"} Empresa`} backFnc={onCloseForm} />
      </Box>
      <CompanyForm
        id={data?.id || null}
        initialValues={setCorrectValues()}
        handleSubmit={handleSubmit}
        plans={plans}
        categories={categories}
        states={states}
        legalNatureOptions={legalNatureOptions}
        citiesCache={citiesCache}
      />
    </>
  );
}

export default RegisterEdit;
