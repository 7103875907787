import React, { useState, useEffect, useContext, useRef, createContext } from "react";
import { ApiNfseUseCases } from "~/data/usecases/nfse/apiNfse";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import {
  formatDateToBrazilian,
  formatDateFilter,
} from "~/presentation/views/Reinf/Utils/utilsDate";
import { useTableFilters } from "../../Hooks/useTableFilters";
import Filters from "./Filters/Filters.jsx";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import DocTable from "./DocTable";

export const TableNfse = ({ importRefresh }) => {
  const apiNfse = new ApiNfseUseCases(makeHttpClient());
  const { docSituationFilter, docTypeFilter, handleSearchTable } = useTableFilters(); // HOOK FILTROS
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //DATA
  const [nfseData, setNfseData] = useState([]);
  const [currentNfseData, setCurrentNfseData] = useState([]);
  const [error, setError] = useState(null);

  //ERROS CAMPOS
  const [errors, setErrors] = useState({});

  //FILTRO DE PESQUISA
  const [searchField, setSearchField] = useState("");

  // FILTROS - PADRÃO NOTAS ÚLTIMOS 6 MESES + data de emissão
  const today = new Date();
  const sixMonths = new Date(new Date().setMonth(new Date().getMonth() - 6));
  const [orderBy, setOrderBy] = useState("dateOfIssue");
  const [initialDate, setInitialDate] = useState(sixMonths);
  const [finalDate, setFinalDate] = useState(today);
  const [documentSituation, setDocumentSituation] = useState("");
  const [documentType, setDocumentType] = useState("");

  //ERRO: initialDate não pode ser maior do que final e vice-versa.
  const isDateInTheFuture = initialDate > new Date() || finalDate > new Date();
  const isDateWrong = initialDate > finalDate;

  // ⇣⇣⇣ useEffect para depois de importar xml dar refresh à data
  const firstRender = useRef(false);
  useEffect(() => {
    if (firstRender.current) {
      fetchDataIntoState(paramsDate);
    }
    firstRender.current = true;
  }, [importRefresh]);

  // ⇣⇣⇣ Cons para ver se o período de datas se modifica
  const [hasDateChanged, setHasDateChanged] = useState(1);
  const hasDateChangedRef = useRef(0);
  // ⇣⇣⇣ caso a ordem, ou alguma data mude aumenta um no hasDataChangedRef
  // ⇣⇣⇣ e no handleFilters ele compara o ref com o state, caso seja igual não dá fecth à API
  // ⇣⇣⇣ caso seja diferente, quer dizer que as datas alterarão e aí sim dá novo fetch à API
  useEffect(() => {
    hasDateChangedRef.current += 1;
  }, [initialDate, finalDate, orderBy]);

  // ⇣⇣⇣ PARAMS para o fetch
  const params = {
    start: formatDateFilter(initialDate),
    end: formatDateFilter(finalDate),
    isDateOfPayment: orderBy === "dateOfPayment" ? true : false,
  };
  // ⇣⇣⇣ caso não haja datas quero que vá null para não dar erro
  const paramsDate = !params.start || !params.end ? null : params;

  const loadData = async (data) => {
    try {
      openBackdrop(true, "Carregando dados de Doc.Fiscais");
      const response = await apiNfse.getNfse(data);
      if (response.status === 200) {
        const apiNfseData = response.data;
        return apiNfseData;
      } else {
        setError("Erro na resposta da API: " + response.status);
      }
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos dados, verifique sua conexão",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const fetchDataIntoState = async (data) => {
    const result = await loadData(data);
    setNfseData(result);
    setCurrentNfseData(result);
  };

  useEffect(() => {
    if (paramsDate) {
      fetchDataIntoState(paramsDate); //1º fecth da data
    }
  }, []);

  const handleFilters = async () => {
    // ⇣⇣⇣ caso data seja errada não permite o fetch
    if (isDateWrong || isDateInTheFuture) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Período de datas incorreto!",
        type: "error",
      });
      return;
    }

    // para filtrar por pagamento é preciso ter selecionado um período de datas
    if ((!initialDate || !finalDate) && orderBy === "dateOfPayment") {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Escolha um período de datas para filtrar!",
        type: "warning",
      });
      return;
    }

    let result = nfseData;

    // ⇣⇣⇣ Caso não seja alterado o periodo de datas o fetch não é chamado e só é filtrada a data da tabela
    if (hasDateChangedRef.current !== hasDateChanged) {
      setHasDateChanged(hasDateChangedRef.current);
      result = await loadData(paramsDate);
      setNfseData(result);
    }

    if (documentSituation) {
      result = docSituationFilter(result, documentSituation);
    }

    //não pode só ver se é true porque 0 é valor válido
    if ([0, 1, 2, 3, 4, 99].includes(documentType)) {
      result = docTypeFilter(result, "typeDoc", documentType);
    }

    setCurrentNfseData(result);
  };

  const handleDeleteNfse = (nfseId) => {
    openDialogBox({
      message: `Deseja eliminar permanentemente esta Nota?`,
      callback: () => {
        apiNfse
          .deleteNfse(nfseId)
          .then(() => {
            // Remover a NFSe excluída da tabela
            setCurrentNfseData((prevNfseData) => prevNfseData.filter((nfse) => nfse.id !== nfseId));
          })
          .catch((error) => {
            setFeedbackSnackbar({
              isOpen: true,
              message: `Erro ao excluir NFSe: ${error}`,
              type: "error",
            });
          });
      },
    });
  };

  const handleChangeFieldValue = (props) => {
    const { newItem, index, field, byPass } = props;
    //campo hadChanges serve para mostrar ao usuário que a nota teve alterações
    //byPass é para useEffect (mudanças automaticas) não ativar o hadChanges
    let data = {};
    if (byPass) {
      data = { ...newItem };
    } else {
      data = { ...newItem, hadChanges: true };
    }
    setCurrentNfseData((prev) => {
      let updatedItems = [...prev];
      updatedItems[index] = {
        ...currentNfseData[index],
        ...data,
      };
      return updatedItems;
    });
  };

  const handleChangeINSSfield = (props) => {
    const { newItem, parentIndex, nestedIndex, field, byPass } = props;
    //campo hadChanges serve para mostrar ao usuário que a nota teve alterações
    //byPass é para useEffect (mudanças automaticas) não ativar o hadChanges
    let updatedNfse = {};
    if (byPass) {
      updatedNfse = { ...currentNfseData[parentIndex] };
    } else {
      updatedNfse = { ...currentNfseData[parentIndex], hadChanges: true };
    }
    const updatedItemINSS = [...updatedNfse.itemsNFse];
    updatedItemINSS.splice(nestedIndex, 1, newItem);
    updatedNfse.itemsNFse = updatedItemINSS;
    setCurrentNfseData((prev) => {
      let updatedItems = [...prev];
      updatedItems[parentIndex] = updatedNfse;
      return updatedItems;
    });
  };

  const handleSearch = (value) => {
    setSearchField(value);
    handleSearchTable(value, nfseData, setCurrentNfseData);
  };

  return (
    <>
      {error && (
        <div>
          <p>{error}</p>
        </div>
      )}

      <Filters
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        documentSituation={documentSituation}
        setDocumentSituation={setDocumentSituation}
        initialDate={initialDate}
        setInitialDate={setInitialDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
        formatDateToBrazilian={formatDateToBrazilian}
        searchField={searchField}
        handleSearch={handleSearch}
        handleFilters={handleFilters}
        setCurrentNfseData={setCurrentNfseData}
        nfseData={nfseData}
        fetchDataIntoState={fetchDataIntoState}
        documentType={documentType}
        setDocumentType={setDocumentType}
      />

      <DocTable
        data={currentNfseData}
        onDelete={handleDeleteNfse}
        handleChangeFieldValue={handleChangeFieldValue}
        handleChangeINSSfield={handleChangeINSSfield}
        errors={errors}
        setErrors={setErrors}
      />
    </>
  );
};
