import React from "react";
import {
  Container,
  ContentWrapper,
  OuterContainer,
} from "~/presentation/views/LandingPage/Home/Sections/TaxInfo/styles";
import {Fade} from "react-reveal";

const TaxInfo = ({id}) => {
  return (
    <OuterContainer id={id}>
      <Container id={id}>
        <div className="bgImg" />
        <div className="linearGradient" />
        <Fade left distance="10%">
          <ContentWrapper>
            <div className="line" />
            <div className="title">
              <h2>Informação Fiscal (IF)</h2>
            </div>
            <div className="content">
              <p>
                Para lhe proporcionar sempre as melhores respostas,
                disponibilizamos um serviço chamado Informação Fiscal (ou
                simplesmente IF).
                <br></br>
                <br></br>
                Envie questionamentos e receba uma consultoria personalizada
                acerca da incidência de tributos: INSS, IRRF, CSLL, PIS/Pasep,
                COFINS e ISS sobre os pagamentos a seus contratados, sejam eles
                pessoas físicas ou jurídicas.
              </p>
            </div>
          </ContentWrapper>
        </Fade>
      </Container>
    </OuterContainer>
  );
};

export default TaxInfo;
