import React from "react";
import {
  Container,
  TitleWrapper,
  Card,
  CardTitle,
  PriceWrapper,
  ContentList,
  CardsWrapper,
  ButtonWrapper,
  YearPlanPrice,
  PersonalPlan,
  TagPlan,
} from "~/presentation/views/LandingPage/Home/Sections/Plans/styles";
import { Fade } from "react-reveal";

const plans = [
  {
    id: 1,
    name: "Diamante",
    isConsultISS: true,
    isGenerateReport: true,
    consultLimit: 99999999999999,
    taxInformationNumber: 10,
    numberOfUsers: 12,
    isCalculationSimulator: true,
    isSpecificSubstitutionAnalysis: true,
    isAllowTaxInformation: false,
    isCustom: true,
    valor: 999.0,
  },
  {
    id: 2,
    name: "Ouro",
    isConsultISS: false,
    isGenerateReport: true,
    consultLimit: 500,
    taxInformationNumber: 15,
    numberOfUsers: 6,
    isCalculationSimulator: true,
    isSpecificSubstitutionAnalysis: false,
    isAllowTaxInformation: true,
    isCustom: true,
    customCard: true,
    valor: 699.0,
  },
  {
    id: 2,
    name: "Prata",
    isConsultISS: false,
    isGenerateReport: false,
    consultLimit: 100,
    taxInformationNumber: 12,
    numberOfUsers: 5,
    isCalculationSimulator: false,
    isSpecificSubstitutionAnalysis: false,
    isAllowTaxInformation: true,
    isCustom: true,
    customCard: true,
    valor: 499.0,
  },
];

function mapPlanFeatures(plan) {
  let featuresList = [];

  if (plan.consultLimit > 500) {
    featuresList.push("Consultas ilimitadas");
  } else {
    featuresList.push(`${plan.consultLimit} consultas/mês`);
  }

  if (plan.isGenerateReport) {
    featuresList.push("Geração de relatório PDF das consultas");
  }

  if (plan.isCalculationSimulator) {
    featuresList.push("Simulador de cálculo");
  }

  if (plan.isConsultISS) {
    featuresList.push("Consulta de legislação por município");
  }

  return featuresList;
}

const handleOnClickContactButton = () => {
  window.open(
    "https://api.whatsapp.com/send?phone=5571992084907&text=Ol%C3%A1!%20Acessei%20o%20site%20Gest%C3%A3o%20Tribut%C3%A1ria%20e%20quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20planos%20junto%20a%20um%20representante.",
    "_blank",
    "noopener,noreferrer"
  );
};

const Plans = ({ id }) => {
  return (
    <Container id={id}>
      <Fade>
        <TitleWrapper>
          <h2>
            Prepare-se para viver uma <span>nova</span> e <span>revolucionária</span> forma de lidar
            com as principais Retenções Tributárias incidentes em seus contratos.
          </h2>
        </TitleWrapper>
      </Fade>

      <CardsWrapper>
        {plans.map((plan, index) => (
          <Fade
            distance="20%"
            left={index === 0}
            bottom={index === 1}
            right={index === 2}
            key={"landing-page-plans-card-" + index}
          >
            <div className={`reactRevealFadeCardWrapper ${plan.main && "mainCardWrapper"}`}>
              <Card className={plan.customCard ? "customCard" : ""}>
                {!plan.customCard && <TagPlan>APROVEITE</TagPlan>}
                <CardTitle>
                  <div className="preTitle">GT</div>
                  <div className="text">{plan.name}</div>
                </CardTitle>
                <div
                  style={{
                    marginTop: 15,
                    backgroundColor: !plan.customCard ? "#fff" : "#173468",
                    height: 1,
                    width: "100%",
                  }}
                />
                <PriceWrapper>
                  <div className="price">
                    <span className="currency">R$</span>
                    <span className="value">
                      {plan.valor.toLocaleString("pt-br", { minimumFractionDigits: 2 })}
                    </span>
                    <span className="period">/mês</span>
                  </div>
                </PriceWrapper>
                <ButtonWrapper onClick={handleOnClickContactButton}>
                  <div className="button">Contrate</div>
                </ButtonWrapper>
                <YearPlanPrice>
                  <p>
                    *No plano anual (Total = R${" "}
                    {(plan.valor * 12).toLocaleString("pt-br", { minimumFractionDigits: 2 })})
                  </p>
                </YearPlanPrice>
                <ContentList>
                  <div className="mainFeaturesWrapper">
                    <div className="mainFeatures">CADASTRO DE {plan.numberOfUsers} USUÁRIOS</div>
                    <div className="mainFeatures">ACESSO AOS ARTIGOS PUBLICADOS</div>
                    <div className="mainFeatures">ACESSO À LEGISLAÇÃO SELECIONADA</div>
                    <div className="mainFeatures">ACESSO A VÍDEOS</div>
                    <div className="mainFeatures">ACESSO AO GT-FÁCIL</div>
                  </div>
                  <div
                    style={{
                      marginTop: 15,
                      backgroundColor: !plan.customCard ? "#fff" : "#173468",
                      height: 1,
                      width: "100%",
                      marginBlock: 10,
                    }}
                  />
                  <div>
                    <div className="gtFacilContainer">
                      <img
                        src={
                          !plan.customCard
                            ? "/assets/icons/calendar-white.png"
                            : "/assets/icons/calendar-blue.png"
                        }
                        alt="calendar"
                        style={{ width: 53, height: 53, objectFit: "contain", marginRight: 10 }}
                      />
                      <p className="gtFacilTitle">RECURSOS DO GT-FÁCIL DISPONÍVEIS:</p>
                    </div>
                    <div className="gtFacilList">
                      {mapPlanFeatures(plan).map((feature, index) => (
                        <div
                          className="gtFeature"
                          key={`feature${index}`}
                          style={{ color: plan.customCard && "#173468" }}
                        >
                          <img
                            src={
                              !plan.customCard
                                ? "/assets/icons/check.png"
                                : "/assets/icons/blue-check.png"
                            }
                            alt="check"
                            style={{ width: 20, height: 20, objectFit: "contain", marginRight: 10 }}
                          />
                          {feature}
                        </div>
                      ))}
                    </div>
                  </div>
                </ContentList>
              </Card>
            </div>
          </Fade>
        ))}
      </CardsWrapper>
      <PersonalPlan>
        <div className="ContentSide">
          <h2>Dá um upgrade no seu plano!</h2>
          <p>
            Nós temos planos personalizados para lhe atender de forma ainda mais completa. Confira
            os recursos avançados disponíveis:
          </p>
          <p>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Consultas ao <b>GT-Fácil</b> Ilimitadas
          </p>
          <p>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Envio facilitado da <b>EFD-Reinf</b>
          </p>
          <p>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Pacote de Informação Fiscal Ilimitados
          </p>
          <p>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Suporte direto via WhatsApp
          </p>
          <p>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Planos a partir de R$ 3.000,00/Mês
          </p>
          <p>
            <img src="/assets/icons/blue-check.png" alt="check" />
            Plano GT Fácil + GT Reinf + IFs (Informações fiscais) a partir de R$6.000,00
          </p>
          <ButtonWrapper onClick={handleOnClickContactButton}>
            <div className="button">Contrate</div>
          </ButtonWrapper>
        </div>
        <div className="PlanSide"></div>
      </PersonalPlan>
    </Container>
  );
};

export default Plans;
