import styled from "styled-components";
import { transparentize } from "polished";
import { Form as FormikForm } from "formik";

export const Container = styled.div`
  position: relative;
  background: linear-gradient(180deg, #102342 0%, #2455b2 100%);
`;

export const Wrapper = styled.div`
  top: 0; /* Alinhar o topo do Wrapper ao topo do Container */
  left: 50%;
  transform: translateX(-50%);
  height: 800px;
  background-image: url("/assets/bg/paper.png");
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  overflow: hidden;

  @media screen and (min-width: 600px) {
    height: 9000px;
  }

  @media screen and (min-width: 768px) {
    height: 880px;
    top: -30px;
  }

  @media screen and (min-width: 960px) {
    height: 600px;
  }

  max-width: 1230px;
  widht: 100%;
  font-family: "Montserrat";

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: ${(props) => transparentize(0.75, "#fff")};
  }

  > img {
    position: absolute;
    bottom: -80px;
    left: -25px;
    hight: 300px;
    width: 300px;
    opacity: 0.5;
  }
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 5% 5% 6% 4%;
  display: grid;
  gap: 32px;
  grid-template-columns: auto;
  grid-auto-rows: min-content auto;

  @media screen and (min-width: 450px) {
    padding: 40px 45px 50px 58px;
  }

  @media screen and (min-width: 960px) {
    grid-template-columns: calc(40% - 16px) calc(60% - 16px); /** subtract gap spacing */
    grid-auto-rows: auto;
  }

  > div:nth-child(1) {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: min-content min-content min-content min-content;
    gap: 19px;

    > p {
      font-weight: 300;
      font-size: 16px;
      line-height: 18px;
      text-align: justify;
      letter-spacing: 0.06em;
      color: #333;
    }
  }
`;

export const TitleWrapper = styled.div`
  > small {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
  }

  > h2 {
    font-family: "Montserrat";
    font-weight: normal;
    font-size: 40px;
    line-height: 43px;
  }

  .line {
    margin-top: 14px;
    width: 53px;
    height: 2px;
    background: ${(props) => "#cf9455"};
  }
`;

export const IconAndTextWrapper = styled.div`
  display: flex;
  gap: 12px;

  > span {
    font-weight: 300;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #333;
    word-wrap: break-word;
  }
`;

export const Form = styled(FormikForm)`
  display: grid;
  grid-gap: 16px;
`;

export const FormWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const SentMessage = styled.div`
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  > svg {
    transform: scale(1.5);
  }

  > div {
    text-align: center;
    /* font-weight: 300;
    font-size: 20px; */
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.02em;
    /* text-transform: uppercase; */
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 313px;
  width: 60%;

  .button {
    width: 100%;
    height: 50px;
    border-radius: 18px;
    background: linear-gradient(180deg, #2455b2 0%, #102342 100%);
    color: #fff;
    text-align: center;
    font-family: Bebas Neue;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none;
  }
`;
