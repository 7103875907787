import React from "react";
// packages
import { Formik, Field } from "formik";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import DateInput from "~/presentation/components/DateInput";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
import { Container, Form } from "~/presentation/views/Admin/Survey/CreateEdit/Form/styles";
import { Box, IconButton, Tooltip } from "@mui/material";
import AnswerContainer from "../components/AnswerContainer";
// schema
import validationSchema from "~/presentation/views/Admin/Survey/CreateEdit/Form/validationSchema";

const SurveyForm = ({ handleSubmit, initialValues }) => {
  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          await handleSubmit(values);
          action.setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Form>
            <h2>Enquete</h2>
            <Box className="form-grid">
              <Box className="col-1_3">
                <Field name="question" type="text" label="Pergunta*" as={TextInput} />
                <ErrorMessage name="question" />
              </Box>

              <Box className="col-3_5">
                <DateInput
                  label="Data da publicação *"
                  value={values.publishDate}
                  onChange={(value) => setFieldValue("publishDate", value)}
                />
                <ErrorMessage name="publishDate" />
              </Box>

              <Box className="col-1_5">
                <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
                  <h2>Respostas</h2>
                  <Tooltip title="Adicionar nova resposta">
                    <IconButton
                      sx={{ marginBottom: "8px" }}
                      onClick={() =>
                        setFieldValue("answers", [
                          ...values.answers,
                          {
                            id: 0,
                            text: "",
                            isCorrect: false,
                          },
                        ])
                      }
                    >
                      <ControlPointIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box className="form-grid answersWrapper">
                  {values.answers.map((answer, index) => (
                    <>
                      <Box className="col-1_5" key={"answers-wrapper-" + index}>
                        <AnswerContainer
                          answer={answer}
                          index={index}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      </Box>
                      <ErrorMessage name={`answers[${index}].text`} />
                    </>
                  ))}
                </Box>
              </Box>
            </Box>

            <FormSubmitButtonsDock>
              <PrimaryButton
                className="draft"
                type="submit"
                onClick={() => {
                  setFieldValue("draft", true);
                }}
              >
                Salvar como rascunho
              </PrimaryButton>
              <PrimaryButton
                type="submit"
                onClick={() => {
                  setFieldValue("draft", false);
                }}
              >
                Publicar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default SurveyForm;
