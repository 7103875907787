import styled from "styled-components";
import FormikCustomErrorMessage from "~/presentation/components/ErrorMessage/index.jsx";
import { Radio as MuiRadio, FormControlLabel as MuiFormControlLabel } from "@mui/material";

export const RadiosWrapper = styled.div`
  margin-left: max(1.5vw, 16px);
`;

export const Radio = styled(MuiRadio)`
  &.MuiRadio-root,
  &.MuiRadio-root.Mui-checked {
    color: ${(props) => "#383f48"};
  }
`;

export const FormControlLabel = styled(MuiFormControlLabel)`
  .MuiTypography-root {
    font-size: max(1.2vw, 18px);
    font-weight: 300;
    line-height: unset;
  }
`;

export const ErrorMessage = styled(FormikCustomErrorMessage)`
  margin-left: 16px;
`;
