import React, { useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// components
import Form from "~/presentation/views/Admin/GTFacil/Dialog/Form";

const initialValues = {
  text: "",
};

const CreateDialog = ({ dialogUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const handleSubmit = async (values) => {
    openBackdrop(true, "Cadastrando novo diálogo");
    try {
      await dialogUseCases.createDialog(values);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar diálogo:" + error,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <>
      <h1>Diálogo</h1>
      <Form initialValues={initialValues} handleSubmit={handleSubmit} />
    </>
  );
};

export default CreateDialog;
