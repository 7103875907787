import styled from "styled-components";
import ModalBg from "~/presentation/views/LandingPage/Home/Modals/components/Bg";
import BaseModalContentWrapper from "~/presentation/views/LandingPage/Home/Modals/components/Wrappers";
import BaseTextInput from "~/presentation/components/TextInputLight";
import BaseButton from "~/presentation/components/SecondaryButton";
import BaseAutocompleteSelect from "~/presentation/components/AutocompleteSelect";

export const Bg = styled(ModalBg).attrs(() => {
  return {
    opacity: 0.8,
    mixBlendMode: "multiply",
    color: "#1341a1",
  };
})``;

export const ModalContentWrapper = styled(BaseModalContentWrapper)`
  display: grid;
  grid-template-rows: min-content auto;
  row-gap: 60px;
`;

export const Header = styled.div`
  border-left: "solid 7px #cf9455";
  padding-left: 21px;
  padding-bottom: 7px;

  h1 {
    font-family: "Playfair Display", serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 53px;
    margin-bottom: 7px;
  }

  p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.02em;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;

  form {
    width: 100%;
  }

  .form-grid {
    grid-gap: 23px;
  }
`;

export const TextInput = styled(BaseTextInput).attrs(() => {
  return {
    color: "#fafafa",
    backgroundColor: "#7B8499",
    padding: "0 0 0 20px",
  };
})`
  font-family: "Roboto", sans-serif;
`;

export const InputLabel = styled.label`
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.02em;
  margin: 0 0 9px 5px;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Button = styled(BaseButton)`
  margin: 0 auto;
  border-radius: 4px;
  font-size: 16px;
  padding: 12px 62px;
  margin-top: 65px;
`;

export const AutocompleteSelect = styled(BaseAutocompleteSelect).attrs(() => {
  return {
    inputProps: {
      labelWidth: 0,
      color: "#fafafa",
      backgroundColor: "#7B8499",
      borderColor: "transparent",
      size: "small",
      borderRadius: "4px",
      height: "48px",
      fontSize: "0.9rem",
      placeholder: "Selecionar...",
    },
  };
})``;
