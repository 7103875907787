import React, { useState, useEffect, useContext } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
// packages
import { useParams } from "react-router-dom";
// components
import Form from "~/presentation/views/Admin/GTFacil/Dialog/Form";
import HeaderForm from "../../../components/HeaderForm/HeaderForm";

const EditDialog = ({ history, dialogUseCases }) => {
  const id = useParams().id;
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const [initialValues, setInitialValues] = useState();

  const fetchData = async (id) => {
    openBackdrop(true, "Carregando dados...");
    setInitialValues(await dialogUseCases.getById(id));
    openBackdrop(false);
  };

  const handleSubmit = async (values) => {
    openBackdrop(true, "Atualizando diálogo");
    try {
      await dialogUseCases.update(id, values);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao editar diálogo: missing endpoint",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  return (
    <>
      <HeaderForm title="Editar diálogo" backLink="/admin/gt-facil/dialogo" />
      {initialValues ? <Form initialValues={initialValues} handleSubmit={handleSubmit} /> : <div />}
    </>
  );
};

export default EditDialog;
