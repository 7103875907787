import buildQueryString from "~/presentation/utils/buildQueryString";
import { apiURL } from "../../usecases/api";
const types = {
  none: 0,
  news: 1,
  article: 2,
  legislation: 3,
  multimedia: 4,
};

export class ApiContentUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getContentTypes() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/content/GetTypes`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async getAllContents(isclient = false) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/content`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async registerContent(data) {
    await this.httpClient.request({
      url: `${apiURL}/content/`,
      method: "POST",
      data,
    });
  }

  async getContentById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/content/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        throw new Error(err.message);
      }
    }
  }

  async updateContent(data) {
    try {
      await this.httpClient.request({
        url: `${apiURL}/content`,
        method: "PUT",
        data,
      });
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async deleteContent(id) {
    try {
      await this.httpClient.request({
        url: `${apiURL}/content/${id}`,
        method: "DELETE",
      });
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getAllLegislation(queryParams) {
    try {
      let url = `${apiURL}/content?type=${types.legislation}`;
      url += `&${buildQueryString(queryParams)}`;
      let response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getLegislation(queryParams) {
    try {
      let url = `${apiURL}/legislation?`;
      url += `${buildQueryString(queryParams)}`;
      let response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getArticle(queryParams) {
    try {
      let url = `${apiURL}/article?`;
      url += `${buildQueryString(queryParams)}`;
      let response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getLegislationCategories() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/ContentCategory/Legislation`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getMostAccessedLegislation(queryParams) {
    // let response = { data: fakeLegislationList };
    try {
      let url = `${apiURL}/content?type=${types.legislation}`;
      url += `&${buildQueryString(queryParams)}`;
      let response = await this.httpClient.request({
        url: url,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getMostAccessedArticles(limit = 3) {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/article?&limit=${limit}&order=access`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getAllArticle() {
    try {
      let response = await this.httpClient.request({
        url: `${apiURL}/content?type=${types.article}`,
        method: "GET",
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        throw err.response;
      } else {
        console.log("Error", err.message);
        throw new Error(err.message);
      }
    }
  }

  async getAllMultimedia(queryParams) {
    try {
      let url = `${apiURL}/multimidia?`;
      url += `${buildQueryString(queryParams)}`;
      let response = await this.httpClient.request({
        url: url,
        method: "GET",
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response;
      } else {
        console.log("Error", error.message);
        throw new Error(error.message);
      }
    }
  }
}
