import styled from "styled-components";
import { Form as FormikForm } from "formik";
import { FormLabel as MuiFormLabel } from "@mui/material";
import { darken } from "polished";

export const Container = styled.div`
  background: #dfdfdf;
  border-radius: 10px;
  height: calc(100% - 40px - 12px); /** 40: h2 height // 12px: margin-top */
  margin-top: 12px;
  overflow-y: auto;
  padding: 20px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-row-gap: 20px;

  /* preserve the padding bottom */
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
  }
`;

export const Form = styled(FormikForm)`
  display: grid;
  grid-gap: 16px;
`;

export const FormLabel = styled(MuiFormLabel)`
  &.MuiFormLabel-root,
  &.MuiFormLabel-root.Mui-focused {
    color: #383f48;
  }
`;

export const SubContainer = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.7s ease-in-out;

  &.expand-true {
    max-height: 400px;
  }

  .wrapper {
    background: #e1e1e1;
    padding: 20px;
    /* margin: 6px 0 12px; */
  }
`;

export const ImageInputWrapper = styled.label`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 56px; /*${({ image }) => (image ? "unset" : "100%")};*/
  max-height: 330px;
  background: #eee;
  overflow: hidden;

  > input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  > div {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    border: solid 1px #383f48;
    opacity: ${({ image }) => (image ? "0.2" : "1")};
    transition: all 0.2s ease-in-out;
    font-size: 16px;
  }

  > img {
    width: 100%;
  }

  &:hover {
    > div {
      opacity: 1;
    }
  }
`;

export const DropzoneContainer = styled.div`
  border: 2px solid #383f48;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${darken(0.06, "#dedede")};
  }
  &:active {
    background-color: ${darken(0.09, "#dedede")};
  }

  &.dragActive {
    border-color: #26c6da;
    color: #26c6da;
  }

  p.fileName {
    text-decoration: underline;
  }
`;
