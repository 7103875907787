import React, { useState, useRef, useContext } from "react";
import { Field, Form, Formik } from "formik";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";

import FormWrapper from "~/presentation/views/LandingPage/Home/LoginBox/components/FormWrapper";
import validationSchema from "~/presentation/views/LandingPage/Home/LoginBox/ForgetPassForm/validationSchema";
import SubmitButtonWrapper from "~/presentation/views/LandingPage/Home/LoginBox/components/SubmitButtonWrapper";
import { ForgetPassFormStyles } from "~/presentation/views/LandingPage/Home/LoginBox/ForgetPassForm/ForgetPassForm.styles";

import TextInput from "~/presentation/components/TextInputLight";
import { FormikCustomErrorMessage } from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";

import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { ApiAuthentication } from "~/data/usecases/authentication/apiAuthentication";
import { Box, CircularProgress } from "@mui/material";

const ForgotPassForm = ({ currentModal, setCurrentModal }) => {
  const [values, setValues] = useState({
    email: currentModal?.content?.email,
  });
  const [loading, setLoading] = useState(false);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { email } = values;
      const apiAuth = new ApiAuthentication(makeHttpClient());

      const response = await apiAuth.recoverPassword(email);

      setFeedbackSnackbar({
        isOpen: true,
        message: response?.data
          ? response?.data
          : "Sua nova senha foi enviada para o e-mail cadastrado",
        type: "success",
      });

      redirectToLoginModal();
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: error?.response?.data ? error?.response?.data : "Erro ao recuperar senha",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      email: event.target.value,
    });
  };

  const redirectToLoginModal = () =>
    setCurrentModal({ name: "login", content: { email: values.email } });

  return (
    <Formik
      initialValues={{
        email: values.email,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await handleSubmit(values);
      }}
    >
      {() => (
        <Form>
          <Box sx={styles.form}>
            <div className="formMessage text-center">
              Para recuperar a senha, informe-nos o seu email.
            </div>
            <div style={{ width: "100%" }}>
              <Field
                placeholder="Email"
                name="email"
                type="text"
                as={TextInput}
                icon={() => <AccountCircleIcon />}
                onChange={handleChange}
                value={values.email}
                autoFocus
              />
              <FormikCustomErrorMessage name="email" />
            </div>

            <Box sx={styles.backText} onClick={redirectToLoginModal}>
              Voltar para o login
            </Box>

            <SubmitButtonWrapper>
              <PrimaryButton
                type="submit"
                fullWidth
                backgroundColor="#1341a1"
                textColor="#fff"
                size="small"
              >
                <ForgetPassFormStyles.RecoverText>
                  {loading ? <CircularProgress color="inherit" size="1.4rem" /> : "Recuperar senha"}
                </ForgetPassFormStyles.RecoverText>
              </PrimaryButton>
            </SubmitButtonWrapper>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassForm;

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 3,
    width: "100%",
  },
  backText: {
    cursor: "pointer",
    fontSize: 14,
  },
};
