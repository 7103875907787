import React from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import Select from "~/presentation/components/Select";
import { Container, Form } from "~/presentation/views/Admin/Cnae/CreateEdit/Form/styles";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
// schema
import validationSchema from "~/presentation/views/Admin/Cnae/CreateEdit/Form/validationSchema";
import { dataToSelectMapper } from "~/presentation/functions/dataToSelectMapper";
import MaskedInput from "~/presentation/components/MaskedInput";

const CnaeForm = ({ handleSubmit, initialValues, services, cnaes }) => {
  const mappedServices = dataToSelectMapper(services);
  const mappedCnaes = dataToSelectMapper(cnaes);

  const defaultServices = dataToSelectMapper(initialValues?.serviceCodes) || null;
  const defaultCnaes = dataToSelectMapper(initialValues?.cnaeAnswersLinked) || null;

  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          const submitValues = {
            id: values.id,
            code: values.code,
            description: values.description,
            detailCode: values.detailCode,
            synonym: values.synonym,
            comment: values.comment,
            serviceCodes:
              values.serviceCodes?.map((item) => {
                let service = {
                  id: item.id,
                  code: item.code,
                  description: item.description,
                };
                return service;
              }) || null,
            cnaeAnswersLinked: values.cnaeAnswersLinked?.map((item) => item.id) || null,
          };
          action.setSubmitting(true);
          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <h2>Cnae</h2>
            <div className="form-grid">
              <div className="col-1_2">
                <Field name="code" type="text" label="Código*" mask="9999-9/99" as={MaskedInput} />
                <ErrorMessage name="code" />
              </div>
              <div className="col-2_3">
                <Field name="detailCode" type="text" label="Detalhamento" as={TextInput} />
                <ErrorMessage name="detailCode" />
              </div>
              <div className="col-1_5">
                <Field name="description" type="text" label="Descrição*" as={TextInput} />
                <ErrorMessage name="description" />
              </div>
              <div className="col-1_5">
                <Field name="synonym" type="text" label="Sinônimo" as={TextInput} />
                <ErrorMessage name="synonym" />
              </div>
              <div className="col-1_5">
                <Field name="comment" type="text" label="Comentário" as={TextInput} />
                <ErrorMessage name="comment" />
              </div>
              <div className="col-1_5">
                <Field
                  name="serviceCodes"
                  label="Serviços"
                  placeholder="Serviços"
                  component={Select}
                  defaultValue={defaultServices}
                  isMulti={true}
                  options={mappedServices}
                  onChange={(option) => setFieldValue("serviceCodes", option)}
                  getOptionLabel={(option) => `${option.code} - ${option.description}`}
                />
              </div>
              <div className="col-1_5">
                <Field
                  name="cnaeAnswersLinked"
                  label="Cnaes"
                  placeholder="Cnaes"
                  component={Select}
                  defaultValue={defaultCnaes}
                  isMulti={true}
                  options={mappedCnaes}
                  onChange={(option) => setFieldValue("cnaeAnswersLinked", option)}
                  getOptionLabel={(option) => {
                    if (
                      option.detailCode !== null &&
                      option.detailCode !== "" &&
                      option.detailCode !== undefined
                    ) {
                      return `${option.code || 0} (${option.detailCode}) - ${option.description || option.label}`;
                    }
                    return `${option.code || 0} - ${option.description || option.label}`;
                  }}
                />
                <span className="info">
                  Defina quais respostas serão vinculadas a este CNAE com base nas respostas
                  vinculadas aos CNAEs selecionados.
                </span>
              </div>
            </div>

            <FormSubmitButtonsDock>
              <PrimaryButton className="submit-btn" type="submit">
                Salvar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CnaeForm;
