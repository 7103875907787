import React from "react";
import {Container} from "~/presentation/views/LandingPage/Home/Sections/Clients/styles";
import Carousel from "nuka-carousel";
import logos from "~/presentation/views/LandingPage/Home/Sections/Clients/logos.js";
import useWindowSize from "~/presentation/hooks/useWindowSize";

const carouselBreakpoints = {
  L: 1080,
  M: 960,
  S: 768,
  XS: 450,
};

const Clients = ({id}) => {
  const {width} = useWindowSize();

  const handleSlidesToShow = () => {
    if (width > carouselBreakpoints.L) return 5;
    if (width > carouselBreakpoints.M) return 4;
    if (width > carouselBreakpoints.S) return 3;
    if (width > carouselBreakpoints.XS) return 2;
    else return 1;
  };

  const handleSlidesToScroll = () => {
    if (width > carouselBreakpoints.L) return 4;
    if (width > carouselBreakpoints.M) return 3;
    if (width > carouselBreakpoints.S) return 2;
    else return 1;
  };

  return (
    <Container id={id}>
      <div className="radialGradient" />
      <div className="title">
        <h2>Nossos Clientes</h2>
        <div className="separator" />
      </div>
      <Carousel
        slidesToShow={handleSlidesToShow()}
        slidesToScroll={handleSlidesToScroll()}
        cellSpacing={70}
        width="100%"
        wrapAround
        renderCenterLeftControls={() => {}}
        renderCenterRightControls={() => {}}
        withoutControls={width < carouselBreakpoints.M}
        autoplay
        autoplayInterval={4000}
        speed={1000}
      >
        {logos.map((l, index) => (
          <img
            src={`/assets/clients_logo/${l}`}
            alt={l}
            key={"landing-page-clients-carousel-image-" + index}
          />
        ))}
      </Carousel>
    </Container>
  );
};

export default Clients;
