import styled from "styled-components";

const VideoContainer = styled.div`
  grid-area: video;
  position: relative;

  width: 60rem;
  height: 10rem;
  overflow: hidden;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  z-index: 999;
`;

export const GTFacilModalStyles = {
    VideoContainer
}