import { Box, Typography, Paper } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import ButtonCommon from "~/presentation/components/Common/Button";

function HeaderForm({ title, backLink, backFnc }) {
  const history = useHistory();
  const backButton = backFnc || backLink;

  const getBack = () => {
    if (backLink) {
      return history.push(backLink);
    }
    if (backFnc) {
      return backFnc();
    }
  };

  return (
    <Paper elevation={1} sx={styles.Box}>
      <Typography sx={styles.Title}>{title}</Typography>
      {backButton && (
        <ButtonCommon variant="outlined" sx={{ maxWidth: "150px" }} onClick={getBack}>
          Voltar
        </ButtonCommon>
      )}
    </Paper>
  );
}

export default HeaderForm;

const styles = {
  Box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
    marginBottom: 1,
    marginTop: 1,
    paddingBlock: 3,
    paddingInline: 1,
    "@media (min-width: 600px)": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  Title: {
    fontFamily: "Montserrat, sans-serif",
    textAlign: "left",
    fontSize: 28,
    fontWeight: 600,
    color: "#242424",
  },
};
