import styled from "styled-components";
import { Pagination as MuiPagination } from "@mui/material";
import { Inbox } from "@mui/icons-material";

export const Container = styled.div`
  min-height: 80vh;
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const CardsWrapper = styled.div`
  padding: 1rem 0;
  display: grid;
  justify-content: center;
  grid-row-gap: max(2vw, 16px);
  grid-template-columns: 100%;

  @media screen and (min-width: 600px) {
    grid-template-columns: 70%;
  }

  @media screen and (min-width: 960px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: max(2vw, 16px);
  }

  display: flex;
  flex-direction: column;
`;

export const Loading = styled.div`
  grid-column: 1/4;
  justify-self: center;
  align-self: center;
  padding: max(80px, 8vw) 0;
  margin-top: max(36px, 5vw);
`;

export const Pagination = styled(MuiPagination)`
  justify-self: center;
  width: fit-content;
  margin: 0 auto 2rem auto;

  @media screen and (min-width: 960px) {
    grid-column: 1/4;
  }

  &.MuiPagination-root {
    display: flex;
    align-items: center;

    .MuiPaginationItem-root {
      &.Mui-selected {
        font-weight: 700;
        background-color: transparent;
        font-size: 1rem;
      }
      &:hover {
        font-weight: 700;
        background-color: transparent;
      }
    }
  }
`;

export const NoPostsFound = styled.div`
  justify-self: center;
  display: flex;
  align-items: center;
  font-size: max(18px, 1.4vw);
  gap: 10px;
  margin-top: max(36px, 5vw);
  padding: max(80px, 8vw) 0;
  flex-flow: column;
  text-align: center;

  @media screen and (min-width: 768px) {
    flex-flow: row;
  }

  @media screen and (min-width: 960px) {
    grid-column: 1/4;
  }
`;

export const InboxIcon = styled(Inbox)`
  &.MuiSvgIcon-root {
    fill: #1341a1;
  }
`;
