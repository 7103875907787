import styled from "styled-components";
import ModalContainer from "~/presentation/components/ModalContainer";

export const Container = styled(ModalContainer)`
  padding: max(3vw, 30px) max(4.5vw, 45px);
  display: flex;
  flex-flow: column;
  row-gap: max(4vw, 48px);
  background-position: 30%;
  max-height: 101vh;
  overflow-y: auto;
`;

export const Logo = styled.img.attrs({
  src: "/assets/logo_gt.png",
  alt: "logo",
})`
  width: max(20vw, 200px);
  background: #fafafa;
  padding: max(1vw, 12px);
  border-radius: max(0.5vw, 10px);
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Line = styled.span`
  background: #fafafa;
  width: max(0.2vw, 2px);
  height: 75%;
  position: absolute;
  left: min(-1.5vw, -15px);
`;

export const Title = styled.h1`
  font-size: max(4.5vw, 48px);
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  font-style: italic;
  line-height: max(5vw, 56px);
  width: 100%;

  > span {
    color: #1e88e5;
  }

  @media screen and (min-width: 600px) {
    width: 50%;
  }
`;

export const Subtitle = styled.div`
  font-size: max(1.5vw, 18px);
  font-family: "Montserrat", sans-serif;
  width: 100%;

  > b {
    font-family: "Roboto", sans-serif;
  }

  @media screen and (min-width: 600px) {
    width: 50%;
  }
`;

export const Button = styled.button`
  border: none;
  outline: none;
  background: #fafafa;
  color: #1341a1;
  font-size: max(1.5vw, 20px);
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
  width: fit-content;
  padding: max(0.8vw, 8px) max(2vw, 16px);
  border-radius: 2vw;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #fafafa;
    background: #1341a1;
  }
`;

export const ButtonBackground = styled.div`
  background: #021B3A;
  width: 44px;
  height: 44px;
  margin: 5px;
  border-radius: 50px;
  position: fixed;
  z-index: 4;
  right: 4.5vw;
  top: 6.1%;
`;
