import styled from "styled-components";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import { Checkbox as MuiCheckbox } from "@mui/material";
import { Form as FormikForm } from "formik";

export const Container = styled.div``;

export const Title = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: max(1.4vw, 20px);
  font-weight: normal;
  margin-bottom: 16px;
`;

export const Warning = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: max(1.4vw, 16px);
  font-weight: normal;
  margin-bottom: 16px;
`;

export const Checkbox = styled(MuiCheckbox)`
  &.MuiCheckbox-root,
  &.MuiCheckbox-root.Mui-checked {
    color: ${(props) => "#1341a1"};
  }
`;

export const CheckboxesWrapper = styled.div`
  display: flex;
  flex-flow: column;
`;

export const Button = styled(PrimaryButton).attrs({
  backgroundColor: "#1341a1",
  size: "small",
})`
  &.MuiButton-root {
    align-self: center;
  }
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex-flow: column;
  gap: 16px;
`;
