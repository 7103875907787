import { Container } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "~/presentation/AppContext";
import TableActions from "~/presentation/components/TableActions";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import PageListHeader from "../components/PageListHeader";
import Table from "~/presentation/components/Table";
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import RegisterEdit from "./RegisterEdit/RegisterEdit";

const columns = [
  { name: "name", label: "Nome" },
  { name: "numberOfUsers", label: "Usuários" },
  { name: "valor", label: "Preço" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

function Plan({ planUseCases }) {
  const { haveSuperAdminAccess } = useContext(AppContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  //Data
  const [plans, setPlans] = useState([]);

  //Search
  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  //Abrir forms criar / editar
  const [openForm, setOpenForm] = useState(false);
  const [formProps, setFormProps] = useState(false);

  const handleOpenForm = (data = null) => {
    setOpenForm(true);
    setFormProps(data);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setFormProps(null);
  };

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados da tabela");

    const userIsSuperAdmin = haveSuperAdminAccess();

    const response = await planUseCases.getPlans();
    response.forEach((plan) => {
      if (userIsSuperAdmin) {
        plan.actions = (
          <TableActions
            handleDelete={() => deleteDialog(plan.id)}
            handleEdit={() => handleOpenForm(plan)}
          />
        );
      } else {
        plan.actions = <TableActions handleEdit={() => handleOpenForm(plan)} />;
      }
    });

    const plansWithValueFormatted = response.map((plan) => {
      return {
        ...plan,
        valor: formatNumberToRealString(plan.valor),
      };
    });

    setPlans(plansWithValueFormatted);
    openBackdrop(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo plano");

    try {
      await planUseCases.deletePlan(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Plano excluído!",
        type: "success",
      });
      setPlans((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      console.log(error.response);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir o plano. " + error?.response?.data,
        type: "error",
      });
    }

    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este plano?`,
      callback: () => handleDelete(id),
    });
  };

  const cleanSearch = () => {
    triggerInputChange(searchFieldRef.current);
    triggerInputChange(hiddenInputRef.current);
  };

  //Search
  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);

  return (
    <>
      <Container maxWidth="100%">
        {openForm ? (
          <RegisterEdit
            data={formProps}
            onCloseForm={handleCloseForm}
            refresData={fetchData}
            planUseCases={planUseCases}
          />
        ) : (
          <>
            <PageListHeader
              title="Plano"
              titleAccess="Novo plano"
              searchFieldRef={searchFieldRef}
              handleAdd={() => handleOpenForm()}
              handleSearch={handleSearch}
            />
            <Table searchRef={hiddenInputRef} columns={columns} data={plans} title="Planos" />
          </>
        )}
      </Container>
    </>
  );
}

export default Plan;
