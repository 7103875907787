export default [
    {
        id: 0,
        name: 'PJ',
    },
    {
        id: 1,
        name: 'MEI',
    },
    {
        id: 2,
        name: 'PF',
    },
    {
        id: 3,
        name: 'Cooperativa',
    },
    {
        id: 4,
        name: 'Simples Nacional',
    },    
]