import React from "react";
import { makeCompanyUseCases } from "~/main/factories/use-cases/company";
import { makePlanUseCases } from "~/main/factories/use-cases/plan";
import Company from "~/presentation/views/Admin/Company/Company";

export const makeCompanyTable = (params) => {
  return (
    <Company
      {...params}
      companyUseCases={makeCompanyUseCases()}
      planUseCases={makePlanUseCases()}
    />
  );
};
