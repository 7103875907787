import { darken } from "@mui/material";
import { transparentize } from "polished";
import styled from "styled-components";

const Base = styled.div`
  text-align: center;
  font-size: max(18px, 1.3vw);
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  color: #fafafa;
  padding: max(18px, 1.3vw);
  border-radius: 8px;
  box-shadow: "0px 8px 10px -5px rgba(0,0,0,0.17)";
  transition: all 0.16s ease-in-out;
`;

export const Container = styled(Base)`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")}};

  background-color: ${({ color, selected, disabled }) =>
    disabled ? "#c2c2c2" : selected ? darken(color, 0.15) : color};

  &:hover {
    background-color: ${({ disabled, color }) => (disabled ? "" : darken(color, 0.15))};
    transform: ${({ disabled }) => (disabled ? "none" : "scale(1.05)")};
  }
`;

// export const Disabled = styled(Base)`
//   cursor: default;
//   background-color: ${({color}) => transparentize(0.5, color)};
// `;
