import styled from "styled-components";
import { Form as FormikForm } from "formik";

export const Container = styled.div`
  background-color: #fff;

  border-radius: 10px;
  height: calc(100% - 40px - 12px); /** 40: h2 height // 12px: margin-top */
  margin: 12px 0px;

  overflow-y: auto;
  padding: 20px;
  display: grid;
  grid-template-rows: min-content auto;
  grid-row-gap: 20px;

  /* preserve the padding bottom */
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
  }

  .answersWrapper {
    background: #e1e1e1;
    padding: 20px;
    border-radius: 4px;
  }
`;

export const Form = styled(FormikForm)`
  display: grid;
  grid-gap: 16px;
`;
