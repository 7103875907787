import styled from "styled-components";

export const Container = styled.div`
  padding-bottom: 10px;
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;

  .errorMessage {
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
    width: fit-content;
  }
`;
