import styled from "styled-components";

export const TextWrapper = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: max(18px, 1.3vw);
`;

export const Text = styled.div`
  font-size: max(18px, 1.3vw);
  font-weight: 300;

  > b {
    font-weight: 500;
  }
`;

export const TextInnerHtml = styled.span`
  font-size: max(18px, 1.3vw);
  font-weight: 300;
  line-height: 1.5;
  word-break: break-word;
    
    & * {
        display: inline;
        font-weight: 300;
    }
`;
