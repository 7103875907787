import styled from "styled-components";
import { TextField as MuiTextField } from "@mui/material";

const getColor = (theme, color) => color || "#383f48";
const getDisabledBgColor = (theme, bgColor, title) => {
  if (bgColor) return bgColor;
  if (title === "dark") return "#e1e1e1";
  else return "#bcbcbc";
};
const getBgColor = (bgColor) => bgColor || "transparent";
const getBorderColor = (theme, borderColor) => borderColor || "#383f48";

export const TextField = styled(MuiTextField)`
  margin: 5px;
  width: 100%;
  border-radius: ${({ borderRadius }) => borderRadius || 0};
  height: ${({ height }) => height || "unset"};
  justify-content: center;

  background-color: ${({ backgroundColor }) => getBgColor(backgroundColor)};

  label {
    color: ${({ theme, color }) => getColor(theme, color)};
    &.Mui-disabled {
      color: ${({ theme, color }) => getColor(theme, color)};
    }
    &.Mui-focused {
      color: ${({ theme, color }) => getColor(theme, color)};
    }
  }

  & .MuiInput-underline:after {
    border-bottom-color: ${({ theme, borderColor }) => getBorderColor(theme, borderColor)};
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${({ theme, borderColor }) => getBorderColor(theme, borderColor)};
    }
    &:hover fieldset {
      border-color: ${({ theme, borderColor }) => getBorderColor(theme, borderColor)};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme, borderColor }) => getBorderColor(theme, borderColor)};
    }
    &.Mui-disabled {
      fieldset {
        border-color: ${({ theme, borderColor }) => getBorderColor(theme, borderColor)};
      }
    }
  }

  .Mui-disabled {
    color: ${({ theme, color }) => getColor(theme, color)};
    cursor: not-allowed;
    background-color: ${({ theme, title, backgroundColor }) =>
      getDisabledBgColor(theme, backgroundColor, title)};
  }

  .MuiInputBase-root {
    color: ${({ theme, color }) => getColor(theme, color)};
    font-size: ${({ fontSize }) => fontSize || "1rem"};
  }

  .MuiAutocomplete-endAdornment {
    .MuiIconButton-root {
      color: ${({ theme, color }) => getColor(theme, color)};
    }
  }
`;
