import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  gap: 2px;

  > div {
    display: grid;
    grid-template-columns: min-content auto;
    align-items: center;

    > small {
      min-width: 5rem;
    }

    > span {
      margin-left: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 6px 12px;
  }

  > hr {
    margin-top: 10px;
    border: none;
    height: 1px;
    background: ${(props) => "#383f48"};
  }
`;
