import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import TypeAnswerForm from "../Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

const initialValues = {
  id: 0,
  title: "",
};

export const TypeAnswerCreate = ({ typesAnswerUseCases }) => {
  const { openBackdrop } = useContext(BackdropContext);

  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const history = useHistory();

  const handleSubmit = async (values) => {
    openBackdrop(true, "Cadastrando tipo de resposta");
    try {
      await typesAnswerUseCases.createTypeAnswer(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Tipo de resposta cadastrado com sucesso!",
        type: "success",
      });
      history.push("/admin/tipos-de-resposta");
    } catch (err) {
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar tipo de resposta",
        type: "error",
      });
    }
    openBackdrop(false);
  };

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Cadastrar Tipo de Resposta" />
      <TypeAnswerForm initialValues={initialValues} handleSubmit={handleSubmit} />
    </Container>
  );
};
