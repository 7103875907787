import React, { useContext } from "react";
import Select from "react-select";
import { customStyles } from "~/presentation/components/Select/styles";
import { ThemeContext } from "styled-components";

const SelectField = ({
  options,
  field,
  placeholder,
  form,
  onChange,
  padding,
  label,
  defaultValue,
  ...rest
}) => {
  const appTheme = useContext(ThemeContext);

  const getValue = () => {
    if (!options) return "";
    const foundOption = options.find(
      (option) => JSON.stringify(option.value) === JSON.stringify(field.value)
    );
    return foundOption;
  };

  return (
    <Select
      placeholder={placeholder}
      label={label}
      defaultValue={defaultValue}
      options={options}
      value={getValue()}
      onChange={(option) => {
        if (onChange) {
          onChange(option);
        } else {
          form.setFieldValue(field.name, option.value);
        }
      }}
      appTheme={appTheme}
      styles={customStyles(appTheme, { padding })}
      noOptionsMessage={() => "Não há opções"}
      theme={(theme) => ({
        ...theme,
        colors: {
          primary50: "#d7d7d7",
          primary25: "#dedede",
          primary: "#1e88e5",
        },
      })}
      isDisabled={rest.disabled}
      {...rest}
    />
  );
};

export default SelectField;
