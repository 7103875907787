import * as Yup from "yup";

export const validationSendR2010 = Yup.object({
  amountBC: Yup.number("É necessário ser um número!")
    .positive("Campo Obrigatório!")
    .required("Campo Obrigatório!")
    .typeError("Campo obrigatório!"),
  itemsNFse: Yup.array().of(
    Yup.object({
      serviceClassificationId: Yup.number("Campo Obrigatório!")
        .positive("Campo Obrigatório!")
        .required("Campo Obrigatório!"),
      bc: Yup.number("É necessário ser um número!")
        .positive("Campo Obrigatório!")
        .required("Campo Obrigatório!")
        .typeError("Campo obrigatório!"),
      amount: Yup.number("É necessário ser um número!")
        .positive("Campo Obrigatório!")
        .required("Campo Obrigatório!")
        .typeError("Campo obrigatório!"),
    })
  ),
});

export const validationSendR4020AB = Yup.object({
  amountBC: Yup.number("É necessário ser um número!")
    .positive("Campo Obrigatório!")
    .required("Campo Obrigatório!")
    .typeError("Campo obrigatório!"),
  incomeNatureId: Yup.number("É necessário ser um número!")
    .typeError("Campo obrigatório!")
    .positive("Campo Obrigatório!")
    .required("Campo Obrigatório!"),
  dateOfPayment: Yup.date("Data inválida!")
    .typeError("Campo obrigatório!")
    .max(new Date(), "Escolha uma data no presente!")
    .required("Campo Obrigatório!"),
});

export const validationSendR4020C = Yup.object({
  amountBC: Yup.number("É necessário ser um número!")
    .positive("Campo Obrigatório!")
    .required("Campo Obrigatório!")
    .typeError("Campo obrigatório!"),
  incomeNatureId: Yup.number("É necessário ser um número!")
    .typeError("Campo obrigatório!")
    .positive("Campo Obrigatório!")
    .required("Campo Obrigatório!"),
  dateOfPayment: Yup.date("Data inválida!")
    .typeError("Campo obrigatório!")
    .max(new Date(), "Escolha uma data no presente!")
    .required("Campo Obrigatório!"),
  bcIR: Yup.number("É necessário ser um número!")
    .positive("Campo Obrigatório!")
    .required("Campo Obrigatório!")
    .typeError("Campo obrigatório!"),
});

export const validationSendR4020D = Yup.object({
  amountBC: Yup.number("É necessário ser um número!")
    .positive("Campo Obrigatório!")
    .required("Campo Obrigatório!")
    .typeError("Campo obrigatório!"),
  incomeNatureId: Yup.number("É necessário ser um número!")
    .typeError("Campo obrigatório!")
    .positive("Campo Obrigatório!")
    .required("Campo Obrigatório!"),
  dateOfPayment: Yup.date("Data inválida!")
    .typeError("Campo obrigatório!")
    .max(new Date(), "Escolha uma data no presente!")
    .required("Campo Obrigatório!"),
  bcIR: Yup.number("É necessário ser um número!")
    .positive("Campo Obrigatório!")
    .required("Campo Obrigatório!")
    .typeError("Campo obrigatório!"),
  bcCSRF: Yup.number("É necessário ser um número!")
    .positive("Campo Obrigatório!")
    .required("Campo Obrigatório!")
    .typeError("Campo obrigatório!"),
});
