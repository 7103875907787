import styled from "styled-components";

export const ModalContentWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  padding: 7.4vh 10vw;
  border-radius: 13px;

  @media screen and (min-width: 768px) {
    padding: 7.4vh 13.1vw 7.4vh 9.1vw;
  }
`;

export const HeaderTextSlideWrapper = styled(ModalContentWrapper)`
  padding: 10vh 10vw 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto min-content;
  grid-template-areas: "H" "R" "L";

  @media screen and (min-width: 768px) {
    padding: 14vh 2vw 0 9.1vw;
    grid-template-columns: 50% 50%;
    grid-template-rows: min-content auto;
    grid-template-areas: "H H" "L R";
  }
`;

export const LeftContent = styled.div`
  grid-area: L;
  display: grid;
  grid-template-rows: auto min-content;
`;

export const RightContent = styled.div`
  grid-area: R;
  display: flex;
  align-items: center;
  margin: 64px 0;

  @media screen and (min-width: 768px) {
    padding-bottom: 14vh;
    margin: 0;
  }
`;

export const Text = styled.div`
  align-self: center;
  width: 100%;
  margin-bottom: 32px;

  p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.02em;
    text-align: justify;
    margin-bottom: 17px;
  }
`;

export const Header = styled.div`
  width: 100%;
  background: linear-gradient(180deg, #e97c03 48%, #6a4113 100%);
  border-radius: 18px;
  z-index: 2;
  color: white;
  font-size: 2.625rem;
  font-family: Montserrat;
  word-wrap: break-word;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 2rem 3rem;

  span {
    font-weight: 700;
  }

  @media (max-width: 83.8125rem) {
    font-size: 1.625rem;
  }

  @media (max-width: 63.8125rem) {
    flex-direction: column;
  }
`;

export const Button = styled.button`
  background: linear-gradient(180deg, #2455b2 0%, #102342 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  wtext-align: center;
  color: white;
  font-size: 30px;
  font-family: "Bebas Neue";
  font-weight: 400;
  word-wrap: break-word;
  width: 20rem;
  height: 3.5rem;
  cursor: pointer;
`;

export const ContainerTextHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 55rem) {
    flex-direction: column;
    text-align: center;
  }
`;

export const Description = styled.div`
  z-index: 2;
  padding: 2rem 3rem;

  span {
    font-weight: 700;
  }
`;

export const VideoContainer = styled.div`
  grid-area: video;
  position: relative;

  box-shadow: -20px -19px 0px 0px #14213d;

  width: 60rem;
  height: 10rem;
  overflow: hidden;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const ContainerDescriptionWithVideo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 63.75rem) {
    flex-direction: column;
  }
`;

export const Icon = styled.img``;

export const WrapperContent = styled.div`
  padding: 1rem;
`;

export default ModalContentWrapper;
