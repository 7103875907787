import React, { useContext, useEffect, useState } from "react";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { useHistory, useParams } from "react-router-dom";
import AnswerForm from "../Form";
import HeaderForm from "../../components/HeaderForm/HeaderForm";
import { Container } from "@mui/material";

const AnswerEdit = ({
  answerUseCases,
  itemAnswerUseCases,
  companyUseCases,
  cnaesUseCases,
  termUseCases,
}) => {
  const { openBackdrop } = useContext(BackdropContext);

  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const id = useParams().id;
  const history = useHistory();

  const [initialValues, setInitialValues] = useState();
  const [cnaes, setCnaes] = useState([]);
  const [itemsAnswer, setItemsAnswer] = useState([]);
  const [categories, setCategories] = useState([]);
  const [terms, setTerms] = useState([]);

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados");
    try {
      Promise.all([
        answerUseCases.getById(id),
        cnaesUseCases.listCnaes(),
        itemAnswerUseCases.getAll(),
        companyUseCases.getCompanyCategories(),
        termUseCases.getAll(),
      ]).then(([apiAnswer, { data: cnaes }, apiTypeAnswers, categories, terms]) => {
        setInitialValues(apiAnswer);
        setCnaes(cnaes);
        setItemsAnswer(apiTypeAnswers);
        setCategories(categories);
        setTerms(terms);
        openBackdrop(false);
      });
    } catch (err) {
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao carregar dados",
        type: "error",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    openBackdrop(true, "Editando resposta");
    try {
      await answerUseCases.update(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Resposta atualizada com sucesso!",
        type: "success",
      });
      history.push("/admin/respostas");
    } catch (error) {
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: `Erro ao atualizar a resposta: ${error.response.data}`,
        type: "error",
      });
    }
    openBackdrop(false);
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Editar resposta" backLink="/admin/respostas" />
      {initialValues ? (
        <AnswerForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          cnaes={cnaes}
          itemsAnswer={itemsAnswer}
          categories={categories}
          terms={terms}
        />
      ) : (
        <div />
      )}
    </Container>
  );
};

export default AnswerEdit;
