import React, { memo } from "react";
import { Container, StyledCurrencyInput } from "./styles";

export const MoneyField = memo(
  ({
    onChangeEvent,
    value,
    disabled,
    allowNegative,
    prefix,
    variant,
    border,
    borderRadius,
    backgroundColor,
    ...props
  }) => {
    return (
      <Container
        disabled={disabled}
        border={border}
        borderRadius={borderRadius}
        backgroundColor={backgroundColor}
        {...props}
      >
        <span style={{ color: disabled ? "#575757" : "#000", userSelect: "none" }}>{prefix}</span>

        <StyledCurrencyInput
          allowNegative={allowNegative}
          value={value}
          onChangeEvent={onChangeEvent}
          precision="2"
          decimalSeparator=","
          thousandSeparator="."
          disabled={disabled}
          {...props}
        />

        {props.percentageValue && (
          <>
            <span
              style={{
                borderLeft: "1px solid gray",
                paddingLeft: 8,
                userSelect: "none",
                overflow: "hidden",
                width: "7ch",
              }}
            >
              {props.percentageValue}
            </span>
            %
          </>
        )}
      </Container>
    );
  }
);
