import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getRetentionTable } from "~/presentation/views/Reinf/Utils/utilsReinf";
import RButton from "~/presentation/views/Reinf/components/Common/Buttons/RButton";
import Input from "~/presentation/views/Reinf/components/Common/Inputs";
import handleMoney from "~/presentation/views/Reinf/components/HandleMoney/handleMoney";

function CalculateRetentions({ onClose, onChangeItem, values, index, nfseData }) {
  const QSO = JSON.parse(localStorage.getItem("GT_layoutQSO")); // LETRA QSO

  const codeOptions = QSO === "C" ? ["0", "0.24", "1.2", "2.4", "4.8"] : getRetentionTable();
  const [percentage, setPercentage] = useState(null);

  const handleCalculate = () => {
    let retentionIR = values?.amountIR;
    let retentionCSLL = values?.amountCSLL;
    let retentionCOFINS = values?.amountCOFINS;
    let retentionPIS = values?.amountPIS;
    let aliquotaIR = values?.aliquotIR;
    let aliquotaCSRF = values?.aliquotCSRF;

    if (QSO === "A" || QSO === "B") {
      retentionIR = values?.bcIR * (percentage.IR / 100);
      retentionCSLL = values?.bcIR * (percentage.CSLL / 100);
      retentionCOFINS = values?.bcIR * (percentage.COFINS / 100);
      retentionPIS = values?.bcIR * (percentage.PIS / 100);
      aliquotaIR = percentage.percentage;
    }

    if (QSO === "C") {
      retentionIR = values?.bcIR * (percentage / 100);
      aliquotaIR = percentage;
    }

    if (QSO === "D") {
      retentionIR = values?.bcIR * (percentage.IR / 100);
      retentionCSLL = values?.bcCSRF * (percentage.CSLL / 100);
      retentionCOFINS = values?.bcCSRF * (percentage.COFINS / 100);
      retentionPIS = values?.bcCSRF * (percentage.PIS / 100);
      aliquotaCSRF = percentage.percentage;
    }

    onChangeItem(
      {
        ...nfseData[index],
        amountIR: retentionIR,
        amountCSLL: retentionCSLL,
        amountCOFINS: retentionCOFINS,
        amountPIS: retentionPIS,
        aliquotCSRF: aliquotaCSRF,
        aliquotIR: aliquotaIR,
      },
      index
    );
    onClose();
  };

  return (
    <Grid container spacing={2} sx={styles.container}>
      {QSO === "C" ? (
        <>
          <Grid item xs={12}>
            <Typography sx={styles.title}>Calcular retenção</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={styles.text}>
              A retenção do IR irá ser calculada automaticamente de acordo com a alíquota.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              type="select"
              placeholder="Selecione a alíquota"
              title="Selecione a alíquota do IR"
              options={codeOptions}
              getOptionLabel={(option) => `Alíquota: ${option}%`}
              value={codeOptions?.filter((item) => item == percentage) || null}
              onChange={(option) => {
                setPercentage(option);
              }}
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography sx={styles.title}>Calcular retenções</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={styles.text}>
              As retenções de IR, CSLL, PIS/Pasep e COFINS serão calculadas automaticamente de
              acordo com o código de receita selecionado.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              type="select"
              title="Selecione o código da receita"
              placeholder="Selecione a código"
              options={codeOptions}
              getOptionLabel={(option) => `Código: ${option?.code}`}
              value={codeOptions?.find((item) => item.code === percentage?.code) || null}
              onChange={(option) => {
                setPercentage(option);
              }}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sx={styles.buttons}>
        <RButton sx={{ width: "250px" }} variant="outlined" onClick={onClose}>
          CANCELAR
        </RButton>
        <RButton sx={{ width: "250px" }} onClick={handleCalculate} disabled={!percentage}>
          CALCULAR
        </RButton>
      </Grid>
    </Grid>
  );
}

export default CalculateRetentions;

const styles = {
  container: {
    p: 4,
    maxWidth: "700px",
  },
  title: {
    fontSize: 25,
    fontWeight: 600,
    letterSpacing: 0,
    color: "#242424",
    fontFamily: "Montserrat, sans-serif",
  },
  text: {
    fontSize: 16,
    pt: 1,
    letterSpacing: 0,
    color: "#242424",
    fontFamily: "Montserrat, sans-serif",
    textAlign: "justify",
    lineHeight: 2,
  },
  buttons: {
    marginTop: 2,
    display: "flex",
    alignItems: "center",
    gap: 2,
    justifyContent: "right",
  },
};
