import styled from "styled-components";

export const QuestionContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;

  @media (min-width: 600px) {
    grid-template-columns: 65% 35%;
    grid-template-rows: auto;
    align-items: center;
    margin: 0;

    > div:last-child {
      align-self: flex-start;
    }

    ${({ vertical }) =>
      vertical
        ? `
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        grid-row-gap: 16px;
      `
        : ""}
  }
`;
