import React from "react";
import {Container} from "./styles";

export const SectionHeader = ({text, subTitle}) => {
  return (
    <Container>
      <h2>{text}</h2>
      {subTitle ? <p>{subTitle}</p> : null}
      <span />
    </Container>
  );
};
