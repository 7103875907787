import React, { useContext, useEffect, useState } from "react";
import { HeaderSectionPage } from "../components/HeaderSectionPage";
import { makeHttpClient } from "~/main/factories/infra/http-client";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { Box } from "@mui/material";
import { Search } from "@mui/icons-material";
import RButton from "../components/Common/Buttons/RButton";
import { ApiBusinessPartnerUseCases } from "~/data/usecases/businessPartner/businessPartner";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { PartnerForm } from "~/presentation/views/Reinf/BusinessPartner/components/PartnerForm/PartnerForm";
import { BusinessPartnerTable } from "~/presentation/views/Reinf/BusinessPartner/components/BusinessPartnerTable/BusinessPartnerTable";
import ModalContainer from "../components/ModalContainer/ModalContainer";
import { useTableFilters } from "../Hooks/useTableFilters";

function BusinessPartner() {
  const apiBusinessPartner = new ApiBusinessPartnerUseCases(makeHttpClient());
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openBackdrop } = useContext(BackdropContext);
  const { openDialogBox } = useContext(DialogContext);
  const { handleSearchTable } = useTableFilters();

  // Table Data
  const [partnersData, setPartnersData] = useState([]);
  const [storageData, setStorageData] = useState([]);

  // Filter
  const [searchTerm, setSearchTerm] = useState("");

  // Modal
  const [open, setOpen] = useState(false);
  const [currentPartnerDataToEdit, setCurrentPartnerDataToEdit] = useState(null);

  const handleOpenCreate = () => {
    setCurrentPartnerDataToEdit([]);
    setOpen(true);
  };

  const handleOpenEdit = (item) => {
    setCurrentPartnerDataToEdit(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    openDialogBox({
      message: "Deseja excluir este Parceiro de Negócio?",
      callback: () => {
        apiBusinessPartner
          .deleteBusinessPartner(id)
          .then(() => {
            //eliminar da tabela
            setPartnersData((prev) => prev.filter((partner) => partner.id !== id));
            setFeedbackSnackbar({
              isOpen: true,
              message: "Parceiro de Negócio excluído com sucesso",
              type: "success",
            });
          })
          .catch((error) => {
            setFeedbackSnackbar({
              isOpen: true,
              message: `Erro ao excluir Parceiro de Negócio: ${error}`,
              type: "error",
            });
          });
      },
    });
  };

  useEffect(() => {
    setDataIntoState();
  }, []);

  const getData = async () => {
    const events = await apiBusinessPartner.listBusinessPartner();
    if (events) {
      return events;
    } else {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos eventos, verifique sua conexão",
        type: "error",
      });
    }
  };

  const setDataIntoState = async () => {
    try {
      openBackdrop(true, "Carregando informação dos eventos");
      const response = await getData();
      const data = response?.reverse(); //para o mais recente ficar em cima na tabela
      setPartnersData(data);
      setStorageData(data);
    } catch (error) {
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro no retorno dos dados, verifique sua conexão",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleSearchTable(value, storageData, setPartnersData);
  };

  return (
    <>
      {/* HEADER */}
      <HeaderSectionPage
        title="Parceiros de Negócio"
        iconLeft
        buttonPrimary={<RButton onClick={handleOpenCreate}>Cadastrar novo</RButton>}
      />

      {/* BOTÃO PESQUISA */}
      <Box sx={styles.filterContainer}>
        <Box sx={styles.searchContainer}>
          <input
            style={styles.searchInput}
            type="text"
            onChange={handleSearch}
            placeholder="Pesquisar..."
            value={searchTerm}
          />
          <Search />
        </Box>
      </Box>

      {/* TABELA */}
      <BusinessPartnerTable
        partnersData={partnersData}
        handleOpenEdit={handleOpenEdit}
        handleDelete={handleDelete}
      />

      {/* MODAL EDIÇÃO / CRIAÇÃO */}
      <ModalContainer scroll={true} open={open} onClose={handleClose}>
        <PartnerForm
          onClose={handleClose}
          partnerFormData={currentPartnerDataToEdit}
          refreshData={setDataIntoState}
        />
      </ModalContainer>
    </>
  );
}

export default BusinessPartner;

const styles = {
  Button: {
    width: "fit-content",
    borderRadius: "8px",
    boxShadow: 0,
    textTransform: "uppercase",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "end",
    marginRight: 3,
    marginLeft: 3,
    marginBlock: 2,
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#DCDCDC",
    paddingRight: 2,
    paddingLeft: 2,
    borderRadius: "5px",
    height: "45px",
    marginTop: "5px",
    maxWidth: "400px",
    width: "100%",
  },
  searchInput: {
    border: "none",
    background: "transparent",
    width: "100%",
    fontFamily: "Montserrat",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  serachIcon: {
    color: "#1341A1",
  },
};
