import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import styled from "styled-components";

export const DialogComponent = styled(Dialog)`
  .MuiDialog-container > .MuiPaper-root {
    border-radius: 10px;
    background-color: #eee;
  }
`;

export const DialogContentComponent = styled(DialogContent)`
  flex-flow: column;
  text-align: center;
  color: #383f48;
  font-family: "Roboto", sans-serif;
`;

export const DialogActionsComponent = styled(DialogActions)`
  &.MuiDialogActions-root {
    justify-content: center;
  }
`;

export const ButtonComponent = styled(Button)`
  .MuiButton-label {
    color: #383f48;
  }
`;
