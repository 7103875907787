import React from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import { Container, Form } from "~/presentation/views/Admin/Service/CreateEdit/Form/styles";
import FormSubmitButtonsDock from "~/presentation/views/Admin/components/FormSubmitButtonsDock";
// schema
import validationSchema from "./validationSchema";

const TypeAnswerForm = ({ handleSubmit, initialValues }) => {
  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          const submitValues = {
            id: values.id,
            title: values.title,
            description: values.description,
            order: values.order,
          };
          action.setSubmitting(true);
          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {() => (
          <Form>
            <h2>Tipo de Resposta</h2>
            <div className="form-grid">
              <div className="col-1_4">
                <Field name="title" type="text" label="Título*" as={TextInput} />
                <ErrorMessage name="title" />
              </div>
              <div className="col-4_5">
                <Field name="order" type="number" label="Ordem*" as={TextInput} />
                <ErrorMessage name="order" />
              </div>
              <div className="col-1_5">
                <Field name="description" type="text" label="Descrição" as={TextInput} />
                <ErrorMessage name="description" />
              </div>
            </div>

            <FormSubmitButtonsDock>
              <PrimaryButton className="submit-btn" type="submit">
                Salvar
              </PrimaryButton>
            </FormSubmitButtonsDock>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default TypeAnswerForm;
