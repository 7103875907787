import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Footer from "~/presentation/components/Footer";
import HomeLayout from "~/presentation/layouts/HomeLayout";
import clientNavbarItems from "~/presentation/views/Client/data/clientNavbarItems";
import { PostContent } from "~/presentation/components/PostContent/PostContent";
import { SectionTitlePost } from "~/presentation/components/SectionTitlePost/SectionTitlePost";

const DEFAULT_IMAGE = "/assets/bglegislacao.png";

const LegislationPost = ({ contentUseCases }) => {
  const { id: postId } = useParams();
  const [post, setPost] = useState();

  const getPost = async () => {
    const response = await contentUseCases.getContentById(postId);
    setPost(response);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
    getPost();
  }, [postId]);

  return (
    <HomeLayout navbarItems={clientNavbarItems()}>
      {() => (
        <>
          <SectionTitlePost
            redirectURL="/cliente/conteudo-selecionado/legislacao"
            title="Legislação"
            image={DEFAULT_IMAGE}
            searchButtonBg="#2699DA"
            searchPlaceholder="Pesquisar Norma"
          />
          <PostContent
            redirectURL="/cliente/conteudo-selecionado/legislacao"
            post={post}
            type="LEGISLAÇÃO"
            defaultImg={"/assets/judge-table.jpeg"}
          />
          <Footer />
        </>
      )}
    </HomeLayout>
  );
};

export default LegislationPost;
