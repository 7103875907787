import React from "react";
import { Fade } from "react-reveal";
import { SectionTitlePostStyles } from "~/presentation/components/SectionTitlePost/SectionTitlePost.styles";
import { SearchForm } from "~/presentation/views/Blog/components/SearchForm";
import { useHistory } from "react-router";
import { Container } from "@mui/material";

export const SectionTitlePost = ({
  title,
  $marginToHeader,
  redirectURL,
  image,
  onSearch,
  searchButtonBg,
  searchPlaceholder,
  blur,
}) => {
  const history = useHistory();

  return (
    <SectionTitlePostStyles.Container $marginToHeader={$marginToHeader} $image={image}>
      <SectionTitlePostStyles.Blur blur={blur} />
      <Container maxWidth="lg">
        <SectionTitlePostStyles.ContainerContent>
          <Fade left distance="10%">
            <div>
              <span onClick={() => history.push(redirectURL)}>{title}</span>
            </div>
          </Fade>
          {onSearch && (
            <SearchForm
              title={""}
              searchButtonBg={searchButtonBg} // default -> primary (blue) // secondary (orange)
              componentRef={null}
              initialValue={""}
              onSearch={(value) => onSearch(value, true)}
              placeholder={searchPlaceholder}
            />
          )}
        </SectionTitlePostStyles.ContainerContent>
      </Container>
    </SectionTitlePostStyles.Container>
  );
};
