import React, { useContext, useEffect, useState } from "react";
import AppContext from "~/presentation/AppContext";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import AnswerForm from "../Form";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "@mui/material";
import HeaderForm from "../../components/HeaderForm/HeaderForm";

const initialValues = {
  id: 0,
  gtAnswerName: "",
  description: "",
  typeTax: null,
  isSimple: true,
  companyType: [],
  categories: [],
  cityId: null,
  cnaes: [],
  gtAnswerItems: [],
};

export const AnswerCreate = ({
  answerUseCases,
  itemAnswerUseCases,
  companyUseCases,
  cnaesUseCases,
  termUserCases,
}) => {
  const [copiedAnswer, setCopiedAnswer] = useState(null);

  const location = useLocation();

  const { openBackdrop } = useContext(BackdropContext);

  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const history = useHistory();

  const [cnaes, setCnaes] = useState([]);
  const [itemsAnswer, setItemsAnswer] = useState([]);
  const [categories, setCategories] = useState([]);
  const [terms, setTerms] = useState([]);

  const fetchData = async () => {
    openBackdrop(true, "Carregando dados");
    const { data } = await cnaesUseCases.listCnaes();
    const apiTypeAnswers = await itemAnswerUseCases.getAll();
    const categories = await companyUseCases.getCompanyCategories();
    const terms = await termUserCases.getAll();
    if (location.state) {
      const apiAnswer = await answerUseCases.getById(location.state.answer.id);
      setCopiedAnswer(apiAnswer);
    }
    setCnaes(data);
    setItemsAnswer(apiTypeAnswers);
    setCategories(categories);
    setTerms(terms);
    openBackdrop(false);
  };

  useEffect(() => {
    fetchData();
  }, [location.state]);

  const handleSubmit = async (values) => {
    openBackdrop(true, "Cadastrando resposta");
    try {
      await answerUseCases.createAnswer(values);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Resposta cadastrado com sucesso!",
        type: "success",
      });
      history.push("/admin/respostas");
    } catch (err) {
      openBackdrop(false);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao cadastrar resposta",
        type: "error",
      });
    } finally {
      openBackdrop(false);
    }
  };

  return (
    <Container maxWidth="100%">
      <HeaderForm title="Cadastrar Resposta" />
      {!!copiedAnswer && (
        <AnswerForm
          initialValues={copiedAnswer}
          handleSubmit={handleSubmit}
          cnaes={cnaes}
          itemsAnswer={itemsAnswer}
          categories={categories}
          terms={terms}
        />
      )}
      {!copiedAnswer && (
        <AnswerForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          cnaes={cnaes}
          itemsAnswer={itemsAnswer}
          categories={categories}
          terms={terms}
        />
      )}
    </Container>
  );
};
