import React from "react";
// packages
import { Formik, Field } from "formik";
//components
import ErrorMessage from "~/presentation/components/ErrorMessage";
import PrimaryButton from "~/presentation/components/PrimaryButton";
import TextInput from "~/presentation/components/TextInput";
import {
  Container,
  Form,
  Checkbox,
} from "~/presentation/views/Admin/LegislationCategory/Form/styles";
// schema
import validationSchema from "~/presentation/views/Admin/LegislationCategory/Form/validationSchema";

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION_BUCKET,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const LegislationCategoryForm = ({ handleSubmit, initialValues }) => {
  return (
    <Container className="scrollbar-white">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, action) => {
          action.setSubmitting(true);
          const submitValues = {
            ...values,
          };
          await handleSubmit(submitValues);
          action.setSubmitting(false);
        }}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <h2>Categoria</h2>
            <div className="form-grid">
              <div className="col-1_5">
                <Field name="name" label="Nome" as={TextInput} />
                <ErrorMessage name="title" />
              </div>
              <div className="col-1_5">
                <Field name="letter" label="Letra" as={TextInput} />
                <ErrorMessage name="letter" />
              </div>
            </div>
            <PrimaryButton className="submit-btn" type="submit">
              Salvar
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default LegislationCategoryForm;
