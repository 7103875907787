export class INSSCalculator {
  constructor({
    simulatorValue,
    materialOrEquipmentExclusionValue,
    transportationOrAlimentationBenefitsDeductionValue,
    aliquotRetention,
    additionalAliquotRetention,
    principalAliquotRentionValue,
    additionalAliquotRetentionValue
  }) {
    (this.simulatorValue = simulatorValue),
      (this.materialOrEquipmentExclusionValue =
        materialOrEquipmentExclusionValue),
      (this.transportationOrAlimentationBenefitsDeductionValue =
        transportationOrAlimentationBenefitsDeductionValue),
      (this.aliquotRetention = aliquotRetention),
      (this.additionalAliquotRetention = additionalAliquotRetention),
      (this.principalAliquotRentionValue = this.baseRetentionCalcValue * this.aliquotRetention),
      (this.additionalAliquotRetentionValue = this.baseRetentionCalcValue * this.additionalAliquotRetention)

  }

  get baseRetentionCalcValue() {
    return (
      this.simulatorValue -
      this.transportationOrAlimentationBenefitsDeductionValue -
      this.materialOrEquipmentExclusionValue
    );
  }

  get retentionValue() {
    return (
      this.baseRetentionCalcValue * this.aliquotRetention +
      this.baseRetentionCalcValue * this.additionalAliquotRetention
    );
  }
}
