import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { makeBlogHome, makeBlogPost, makeBlogList } from "~/main/factories/pages/blog";
import PrivateRoute from "./PrivateRoute";

const BlogRoutes = ({ match: { url } }) => {
  return (
    <Switch>
      <Route exact path={`${url}`} component={makeBlogHome} />
      <Route exact path={`${url}/list/`} component={makeBlogList} />
      <PrivateRoute
        roles={["user", "manager", "admin", "superAdmin"]}
        exact
        path={`${url}/post/:id`}
        redirectTo={`${url}`}
        component={makeBlogPost}
      />
      <Redirect to={url} />
    </Switch>
  );
};

export default BlogRoutes;
