// import fakeSearches from "./fake-searches.json";
// import fakeAnswers from "./fake-answers.json";
// import fakeHiredTypes from "./fake-hired-types.json";
import dummyBase64pdf from "./dummyBase64pdf";
import { HiredType } from "~/domain/entities/hired-type";
import { errorHandler } from "~/data/functions/errorHandler";
import { apiURL } from "../../usecases/api";
export class ApiGTFacilUseCases {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getCnaeGT(iscnae = true) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/gtfacil/items/${iscnae}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getAnswersGT() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/gtfacil/answers-summary`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getSearchTerms() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/gtfacil/searchterm`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async saveAnswers(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.httpClient.request({
      url: `${apiURL}/gt-facil/answers`,
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  }

  /**
   * @param {String} search
   * @param {String} filter = "cnae" | "lc116" | "freeSearch"
   * @returns {String[]}
   */
  async getAnswersBy(search, filter) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/gt-facil/answers?search=${search}&filter=${filter}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async saveFreeSearch(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.httpClient.request({
      url: `${apiURL}/gt-facil/free-search`,
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  }

  async saveCNAE(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.httpClient.request({
      url: `${apiURL}/gt-facil/cnae`,
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    return response.data;
  }

  /**
   * @param {String} search
   * @param {String} filter = "cnae" | "lc116" | "freeSearch"
   * @returns {String[]}
   */
  async getSearchesBy(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/gt-facil/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getSearchBy(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/gtfacil/${id}`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getAnswerBy(data) {
    const queryString = Object.keys(data)
      .filter((key) => data[key] !== null)
      .map((key) => `${key}=${data[key]}`)
      .join("&");

    let url = `${apiURL}/gtfacil`;
    url += `?${queryString}`;

    const response = await this.httpClient.request({
      url,
      method: "GET",
    });
    return response;
  }

  async hiredTypes() {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/gt-facil/hired-type`,
        method: "GET",
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async hiredTypeById(id) {
    try {
      const response = await this.httpClient.request({
        url: `${apiURL}/gt-facil/hired-type/${id}`,
        method: "GET",
      });
      return new HiredType(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * @param {boolean[]} data list of tribute types (categories) to be included in the report
   */
  async sendReportRequest(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/report/gt-facil`,
      method: "POST",
      data,
    });
    return response;
    // const result =  errorHandler(response);
    // return result;
  }

  async sendSimReportRequest(data) {
    const response = await this.httpClient.request({
      url: `${apiURL}/report/simulator`,
      method: "POST",
      data,
    });
    return response.data;
  }
}
