import * as Yup from "yup";

import { formatRealStringToNumber } from "~/presentation/utils/formatCurrency";

export default Yup.object({
  name: Yup.string().min(4, "Digite um nome válido!").required("Campo obrigatório!"),
  numberOfUsers: Yup.number()
    .positive("Digite um número positivo.")
    .required("Campo obrigatório!")
    .integer("Digite um número inteiro"),
  consultLimit: Yup.number()
    .positive("Digite um número positivo.")
    .required("Campo obrigatório!")
    .integer("Digite um número inteiro"),
  isGenerateReport: Yup.boolean(),
  isCalculationSimulator: Yup.boolean(),
  isConsultISS: Yup.boolean(),
  isAllowReinf: Yup.boolean(),
  isSpecificSubstitutionAnalysis: Yup.boolean(),
  valor: Yup.string()
    .required("Campo obrigatório!")
    .test("is-positive", "Digite valor positivo.", (value) => {
      const formattedValue = formatRealStringToNumber(value);
      if (formattedValue) {
        return formattedValue > 0;
      }
      return true;
    }),
  taxInformationNumber: Yup.number()
    .integer("Digite um número inteiro.")
    .positive("Digite uma taxa positivo.")
    .required("Campo obrigatório!"),
});
