import React from "react";
import {makeLegislationCategoryUseCases} from "~/main/factories/use-cases/legislation-category";
import LegislationCategoryTable from "~/presentation/views/Admin/LegislationCategory/Table";

export const makeLegislationCategoryTable = (params) => {
  return (
    <LegislationCategoryTable
      {...params}
      legislationCategoryUseCases={makeLegislationCategoryUseCases()}
    />
  );
};