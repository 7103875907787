import React from "react";
import { htmlToText } from "~/presentation/functions/htmlToText";
import { limitTextSize } from "~/presentation/utils/limitTextSize";
import { Box, Typography } from "@mui/material";

export const ContentCard = ({ imageSrc, onClick, item }) => {
  const defaultImage = "/assets/judge-table.jpeg";
  const handleError = (e) => {
    e.target.onerror = null; // Prevents the handler from running again if the default image also fails
    e.target.src = defaultImage;
  };

  const { title, text, publishDate, autor, source, summary } = item;

  const formattedDate = new Intl.DateTimeFormat("pt-BR", {
    dateStyle: "short",
  }).format(new Date(publishDate));

  const renderAuthorAndDate = () => {
    const writer = autor ? autor : source;

    if (writer && !publishDate) {
      return `por ${writer}`;
    }

    if (!writer && publishDate) {
      return `${formattedDate}`;
    }

    if (writer && publishDate) {
      return `por ${writer} em ${formattedDate}`;
    }
  };

  return (
    <Box sx={styles.container} onClick={onClick}>
      <Box
        component="img"
        src={imageSrc || defaultImage}
        alt="card-image"
        onError={handleError}
        sx={styles.img}
      />
      <Box sx={styles.line} />
      <Box sx={styles.textContainer}>
        <Typography
          sx={{
            ...styles.text,
            lineHeight: "28.04px",
            fontSize: "1rem",
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            ...styles.text,
            lineHeight: "18.28px",
            fontSize: "0.85rem",
            fontWeight: 300,
            fontStyle: "italic",
          }}
        >
          {renderAuthorAndDate()}
        </Typography>
        <Typography
          sx={{
            ...styles.text,
            lineHeight: "15.85px",
            fontSize: "0.8rem",
            fontWeight: 400,
          }}
        >
          {htmlToText(summary ? limitTextSize(summary, 200) : "")}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    background: "#E1E1E1",
    borderRadius: "6px",
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    transition: "transform 0.3s ease-out, background-color 0.3s ease-out",
    "&:hover": {
      transform: "scale(1.02)",
      backgroundColor: "#E9E9E9",
    },
    "@media (min-width: 600px)": {
      flexDirection: "row",
      gap: 3,
      p: 2,
    },
  },
  img: {
    width: 90,
    height: 90,
    borderRadius: "50%",
    objectFit: "cover",
    alignSelf: "center",
    m: 1,
    "@media (min-width: 600px)": {
      m: 0,
      marginLeft: 1,
    },
  },
  line: {
    border: "1px solid #C3C3C3",
    opacity: 0.4,
    "@media (min-width: 600px)": {
      opacity: 1,
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    alignSelf: "center",
    width: "80%",
    m: 1,
  },
  text: {
    fontFamily: "Montserrat",
    textAlign: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    whiteSpace: "normal",
  },
};
