import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "~/presentation/AppContext";
import Table from "~/presentation/components/Table";
import TableActions from "~/presentation/components/TableActions";
import triggerInputChange from "~/presentation/hooks/triggerInputChange";
import { formatNumberToRealString } from "~/presentation/utils/formatCurrency";
import { BackdropContext } from "~/presentation/providers/BackdropProvider";
import { SnackbarContext } from "~/presentation/providers/SnackbarProvider";
import { DialogContext } from "~/presentation/providers/DialogProvider";
import { Box, Modal } from "@mui/material";
import { formatDateToBrazilian } from "~/presentation/views/Reinf/Utils/utilsDate";
import {
  makeEditAdditionalCompanyPlan,
  makeRegisterAdditionalCompanyPlan,
} from "~/main/factories/pages/admin/additional-company-plan";
import DetailListHeader from "~/presentation/views/Admin/components/DetailListHeader";

const columns = [
  { name: "typeDescription", label: "Tipo" },
  { name: "amount", label: "Quantidade" },
  { name: "price", label: "Preço" },
  { name: "limitDate", label: "Data de vencimento" },
  { name: "actions", label: "Ações", options: { sortable: "false" } },
];

export const AdditionalCompanyPlanList = ({ additionalCompanyPlanUseCases, company }) => {
  const { haveSuperAdminAccess } = useContext(AppContext);

  const [additionalCompanyPlanList, setAdditionalCompanyPlanList] = useState([]);
  const hiddenInputRef = useRef();
  const searchFieldRef = useRef();

  const { openBackdrop } = useContext(BackdropContext);
  const { setFeedbackSnackbar } = useContext(SnackbarContext);
  const { openDialogBox } = useContext(DialogContext);

  const [error, setError] = useState(false);

  const [isCreateAdditionalCompanyPlan, setIsCreateAdditionalCompanyPlan] = useState(false);
  const [isEditAdditionalCompanyPlan, setIsEditAdditionalCompanyPlan] = useState(false);
  const [editAdditionalCompanyPlan, setEditAdditionalCompanyPlan] = useState(null);

  const fetchData = async () => {
    try {
      const userIsSuperAdmin = haveSuperAdminAccess();

      const response = await additionalCompanyPlanUseCases.getAdditionalCompanyPlans(company.id);
      response.forEach((additionalCompanyPlan) => {
        if (userIsSuperAdmin) {
          additionalCompanyPlan.actions = (
            <TableActions
              handleDelete={() => deleteDialog(additionalCompanyPlan.id)}
              handleEdit={() => handleEdit(additionalCompanyPlan)}
            />
          );
        } else {
          additionalCompanyPlan.actions = (
            <TableActions handleEdit={() => handleEdit(additionalCompanyPlan)} />
          );
        }
      });

      const additionalCompanyPlansWithValueFormatted = response.map((additionalCompanyPlan) => {
        return {
          ...additionalCompanyPlan,
          typeDescription:
            additionalCompanyPlan.typeDescription === "User"
              ? "Usuário"
              : additionalCompanyPlan.typeDescription,
          limitDate: additionalCompanyPlan.limitDate
            ? formatDateToBrazilian(additionalCompanyPlan.limitDate)
            : "-",
          price: formatNumberToRealString(additionalCompanyPlan.price),
        };
      });

      setAdditionalCompanyPlanList(additionalCompanyPlansWithValueFormatted);
    } catch (error) {
      setError("Erro ao carregar os adicionais de plano.");
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao carregar os adicionais de plano.",
        type: "error",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (values) => {
    setEditAdditionalCompanyPlan(values);
    setIsEditAdditionalCompanyPlan(true);
  };

  const handleDelete = async (id) => {
    openBackdrop(true, "Excluindo adicional de plano");

    try {
      await additionalCompanyPlanUseCases.deleteAdditionalCompanyPlan(id);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Adicional de plano excluído com sucesso.",
        type: "success",
      });
      setAdditionalCompanyPlanList((prev) => prev.filter((item) => item.id !== id));
      cleanSearch();
    } catch (error) {
      console.log(error.response);
      setFeedbackSnackbar({
        isOpen: true,
        message: "Erro ao excluir o adicional de plano.",
        type: "error",
      });
    }

    openBackdrop(false);
  };

  const deleteDialog = (id) => {
    openDialogBox({
      message: `Deseja mesmo excluir este adicional de plano?`,
      callback: () => handleDelete(id),
    });
  };

  const handleSearch = (value) => triggerInputChange(hiddenInputRef.current, value);
  const cleanSearch = () => {
    triggerInputChange(hiddenInputRef.current);
    triggerInputChange(searchFieldRef.current);
  };

  const handleAdd = () => setIsCreateAdditionalCompanyPlan(true);

  return (
    <>
      <Box maxWidth="100%">
        <DetailListHeader
          title="Adicionais de plano"
          titleAccess="Novo adicional de plano"
          searchFieldRef={searchFieldRef}
          handleAdd={handleAdd}
          handleSearch={handleSearch}
        />

        <Table
          searchRef={hiddenInputRef}
          columns={columns}
          data={additionalCompanyPlanList}
          title="Adicionais de plano"
          tableOptions={false}
          errorMsg={error}
        />
      </Box>
      <Modal open={isCreateAdditionalCompanyPlan} onClose={setIsCreateAdditionalCompanyPlan}>
        {makeRegisterAdditionalCompanyPlan({
          closeModal: () => setIsCreateAdditionalCompanyPlan(false),
          companyName: company.name,
          companyId: company.id,
          refetchData: fetchData,
        })}
      </Modal>
      <Modal open={isEditAdditionalCompanyPlan} onClose={setIsEditAdditionalCompanyPlan}>
        {makeEditAdditionalCompanyPlan({
          closeModal: () => {
            setIsEditAdditionalCompanyPlan(false);
            setEditAdditionalCompanyPlan(null);
          },
          initialValues: editAdditionalCompanyPlan,
          id: editAdditionalCompanyPlan?.id,
          companyName: company.name,
          companyId: company.id,
          refetchData: fetchData,
        })}
      </Modal>
    </>
  );
};
